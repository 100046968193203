import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import ProductCard from './ProductCard';
import { State } from '../../../../models/State';
import { fetchFileRequest } from '../../../../actions/files';

const mapStateToProps = ({
  files: { byHash },
  catalog: { embellishmentsByHash },
}: State) => ({
  filesByHash: byHash,
  embellishmentsByHash,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchFile: bindActionCreators(fetchFileRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
