import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import TeamsFooter from './TeamsFooter';
import { deleteMembersRequest } from '../../../../actions/members';
import State from '../../../../models';
import { bulkDeactivateInviteTokenRequest } from '../../../../actions/invitations';

const mapStateToProps = ({
  players: { playerDeleted, byHash: playersByHash, selectedId: playersSelected },
  staff: {
    byHash: staffByHash, pendingStaffMembersByHash, selectedId, selectedStaffIds,
  },
}: State) => ({
  playerDeleted,
  staffByHash,
  pendingStaffMembersByHash,
  selectedStaff: selectedStaffIds,
  selectedPlayer: playersSelected,
  playersByHash,
  selectedPendingStaff: selectedId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  deleteMembers: bindActionCreators(deleteMembersRequest, dispatch),
  bulkDeactivateToken: bindActionCreators(bulkDeactivateInviteTokenRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsFooter);
