import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { push } from 'connected-react-router';

import { Response } from '../../models/Response';
import { Player } from '../../models/Player';
import { sendInvitationRequest } from '../../actions/invitations';
import { CREATE_PLAYER_ERROR, CREATE_PLAYER_REQUEST, CREATE_PLAYER_SUCCESS } from '../../constants/actionTypes';
import { CreatePlayerRequestAction, NotificationType } from '../../models/actions';
import { State } from '../../models/State';
import { TEAM_PLAYERS_PATH } from '../../constants/paths';
import { CLUB_TEAMS_ROUTE } from '../../constants/routes';
import { addNotification } from '../../actions/notifications';
import { createPlayerError, createPlayerSuccess } from '../../actions/players';
import { handleError } from '../../actions/errors';

export default (
  action$: ActionsObservable<CreatePlayerRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(CREATE_PLAYER_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      team, player, shouldInvite, createInvitation, invitationPersonalData,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: TEAM_PLAYERS_PATH.replace('$teamId', `${team.id}`),
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify(player),
  }).pipe(
    mergeMap(({ response: createdPlayer }: Response<Player>) => {
      const createInvitationWithId = { ...createInvitation, playerId: createdPlayer.id };

      return [
        addNotification(NotificationType.SUCCESS, CREATE_PLAYER_SUCCESS),
        createPlayerSuccess(createdPlayer),
        !shouldInvite && push(CLUB_TEAMS_ROUTE),
        shouldInvite && sendInvitationRequest(createInvitationWithId, invitationPersonalData, true),
      ].filter(Boolean);
    }),
    catchError((error: AjaxError) => [
      handleError(error, CREATE_PLAYER_ERROR),
      createPlayerError(error),
    ]),
  )),
);
