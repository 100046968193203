import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Match, Training } from '../../../../models/Calendar';
import styles from './PreviewCSVUpload.module.scss';
import MatchContent from './components/MatchContent';
import TrainingContent from './components/TrainingContent';

interface PreviewCSVUploadProps {
  event: Training|Match|null;
  loading: boolean;
}

const PreviewCSVUpload: FC<PreviewCSVUploadProps> = ({ event, loading }) => {
  const [translate] = useTranslation();

  const renderMatchContent = () => event && (event as Match).match && (
    <MatchContent event={event as Match} />
  );

  const renderTrainingContent = () => event && (event as Training).training && (
    <TrainingContent event={event as Training} />
  );

  return loading ? (
    <div className={styles.dimmerWrapper}><Dimmer active inverted><Loader /></Dimmer></div>
  ) : (
    <div className={styles.wrapper}>
      <h2 className={styles.header}>
        {translate('MATCH_BULK_UPLOAD_PREVIEW')}
      </h2>
      <p>{translate('THIS_IS_A_PREVIEW_OF_THE_MATCH_INFORMATION')}</p>
      {renderMatchContent()}
      {renderTrainingContent()}
    </div>
  );
};

export default PreviewCSVUpload;
