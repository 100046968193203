import pickBy from 'lodash/pickBy';
import { filterOptionalEmbellishments } from '../../../../containers/OrderPreparation/components/MemberDetails/utils/prepareOrderItemForUpsert';
import { ProductsByHash } from '../../../../models/Catalog';

import { Item, ItemsByHash } from '../../../../models/Order';

export default (itemId: string, itemsByHash: ItemsByHash, productsByHash: ProductsByHash) => {
  const {
    productCatalogId, quantity, playerId,
    size, color, maxQuantity, staffId,
    quantityLocked, sizeLocked, embellishments = [],
  }: Item = itemsByHash[itemId];
  const productCatalogEmbellishments = productsByHash[productCatalogId].embellishments;

  return pickBy({
    id: itemsByHash[itemId].id,
    externalId: itemsByHash[itemId].externalId,
    productCatalogId,
    quantity,
    maxQuantity,
    playerId,
    size,
    color,
    staffId,
    quantityLocked,
    sizeLocked,
    embellishments: filterOptionalEmbellishments(embellishments, productCatalogEmbellishments),
  });
};
