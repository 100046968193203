import { ItemEmbellishment, ProductEmbellishment } from '../../../../../models/Embellishment';
import { EmbellishmentGroup } from '../embellishmentTypes';

export const filterOptionalEmbellishments = (
  embellishments: ItemEmbellishment[], productCatalogEmbellishments: ProductEmbellishment[],
) => embellishments.filter(
  e => {
    const catalogMatch = productCatalogEmbellishments.find(pce => pce.embellishment === e.type);

    if (
      !!catalogMatch
        && EmbellishmentGroup.BOOLEAN_TYPES.includes(e.type)
        && !catalogMatch.mandatory
    ) {
      return !!e.value;
    }
    return true;
  },
).map(e => {
  const {
    mandatory, value, type, ...rest
  } = e;
  return ({
    ...rest,
    type,
    value: !EmbellishmentGroup.BOOLEAN_TYPES.includes(type) ? value : null,
  });
});
