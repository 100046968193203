import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AjaxError } from 'rxjs/ajax';
import { NotificationType, SetLockNumbersRequestAction } from '../../models/actions';
import { State } from '../../models/State';
import { SET_LOCK_NUMBERS_SUCCESS, SET_LOCK_NUMBERS_REQUEST, SET_LOCK_NUMBERS_ERROR } from '../../constants/actionTypes';
import { LOCKED_NUMBERS_PATH } from '../../constants/paths';
import { addNotification } from '../../actions/notifications';
import { setLockNumbersError, setLockNumbersSuccess } from '../../actions/teams';
import { handleError } from '../../actions/errors';

export default (
  action$: ActionsObservable<SetLockNumbersRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(SET_LOCK_NUMBERS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      lockedNumbers,
    },
  }, {
    authentication: { authToken },
    application: {
      teamId,
    },
  }]) => ajax({
    url: LOCKED_NUMBERS_PATH.replace('$teamId', `${teamId}`),
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify({
      lockedNumbers,
    }),
  }).pipe(
    mergeMap(() => [
      addNotification(NotificationType.SUCCESS, SET_LOCK_NUMBERS_SUCCESS),
      setLockNumbersSuccess(teamId, lockedNumbers),
    ]),
    catchError((error: AjaxError) => [
      handleError(error, SET_LOCK_NUMBERS_ERROR),
      setLockNumbersError(error),
    ]),
  )),
);
