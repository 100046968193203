import React from 'react';
import { Redirect, Route, RouteProps as RoutePropsLib } from 'react-router-dom';
import { HOME_ROUTE } from '../../../../constants/routes';
import { Roles } from '../../../../constants/roles';
import { User } from '../../../../models/general/User';
import { REQUIRED_ROLES } from '../../../../constants/customizations';
import { NtsUserMembership } from '../../../../models/Response';

export interface UnprotectedRouteProps extends RoutePropsLib {
  isAuthenticated?: Boolean;
  clubId?: number | null;
  teamId?: number | null;
  userRole?: Roles | null;
  user?: User | null;
  memberships?: NtsUserMembership;
  infoFetched?: boolean;
}

const hasRequiredRole = (
  { memberships = [], clubId }: { memberships?: NtsUserMembership, clubId?: number | null },
  requiredRoles: Roles[],
) => clubId && memberships[clubId]
  && memberships[clubId].some((role: Roles) => requiredRoles.includes(role));

const UnprotectedRoute = ({
  isAuthenticated, location, clubId, infoFetched, teamId, userRole, memberships, user, ...props
}: UnprotectedRouteProps) => {
  const assureTeamLeadHasTeams = userRole && infoFetched
    && (userRole === Roles.CLUB_ADMIN || (teamId && user!.teams && user!.teams.length));

  return isAuthenticated
    && assureTeamLeadHasTeams
    && (
      Object.keys(memberships!)
      && clubId
      && hasRequiredRole({ clubId, memberships }, REQUIRED_ROLES))
    ? <Redirect to={`${HOME_ROUTE}${location ? location.search : ''}`} />
    : <Route {...props} />;
};

export default UnprotectedRoute;
