import some from 'lodash/some';
import { Club, ClubEditRequest } from '../../models/Club';

const serializeClubEdit = ({
  name, address, phoneNumber, faxNumber, email, primaryColor, socialMedia,
  websiteAddress, websiteEventsAddress, websiteNewsAddress,
}: Club): ClubEditRequest => ({
  name,
  ...(some(address, Boolean) && {
    address: {
      ...address,
      country: 'DE',
    },
  }),
  phoneNumber,
  faxNumber,
  email,
  websiteAddress,
  websiteEventsAddress,
  websiteNewsAddress,
  primaryColor,
  socialMedia,
});

export default serializeClubEdit;
