import React, { useCallback, useEffect, FormEvent } from 'react';
import xor from 'lodash/xor';
import { Checkbox, CheckboxProps } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PlayerRow from './components/PlayerRow';
import SortArrows from '../../components/Table/components/SortArrows';
import StaffRow from './components/StaffRow';
import TableContainer from '../../components/Layout/components/TableContainer';
import Text from '../../components/Text';
import params from '../../utils/params';
import styles from './PlayersAndStaffList.module.scss';
import useSorting from '../../hooks/useSorting';
import { Column } from '../../components/Table/Column';
import { OrderItemType } from '../OrderPreparation/OrderPreparationProps';
import { PlayersAndStaffListProps } from './PlayersAndStaffListProps';
import { UrlParams } from '../../constants/urlParams';
import { playerColumns, staffColumns } from './columns';
import { SortOrder } from '../../models/general/SortOrder';
import { PLAYERS_LIST_LIMIT, TABLE_SORT_FIELD_URL_PARAM, TABLE_SORT_ORDER_URL_PARAM } from '../../constants/customizations';
import RoleFilterTabs from './components/RoleFilterTabs';

const PlayersAndStaffList = ({
  staffById,
  staffByHash,
  staffLoading,
  playersByHash,
  playersCount,
  playersLoading,
  playersById,
  selectedPlayers,
  setSelectedPlayers,
  selectedPendingStaff,
  setSelectedPendingStaff,
  fetchPlayers,
  fetchStaff,
  userRole,
  teamId,
  clubId,
  loading,
  playersActions,
  staffActions,
  resendInvitationForMember,
  resendInvitationForPlayer,
  loadingInvite,
  selectedStaff,
  selectedMembersTeam,
  setSelectedStaff,
  pendingStaffByHash,
  pendingStaffById,
}: PlayersAndStaffListProps) => {
  const [translate] = useTranslation();
  const history = useHistory();
  const { getParams } = params(history);
  const { setSortOrder, setSortField } = useSorting(history);
  const {
    [UrlParams.TEAM_ROLE_FILTER]: roleFilter = OrderItemType.PLAYER,
    [TABLE_SORT_FIELD_URL_PARAM]: field,
    [TABLE_SORT_ORDER_URL_PARAM]: order,
  } = getParams();

  useEffect(() => {
    setSortField(field as string);
    setSortOrder(order as SortOrder);
  }, [setSortOrder, setSortField, field, order, roleFilter]);

  useEffect(() => {
    if (!!selectedMembersTeam && selectedMembersTeam !== teamId) {
      setSelectedPlayers([]);
      setSelectedStaff([]);
    }
  }, [selectedMembersTeam, teamId, setSelectedPlayers, setSelectedStaff]);

  const fetchPlayersOrStaff = useCallback((payload?: any) => {
    if (teamId && roleFilter === OrderItemType.STAFF) {
      fetchStaff(teamId);
    } else if (teamId) {
      fetchPlayers(teamId, payload);
    }
  }, [teamId, fetchPlayers, roleFilter, fetchStaff]);

  const handleRowSelection = (id: number) => {
    setSelectedPlayers(xor(selectedPlayers as number[], [id]), teamId as number);
  };

  const handleStaffRowSelection = (id: number) => {
    setSelectedStaff(xor(selectedStaff as number[], [id]), teamId as number);
  };

  const handlePendingRowSelection = (id: string) => {
    setSelectedPendingStaff(xor(selectedPendingStaff, [id]), teamId as number);
  };

  const handleSelectAll = (ids: (number|string)[], pendingStaffIds?: string[]) => (
    e: FormEvent<HTMLInputElement>, data: CheckboxProps,
  ) => {
    e.stopPropagation();
    if (roleFilter === OrderItemType.STAFF) {
      setSelectedStaff(data.checked ? ids as number[] : [], teamId as number);
      setSelectedPendingStaff(data.checked
        && pendingStaffIds
        && !!pendingStaffIds.length ? pendingStaffIds : [], teamId as number);
    } else {
      setSelectedPlayers(data.checked ? ids as number[] : [], teamId as number);
    }
  };

  const [firstPlayerColumn, ...restPlayerColumns] = playerColumns;
  const enrichedPlayerColumns: Column[] = [{
    ...firstPlayerColumn,
    headerComponent: (sortProps) => (
      <div className={styles.headerComponent}>
        <Checkbox
          checked={playersById.every((id) => selectedPlayers.includes(id))}
          onChange={handleSelectAll(playersById)}
        />
        <Text bold>{translate('TEAM_MEMBER')}</Text>
        <SortArrows sortOrder={sortProps} />
      </div>
    ),
  },
  ...restPlayerColumns,
  ];

  const [firstStaffColumn, ...restStaffColumns] = staffColumns;
  const enrichedStaffColumns: Column[] = [{
    ...firstStaffColumn,
    headerComponent: () => (
      <div className={styles.headerComponent}>
        <Checkbox
          checked={staffById.every((id) => selectedStaff.includes(id))
            && pendingStaffById.every((id) => selectedPendingStaff.includes(id))}
          onChange={
            handleSelectAll([...staffById.map((id) => staffByHash[id].personId)], pendingStaffById)
          }
        />
        <Text bold>{translate('TEAM_MEMBER')}</Text>
      </div>
    ),
  },
  ...restStaffColumns,
  ];

  const renderPlayers = () => (
    <TableContainer
      columns={enrichedPlayerColumns}
      loading={loading || loadingInvite || playersLoading}
      count={playersCount}
      disableSearch
      fetch={fetchPlayersOrStaff}
      history={history}
      invertedHeader
      limit={PLAYERS_LIST_LIMIT}
      renderItems={playersById.map((id: number) => (
        <PlayerRow
          activeTeam={teamId}
          isSelected={selectedPlayers.includes(id)}
          key={id}
          onSelect={handleRowSelection}
          player={playersByHash[id]}
          resendInvitation={resendInvitationForPlayer}
          clubId={clubId}
        />
      ))}
    />
  );

  const renderStaff = () => (
    <TableContainer
      columns={enrichedStaffColumns}
      loading={loading || loadingInvite || staffLoading}
      count={0}
      disableSearch
      fetch={fetchPlayersOrStaff}
      history={history}
      invertedHeader
      renderItems={[
        ...staffById.map((id: number) => (
          <StaffRow
            isSelected={selectedStaff.includes(staffByHash[id].personId)}
            key={id}
            member={staffByHash[id]}
            onSelect={handleStaffRowSelection}
            resendInvitation={resendInvitationForMember}
            userRole={userRole}
            activeTeam={teamId}
          />
        )),
        ...pendingStaffById.map((id: string) => (
          <StaffRow
            isSelected={selectedPendingStaff.includes(pendingStaffByHash[id].invitationToken)}
            key={id}
            member={pendingStaffByHash[id]}
            pendingOnSelect={handlePendingRowSelection}
            resendInvitation={resendInvitationForMember}
            userRole={userRole}
            activeTeam={teamId}
          />
        )),
      ]}
    />
  );

  const renderTable = roleFilter === OrderItemType.STAFF ? renderStaff : renderPlayers;

  return (
    <>
      <RoleFilterTabs
        roleFilter={roleFilter as OrderItemType}
        staffActions={staffActions}
        playersActions={playersActions}
      />
      {renderTable()}
    </>
  );
};

export default PlayersAndStaffList;
