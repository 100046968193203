import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import queryString from 'query-string';
import pickBy from 'lodash/pickBy';

import { FETCH_MEMBERS_ERROR, FETCH_MEMBERS_REQUEST } from '../../constants/actionTypes';
import { FetchMembersRequestAction } from '../../models/actions';
import { fetchMembersError, fetchMembersSuccess } from '../../actions/members';
import serializeMember from '../../utils/serializers/serializeMember';
import { State } from '../../models/State';
import { MEMBERS_PATH } from '../../constants/paths';
import { Response, ListResponse } from '../../models/Response';
import { handleError } from '../../actions/errors';
import { PLAYERS_LIST_LIMIT } from '../../constants/customizations';
import { MemberResponse } from '../../models/ClubManagement';

export default (
  action$: ActionsObservable<FetchMembersRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_MEMBERS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { search, filters: { field, order, page } = { field: '', order: '', page: 1 } } },
    { authentication: { authToken }, application: { clubId } },
  ]) => {
    const query = queryString.stringify(pickBy({
      offset: (Number(page) - 1) * PLAYERS_LIST_LIMIT,
      limit: PLAYERS_LIST_LIMIT,
    }));

    return ajax({
      url: `${MEMBERS_PATH.replace('$clubId', `${clubId}`)}?${query}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authToken,
      },
      body: JSON.stringify(pickBy({
        ...((field && order) && { sort: { field, order } }),
        ...{ search },
      })),
    }).pipe(
      map(({ response: { data, count } }: Response<ListResponse<MemberResponse>>) => (
        fetchMembersSuccess(data.map(serializeMember), count)
      )),
      catchError((error: AjaxError) => [
        handleError(error, FETCH_MEMBERS_ERROR),
        fetchMembersError(error),
      ]),
    );
  }),
);
