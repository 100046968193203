import React, { FC, useEffect } from 'react';
import { Divider, Image } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import Header, { HeaderTypes } from '../../../../components/Header';
import styles from './ProductsImages.module.scss';
import { ProductsImagesProps } from './ProductsImagesProps';

const ProductsImages: FC<ProductsImagesProps> = ({
  clubId,
  fetchFile,
  filesByHash,
  images,
  originalImages,
}) => {
  const [translate] = useTranslation();

  useEffect(() => {
    images
      .filter(({ id }) => !filesByHash[id])
      .map(({ id, extension }) => fetchFile(id, extension));
  }, [fetchFile, filesByHash, images, clubId]);

  return (
    <div className={styles.wrapper}>
      <Header title={translate('IMAGE')} type={HeaderTypes.H3} />

      <div className={styles.imageContainer}>
        {images.map(({ id }) => (
          <div className={styles.imageWrapper} key={id}>
            <Image src={filesByHash[id]} />
          </div>
        ))}
      </div>
      {!!originalImages.length && !!images.length && <Divider />}
      {!!originalImages.length
                && (
                <div className={styles.imageContainer}>
                  {originalImages.map(imageUrl => (
                    <div className={styles.imageWrapper} key={imageUrl}>
                      <Image src={imageUrl} />
                    </div>
                  ))}
                </div>
                )
            }
    </div>
  );
};

export default ProductsImages;
