export default [{
  name: 'RED',
  hex: '#D00808',
}, {
  name: 'YELLOW',
  hex: '#FFD400',
}, {
  name: 'GREEN',
  hex: '#1BA912',
}, {
  name: 'BLUE',
  hex: '#001CB5',
}, {
  name: 'WHITE',
  hex: '#FFFFFF',
}, {
  name: 'BLACK',
  hex: '#000000',
}];
