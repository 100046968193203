import { RouteComponentProps } from 'react-router-dom';
import {
  Match, OneTimeEvent, Training,
} from '../../../../models/Calendar';
import { CreateEventRequestAction, UpdateEventRequestAction } from '../../../../models/actions';
import { TeamsByHash } from '../../../../models/Team';

export const NAME = 'name';
export const DESCRIPTION = 'description';
export const ADDRESS = 'address';
export const MEETING_TIME = 'meetingTime';
export const START_TIME = 'startTime';
export const END_TIME = 'endTime';
export const DAY = 'day';
export const DATE = 'date';
export const RESET_ATTENDANCE = 'resetAttendance';
export const INFORM_TEAM = 'informTeam';

export interface OneTimeEventModalProps extends RouteComponentProps {
  teamId: number;
  activeSlot?: Date;
  event: Match | Training | OneTimeEvent | null;
  teamsByHash: TeamsByHash;
  firstName: string | null;
  onClose: () => void;
  createEvent: (oneTimeEvent: OneTimeEvent) => CreateEventRequestAction;
  updateEvent: (oneTimeEvent: OneTimeEvent) => UpdateEventRequestAction;
}

export interface OneTimeEventFormSchema {
  [NAME]: string;
  [DESCRIPTION]: string;
  [MEETING_TIME]: Date | null;
  [START_TIME]: Date | null;
  [END_TIME]: Date | null;
  [ADDRESS]: string;
}

export const ONE_TIME_EVENT_FORM_DEFAULT_STATE: OneTimeEventFormSchema = {
  [NAME]: '',
  [MEETING_TIME]: null,
  [START_TIME]: null,
  [END_TIME]: null,
  [DESCRIPTION]: '',
  [ADDRESS]: '',
};

export interface InformTeamParams {
  [RESET_ATTENDANCE]: boolean;
  [INFORM_TEAM]: boolean;
}

export const INFORM_TEAM_PARAMS_DEFAULT_PROPS: InformTeamParams = {
  [RESET_ATTENDANCE]: false,
  [INFORM_TEAM]: false,
};
