import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import { FETCH_NUMBERS_SETTINGS_ERROR, FETCH_NUMBERS_SETTINGS_REQUEST } from '../../constants/actionTypes';
import { FetchNumbersSettingsRequestAction } from '../../models/actions';
import { Response } from '../../models/Response';
import { State } from '../../models/State';
import { fetchNumbersSettingsError, fetchNumbersSettingsSuccess } from '../../actions/orders';
import { FETCH_NUMBERS } from '../../constants/paths';
import { NumberSettings } from '../../models/Order';
import { handleError } from '../../actions/errors';

const fetchNumbersSettings = (
  action$: ActionsObservable<FetchNumbersSettingsRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_NUMBERS_SETTINGS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { orderId } },
    {
      authentication: { authToken },
    },
  ]) => ajax({
    url: FETCH_NUMBERS.replace('$orderId', String(orderId)),
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    map(({ response }: Response<NumberSettings>) => (
      fetchNumbersSettingsSuccess(response)
    )),
    catchError(error => [
      handleError(error, FETCH_NUMBERS_SETTINGS_ERROR),
      fetchNumbersSettingsError(error),
    ]),
  )),
);

export default fetchNumbersSettings;
