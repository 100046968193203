import { Column } from '../../../../components/Table/Column';

const columnsMobile: Column[] = [{
  size: 7,
  header: 'TEAM_MEMBER',
  align: 'left',
  key: 'firstName',
  sortable: true,
}, {
  size: 2,
  key: 'menu',
  align: 'right',
}];

export default columnsMobile;
