import { END_TIME, MatchFormSchema, START_TIME } from '../components/MatchEventModal/MatchEventModalProps';
import { TrainingFormSchema } from '../components/TrainingEventModal/TrainingEventModalProps';

export default (
  formState: MatchFormSchema | TrainingFormSchema,
  startDate?: Date,
  endDate?: Date,
) => {
  const startDateTime = new Date(startDate!);
  const endDateTime = new Date(endDate! || startDate!);
  startDateTime.setHours(formState[START_TIME]!.getHours());
  startDateTime.setMinutes(formState[START_TIME]!.getMinutes());
  endDateTime.setHours(formState[END_TIME]!.getHours());
  endDateTime.setMinutes(formState[END_TIME]!.getMinutes());

  return [startDateTime as Date, endDateTime as Date];
};
