import { useState } from 'react';
import params from '../../utils/params';
import SortingOrder from '../../constants/sortingOrder';
import { SortOrder } from '../../models/general/SortOrder';

export const SORT_FIELD_KEY = 'field';
export const SORT_ORDER_KEY = 'order';

const useSorting = (history: any) => {
  const [sortOrder, setSortOrder] = useState<SortOrder | undefined>(SortingOrder.DESC);
  const [sortField, setSortField] = useState<string | undefined>();
  const { setParams } = params(history);

  const reverseOrder = () => (sortOrder === SortingOrder.DESC
    ? SortingOrder.ASC : SortingOrder.DESC);

  const onSortChange = (field: string) => {
    if (sortField !== field) {
      setParams({
        [SORT_FIELD_KEY]: field,
        [SORT_ORDER_KEY]: SortingOrder.DESC,
      });
      setSortField(field);
      setSortOrder(SortingOrder.DESC);
    } else {
      const reversedOrder = reverseOrder();
      setSortOrder(reversedOrder);
      setParams({ [SORT_ORDER_KEY]: reversedOrder });
    }
  };

  return {
    sortOrder, sortField, setSortField, setSortOrder, onSortChange,
  };
};

export default useSorting;
