import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import EditRoleModal from './EditRoleModal';
import { editRolesMembersRequest } from '../../../../actions/members';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  editRolesMembers: bindActionCreators(editRolesMembersRequest, dispatch),
});

export default connect(null, mapDispatchToProps)(EditRoleModal);
