import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Loader, Dimmer } from 'semantic-ui-react';
import styles from './Table.module.scss';
import Text from '../Text';
import { TableProps } from './TableProps';
import SortArrows from './components/SortArrows';
import { TableFilter } from './components/TableFilter';

const renderEmptyElements = (text: string) => (
  <div className={styles.empty}>
    {text}
  </div>
);

const { Provider, Consumer } = React.createContext({});

const Table: FC<TableProps> = ({
  columns,
  children,
  emptyTableText,
  loading,
  loadingText,
  onSortChange,
  onFilterChange,
  filters,
  scrollable = false,
  sortOrder,
  sortField,
  invertedHeader,
  showHeaderOnEmpty = false,
}) => {
  const [translate] = useTranslation();
  const shouldRenderItems = children && (children as JSX.Element[]).length !== 0;
  const shouldRenderHeaderRow = (shouldRenderItems || showHeaderOnEmpty) && columns;
  const onSortClick = ({ sortable, key }: { sortable?: boolean, key?: string }) => () => (
    sortable && key && onSortChange && onSortChange(key)
  );

  return (
    <div className={classNames(styles.wrapper, scrollable && styles.scrollable)}>
      {shouldRenderHeaderRow && (
        <div className={classNames(styles.row, invertedHeader && styles.header)}>
          {columns.map(column => (
            <div
              onClick={onSortClick(column)}
              key={column.key || column.header}
              className={classNames(
                styles['column-name'],
                styles[`align-content-${column.align}`],
                column.size && styles[`flex-${column.size}`],
                column.noPadding && styles['no-padding'],
              )}
              style={{
                ...(column.width ? ({ width: `${column.width}` }) : ''),
              }}
            >
              <div className={classNames(styles['table-header'], column.sortable && styles.pointer)}>
                {column.headerComponent
                  ? column.headerComponent(sortField === column.key ? sortOrder : null)
                  : ([
                    <Text bold translate key={column.key}>{column.header}</Text>,
                    column.sortable && (
                      <SortArrows
                        sortOrder={sortField === column.key ? sortOrder : null}
                        key={`sort${column.key}`}
                      />
                    ),
                    column.filter && (
                      <TableFilter
                        key={`filter${column.key}`}
                        columnName={column.key as string}
                        component={column.filter}
                        onFilterChange={onFilterChange}
                        filters={filters}
                      />
                    ),
                  ])}
              </div>
            </div>
          ))}
        </div>
      )}

      <div className={styles.container}>
        {loading && <Dimmer active inverted className={styles.dimmer}><Loader /></Dimmer>}

        {shouldRenderItems
          ? <Provider value={columns}>{children}</Provider>
          : renderEmptyElements(translate((loading ? loadingText : emptyTableText) as string))}
      </div>
    </div>
  );
};

// @ts-ignore
Table.defaultProps = {
  emptyTableText: 'NO_ELEMENTS_AVAILABLE',
  columns: [],
  loading: false,
  loadingText: 'LOADING',
  invertedHeader: false,
};

export default Table;

export { Consumer };
