import { AjaxError } from 'rxjs/ajax';
import * as types from '../constants/actionTypes';
import {
  SetMenuExpandedAction,
  FetchTokenDetailsRequestAction,
  FetchTokenDetailsSuccessAction,
  FetchTokenDetailsErrorAction,
  SetApplicationParameterAction,
  ClearTeamIdAction,
  ValidateHobbyTeamTokenRequestAction,
  ValidateHobbyTeamTokenSuccessAction,
  ValidateHobbyTeamTokenErrorAction,
  AcceptInvitationRequestAction,
  AcceptInvitationSuccessAction,
  AcceptInvitationErrorAction,
  CheckEmailAvailabilityRequestAction,
  CheckEmailAvailabilitySuccessAction,
  CheckEmailAvailabilityErrorAction,
  SetUserConsentApprovedAction,
  FetchAuthUserRequestAction,
  FetchAuthUserSuccessAction,
  FetchAuthUserErrorAction,
  ResetUserDataAction,
} from '../models/actions';
import { Token } from '../models/Token';
import EmailAvailabilityResponse from '../models/general/EmailAvailabilityResponse';
import { ApplicationParameters } from '../models/Application';
import { HobbyTeamToken } from '../models/HobbyTeamToken';

export const resetUserData = (): ResetUserDataAction => ({
  type: types.RESET_USER_DATA,
});

export const checkEmailAvailabilityRequest = (
  email: string,
): CheckEmailAvailabilityRequestAction => ({
  type: types.CHECK_EMAIL_AVAILABILITY_REQUEST,
  payload: {
    email,
  },
});

export const checkEmailAvailabilitySuccess = (
  emailAvailability: EmailAvailabilityResponse,
): CheckEmailAvailabilitySuccessAction => ({
  type: types.CHECK_EMAIL_AVAILABILITY_SUCCESS,
  payload: {
    emailAvailability,
  },
});

export const checkEmailAvailabilityError = (
  error: AjaxError,
): CheckEmailAvailabilityErrorAction => ({
  type: types.CHECK_EMAIL_AVAILABILITY_ERROR,
  payload: {
    error,
  },
});

export const setMenuExpanded = (value: boolean): SetMenuExpandedAction => ({
  type: types.SET_MENU_EXPANDED,
  payload: {
    value,
  },
});

export const setUserConsentApproved = (value: boolean): SetUserConsentApprovedAction => ({
  type: types.SET_USER_CONSENT_APPROVED,
  payload: {
    value,
  },
});

export const setApplicationParameters = (
  params: ApplicationParameters,
): SetApplicationParameterAction => ({
  type: types.SET_APPLICATION_PARAMETERS,
  payload: {
    params,
  },
});

export const fetchTokenDetailsRequest = (token: string): FetchTokenDetailsRequestAction => ({
  type: types.FETCH_TOKEN_DETAILS_REQUEST,
  payload: {
    token,
  },
});

export const fetchTokenDetailsSuccess = (tokenDetails: Token): FetchTokenDetailsSuccessAction => ({
  type: types.FETCH_TOKEN_DETAILS_SUCCESS,
  payload: {
    tokenDetails,
  },
});

export const fetchAuthUserRequest = (authToken: string): FetchAuthUserRequestAction => ({
  type: types.FETCH_AUTH_USER_REQUEST,
  payload: {
    authToken,
  },
});

export const fetchAuthUserSuccess = (): FetchAuthUserSuccessAction => ({
  type: types.FETCH_AUTH_USER_SUCCESS,
});

export const fetchAuthUserError = (error: AjaxError): FetchAuthUserErrorAction => ({
  type: types.FETCH_AUTH_USER_ERROR,
  payload: {
    error,
  },
});

export const fetchTokenDetailsError = (error: AjaxError): FetchTokenDetailsErrorAction => ({
  type: types.FETCH_TOKEN_DETAILS_ERROR,
  payload: {
    error,
  },
});

export const acceptInvitationRequest = (token: string): AcceptInvitationRequestAction => ({
  type: types.ACCEPT_INVITATION_REQUEST,
  payload: {
    token,
  },
});

export const acceptInvitationSuccess = (token: string): AcceptInvitationSuccessAction => ({
  type: types.ACCEPT_INVITATION_SUCCESS,
  payload: {
    token,
  },
});

export const acceptInvitationError = (error: AjaxError): AcceptInvitationErrorAction => ({
  type: types.ACCEPT_INVITATION_ERROR,
  payload: {
    error,
  },
});

export const validateHobbyTeamTokenRequest = (
  token: string,
): ValidateHobbyTeamTokenRequestAction => ({
  type: types.VALIDATE_HOBBY_TEAM_TOKEN_REQUEST,
  payload: {
    token,
  },
});

export const validateHobbyTeamTokenSuccess = (
  tokenDetails: HobbyTeamToken,
): ValidateHobbyTeamTokenSuccessAction => ({
  type: types.VALIDATE_HOBBY_TEAM_TOKEN_SUCCESS,
  payload: {
    tokenDetails,
  },
});

export const validateHobbyTeamTokenError = (
  error: AjaxError,
): ValidateHobbyTeamTokenErrorAction => ({
  type: types.VALIDATE_HOBBY_TEAM_TOKEN_ERROR,
  payload: {
    error,
  },
});

export const clearTeamId = (): ClearTeamIdAction => ({
  type: types.CLEAR_TEAM_ID,
});
