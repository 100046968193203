import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { State } from '../../../../models/State';
import ProductsList from './ProductsList';
import groupArticlesByProducts from '../../methods/groupArticlesByProducts';
import { addItemsForPlayers, toggleAllProductsSelection, addItemsForSelectedStaffMembers } from '../../../../actions/orderEditor';

const mapStatesToProps = ({
  catalog: {
    catalog: {
      productsByHash = {},
    },
    loading,
  },
  orders,
}: State) => ({
  loading,
  productsByHash,
  groupedArticles: groupArticlesByProducts(orders.itemsById, orders.itemsByHash),
  selected: orders.selectedProductsIds,
  productsByItemId: orders.productsByItemId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleAllSelection: bindActionCreators(toggleAllProductsSelection, dispatch),
  addItemsForPlayers: bindActionCreators(addItemsForPlayers, dispatch),
  addItemsForStaff: bindActionCreators(addItemsForSelectedStaffMembers, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(ProductsList);
