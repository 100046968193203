import { connect } from 'react-redux';
import TrainingEventForm from './TrainingEventForm';
import State from '../../../../models';

const mapStateToProps = ({ calendar: { loading, event } }: State) => ({
  loading,
  event,
});

export default connect(mapStateToProps)(TrainingEventForm);
