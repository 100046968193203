import React, { SyntheticEvent } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { useParams } from 'react-router-dom';
import {
  Checkbox,
  CheckboxProps,
  DropdownItemProps, Form,
  Image, Input, InputOnChangeData,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import DropdownWithLock from '../../../../../../components/DropdownWithLock';
import InputWithLock from '../../../../../../components/InputWithLock';
import PriceDiscountsPopup from '../../../../../ProductDetails/components/PriceDiscountsPopup';
import ProfileAvatar from '../../../../../PlayerProfile/components/ProfileAvatar';
import Text from '../../../../../../components/Text';
import getMissingProperties from '../../../../methods/getMissingProperties';
import styles from './MobileItem.module.scss';
import useImage from '../../../../../../hooks/useImage';
import { MobileItemProps } from './MobileItemProps';
import { LockedProperties } from '../../../../../../constants/lockedProperties';
import { OrderItemType } from '../../../../OrderPreparationProps';
import { OrderStatus } from '../../../../../../constants/orderStatus';
import {
  Embellishment, EmbellishmentTypes, EmbellishmentValueType, ItemEmbellishment,
} from '../../../../../../models/Embellishment';
import Confirm from '../../../../../../components/Confirm';
import { EmbellishmentGroup } from '../../embellishmentTypes';

const MobileItem = ({
  changeProperty,
  clubId,
  embellishmentsByHash,
  embellishmentsColumns,
  fetchFile,
  filesByHash,
  item,
  itemType,
  lockedNumbers,
  orderStatus,
  productsByHash,
  usedNumbers,
  member,
  removeItems,
  member: { firstName, lastName },
  product: {
    name, files, originalImages, availableSizes,
    embellishments: productEmbellishments, originalPrice, discountPercent,
  },
}: MobileItemProps) => {
  const [img] = useImage(files, originalImages, filesByHash, fetchFile);
  const [translate] = useTranslation();
  const teamId = +get(useParams(), 'teamId');
  const { embellishments } = item;
  const playerNameTypes = [EmbellishmentTypes.PLAYER_NAME, EmbellishmentTypes.SMALL_PLAYER_NAME];
  const itemEmbellishments: Embellishment[] = productEmbellishments
    .filter(({ embellishment }) => embellishments.some(({ type }) => type === embellishment))
    .map(({ embellishment }) => embellishmentsByHash[embellishment]);

  const remove = () => {
    removeItems([item.externalId]);
  };

  const changeQuantity = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    changeProperty(item.externalId, 'quantity', +value);
  };

  const changeMaxQuantity = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    changeProperty(item.externalId, 'maxQuantity', +value);
  };

  const changeSize = (event: SyntheticEvent, { text }: DropdownItemProps) => {
    changeProperty(item.externalId, 'size', text as string);
  };

  const toggleItemLock = (property: LockedProperties) => () => {
    changeProperty(item.externalId, property, !item[property]);
  };

  const changeEmbellishment = (
    embellishmentTypes: EmbellishmentTypes[], property: 'value' | 'locked', value: any,
  ) => {
    const indexes = embellishments.reduce((a: number[], e: ItemEmbellishment, i: number) => {
      if (embellishmentTypes.includes(e.type)) {
        a.push(i);
      }
      return a;
    }, []);

    const enrichedEmbellishments: ItemEmbellishment[] = JSON.parse(
      JSON.stringify(embellishments),
    );
    indexes.forEach(index => {
      enrichedEmbellishments[index][property] = value;
    });

    changeProperty(item.externalId, 'embellishments', enrichedEmbellishments);
  };

  const changeEmbellishmentValue = (
    embellishmentType: EmbellishmentTypes, valueType: EmbellishmentValueType,
  ) => (event: SyntheticEvent, data: InputOnChangeData | DropdownItemProps | CheckboxProps) => {
    const value = data.value || data.text || data.checked;
    const embellishmentValue = valueType === EmbellishmentValueType.INTEGER ? +value : value;

    let embellishmentTypes;
    switch (embellishmentType) {
      case EmbellishmentTypes.BACK_NUMBER:
      case EmbellishmentTypes.SMALL_NUMBER:
        embellishmentTypes = EmbellishmentGroup.NUMBER_TYPES;
        break;
      case EmbellishmentTypes.PLAYER_NAME:
      case EmbellishmentTypes.SMALL_PLAYER_NAME:
        embellishmentTypes = playerNameTypes;
        break;
      default:
        embellishmentTypes = [embellishmentType];
    }

    changeEmbellishment(embellishmentTypes, 'value', embellishmentValue);
  };

  const toggleLockedEmbellishment = (embellishmentType: EmbellishmentTypes) => () => {
    const embellishment = embellishments.find(({ type }) => type === embellishmentType);

    changeEmbellishment([embellishmentType], 'locked', !(embellishment && embellishment.locked));
  };

  const showSizeField = availableSizes && availableSizes.some(Boolean);
  const missingProps = getMissingProperties([item], productsByHash);
  const showDangerState = orderStatus === OrderStatus.SENT_TO_TEAM && !!missingProps.length;
  const isNumberLabelInvalid = showDangerState && missingProps.includes('number');
  const isInitialsLabelInvalid = showDangerState && missingProps.includes('initialsLabel');
  const isSizeInvalid = showDangerState && missingProps.includes('size');

  const renderEmpty = (key?: number | string) => (
    <div className={styles.placeholder} key={key} />
  );

  const renderNumberField = ({ type, value, locked }: ItemEmbellishment) => (
    <Form.Field className={classNames(styles.formField)} key={type}>
      <label className={styles.label}>
        {translate(type)}
      </label>
      <DropdownWithLock
        error={isNumberLabelInvalid}
        isLocked={!!locked}
        key={type}
        value={value as string | number | undefined || ''}
        toggleLocked={toggleLockedEmbellishment(type)}
        options={[...Array(100).keys()].filter(num => (
          !lockedNumbers.includes(num)
          && (!usedNumbers[num] || usedNumbers[num] === item.playerId)
        )).map(num => ({
          key: num,
          text: num,
          onClick: changeEmbellishmentValue(type, EmbellishmentValueType.INTEGER),
        }))}
      />
    </Form.Field>
  );

  const renderCheckboxField = (
    { type, value, mandatory }: any,
    valueType: EmbellishmentValueType,
  ) => {
    const catalogMatch = productEmbellishments.find(pce => pce.embellishment === type);

    return (
      <Form.Field className={classNames(styles.formField)} key={type}>
        <label className={styles.label}>
          {translate(type)}
        </label>
        <Checkbox
          toggle
          disabled={(catalogMatch && catalogMatch.mandatory) || false}
          key={type}
          checked={mandatory ? true : value}
          onChange={changeEmbellishmentValue(type, valueType)}
        />
      </Form.Field>
    );
  };

  const renderInputField = (
    { type, value, locked }: ItemEmbellishment,
    valueType: EmbellishmentValueType,
  ) => (
    <Form.Field className={classNames(styles.formField)} key={type}>
      <label className={styles.label}>
        {translate(type)}
      </label>
      <InputWithLock
        error={isInitialsLabelInvalid}
        isLocked={!!locked}
        key={type}
        value={value}
        onChange={changeEmbellishmentValue(type, valueType)}
        toggleLocked={toggleLockedEmbellishment(type)}
        type={valueType === EmbellishmentValueType.INTEGER ? 'number' : 'text'}
        {...(playerNameTypes.includes(type) && { value: value || '' })}
      />
    </Form.Field>
  );

  const renderEmbellishment = (type: EmbellishmentTypes) => {
    const itemEmbellishment = embellishments.find(embellishment => embellishment.type === type);
    const { valueType } = embellishmentsByHash[type];

    if (!itemEmbellishment) {
      return renderEmpty(type);
    }

    if (EmbellishmentGroup.NUMBER_TYPES.includes(type)) {
      return renderNumberField(itemEmbellishment);
    }
    if (EmbellishmentGroup.BOOLEAN_TYPES.includes(type)) {
      return renderCheckboxField(itemEmbellishment, valueType);
    }
    return renderInputField(itemEmbellishment, valueType);
  };

  return (
    <div key={item.id} className={classNames(styles.wrapper)}>
      <Confirm
        confirmText={translate('ARE_YOU_SURE_TO_REMOVE_ITEM')}
        onConfirm={remove}
        icon="trash alternate outline"
        basic
        className={classNames('simple', styles.removeIcon)}
        primary
      />
      <div className={styles.teamMember}>
        {itemType === OrderItemType.PRODUCT ? [
          <div className={styles.img} key="img">
            {!!img && <Image src={img} className={styles.image} />}
          </div>,
          <Text bold key="text">{name}</Text>,
        ] : [
          <ProfileAvatar user={member} key="avatar" />,
          <Text bold key="text">{`${firstName} ${lastName}`}</Text>,
        ]}
      </div>
      <div className={styles.price}>
        {itemType === OrderItemType.PRODUCT && clubId ? (
          <PriceDiscountsPopup
            clubId={clubId}
            teamId={teamId}
            productEmbellishments={productEmbellishments}
            embellishments={itemEmbellishments}
            price={originalPrice}
            productDiscountPercent={discountPercent}
            underline={false}
          />
        ) : null}
      </div>
      <Form.Field className={classNames(styles.formField)}>
        <label className={styles.label}>
          {translate('QUANTITY')}
        </label>
        <InputWithLock
          isLocked={item.quantityLocked}
          max={item.maxQuantity}
          min={1}
          onChange={changeQuantity}
          toggleLocked={toggleItemLock(LockedProperties.quantityLocked)}
          type="number"
          value={item.quantity}
        />
      </Form.Field>
      <Form.Field className={classNames(styles.formField)}>
        <label className={styles.label}>
          {translate('MAX_QUANTITY')}
        </label>
        <Input
          className="full"
          min={item.quantity}
          onChange={changeMaxQuantity}
          type="number"
          value={item.maxQuantity}
        />
      </Form.Field>
      <Form.Field className={classNames(styles.formField)}>
        <label className={styles.label}>
          {translate('SIZE')}
        </label>
        {!showSizeField ? renderEmpty() : (
          <DropdownWithLock
            error={isSizeInvalid}
            isLocked={item.sizeLocked}
            toggleLocked={toggleItemLock(LockedProperties.sizeLocked)}
            value={item.size}
            options={availableSizes.map(size => ({
              key: size,
              text: size,
              onClick: changeSize,
            }))}
          />
        )}
      </Form.Field>
      {embellishmentsColumns.map(renderEmbellishment) as any}
    </div>
  );
};

export default MobileItem;
