import { AjaxError } from 'rxjs/ajax';
import {
  DFB_CLUBS_ERROR,
  DFB_CLUBS_REQUEST,
  DFB_CLUBS_SUCCESS,
} from '../../constants/actionTypes';

import {
  DfbClubErrorAction,
  DfbClubRequestAction,
  DfbClubSuccessAction,
} from '../../models/actions/externalSystem';
import { OfficialClubDto } from '../../models/externalSystemIntegration';

export const dfbClubsRequest = (
  searchTerm: string,
): DfbClubRequestAction => ({
  type: DFB_CLUBS_REQUEST,
  payload: {
    searchTerm,
  },
});

export const dfbClubsSuccess = (
  clubs: OfficialClubDto[],
): DfbClubSuccessAction => ({
  type: DFB_CLUBS_SUCCESS,
  payload: {
    clubs,
  },
});

export const dfbClubsError = (error: AjaxError): DfbClubErrorAction => ({
  type: DFB_CLUBS_ERROR,
  payload: {
    error,
  },
});
