import { Column } from '../../../../components/Table/Column';

const columns: Column[] = [{
  width: '45%',
  header: 'ARTICLES',
  align: 'left',
  key: 'name',
}, {
  width: '15%',
  header: 'PRICE_INCL_OPTIONAL_EMBELLISHMENT',
  align: 'left',
  key: 'vk',
}, {
  width: '15%',
  header: 'OPTIONAL_EMBELLISHMENTS_AVAILABLE',
  align: 'left',
  key: 'optionals',
}, {
  width: '10%',
  header: 'QUANTITY',
  align: 'left',
  key: 'amount',
}, {
  width: '10%',
  header: 'MAX_QUANTITY',
  align: 'left',
  key: 'maxAmount',
}, {
  width: '5%',
  key: 'menu',
  align: 'right',
}];

export default columns;
