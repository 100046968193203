import { AjaxError } from 'rxjs/ajax';
import {
  TOR24_TEAMS_ERROR,
  TOR24_TEAMS_REQUEST, TOR24_TEAMS_SUCCESS,
} from '../../constants/actionTypes';

import {
  Tor24TeamsErrorAction,
  Tor24TeamsRequestAction,
  Tor24TeamsSuccessAction,
} from '../../models/actions/externalSystem';
import { Tor24Team } from '../../models/externalSystemIntegration/tor24Team';

export const tor24TeamsRequest = (
  tor24ClubId: string,
): Tor24TeamsRequestAction => ({
  type: TOR24_TEAMS_REQUEST,
  payload: {
    tor24ClubId,
  },
});

export const tor24TeamsSuccess = (
  teams: { response: Tor24Team[] },
): Tor24TeamsSuccessAction => ({
  type: TOR24_TEAMS_SUCCESS,
  payload: {
    teams,
  },
});

export const tor24TeamsError = (error: AjaxError): Tor24TeamsErrorAction => ({
  type: TOR24_TEAMS_ERROR,
  payload: {
    error,
  },
});
