import React, {
  FC, useMemo, useState, useCallback, useEffect, FormEvent,
} from 'react';
import difference from 'lodash/difference';
import xor from 'lodash/xor';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Checkbox } from 'semantic-ui-react';
import styles from './ImportMatchModal.module.scss';
import { ImportMatchModalProps } from './ImportMatchModalProps';
import Table from '../../../../components/Table';
import Text from '../../../../components/Text';
import columns from './columns';
import Row from '../../../../components/Table/components/Row';
import TableCheckboxHeader from '../../../../components/TableCheckboxHeader';
import { formatDate, TIME_FORMAT } from '../../../../utils/dateUtils';
import { MatchData } from '../../../../models/Calendar';
import convertMatch from '../../methods/convertMatch';

const ImportMatchModal: FC<ImportMatchModalProps> = ({
  setModalState,
  fetchMatches,
  createEvents,
  resetEventCreated,
  loading,
  eventsCreated,
  externalMatchesById,
  matches,
  teamId,
}) => {
  const [translate] = useTranslation();
  const [selectedMatches, setSelectedMatches] = useState<string[]>(externalMatchesById);
  const [firstColumn, ...restColumns] = columns;

  const importMatches = () => {
    const matchesToCreate = matches
      .filter(match => match.externalId && selectedMatches.includes(match.externalId))
      .map(m => convertMatch(m, teamId));
    createEvents(matchesToCreate);
  };

  useEffect(() => {
    if (externalMatchesById.length) {
      setSelectedMatches(externalMatchesById);
    }
  }, [externalMatchesById, setSelectedMatches]);

  useEffect(() => {
    if (teamId) {
      fetchMatches(teamId);
    }
  }, [fetchMatches, teamId]);

  const isSelected = (id: string) => selectedMatches.some(matchId => matchId === id);

  const handleCheckboxChange = (id: string) => (event: FormEvent<HTMLInputElement>) => {
    event.stopPropagation();
    setSelectedMatches(items => xor(items, [id]));
  };

  const toggleAllSelection = useCallback(() => {
    setSelectedMatches(selectedItems => (difference(externalMatchesById, selectedItems).length
      ? externalMatchesById : []));
  }, [setSelectedMatches, externalMatchesById]);

  const enrichedColumns = useMemo(() => [
    {
      ...firstColumn,
      headerComponent: () => (
        <TableCheckboxHeader
          title={firstColumn.header || ''}
          onClick={toggleAllSelection}
          checked={!difference(externalMatchesById, selectedMatches).length}
          checkbox
        />
      ),
    },
    ...restColumns,
  ], [firstColumn, restColumns, externalMatchesById, selectedMatches, toggleAllSelection]);

  const renderItems = () => matches.map(
    ({
      externalId, homeTeamName, awayTeamName, startDateTime,
    }: MatchData) => (
      <Row
        key={externalId}
        classNames={styles.wrapper}
        itemOnClick={handleCheckboxChange(externalId!)}
      >
        <div className={styles.matchName}>
          <Checkbox
            checked={isSelected(externalId!)}
            onChange={handleCheckboxChange(externalId!)}
          />
          <Text bold>{`${homeTeamName} vs ${awayTeamName}`}</Text>
        </div>
        <Text>{`${formatDate(startDateTime)} ${translate('AT_HOUR')} ${moment(startDateTime).format(TIME_FORMAT)}`}</Text>
      </Row>
    ),
  );

  const onClose = useCallback(() => {
    setModalState(false);
  }, [setModalState]);

  useEffect(() => {
    if (eventsCreated) {
      onClose();
    }
  }, [eventsCreated, onClose]);

  useEffect(() => () => resetEventCreated(), [resetEventCreated]);

  return (
    <Modal
      open
      key="import-match-modal"
      style={{ width: '1000px' }}
      onClose={onClose}
      closeIcon
    >
      <div className={styles.modalContent}>
        <h2 className={styles.header}>{translate('IMPORT_SEASON_GAMES')}</h2>
        <Table
          columns={enrichedColumns}
          loading={loading}
          invertedHeader
          emptyTableText={translate('NO_FUTURE_MATCHES_FOUND')}
        >
          {renderItems()}
        </Table>
        <div className={styles.actionButtons}>
          <Button
            basic
            circular
            content={translate('CANCEL')}
            fluid
            onClick={onClose}
            primary
          />
          <Button
            circular
            content={translate('SAVE')}
            disabled={!selectedMatches.length}
            fluid
            onClick={importMatches}
            primary
          />
        </div>
      </div>
    </Modal>
  );
};

export default ImportMatchModal;
