import get from 'lodash/get';

import { Catalog, EmbellishmentFixedPriceDiscountType, TeamDiscountsType } from '../../models/Catalog';
import { Embellishment, ProductEmbellishment } from '../../models/Embellishment';
import { Price } from '../../models/Price';
import { calculatePriceWithQuantity } from '../PricingUtil';

const getFixedPrice = (
  groupId: number | null,
  embellishment: Embellishment | null,
  embellishmentFixedPriceDiscounts: EmbellishmentFixedPriceDiscountType[],
) => {
  const discount = embellishmentFixedPriceDiscounts
    .find(fixedPriceDiscount => (
      fixedPriceDiscount.groupId === groupId
      && fixedPriceDiscount.embellishment === (embellishment && embellishment.type)
    ));
  return get(discount, 'price.amount', null);
};

// returns quantity aware embellishment discounted price
// if global or team embellishment discount for specific item was defined
const calculatePriceForNonFixedPriceDiscounts = (
  teamId: number | null,
  teamEmbellishmentsDiscounts: TeamDiscountsType[],
  embellishmentsDiscountPercent: number,
  embellishmentPrice: Price | null,
  embellishmentQuantity: number,
) => {
  const teamDiscount = teamEmbellishmentsDiscounts.find(
    ({ teamId: id }) => teamId === id,
  );
  const discountPercent = get(teamDiscount, 'discountPercent', embellishmentsDiscountPercent);
  const { amount = 0 } = embellishmentPrice || {};

  return calculatePriceWithQuantity(amount, embellishmentQuantity, discountPercent);
};

// returns quantity aware embellishment fixed price if such discount is defined
const calculatePriceForFixedPriceDiscounts = (
  clubId: number,
  teamId: number | null,
  embellishment: Embellishment | null,
  embellishmentFixedPriceDiscounts: EmbellishmentFixedPriceDiscountType[],
  productEmbellishment?: ProductEmbellishment | null,
) => {
  const embellishmentQuantity = get(productEmbellishment, 'quantity', 1);

  const fixedPrice = get(productEmbellishment, 'fixedPricePerUnit.amount', null)
  || getFixedPrice(teamId, embellishment, embellishmentFixedPriceDiscounts)
  || getFixedPrice(clubId, embellishment, embellishmentFixedPriceDiscounts);

  return fixedPrice ? calculatePriceWithQuantity(fixedPrice, embellishmentQuantity) : null;
};

const calculateEmbellishmentPrice = (
  clubId: number,
  teamId: number | null,
  embellishment: Embellishment | null,
  catalog?: Catalog,
  productEmbellishment?: ProductEmbellishment | null,
) => {
  const embellishmentQuantity = get(productEmbellishment, 'quantity', 1);
  const { price = null } = embellishment || {};
  const {
    embellishmentsDiscountPercent = 0,
    teamEmbellishmentsDiscounts = [],
    embellishmentFixedPriceDiscounts = [],
  } = catalog || {};

  return calculatePriceForFixedPriceDiscounts(
    clubId,
    teamId,
    embellishment,
    embellishmentFixedPriceDiscounts,
    productEmbellishment,
  ) || calculatePriceForNonFixedPriceDiscounts(
    teamId,
    teamEmbellishmentsDiscounts,
    embellishmentsDiscountPercent,
    price,
    embellishmentQuantity,
  );
};

export default calculateEmbellishmentPrice;
