import React, { useMemo } from 'react';
import { Button, Checkbox, Label } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import ProfileAvatar from '../../../PlayerProfile/components/ProfileAvatar';
import Row from '../../../../components/Table/components/Row';
import Text from '../../../../components/Text';
import getMissingProperties from '../../methods/getMissingProperties';
import styles from './DesktopMemberRow.module.scss';
import { DesktopMemberRowProps } from './DesktopMemberRowProps';
import { OrderStatus } from '../../../../constants/orderStatus';
import { OrderItemType } from '../../OrderPreparationProps';
import { useViewport } from '../../../../hooks/useViewport';

const DesktopMemberRow = ({
  member: {
    id, firstName, lastName, number, preferredPosition, teamNumber,
  },
  member,
  checked,
  togglePlayerSelection,
  toggleStaffSelection,
  items,
  showDetails,
  productsByHash,
  teamId,
  orderStatus,
  usedNumbersInTeamOrder,
  itemType,
}: DesktopMemberRowProps) => {
  const { isMobile } = useViewport();
  const [translate] = useTranslation();

  const isTypePlayer = itemType === OrderItemType.PLAYER;

  const onClick = () => (
    isTypePlayer
      ? togglePlayerSelection(id, teamId)
      : toggleStaffSelection(id, teamId)
  );

  const missingProperties = orderStatus === OrderStatus.SENT_TO_TEAM
    && !!getMissingProperties(items, productsByHash).length;

  const orderShirtNumber = useMemo(() => Object.keys(usedNumbersInTeamOrder).find(shirtNumber => usedNumbersInTeamOrder[+shirtNumber] === id) || '',
    [usedNumbersInTeamOrder, id]);

  const playerNumber = useMemo(() => orderShirtNumber || teamNumber || '', [orderShirtNumber, teamNumber]);

  return (
    <Row key={id} classNames={[styles.wrapper, missingProperties ? styles.danger : ''].join(' ')}>
      <div className={styles.teamMember}>
        <Checkbox onClick={onClick} checked={checked} />
        {!isMobile && <ProfileAvatar user={member} />}
        <Text>
          {`${firstName} ${lastName}`}
        </Text>
      </div>
      {isTypePlayer ? <Text>{preferredPosition ? translate(preferredPosition) : ''}</Text> : null}
      {isTypePlayer ? <Text>{playerNumber ? `#${playerNumber}` : ''}</Text> : null}
      {isTypePlayer ? <Text>{number ? `#${number}` : ''}</Text> : null}
      <div className={styles.icons}>
        {!!items.length && <Label circular className="badge">{items.length}</Label>}
      </div>
      <div className={styles.rowActionsWrapper}>
        <Button basic primary className="simple" icon="angle right" onClick={showDetails} />
      </div>
    </Row>
  );
};

export default DesktopMemberRow;
