import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { push } from 'connected-react-router';

import { Player } from '../../models/Player';
import { Response } from '../../models/Response';
import { State } from '../../models/State';
import { TEAM_PLAYER_PATH } from '../../constants/paths';
import { UPDATE_PLAYER_ERROR, UPDATE_PLAYER_REQUEST, UPDATE_PLAYER_SUCCESS } from '../../constants/actionTypes';
import { NotificationType, UpdatePlayerRequestAction } from '../../models/actions';
import { updatePlayerError, updatePlayerSuccess } from '../../actions/players';
import { PLAYER_PROFILE_ROUTE } from '../../constants/routes';
import { handleError } from '../../actions/errors';
import { addNotification } from '../../actions/notifications';

const updatePlayer = (
  action$: ActionsObservable<UpdatePlayerRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(UPDATE_PLAYER_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      teamId, playerId, player,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: TEAM_PLAYER_PATH
      .replace('$teamId', `${teamId}`)
      .replace('$playerId', `${playerId}`),
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify(player),
  }).pipe(
    mergeMap(({ response }: Response<Player>) => [
      addNotification(NotificationType.SUCCESS, UPDATE_PLAYER_SUCCESS),
      updatePlayerSuccess(response),
      push(
        PLAYER_PROFILE_ROUTE
          .replace(':teamId', String(teamId))
          .replace(':playerId', String(playerId)),
      ),
    ]),
    catchError((error: AjaxError) => {
      let errorMessageKey;
      switch (error.response.message) {
        case 'email.already_in_use':
          errorMessageKey = 'UPDATE_PLAYER_EMAIL_IN_USE_ERROR';
          break;
        case 'nickname.already_in_use':
          errorMessageKey = 'UPDATE_PLAYER_NICKNAME_IN_USE_ERROR';
          break;
        case 'number.already_in_use':
        case 'number.invalid.out_of_range':
          errorMessageKey = 'UPDATE_PLAYER_NUMBER_IN_USE_ERROR';
          break;
        default:
          errorMessageKey = UPDATE_PLAYER_ERROR;
          break;
      }

      return [
        handleError(error, errorMessageKey),
        updatePlayerError(error),
      ];
    }),
  )),
);

export default updatePlayer;
