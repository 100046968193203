import pickBy from 'lodash/pickBy';
import queryString from 'query-string';
import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import { FETCH_EVENTS_ERROR, FETCH_EVENTS_REQUEST } from '../../constants/actionTypes';
import { FetchEventsRequestAction } from '../../models/actions';
import { ListResponse, Response } from '../../models/Response';
import { State } from '../../models/State';
import { EVENTS_LIST } from '../../constants/paths';
import { EventListData } from '../../models/Calendar';
import { fetchEventsSuccess, fetchEventsError } from '../../actions/calendar';
import { handleError } from '../../actions/errors';
import serializeEventList from '../../utils/serializers/serializeEventList';

const FROM_DATE_PARAM = 'from';
const TO_DATE_PARAM = 'to';

const fetchOrders = (
  action$: ActionsObservable<FetchEventsRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_EVENTS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { teamId, start, end } },
    {
      authentication: { authToken },
    },
  ]) => {
    const query = queryString.stringify(pickBy({
      [FROM_DATE_PARAM]: start.getTime(),
      [TO_DATE_PARAM]: end.getTime(),
    }));

    return ajax({
      url: `${EVENTS_LIST}?${query}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authToken,
      },
      body: JSON.stringify({ teamId }),
    }).pipe(
      map(({ response: { data: events, count } }: Response<ListResponse<EventListData>>) => (
        fetchEventsSuccess(serializeEventList(events), count)
      )),
      catchError(error => [
        handleError(error, FETCH_EVENTS_ERROR),
        fetchEventsError(error),
      ]),
    );
  }),
);

export default fetchOrders;
