import { FETCH_FILE_SUCCESS, REMOVE_FILE } from '../constants/actionTypes';
import { FileActions } from '../models/actions';
import { FilesReducer } from '../models/File';

const initialState: FilesReducer = {
  byHash: {},
  byId: [],
};

export default (state = initialState, action: FileActions) => {
  switch (action.type) {
    case FETCH_FILE_SUCCESS: {
      const { id, file } = action.payload;

      return {
        ...state,
        byHash: {
          ...state.byHash,
          [id]: file,
        },
        byId: [
          id,
          ...state.byId.filter(fileId => fileId !== id),
        ],
      };
    }
    case REMOVE_FILE: {
      const { fileId } = action.payload;
      const { [fileId]: removedFile, ...updatedByHash } = state.byHash;

      return {
        ...state,
        byHash: updatedByHash,
        byId: state.byId.filter(id => id !== fileId),
      };
    }
    default:
      return state;
  }
};
