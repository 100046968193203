import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { signOut } from '../../../../packages/authentication/actions';
import { State } from '../../../../models/State';
import AuthenticatedMenuItem from './AuthenticatedMenuItem';
import { User } from '../../../../models/general/User';
import { Roles } from '../../../../constants/roles';

const mapStateToProps = ({
  application: { userRole },
  user: { user },
}: State) => ({
  user: user || {} as User,
  userRole: userRole || Roles.TEAM_LEAD,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ signOut }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedMenuItem);
