import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AssignToTeamModal from '../AssignToTeamModal';
import Confirm from '../../../../components/Confirm';
import styles from './TeamsFooter.module.scss';
import { TeamsFooterProps } from './TeamsFooterProps';
import { OrderItemType } from '../../../OrderPreparation/OrderPreparationProps';
import FooterSelectedNumber from '../../../OrderPreparation/components/DesktopFooter/FooterSelectedNumber';

const TeamsFooter: FC<TeamsFooterProps> = ({
  activeTeam,
  deleteMembers,
  selectedStaff,
  selectedPlayer,
  showAssignModal = false,
  selectedPendingStaff,
  roleFilter = OrderItemType.PLAYER,
  bulkDeactivateToken,
  staffByHash,
  pendingStaffMembersByHash,
  playersByHash,
}) => {
  const [translate] = useTranslation();
  const [selectedPlayerPersonIds, setSelectedPlayerPersonIds] = useState<number[]>([]);
  const [selectedStaffPersonIds, setSelectedStaffPersonIds] = useState<number[]>([]);

  const handleDeletePlayers = () => {
    if (activeTeam) {
      if (roleFilter === OrderItemType.STAFF && !!selectedStaffPersonIds.length) {
        deleteMembers(selectedStaffPersonIds, activeTeam);
      } else if (roleFilter === OrderItemType.PLAYER && !!selectedPlayerPersonIds.length) {
        deleteMembers(selectedPlayerPersonIds, activeTeam);
      }
      if (selectedPendingStaff.length) {
        bulkDeactivateToken(
          selectedPendingStaff.map(id => pendingStaffMembersByHash[id].invitationToken),
        );
      }
    }
  };

  useEffect(() => {
    if (roleFilter === OrderItemType.STAFF) {
      setSelectedStaffPersonIds(
        selectedStaff.filter(id => !!staffByHash[id]).map(id => staffByHash[id].personId),
      );
      setSelectedPlayerPersonIds([]);
    } else {
      setSelectedPlayerPersonIds(
        selectedPlayer.filter(id => !!playersByHash[id]).map(id => playersByHash[id].personId),
      );
      setSelectedStaffPersonIds([]);
    }
  }, [roleFilter, selectedStaff, selectedPlayer, staffByHash, playersByHash]);

  return (
    <div className={styles.footer}>
      <FooterSelectedNumber
        text={
          roleFilter === OrderItemType.STAFF
            ? `${translate('STAFF_SELECTED')} (${selectedStaff.length + selectedPendingStaff.length})`
            : `${translate('PLAYERS_SELECTED')} (${selectedPlayer.length})`
        }
      />
      {showAssignModal && (
        <AssignToTeamModal
          basic
          circular
          disabled={(!selectedPlayer.length && !selectedStaff.length)
            || !!selectedPendingStaff.length}
          primary
          memberIds={
            roleFilter === OrderItemType.STAFF
              ? selectedStaffPersonIds
              : selectedPlayerPersonIds
          }
          triggerText={`${translate('ASSIGN_TO_NEW_TEAM')} (${
            roleFilter === OrderItemType.STAFF
              ? selectedStaff.length + selectedPendingStaff.length
              : selectedPlayer.length})`}
        />
      )}
      <Confirm
        basic
        circular
        confirmText={translate('CONFIRM_MEMBER_DELETION_MESSAGE')}
        disabled={!selectedPlayer.length && !selectedStaff.length && !selectedPendingStaff.length}
        headerText={translate('DELETE_MEMBER')}
        onConfirm={handleDeletePlayers}
        primary
        triggerText={`${translate('DELETE_MEMBER')} (${
          roleFilter === OrderItemType.STAFF
            ? selectedStaff.length + selectedPendingStaff.length
            : selectedPlayer.length})`}
      />
    </div>
  );
};

export default TeamsFooter;
