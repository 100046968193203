import { AjaxError } from 'rxjs/ajax';
import * as types from '../constants/actionTypes';
import { Club, ClubEditRequest } from '../models/Club';
import {
  FetchClubErrorAction,
  FetchClubRequestAction,
  FetchClubSuccessAction,
  FetchClubLogoErrorAction,
  FetchClubLogoRequestAction,
  FetchClubLogoSuccessAction,
  UploadClubLogoRequestAction,
  UploadClubLogoSuccessAction,
  UpdateClubErrorAction,
  UpdateClubSuccessAction,
  UpdateClubRequestAction,
} from '../models/actions';

export const fetchClubRequest = (): FetchClubRequestAction => ({
  type: types.FETCH_CLUB_REQUEST,
  payload: {},
});

export const fetchClubSuccess = (club: Club): FetchClubSuccessAction => ({
  type: types.FETCH_CLUB_SUCCESS,
  payload: {
    club,
  },
});

export const fetchClubError = (error: AjaxError): FetchClubErrorAction => ({
  type: types.FETCH_CLUB_ERROR,
  payload: {
    error,
  },
});

export const updateClubRequest = (
  club: ClubEditRequest, clubId: number,
): UpdateClubRequestAction => ({
  type: types.UPDATE_CLUB_REQUEST,
  payload: {
    club,
    clubId,
  },
});
export const updateClubSuccess = (club: Club): UpdateClubSuccessAction => ({
  type: types.UPDATE_CLUB_SUCCESS,
  payload: {
    club,
  },
});

export const updateClubError = (error: AjaxError): UpdateClubErrorAction => ({
  type: types.UPDATE_CLUB_ERROR,
  payload: {
    error,
  },
});

export const fetchClubLogoRequest = (clubId?: number): FetchClubLogoRequestAction => ({
  type: types.FETCH_CLUB_LOGO_REQUEST,
  payload: {
    clubId,
  },
});

export const fetchClubLogoSuccess = (club: Club): FetchClubLogoSuccessAction => ({
  type: types.FETCH_CLUB_LOGO_SUCCESS,
  payload: {
    club,
  },
});

export const fetchClubLogoError = (error: AjaxError): FetchClubLogoErrorAction => ({
  type: types.FETCH_CLUB_LOGO_ERROR,
  payload: {
    error,
  },
});

export const uploadClubLogoRequest = (
  image: File,
): UploadClubLogoRequestAction => ({
  type: types.UPLOAD_CLUB_LOGO_REQUEST,
  payload: {
    image,
  },
});

export const uploadClubLogoSuccess = (
  clubId: number,
  publicUrl: string,
): UploadClubLogoSuccessAction => ({
  type: types.UPLOAD_CLUB_LOGO_SUCCESS,
  payload: {
    clubId,
    publicUrl,
  },
});

export const uploadClubLogoError = (error: AjaxError) => ({
  type: types.UPLOAD_CLUB_LOGO_ERROR,
  payload: {
    error,
  },
});
