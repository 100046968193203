import pickBy from 'lodash/pickBy';
import queryString from 'query-string';
import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import { FETCH_TEAM_BASKET_ORDERS_ERROR, FETCH_TEAM_BASKET_ORDERS_REQUEST } from '../../constants/actionTypes';
import { FetchTeamBasketOrdersRequest } from '../../models/actions';
import State from '../../models';
import { ListResponse, Response } from '../../models/Response';
import { TeamBasketOrder } from '../../models/TeamBasketOrder';
import { fetchTeamBasketOrdersError, fetchTeamBasketOrdersSuccess } from '../../actions/fetchTeamBasketOrders';
import { handleError } from '../../actions/errors';
import { serializeTeamBasketOrder } from '../../utils/serializers/serializeTeamBasketOrder';
import { TEAM_BASKET_ORDERS_LIST } from '../../constants/paths';
import { TEAM_BASKET_ORDERS_LIST_LIMIT } from '../../constants/customizations';

const fetchOrders = (
  action$: ActionsObservable<FetchTeamBasketOrdersRequest>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_TEAM_BASKET_ORDERS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    {
      payload:
        {
          page, field, order, search,
        },
    },
    {
      authentication: { authToken },
      application: { clubId },
    },
  ]) => {
    const query = queryString.stringify(pickBy({
      offset: ((page || 1) - 1) * TEAM_BASKET_ORDERS_LIST_LIMIT,
      limit: TEAM_BASKET_ORDERS_LIST_LIMIT,
      ...(search && ({ search })),
    }));

    return ajax({
      url: `${TEAM_BASKET_ORDERS_LIST}?${query}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authToken,
      },
      body: JSON.stringify(((field || order) ? { sort: { field, order }, clubId } : { clubId })),
    }).pipe(
      map(({ response: { data: orders, count } }: Response<ListResponse<TeamBasketOrder>>) => (
        fetchTeamBasketOrdersSuccess(
          (orders as (TeamBasketOrder & { Id: number })[]).map(serializeTeamBasketOrder), count,
        )
      )),
      catchError(error => ([
        handleError(error, FETCH_TEAM_BASKET_ORDERS_ERROR),
        fetchTeamBasketOrdersError(error),
      ])),
    );
  }),
);

export default fetchOrders;
