import { ActionsObservable, ofType } from 'redux-observable';
import { push } from 'connected-react-router';
import { mergeMap } from 'rxjs/operators';
import { SIGN_UP_SUCCESS } from '../../constants/actionTypes';
import { signIn } from '../../packages/authentication/actions';
import { SignUpWithTokenSuccessAction } from '../../models/actions';
import { Roles } from '../../constants/roles';
import { SIGNUP_WELCOME_PAGE_ROUTE } from '../../constants/routes';

export default (
  action$: ActionsObservable<SignUpWithTokenSuccessAction>,
) => action$.pipe(
  ofType(SIGN_UP_SUCCESS),
  mergeMap(({ payload: { signUpData, receiverRole } }) => {
    const actions = receiverRole === Roles.PLAYER
      ? [push(SIGNUP_WELCOME_PAGE_ROUTE)]
      : [
        signIn(signUpData.username, signUpData.password),
        push(SIGNUP_WELCOME_PAGE_ROUTE),
      ];

    return [
      ...actions,
    ];
  }),
);
