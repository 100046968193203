import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import { RESET_PASSWORD_BY_ADMIN_PATH } from '../constants/paths';
import { RESET_PASSWORD_BY_ADMIN_REQUEST } from '../constants/actionTypes';
import { resetPasswordByAdminSuccess } from '../actions/resetPasswordByAdmin';
import { resetPasswordError } from '../actions';

export default baseUrl => (action$, state$, { ajax }) => action$.pipe(
  ofType(RESET_PASSWORD_BY_ADMIN_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { userId, password } },
    { authentication: { authToken } },
  ]) => ajax({
    url: `${baseUrl}${RESET_PASSWORD_BY_ADMIN_PATH.replace('$userId', userId)}`,
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify({
      newPassword: password,
    }),
  }).pipe(
    map(() => resetPasswordByAdminSuccess()),
    catchError(error => of(resetPasswordError(error))),
  )),
);
