import React, { FC } from 'react';
import { Checkbox } from 'semantic-ui-react';

import styles from './MemberRow.module.scss';
import { Item } from '../../../../../../models/Order';
import { Player } from '../../../../../../models/Player';
import ProfileAvatar from '../../../../../PlayerProfile/components/ProfileAvatar';
import Row from '../../../../../../components/Table/components/Row';
import Text from '../../../../../../components/Text';
import { StaffMember } from '../../../../../../models/Staff';

interface MemberRowProps {
  player?: Player;
  member?: StaffMember;
  selected: boolean;
  select: (item: Item) => void;
}

const MemberRow: FC<MemberRowProps> = ({
  member,
  player,
  select,
  selected,
}) => {
  const item = {
    playerId: (player && player.id) || (member && member.id),
  } as Item;

  const onClick = () => {
    select(item);
  };

  return (
    <Row key={(player && player.id) || (member && member.id)} classNames={styles.wrapper}>
      <div className={styles.teamMember}>
        <Checkbox onClick={onClick} checked={selected} />
        <ProfileAvatar user={(player || member)!} />
        <Text bold>
          {
            member
              ? `${member.firstName} ${member.lastName}`
              : `${player!.firstName} ${player!.lastName}`
          }
        </Text>
      </div>
    </Row>
  );
};

export default MemberRow;
