import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import OrderPreparation from './OrderPreparation';
import { State } from '../../models/State';
import { changeOrderStatusRequest, upsertOrderRequest, lockNumbers } from '../../actions/orders';
import { initializeOrder, clearOrder } from '../../actions/orderEditor';
import { OrderPreparationProps } from './OrderPreparationProps';
import { fetchTeamsRequest } from '../../actions/teams';
import { setSelectedPlayers } from '../../actions/players';
import { setSelectedStaff } from '../../actions/staff';

const mapStatesToProps = ({
  teams,
  application: { teamId },
  orders: {
    selectedPlayersIds, selectedProductsIds,
    productsByItemId, id, loadingUpsert, selectedMembersTeam,
  },
}: State, { match: { params } }: OrderPreparationProps) => ({
  loading: teams.loading,
  loadingUpsert,
  isTeamAvailable: params.teamId && !!teams.byHash[params.teamId],
  selectedPlayersIds,
  selectedProductsIds,
  amountOfArticles: productsByItemId.length,
  selectedMembersTeam,
  id,
  teamLockedNumbers: (teamId && teams.byHash[teamId] && teams.byHash[teamId].lockedNumbers) || [],
  teamUsedNumbers: (teamId && teams.byHash[teamId] && teams.byHash[teamId].usedNumbers) || {},
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initializeOrder: bindActionCreators(initializeOrder, dispatch),
  clearOrder: bindActionCreators(clearOrder, dispatch),
  lockNumbers: bindActionCreators(lockNumbers, dispatch),
  fetchTeams: bindActionCreators(fetchTeamsRequest, dispatch),
  upsertOrder: bindActionCreators(upsertOrderRequest, dispatch),
  changeStatus: bindActionCreators(changeOrderStatusRequest, dispatch),
  setSelectedPlayers: bindActionCreators(setSelectedPlayers, dispatch),
  setSelectedStaff: bindActionCreators(setSelectedStaff, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(withRouter(OrderPreparation));
