import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import DesktopOrderHeader from './DesktopOrderHeader';
import { State } from '../../../../models/State';
import {
  changeOrderStatusRequest, deleteOrdersRequest, upsertOrderRequest,
} from '../../../../actions/orders';
import { clearOrder, initializeOrder } from '../../../../actions/orderEditor';

const mapStatesToProps = ({
  application: { userRole },
  orders: {
    itemsById, itemsByHash, orderStatus, draft, loading, orderPrice,
  },
  catalog: {
    catalog: {
      productsByHash,
    },
  },
}: State) => ({
  draft,
  itemsByHash,
  itemsById,
  loading,
  orderPrice,
  userRole,
  orderStatus,
  productsByHash,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  changeStatus: bindActionCreators(changeOrderStatusRequest, dispatch),
  clearOrder: bindActionCreators(clearOrder, dispatch),
  deleteOrders: bindActionCreators(deleteOrdersRequest, dispatch),
  initializeOrder: bindActionCreators(initializeOrder, dispatch),
  upsertOrder: bindActionCreators(upsertOrderRequest, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(withRouter(DesktopOrderHeader));
