import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import TeamsTeamLead from './TeamsTeamLead';
import { State } from '../../models/State';
import {
  fetchTeamIdRequest, fetchTeamInviteRequest, fetchTeamsRequest, setLockNumbersRequest,
} from '../../actions/teams';
import { fetchPlayersRequest, setSelectedPlayers } from '../../actions/players';
import { fetchStaffRequest } from '../../actions/staff';
import { setApplicationParameters } from '../../actions/application';

const mapStatesToProps = (
  {
    application: { clubId, userRole },
    teams,
    players,
    staff,
    user: { user: authUser },
  }: State,
  { match: { params: { teamId } } }: RouteComponentProps<{ teamId: string }>,
) => ({
  clubId,
  teamId: +teamId,
  userRole,
  authUser,
  teamsById: teams.byId,
  teamsByHash: teams.byHash,
  loadingTeams: teams.loadingTeams,
  playersByHash: players.byHash,
  playersById: players.byId,
  playersLoading: players.loading,
  playerDeleted: players.playerDeleted,
  playersCount: players.count,
  selectedPlayers: players.selectedId,
  staffByHash: staff.byHash,
  staffById: staff.byId,
  lockedNumbers: teamId && teams.byHash[teamId]
    ? teams.byHash[teamId].lockedNumbers!
    : [],
  usedNumbers: teamId
    && teams.byHash[teamId]
    && teams.byHash[teamId].usedNumbers
    ? Object.keys(teams.byHash[teamId].usedNumbers).map(Number)
    : [],
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchTeams: bindActionCreators(fetchTeamsRequest, dispatch),
  fetchPlayers: bindActionCreators(fetchPlayersRequest, dispatch),
  fetchStaff: bindActionCreators(fetchStaffRequest, dispatch),
  fetchInviteToken: bindActionCreators(fetchTeamInviteRequest, dispatch),
  setSelectedPlayers: bindActionCreators(setSelectedPlayers, dispatch),
  fetchTeamId: bindActionCreators(fetchTeamIdRequest, dispatch),
  lockNumbers: bindActionCreators(setLockNumbersRequest, dispatch),
  setApplicationParameter: bindActionCreators(setApplicationParameters, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(TeamsTeamLead);
