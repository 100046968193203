import React from 'react';
import classNames from 'classnames';
import { Button, Label, Menu } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { get } from 'lodash';
import styles from './HeaderMenu.module.scss';
import { HeaderMenuProps } from './HeaderMenuProps';
import { OrderItemType } from '../../OrderPreparationProps';
import NumbersBalloon from '../NumbersBalloon';
import { useViewport } from '../../../../hooks/useViewport';

const HeaderMenu = ({
  activeTab, setActiveTab, amountProducts, itemsById,
  itemsByHash, lockedNumbers, lockNumbers, usedNumbers,
}: HeaderMenuProps) => {
  const [translate] = useTranslation();
  const { isMobile } = useViewport();
  const { PRODUCT, STAFF, PLAYER } = OrderItemType;
  const selectedNumbers = itemsById.map(id => get(itemsByHash, [id, 'numberLabel'])) as number[];

  const handleOnClick = (tab: OrderItemType) => () => setActiveTab(tab);

  return (
    <div className={classNames(styles.menuWrapper)}>
      <Menu pointing secondary widths={isMobile ? 2 : undefined} className={['filter', styles.menuWrapperOptions].join(' ')}>
        <Menu.Item
          fitted="horizontally"
          active={activeTab !== PRODUCT}
          onClick={handleOnClick(PLAYER)}
          className={styles.perspectiveSwitch}
        >
          {translate('MEMBER_SELECTION')}
        </Menu.Item>
        <Menu.Item
          fitted="horizontally"
          active={activeTab === PRODUCT}
          onClick={handleOnClick(PRODUCT)}
          className={styles.perspectiveSwitch}
        >
          {translate('PRODUCT_OVERVIEW')}
          {!!amountProducts && <Label circular className="badge">{amountProducts}</Label>}
        </Menu.Item>
      </Menu>
      {activeTab !== PRODUCT && (
        <div className={styles.secondMenuWrapper}>
          <Button
            basic
            className="simple"
            content={translate('PLAYERS')}
            onClick={handleOnClick(PLAYER)}
            primary={activeTab !== STAFF}
          />
          <Button
            basic
            className="simple"
            content={translate('STAFF')}
            onClick={handleOnClick(STAFF)}
            primary={activeTab === STAFF}
          />
          {!isMobile && (
            <div className={styles.numbersBalloonWrapper}>
              <NumbersBalloon
                // @ts-ignore
                basic
                primary
                selectedNumbers={selectedNumbers}
                lockedNumbers={lockedNumbers}
                lockNumbers={lockNumbers}
                usedNumbers={usedNumbers}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default HeaderMenu;
