import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import getEmbellishmentsForItem from '../methods/getEmbellishmentsForItem';
import { AddItemsForSelectedStaffMembersAction } from '../../../models/actions';
import { Item } from '../../../models/Order';
import { ORDER_ADD_ITEMS_FOR_SELECTED_STAFF_MEMBERS } from '../../../constants/actionTypes';
import { State } from '../../../models/State';
import { orderUpdatePriceRequest, upsertItems } from '../../../actions/orderEditor';

const addItemsForSelectedStaffMembers = (
  action$: ActionsObservable<AddItemsForSelectedStaffMembersAction>,
  state$: StateObservable<State>,
) => action$.pipe(
  ofType(ORDER_ADD_ITEMS_FOR_SELECTED_STAFF_MEMBERS),
  withLatestFrom(state$),
  mergeMap(([
    {
      payload: { items, memberIds },
    },
    {
      catalog: { catalog: { productsByHash } },
      orders: { itemsById, itemsByHash },
      staff: { byHash },
    },
  ]) => {
    const enrichedItems: Item[] = items.reduce((previousItems: Item[], itemId: string) => {
      const itemEmbellishments = getEmbellishmentsForItem({
        itemsById, itemsByHash, productCatalogId: itemId, productsByHash,
      });

      const itemsForAllSelected = memberIds.map((id: number) => ({
        quantity: 1,
        maxQuantity: 1,
        quantityLocked: false,
        sizeLocked: false,
        backLabelLocked: false,
        numberLabelLocked: false,
        externalId: uuidv4(),
        backLabel: byHash[id].lastName,
        productCatalogId: itemId,
        staffId: byHash[id].userId,
        embellishments: itemEmbellishments,
      }));

      return [
        ...previousItems,
        ...itemsForAllSelected,
      ];
    }, []);

    return [
      upsertItems(enrichedItems),
      orderUpdatePriceRequest(),
    ];
  }),
);

export default addItemsForSelectedStaffMembers;
