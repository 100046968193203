import { useState, useMemo } from 'react';
import debounce from 'lodash/debounce';
import params from '../../utils/params';

export default (history: any) => {
  const { setParam } = params(history);
  const [filterPanelVisible, setFilterPanelVisible] = useState(false);
  const toggleFilter = () => setFilterPanelVisible(!filterPanelVisible);

  const debouncedSetSearch = useMemo(() => debounce(value => {
    setParam('search', value);
  }, 500), [setParam]);

  const onChange = ({ target: { value } }: any) => {
    debouncedSetSearch(value);
  };

  return [toggleFilter as () => {}, onChange as () => {}, filterPanelVisible as boolean];
};
