import { AjaxError } from 'rxjs/ajax';
import { CreateInvitation, InvitationPersonalData } from '../models/InvitationData';
import { Player, PlayerFormData, PlayerListTL } from '../models/Player';
import { Team } from '../models/Team';
import {
  CreatePlayerSuccessAction,
  CreatePlayerRequestAction,
  CreatePlayerErrorAction,
  FetchPlayerRequestAction,
  FetchPlayerSuccessAction,
  FetchPlayerErrorAction,
  FetchPlayersRequestAction,
  FetchPlayersSuccessAction,
  FetchPlayersErrorAction,
  UpdatePlayerRequestAction,
  UpdatePlayerSuccessAction,
  UpdatePlayerErrorAction,
  SetSelectedPlayersAction,
} from '../models/actions';
import {
  CREATE_PLAYER_REQUEST,
  CREATE_PLAYER_SUCCESS,
  CREATE_PLAYER_ERROR,
  FETCH_PLAYER_REQUEST,
  FETCH_PLAYER_SUCCESS,
  FETCH_PLAYER_ERROR,
  FETCH_PLAYERS_ERROR,
  FETCH_PLAYERS_SUCCESS,
  FETCH_PLAYERS_REQUEST,
  UPDATE_PLAYER_REQUEST,
  UPDATE_PLAYER_SUCCESS,
  UPDATE_PLAYER_ERROR,
  SET_SELECTED_PLAYERS,
} from '../constants/actionTypes';
import { ListFilters } from '../models/ListFilters';

export const createPlayerRequest = (
  player: PlayerFormData,
  team: Team,
  shouldInvite: boolean,
  createInvitation: CreateInvitation,
  invitationPersonalData: InvitationPersonalData,
): CreatePlayerRequestAction => ({
  type: CREATE_PLAYER_REQUEST,
  payload: {
    team,
    player,
    shouldInvite,
    createInvitation,
    invitationPersonalData,
  },
});

export const createPlayerSuccess = (player: Player): CreatePlayerSuccessAction => ({
  type: CREATE_PLAYER_SUCCESS,
  payload: {
    player,
  },
});

export const createPlayerError = (error: AjaxError): CreatePlayerErrorAction => ({
  type: CREATE_PLAYER_ERROR,
  payload: {
    error,
  },
});

export const fetchPlayerRequest = (teamId: number, playerId: number): FetchPlayerRequestAction => ({
  type: FETCH_PLAYER_REQUEST,
  payload: {
    teamId,
    playerId,
  },
});

export const fetchPlayerSuccess = (player: Player): FetchPlayerSuccessAction => ({
  type: FETCH_PLAYER_SUCCESS,
  payload: {
    player,
  },
});

export const fetchPlayerError = (error: AjaxError): FetchPlayerErrorAction => ({
  type: FETCH_PLAYER_ERROR,
  payload: {
    error,
  },
});

export const fetchPlayersRequest = (
  teamId: number, filters: ListFilters,
): FetchPlayersRequestAction => ({
  type: FETCH_PLAYERS_REQUEST,
  payload: {
    teamId,
    filters,
  },
});

export const fetchPlayersSuccess = (
  players: PlayerListTL[], count: number, teamId: number,
): FetchPlayersSuccessAction => ({
  type: FETCH_PLAYERS_SUCCESS,
  payload: {
    players,
    count,
    teamId,
  },
});

export const fetchPlayersError = (error: AjaxError): FetchPlayersErrorAction => ({
  type: FETCH_PLAYERS_ERROR,
  payload: {
    error,
  },
});

export const updatePlayerRequest = (
  teamId: number, playerId: number, player: Player,
): UpdatePlayerRequestAction => ({
  type: UPDATE_PLAYER_REQUEST,
  payload: {
    teamId,
    playerId,
    player,
  },
});

export const updatePlayerSuccess = (player: Player): UpdatePlayerSuccessAction => ({
  type: UPDATE_PLAYER_SUCCESS,
  payload: {
    player,
  },
});

export const updatePlayerError = (error: AjaxError): UpdatePlayerErrorAction => ({
  type: UPDATE_PLAYER_ERROR,
  payload: {
    error,
  },
});

export const setSelectedPlayers = (ids: number[], teamId?: number): SetSelectedPlayersAction => ({
  type: SET_SELECTED_PLAYERS,
  payload: {
    ids,
    teamId,
  },
});
