import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { State } from '../../../../models/State';
import HeaderMenu from './HeaderMenu';
import { lockNumbers } from '../../../../actions/orders';

const mapStatesToProps = ({
  orders: {
    itemsById, itemsByHash,
    lockedNumbers, usedNumbers,
  },
}: State) => ({
  itemsById,
  itemsByHash,
  usedNumbers: usedNumbers ? Object.keys(usedNumbers).map(Number) : [],
  lockedNumbers,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  lockNumbers: bindActionCreators(lockNumbers, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(HeaderMenu);
