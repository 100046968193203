import React, {
  FC, useState, ChangeEvent, useEffect,
} from 'react';
import classNames from 'classnames';
import { Button, Header, Input } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { history } from '../../configureStore';

import styles from './Header.module.scss';
import { HeaderProps, HeaderTypes } from './HeaderProps';

const HeaderComponent: FC<HeaderProps> = ({
  backLinkTo,
  backLabel = 'BACK',
  goBack = false,
  onEdit,
  renderButton,
  title,
  type = HeaderTypes.H2,
  border,
  loading,
  editionSuccessful,
}) => {
  const [translate] = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const [editedText, setEditedText] = useState(title || '');

  useEffect(() => {
    setEditedText(title || '');
  }, [title]);

  useEffect(() => {
    if (editionSuccessful) {
      setEditMode(false);
    }
  }, [editionSuccessful]);

  const handleEditClick = () => {
    if (!editMode) {
      setEditMode(true);
    }
    if (editMode && onEdit) {
      onEdit(editedText);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEditedText(event.target.value);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleEditClick();
    }
  };

  return (
    <div className={classNames(
      styles.wrapper,
      type === HeaderTypes.H2 && styles.wrapperPadded,
      border && styles.border,
    )}
    >
      <div className={styles.leftContainer}>
        {!!backLinkTo && (
          <div className={styles.backLinkWrapper}>
            <Button
              as={Link}
              basic
              className="simple"
              content={translate(backLabel)}
              icon="caret left"
              primary
              to={backLinkTo}
            />
          </div>
        )}
        {goBack && (
          <div className={styles.backLinkWrapper}>
            <Button
              basic
              className="simple"
              content={translate(backLabel)}
              icon="caret left"
              primary
              onClick={() => { history.goBack(); }}
            />
          </div>
        )}
        <div className={styles.header}>
          {title && (
            !editMode
              ? <Header as={type} content={title} />
              : (
                <Input
                  name="title-input"
                  className={styles.newTitleInput}
                  onKeyDown={handleKeyDown}
                  onChange={handleInputChange}
                  value={editedText}
                  disabled={loading}
                />
              )
          )}
        </div>
      </div>
      <div className={styles.rightContainer}>
        {onEdit && (
          <Button
            primary
            basic
            content={translate(editMode ? 'SAVE_CHANGES' : 'EDIT_TEAM_NAME')}
            key="button"
            onClick={handleEditClick}
            disabled={loading}
          />
        )}
        {!!renderButton && renderButton()}
      </div>
    </div>
  );
};

export default HeaderComponent;
