import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { deactivateInviteTokenRequest, fetchInvitationsRequest, resendInvitationRequest } from '../../../../actions/invitations';
import { State } from '../../../../models/State';
import InvitationsList from './InvitationsList';

const mapStatesToProps = ({
  invitations: {
    byHash, byId, loading, count,
  },
  application: { clubId },
}: State) => ({
  byHash, byId, loading, clubId, count,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchInvitations: bindActionCreators(fetchInvitationsRequest, dispatch),
  resendInvitation: bindActionCreators(resendInvitationRequest, dispatch),
  invalidateInvitation: bindActionCreators(deactivateInviteTokenRequest, dispatch),
});

export default withRouter(connect(mapStatesToProps, mapDispatchToProps)(InvitationsList));
