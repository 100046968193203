import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import { CLUBS_PATH } from '../../constants/paths';
import { FetchCatalogRequestAction } from '../../models/actions';
import { Response } from '../../models/Response';
import { State } from '../../models/State';
import { CatalogDataResponse } from '../../models/Catalog';
import serializeCatalog from '../../utils/serializers/serializeCatalog';
import { fetchCatalogError, fetchCatalogSuccess } from '../../actions/catalogs';
import { FETCH_CATALOG_ERROR, FETCH_CATALOG_REQUEST } from '../../constants/actionTypes';
import { handleError } from '../../actions/errors';

export default (
  action$: ActionsObservable<FetchCatalogRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_CATALOG_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { activeTeam } },
    { authentication: { authToken }, application: { clubId } },
  ]) => ajax({
    url: `${CLUBS_PATH}/${clubId}/catalog${activeTeam ? `?teamId=${activeTeam}` : ''}`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    map(({ response }: Response<CatalogDataResponse>) => serializeCatalog(response)),
    map(fetchCatalogSuccess),
    catchError(error => [
      handleError(error, FETCH_CATALOG_ERROR),
      fetchCatalogError(error),
    ]),
  )),
);
