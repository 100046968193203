import { connect } from 'react-redux';

import Settings from './Settings';
import { State } from '../../models/State';
import { Club } from '../../models/Club';

const mapStatesToProps = ({
  application: { clubId },
  clubs: { byHash },
}: State) => ({
  club: clubId && byHash[clubId] && byHash[clubId].address
    ? byHash[clubId]
    : {
      address: {},
    } as Club,
  clubId,
});

export { default as SettingsEdit } from './components/SettingsEdit';
export default connect(mapStatesToProps)(Settings);
