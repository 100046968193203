import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import PlayersAndStaffList from './PlayersAndStaffList';
import { State } from '../../models/State';
import { fetchTeamIdRequest, fetchTeamsRequest, setLockNumbersRequest } from '../../actions/teams';
import { fetchPlayersRequest, setSelectedPlayers } from '../../actions/players';
import { fetchStaffRequest, setSelectedPendingStaff, setSelectedStaff } from '../../actions/staff';
import { resendInvitationRequest, sendInvitationRequest } from '../../actions/invitations';

const mapStatesToProps = (
  {
    application: { clubId, userRole },
    teams,
    players,
    staff,
    invitations: { loading: loadingInvite },
  }: State,
) => ({
  clubId,
  userRole,
  loadingInvite,
  teamsById: teams.byId,
  teamsByHash: teams.byHash,
  loading: teams.loading,
  bulkAssignLoading: teams.bulkAssignLoading,
  playersByHash: players.byHash,
  playersById: players.byId,
  playersLoading: players.loading,
  playerDeleted: players.playerDeleted,
  playersCount: players.count,
  selectedPlayers: players.selectedId,
  selectedStaff: staff.selectedStaffIds,
  selectedPendingStaff: staff.selectedId,
  staffByHash: staff.byHash,
  staffById: staff.byId,
  staffLoading: staff.loading,
  pendingStaffByHash: staff.pendingStaffMembersByHash,
  pendingStaffById: staff.pendingStaffMembersbyId,
  selectedMembersTeam: players.selectedMembersTeam || staff.selectedMembersTeam,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchTeams: bindActionCreators(fetchTeamsRequest, dispatch),
  fetchPlayers: bindActionCreators(fetchPlayersRequest, dispatch),
  fetchStaff: bindActionCreators(fetchStaffRequest, dispatch),
  resendInvitationForMember: bindActionCreators(resendInvitationRequest, dispatch),
  resendInvitationForPlayer: bindActionCreators(sendInvitationRequest, dispatch),
  setSelectedPlayers: bindActionCreators(setSelectedPlayers, dispatch),
  setSelectedStaff: bindActionCreators(setSelectedStaff, dispatch),
  setSelectedPendingStaff: bindActionCreators(setSelectedPendingStaff, dispatch),
  fetchTeamId: bindActionCreators(fetchTeamIdRequest, dispatch),
  lockNumbers: bindActionCreators(setLockNumbersRequest, dispatch),
});

export default withRouter(connect(mapStatesToProps, mapDispatchToProps)(PlayersAndStaffList));
