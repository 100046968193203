import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';

import { RESET_PASSWORD_PATH } from '../constants/paths';
import { RESET_PASSWORD_REQUEST } from '../constants/actionTypes';
import { resetPasswordSuccess, resetPasswordError } from '../actions/resetPassword';

export default baseUrl => (action$, state$, { ajax }) => action$.pipe(
  ofType(RESET_PASSWORD_REQUEST),
  mergeMap(({ payload: { username, password, token } }) => ajax({
    url: `${baseUrl}${RESET_PASSWORD_PATH.replace('$username', username)}`,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      password,
      token,
    }),
  }).pipe(
    map(() => resetPasswordSuccess()),
    catchError(error => of(resetPasswordError(error))),
  )),
);
