import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AjaxError } from 'rxjs/ajax';
import { NotificationType, TeamSeasonTransferRequestAction } from '../../models/actions';
import { State } from '../../models/State';
import {
  TEAM_SEASON_TRANSFER_ERROR, TEAM_SEASON_TRANSFER_REQUEST,
  TEAM_SEASON_TRANSFER_SUCCESS,
} from '../../constants/actionTypes';
import { TEAM_SEASON_TRANSFER_PATH } from '../../constants/paths';
import { addNotification } from '../../actions/notifications';
import { teamSeasonTransferError, teamSeasonTransferSuccess } from '../../actions/teams';
import { handleError } from '../../actions/errors';

export default (
  action$: ActionsObservable<TeamSeasonTransferRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(TEAM_SEASON_TRANSFER_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      teamId,
      playerLineUpTransferOption,
      treasurerTransferOption,
      season,
      teamLeadTransferOption,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: TEAM_SEASON_TRANSFER_PATH.replace('$teamId', `${teamId}`),
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify({
      season,
      playerLineUpTransferOption,
      treasurerTransferOption,
      teamLeadTransferOption,
    }),
  }).pipe(
    mergeMap(() => [
      addNotification(NotificationType.SUCCESS, TEAM_SEASON_TRANSFER_SUCCESS),
      teamSeasonTransferSuccess(),
    ].filter(Boolean)),
    catchError((error: AjaxError) => [
      handleError(error, TEAM_SEASON_TRANSFER_ERROR),
      teamSeasonTransferError(error),
    ]),
  )),
);
