import pickBy from 'lodash/pickBy';
import queryString from 'query-string';
import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import { FETCH_PLAYERS_ERROR, FETCH_PLAYERS_REQUEST } from '../../constants/actionTypes';
import { FetchPlayersRequestAction } from '../../models/actions';
import { PlayerListTL } from '../../models/Player';
import { Response, ListResponse } from '../../models/Response';
import { State } from '../../models/State';
import { PLAYERS_LIST_LIMIT } from '../../constants/customizations';
import { TEAM_PLAYERS_FILTER } from '../../constants/paths';
import { fetchPlayersSuccess, fetchPlayersError } from '../../actions/players';
import { handleError } from '../../actions/errors';

export default (
  action$: ActionsObservable<FetchPlayersRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_PLAYERS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { teamId, filters: { field, order, page } = { field: '', order: '', page: 1 } } },
    {
      authentication: { authToken },
    },
  ]) => {
    const query = queryString.stringify(pickBy({
      offset: (Number(page) - 1) * PLAYERS_LIST_LIMIT,
      limit: PLAYERS_LIST_LIMIT,
    }));

    return ajax({
      url: `${TEAM_PLAYERS_FILTER.replace('$teamId', `${teamId}`)}?${query}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authToken,
      },
      body: JSON.stringify({
        ...((field && order) && { sort: { field, order } }),
      }),
    }).pipe(
      map(({ response: { data, count } }: Response<ListResponse<PlayerListTL>>) => {
        const serializedPlayers = data.map(player => ({
          ...player,
          ...(player.joinedClubDate && ({ joinedClubDate: new Date(player.joinedClubDate) })),
        }));

        return fetchPlayersSuccess(serializedPlayers, count, teamId);
      }),
      catchError(error => [
        handleError(error, FETCH_PLAYERS_ERROR),
        fetchPlayersError(error),
      ]),
    );
  }),
);
