import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import SignUp from './SignUp';
import State from '../../models';
import { fetchTokenDetailsRequest, setUserConsentApproved } from '../../actions/application';
import { signUpWithToken, resetSignup } from '../../actions/signUp';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  signUpWithToken: bindActionCreators(signUpWithToken, dispatch),
  fetchTokenDetails: bindActionCreators(fetchTokenDetailsRequest, dispatch),
  resetSignup: bindActionCreators(resetSignup, dispatch),
  setUserConsentApproved: bindActionCreators(setUserConsentApproved, dispatch),
});

const mapStateToProps = ({
  signUp: { serverError, loading }, clubs: { logoUrl },
  application: { tokenDetails, consentApproved },
}: State) => ({
  clubLogoUrl: logoUrl,
  serverError,
  loading,
  tokenDetails,
  consentApproved,
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
