import React, { useCallback, useEffect, useState } from 'react';
import get from 'lodash/get';
import xor from 'lodash/xor';
import { Button, Image, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';

import ItemsList from '../../../MemberDetails/components/DesktopItemsList';
import ManageEmbellishmentsModal from '../ManageEmbellishmentsModal';
import NumbersBalloon from '../../../NumbersBalloon';
import Text from '../../../../../../components/Text';
import styles from './DesktopProductDetails.module.scss';
import useImage from '../../../../../../hooks/useImage';
import { ItemsByHash, SortFields } from '../../../../../../models/Order';
import { DesktopProductDetailsProps } from './DesktopProductDetailsProps';
import { SortOrder } from '../../../../../../models/general/SortOrder';
import { OrderItemType } from '../../../../OrderPreparationProps';
import SortingOrder from '../../../../../../constants/sortingOrder';
import changeEmbellishmentAllLock from '../../../../methods/changeEmbellishmentAllLock';
import changeEmbellishments from '../../../../methods/changeEmbellishments';
import changeProperty from '../../../../methods/changeProperty';
import sortByLastName from './methods/sortByLastName';
import sortByNumber from './methods/sortByNumber';
import FooterSelectedNumber from '../../../DesktopFooter/FooterSelectedNumber';

const DesktopProductDetails = ({
  open,
  product: {
    files, name, originalImages, embellishments,
  },
  fetchFile,
  filesByHash,
  article,
  onClose,
  itemsByHash,
  itemsById,
  updateItems,
  removeItems,
  playersByHash,
  lockedNumbers,
  lockNumbers,
  usedNumbers,
}: DesktopProductDetailsProps) => {
  const [translate] = useTranslation();
  const [img] = useImage(files, originalImages, filesByHash, fetchFile);
  const [editableItemsByHash, setEditableItemsByHash] = useState<ItemsByHash>({});
  const [selectedItems, selectItems] = useState<string[]>([]);
  const selectedNumbers = itemsById.map(id => get(editableItemsByHash, [id, 'numberLabel'])) as number[];
  const [sortedIds, setSortedIds] = useState<string[]>([]);
  const [sortField, setSortField] = useState<SortFields>();
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortingOrder.ASC);
  const itemEmbellishments = get(editableItemsByHash[sortedIds[0]], 'embellishments', []);

  const sortItems = useCallback((ids: string[], order: SortOrder, field?: SortFields) => (
    field === SortFields.player
      ? sortByLastName(editableItemsByHash, ids, order, playersByHash)
      : sortByNumber(editableItemsByHash, ids, order, playersByHash)
  ), [editableItemsByHash, playersByHash]);

  const sort = (field: SortFields) => {
    let order = sortOrder;

    if (field === sortField) {
      order = sortOrder === SortingOrder.ASC ? SortingOrder.DESC : SortingOrder.ASC;
    }

    setSortField(field);
    setSortOrder(order);
  };

  useEffect(() => {
    const { itemIds: ids = [] } = article || {};
    setSortedIds(sortItems(ids, sortOrder, sortField));
  }, [article, sortOrder, sortField]); // eslint-disable-line

  useDeepCompareEffect(() => {
    setEditableItemsByHash(itemsById.reduce((prev: ItemsByHash, id: string) => ({
      ...prev,
      [id]: itemsByHash[id],
    }), {}));
  }, [itemsById, itemsByHash]);

  const toggleItemValue = (
    setItemsValues: (items: string[]) => void,
    itemsValues: string[],
  ) => (id: string) => () => setItemsValues(xor(itemsValues, [id]));

  const toggleAllItems = (
    setItemsValues: (items: string[]) => void,
    itemsValues: string[],
  ) => () => setItemsValues(
    sortedIds.length !== itemsValues.length ? sortedIds : [],
  );

  const close = () => {
    onClose();
    setEditableItemsByHash(itemsByHash);
  };

  const onSave = () => {
    onClose();
    updateItems(editableItemsByHash);
  };

  const remove = () => {
    removeItems(selectedItems);
    selectItems([]);
  };

  const onMount = () => {
    selectItems([]);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      onMount={onMount}
      size="large"
    >
      <div className={styles.wrapper}>
        <div className={styles.topGroup}>
          <div className={styles.container}>
            <div className={styles.article}>
              <div className={styles.img}>
                {!!img && (
                  <Image src={img} className={styles.image} />
                )}
              </div>
              <div>
                <Text bold>{name}</Text>
                {!!itemEmbellishments.length && (
                  <div>{`(${itemEmbellishments.length} ${translate('EMBELLISHMENTS')})`}</div>
                )}
              </div>
            </div>
            <div className={styles.buttons}>
              <Button primary onClick={close} content={translate('CANCEL')} basic />
              <Button primary onClick={onSave} content={translate('SAVE')} />
            </div>
          </div>

          <div className={styles.manageMenu}>
            {!!(embellishments && embellishments.length) && (
              <ManageEmbellishmentsModal
                changeEmbellishments={changeEmbellishments(
                  editableItemsByHash, setEditableItemsByHash,
                )}
                embellishments={embellishments}
                itemsByHash={editableItemsByHash}
                itemsById={sortedIds}
              />
            )}
            <NumbersBalloon
              // @ts-ignore
              basic
              primary
              selectedNumbers={selectedNumbers}
              lockedNumbers={lockedNumbers}
              lockNumbers={lockNumbers}
              usedNumbers={usedNumbers}
            />
          </div>

          <div className={styles.contentWrapper}>
            <ItemsList
              selected={selectedItems}
              changeProperty={changeProperty(editableItemsByHash, setEditableItemsByHash)}
              changeEmbellishmentAllLock={changeEmbellishmentAllLock(
                editableItemsByHash, setEditableItemsByHash,
              )}
              itemsById={sortedIds}
              itemsByHash={editableItemsByHash}
              toggleAllSelection={toggleAllItems(selectItems, selectedItems)}
              toggleSelection={toggleItemValue(selectItems, selectedItems)}
              itemType={OrderItemType.PLAYER}
              onSortClick={sort}
              sortField={sortField}
              sortOrder={sortOrder}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <FooterSelectedNumber text={translate('MEMBER_SELECTED_WITH_COUNT', { count: selectedItems.length })} />
          <Button
            circular
            disabled={!selectedItems.length}
            onClick={remove}
            primary
            content={translate('DELETE_MEMBER_WITH_COUNT', { count: selectedItems.length })}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DesktopProductDetails;
