import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../Content.module.scss';
import { formatDate, formatTime } from '../../../../../../utils/dateUtils';
import { Training } from '../../../../../../models/Calendar';

interface TrainingContentProps {
  event: Training;
}

const TrainingContent: FC<TrainingContentProps> = ({
  event: {
    training, interval, location, description, name,
  },
}) => {
  const [translate] = useTranslation();
  return (
    <>
      <div className={styles.content}>
        <div className={styles.half}>
          <div className={styles.formGroup}>
            <div>{translate('NAME')}</div>
            <div>{name}</div>
          </div>
        </div>
        <div className={styles.half}>
          <div className={styles.formGroup}>
            <div>{translate('DAY')}</div>
            <div>{interval && interval.startDay}</div>
          </div>
        </div>
        <div className={styles.half}>
          <div className={styles.formGroup}>
            <div>{translate('INTERVAL')}</div>
            <div>{interval && interval.type}</div>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={[styles.half, styles.content].join(' ')} style={{ marginBottom: 0 }}>
          <div className={styles.half}>
            <div className={styles.formGroup}>
              <div>{translate('START_DATE')}</div>
              <div>{interval && formatDate(interval.startDate)}</div>
            </div>
          </div>
          <div className={styles.half}>
            <div className={styles.formGroup}>
              <div>{translate('END_DATE')}</div>
              <div>{interval && formatDate(interval.endDate)}</div>
            </div>
          </div>
        </div>
        <div className={styles.half}>
          <div className={styles.formGroup}>
            <div>{translate('TIME')}</div>
            <div className={styles.time}>
              <div>{interval && formatTime(interval.startTime)}</div>
              <div>to</div>
              <div>{interval && formatTime(interval.endTime)}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.dominant}>
          <div className={styles.formGroup}>
            <div>{translate('MEETING_POINT')}</div>
            <div>{location && location.displayName}</div>
          </div>
        </div>
        <div className={styles.half}>
          <div className={styles.formGroup}>
            <div>{translate('MEETING_TIME')}</div>
            <div>{formatTime(training.meetingTime)}</div>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.formGroup}>
          <div>{translate('INFORMATION')}</div>
          <div>{description}</div>
        </div>
      </div>
    </>
  );
};

export default TrainingContent;
