import pickBy from 'lodash/pickBy';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import {
  catchError, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import { EDIT_ROLES_MEMBERS_PATH } from '../../constants/paths';
import { EditRolesMembersRequestAction, NotificationType } from '../../models/actions';
import { State } from '../../models/State';
import { addNotification } from '../../actions/notifications';
import { editRolesMembersError, editRolesMembersSuccess } from '../../actions/members';
import { handleError } from '../../actions/errors';
import {
  EDIT_ROLES_MEMBERS_ERROR, EDIT_ROLES_MEMBERS_REQUEST, EDIT_ROLES_MEMBERS_SUCCESS,
} from '../../constants/actionTypes';

export default (
  action$: ActionsObservable<EditRolesMembersRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(EDIT_ROLES_MEMBERS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: { teamId, personId, roles },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: EDIT_ROLES_MEMBERS_PATH.replace('$teamId', `${teamId}`),
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify(pickBy({
      personId,
      roles,
    })),
  }).pipe(
    mergeMap(() => [
      addNotification(NotificationType.SUCCESS, EDIT_ROLES_MEMBERS_SUCCESS),
      editRolesMembersSuccess(teamId, personId, roles),
    ]),
    catchError((error: AjaxError) => [
      handleError(error, EDIT_ROLES_MEMBERS_ERROR),
      editRolesMembersError(error),
    ]),
  )),
);
