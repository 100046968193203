import { EventType } from '../../../../constants/eventTypes';
import { Team } from '../../../../models/Team';
import { EventStatus } from '../../../../models/Calendar';

export enum CellType {
  MONTH = 'month',
  DAY = 'day',
}

export interface EventCellProps {
  event: {
    id: number;
    start: Date;
    end: Date;
    title: string;
    type: EventType;
    match: any;
    meetingDate: Date;
    location: any;
    teamName?: string;
    status?: EventStatus;
  }
  team: Team | null;
  onOpen: () => void;
  cellType: CellType;
}
