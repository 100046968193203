import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';

import { NotificationType, UploadAvatarRequestAction } from '../../models/actions';
import { State } from '../../models/State';
import { UPLOAD_AVATAR_PATH } from '../../constants/paths';
import { addNotification } from '../../actions/notifications';
import { handleError } from '../../actions/errors';
import {
  UPLOAD_AVATAR_ERROR, UPLOAD_AVATAR_REQUEST, UPLOAD_AVATAR_SUCCESS,
} from '../../constants/actionTypes';
import { fetchUserInfoRequest, uploadAvatarError, uploadAvatarSuccess } from '../../actions/user';
import { removeFile as removeOldFile } from '../../actions/files';

export default (
  action$: ActionsObservable<UploadAvatarRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(UPLOAD_AVATAR_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { image } },
    { authentication: { authToken } },
  ]) => {
    const form = new FormData();
    form.append('image', image);

    return ajax.post(
      UPLOAD_AVATAR_PATH,
      form,
      { authToken },
    ).pipe(
      mergeMap(({ response: { id } }) => [
        addNotification(NotificationType.SUCCESS, UPLOAD_AVATAR_SUCCESS),
        removeOldFile(id),
        uploadAvatarSuccess(id),
        fetchUserInfoRequest(),
      ]),
      catchError(error => [
        handleError(error, UPLOAD_AVATAR_ERROR),
        uploadAvatarError(error),
      ]),
    );
  }),
);
