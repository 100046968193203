import React, { FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  CLUB_INDIVIDUAL_ORDERS_ROUTE,
  CLUB_ORDERS_ROUTE, CLUB_TEAM_BASKET_ORDERS_ROUTE,
} from '../../constants/routes';

const OrderTabsComponent: FC<RouteComponentProps> = ({ location: { pathname } }) => {
  const [translate] = useTranslation();

  return (
    <Menu className="filter" pointing secondary>
      <Menu.Item
        active={pathname === CLUB_ORDERS_ROUTE}
        as={Link}
        content={translate('TEAM_ORDERS')}
        to={CLUB_ORDERS_ROUTE}
      />
      <Menu.Item
        active={pathname === CLUB_INDIVIDUAL_ORDERS_ROUTE}
        as={Link}
        content={translate('INDIVIDUAL_ORDERS')}
        to={CLUB_INDIVIDUAL_ORDERS_ROUTE}
      />
      <Menu.Item
        active={pathname === CLUB_TEAM_BASKET_ORDERS_ROUTE}
        as={Link}
        content={translate('TEAM_BASKET_ORDERS')}
        to={CLUB_TEAM_BASKET_ORDERS_ROUTE}
      />
    </Menu>
  );
};

export default withRouter(OrderTabsComponent);
