import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Image, Menu } from 'semantic-ui-react';
import styles from './MatchEventHeader.module.scss';
import { MatchEventHeaderProps } from './MatchEventHeaderProps';
import { MatchEditTabs } from '../MatchEventModal/MatchEventModalProps';
import { DEFAULT_LOGO_URL } from '../../../../constants/customizations';
import Confirm from '../../../../components/Confirm';
import DeleteEventModal from '../DeleteEventModal';

const MatchEventHeader: FC<MatchEventHeaderProps> = ({
  onClose, onSubmit, isFormValid, clubLogoUrl, event,
  header, setActiveTab, activeTab, cancelEvent, isSubmitted,
}) => {
  const [translate] = useTranslation();
  const [deletionModalOpened, setDeletionModalOpened] = useState(false);
  const isDateAfterStart = event && event.startDateTime && event.startDateTime < new Date();

  const handleTabChange = (tab: MatchEditTabs) => () => {
    setActiveTab(tab);
  };

  const handleCancel = (id: number) => () => {
    cancelEvent(id);
    onClose();
  };

  const closeDeleteModal = () => {
    setDeletionModalOpened(false);
  };

  const openDeleteModal = () => {
    setDeletionModalOpened(true);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.actionsContainer}>
        <div className={styles.leftWrapper}>
          <Image
            src={clubLogoUrl || DEFAULT_LOGO_URL}
            className={styles.image}
          />
          <h2 className={styles.header}>
            {header}
          </h2>
        </div>
        <div className={styles.rightWrapper}>
          {event && event.id ? [
            <Button key="delete" basic primary onClick={openDeleteModal} content={translate('DELETE')} />,
            <Confirm
              basic
              headerText={translate('CONFIRM_CANCEL_MATCH')}
              confirmText={translate('CONFIRM_CANCEL_MATCH_TEXT')}
              onConfirm={handleCancel(event.id)}
              primary
              triggerText={translate('CANCEL_EVENT')}
              key="cancel"
            />,
          ] : (
            <Button basic primary onClick={onClose} content={translate('CANCEL')} />
          )}
          <Button primary disabled={isSubmitted && !isFormValid} onClick={onSubmit}>
            {translate(event ? 'SAVE_EVENT' : 'CREATE_EVENT')}
          </Button>
        </div>
      </div>
      <Menu className="filter" pointing secondary>
        {event && (
          <Menu.Item
            content={translate(MatchEditTabs.MATCH_DETAILS)}
            active={MatchEditTabs.MATCH_DETAILS === activeTab}
            onClick={handleTabChange(MatchEditTabs.MATCH_DETAILS)}
          />
        )}
        {isDateAfterStart && (
          <Menu.Item
            content={translate(MatchEditTabs.MATCH_STATISTICS)}
            active={MatchEditTabs.MATCH_STATISTICS === activeTab}
            onClick={handleTabChange(MatchEditTabs.MATCH_STATISTICS)}
          />
        )}
      </Menu>
      {event && deletionModalOpened && <DeleteEventModal onClose={closeDeleteModal} />}
    </div>
  );
};

export default MatchEventHeader;
