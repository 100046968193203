import React, { FC, useMemo } from 'react';
import {
  Dropdown,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

import Confirm from '../../../../components/Confirm';
import getMissingProperties from '../../methods/getMissingProperties';
import { MobileOrderHeaderProps } from './MobileOrderHeaderProps';
import { OrderStatus } from '../../../../constants/orderStatus';
import { CLUB_ORDERS_ROUTE, TEAM_ORDERS_ROUTE } from '../../../../constants/routes';
import { formatPrice } from '../../../../utils/PricingUtil';
import NumbersBalloon from '../NumbersBalloon';
import MobileHeader from '../../../../components/MobileHeader';
import { HeaderTypes } from '../../../../components/MobileHeader/MobileHeaderProps';
import { Roles } from '../../../../constants/roles';

const MobileOrderHeader: FC<MobileOrderHeaderProps> = ({
  changeStatus,
  deleteOrders,
  draft,
  history,
  initializeOrder,
  itemsByHash,
  itemsById,
  userRole,
  loading,
  orderId,
  orderPrice,
  orderStatus,
  productsByHash = {},
  teamId,
  upsertOrder,
  lockedNumbers,
  lockNumbers,
  usedNumbers,
}) => {
  const [translate] = useTranslation();
  const selectedNumbers = itemsById.map(id => get(itemsByHash, [id, 'numberLabel'])) as number[];

  const orderListPath = useMemo(() => (userRole === Roles.CLUB_ADMIN
    ? CLUB_ORDERS_ROUTE
    : TEAM_ORDERS_ROUTE.replace(':teamId', String(teamId))), [userRole, teamId]);

  const save = () => upsertOrder(
    teamId,
    () => orderListPath,
  );

  const handleDelete = () => {
    if (orderId) {
      deleteOrders([orderId]);
    }
    history.push(orderListPath);
  };

  const handleClearOrder = () => initializeOrder(teamId, orderId);

  const changeOrderStatus = () => changeStatus(
    teamId,
    orderStatus === OrderStatus.SENT_TO_TEAM
      ? OrderStatus.SENT_TO_CLUB_ADMIN
      : OrderStatus.SENT_TO_TEAM,
  );

  const items = itemsById.map(id => itemsByHash[id]);
  const orderInvalid = !!getMissingProperties(items, productsByHash).length;
  const orderIsEmpty = !itemsById.length;
  const title = `${translate('ORDER')} ${orderId ? `#${orderId}` : ''}`;

  return (
    <MobileHeader
      title={translate(title)}
      titleType={HeaderTypes.H2}
      subtitle={
        (orderPrice && orderPrice.amount)
          ? formatPrice(orderPrice.amount, orderPrice.currency)
          : null
      }
      primarySubtitle
      border
      menuItems={[
        <Confirm
          confirmText={translate('SAVE_ORDER_CONFIRMATION')}
          onConfirm={save}
          as={Dropdown.Item}
          disabled={orderIsEmpty}
          triggerText={translate('SAVE')}
          key="save-order-confirmation"
        />,
        <Confirm
          confirmText={translate(orderStatus === OrderStatus.SENT_TO_TEAM ? 'SEND_TO_CLUB_ADMIN_CONFIRMATION' : 'SEND_TO_THE_TEAM_CONFIRMATION')}
          onConfirm={changeOrderStatus}
          as={Dropdown.Item}
          disabled={(orderStatus === OrderStatus.SENT_TO_TEAM && orderInvalid) || orderIsEmpty}
          triggerText={translate(orderStatus === OrderStatus.SENT_TO_TEAM ? 'SEND_TO_CLUB_ADMIN' : 'SEND_TO_THE_TEAM')}
          key="sent-to-ca"
        />,
        (draft && orderId ? (
          <Confirm
            confirmText={translate('CLEAR_ORDER_CONFIRMATION')}
            onConfirm={handleClearOrder}
            loading={loading}
            as={Dropdown.Item}
            triggerText={translate('CLEAR_ORDER')}
            key="clear-order-confirmation"
          />
        ) : (
          <Confirm
            confirmText={translate('ARE_YOU_SURE_TO_DELETE_ORDER')}
            onConfirm={handleDelete}
            as={Dropdown.Item}
            triggerText={translate('DELETE_ORDER')}
            key="delete-order"
          />
        )),
        <NumbersBalloon
          // @ts-ignore
          as={Dropdown.Item}
          selectedNumbers={selectedNumbers}
          lockedNumbers={lockedNumbers}
          lockNumbers={lockNumbers}
          usedNumbers={usedNumbers}
          key="numbers"
        />,
      ]}
    />
  );
};

export default MobileOrderHeader;
