import React, {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Linkify from 'react-linkify';
import queryString from 'query-string';
import AuthenticationFormsWrapper from '../../components/AuthenticationFormsWrapper';
import styles from './SignupWelcomePage.module.scss';
import { Roles } from '../../constants/roles';
import MobileApps from '../../components/WelcomePage/MobileApps';
import Signature from '../../components/WelcomePage/Signature';
import HeaderTag from '../../components/WelcomePage/HeaderTag';
import { SIGN_IN_ROUTE } from '../../constants/routes';
import { UrlParams } from '../../constants/urlParams';
import { InvitationQueryParams } from '../../models/InvitationData';
import { jwtDecoder } from '../../utils/decoders/jwtDecoder';
import InvitationModal from '../InvitationModal';
import { INVITATIONS_PATH } from '../../constants/paths';

interface SignupWelcomePageProps extends RouteComponentProps {
  clubLogoUrl: string;
  userFirstName: string;
  validateHobbyTeamToken: (token: string) => void;
  signOut: () => void;
  isAuthenticated?: Boolean;
  serverError?: boolean;
  loading?: boolean;
  isHobbyTeam?: boolean;
  mainUserRole?: Roles | null;
  userRoles: Roles[];
}

const SignupWelcomePage: FC<SignupWelcomePageProps> = ({
  clubLogoUrl,
  isHobbyTeam,
  loading,
  userRoles,
  mainUserRole,
  isAuthenticated,
  signOut,
  location,
  userFirstName,
}) => {
  const queryParams = queryString.parse(location.search);
  const [invitation, setInvitation] = useState<InvitationQueryParams | null>();
  const inviteJwtToken = queryParams[UrlParams.INVITATION_TOKEN] as string;
  useEffect(() => {
    if (inviteJwtToken) {
      setInvitation(jwtDecoder(inviteJwtToken, UrlParams.INVITATION_TOKEN));
    }
  }, [inviteJwtToken]);

  const renderInvitationModal = useCallback(() => {
    if (inviteJwtToken && invitation) {
      return (
        <InvitationModal
          key={INVITATIONS_PATH}
          inviteToken={invitation.invitationToken}
          invitingPerson={invitation.invitedBy}
          invitingClub={invitation.clubName}
        />
      );
    }
    return null;
  }, [inviteJwtToken, invitation]);

  const [translate] = useTranslation();

  const userRole = useMemo(() => (isHobbyTeam ? 'HOBBY_TEAM' : mainUserRole), [mainUserRole, isHobbyTeam]);

  const labelText = {
    [Roles.CLUB_ADMIN]: 'WELCOME_PAGE.LABEL_TEXT_CA',
    [Roles.TEAM_LEAD]: 'WELCOME_PAGE.LABEL_TEXT_TL',
    [Roles.PLAYER]: 'WELCOME_PAGE.LABEL_TEXT_PL',
    HOBBY_TEAM: 'WELCOME_PAGE.LABEL_TEXT_HOBBY',
  };

  const mobileText = {
    [Roles.TEAM_LEAD]: 'WELCOME_PAGE.MOBILE_TEXT_TL',
    [Roles.PLAYER]: 'WELCOME_PAGE.MOBILE_TEXT_PL',
    HOBBY_TEAM: 'WELCOME_PAGE.MOBILE_TEXT_HOBBY',
  };

  const successfulRegistrationText = {
    [Roles.CLUB_ADMIN]: 'WELCOME_PAGE.SUCCESSFUL_REGISTRATION_TEXT_CA',
    [Roles.TEAM_LEAD]: 'WELCOME_PAGE.SUCCESSFUL_REGISTRATION_TEXT_TL',
    [Roles.PLAYER]: 'WELCOME_PAGE.SUCCESSFUL_REGISTRATION_TEXT_PL',
    HOBBY_TEAM: 'WELCOME_PAGE.SUCCESSFUL_REGISTRATION_TEXT_HOBBY',
  };

  const noteText = {
    [Roles.CLUB_ADMIN]: 'WELCOME_PAGE.NOTE_TEXT_CA',
    [Roles.TEAM_LEAD]: 'WELCOME_PAGE.NOTE_TEXT_TL',
  };

  const headerText = {
    [Roles.CLUB_ADMIN]: 'WELCOME_PAGE.HEADER_TEXT_CA',
    [Roles.TEAM_LEAD]: 'WELCOME_PAGE.HEADER_TEXT_TL',
    [Roles.PLAYER]: 'WELCOME_PAGE.HEADER_TEXT_PL',
    HOBBY_TEAM: 'WELCOME_PAGE.HEADER_TEXT_HOBBY',
  };

  const manageText = {
    [Roles.CLUB_ADMIN]: 'WELCOME_PAGE.MANAGE_YOUR_CLUB',
    [Roles.TEAM_LEAD]: 'WELCOME_PAGE.MANAGE_YOUR_TEAM',
  };

  const handleGoBack = () => {
    if (isAuthenticated) {
      signOut();
    }
  };

  return (
    <AuthenticationFormsWrapper clubLogoUrl={clubLogoUrl}>
      { !loading && (
        <div className={styles.wrapper}>
          <HeaderTag successTitle={labelText[
            userRole as Roles.TEAM_LEAD | Roles.CLUB_ADMIN | Roles.PLAYER | 'HOBBY_TEAM']}
          />
          <h2 className={styles.header}>
            {translate(headerText[
              userRole as Roles.TEAM_LEAD | Roles.CLUB_ADMIN | Roles.PLAYER | 'HOBBY_TEAM'])}
          </h2>
          <span className={[styles.text].join(' ')}>
            {translate('HI')}
            <span className={styles.capitalizedName}>{(userFirstName && ` ${userFirstName}!`) || '!'}</span>
          </span>
          <p className={[styles.text, styles.successfulText, styles.shrink].join(' ')}>
            {translate(successfulRegistrationText[
              userRole as Roles.TEAM_LEAD | Roles.CLUB_ADMIN | Roles.PLAYER | 'HOBBY_TEAM'])}
          </p>
          {
            !isHobbyTeam
            && userRoles.some(role => [Roles.CLUB_ADMIN, Roles.TEAM_LEAD].includes(role))
            && (
              <Linkify>
                <p className={[styles.text, styles.manageText].join(' ')}>
                  {`${translate(manageText[userRole as Roles.TEAM_LEAD | Roles.CLUB_ADMIN])} ${window.location.origin}${SIGN_IN_ROUTE}.`}
                </p>
              </Linkify>
            )
          }
          {userRoles.some(role => [Roles.PLAYER, Roles.TEAM_LEAD].includes(role)) && (
            <MobileApps title={mobileText[userRole as Roles.TEAM_LEAD | Roles.PLAYER | 'HOBBY_TEAM']} />
          )}
          {userRoles.some(role => [Roles.CLUB_ADMIN, Roles.TEAM_LEAD].includes(role)) && (
            <p className={[styles.text, styles.noteText, styles.shrink].join(' ')}>
              {translate(noteText[userRole as Roles.TEAM_LEAD | Roles.CLUB_ADMIN])}
            </p>
          )}
          <Signature showHaveFun />
          {userRoles.some(role => [Roles.PLAYER].includes(role)) && (
            <Link onClick={handleGoBack} to={SIGN_IN_ROUTE} className={styles.backLink}>
              <div className={styles.arrow} />
              {translate('GO_TO_LOGIN_PAGE')}
            </Link>
          )}
        </div>
      )}
      {renderInvitationModal()}
    </AuthenticationFormsWrapper>
  );
};

export default SignupWelcomePage;
