import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import AssignToTeamModal from './AssignToTeamModal';
import { State } from '../../../../models/State';
import { bulkAssignMembersRequest } from '../../../../actions/members';
import TeamStatus from '../../../../constants/teamStatus';
import { fetchTeamsRequest } from '../../../../actions/teams';

const mapStateToProps = ({ teams: { byId, byHash, loading } }: State) => ({
  loading,
  teams: (byId || []).map(id => byHash[id]).filter(({ status }) => status === TeamStatus.ACTIVE),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  bulkAssignMembers: bindActionCreators(bulkAssignMembersRequest, dispatch),
  fetchTeams: bindActionCreators(fetchTeamsRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AssignToTeamModal));
