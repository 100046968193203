import React, { FC, useRef, useState } from 'react';
import {
  Modal, Input, Button, Label,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';

import styles from './InvitationUrlPopup.module.scss';
import { UrlParams } from '../../../../../constants/urlParams';

interface InvitationUrlPopupProps {
  inviteToken: string,
  isOpen: boolean;
  closePopup: () => void;
}

const InvitationUrlPopup: FC<InvitationUrlPopupProps> = ({
  inviteToken,
  isOpen,
  closePopup,
}) => {
  const [translate] = useTranslation();
  const [isTokenCopied, setIsTokenCopied] = useState(false);
  const params = queryString
    .stringify({ [UrlParams.INVITATION_TOKEN]: inviteToken });
  const inputRef = useRef<Input>(null);

  const copyToClipboard = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.select();
      document.execCommand('copy');
      setIsTokenCopied(true);
    }
  };

  const renderPopupContent = () => (
    <div>
      { isTokenCopied && (
      <Label
        className={[styles['copied-label'], styles.absolute, styles.message].join(' ')}
        pointing="below"
      >
        {translate('COPIED_TO_CLIPBOARD')}
      </Label>
      )}
      <Input
        type="text"
        action
        ref={inputRef}
        className={styles['invite-link-input']}
        defaultValue={`${window.location.origin}/?${params}`}
      >
        <input readOnly />
        <Button basic icon="copy" onClick={copyToClipboard} />
      </Input>
      <span className={styles['invite-message']}>{translate('INVITE_WARNING_MESSAGE')}</span>
    </div>
  );

  return (
    <Modal
      open={isOpen}
      onClose={closePopup}
      closeIcon
      closeOnDimmerClick={false}
    >
      <Modal.Header content={translate('INVITATION_LINK')} />
      <Modal.Content>
        { renderPopupContent() }
      </Modal.Content>
    </Modal>
  );
};

export default InvitationUrlPopup;
