import { AjaxError } from 'rxjs/ajax';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import {
  catchError, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import {
  SET_TOR24_TEAMS_ERROR, SET_TOR24_TEAMS_REQUEST,
} from '../../constants/actionTypes';
import { EXTERNAL_SYSTEM_SET_PATH } from '../../constants/paths';
import { State } from '../../models/State';
import { handleError } from '../../actions/errors';
import { SetTor24TeamsRequestAction } from '../../models/actions/externalSystem';

import { GroupType } from '../../models/Catalog';
import {
  ExternalSystemName,
  ExternalSystemReference,
} from '../../models/externalSystemIntegration/externalSystemReference';
import { setTor24TeamsError, setTor24TeamsSuccess } from '../../actions/externalSystems/setTor24Team';

export default (
  action$: ActionsObservable<SetTor24TeamsRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(SET_TOR24_TEAMS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      tor24TeamId,
      teamId,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: EXTERNAL_SYSTEM_SET_PATH,
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify({
      references: [{
        entityType: GroupType.TEAM,
        entityId: teamId,
        externalSystemName: ExternalSystemName.TOR_24,
        externalSystemEntityId: tor24TeamId,
      }],
    }),
  }).pipe(
    mergeMap((externalRefs: { response: ExternalSystemReference[] }) => [
      setTor24TeamsSuccess(externalRefs),
    ]),
    catchError((error: AjaxError) => [
      handleError(error, SET_TOR24_TEAMS_ERROR),
      setTor24TeamsError(error),
    ]),
  )),
);
