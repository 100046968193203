import React,
{ FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IndividualOrdersProps } from './IndividualOrdersProps';
import styles from '../Orders/components/OrdersDesktop/OrdersDesktop.module.scss';
import Header from '../../components/Header';
import OrderTabsComponent from '../../components/OrderTabsComponent/OrderTabsComponent';
import TableContainer from '../../components/Layout/components/TableContainer';
import { history } from '../../configureStore';
import { IndividualOrderListItem } from '../../models/IndividualOrder';
import Row from '../../components/Table/components/Row';
import filteredColumns from './filteredColumns';
import Text from '../../components/Text';
import { formatPrice } from '../../utils/PricingUtil';
import { formatDate } from '../../utils/dateUtils';

const IndividualOrders: FC<IndividualOrdersProps> = ({
  orders,
  loading,
  count,
  fetchOrders,
}) => {
  const [translate] = useTranslation();
  const renderRows = ({
    orderId, itemsSummedPrice, createdBy, createdAt,
  }: IndividualOrderListItem) => (
    <Row key={orderId}>
      <div>{`#${orderId}`}</div>
      <Text>{formatDate(createdAt)}</Text>
      <Text>
        {createdBy.firstName}
&nbsp;
        {createdBy.lastName}
      </Text>
      <Text>
        {itemsSummedPrice ? formatPrice(itemsSummedPrice.amount, itemsSummedPrice.currency) : formatPrice(0, 'EUR')}
      </Text>
    </Row>
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.topGroup}>
        <Header
          title={translate('ORDERS')}
          border
        />
        <div className={styles['content-wrapper']}>
          <OrderTabsComponent />
          <TableContainer
            fetch={fetchOrders}
            loading={loading}
            count={count}
            history={history}
            columns={filteredColumns(translate)}
            renderItems={orders.map(renderRows)}
            disableSearch
            invertedHeader
            emptyTableText="NO_ORDERS_AVAILABLE"
          />
        </div>
      </div>
    </div>
  );
};
export default IndividualOrders;
