import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import { FETCH_PLAYER_ERROR, FETCH_PLAYER_REQUEST } from '../../constants/actionTypes';
import { FetchPlayerRequestAction } from '../../models/actions';
import { Player } from '../../models/Player';
import { Response } from '../../models/Response';
import { State } from '../../models/State';
import { TEAM_PLAYER_PATH } from '../../constants/paths';
import { fetchPlayerError, fetchPlayerSuccess } from '../../actions/players';
import { handleError } from '../../actions/errors';

export default (
  action$: ActionsObservable<FetchPlayerRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_PLAYER_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      teamId, playerId,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: TEAM_PLAYER_PATH
      .replace('$teamId', `${teamId}`)
      .replace('$playerId', `${playerId}`),
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    map(({ response: player }: Response<Player>) => fetchPlayerSuccess(player)),
    catchError((error: AjaxError) => [
      handleError(error, FETCH_PLAYER_ERROR),
      fetchPlayerError(error),
    ]),
  )),
);
