import { Roles } from '../constants/roles';

export enum PlayerPosition {
  GOALKEEPER = 'GOALKEEPER',
  CENTER_BACK = 'CENTER_BACK',
  LEFT_FULL_BACK = 'LEFT_FULL_BACK',
  RIGHT_FULL_BACK = 'RIGHT_FULL_BACK',
  DEFENSIVE_MIDFIELD = 'DEFENSIVE_MIDFIELD',
  LEFT_MIDFIELD = 'LEFT_MIDFIELD',
  RIGHT_MIDFIELD = 'RIGHT_MIDFIELD',
  ATTACKING_MIDFIELD = 'ATTACKING_MIDFIELD',
  WINGER = 'WINGER',
  CENTER_FORWARD = 'CENTER_FORWARD',
  SWEEPER = 'SWEEPER',
}

export enum Footedness {
  BOTH = 'BOTH',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
}

export enum Sizes {
  XS = 'XS',
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL',
  XXL = 'XXL',
}

export interface PlayerAddress {
  city: string;
  country: string;
  number: string;
  postCode: string;
  street: string;
}

export interface PlayerSocialmedia {
  profileUrl: string;
  socialMedia: string;
}

export interface PlayerEmergencyContact {
  name?: string;
  relation?: string;
  phoneNumber?: string;
}

export interface PlayerSizes {
  SHIRT: Sizes;
  SHORT: Sizes;
  SHOE_AND_SOCK_SIZE: Sizes;
}

export interface PlayerBase {
  address?: PlayerAddress;
  alternativePosition?: PlayerPosition;
  email: string;
  emergencyContact: PlayerEmergencyContact;
  firstName: string;
  footedness?: Footedness;
  gender?: Gender;
  height?: number;
  joinedClubDate: number;
  lastName: string;
  nationality?: string;
  nickname?: string;
  number?: number;
  teamNumber?: number;
  passNumber?: string;
  phoneNumber?: string;
  preferredPosition?: PlayerPosition;
  previousClubs?: string[];
  secondaryEmail?: string;
  shoePreferences?: string[];
  sizes?: PlayerSizes;
  socialMedias?: PlayerSocialmedia[];
  userExists?: boolean;
  profilePictureReference?: string;
  roles?: Roles[];
}

export interface PlayerListTL {
  id: number,
  personId: number,
  userId: number,
  firstName: string,
  lastName: string,
  number?: number,
  teamNumber?: number,
  preferredPosition?: PlayerPosition,
  height?: number,
  footedness?: Footedness,
  joinedClubDate?: Date | number,
  userExists?: boolean;
}

export interface PlayerFormData {
  email?: string,
  firstName: string,
  lastName: string,
  number?: number,
  phoneNumber?: string,
  preferredPosition?: string,
}

export interface PlayerEditFormData extends PlayerBase {}

export interface Player extends PlayerBase {
  id: number;
  personId: number;
  dayOfBirth?: string;
  userId?: number;
  createdAt: number;
  teamId: string;
}

export interface PlayersByHash {
  [key: string]: Player;
}

export interface PlayersReducer {
  byHash: PlayersByHash;
  byId: number[];
  selectedId: number[];
  count: number;
  selectedMembersTeam?: number | null;
  error: boolean;
  errorMessage: string;
  loading: boolean;
  playerDeleted: boolean;
}

export interface PlayerTeamList {
  id: number;
  personId: number;
  firstName: string;
  lastName: string;
  profilePictureReference?: string;
  userExists?: boolean;
}
