import { connect } from 'react-redux';

import { State } from '../../../../models/State';
import MobileModal from './MobileModal';

const mapStatesToProps = ({
  application: { clubId, teamId },
  catalog: {
    catalog: {
      productsById,
      productsByHash,
    },
    loading,
  },
}: State) => ({
  clubId,
  teamId,
  productsById,
  productsByHash,
  loading,
});

export default connect(mapStatesToProps)(MobileModal);
