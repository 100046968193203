import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import styles from './DektopFooter.module.scss';
import FooterSelectedNumber from './FooterSelectedNumber';

interface DektopFooterProps {
  selected: any[];
  openModal: () => void;
  counterLabel: string;
  buttonLabel: string;
}

const DektopFooter = ({
  selected,
  openModal,
  counterLabel,
  buttonLabel,
}: DektopFooterProps) => {
  const [translate] = useTranslation();

  return (
    <div className={styles.footer}>
      <FooterSelectedNumber text={translate(counterLabel, { count: selected.length })} />
      <Button
        circular
        disabled={!selected.length}
        onClick={openModal}
        primary
        className={styles.footerButton}
      >
        {translate(buttonLabel, { count: selected.length })}
      </Button>
    </div>
  );
};

export default DektopFooter;
