import { Person, PersonResponse } from '../../models/ClubManagement';
import { Roles } from '../../constants/roles';
import { Player } from '../../models/Player';

const serializePerson = (person: PersonResponse, player: Player, memberRoles: Roles[]): Person => {
  const { roles, ...rest } = person;

  if (memberRoles.includes(Roles.PLAYER)) {
    (rest as Person).playerId = player.id;
  }

  return {
    ...rest,
    roles: memberRoles,
  };
};

export default serializePerson;
