import React, { FC } from 'react';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import Header, { HeaderTypes } from '../../../../components/Header';
import styles from './ProductEmbellishments.module.scss';
import { ProductEmbellishmentsProps } from './ProductEmbellishmentsProps';
import { ProductEmbellishment } from '../../../../models/Embellishment';
import PriceDiscountsPopup from '../PriceDiscountsPopup';

const ProductEmbellishments: FC<ProductEmbellishmentsProps> = ({
  embellishments, embellishmentsByHash, clubId,
}) => {
  const [translate] = useTranslation();

  return (
    <div className={styles.wrapper}>
      <Header
        title={translate('EMBELLISHMENT')}
        type={HeaderTypes.H3}
      />
      {embellishments.length ? (
        <Table striped basic="very" className={styles.table}>
          <Table.Body>
            {embellishments.map((embellishment: ProductEmbellishment) => (
              <Table.Row key={`${embellishment.embellishment}-${embellishment.mandatory}-${embellishment.fixedPricePerUnit}`}>
                <Table.Cell>
                  {translate(embellishment.embellishment)}
                </Table.Cell>
                <Table.Cell>
                  {translate(embellishment.mandatory ? 'MANDATORY' : 'OPTIONAL')}
                </Table.Cell>
                <Table.Cell collapsing textAlign="right">
                  <PriceDiscountsPopup
                    productEmbellishments={[embellishment]}
                    embellishments={
                      [embellishmentsByHash[embellishment.embellishment]].filter(Boolean)
                    }
                    clubId={clubId}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        <p>
          {translate('NO_EMBELLISHMENTS')}
        </p>
      )}
    </div>
  );
};

export default ProductEmbellishments;
