import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { map, withLatestFrom } from 'rxjs/operators';

import { ORDER_UPDATE_ITEMS } from '../../../constants/actionTypes';
import { State } from '../../../models/State';
import { UpdateItemsAction } from '../../../models/actions';
import { orderUpdatePriceRequest } from '../../../actions/orderEditor';

const orderUpdateItems = (
  action$: ActionsObservable<UpdateItemsAction>,
  state$: StateObservable<State>,
) => action$.pipe(
  ofType(ORDER_UPDATE_ITEMS),
  withLatestFrom(state$),
  map(orderUpdatePriceRequest),
);

export default orderUpdateItems;
