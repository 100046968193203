import React, { FC } from 'react';
import styles from './LabelInputField.module.scss';

interface LabelValueFieldProps {
  label: string;
  input: () => Element|JSX.Element;
  required?: boolean
}

const LabelValueField: FC<LabelValueFieldProps> = ({ label, input, required }) => (
  <div className={styles.field}>
    <span className={styles.label}>{`${label}${required ? '*' : ''}`}</span>
    {input()}
  </div>
);

export default LabelValueField;
