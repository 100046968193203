import { v4 as uuidv4 } from 'uuid';
import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from '../constants/actionTypes';
import {
  AddNotificationAction,
  NotificationType,
  RemoveNotificationAction,
} from '../models/actions';

export const addNotification = (
  type: NotificationType,
  actionType: string,
  status?: number,
  message?: string,
  id: string = uuidv4(),
): AddNotificationAction => ({
  type: ADD_NOTIFICATION,
  payload: {
    type,
    actionType,
    status,
    message,
    id,
  },
});

export const removeNotification = (id: string): RemoveNotificationAction => ({
  type: REMOVE_NOTIFICATION,
  payload: {
    id,
  },
});
