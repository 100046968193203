import React, { ReactNode } from 'react';
import {
  Modal,
} from 'semantic-ui-react';
import { HeaderTypes } from '../../../../components/Header';
import MobileHeader from '../../../../components/MobileHeader';
import { MobileHeaderProps } from '../../../../components/MobileHeader/MobileHeaderProps';

import styles from './MobileModal.module.scss';

export interface MobileModalProps extends MobileHeaderProps {
  isOpen: boolean;
  title: string;
  content: ReactNode;
  button: ReactNode;
  type?: HeaderTypes;
  closeIcon?: boolean;
  border?: boolean;
  onMount?: any;
  onClose?: () => void;
}

const MobileModal = ({
  isOpen,
  title,
  content,
  button,
  closeIcon = true,
  titleEllipsis,
  border = false,
  onMount,
  onClose,
  menuItems,
  subtitle,
  titleType = HeaderTypes.H3,
  subtitleType,
  primaryTitle,
  primarySubtitle,
}: MobileModalProps) => (
  <Modal
    open={isOpen}
    onClose={onClose}
    closeIcon={closeIcon}
    size="large"
    style={{ padding: 0, margin: 0 }}
    onMount={onMount}
  >
    <div className={styles.wrapper}>
      <MobileHeader
        menuItems={menuItems}
        title={title}
        subtitle={subtitle}
        titleType={titleType}
        subtitleType={subtitleType}
        border={border}
        primaryTitle={primaryTitle}
        primarySubtitle={primarySubtitle}
        titleEllipsis={titleEllipsis}
      />
      <div className={styles.content}>
        {content}
      </div>
      <div className={styles.bottom}>
        {button}
      </div>
    </div>
  </Modal>
);

export default MobileModal;
