import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dropdown, Icon, Label,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import Row from '../../../../../../components/Table/components/Row';
import Confirm from '../../../../../../components/Confirm';
import Text from '../../../../../../components/Text';
import styles from './OrdersMobileRow.module.scss';
import { history } from '../../../../../../configureStore';
import { formatPrice } from '../../../../../../utils/PricingUtil';
import { ORDER_ROUTE, TEAM_ROUTE } from '../../../../../../constants/routes';
import { OrderStatus } from '../../../../../../constants/orderStatus';
import { OrdersMobileRowProps } from './OrdersMobileRowProps';

const OrdersMobileRow: FC<OrdersMobileRowProps> = ({
  order: {
    id,
    team,
    status,
    price,
    idlePlayersCount,
    idleStaffCount,
    fileReference,
  },
  setStatus,
  orderContext,
  fetchFile,
  deleteOrders,
}) => {
  const [translate] = useTranslation();

  const link = ORDER_ROUTE.replace(':teamId', String(team.id)).replace(':orderId', String(id));

  const handleStatusChange = (newStatus: OrderStatus) => () => {
    setStatus(id, newStatus);
  };

  const handleDownload = () => {
    if (fileReference) {
      fetchFile(fileReference, 'xls', true);
    }
  };

  const handleDelete = () => {
    deleteOrders([id]);
  };

  const renderTeamActionButtons = () => status === OrderStatus.SENT_TO_TEAM
    && (idlePlayersCount + idleStaffCount === 0)
    && [
      <Confirm
        key="club-admin"
        confirmText={translate('SEND_TO_CLUB_ADMIN_CONFIRMATION')}
        onConfirm={handleStatusChange(OrderStatus.SENT_TO_CLUB_ADMIN)}
        triggerText={translate('SEND_TO_CLUB_ADMIN')}
        as={Dropdown.Item}
      />,
      <Confirm
        key="sales-rep"
        confirmText={translate('SEND_TO_SALES_REP_CONFIRMATION')}
        onConfirm={handleStatusChange(OrderStatus.SENT_TO_SALES_REP)}
        triggerText={translate('SEND_TO_SALES_REP')}
        as={Dropdown.Item}
      />,
    ];

  const renderClubActionButtons = () => status === OrderStatus.SENT_TO_CLUB_ADMIN && [
    <Confirm
      triggerText={translate('REJECT')}
      key="reject"
      onConfirm={handleStatusChange(OrderStatus.IN_PROGRESS)}
      as={Dropdown.Item}
      confirmText={translate('REJECT_ORDER_CONFIRMATION')}
    />,
    <Confirm
      triggerText={translate('APPROVE')}
      key="approve"
      onConfirm={handleStatusChange(OrderStatus.SENT_TO_SALES_REP)}
      as={Dropdown.Item}
      confirmText={translate('APPROVE_ORDER_CONFIRMATION')}
    />,
  ];

  const handleRowClick = () => {
    history.push(link);
  };

  const renderActionButtons = orderContext === TEAM_ROUTE
    ? renderTeamActionButtons
    : renderClubActionButtons;

  return (
    <Row key={id} classNames={styles.wrapper} itemOnClick={handleRowClick}>
      <Text bold>{`#${id}`}</Text>
      <div className={styles['order-status']}>
        <Text>{translate(status)}</Text>
        {status === OrderStatus.SENT_TO_TEAM && (idlePlayersCount + idleStaffCount > 0)
          && (
            <Label circular color="blue" className={styles['label-count']}>{idlePlayersCount + idleStaffCount}</Label>
          )
        }
      </div>
      <Text>{formatPrice(price.amount, price.currency)}</Text>

      <Dropdown icon={<Icon className={styles.ellipsis} name="ellipsis vertical" color="blue" size="large" />}>
        <Dropdown.Menu direction="left">
          {orderContext === TEAM_ROUTE
          && ![OrderStatus.SENT_TO_CLUB_ADMIN, OrderStatus.SENT_TO_SALES_REP].includes(status) ? (
            <Dropdown.Item
              text={translate('VIEW_ORDER')}
              onClick={handleDownload}
              key="view-order"
              as={Link}
              to={link}
            />
            ) : null}
          {renderActionButtons()}
          {fileReference
            && [OrderStatus.SENT_TO_CLUB_ADMIN, OrderStatus.SENT_TO_SALES_REP].includes(status)
            && [
              <Dropdown.Item
                text={translate('DOWNLOAD')}
                onClick={handleDownload}
                key="download"
              />,
            ]}
          { orderContext === TEAM_ROUTE && (
            <Confirm
              triggerText={translate('DELETE')}
              key="delete"
              onConfirm={handleDelete}
              as={Dropdown.Item}
              confirmText={translate('DELETE_ORDER_CONFIRMATION')}
            />
          )}
        </Dropdown.Menu>
      </Dropdown>
    </Row>
  );
};

export default OrdersMobileRow;
