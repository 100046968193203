import React, { useEffect } from 'react';
import { Button, Input, Pagination } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import usePagination from '../../../../hooks/usePagination';
import useSearchFilter from '../../../../hooks/useSearchFilter';
import useSorting from '../../../../hooks/useSorting';
import params from '../../../../utils/params';
import styles from './TableContainer.module.scss';
import { TableContainerProps } from './TableContainerProps';
import Table from '../../../Table';
import { SortOrder } from '../../../../models/general/SortOrder';
import {
  DEFAULT_LIST_LIMIT, TABLE_FILTER_URL_PARAM, TABLE_PAGE_URL_PARAM,
  TABLE_SEARCH_URL_PARAM, TABLE_SORT_FIELD_URL_PARAM,
  TABLE_SORT_ORDER_URL_PARAM,
} from '../../../../constants/customizations';
import { useViewport } from '../../../../hooks/useViewport';
import {
  ListFilters,
} from '../../../../models/ListFilters';
import useFilters from '../../../../hooks/useFilters';

const TableContainer = ({
  fetch,
  loading,
  count,
  history,
  columns,
  renderItems,
  renderFilters,
  renderNewElementLogic,
  searchPlaceholder,
  disableSearch = false,
  invertedHeader = false,
  emptyTableText,
  limit = DEFAULT_LIST_LIMIT,
  extraFilters,
  showHeaderOnEmpty = false,
}: TableContainerProps) => {
  const { getParams } = params(history);
  const [translate] = useTranslation();
  const { isMobile } = useViewport();
  const [onPageChange, totalPages, showPagination] = usePagination(history, count, loading, limit);
  const [toggleFilter, onChange, filterPanelVisible] = useSearchFilter(history);
  const { onFilterChange, filter: savedFilters, setFilter } = useFilters(history);

  const {
    onSortChange,
    sortOrder,
    setSortOrder,
    sortField,
    setSortField,
  } = useSorting(history);

  const {
    [TABLE_SEARCH_URL_PARAM]: search,
    [TABLE_PAGE_URL_PARAM]: page,
    [TABLE_SORT_FIELD_URL_PARAM]: field,
    [TABLE_SORT_ORDER_URL_PARAM]: order,
    [TABLE_FILTER_URL_PARAM]: filter,
  }: ListFilters = getParams();

  useEffect(() => {
    if (filter) {
      setFilter(JSON.parse(filter as string));
    }
  }, [filter, setFilter]);

  useEffect(() => {
    fetch({
      page, search, field, order, filter,
    });
    setSortField(field as string);
    setSortOrder(order as SortOrder);
  }, [fetch, page, search, field, order, setSortField, setSortOrder, filter]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.searchExtraFiltersWrapper}>
          {(!disableSearch || !!extraFilters) && (
          <div className={styles.header}>
            {!disableSearch && (
            <div className={styles.searchFilters}>
              {!!renderNewElementLogic && renderNewElementLogic()}

              <Input
                className={`search ${styles.searchBar}`}
                onChange={onChange as any}
                placeholder={translate(searchPlaceholder || 'SEARCH')}
                icon="search"
              />

              {!!renderFilters && (
              <Button
                basic
                icon="filter"
                              // @ts-ignore
                onClick={toggleFilter}
              />
              )}
            </div>
            )}
            {!!extraFilters && (extraFilters)}
          </div>
          )}
        </div>
        <div className={styles.wrapper}>
          <Table
            columns={columns}
            loading={loading}
            onFilterChange={onFilterChange}
            filters={savedFilters}
            onSortChange={onSortChange}
            sortOrder={sortOrder}
            sortField={sortField}
            invertedHeader={invertedHeader}
            emptyTableText={emptyTableText}
            showHeaderOnEmpty={showHeaderOnEmpty}
          >
            {renderItems}
          </Table>
        </div>
        <div className={styles.paginationWrapper}>
          {showPagination && (// @ts-ignore
            <Pagination
              // @ts-ignore
              defaultActivePage={page || 1}
              // @ts-ignore
              totalPages={totalPages}
              // @ts-ignore
              onPageChange={onPageChange}
              siblingRange={1}
              boundaryRange={isMobile ? 0 : 1}
              ellipsisItem={isMobile ? null : '...'}
              firstItem={isMobile ? null : {
                'aria-label': 'First item',
                content: '«',
              }}
              lastItem={isMobile ? null : {
                'aria-label': 'Last item',
                content: '»',
              }}
              className={classNames(styles.pagination, isMobile && styles.mini)}
            />
          )}
        </div>
      </div>
      { !!renderFilters && renderFilters({ visible: filterPanelVisible }) }
    </>
  );
};

export default TableContainer;
