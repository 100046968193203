import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FooterProps } from './FooterProps';
import DektopFooter from '../DesktopFooter';
import { OrderItemType } from '../../OrderPreparationProps';
import { useViewport } from '../../../../hooks/useViewport';
import MobileFooter from '../../../../components/MobileFooter';

const Footer: FC<FooterProps> = ({
  activeTab,
  openModal,
  selectedPlayersIds,
  selectedStaffIds,
  selectedProductsIds,
}) => {
  const selectedIds = useMemo(
    () => [...selectedStaffIds, ...selectedPlayersIds], [selectedStaffIds, selectedPlayersIds],
  );
  const [translate] = useTranslation();
  const { isMobile } = useViewport();
  const buttonLabels = useMemo(() => {
    if (selectedStaffIds.length && selectedPlayersIds.length) {
      return {
        counterLabel: 'MEMBER_SELECTED_WITH_COUNT',
        buttonLabel: 'ADD_PRODUCTS_TO_AUTHORIZED_MEMBERS_WITH_COUNT',
      };
    }
    if (!selectedStaffIds.length && !selectedPlayersIds.length) {
      return {
        counterLabel: activeTab === OrderItemType.STAFF
          ? 'STAFF_SELECTED_WITH_COUNT'
          : 'PLAYER_SELECTED_WITH_COUNT',
        buttonLabel: activeTab === OrderItemType.STAFF
          ? 'ADD_PRODUCTS_TO_AUTHORIZED_STAFF_WITH_COUNT'
          : 'ADD_PRODUCTS_TO_AUTHORIZED_PLAYERS_WITH_COUNT',
      };
    }
    if (selectedStaffIds.length) {
      return {
        counterLabel: 'STAFF_SELECTED_WITH_COUNT',
        buttonLabel: 'ADD_PRODUCTS_TO_AUTHORIZED_STAFF_WITH_COUNT',
      };
    }
    return {
      counterLabel: 'PLAYER_SELECTED_WITH_COUNT',
      buttonLabel: 'ADD_PRODUCTS_TO_AUTHORIZED_PLAYERS_WITH_COUNT',
    };
  }, [selectedStaffIds, selectedPlayersIds, activeTab]);

  if (activeTab === OrderItemType.PRODUCT) {
    return isMobile ? (
      <MobileFooter
        buttonText={
          translate(
            'ADD_NEW_MEMBER_PER_ARTICLE_WITH_COUNT', { count: selectedProductsIds.length },
          )
        }
        // @ts-ignore
        disabled={!selectedProductsIds.length}
        onClick={openModal}
      />
    ) : (
      <DektopFooter
        selected={selectedProductsIds}
        openModal={openModal}
        counterLabel="ARTICLE_SELECTED_WITH_COUNT"
        buttonLabel="ADD_NEW_MEMBER_PER_ARTICLE_WITH_COUNT"
      />
    );
  }

  return isMobile ? (
    <MobileFooter
      buttonText={
        translate(
          buttonLabels.buttonLabel, { count: selectedIds.length },
        )
      }
      // @ts-ignore
      disabled={!selectedIds.length}
      onClick={openModal}
    />
  ) : (
    <DektopFooter
      selected={selectedIds}
      openModal={openModal}
      counterLabel={buttonLabels.counterLabel}
      buttonLabel={buttonLabels.buttonLabel}
    />
  );
};

export default Footer;
