import { AjaxError } from 'rxjs/ajax';
import * as types from '../constants/actionTypes';
import { User } from '../models/general/User';
import {
  FetchUserInfoRequestAction,
  FetchUserInfoSuccessAction,
  SetUserAction,
  UpdateUserErrorAction,
  UpdateUserRequestAction,
  UpdateUserSuccessAction,
  UploadAvatarErrorAction,
  UploadAvatarRequestAction,
  UploadAvatarSuccessAction,
  DeleteDataRequestAction,
  DeleteDataSuccessAction,
  DeleteDataErrorAction,
} from '../models/actions';

export const setUser = (user: User): SetUserAction => ({
  type: types.SET_USER,
  payload: {
    user,
  },
});

export const updateUserRequest = (
  firstName: string, lastName: string, phoneNumber: string | null = null,
): UpdateUserRequestAction => ({
  type: types.UPDATE_USER_REQUEST,
  payload: {
    firstName,
    lastName,
    phoneNumber,
  },
});

export const updateUserSuccess = (user: User): UpdateUserSuccessAction => ({
  type: types.UPDATE_USER_SUCCESS,
  payload: {
    user,
  },
});

export const updateUserError = (error: AjaxError): UpdateUserErrorAction => ({
  type: types.UPDATE_USER_ERROR,
  payload: {
    error,
  },
});

export const fetchUserInfoRequest = (): FetchUserInfoRequestAction => ({
  type: types.FETCH_USER_INFO_REQUEST,
});

export const fetchUserInfoSuccess = (
  user: User,
): FetchUserInfoSuccessAction => ({
  type: types.FETCH_USER_INFO_SUCCESS,
  payload: {
    user,
  },
});

export const uploadAvatarRequest = (
  image: File,
): UploadAvatarRequestAction => ({
  type: types.UPLOAD_AVATAR_REQUEST,
  payload: {
    image,
  },
});

export const uploadAvatarSuccess = (
  fileId: string,
): UploadAvatarSuccessAction => ({
  type: types.UPLOAD_AVATAR_SUCCESS,
  payload: {
    fileId,
  },
});

export const uploadAvatarError = (
  error: AjaxError,
): UploadAvatarErrorAction => ({
  type: types.UPLOAD_AVATAR_ERROR,
  payload: {
    error,
  },
});

export const deleteDataRequest = (
  token: string,
): DeleteDataRequestAction => ({
  type: types.DELETE_DATA_REQUEST,
  payload: {
    token,
  },
});

export const deleteDataSuccess = (
): DeleteDataSuccessAction => ({
  type: types.DELETE_DATA_SUCCESS,
});

export const deleteDataError = (
  error: AjaxError,
): DeleteDataErrorAction => ({
  type: types.DELETE_DATA_ERROR,
  payload: {
    error,
  },
});
