import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button, Dimmer, Loader, Modal,
} from 'semantic-ui-react';

import HeaderComponent from '../../components/Header';
import styles from './PlayerProfile.module.scss';
import { EDIT_PLAYER_ROUTE, TEAM_PLAYERS_ROUTE } from '../../constants/routes';
import { PlayerProfileProps } from './PlayerProfileProps';
import { Roles } from '../../constants/roles';
import Confirm from '../../components/Confirm';
import Profile from './components/Profile/Profile';
import { Player } from '../../models/Player';

const PlayerProfile: FC<PlayerProfileProps> = ({
  fetchPlayer, player, loading, userRole,
  deletePlayer,
  match: { params: { teamId, playerId } },
  clubId,
  sendInvitation,
}) => {
  const [translate] = useTranslation();
  const [inviteModalOpened, setInviteModalOpened] = useState(false);
  const {
    firstName = '', lastName = '', email = '', secondaryEmail = '', personId,
  } = player || {} as Player;

  useEffect(() => {
    fetchPlayer(+teamId, +playerId);
  }, [fetchPlayer, teamId, playerId]);

  const getPlayerLink = (route: string) => route
    .replace(':teamId', String(teamId))
    .replace(':playerId', String(playerId));

  const handleDeletePlayer = () => {
    deletePlayer([personId].filter(Boolean), +teamId);
  };

  const toggleInviteModal = () => {
    setInviteModalOpened(isOpened => !isOpened);
  };

  const handleInvitationClick = (usePrimaryEmail: boolean) => () => {
    if (player && clubId) {
      const invitationPersonalData = { firstName, lastName };
      const createInvitation = {
        receiverEmail: usePrimaryEmail ? email : secondaryEmail,
        receiverRole: Roles.PLAYER,
        teamId: +teamId,
        clubId,
        playerId,
      };

      sendInvitation(createInvitation, invitationPersonalData, false);
      setInviteModalOpened(false);
    }
  };

  const handleInviteClick = () => {
    if (email && secondaryEmail) {
      toggleInviteModal();
    } else if (email || secondaryEmail) {
      handleInvitationClick(!!email)();
    }
  };

  const renderNewElementLogic = () => (userRole === Roles.TEAM_LEAD ? [
    <Button
      basic
      primary
      content={translate('INVITE_PLAYER')}
      key="button1"
      onClick={handleInviteClick}
      className={[styles.inviteButton, styles.invitePlayer].join(' ')}
    />,
    <Confirm
      basic
      confirmText={translate('CONFIRM_MEMBER_DELETION_MESSAGE')}
      onConfirm={handleDeletePlayer}
      primary
      key="button3"
      triggerText={translate('DELETE_PLAYER')}
    />,
    <Button
      as={Link}
      primary
      content={translate('EDIT_PLAYER')}
      key="button2"
      to={getPlayerLink(EDIT_PLAYER_ROUTE)}
    />,
  ] : []);

  return loading ? <Dimmer active inverted><Loader /></Dimmer> : (
    <div className={styles.wrapper}>
      <HeaderComponent
        title={!player ? translate('PLAYER.NOT_FOUND') : undefined}
        renderButton={player && renderNewElementLogic}
        backLinkTo={TEAM_PLAYERS_ROUTE.replace(':teamId', String(teamId))}
        backLabel={translate('BACK_TO_TEAMS')}
      />
      { player && <Profile player={player} />}
      <Modal
        open={inviteModalOpened}
        size="tiny"
        closeIcon
        onClose={toggleInviteModal}
      >
        <Modal.Header>{translate('INVITE_PLAYER')}</Modal.Header>
        <Modal.Content className={styles.content}>
          <span className={styles.content}>
            {translate('CHOOSE_EMAIL_TO_INVITE_PLAYER')}
          </span>
        </Modal.Content>
        <Modal.Actions className={styles.actions}>
          <div className={styles.actions}>
            <Button onClick={handleInvitationClick(true)} primary fluid>
              {translate('USE_PRIMARY_EMAIL')}
            </Button>
            <Button basic primary fluid onClick={handleInvitationClick(false)}>
              {translate('USE_SECONDARY_EMAIL')}
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default PlayerProfile;
