import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import Page404 from './Page404';
import { State } from '../../models/State';
import { signOut } from '../../packages/authentication/actions';

const mapStateToProps = ({ clubs: { logoUrl } }: State) => ({
  clubLogoUrl: logoUrl,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  signOut: bindActionCreators(signOut, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Page404);
