import { isRequired } from '../../../../utils/formValidators';
import { FormSchema } from '../../../../models/Form';
import Seasons from '../../../../constants/seasons';

export const createTeamSchema: FormSchema = ({
  teamName: {
    type: 'text',
    label: 'TEAM_NAME',
    required: true,
  },
  season: {
    type: 'select',
    label: 'SEASON',
    placeholder: 'SEASON',
    required: false,
    options: (translate) => Object.keys(Seasons).map((key: string) => ({
      value: key,
      text: translate(key),
      key,
    })),
  },
  seasonStartDate: {
    type: 'date',
    label: 'SEASON_START',
    required: false,
  },
  seasonEndDate: {
    type: 'date',
    label: 'SEASON_END',
    required: false,
  },

});

export const validationSchema = {
  teamName: [isRequired],
};
