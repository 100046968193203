import {
  PendingStaffMember,
  PendingStaffMembersByHash,
  StaffByHash,
  StaffMember,
  StaffReducer,
} from '../models/Staff';
import { Actions } from '../models/actions';
import {
  DELETE_MEMBERS_ERROR,
  DELETE_MEMBERS_REQUEST, DELETE_MEMBERS_SUCCESS,
  FETCH_STAFF_ERROR,
  FETCH_STAFF_REQUEST,
  FETCH_STAFF_SUCCESS,
  SET_SELECTED_PENDING_STAFF,
  BULK_DEACTIVATE_INVITE_TOKEN_SUCCESS,
  EDIT_ROLES_MEMBERS_ERROR,
  EDIT_ROLES_MEMBERS_REQUEST,
  EDIT_ROLES_MEMBERS_SUCCESS,
  SET_SELECTED_STAFF,
  ORDER_SELECT_STAFF,
} from '../constants/actionTypes';
import { Roles } from '../constants/roles';

export const initialState: StaffReducer = {
  byHash: {},
  byId: [],
  loading: false,
  pendingStaffMembersByHash: {},
  pendingStaffMembersbyId: [],
  selectedMembersTeam: null,
  selectedId: [],
  selectedStaffIds: [],
};

export default (state = initialState, action: Actions) => {
  switch (action.type) {
    case FETCH_STAFF_REQUEST:
    case DELETE_MEMBERS_REQUEST:
    case EDIT_ROLES_MEMBERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SET_SELECTED_STAFF:
    case ORDER_SELECT_STAFF:
      return {
        ...state,
        selectedStaffIds: action.payload.ids,
        selectedMembersTeam: action.payload.teamId,
      };
    case FETCH_STAFF_ERROR:
    case DELETE_MEMBERS_ERROR:
    case EDIT_ROLES_MEMBERS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case EDIT_ROLES_MEMBERS_SUCCESS: {
      const { personId, roles } = action.payload;
      const updatedByHash: StaffByHash = {
        ...state.byHash, [personId]: { ...state.byHash[personId], roles },
      };
      const filteredByHash = state.byId
        .reduce((byHash: StaffByHash, memberId: number) => ({
          ...byHash,
          ...(
            updatedByHash[memberId]
            && updatedByHash[memberId].roles
            && updatedByHash[memberId].roles!.some(
              role => [Roles.TEAM_LEAD, Roles.TREASURER, Roles.CLUB_ADMIN].includes(role),
            )
            && ({ [memberId]: { ...updatedByHash[memberId] } })),
        }), {});
      return {
        ...state,
        loading: false,
        error: false,
        byHash: filteredByHash,
        byId: Object.keys(filteredByHash).map(Number),
      };
    }
    case FETCH_STAFF_SUCCESS: {
      const { staffMembers, pendingStaffMembers } = action.payload;

      return {
        ...state,
        byHash: staffMembers
          .reduce((byHash: StaffByHash, member: StaffMember) => ({
            ...byHash,
            [member.personId]: {
              ...member,
              id: member.personId,
            },
          }), state.byHash),
        byId: staffMembers.map(({ personId }: StaffMember) => personId),
        loading: false,
        pendingStaffMembersByHash: pendingStaffMembers
          .reduce((byHash: PendingStaffMembersByHash, member: PendingStaffMember) => ({
            ...byHash,
            [member.invitationToken]: member,
          }), state.pendingStaffMembersByHash),
        pendingStaffMembersbyId: pendingStaffMembers.map(
          ({ invitationToken }: PendingStaffMember) => invitationToken,
        ),
      };
    }
    case BULK_DEACTIVATE_INVITE_TOKEN_SUCCESS: {
      const { tokens } = action.payload;
      const filteredPendingStaffMembers = state.pendingStaffMembersbyId.filter(
        id => !tokens.includes(id),
      );
      return {
        ...state,
        selectedId: [],
        pendingStaffMembersbyId: filteredPendingStaffMembers,
        pendingStaffMembersByHash: filteredPendingStaffMembers
          .reduce((byHash: PendingStaffMembersByHash, token: string) => ({
            ...byHash,
            [token]: {
              ...state.pendingStaffMembersByHash[token],
            },
          }), {}),
      };
    }
    case SET_SELECTED_PENDING_STAFF:
      return {
        ...state,
        selectedId: action.payload.ids,
        selectedMembersTeam: action.payload.teamId,
      };
    case DELETE_MEMBERS_SUCCESS: {
      const { personIds } = action.payload;
      const filteredPlayers = state.byId
        .filter(id => !personIds.includes(state.byHash[id].personId));
      return {
        ...state,
        loading: false,
        byId: filteredPlayers,
        byHash: filteredPlayers.reduce((byHash: StaffByHash, staffId: number) => ({
          ...byHash,
          [staffId]: state.byHash[staffId],
        }), {}),
      };
    }
    default:
      return state;
  }
};
