import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import {
  catchError, mergeMap, withLatestFrom, map,
} from 'rxjs/operators';
import { of } from 'rxjs';

import { State } from '../../models/State';
import { MATCHES_RESULT } from '../../constants/paths';
import { UPDATE_RESULT_REQUEST } from '../../constants/actionTypes';
import { UpdateResultRequestAction } from '../../models/actions';
import { updateResultSuccess, updateResultError } from '../../actions/calendar';

const updateResult = (
  action$: ActionsObservable<UpdateResultRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(UPDATE_RESULT_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      id,
      homeTeamScore,
      awayTeamScore,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: MATCHES_RESULT.replace('$matchId', String(id)),
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify({
      homeTeamScore,
      awayTeamScore,
    }),
  }).pipe(
    map(() => updateResultSuccess()),
    catchError((error: AjaxError) => of(updateResultError(error))),
  )),
);

export default updateResult;
