import { Column } from '../../../../../../components/Table/Column';

const columns: Column[] = [{
  size: 5,
  header: 'EMBELLISHMENT',
  align: 'left',
  key: 'embellishment',
}, {
  size: 2,
  header: 'PRICE',
  align: 'right',
  key: 'price',
}];

export default columns;
