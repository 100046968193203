import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Orders from './Orders';
import State from '../../models';
import { StateProps, DispatchProps } from './OrdersProps';
import { deleteOrdersRequest, fetchTeamOrdersRequest } from '../../actions/orders';

const mapStateToProps = ({
  ordersList: {
    loading,
    byId,
    byHash,
    count,
    ordersDeleted,
  },
  application: { teamId },
}: State): StateProps => ({
  count,
  teamId,
  loading,
  orders: byId.map((id: number) => byHash[id]),
  ordersDeleted,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  deleteOrders: bindActionCreators(deleteOrdersRequest, dispatch),
  fetchOrders: bindActionCreators(fetchTeamOrdersRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
