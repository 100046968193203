import get from 'lodash/get';
import intersection from 'lodash/intersection';
import { Actions } from '../models/actions';
import { ApplicationReducer } from '../models/State';
import {
  CREATE_TEAM_SUCCESS,
  FETCH_TOKEN_DETAILS_ERROR,
  FETCH_TOKEN_DETAILS_REQUEST,
  FETCH_TOKEN_DETAILS_SUCCESS,
  SET_APPLICATION_PARAMETERS,
  SET_MENU_EXPANDED,
  FETCH_TEAM_ID_REQUEST,
  FETCH_TEAM_ID_ERROR,
  CLEAR_TEAM_ID,
  VALIDATE_HOBBY_TEAM_TOKEN_SUCCESS,
  VALIDATE_HOBBY_TEAM_TOKEN_ERROR,
  VALIDATE_HOBBY_TEAM_TOKEN_REQUEST,
  FETCH_USER_INFO_SUCCESS,
  CHECK_EMAIL_AVAILABILITY_SUCCESS,
  CHECK_EMAIL_AVAILABILITY_REQUEST,
  SET_USER_CONSENT_APPROVED,
  SIGN_UP_SUCCESS,
  FETCH_USER_INFO_REQUEST,
  FETCH_AUTH_USER_SUCCESS,
  FETCH_AUTH_USER_REQUEST,
  FETCH_AUTH_USER_ERROR,
  RESET_USER_DATA,
} from '../constants/actionTypes';
import { SIGN_OUT_REQUEST, UNAUTHORIZED } from '../packages/authentication';
import { Roles } from '../constants/roles';
import { parseMemberships } from '../utils/membershipParser';

export const initialState: ApplicationReducer = {
  memberships: {},
  userRole: null,
  token: null,
  clubId: null,
  menuExpanded: true,
  tokenDetails: null,
  hobbyTeamTokenDetails: null,
  loading: false,
  userDataFetched: false,
  error: false,
  emailAvailable: false,
  checkingEmail: false,
  consentApproved: false,
};

export default (state = initialState, action: Actions) => {
  switch (action.type) {
    case SET_MENU_EXPANDED:
      return {
        ...state,
        menuExpanded: action.payload.value,
      };
    case FETCH_USER_INFO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_AUTH_USER_REQUEST:
      return {
        ...state,
        userDataFetched: false,
      };
    case FETCH_AUTH_USER_SUCCESS:
      return {
        ...state,
        userDataFetched: true,
      };
    case SET_USER_CONSENT_APPROVED:
      return {
        ...state,
        consentApproved: action.payload.value,
      };
    case FETCH_USER_INFO_SUCCESS: {
      const { user } = action.payload;
      const parsedMemberships = parseMemberships(user.clubs!);
      const roles = get(parsedMemberships, state.clubId || '', []);
      const userRole = intersection(
        [Roles.CLUB_ADMIN, Roles.TEAM_LEAD, Roles.PLAYER],
        roles,
      )[0];

      return {
        ...state,
        ...(!Object.keys(state.memberships).length
          && ({ memberships: parsedMemberships })),
        ...(!state.userRole && ({ userRole })),
        loading: false,
      };
    }
    case CHECK_EMAIL_AVAILABILITY_REQUEST: {
      return {
        ...state,
        checkingEmail: true,
        emailAvailable: false,
      };
    }
    case CHECK_EMAIL_AVAILABILITY_SUCCESS: {
      return {
        ...state,
        checkingEmail: false,
        emailAvailable: action.payload.emailAvailability.emailAvailable,
      };
    }
    case CREATE_TEAM_SUCCESS: {
      const { team } = action.payload;
      return {
        ...state,
        teamId: team.id,
      };
    }
    case SET_APPLICATION_PARAMETERS: {
      const { params } = action.payload;

      if (params.memberships) {
        const roles = get(params.memberships, params.clubId || '', []);
        const userRole = intersection(
          [Roles.CLUB_ADMIN, Roles.TEAM_LEAD, Roles.PLAYER],
          roles,
        )[0];

        return {
          ...state,
          ...params,
          userRole,
          loading: false,
        };
      }

      return {
        ...state,
        ...params,
        loading: false,
      };
    }
    case FETCH_TEAM_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TOKEN_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        token: action.payload.token,
      };
    case FETCH_TEAM_ID_ERROR:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_TEAM_ID:
      return {
        ...state,
        teamId: undefined,
        clubId: null,
      };
    case VALIDATE_HOBBY_TEAM_TOKEN_REQUEST:
      return {
        ...state,
        loading: true,
        hobbyTeamTokenDetails: null,
        error: false,
      };
    case VALIDATE_HOBBY_TEAM_TOKEN_SUCCESS: {
      const { tokenDetails } = action.payload;
      return {
        ...state,
        hobbyTeamTokenDetails: tokenDetails,
        loading: false,
        error: false,
      };
    }
    case VALIDATE_HOBBY_TEAM_TOKEN_ERROR:
      return {
        ...state,
        hobbyTeamTokenDetails: null,
        loading: false,
        error: true,
      };
    case FETCH_TOKEN_DETAILS_SUCCESS: {
      const { tokenDetails } = action.payload;
      return {
        ...state,
        tokenDetails,
        loading: false,
        error: false,
      };
    }
    case FETCH_TOKEN_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case FETCH_AUTH_USER_ERROR:
      return {
        ...state,
        userDataFetched: false,
      };
    case RESET_USER_DATA:
      return {
        ...state,
        userDataFetched: false,
      };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        consentApproved: false,
      };
    case SIGN_OUT_REQUEST:
    case UNAUTHORIZED:
      return initialState;
    default:
      return state;
  }
};
