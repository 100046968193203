import { Match, OneTimeEvent, Training } from '../../models/Calendar';
import { EventType } from '../../constants/eventTypes';

const serializeEvent = ({
  startDateTime, endDateTime, meetingDate, type, ...event
}: Training | Match | OneTimeEvent): Training | Match | OneTimeEvent => ({
  ...event,
  type,
  ...(startDateTime && ({ startDateTime: new Date(startDateTime) })),
  ...(endDateTime && ({ endDateTime: new Date(endDateTime) })),
  ...(meetingDate && ({ meetingDate: new Date(meetingDate) })),
  ...(type === EventType.MATCH && ({
    match: {
      ...(event as Match).match,
      matchLocation: (event as Match).match.location || {},
    },
  })),
});

export default serializeEvent;
