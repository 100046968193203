import {
  RESET_PASSWORD_TOKEN_REQUEST,
  RESET_PASSWORD_TOKEN_SUCCESS,
  RESET_PASSWORD_TOKEN_ERROR,
} from '../constants/actionTypes';

export const resetPasswordTokenRequest = username => ({
  type: RESET_PASSWORD_TOKEN_REQUEST,
  payload: {
    username,
  },
});

export const resetPasswordTokenSuccess = () => ({
  type: RESET_PASSWORD_TOKEN_SUCCESS,
});

export const resetPasswordTokenError = error => ({
  type: RESET_PASSWORD_TOKEN_ERROR,
  payload: {
    error,
  },
});
