import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderComponent from '../../../../../../components/Header';
import LabelValueField from '../../../../../../components/LabelValueField';
import styles from './TeamLeadClubAdminDetails.module.scss';

interface TeamLeadClubAdminDetailsProps {
  firstName: string;
  lastName: string;
  email?: string;
}

const TeamLeadClubAdminDetails: FC<TeamLeadClubAdminDetailsProps> = ({
  firstName, lastName, email,
}) => {
  const [translate] = useTranslation();

  return (
    <>
      <HeaderComponent title={translate('USER_SETTINGS')} border />
      <div className={styles.content}>
        <LabelValueField label={translate('FIRST_NAME')} value={firstName} />
        <LabelValueField label={translate('LAST_NAME')} value={lastName} />
        {email && <LabelValueField label={translate('EMAIL')} value={email} />}
      </div>
    </>
  );
};

export default TeamLeadClubAdminDetails;
