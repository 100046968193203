import { AjaxError } from 'rxjs/ajax';
import {
  FetchStaffErrorAction,
  FetchStaffRequestAction,
  FetchStaffSuccessAction,
  SetSelectedPendingStaff,
  SetSelectedStaffAction,
} from '../models/actions';
import {
  FETCH_STAFF_ERROR,
  FETCH_STAFF_REQUEST,
  FETCH_STAFF_SUCCESS,
  SET_SELECTED_PENDING_STAFF,
  SET_SELECTED_STAFF,
} from '../constants/actionTypes';
import { StaffMember, PendingStaffMember } from '../models/Staff';

export const fetchStaffRequest = (teamId: number): FetchStaffRequestAction => ({
  type: FETCH_STAFF_REQUEST,
  payload: {
    teamId,
  },
});

export const fetchStaffSuccess = (
  staffMembers: StaffMember[], pendingStaffMembers: PendingStaffMember[],
): FetchStaffSuccessAction => ({
  type: FETCH_STAFF_SUCCESS,
  payload: {
    staffMembers,
    pendingStaffMembers,
  },
});

export const fetchStaffError = (error: AjaxError): FetchStaffErrorAction => ({
  type: FETCH_STAFF_ERROR,
  payload: {
    error,
  },
});

export const setSelectedPendingStaff = (
  ids: string[], teamId?: number,
): SetSelectedPendingStaff => ({
  type: SET_SELECTED_PENDING_STAFF,
  payload: {
    ids,
    teamId,
  },
});

export const setSelectedStaff = (ids: number[], teamId?: number): SetSelectedStaffAction => ({
  type: SET_SELECTED_STAFF,
  payload: {
    ids,
    teamId,
  },
});
