import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { handleError } from '../../actions/errors';
import { addNotification } from '../../actions/notifications';
import { deleteTeamError, deleteTeamSuccess } from '../../actions/teams';
import { DELETE_TEAM_ERROR, DELETE_TEAM_REQUEST, DELETE_TEAM_SUCCESS } from '../../constants/actionTypes';
import { ONE_TEAM_PATH } from '../../constants/paths';
import { DeleteTeamRequestAction, NotificationType } from '../../models/actions';
import { State } from '../../models/State';

export default (
  action$: ActionsObservable<DeleteTeamRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(DELETE_TEAM_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      id,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: ONE_TEAM_PATH.replace('$teamId', `${id}`),
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    mergeMap(() => [
      addNotification(NotificationType.SUCCESS, DELETE_TEAM_SUCCESS),
      deleteTeamSuccess(id),
    ]),
    catchError((error: AjaxError) => [
      handleError(error, DELETE_TEAM_ERROR),
      deleteTeamError(error),
    ]),
  )),
);
