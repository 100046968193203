import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Signature.module.scss';

interface SignatureProps {
  serverError?: boolean;
  showHaveFun?: boolean;
}

const Signature: FC<SignatureProps> = ({ serverError, showHaveFun }) => {
  const [translate] = useTranslation();

  return (
    <p className={styles.signatureWrapper}>
      {!serverError && showHaveFun && <span className={styles.text}>{translate('HAVE_FUN_TEXT')}</span>}
      <span className={[styles.text, serverError ? styles.greeting : ''].join(' ')}>{translate('WITH_SPORTY_GREETINGS')}</span>
      <span className={[styles.text].join(' ')}>{translate('HOUSE_OF_CLUBS_SIGNATURE')}</span>
    </p>
  );
};

export default Signature;
