import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import MobileItem from './MobileItem';
import { State } from '../../../../../../models/State';
import { fetchFileRequest } from '../../../../../../actions/files';
import { removeItems } from '../../../../../../actions/orderEditor';

const mapStatesToProps = ({
  application: {
    clubId,
  },
  files: {
    byHash,
  },
  orders: {
    orderStatus, usedNumbers, lockedNumbers,
  },
  catalog: {
    catalog: {
      productsByHash,
    },
    embellishmentsByHash,
  },
}: State) => ({
  clubId,
  embellishmentsByHash,
  productsByHash,
  filesByHash: byHash,
  lockedNumbers,
  orderStatus,
  usedNumbers,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchFile: bindActionCreators(fetchFileRequest, dispatch),
  removeItems: bindActionCreators(removeItems, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(MobileItem);
