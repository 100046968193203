import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import AvatarWithPopup from './AvatarWithPopup';
import { State } from '../../../../models/State';
import { fetchPlayerRequest } from '../../../../actions/players';

const mapStatesToProps = ({ players: { byHash, byId, loading } }: State) => ({
  playersByHash: byHash,
  playersById: byId,
  loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchPlayer: bindActionCreators(fetchPlayerRequest, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(AvatarWithPopup);
