import { AjaxError } from 'rxjs/ajax';

import {
  ACTIVE_TEAMS_SUCCESS,
  CHANGE_TEAM_STATUS_ERROR,
  CHANGE_TEAM_STATUS_REQUEST,
  CHANGE_TEAM_STATUS_SUCCESS,
  CREATE_TEAM_ERROR,
  CREATE_TEAM_REQUEST,
  CREATE_TEAM_SUCCESS,
  DELETE_TEAM_ERROR,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
  EDIT_TEAM_ERROR,
  EDIT_TEAM_REQUEST, EDIT_TEAM_SETTINGS_ERROR,
  EDIT_TEAM_SETTINGS_REQUEST, EDIT_TEAM_SETTINGS_SUCCESS,
  EDIT_TEAM_SUCCESS,
  FETCH_TEAM_ERROR,
  FETCH_TEAM_ID_ERROR,
  FETCH_TEAM_ID_REQUEST,
  FETCH_TEAM_INVITE_TOKEN_ERROR,
  FETCH_TEAM_INVITE_TOKEN_REQUEST,
  FETCH_TEAM_INVITE_TOKEN_SUCCESS,
  FETCH_TEAM_REQUEST,
  FETCH_TEAM_SUCCESS,
  FETCH_TEAMS_ERROR,
  FETCH_TEAMS_REQUEST,
  FETCH_TEAMS_SUCCESS,
  GENERATE_INVITE_TOKEN_ERROR,
  GENERATE_INVITE_TOKEN_REQUEST,
  GENERATE_INVITE_TOKEN_SUCCESS,
  SET_LOCK_NUMBERS_ERROR,
  SET_LOCK_NUMBERS_REQUEST,
  SET_LOCK_NUMBERS_SUCCESS,
  TEAM_SEASON_TRANSFER_ERROR,
  TEAM_SEASON_TRANSFER_REQUEST,
  TEAM_SEASON_TRANSFER_SUCCESS,
} from '../constants/actionTypes';
import { Team, TeamBase } from '../models/Team';
import {
  ActiveTeamsSuccessAction,
  ChangeTeamStatusErrorAction,
  ChangeTeamStatusRequestAction,
  ChangeTeamStatusSuccessAction,
  CreateTeamErrorAction,
  CreateTeamRequestAction,
  CreateTeamSuccessAction,
  DeleteTeamErrorAction,
  DeleteTeamRequestAction,
  DeleteTeamSuccessAction,
  EditTeamErrorAction,
  EditTeamRequestAction,
  EditTeamSettingsRequestAction,
  EditTeamSettingsSuccessAction,
  EditTeamSettingsErrorAction,
  EditTeamSuccessAction,
  FetchTeamErrorAction,
  FetchTeamIdErrorAction,
  FetchTeamIdRequestAction,
  FetchTeamInviteErrorAction,
  FetchTeamInviteRequestAction,
  FetchTeamInviteSuccessAction,
  FetchTeamRequestAction,
  FetchTeamsErrorAction,
  FetchTeamsRequestAction,
  FetchTeamsSuccessAction,
  FetchTeamSuccessAction,
  GenerateInviteTokenErrorAction,
  GenerateInviteTokenRequestAction,
  GenerateInviteTokenSuccessAction,
  SetLockNumbersErrorAction,
  SetLockNumbersRequestAction,
  SetLockNumbersSuccessAction,
  TeamSeasonTransferErrorAction,
  TeamSeasonTransferRequestAction,
  TeamSeasonTransferSuccessAction,
} from '../models/actions';
import { CreateInvitation } from '../models/InvitationData';
import TeamStatus from '../constants/teamStatus';
import TransferOptions from '../constants/transferOptions';
import Seasons from '../constants/seasons';
import { ExternalTeam } from '../models/externalSystemIntegration';

export const fetchTeamsRequest = (payload = {}): FetchTeamsRequestAction => ({
  type: FETCH_TEAMS_REQUEST,
  payload,
});

export const fetchTeamsSuccess = (
  count: number, teams: Team[], hasInactiveTeams: boolean,
): FetchTeamsSuccessAction => ({
  type: FETCH_TEAMS_SUCCESS,
  payload: {
    count,
    teams,
    hasInactiveTeams,
  },
});

export const fetchTeamsError = (error: AjaxError): FetchTeamsErrorAction => ({
  type: FETCH_TEAMS_ERROR,
  payload: {
    error,
  },
});

export const fetchTeamRequest = (id: number): FetchTeamRequestAction => ({
  type: FETCH_TEAM_REQUEST,
  payload: {
    id,
  },
});

export const fetchTeamSuccess = (team: Team): FetchTeamSuccessAction => ({
  type: FETCH_TEAM_SUCCESS,
  payload: {
    team,
  },
});

export const fetchTeamError = (error: AjaxError): FetchTeamErrorAction => ({
  type: FETCH_TEAM_ERROR,
  payload: {
    error,
  },
});

export const createTeamRequest = (
  clubId: number,
  name: string,
  season?: Seasons,
  seasonStartDate?: number,
  seasonEndDate?: number,
  externalTeam?: ExternalTeam,
): CreateTeamRequestAction => ({
  type: CREATE_TEAM_REQUEST,
  payload: {
    clubId,
    name,
    season,
    seasonStartDate,
    seasonEndDate,
    externalTeam,
  },
});

export const createTeamSuccess = (team: Team): CreateTeamSuccessAction => ({
  type: CREATE_TEAM_SUCCESS,
  payload: {
    team,
  },
});

export const activeTeamsSuccess = (activeTeams: TeamBase[]): ActiveTeamsSuccessAction => ({
  type: ACTIVE_TEAMS_SUCCESS,
  payload: {
    activeTeams,
  },
});

export const createTeamError = (error: AjaxError): CreateTeamErrorAction => ({
  type: CREATE_TEAM_ERROR,
  payload: {
    error,
  },
});

export const fetchTeamInviteRequest = (teamId: number): FetchTeamInviteRequestAction => ({
  type: FETCH_TEAM_INVITE_TOKEN_REQUEST,
  payload: {
    teamId,
  },
});

export const fetchTeamInviteSuccess = (token: string): FetchTeamInviteSuccessAction => ({
  type: FETCH_TEAM_INVITE_TOKEN_SUCCESS,
  payload: {
    token,
  },
});

export const fetchTeamInviteError = (error: AjaxError): FetchTeamInviteErrorAction => ({
  type: FETCH_TEAM_INVITE_TOKEN_ERROR,
  payload: {
    error,
  },
});

export const editTeamRequest = (
  team: Team,
  deactivateTor24Integration?: boolean,
): EditTeamRequestAction => ({
  type: EDIT_TEAM_REQUEST,
  payload: {
    team,
    deactivateTor24Integration,
  },
});

export const editTeamSuccess = (
  teamId: number, teamName: string,
): EditTeamSuccessAction => ({
  type: EDIT_TEAM_SUCCESS,
  payload: {
    teamId,
    teamName,
  },
});

export const editTeamError = (error: AjaxError): EditTeamErrorAction => ({
  type: EDIT_TEAM_ERROR,
  payload: {
    error,
  },
});

export const deleteTeamRequest = (id: number): DeleteTeamRequestAction => ({
  type: DELETE_TEAM_REQUEST,
  payload: {
    id,
  },
});

export const deleteTeamSuccess = (id: number): DeleteTeamSuccessAction => ({
  type: DELETE_TEAM_SUCCESS,
  payload: {
    id,
  },
});

export const deleteTeamError = (error: AjaxError): DeleteTeamErrorAction => ({
  type: DELETE_TEAM_ERROR,
  payload: {
    error,
  },
});

export const generateInviteTokenRequest = (
  createInvitation: CreateInvitation,
): GenerateInviteTokenRequestAction => ({
  type: GENERATE_INVITE_TOKEN_REQUEST,
  payload: {
    createInvitation,
  },
});

export const generateInviteTokenSuccess = (token: string): GenerateInviteTokenSuccessAction => ({
  type: GENERATE_INVITE_TOKEN_SUCCESS,
  payload: {
    token,
  },
});

export const generateInviteTokenError = (error: AjaxError): GenerateInviteTokenErrorAction => ({
  type: GENERATE_INVITE_TOKEN_ERROR,
  payload: {
    error,
  },
});

export const fetchTeamIdRequest = (clubId: number): FetchTeamIdRequestAction => ({
  type: FETCH_TEAM_ID_REQUEST,
  payload: {
    clubId,
  },
});

export const fetchTeamIdError = (error: AjaxError): FetchTeamIdErrorAction => ({
  type: FETCH_TEAM_ID_ERROR,
  payload: {
    error,
  },
});

export const setLockNumbersRequest = (lockedNumbers: number[]): SetLockNumbersRequestAction => ({
  type: SET_LOCK_NUMBERS_REQUEST,
  payload: {
    lockedNumbers,
  },
});

export const setLockNumbersSuccess = (
  teamId: number|null|undefined, lockedNumbers: number[],
): SetLockNumbersSuccessAction => ({
  type: SET_LOCK_NUMBERS_SUCCESS,
  payload: {
    lockedNumbers,
    teamId,
  },
});

export const setLockNumbersError = (error: AjaxError): SetLockNumbersErrorAction => ({
  type: SET_LOCK_NUMBERS_ERROR,
  payload: {
    error,
  },
});

export const changeTeamStatusRequest = (
  teamId: number,
  status: TeamStatus,
): ChangeTeamStatusRequestAction => ({
  type: CHANGE_TEAM_STATUS_REQUEST,
  payload: {
    teamId,
    status,
  },
});

export const changeTeamStatusSuccess = (
  teamId: number,
  status: TeamStatus,
): ChangeTeamStatusSuccessAction => ({
  type: CHANGE_TEAM_STATUS_SUCCESS,
  payload: {
    teamId,
    status,
  },
});

export const changeTeamStatusError = (error: AjaxError): ChangeTeamStatusErrorAction => ({
  type: CHANGE_TEAM_STATUS_ERROR,
  payload: {
    error,
  },
});

export const teamSeasonTransferRequest = (
  teamId: number,
  season: Seasons,
  playerLineUpTransferOption: TransferOptions,
  treasurerTransferOption: TransferOptions,
  teamLeadTransferOption: TransferOptions,
): TeamSeasonTransferRequestAction => ({
  type: TEAM_SEASON_TRANSFER_REQUEST,
  payload: {
    teamId,
    season,
    playerLineUpTransferOption,
    teamLeadTransferOption,
    treasurerTransferOption,
  },
});

export const teamSeasonTransferSuccess = (): TeamSeasonTransferSuccessAction => ({
  type: TEAM_SEASON_TRANSFER_SUCCESS,
});

export const teamSeasonTransferError = (error: AjaxError): TeamSeasonTransferErrorAction => ({
  type: TEAM_SEASON_TRANSFER_ERROR,
  payload: {
    error,
  },
});

export const editTeamSettingsRequest = (
  teamId: number,
  season?: Seasons,
  seasonStartDate?: number,
  seasonEndDate?: number,
): EditTeamSettingsRequestAction => ({
  type: EDIT_TEAM_SETTINGS_REQUEST,
  payload: {
    teamId,
    season,
    seasonStartDate,
    seasonEndDate,
  },
});

export const editTeamSettingsSuccess = (): EditTeamSettingsSuccessAction => ({
  type: EDIT_TEAM_SETTINGS_SUCCESS,
});

export const editTeamSettingsError = (error: AjaxError): EditTeamSettingsErrorAction => ({
  type: EDIT_TEAM_SETTINGS_ERROR,
  payload: {
    error,
  },
});
