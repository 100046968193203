import get from 'lodash/get';
import { ActionsObservable, ofType } from 'redux-observable';
import { map } from 'rxjs/operators';

import { ERROR } from '../../constants/actionTypes';
import { HandleErrorAction, NotificationType } from '../../models/actions';
import { addNotification } from '../../actions/notifications';

const handleError = (
  action$: ActionsObservable<HandleErrorAction>,
) => action$.pipe(
  ofType(ERROR),
  map(({ payload: { error, actionType } }) => addNotification(
    NotificationType.ERROR,
    actionType,
    error.status,
    `${error.message}: ${get(error, 'response.message', '')}`,
  )),
);

export default handleError;
