import React, { FC } from 'react';
import MobileHeader from '../MobileOrderHeader';
import DesktopHeader from '../DesktopOrderHeader';
import { useViewport } from '../../../../hooks/useViewport';

export interface HeaderProps {
  orderId?: number;
  teamId: number;
}

const Header: FC<HeaderProps> = ({ teamId, orderId }) => {
  const { isMobile } = useViewport();
  return (
    isMobile
      ? <MobileHeader teamId={teamId} orderId={orderId} />
      : <DesktopHeader teamId={teamId} orderId={orderId} />
  );
};

export default Header;
