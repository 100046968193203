import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import queryString from 'query-string';
import pickBy from 'lodash/pickBy';
import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';
import { handleError } from '../../actions/errors';
import { fetchInvitationsError, fetchInvitationsSuccess } from '../../actions/invitations';
import { FETCH_INVITATIONS_ERROR, FETCH_INVITATIONS_REQUEST } from '../../constants/actionTypes';
import { INVITATIONS_LIST } from '../../constants/paths';
import State from '../../models';
import { FetchInvitationsRequestAction } from '../../models/actions';
import { InvitationResponse } from '../../models/Invitations';
import { ListResponse, Response } from '../../models/Response';
import { INVITATIONS_LIST_LIMIT } from '../../constants/customizations';

export default (
  action$: ActionsObservable<FetchInvitationsRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_INVITATIONS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { isPersonalInvitation, filters: { page, filter } = { page: 1, filter: null } } },
    { authentication: { authToken }, application: { clubId } },
  ]) => {
    const query = queryString.stringify(pickBy({
      offset: (Number(page) - 1) * INVITATIONS_LIST_LIMIT,
      limit: INVITATIONS_LIST_LIMIT,
    }));
    let requestFilter;
    try {
      requestFilter = JSON.parse(filter as string);
    } catch (_) {
      requestFilter = null;
    }
    if (requestFilter) {
      requestFilter = {
        ...requestFilter,
        status: requestFilter.status && requestFilter.status.length === 1
          ? requestFilter.status[0] : null,
      };
    }
    return ajax({
      url: `${INVITATIONS_LIST.replace('$type', `${isPersonalInvitation ? 'personal' : 'team'}`)}?${query}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authToken,
      },
      body: { organizationId: clubId, ...requestFilter },
    }).pipe(
      map(({ response: { data, count } }: Response<ListResponse<InvitationResponse>>) => (
        fetchInvitationsSuccess(data, count)
      )),
      catchError((error: AjaxError) => [
        handleError(error, FETCH_INVITATIONS_ERROR),
        fetchInvitationsError(error),
      ]),
    );
  }),
);
