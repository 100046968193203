import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import DesktopMemberRow from './DesktopMemberRow';
import { State } from '../../../../models/State';
import { togglePlayerSelection, toggleStaffSelection } from '../../../../actions/orderEditor';

const mapStatesToProps = ({
  orders: { orderStatus, usedNumbers },
  catalog: {
    catalog: {
      productsByHash,
    },
  },
}: State) => ({
  orderStatus,
  productsByHash,
  usedNumbersInTeamOrder: usedNumbers || {},
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  togglePlayerSelection: bindActionCreators(togglePlayerSelection, dispatch),
  toggleStaffSelection: bindActionCreators(toggleStaffSelection, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(DesktopMemberRow);
