import { Column } from '../../components/Table/Column';

const columns = (translate: any): Column[] => [{
  size: 1,
  align: 'left',
  header: `# ${translate('ORDER')}`,
  key: 'orderId',
  sortable: true,
}, {
  size: 1,
  header: 'ORDER_DATE',
  align: 'left',
  key: 'orderDate',
  sortable: false,
}, {
  size: 2,
  header: 'TEAM_NAME',
  align: 'left',
  key: 'teamName',
  sortable: false,
}, {
  size: 1,
  header: 'ARTICLE_COUNT',
  align: 'left',
  key: 'articleCount',
  sortable: false,
}, {
  size: 1,
  header: 'PRICE',
  align: 'left',
  key: 'price',
  sortable: false,
}];

export default columns;
