import React, { FC, useEffect } from 'react';
import { Modal } from 'semantic-ui-react';

import { PdfModalProps } from './PdfModalProps';
import styles from './PdfModal.module.scss';

const PdfModal: FC<PdfModalProps> = ({
  file, triggerButton, filesByHash, fetchFile,
}) => {
  useEffect(() => {
    if (!filesByHash[file.id]) {
      fetchFile(file.id, file.extension);
    }
  }, [fetchFile, filesByHash, file]);

  return (
    <Modal
      closeIcon
      trigger={triggerButton}
      className={styles.modalContainer}
    >
      <Modal.Header content={file.originalFilename} />
      <Modal.Content className={styles.content}>
        {!!filesByHash[file.id] && (
          <object data={filesByHash[file.id]} type="application/pdf" width="100%" height="100%">
            <p>
              {file.originalFilename}
            </p>
          </object>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default PdfModal;
