import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Header, { HeaderTypes } from '../../../../components/Header';
import PriceDiscountsPopup from '../PriceDiscountsPopup';
import styles from './ProductDetailsPrice.module.scss';
import { Embellishment, ProductEmbellishment } from '../../../../models/Embellishment';
import { ProductDetailsPriceProps } from './ProductDetailsPriceProps';

const ProductDetailsPrice: FC<ProductDetailsPriceProps> = ({
  clubId,
  discountPercent,
  embellishments: productEmbellishments,
  embellishmentsByHash,
  originalPrice,
}) => {
  const [translate] = useTranslation();

  const mapEmbellishment = ({ embellishment }: ProductEmbellishment) => (
    embellishmentsByHash[embellishment]
  );
  const embellishments: Embellishment[] = productEmbellishments.map(mapEmbellishment);
  const embellishmentsMandatory: Embellishment[] = productEmbellishments
    .filter(({ mandatory }) => mandatory).map(mapEmbellishment);
  const embellishmentsOptional: Embellishment[] = productEmbellishments
    .filter(({ mandatory }) => !mandatory).map(mapEmbellishment);

  return (
    <div className={styles.wrapper}>
      <Header title={translate('PRICE')} type={HeaderTypes.H3} />
      <div className={styles.container}>
        {(!embellishments.length
          || (!embellishmentsMandatory.length && !!embellishmentsOptional.length)) && (
          <div className={styles.priceWrapper}>
            <label>{translate('TOTAL_PRICE_NO_EMBELLISHMENT')}</label>
            {originalPrice && clubId && (
              <PriceDiscountsPopup
                clubId={clubId}
                productEmbellishments={[]}
                embellishments={[]}
                price={originalPrice}
                productDiscountPercent={discountPercent}
                popupPosition="left center"
              />
            )}
          </div>
        )}
        {!!embellishmentsMandatory.length && (
          <div className={styles.priceWrapper}>
            <label>{translate('PRICE_INCL_MANDATORY_EMBELLISHMENTS')}</label>
            {originalPrice && clubId && (
              <PriceDiscountsPopup
                clubId={clubId}
                productEmbellishments={productEmbellishments.filter(({ mandatory }) => mandatory)}
                embellishments={embellishmentsMandatory}
                price={originalPrice}
                productDiscountPercent={discountPercent}
                popupPosition="left center"
              />
            )}
          </div>
        )}
        {!!embellishmentsOptional.length && (
          <div className={styles.priceWrapper}>
            <label>{translate('PRICE_INCL_OPTIONAL_EMBELLISHMENT')}</label>
            {originalPrice && clubId && (
              <PriceDiscountsPopup
                clubId={clubId}
                productEmbellishments={productEmbellishments}
                embellishments={embellishments}
                price={originalPrice}
                productDiscountPercent={discountPercent}
                popupPosition="left center"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductDetailsPrice;
