import React, { FC } from 'react';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import styles from './MobileFooter.module.scss';
import Confirm from '../Confirm';

interface MobileFooterProps {
  onClick: () => void;
  confirm?: boolean;
  confirmText?: string;
  buttonText: string;
  size?: 'small' | 'medium' | 'big';
  fixedOnBottom?: boolean;
  translate?: boolean;
  disabled?: boolean;
}

const MobileFooter: FC<MobileFooterProps> = ({
  buttonText,
  confirm = false,
  confirmText = '',
  translate = false,
  size = 'medium',
  fixedOnBottom = false,
  onClick,
  disabled = false,
  ...inheritProps
}) => {
  const [t] = useTranslation();
  return (
    <div className={[styles.footer, fixedOnBottom ? styles.absolute : '', styles[`size-${size}`]].join(' ')}>
      {confirm ? (
        <Confirm
          confirmText={confirmText}
          onConfirm={onClick}
          disabled={disabled}
          triggerText={translate ? t(buttonText) : buttonText}
          as={Button}
          {...inheritProps}
        />
      ) : (
        <Button
          onClick={onClick}
          primary
          disabled={disabled}
          className={styles.footerButton}
          {...inheritProps}
        >
          {translate ? t(buttonText) : buttonText}
        </Button>
      )}
    </div>
  );
};

export default MobileFooter;
