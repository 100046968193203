import { AjaxError } from 'rxjs/ajax';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import {
  catchError, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import {
  SET_EXTERNAL_REF_ERROR,
  SET_EXTERNAL_REF_REQUEST,
} from '../../constants/actionTypes';
import { EXTERNAL_SYSTEM_SET_PATH } from '../../constants/paths';
import { State } from '../../models/State';
import { handleError } from '../../actions/errors';
import { SetExternalReferenceRequestAction } from '../../models/actions/externalSystem';

import {
  ExternalSystemReference,
} from '../../models/externalSystemIntegration';
import {
  setExternalReferencesError,
  setExternalReferencesSuccess,
} from '../../actions/externalSystems/setExternalReferences';

export default (
  action$: ActionsObservable<SetExternalReferenceRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(SET_EXTERNAL_REF_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      references,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: EXTERNAL_SYSTEM_SET_PATH,
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify({
      references,
    }),
  }).pipe(
    mergeMap((externalRefs: { response: ExternalSystemReference[] }) => [
      setExternalReferencesSuccess(externalRefs),
    ]),
    catchError((error: AjaxError) => [
      handleError(error, SET_EXTERNAL_REF_ERROR),
      setExternalReferencesError(error),
    ]),
  )),
);
