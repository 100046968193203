import { Column } from '../../../../../../components/Table/Column';

const columns: Column[] = [{
  width: '65%',
  header: 'EMBELLISHMENT',
  align: 'left',
  key: 'embellishment',
}, {
  width: '35%',
  header: 'PRICE',
  align: 'right',
  key: 'price',
}];

export default columns;
