import config from '../config';

export const CLUBS_PATH = `${config.API_URL}/clubs`;
export const TEAMS_PATH = `${CLUBS_PATH}/$clubId/teams`;
export const ACTIVE_TEAMS_PATH = `${CLUBS_PATH}/$clubId/teams/active`;
export const ASSIGN_MEMBERS_PATH = `${CLUBS_PATH}/member/assign`;
export const BULK_ASSIGN_MEMBERS_PATH = `${ASSIGN_MEMBERS_PATH}/bulk`;
export const TEAM_PATH = `${TEAMS_PATH}/$teamId`;
export const TEAM_SEASON_TRANSFER_PATH = `${config.API_URL}/teams/$teamId/season-transfer`;
export const TAGS_PATH = `${config.API_URL}/tags`;
export const EMBELLISHMENTS_PATH = `${config.API_URL}/embellishments`;
export const MEMBERS_PATH = `${CLUBS_PATH}/$clubId/members/filter`;
export const MEMBER_PATH = `${CLUBS_PATH}/$clubId/members/$memberId`;
export const CHECK_EMAIL_AVAILABILITY = `${CLUBS_PATH}/$clubId/members/verify-email-availability`;

export const UPLOAD_LOGO_PATH = `${CLUBS_PATH}/upload/$clubId`;
export const SIGN_UP_WITH_TOKEN = '/signup/{inviteToken}/accept';

export const ACCEPT_INVITATION = `${config.API_URL}/invitations/$inviteToken/accept`;
export const TEAM_INVITATION_TOKEN = `${config.API_URL}/teams/$teamId/invitation-token`;
export const DEACTIVATE_INVITE_TOKEN = `${config.API_URL}/invitations/$token/invalidate`;
export const BULK_DEACTIVATE_TOKEN = `${config.API_URL}/invitations/invalidate/bulk`;
export const TOKEN_DETAILS = `${config.API_URL}/invitations/$token/details`;
export const HOBBY_TEAM_TOKEN_VALIDATION = `${config.API_URL}/hobby/teams/creation-request/$token/confirm`;

export const INVITATIONS_PATH = `${config.API_URL}/invitations`;
export const RESEND_INVITATION_PATH = `${INVITATIONS_PATH}/:token/resend`;
export const TEAM_PLAYERS_PATH = `${config.API_URL}/teams/$teamId/players`;
export const TEAM_STAFF_PATH = `${config.API_URL}/teams/$teamId/staff`;
export const TEAM_PLAYERS_FILTER = `${config.API_URL}/teams/$teamId/players/filter`;
export const TEAM_PLAYER_PATH = `${config.API_URL}/teams/$teamId/players/$playerId`;
export const ONE_TEAM_PATH = `${config.API_URL}/teams/$teamId`;
export const EDIT_TEAM_SETTINGS = `${config.API_URL}/teams/$teamId/settings`;
export const LOCKED_NUMBERS_PATH = `${config.API_URL}/teams/$teamId/locked-numbers`;
export const CHANGE_TEAM_STATUS_PATH = `${config.API_URL}/teams/$teamId/status`;
export const EDIT_ROLES_MEMBERS_PATH = `${config.API_URL}/teams/$teamId/members/roles`;
export const DELETE_MEMBERS_PATH = `${config.API_URL}/teams/$teamId/members/bulk`;
export const DELETE_MEMBER_PATH = `${config.API_URL}/clubs/$clubId/members/$memberId`;

export const FETCH_FILE_PATH = `${config.API_URL}/files/$contentId`;

export const ORDERS = `${config.API_URL}/orders`;
export const ORDERS_LIST = `${ORDERS}/list/$role`;
export const ORDERS_SET_STATUS = `${ORDERS}/$id/status`;
export const FETCH_NUMBERS = `${ORDERS}/$orderId/number-availability`;

export const CALENDAR = `${config.API_URL}/calendar`;
export const DELETE_DATA = `${config.API_URL}/gdpr/remove-stored-data`;
export const EVENTS = `${CALENDAR}/events`;
export const EVENTS_DELETE = `${EVENTS}/$eventId`;
export const BULK_EVENTS_DELETE = `${EVENTS}/$groupId/group`;
export const EVENTS_CANCEL = `${EVENTS_DELETE}/cancel`;
export const EVENTS_LIST = `${EVENTS}/list`;
export const EVENTS_UPLOAD = `${EVENTS}/team/$teamId/upload`;
export const EVENT_CSV_PREVIEW = `${EVENTS}/team/$teamId/upload/preview`;

export const USER_PATH = `${config.API_URL}/user`;
export const USER_INFO_PATH = `${config.API_URL}/user/nts-V2`;
export const UPLOAD_AVATAR_PATH = `${config.API_URL}/user/nts/upload`;

export const MATCHES = `${config.API_URL}/matches`;
export const MATCHES_RESULT = `${MATCHES}/$matchId/result`;

export const EXTERNAL_PATH = `${config.API_URL}/external-system-integration`;
export const TOR24_TEAMS_PATH = `${EXTERNAL_PATH}/helpers/tor24/clubs/$clubId/teams`;
export const TOR24_MATCHES_PATH = `${config.API_URL}/teams/$teamId/future-matches`;
export const EXTERNAL_SYSTEM_SET_PATH = `${EXTERNAL_PATH}/references`;
export const EXTERNAL_REF_DELETION_PATH = `${EXTERNAL_SYSTEM_SET_PATH}/team/$teamId/$externalRefName`;
export const SEARCH_EXTERNAL_CLUBS_PATH = `${config.EXTERNAL_CLUBS_URL}/search/club_name?term=$searchTerm`;
export const SEARCH_EXTERNAL_TEAMS_PATH = `${config.EXTERNAL_CLUBS_URL}/teams?club_id=$clubId`;

export const INDIVIDUAL_ORDERS = `${config.API_URL}/individual/orders`;
export const INDIVIDUAL_ORDERS_LIST = `${INDIVIDUAL_ORDERS}/filter/all`;

export const TEAM_BASKET_ORDERS_LIST = `${config.API_URL}/teambasket/orders/list`;

export const INVITATIONS_LIST = `${config.API_URL}/invitations/$type/filter`;
