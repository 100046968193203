import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useDeepCompareEffect from 'use-deep-compare-effect';
import get from 'lodash/get';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import {
  Button, Dropdown, Form, Menu, Message,
} from 'semantic-ui-react';
import { Subject } from 'rxjs';
import { tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import i18next from 'i18next';
import styles from './CreateMember.module.scss';
import Header from '../../../../components/Header';
import FormFields from '../../../../components/FormFields';
import {
  createSchema, createValidationSchema, existingSchema, existingValidationSchema, getOptions,
} from './schema';
import useForm from '../../../../hooks/useForm';
import { CreateMemberProps } from './CreateMemberProps';
import { Team } from '../../../../models/Team';
import { Roles } from '../../../../constants/roles';
import { PlayerFormData } from '../../../../models/Player';
import { CreateInvitation } from '../../../../models/InvitationData';
import { FormSchema } from '../../../../models/Form';
import { isValidEmail } from '../../../../utils/formValidators';

enum MenuItems {
  EXISTING_MEMBER = 'ADD_EXISTING_CLUB_MEMBER',
  NEW_MEMBER = 'ADD_AND_INVITE_NEW_MEMBER',
}

const CreateMember = ({
  clubId,
  sendInvitation,
  createPlayer,
  error,
  fetchTeam,
  emailAvailable,
  loading,
  byHash,
  membersByHash,
  membersById,
  fetchMembers,
  assignMember,
  checkEmail,
  checkingEmail,
}: CreateMemberProps) => {
  const [translate] = useTranslation();
  const [emailChecked, setEmailChecked] = useState(false);
  const [email$] = useState(() => new Subject<string>());
  // @ts-ignore
  const { teamId: teamId1 } = useParams();
  const teamId: string = teamId1 as string;
  const team: Team = get(byHash, teamId, {} as Team);
  const [selectedMember, setSelectedMember] = useState<number>();
  const [filteredClubMembers, setFilteredClubMembers] = useState<number[]>([]);
  const [activeMenuItem, setMenuItem] = useState<string>(MenuItems.EXISTING_MEMBER);
  const [debounceCallback] = useDebouncedCallback(searchValue => {
    fetchMembers({}, searchValue as string);
  }, 1000);

  useDeepCompareEffect(() => {
    // @ts-ignore
    const players: number[] = get(team, 'players', []).map(player => player.id);
    setFilteredClubMembers(membersById.filter(id => !players.includes(id)));
  }, [membersById, team]);

  const {
    values: createValues,
    setValues: setCreateValues,
    errors: createErrors,
    handleChange: handleCreateChange,
    validate: validateCreate,
  } = useForm(createValidationSchema);

  useEffect(() => {
    email$.next(createValues.email);
  }, [createValues.email, email$]);

  useEffect(() => {
    const emailChangeListener = email$
      .pipe(
        tap(() => { setEmailChecked(false); }),
        debounceTime(500),
        distinctUntilChanged(),
      ).subscribe((email: string) => {
        if (isValidEmail.rule(email)) {
          setEmailChecked(true);
          checkEmail(email);
        }
      });

    return () => {
      emailChangeListener.unsubscribe();
    };
  }, [email$, checkEmail]);

  const {
    values: existingValues,
    setValues: setExistingValues,
    errors: existingErrors,
    handleChange: handleExistingChange,
    validate: validateExisting,
  } = useForm(existingValidationSchema);

  useEffect(() => {
    if (byHash[teamId]) {
      setCreateValues({ teamName: byHash[teamId].name });
      setExistingValues({ teamName: byHash[teamId].name });
      setSelectedMember(undefined);
    }
  }, [byHash, setCreateValues, setExistingValues, teamId]);

  useEffect(() => {
    const member = get(membersByHash, `[${selectedMember}]`, false);
    if (member) {
      setExistingValues({
        ...existingValues,
        fullName: `${member.firstName} ${member.lastName}`,
      });
    }
    // eslint-disable-next-line
  }, [membersByHash, setExistingValues, selectedMember]);

  const submitInviteMember = () => {
    if (validateCreate()) {
      const {
        firstName, lastName, role, email,
      } = createValues;
      const invitationPersonalData = { firstName, lastName };
      const createInvitation: CreateInvitation = {
        receiverEmail: email,
        receiverRole: role,
        teamId: +teamId,
        clubId,
      };

      if (role === Roles.PLAYER) {
        const player: PlayerFormData = {
          firstName,
          lastName,
          email,
        };

        createPlayer(player, team, true, createInvitation, invitationPersonalData);
      } else {
        sendInvitation(
          createInvitation,
          invitationPersonalData,
          true,
        );
      }
    }
  };

  const submitExistingMember = () => {
    if (validateExisting()) {
      const {
        role,
      } = existingValues;

      if (selectedMember) {
        assignMember(+teamId, role, selectedMember);
      }
    }
  };

  useEffect(() => {
    fetchTeam(+teamId);
    fetchMembers({}, '');
  }, [fetchTeam, teamId, fetchMembers]);

  const renderSaveButton = () => (
    <div>
      <Button
        primary
        content={translate('SAVE')}
        disabled={activeMenuItem === MenuItems.NEW_MEMBER
          && (checkingEmail || !emailChecked || !emailAvailable)}
        key="button"
        onClick={activeMenuItem === MenuItems.NEW_MEMBER
          ? submitInviteMember
          : submitExistingMember
        }
      />
    </div>
  );

  const enrichSchema = (schema: FormSchema): FormSchema => ({
    ...schema,
    role: {
      ...schema.role,
      options: (t: i18next.TFunction) => getOptions(
        [
          Roles.PLAYER,
          Roles.TEAM_LEAD,
        ].filter(Boolean) as string[],
        t,
      ),
    },
  });

  return (
    <div className={styles.wrapper}>
      <div className={[styles.topGroup, styles.fixHeight].join(' ')}>
        <Header
          backLabel="BACK_TO_TEAM_MEMBERS"
          title={
            teamId1 && team && team.name
              ? translate('ADD_NEW_MEMBER_TO', { team: team.name })
              : translate('ADD_TEAM_MEMBER')
          }
          renderButton={renderSaveButton}
          goBack
        />
        <div className={styles.contentWrapper}>
          <Menu className="filter" pointing secondary>
            <Menu.Item
              content={translate(MenuItems.EXISTING_MEMBER)}
              active={MenuItems.EXISTING_MEMBER === activeMenuItem}
              onClick={() => { setMenuItem(MenuItems.EXISTING_MEMBER); }}
            />
            <Menu.Item
              content={translate(MenuItems.NEW_MEMBER)}
              active={MenuItems.NEW_MEMBER === activeMenuItem}
              onClick={() => { setMenuItem(MenuItems.NEW_MEMBER); }}
            />
          </Menu>
          {activeMenuItem === MenuItems.EXISTING_MEMBER && (
            <div className={styles.form}>
              <Dropdown
                placeholder={translate('SEARCH_NAME_OR_MAIL')}
                fluid
                search
                selection
                value={selectedMember}
                onChange={(e, { value }) => {
                  // @ts-ignore
                  setSelectedMember(value);
                }}
                options={filteredClubMembers.map(id => ({
                  key: id,
                  value: id,
                  text: `${membersByHash[id].firstName} ${membersByHash[id].lastName}`,
                }))}
                onSearchChange={({ target }) => {
                  // @ts-ignore
                  debounceCallback(target.value);
                }}
              />
              {selectedMember && (
                <>
                  <h3 className={styles.smallHeader}>{translate('SEARCH_RESULTS')}</h3>
                  <Form error={error} onSubmit={submitExistingMember} loading={loading} id="existing-form">
                    {error && (<Message error={error} header={translate('ERROR')} content={translate('GENERAL_ERROR')} />)}

                    <FormFields
                      schema={enrichSchema(existingSchema)}
                      errors={existingErrors}
                      handleChange={handleExistingChange}
                      values={existingValues}
                      inline
                    />
                  </Form>
                </>
              )}
            </div>
          )}
          {activeMenuItem === MenuItems.NEW_MEMBER && (
            <Form error={error} onSubmit={submitInviteMember} loading={loading} className={styles.form} id="create-form">
              {error && (<Message error={error} header={translate('ERROR')} content={translate('GENERAL_ERROR')} />)}

              <FormFields
                schema={enrichSchema(createSchema)}
                errors={createErrors}
                handleChange={handleCreateChange}
                values={createValues}
                inline
              />
              {!emailAvailable && !checkingEmail && emailChecked && (
                <Message
                  negative
                  header={translate('EMAIL_NOT_AVAILABLE_ERROR_HEADER')}
                  content={translate('EMAIL_NOT_AVAILABLE_ERROR_TEXT', { tabName: translate(MenuItems.EXISTING_MEMBER) })}
                />
              )}
            </Form>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateMember;
