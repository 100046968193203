import get from 'lodash/get';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import ProductDetails from './ProductDetails';
import { ProductDetailsProps } from './ProductDetailsProps';
import { State } from '../../models/State';
import { fetchCatalogRequest } from '../../actions/catalogs';
import { fetchEmbellishmentsRequest } from '../../actions/embellishments';
import { fetchTeamsRequest } from '../../actions/teams';
import { fetchTagsRequest } from '../../actions/tags';

const mapStateToProps = (
  {
    catalog: {
      catalog,
      loading,
      embellishmentsById,
      loadingTags,
      tagsByHash,
      tagsById,
    },
    application: { clubId },
    teams: {
      byId: teamsById,
      byHash: teamsByHash,
    },
  }: State,
  { match: { params: { id } } }: ProductDetailsProps,
) => ({
  catalog,
  product: get(catalog, ['productsByHash', id]),
  clubId,
  loading,
  allTags: tagsById.map(tagId => tagsByHash[tagId]),
  loadingTags,
  teams: teamsById.map(teamId => teamsByHash[teamId]),
  embellishmentsById,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchCatalog: bindActionCreators(fetchCatalogRequest, dispatch),
  fetchEmbellishments: bindActionCreators(fetchEmbellishmentsRequest, dispatch),
  fetchTeams: bindActionCreators(fetchTeamsRequest, dispatch),
  fetchTags: bindActionCreators(fetchTagsRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
