import React, { FC } from 'react';
import styles from './FooterSelectedNumber.module.scss';

interface FooterSelectedNumberProps {
  text: string;
}

const FooterSelectedNumber: FC<FooterSelectedNumberProps> = ({ text }) => (
  <span className={styles.text}>
    {text}
  </span>
);

export default FooterSelectedNumber;
