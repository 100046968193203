import { EmbellishmentTypes } from '../../../../models/Embellishment';

export const NUMBER_TYPES = [
  EmbellishmentTypes.BACK_NUMBER,
  EmbellishmentTypes.SMALL_NUMBER,
  EmbellishmentTypes.SMALL_NUMBERS,
  EmbellishmentTypes.LARGE_NUMBERS,
];

export const BOOLEAN_TYPES = [
  EmbellishmentTypes.TEAM_NAME,
  EmbellishmentTypes.SMALL_TEAM_NAME,
  EmbellishmentTypes.SPONSOR_BACK,
  EmbellishmentTypes.SPONSOR_CHEST,
  EmbellishmentTypes.SMALL_SPONSOR,
  EmbellishmentTypes.SPONSOR_SLEEVE,
  EmbellishmentTypes.EMBLEM,
  EmbellishmentTypes.SLEEVE,
  EmbellishmentTypes.CLUB_NAME_XL,
  EmbellishmentTypes.CLUB_NAME_S,
  EmbellishmentTypes.TEMPLATE,
  EmbellishmentTypes.SPECIAL_EMBLEM,
  EmbellishmentTypes.ADVERTISING_PRINT_100_MONOCHROMATIC,
  EmbellishmentTypes.ADVERTISING_PRINT_100_POLYCHROMATIC,
  EmbellishmentTypes.ADVERTISING_PRINT_200_MONOCHROMATIC,
  EmbellishmentTypes.ADVERTISING_PRINT_200_POLYCHROMATIC,
  EmbellishmentTypes.ADVERTISING_PRINT_400_MONOCHROMATIC,
  EmbellishmentTypes.ADVERTISING_PRINT_400_POLYCHROMATIC,
  EmbellishmentTypes.SUBLIMATION,
  EmbellishmentTypes.TEAMSPORTS_11_LOGO_LARGE,
  EmbellishmentTypes.TEAMSPORTS_11_LOGO_SMALL,
  EmbellishmentTypes.TEAMSPORTS_11_SLEEVE_PATCH,
  EmbellishmentTypes.CLUB_EMBLEM_POLYCHROMATIC,
  EmbellishmentTypes.CLUB_EMBLEM_MONOCHROMATIC,
];

export const EmbellishmentGroup = {
  NUMBER_TYPES,
  BOOLEAN_TYPES,
};
