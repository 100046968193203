import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import CreateTeam from './CreateTeam';
import { State } from '../../../../models/State';
import { DispatchProps, StateProps } from './CreateTeamProps';
import { createTeamRequest } from '../../../../actions/teams';
import { Club } from '../../../../models/Club';
import { externalTeamsRequest } from '../../../../actions/externalSystems/externalTeams';

const mapStatesToProps = ({
  teams: { error, loading },
  application: { clubId },
  clubs,
  externalSystems: {
    externalTeamsById,
    externalTeamsByHash,
  },
}: State): StateProps => ({
  clubId,
  club: clubId && clubs.byHash[clubId] && clubs.byHash[clubId].address
    ? clubs.byHash[clubId]
    : {
      address: {},
    } as Club,
  error,
  loading,
  externalTeams: externalTeamsById.map(id => externalTeamsByHash[id]),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  createTeam: bindActionCreators(createTeamRequest, dispatch),
  fetchExternalTeams: bindActionCreators(externalTeamsRequest, dispatch),

});

export default connect(mapStatesToProps, mapDispatchToProps)(CreateTeam);
