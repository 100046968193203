import React from 'react';
import { ProductDetailsProps } from './ProductDetailsProps';
import { useViewport } from '../../../../../../hooks/useViewport';
import DesktopProductDetails from '../DesktopProductDetails';
import MobileProductDetails from '../MobileProductDetails';

const ProductDetails = ({
  open,
  article,
  product,
  onClose,
}: ProductDetailsProps) => {
  const { isMobile } = useViewport();
  const ProductDetailsView = isMobile ? MobileProductDetails : DesktopProductDetails;

  return (
    <ProductDetailsView
      open={open}
      article={article}
      product={product}
      onClose={onClose}
    />
  );
};

export default ProductDetails;
