import React, { SyntheticEvent } from 'react';
import { Checkbox, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import Text from '../Text';
import styles from './styles.module.scss';
import { TableCheckboxHeaderProps } from './TableCheckboxHeaderProps';
import SortArrows from '../Table/components/SortArrows';

const TableCheckboxHeader = ({
  onClick, checked, title, checkbox, sortProps, sortable, disabled,
}: TableCheckboxHeaderProps) => {
  const [translate] = useTranslation();

  const handleClick = (event: SyntheticEvent) => {
    event.stopPropagation();
    onClick();
  };

  return (
    <div className={styles.headerComponent}>
      {checkbox ? (
        <Checkbox disabled={disabled} onClick={handleClick} checked={checked} />
      ) : (
        <Icon disabled={disabled} onClick={onClick} name={checked ? 'lock' : 'unlock'} link />
      )}
      <Text bold>{title ? translate(title) : title}</Text>
      {sortable && <SortArrows sortOrder={sortProps} />}
    </div>
  );
};

export default TableCheckboxHeader;
