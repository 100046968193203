import {
  catchError, map, mergeMap, tap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';

import { SIGN_IN_REQUEST } from '../constants/actionTypes';
import { signInSuccess, signInError } from '../actions/signIn';
import { SIGN_IN_PATH } from '../constants/paths';
import AUTH_TOKEN from '../constants/localStorageKeys';

export default baseUrl => (action$, state$, { ajax, localStorage }) => action$.pipe(
  ofType(SIGN_IN_REQUEST),
  mergeMap(({ payload: { username, password, params } }) => ajax({
    url: `${baseUrl}${SIGN_IN_PATH}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
      password,
    }),
  }).pipe(
    tap(({ response: { session: { authToken } } }) => {
      const storage = localStorage || window.localStorage;

      return storage.setItem(AUTH_TOKEN, authToken);
    }),
    map(({ response: signInResponse }) => (
      signInSuccess(signInResponse, params)
    )),
    catchError(error => of(signInError(error))),
  )),
);
