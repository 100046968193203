import { partition } from 'lodash';
import { ItemsByHash } from '../../../../../../../../models/Order';
import { SortOrder } from '../../../../../../../../models/general/SortOrder';
import { PlayersByHash } from '../../../../../../../../models/Player';
import SortingOrder from '../../../../../../../../constants/sortingOrder';

const sortString = (
  itemsByHash: any, playersByHash: PlayersByHash, order: SortOrder,
) => (a: string, b: string) => {
  const textA = playersByHash[itemsByHash[a].playerId].lastName.toUpperCase();
  const textB = playersByHash[itemsByHash[b].playerId].lastName.toUpperCase();

  return order === SortingOrder.ASC
    ? textA.localeCompare(textB)
    : textB.localeCompare(textA);
};

export default (
  itemsByHash: ItemsByHash,
  itemsById: string[],
  order: SortOrder,
  playersByHash: PlayersByHash,
): string[] => {
  const checkProperties = (id: string) => !!(
    playersByHash[(itemsByHash[id] as any).playerId].lastName
  );

  const [
    withLastName, withoutLastName,
  ] = partition(itemsById, itemId => (
    itemsByHash[itemId]
      && playersByHash[(itemsByHash[itemId] as any).playerId]
      && checkProperties(itemId)
  ));

  const sortAlg = sortString(itemsByHash, playersByHash, order);

  withLastName.sort(sortAlg);

  return [
    ...withLastName,
    ...withoutLastName,
  ];
};
