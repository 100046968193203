import { AjaxError } from 'rxjs/ajax';

import {
  SEND_INVITATION,
  SEND_INVITATION_SUCCESS,
  SEND_INVITATION_ERROR,
  RESEND_INVITATION_REQUEST,
  RESEND_INVITATION_SUCCESS,
  RESEND_INVITATION_ERROR,
  DEACTIVATE_INVITE_TOKEN_ERROR,
  DEACTIVATE_INVITE_TOKEN_REQUEST,
  DEACTIVATE_INVITE_TOKEN_SUCCESS,
  BULK_DEACTIVATE_INVITE_TOKEN_REQUEST,
  BULK_DEACTIVATE_INVITE_TOKEN_SUCCESS,
  BULK_DEACTIVATE_INVITE_TOKEN_ERROR,
} from '../constants/actionTypes';
import { CreateInvitation, InvitationPersonalData } from '../models/InvitationData';
import {
  ResendInvitationRequestAction,
  ResendInvitationSuccessAction,
  ResendInvitationErrorAction,
  DeactivateInviteTokenErrorAction,
  DeactivateInviteTokenRequestAction,
  DeactivateInviteTokenSuccessAction,
  BulkDeactivateInviteTokenRequestAction,
  BulkDeactivateInviteTokenSuccessAction,
  BulkDeactivateInviteTokenErrorAction,
  FetchInvitationsErrorAction,
  FetchInvitationsRequestAction,
  FetchInvitationsSuccessAction,
} from '../models/actions';
import * as types from '../constants/actionTypes';
import { InvitationResponse } from '../models/Invitations';
import { ListFilters } from '../models/ListFilters';

export interface SendInvitationAction {
  type: typeof SEND_INVITATION;
  payload: {
    createInvitation: CreateInvitation,
    invitationPersonalData: InvitationPersonalData,
    shouldRedirect: boolean,
  }
}

export interface SendInvitationSuccessAction {
  type: typeof SEND_INVITATION_SUCCESS;
}

export interface SendInvitationErrorAction {
  type: typeof SEND_INVITATION_ERROR,
  payload: {
    error: AjaxError,
  }
}

export const sendInvitationRequest = (
  createInvitation: CreateInvitation,
  invitationPersonalData: InvitationPersonalData,
  shouldRedirect: boolean,
): SendInvitationAction => ({
  type: SEND_INVITATION,
  payload: {
    createInvitation,
    invitationPersonalData,
    shouldRedirect,
  },
});

export const sendInvitationSuccess = (): SendInvitationSuccessAction => ({
  type: SEND_INVITATION_SUCCESS,
});

export const sendInvitationError = (error: AjaxError): SendInvitationErrorAction => ({
  type: SEND_INVITATION_ERROR,
  payload: {
    error,
  },
});

export const resendInvitationRequest = (token: string): ResendInvitationRequestAction => ({
  type: RESEND_INVITATION_REQUEST,
  payload: {
    token,
  },
});

export const resendInvitationSuccess = (): ResendInvitationSuccessAction => ({
  type: RESEND_INVITATION_SUCCESS,
});

export const resendInvitationError = (error: AjaxError): ResendInvitationErrorAction => ({
  type: RESEND_INVITATION_ERROR,
  payload: {
    error,
  },
});

export const deactivateInviteTokenRequest = (
  token: string,
): DeactivateInviteTokenRequestAction => ({
  type: DEACTIVATE_INVITE_TOKEN_REQUEST,
  payload: {
    token,
  },
});

export const deactivateInviteTokenSuccess = (
  payload = {},
): DeactivateInviteTokenSuccessAction => ({
  type: DEACTIVATE_INVITE_TOKEN_SUCCESS,
  payload,
});

export const deactivateInviteTokenError = (
  error: AjaxError,
): DeactivateInviteTokenErrorAction => ({
  type: DEACTIVATE_INVITE_TOKEN_ERROR,
  payload: {
    error,
  },
});

export const bulkDeactivateInviteTokenRequest = (
  tokens: string[],
): BulkDeactivateInviteTokenRequestAction => ({
  type: BULK_DEACTIVATE_INVITE_TOKEN_REQUEST,
  payload: {
    tokens,
  },
});

export const bulkDeactivateInviteTokenSuccess = (
  tokens: string[],
): BulkDeactivateInviteTokenSuccessAction => ({
  type: BULK_DEACTIVATE_INVITE_TOKEN_SUCCESS,
  payload: {
    tokens,
  },
});

export const bulkDeactivateInviteTokenError = (
  error: AjaxError,
): BulkDeactivateInviteTokenErrorAction => ({
  type: BULK_DEACTIVATE_INVITE_TOKEN_ERROR,
  payload: {
    error,
  },
});

export const fetchInvitationsRequest = (
  isPersonalInvitation: boolean,
  filters: ListFilters,
): FetchInvitationsRequestAction => ({
  type: types.FETCH_INVITATIONS_REQUEST,
  payload: {
    isPersonalInvitation,
    filters,
  },
});

export const fetchInvitationsSuccess = (
  invitations: InvitationResponse[],
  count: number,
): FetchInvitationsSuccessAction => ({
  type: types.FETCH_INVITATIONS_SUCCESS,
  payload: {
    invitations,
    count,
  },
});

export const fetchInvitationsError = (error: AjaxError): FetchInvitationsErrorAction => ({
  type: types.FETCH_INVITATIONS_ERROR,
  payload: {
    error,
  },
});
