import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import { v4 as uuidv4 } from 'uuid';
import { Actions } from '../models/actions';
import {
  CalendarReducer, EventListData, EventsByHash, EventStatus,
} from '../models/Calendar';
import {
  CANCEL_EVENT_REQUEST,
  CANCEL_EVENT_SUCCESS,
  CREATE_EVENT_ERROR,
  CREATE_EVENT_REQUEST,
  CREATE_EVENT_SUCCESS,
  DELETE_EVENT_REQUEST,
  DELETE_EVENT_SUCCESS,
  FETCH_EVENT_ERROR,
  FETCH_EVENT_REQUEST,
  FETCH_EVENT_SUCCESS,
  FETCH_EVENTS_ERROR,
  FETCH_EVENTS_REQUEST,
  FETCH_EVENTS_SUCCESS,
  PREVIEW_CSV_EVENT_ERROR,
  PREVIEW_CSV_EVENT_REQUEST,
  PREVIEW_CSV_EVENT_SUCCESS,
  RESET_EVENT,
  UPDATE_EVENT_ERROR,
  UPDATE_EVENT_REQUEST,
  UPDATE_EVENT_SUCCESS,
  UPDATE_RESULT_ERROR,
  UPLOAD_EVENTS_ERROR,
  UPLOAD_EVENTS_REQUEST,
  UPLOAD_EVENTS_SUCCESS,
  BULK_DELETE_EVENT_REQUEST,
  BULK_DELETE_EVENT_SUCCESS,
  BULK_DELETE_EVENT_ERROR,
  CREATE_EVENTS_REQUEST,
  RESET_EVENT_CREATED,
} from '../constants/actionTypes';

const initialState: CalendarReducer = {
  byHash: {},
  byId: [],
  count: 0,
  error: false,
  loading: false,
  eventCreated: false,
  eventRemoved: false,
  event: null,
  loadingPreview: false,
  previewEvent: null,
};

export default (state = initialState, action: Actions) => {
  switch (action.type) {
    case CREATE_EVENT_REQUEST:
      return {
        ...state,
        loading: true,
        eventCreated: false,
      };
    case RESET_EVENT_CREATED:
      return {
        ...state,
        eventCreated: false,
      };
    case CREATE_EVENTS_REQUEST:
      return {
        ...state,
        loading: true,
        eventCreated: false,
      };
    case CREATE_EVENT_SUCCESS: {
      const { events } = action.payload;
      return {
        ...state,
        loading: false,
        error: false,
        eventCreated: true,
        byHash: events
          .reduce((byHash: EventsByHash, event: EventListData) => ({
            ...byHash,
            [event.id]: event,
          }), state.byHash),
        byId: uniq([...state.byId, ...events.map(({ id }) => id)]),
      };
    }
    case CANCEL_EVENT_SUCCESS: {
      const { byHash } = state;
      const { id } = action.payload;
      return {
        ...state,
        loading: false,
        byHash: {
          ...byHash,
          [id]: {
            ...byHash[id],
            status: EventStatus.CANCELED,
          },
        },
      };
    }
    case DELETE_EVENT_SUCCESS: {
      const { id } = action.payload;
      const { [id]: removedEvent, ...updatedByHash } = state.byHash;
      return {
        ...state,
        loading: false,
        error: false,
        eventRemoved: true,
        byHash: updatedByHash,
        byId: difference(state.byId, [id]),
      };
    }
    case RESET_EVENT:
      return {
        ...state,
        event: null,
      };
    case BULK_DELETE_EVENT_SUCCESS: {
      const { groupId } = action.payload;
      const updatedByHash = state.byId.reduce((byHash: EventsByHash, eventId: number) => ({
        ...byHash,
        ...(state.byHash[eventId] && state.byHash[eventId].groupId !== groupId
          && ({ [eventId]: { ...state.byHash[eventId] } })),
      }), {});

      return {
        ...state,
        eventRemoved: true,
        byHash: updatedByHash,
        loading: false,
        error: false,
        byId: Object.keys(updatedByHash).map(Number),
      };
    }
    case CANCEL_EVENT_REQUEST:
    case BULK_DELETE_EVENT_REQUEST:
    case DELETE_EVENT_REQUEST:
      return {
        ...state,
        loading: true,
        eventRemoved: false,
      };
    case UPDATE_EVENT_REQUEST:
      return {
        ...state,
        loading: true,
        eventCreated: false,
      };
    case UPDATE_EVENT_SUCCESS: {
      const { event } = action.payload;
      return {
        ...state,
        loading: false,
        error: false,
        eventCreated: true,
        byHash: {
          ...state.byHash,
          [event.id]: event,
        },
      };
    }
    case FETCH_EVENT_REQUEST:
      return {
        ...state,
        loading: true,
        event: null,
      };
    case FETCH_EVENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_EVENTS_SUCCESS: {
      const { events, count } = action.payload;
      const eventsByHash = events
        .reduce((byHash: EventsByHash, event: EventListData) => ({
          ...byHash,
          [event.id ? event.id : uuidv4()]: event,
        }), {});

      return {
        ...state,
        byHash: eventsByHash,
        byId: Object.keys(eventsByHash).map(id => id),
        loading: false,
        count,
      };
    }
    case FETCH_EVENT_SUCCESS: {
      const { event } = action.payload;
      return {
        ...state,
        error: false,
        loading: false,
        event,
      };
    }
    case UPLOAD_EVENTS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPLOAD_EVENTS_SUCCESS: {
      const { events } = action.payload;
      const eventsByHash = events
        .reduce((byHash: EventsByHash, event: EventListData) => ({
          ...byHash,
          [event.id ? event.id : uuidv4()]: event,
        }), {});
      return {
        ...state,
        byHash: {
          ...state.byHash,
          ...eventsByHash,
        },
        byId: [...new Set([...state.byId, ...Object.keys(eventsByHash)])],
        loading: false,
        loadingPreview: false,
        previewEvent: null,
      };
    }
    case PREVIEW_CSV_EVENT_REQUEST:
      return {
        ...state,
        loadingPreview: true,
        previewEvent: null,
        error: false,
      };
    case PREVIEW_CSV_EVENT_SUCCESS:
      return {
        ...state,
        loadingPreview: false,
        previewEvent: action.payload.event,
      };
    case PREVIEW_CSV_EVENT_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        loadingPreview: false,
        previewEvent: null,
      };
    case BULK_DELETE_EVENT_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        eventRemoved: false,
      };
    case UPLOAD_EVENTS_ERROR:
    case FETCH_EVENTS_ERROR:
    case UPDATE_EVENT_ERROR:
    case CREATE_EVENT_ERROR:
    case FETCH_EVENT_ERROR:
    case UPDATE_RESULT_ERROR:
      return {
        ...state,
        loadingPreview: false,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};
