import { connect } from 'react-redux';
import State from '../../../../models';
import PlainInfoPage from './PlainInfoPage';

const mapStateToProps = ({
  clubs: { logoUrl },
}: State) => ({
  clubLogoUrl: logoUrl,
});

export default connect(mapStateToProps)(PlainInfoPage);
