import React from 'react';
import { Label } from 'semantic-ui-react';

interface LabelsProps {
  values: string[];
}

const Labels = ({ values }: LabelsProps) => (
  <div>
    {values.map(val => (
      <Label
        circular
        color="grey"
        content={val}
        key={val}
        size="large"
      />
    ))}
  </div>
);

export default Labels;
