import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import MemberView from './MemberView';
import { fetchMemberRequest } from '../../../../actions/members';
import { DispatchProps } from './MemberViewProps';
import { State } from '../../../../models/State';

const mapStateToProps = ({
  persons,
  players,
  members,
}: State) => ({
  personsByHash: persons.byHash,
  playersByHash: players.byHash,
  membersByHash: members.byHash,
  loading: persons.loading,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  fetchMember: bindActionCreators(fetchMemberRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberView);
