import { Column } from '../../components/Table/Column';

const columns = (translate: any): Column[] => [{
  size: 1,
  align: 'left',
  header: `# ${translate('ORDER')}`,
  key: 'orderId',
  sortable: true,
}, {
  size: 1,
  header: 'ORDER_DATE',
  align: 'left',
  key: 'createdAt',
  sortable: true,
}, {
  size: 2,
  header: 'NAME',
  align: 'left',
  key: 'name',
  sortable: false,
}, {
  size: 1,
  header: 'ITEMS_SUM_PRICE',
  align: 'left',
  key: 'itemsSumPrice',
  sortable: false,
}];

export default columns;
