import { SET_AUTH_TOKEN } from '../constants';

const setAuthToken = authToken => ({
  type: SET_AUTH_TOKEN,
  payload: {
    authToken,
  },
});

export default setAuthToken;
