import { AjaxError } from 'rxjs/ajax';
import {
  DELETE_EXTERNAL_REF_ERROR,
  DELETE_EXTERNAL_REF_REQUEST, DELETE_EXTERNAL_REF_SUCCESS,
} from '../../constants/actionTypes';
import {
  ExternalSystemName,
  ExternalSystemReference,
} from '../../models/externalSystemIntegration';
import {
  DeleteExternalReferenceErrorAction,
  DeleteExternalReferenceRequestAction,
  DeleteExternalReferenceSuccessAction,
} from '../../models/actions';

export const deleteExternalReferencesRequest = (
  referenceName: ExternalSystemName,
  teamId: string,
): DeleteExternalReferenceRequestAction => ({
  type: DELETE_EXTERNAL_REF_REQUEST,
  payload: {
    referenceName,
    teamId,
  },
});

export const deleteExternalReferencesSuccess = (
  externalRefs: { response: ExternalSystemReference[] },
): DeleteExternalReferenceSuccessAction => ({
  type: DELETE_EXTERNAL_REF_SUCCESS,
  payload: {
    externalRefs,
  },
});

export const deleteExternalReferencesError = (
  error: AjaxError,
): DeleteExternalReferenceErrorAction => ({
  type: DELETE_EXTERNAL_REF_ERROR,
  payload: {
    error,
  },
});
