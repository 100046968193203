import React, { FC, useEffect } from 'react';
import classNames from 'classnames';
import { Image } from 'semantic-ui-react';

import Avatar from './components/Avatar';
import styles from './ProfileAvatar.module.scss';
import { ProfileAvatarProps } from './ProfileAvatarProps';
import { Roles } from '../../../../constants/roles';

const ProfileAvatar: FC<ProfileAvatarProps> = ({
  fetchFile,
  filesByHash,
  italic = false,
  size = 'tiny',
  user: {
    firstName, lastName, profilePictureReference: imageId, userExists, roles,
  },
  ...props
}) => {
  const imageUrl = imageId && filesByHash[imageId];
  const isTreasurer = (typeof roles !== 'undefined') && roles.includes(Roles.TREASURER);
  const showBadge = isTreasurer || userExists;

  useEffect(() => {
    if (imageId && !filesByHash[imageId]) {
      fetchFile(imageId);
    }
    // eslint-disable-next-line
  }, [fetchFile, imageId]);

  return (
    <div
      className={classNames(styles.profileImageContainer, styles[`image-${size}`], italic && styles.italic)}
      {...props}
    >
      {imageUrl ? (
        <Image src={imageUrl} circular className={styles.profileImage} />
      ) : (
        <Avatar firstName={firstName} lastName={lastName} />
      )}
      {showBadge && (
        <div className={isTreasurer ? styles.currency : styles.user} />
      )}
    </div>
  );
};

export default ProfileAvatar;
