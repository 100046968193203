import { ReactChild } from 'react';

export enum HeaderTypes {
  H2 = 'h2',
  H3 = 'h3',
}

export interface HeaderProps {
  backLinkTo?: string,
  backLabel?: string,
  renderButton?: () => ReactChild | ReactChild[] | null,
  onEdit?: (editedText: string) => void,
  loading?: boolean,
  editionSuccessful?: boolean,
  title?: string,
  type?: HeaderTypes,
  border?: boolean,
  goBack?: boolean,
}
