import { Link } from 'react-router-dom';
import React, { FC } from 'react';
import queryString, { ParsedQuery } from 'query-string';
import { Header } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';

import RequestTokenForm from './components/RequestTokenForm';
import UpdatePasswordForm from './components/UpdatePasswordForm';
import styles from './ResetPassword.module.scss';
import { SIGN_IN_ROUTE } from '../../constants/routes';
import AuthenticationFormsWrapper from '../../components/AuthenticationFormsWrapper';

interface ResetPasswordProps extends RouteComponentProps {
  clubLogoUrl: string;
  resetPasswordCleanUp: () => void;
}

const ResetPassword: FC<ResetPasswordProps> = ({ clubLogoUrl, location, resetPasswordCleanUp }) => {
  const { resettoken: resetToken, username }: ParsedQuery = queryString.parse(location.search);
  const [translate] = useTranslation();

  const handleGoBack = () => {
    resetPasswordCleanUp();
  };

  return (
    <AuthenticationFormsWrapper clubLogoUrl={clubLogoUrl}>
      <Link onClick={handleGoBack} to={SIGN_IN_ROUTE} className={styles.backLink}>
        <div className={styles.arrow} />
        {translate('BACK_TO_LOGIN')}
      </Link>
      <Header as="h2" content={translate('RESET_PASSWORD')} className={styles.header} />
      {resetToken && username ? (
        <UpdatePasswordForm
          token={resetToken as string}
          username={username as string}
        />
      ) : (
        <RequestTokenForm />
      )}
    </AuthenticationFormsWrapper>
  );
};

export default ResetPassword;
