import React, { FC } from 'react';
import { Header } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import styles from './ProfileSection.module.scss';
import { ProfileSectionProps } from './ProfileSectionProps';

const ProfileSection: FC<ProfileSectionProps> = ({ title, data }) => {
  const [translate] = useTranslation();

  return (
    <div className={styles.contentWrapper}>
      <Header content={title} as="h3" />

      <div className={styles.statsContainer}>
        {data.map(({
          name, value, wideColumn, italic,
        }) => (
          <div className={wideColumn ? styles.wideColumn : ''} key={name}>
            <span>{translate(name)}</span>
            <span className={[italic ? styles.italic : styles.bold].join(' ')}>{typeof value === 'string' ? translate(value) : value}</span>
            {!value && <em>{translate('UNKNOWN')}</em>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProfileSection;
