import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Header, { HeaderTypes } from '../../../../components/Header';
import styles from './ProductDescription.module.scss';
import { ProductDescriptionProps } from './ProductDescriptionProps';

const ProductDescription: FC<ProductDescriptionProps> = ({ content }) => {
  const [translate] = useTranslation();

  return (
    <div className={styles.wrapper}>
      <Header title={translate('DESCRIPTION')} type={HeaderTypes.H3} />

      <p>
        {content}
      </p>
    </div>
  );
};

export default ProductDescription;
