import { bindActionCreators, Dispatch } from 'redux';

import { connect } from 'react-redux';
import MemberRow from './MemberRow';
import { State } from '../../../../../../models/State';
import { fetchFileRequest } from '../../../../../../actions/files';

const mapStatesToProps = ({
  files: {
    byHash,
  },
}: State) => ({
  filesByHash: byHash,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchFile: bindActionCreators(fetchFileRequest, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(MemberRow);
