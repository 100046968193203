import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import ChangeTeamSeasonPopup from './ChangeTeamSeasonPopup';
import { teamSeasonTransferRequest } from '../../../../actions/teams';
import { DispatchProps } from './ChangeTeamSeasonPopupProps';
import { State } from '../../../../models/State';

const mapStateToProps = ({
  teams: { switchSeasonSuccess, switchingSeason },
}: State) => ({
  switchSeasonSuccess, switchingSeason,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  teamSeasonTransfer: bindActionCreators(teamSeasonTransferRequest, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(ChangeTeamSeasonPopup);
