import React, { FC } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { TableFilterProps } from './TableFilterProps';

const TableFilter: FC<TableFilterProps> = ({
  columnName,
  component: Filter,
  onFilterChange,
  filters = {},
}) => (
  <Popup
    trigger={<Icon name="filter" color={filters && Object.keys(filters).includes(columnName!) ? 'red' : 'grey'} size="small" circular />}
    on="click"
    size="large"
    position="bottom center"
    style={{ padding: '20px' }}
  >
    <Filter
      column={columnName}
      onFilterChange={onFilterChange}
      filterValue={filters[columnName]}
    />
  </Popup>
);

export default TableFilter;
