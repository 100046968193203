import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import HeaderComponent from '../../components/Header';
import styles from './ProfileEdit.module.scss';
import { User } from '../../models/general/User';
import { PROFILE_ROUTE } from '../../constants/routes';
import { UpdateUserRequestAction } from '../../models/actions';

interface ProfileEditProps {
  user: User|null,
  updateUser: (
    firstName: string, lastName: string, phoneNumber?: string,
  ) => UpdateUserRequestAction,
}

const ProfileEdit: FC<ProfileEditProps> = ({ user, updateUser }) => {
  const [firstName, setFirstName] = useState<string>(user ? user.firstName : '');
  const [lastName, setLastName] = useState<string>(user ? user.lastName : '');
  const [translate] = useTranslation();

  const save = () => {
    updateUser(firstName, lastName);
  };

  const renderButton = () => [
    <Button as={Link} to={PROFILE_ROUTE} content={translate('CANCEL')} />,
    <Button primary onClick={save} content={translate('SAVE')} />,
  ];

  const changeFirstName = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(value);
  };

  const changeLastName = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(value);
  };

  return user && (
    <>
      <HeaderComponent title={translate('USER_SETTINGS')} renderButton={renderButton} border />
      <div className={styles.content}>
        <div className={styles.field}>
          <span className={styles.label}>{translate('FIRST_NAME')}</span>
          <Input onChange={changeFirstName} defaultValue={firstName} />
        </div>
        <div className={styles.field}>
          <span className={styles.label}>{translate('LAST_NAME')}</span>
          <Input onChange={changeLastName} defaultValue={lastName} />
        </div>
      </div>
    </>
  );
};

export default ProfileEdit;
