import { AuthenticationActions, ClubActions } from '../models/actions';
import { ClubReducer } from '../models/Club';
import {
  FETCH_CLUB_REQUEST,
  FETCH_CLUB_SUCCESS,
  FETCH_CLUB_ERROR,
  FETCH_CLUB_LOGO_REQUEST,
  FETCH_CLUB_LOGO_SUCCESS,
  FETCH_CLUB_LOGO_ERROR,
  UPLOAD_CLUB_LOGO_REQUEST,
  UPLOAD_CLUB_LOGO_SUCCESS,
  UPLOAD_CLUB_LOGO_ERROR,
  UPDATE_CLUB_SUCCESS,
  UPDATE_CLUB_REQUEST,
  UPDATE_CLUB_ERROR,
} from '../constants/actionTypes';
import { DEFAULT_LOGO_URL } from '../constants/customizations';
import { SIGN_OUT_REQUEST, UNAUTHORIZED } from '../packages/authentication';

export const initialState: ClubReducer = {
  byHash: {},
  byId: [],
  loading: false,
  loadingLogo: false,
  loadingUpload: false,
  logoUrl: DEFAULT_LOGO_URL,
  error: false,
  errorMessage: '',
};

export default (state = initialState, action: ClubActions | AuthenticationActions) => {
  switch (action.type) {
    case FETCH_CLUB_LOGO_REQUEST:
      return {
        ...state,
        loadingLogo: true,
      };
    case UPDATE_CLUB_REQUEST:
    case FETCH_CLUB_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CLUB_LOGO_SUCCESS: {
      const { club } = action.payload;
      return {
        ...state,
        byHash: {
          ...state.byHash,
          [club.id]: {
            ...state.byHash[club.id],
            ...club,
          },
        },
        byId: [
          ...state.byId.filter(fileId => fileId !== club.id),
          club.id,
        ],
        loadingLogo: false,
        logoUrl: club.logoUrl || DEFAULT_LOGO_URL,
      };
    }
    case UPDATE_CLUB_SUCCESS:
    case FETCH_CLUB_SUCCESS: {
      const { club } = action.payload;

      return {
        ...state,
        byHash: {
          ...state.byHash,
          [club.id]: club,
        },
        byId: [
          ...state.byId.filter(fileId => fileId !== club.id),
          club.id,
        ],
        loading: false,
        logoUrl: club.logoUrl || DEFAULT_LOGO_URL,
      };
    }
    case FETCH_CLUB_LOGO_ERROR:
      return {
        ...state,
        error: true,
        loadingLogo: false,
      };
    case UPDATE_CLUB_ERROR:
    case FETCH_CLUB_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case UPLOAD_CLUB_LOGO_REQUEST:
      return {
        ...state,
        loadingUpload: true,
      };
    case UPLOAD_CLUB_LOGO_SUCCESS: {
      const { clubId, publicUrl } = action.payload;
      const timestamp = (new Date()).valueOf();
      const logoUrl = `${publicUrl}?ts=${timestamp}&alpha=true`;

      return {
        ...state,
        loadingUpload: false,
        logoUrl,
        byHash: {
          ...state.byHash,
          [clubId]: {
            ...state.byHash[clubId],
            logoUrl,
          },
        },
      };
    }
    case UPLOAD_CLUB_LOGO_ERROR:
      return {
        ...state,
        error: true,
        loadingUpload: false,
      };
    case SIGN_OUT_REQUEST:
    case UNAUTHORIZED:
      return {
        ...initialState,
        logoUrl: state.logoUrl,
      };
    default:
      return state;
  }
};
