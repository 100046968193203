import { Roles } from './roles';

export const PLAYERS_LIST_LIMIT = 25;
export const TEAMS_LIST_LIMIT = 10;
export const MAX_TEAMS_LIST_LIMIT = 200;
export const TEAM_ORDERS_LIST_LIMIT = 10;
export const DEFAULT_LIST_LIMIT = 10;
export const TEAM_BASKET_ORDERS_LIST_LIMIT = 10;
export const INVITATIONS_LIST_LIMIT = 25;

export const APP_NAME = 'ntsClub';
export const IMAGES_EXTENSIONS = ['jpg', 'png', 'svg', 'jpeg'];
export const EXCEL_EXTENSIONS = ['xls', 'xlsx'];
export const UPLOAD_EVENTS_EXTENSIONS = ['xlsx'];

export const DEFAULT_LOGO_URL = '/images/11-ts-logo.png';

export const EN = 'en';

export const NOTIFICATION_DISPLAY_TIME = 5000;

export const REQUIRED_ROLES = [Roles.CLUB_ADMIN, Roles.TEAM_LEAD, Roles.PLAYER];

export const TEMPLATE_EVENT_XLSX_FILE = `${process.env.PUBLIC_URL}/xlsx/template.xlsx`;

export const IUBENDA_API = 'https://www.iubenda.com/api/';
export const TERMS_AND_CONDITIONS_PATH = 'nutzungsbedingungen/94821277';
export const DATA_PRIVACY_PATH = 'privacy-policy/94821277';

export const CUSTOMER_SERVICE_EMAIL_11TEAMSPORTS = 'service@11teamsports.com';

export const INDIVIDUAL_ORDERS_LIST_LIMIT = 10;

export const TABLE_PAGE_URL_PARAM = 'page';
export const TABLE_SEARCH_URL_PARAM = 'search';
export const TABLE_SORT_FIELD_URL_PARAM = 'field';
export const TABLE_SORT_ORDER_URL_PARAM = 'order';
export const TABLE_FILTER_URL_PARAM = 'filter';
