import React, { FC, useState } from 'react';
import { Button, Confirm, ConfirmProps as SemanticConfirmProps } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import styles from './Confirm.module.scss';
import { ConfirmProps } from './ConfirmProps';

const ConfirmComponent: FC<ConfirmProps> = ({
  onConfirm, triggerText, confirmText, as, disabled, headerText, ...props
}) => {
  const [translate] = useTranslation();
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);

  const setOpen = (value: boolean) => (event: React.MouseEvent) => {
    event.stopPropagation();
    setConfirmIsOpen(value);
  };

  const handleConfirm = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: SemanticConfirmProps,
  ) => {
    setConfirmIsOpen(false);
    onConfirm(event, data);
  };

  return (
    <>
      <Button
        as={as}
        content={triggerText}
        onClick={setOpen(true)}
        {...props}
        disabled={disabled}
      />
      <Confirm
        className={styles.confirmModal}
        onCancel={setOpen(false)}
        onConfirm={handleConfirm}
        open={confirmIsOpen}
        header={headerText || translate('ARE_YOU_SURE')}
        content={confirmText}
        cancelButton={(
          <Button
            basic
            className={styles.button}
            content={translate('NO')}
            fluid
            primary
          />
        )}
        confirmButton={(
          <Button
            className={styles.button}
            content={translate('YES')}
            fluid
            primary
          />
        )}
      />
    </>
  );
};

export default ConfirmComponent;
