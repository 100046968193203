import { TeamBasketOrder } from '../../models/TeamBasketOrder';

export const serializeTeamBasketOrder = ({
  Id,
  id,
  ...rest
}: TeamBasketOrder & { Id: number }): TeamBasketOrder => ({
  ...rest,
  id: id || Id,
});
