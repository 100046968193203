import { Column } from '../../../../components/Table/Column';

const columns: Column[] = [{
  width: '35%',
  header: 'TEAM_MEMBER',
  align: 'left',
  key: 'firstName',
  sortable: true,
}, {
  width: '20%',
  header: 'PREFFERED_TEAM_POSITION',
  align: 'left',
  key: 'preferredPosition',
  sortable: true,
}, {
  width: '15%',
  header: 'TEAMS_SHIRT_NUMBER',
  align: 'left',
  key: 'teamNumber',
  sortable: true,
}, {
  width: '15%',
  header: 'FAVOURITE_NUMBER',
  align: 'left',
  key: 'number',
  sortable: true,
}, {
  width: '10%',
  header: 'ORDER_ITEMS',
  key: 'orderItems',
  align: 'right',
}, {
  width: '5%',
  key: 'menu',
  align: 'right',
}];

export default columns;
