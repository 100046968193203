import authEpics from './epics';
import reducer from './reducers';


const useAuthentication = ({ baseUrl, app }) => {
  const epics = authEpics.map(epic => epic(baseUrl, app));

  return [
    reducer,
    epics,
  ];
};

export default useAuthentication;
export * from './actions';
export * from './constants';
