import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { Dropdown } from 'semantic-ui-react';

import ItemsList from '../../../MemberDetails/components/MobileItemsList';
import styles from './MobileProductDetails.module.scss';
import { ItemsByHash } from '../../../../../../models/Order';
import { MobileProductDetailsProps } from './MobileProductDetailsProps';
import { OrderItemType } from '../../../../OrderPreparationProps';
import changeProperty from '../../../../methods/changeProperty';
import MobileFooter from '../../../../../../components/MobileFooter';
import MobileModal from '../../../MobileModal';
import changeEmbellishments from '../../../../methods/changeEmbellishments';
import Confirm from '../../../../../../components/Confirm';
import ManageEmbellishmentsModal from '../ManageEmbellishmentsModal';
import NumbersBalloon from '../../../NumbersBalloon';

const MobileProductDetails = ({
  open,
  product: {
    name, embellishments,
  },
  article,
  onClose,
  itemsByHash,
  itemsById,
  updateItems,
  lockedNumbers,
  lockNumbers,
  usedNumbers,
}: MobileProductDetailsProps) => {
  const [translate] = useTranslation();
  const [editableItemsByHash, setEditableItemsByHash] = useState<ItemsByHash>({});
  const selectedNumbers = itemsById.map(id => get(editableItemsByHash, [id, 'numberLabel'])) as number[];
  const [memberIds, setMemberIds] = useState<string[]>([]);

  useEffect(() => {
    const { itemIds: ids = [] } = article || {};
    setMemberIds(ids);
  }, [article]);

  useDeepCompareEffect(() => {
    setEditableItemsByHash(itemsById.reduce((prev: ItemsByHash, id: string) => ({
      ...prev,
      [id]: itemsByHash[id],
    }), {}));
  }, [itemsById, itemsByHash]);

  const close = () => {
    onClose();
    setEditableItemsByHash(itemsByHash);
  };

  const onSave = () => {
    onClose();
    updateItems(editableItemsByHash);
  };

  return (
    <>
      <MobileModal
        button={(
          <MobileFooter
            buttonText={translate('SAVE')}
            onClick={onSave}
          />
        )}
        content={(
          <div className={styles.contentWrapper}>
            <ItemsList
              changeProperty={changeProperty(editableItemsByHash, setEditableItemsByHash)}
              itemsById={memberIds}
              itemsByHash={editableItemsByHash}
              itemType={OrderItemType.PLAYER}
            />
          </div>
        )}
        border
        isOpen={open}
        title={name}
        closeIcon={false}
        titleEllipsis
        menuItems={[
          <Confirm
            confirmText={translate('SAVE_ORDER_CONFIRMATION')}
            onConfirm={onSave}
            as={Dropdown.Item}
            triggerText={translate('SAVE')}
            key="save-order-confirmation"
          />,
          <Confirm
            confirmText={translate('CANCEL_ARTICLE_CONFIGURATION_CONFIRMATION')}
            onConfirm={close}
            as={Dropdown.Item}
            triggerText={translate('CANCEL')}
            key="cancel-order-confirmation"
          />,
          !!(embellishments && embellishments.length) && (
            <ManageEmbellishmentsModal
              as={Dropdown.Item}
              changeEmbellishments={changeEmbellishments(
                editableItemsByHash, setEditableItemsByHash,
              )}
              embellishments={embellishments}
              itemsByHash={editableItemsByHash}
              itemsById={memberIds}
              key="manage-embellishments"
            />
          ),
          <NumbersBalloon
            // @ts-ignore
            as={Dropdown.Item}
            selectedNumbers={selectedNumbers}
            lockedNumbers={lockedNumbers}
            lockNumbers={lockNumbers}
            usedNumbers={usedNumbers}
            key="numbers"
          />,
        ].filter(Boolean)}
        onClose={close}
      />
    </>
  );
};

export default MobileProductDetails;
