import { RouteComponentProps } from 'react-router-dom';
import { SignUpData } from '../../models/signUp/SignUpData';
import { Token } from '../../models/Token';
import { ServerError } from '../../models/general/ServerError';

export interface SignUpProps extends RouteComponentProps {
  clubLogoUrl: string;
  signUpWithToken: (
    signUpData: SignUpData, inviteToken: string, tokenDetails: Token,
  ) => void;
  fetchTokenDetails: (token: string) => void;
  setUserConsentApproved: (approved: boolean) => void;
  loading: boolean;
  consentApproved: boolean;
  serverError: ServerError | null;
  tokenDetails?: Token | null;
  resetSignup: () => void;
}

export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';
export const EMAIL = 'email';
export const PASSWORD = 'password';
export const CONFIRM_PASSWORD = 'confirmPassword';

export interface SignUpFormShema {
  [FIRST_NAME]: string;
  [LAST_NAME]: string;
  [EMAIL]: string;
  [PASSWORD]: string;
  [CONFIRM_PASSWORD]: string;
}
