import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AjaxError } from 'rxjs/ajax';
import { ChangeTeamStatusRequestAction, NotificationType } from '../../models/actions';
import { State } from '../../models/State';
import {
  CHANGE_TEAM_STATUS_ERROR,
  CHANGE_TEAM_STATUS_REQUEST, CHANGE_TEAM_STATUS_SUCCESS,
} from '../../constants/actionTypes';
import { addNotification } from '../../actions/notifications';
import { handleError } from '../../actions/errors';
import { CHANGE_TEAM_STATUS_PATH } from '../../constants/paths';
import { changeTeamStatusError, changeTeamStatusSuccess } from '../../actions/teams';

export default (
  action$: ActionsObservable<ChangeTeamStatusRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(CHANGE_TEAM_STATUS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      status,
      teamId,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: CHANGE_TEAM_STATUS_PATH.replace('$teamId', `${teamId}`),
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify({
      status,
    }),
  }).pipe(
    mergeMap(() => [
      addNotification(NotificationType.SUCCESS, CHANGE_TEAM_STATUS_SUCCESS),
      changeTeamStatusSuccess(teamId, status),
    ]),
    catchError((error: AjaxError) => [
      handleError(error, CHANGE_TEAM_STATUS_ERROR),
      changeTeamStatusError(error),
    ]),
  )),
);
