import get from 'lodash/get';

import { EmbellishmentTypes } from '../../../../models/Embellishment';
import { GetEmbellishmentsForItemProps } from './getEmbellishmentsForItemProps';
import { Player } from '../../../../models/Player';

export const enrichEmbellishment = (
  embellishment: { type: EmbellishmentTypes, mandatory?: boolean }, player?: Player | null,
) => {
  const { type, mandatory } = embellishment;
  const { lastName = null, teamNumber = null, number = null } = player || {};
  switch (type) {
    case EmbellishmentTypes.BACK_NUMBER:
    case EmbellishmentTypes.SMALL_NUMBER:
      return { type, value: teamNumber || number, mandatory };
    case EmbellishmentTypes.PLAYER_NAME:
    case EmbellishmentTypes.SMALL_PLAYER_NAME:
      return { type, value: lastName, mandatory };
    default:
      return { type, value: null, mandatory };
  }
};

const getEmbellishmentsForItem = ({
  itemsById, itemsByHash, productCatalogId, productsByHash, player,
}: GetEmbellishmentsForItemProps) => {
  const relatedItem = itemsById.map(id => itemsByHash[id])
    .find(item => item.productCatalogId === productCatalogId);
  const itemEmbellishments = get(relatedItem, 'embellishments');
  const { embellishments = [] } = productsByHash[productCatalogId] || {};
  const defaultEmbellishments = embellishments
    .filter(({ mandatory }) => mandatory)
    .map(({ embellishment, mandatory }) => ({ type: embellishment, mandatory }));

  return (itemEmbellishments || defaultEmbellishments)
    .map((
      embellishment: any,
    ) => enrichEmbellishment(embellishment, player));
};

export default getEmbellishmentsForItem;
