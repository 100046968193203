import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import ClubTeams from './ClubTeams';
import { State } from '../../models/State';
import { fetchTeamsRequest, deleteTeamRequest } from '../../actions/teams';

const mapStatesToProps = ({
  teams: {
    loading,
    byId,
    byHash,
    count,
  },
}: State) => ({
  teams: byId.map(id => byHash[id]),
  loading,
  count,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetch: bindActionCreators(fetchTeamsRequest, dispatch),
  deleteTeam: bindActionCreators(deleteTeamRequest, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(ClubTeams);
