import { bindActionCreators, Dispatch } from 'redux';

import { connect } from 'react-redux';
import ProductsRow from './ProductsRow';
import { State } from '../../../../../../models/State';
import { fetchFileRequest } from '../../../../../../actions/files';
import { toggleProductSelection } from '../../../../../../actions/orderEditor';

const mapStatesToProps = ({
  files: { byHash },
  application: { clubId },
  catalog: { embellishmentsByHash },
}: State) => ({
  filesByHash: byHash,
  embellishmentsByHash,
  clubId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchFile: bindActionCreators(fetchFileRequest, dispatch),
  toggleSelection: bindActionCreators(toggleProductSelection, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(ProductsRow);
