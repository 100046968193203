import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';

import { BULK_DELETE_EVENT_REQUEST } from '../../constants/actionTypes';
import { NotificationType, BulkDeleteEventRequestAction } from '../../models/actions';
import { State } from '../../models/State';
import { BULK_EVENTS_DELETE } from '../../constants/paths';
import { bulkDeleteEventSuccess, bulkDeleteEventError } from '../../actions/calendar';
import { addNotification } from '../../actions/notifications';
import { handleError } from '../../actions/errors';

export default (
  action$: ActionsObservable<BulkDeleteEventRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(BULK_DELETE_EVENT_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      groupId, eventType,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: BULK_EVENTS_DELETE.replace('$groupId', `${groupId}`),
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    mergeMap(() => [
      addNotification(NotificationType.SUCCESS, `BULK_DELETE_${eventType}_SUCCESS`),
      bulkDeleteEventSuccess(groupId),
    ]),
    catchError((error: AjaxError) => [
      handleError(error, `BULK_DELETE_${eventType}_ERROR`),
      bulkDeleteEventError(error),
    ]),
  )),
);
