import { Roles } from '../constants/roles';
import { User } from '../models/general/User';

const filterTeamsBasedOnRole = (
  authUser: User | null, userRole: Roles | null, teamsById: number[],
) => {
  if (authUser && authUser.teams && userRole && userRole !== Roles.CLUB_ADMIN) {
    return teamsById.filter(id => {
      const teamData = authUser.teams!.find(team => team.id === id);
      return teamData ? teamData.roles.includes(userRole) : true;
    });
  }
  return teamsById;
};

export default filterTeamsBasedOnRole;
