import { AjaxError } from 'rxjs/ajax';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';

import { RESEND_INVITATION_REQUEST, RESEND_INVITATION_SUCCESS, RESEND_INVITATION_ERROR } from '../../constants/actionTypes';
import { RESEND_INVITATION_PATH } from '../../constants/paths';
import { resendInvitationSuccess, resendInvitationError } from '../../actions/invitations';
import { NotificationType, ResendInvitationRequestAction } from '../../models/actions';
import { State } from '../../models/State';
import { addNotification } from '../../actions/notifications';
import { handleError } from '../../actions/errors';

export default (
  action$: ActionsObservable<ResendInvitationRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(RESEND_INVITATION_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      token,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: RESEND_INVITATION_PATH.replace(':token', token),
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    mergeMap(() => [
      addNotification(NotificationType.SUCCESS, RESEND_INVITATION_SUCCESS),
      resendInvitationSuccess(),
    ]),
    catchError((error: AjaxError) => [
      handleError(error, RESEND_INVITATION_ERROR),
      resendInvitationError(error),
    ]),
  )),
);
