import React, { useMemo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import styles from './TeamSelectComponent.module.scss';
import { TeamSelectComponentProps } from './TeamSelectComponentProps';

const TeamSelectComponent: FC<TeamSelectComponentProps> = ({
  activeTeams, teamId, onTeamChange,
}) => {
  const [translate] = useTranslation();

  const handleTeamChange = (
    event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps,
  ) => {
    onTeamChange(data.value as number);
  };

  const teamsOptions = useMemo(() => (activeTeams || []).map(t => ({
    key: t.id,
    text: t.name,
    value: t.id,
  })), [activeTeams]);

  return (
    <div className={styles.teamInfo}>
      <span>{translate('TEAM')}</span>
      <Dropdown
        className={styles.teamsPicker}
        placeholder={translate('TEAM')}
        value={teamId}
        selection
        options={teamsOptions}
        onChange={handleTeamChange}
      />
    </div>
  );
};

export default TeamSelectComponent;
