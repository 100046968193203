import { AjaxError } from 'rxjs/ajax';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import {
  catchError, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import {
  BULK_DEACTIVATE_INVITE_TOKEN_REQUEST,
  BULK_DEACTIVATE_INVITE_TOKEN_ERROR,
  BULK_DEACTIVATE_INVITE_TOKEN_SUCCESS,
} from '../../constants/actionTypes';
import { BULK_DEACTIVATE_TOKEN } from '../../constants/paths';
import { BulkDeactivateInviteTokenRequestAction, NotificationType } from '../../models/actions';
import { State } from '../../models/State';
import { handleError } from '../../actions/errors';
import { bulkDeactivateInviteTokenSuccess, bulkDeactivateInviteTokenError } from '../../actions/invitations';
import { addNotification } from '../../actions/notifications';

export default (
  action$: ActionsObservable<BulkDeactivateInviteTokenRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(BULK_DEACTIVATE_INVITE_TOKEN_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { tokens } },
    { authentication: { authToken } },
  ]) => ajax({
    url: BULK_DEACTIVATE_TOKEN,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify({
      tokens,
    }),
  }).pipe(
    mergeMap(() => [
      addNotification(NotificationType.SUCCESS, BULK_DEACTIVATE_INVITE_TOKEN_SUCCESS),
      bulkDeactivateInviteTokenSuccess(tokens),
    ]),
    catchError((error: AjaxError) => [
      handleError(error, BULK_DEACTIVATE_INVITE_TOKEN_ERROR),
      bulkDeactivateInviteTokenError(error),
    ]),
  )),
);
