import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';

import { DELETE_EVENT_REQUEST } from '../../constants/actionTypes';
import { DeleteEventRequestAction, NotificationType } from '../../models/actions';
import { State } from '../../models/State';
import { EVENTS_DELETE } from '../../constants/paths';
import { deleteEventSuccess, deleteEventError } from '../../actions/calendar';
import { addNotification } from '../../actions/notifications';
import { handleError } from '../../actions/errors';

export default (
  action$: ActionsObservable<DeleteEventRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(DELETE_EVENT_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      id,
    },
  }, {
    authentication: { authToken }, calendar: { byHash },
  }]) => ajax({
    url: EVENTS_DELETE.replace('$eventId', `${id}`),
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    mergeMap(() => [
      addNotification(NotificationType.SUCCESS, `DELETE_${byHash[id].type}_SUCCESS`),
      deleteEventSuccess(id),
    ]),
    catchError((error: AjaxError) => [
      handleError(error, `DELETE_${byHash[id].type}_ERROR`),
      deleteEventError(error),
    ]),
  )),
);
