import {
  catchError, mergeMap, withLatestFrom,
} from 'rxjs/operators';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { push } from 'connected-react-router';

import { AjaxError } from 'rxjs/ajax';
import pickBy from 'lodash/pickBy';
import { ASSIGN_MEMBER_ERROR, ASSIGN_MEMBER_REQUEST, ASSIGN_MEMBER_SUCCESS } from '../../constants/actionTypes';
import { assignMemberError } from '../../actions/members';
import { State } from '../../models/State';
import { ASSIGN_MEMBERS_PATH } from '../../constants/paths';
import { handleError } from '../../actions/errors';
import { AssignMemberRequestAction, NotificationType } from '../../models/actions';
import { CLUB_TEAMS_MEMBERS_ROUTE } from '../../constants/routes';
import { addNotification } from '../../actions/notifications';

export default (
  action$: ActionsObservable<AssignMemberRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(ASSIGN_MEMBER_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { teamId, personId, role } },
    { authentication: { authToken }, application: { clubId } },
  ]) => ajax({
    url: ASSIGN_MEMBERS_PATH,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify(pickBy({
      teamId,
      clubId,
      personId,
      role,
    })),
  }).pipe(
    mergeMap(() => [
      push(CLUB_TEAMS_MEMBERS_ROUTE.replace(':teamId', String(teamId))),
      addNotification(NotificationType.SUCCESS, ASSIGN_MEMBER_SUCCESS),
    ]),
    catchError((error: AjaxError) => [
      handleError(error, ASSIGN_MEMBER_ERROR),
      assignMemberError(error),
    ]),
  )),
);
