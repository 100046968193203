import React, { useState, FC } from 'react';
import {
  Modal, Button, Radio,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import styles from './DeleteEventModal.module.scss';
import { DeleteEventRequestAction, BulkDeleteEventRequestAction } from '../../../../models/actions';
import { Training, Match, OneTimeEvent } from '../../../../models/Calendar';
import { EventType } from '../../../../constants/eventTypes';

interface DeleteEventModalProps {
  deleteEvent: (id: number) => DeleteEventRequestAction;
  bulkDeleteEvents: (groupId: string, eventType: EventType) => BulkDeleteEventRequestAction;
  event: Match | Training | OneTimeEvent | null;
  idDeleteSeriesAvailable?: boolean;
  onClose: () => void;
}

const DeleteEventModal: FC<DeleteEventModalProps> = ({
  bulkDeleteEvents,
  deleteEvent,
  event,
  onClose,
}) => {
  const [translate] = useTranslation();
  const [deleteIndividual, setDeleteIndividual] = useState(true);

  const handleSubmit = () => {
    if (deleteIndividual && event && event.id) {
      deleteEvent(event.id);
    } else if (event && event.groupId) {
      bulkDeleteEvents(event.groupId,
        (event as Match).match ? EventType.MATCH : EventType.TRAINING);
    }
  };

  const toggleDeletion = () => {
    setDeleteIndividual(currentDeletionType => !currentDeletionType);
  };

  const SeriesSelection = () => (
    <div className={styles.radioWrapper}>
      <Radio
        label={translate('DELETE_THIS_EVENT')}
        checked={deleteIndividual}
        onChange={toggleDeletion}
        className={styles.radioButton}
      />
      <Radio
        label={translate('DELETE_EVENT_SERIES')}
        checked={!deleteIndividual}
        onChange={toggleDeletion}
      />
    </div>
  );

  const TrainingModal = () => (
    <>
      <Modal.Header>{translate('CONFIRM_DELETE_TRAINING')}</Modal.Header>
      <Modal.Content className={styles.content}>
        <p>{translate('CONFIRM_DELETE_TRAINING_TEXT')}</p>
        {(event!.numberOfEventsInGroup && event!.numberOfEventsInGroup > 1) && <SeriesSelection />}
      </Modal.Content>
    </>
  );

  const MatchModal = () => (
    <>
      <Modal.Header>{translate((event as Match).match && 'CONFIRM_DELETE_MATCH')}</Modal.Header>
      <Modal.Content className={styles.content}>
        <p>{translate((event as Match).match && 'CONFIRM_DELETE_MATCH_TEXT')}</p>
        {(event!.numberOfEventsInGroup && event!.numberOfEventsInGroup > 1) && <SeriesSelection />}
      </Modal.Content>
    </>
  );

  const OneTimeEventModal = () => (
    <>
      <Modal.Header>{translate('CONFIRM_DELETE_ONE_TIME_EVENT')}</Modal.Header>
      <Modal.Content className={styles.content}>
        <p>{translate('CONFIRM_DELETE_ONE_TIME_EVENT_TEXT')}</p>
      </Modal.Content>
    </>
  );

  const ModalContent = () => {
    if (!event) {
      return null;
    }
    if (event.type === EventType.TRAINING) {
      return <TrainingModal />;
    }
    if (event.type === EventType.MATCH) {
      return <MatchModal />;
    }
    return <OneTimeEventModal />;
  };

  return (
    <Modal onClose={onClose} className={styles.wrapper} open>
      <ModalContent />
      <Modal.Actions>
        <Button basic primary onClick={onClose}>{translate('CANCEL')}</Button>
        <Button
          primary
          content={translate(deleteIndividual ? 'DELETE' : 'DELETE_EVENT_SERIES')}
          onClick={handleSubmit}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default DeleteEventModal;
