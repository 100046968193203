import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { State } from '../../models/State';
import InactiveTeamPopup from './InactiveTeamPopup';
import { clearTeamId } from '../../actions/application';

const mapStatesToProps = ({
  teams: { hasInactiveTeams },
  application: { userRole, teamId },
}: State) => ({
  teamId,
  hasInactiveTeams,
  userRole,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  clearTeamId,
}, dispatch);

export default connect(mapStatesToProps, mapDispatchToProps)(InactiveTeamPopup);
