import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Modal } from 'semantic-ui-react';
import styles from './InformTeamModal.module.scss';
import Header from '../../../../components/Header';
import { INFORM_TEAM, RESET_ATTENDANCE } from '../MatchEventModal/MatchEventModalProps';
import { InformTeamModalProps } from './InformTeamModalProps';

const InformTeamModal: FC<InformTeamModalProps> = ({
  showInformTeamModal,
  setShowInformTeamModal,
  onEditFormSubmit,
  toggleResetAttendance,
  toggleInformTeam,
  firstName,
  informTeamParams,
}) => {
  const [translate] = useTranslation();

  return (
    <Modal
      open={showInformTeamModal}
      key="inform-team-modal"
      size="tiny"
      onClose={() => { setShowInformTeamModal(false); }}
      closeIcon
      closeOnDimmerClick={false}
    >
      <Header
        title={translate('INFORM_TEAM')}
        renderButton={() => (
          <Button
            content={translate('SAVE')}
            onClick={onEditFormSubmit}
            primary
          />
        )}
      />
      <div className={styles.modalContent}>
        {firstName && (
          <p>{`${translate('HEY')} ${firstName}!`}</p>
        )}
        <p className={styles.updateText}>{translate('WOULD_YOU_LIKE_TO_SEND_AN_UDPATE_NOTIFICATION_TO_THE_TEAM')}</p>
        <Checkbox label={translate('INFORM_TEAM')} disabled={!toggleInformTeam} toggle checked={informTeamParams[INFORM_TEAM]} onClick={toggleInformTeam} />
        <Checkbox label={translate('TEAM_ACCEPTANCE_NECESSARY')} toggle checked={informTeamParams[RESET_ATTENDANCE]} onClick={toggleResetAttendance} />
      </div>
    </Modal>
  );
};

export default InformTeamModal;
