import NumberFormat from 'react-number-format';
import React, { FC } from 'react';
import {
  Icon, Checkbox, Dropdown, Button,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import EditRoleModal from '../EditRoleModal';
import ProfileAvatar from '../../../PlayerProfile/components/ProfileAvatar';
import Row from '../../../../components/Table/components/Row';
import Text from '../../../../components/Text';
import { history } from '../../../../configureStore';
import styles from './PlayerRow.module.scss';
import { Footedness } from '../../../../models/Player';
import { PLAYER_PROFILE_ROUTE } from '../../../../constants/routes';
import { PlayerRowProps } from './PlayerRowProps';
import { Roles } from '../../../../constants/roles';
import { formatDate } from '../../../../utils/dateUtils';

const PlayerRow: FC<PlayerRowProps> = ({
  player: {
    id, personId, firstName, lastName, preferredPosition,
    footedness, height, dayOfBirth, userExists,
    teamNumber, number, email,
  },
  player,
  activeTeam,
  isSelected,
  onSelect,
  resendInvitation,
  clubId,
}) => {
  const [translate] = useTranslation();
  const handleCheckboxChange = (e: React.FormEvent<HTMLInputElement>) => {
    e.stopPropagation();
    onSelect(id);
  };

  const handleReinvitePlayerClick = () => {
    const invitationPersonalData = { firstName, lastName };
    const createInvitation = {
      receiverEmail: email,
      receiverRole: Roles.PLAYER,
      playerId: id,
      ...(clubId && { clubId }),
      ...(activeTeam && { teamId: activeTeam }),
    };

    resendInvitation(createInvitation, invitationPersonalData, false);
  };

  const handleRowClick = () => {
    history.push(PLAYER_PROFILE_ROUTE
      .replace(':teamId', String(activeTeam))
      .replace(':playerId', String(id)));
  };

  return (
    <Row key={id} classNames={styles.wrapper} itemOnClick={handleRowClick}>
      <div className={styles.teamMember}>
        <Checkbox onChange={handleCheckboxChange} checked={isSelected} disabled={!personId} />
        <ProfileAvatar
          user={player}
          size="small"
        />
        <Text bold>
          {`${firstName} ${lastName}`}
        </Text>
      </div>
      <Text>{translate(preferredPosition as string)}</Text>
      <Text>{teamNumber ? `#${teamNumber}` : ''}</Text>
      <Text>{number ? `#${number}` : ''}</Text>
      <NumberFormat format="#.##" value={height} displayType="text" className={styles.rowData} />
      <Text>{footedness ? translate(Footedness[footedness]) : ''}</Text>
      <Text>{formatDate(dayOfBirth) || ''}</Text>
      <div className={styles['options-wrapper']} onClick={(e: any) => { e.stopPropagation(); }}>
        <Dropdown icon={<Icon name="ellipsis vertical" color="blue" size="large" />}>
          <Dropdown.Menu direction="left">
            <Dropdown.Item
              text={translate('VIEW_PROFILE')}
              as={Link}
              to={PLAYER_PROFILE_ROUTE
                .replace(':teamId', String(activeTeam))
                .replace(':playerId', String(id))
              }
            />
            {userExists && !!activeTeam && (
              <EditRoleModal
                member={player}
                teamId={activeTeam}
              />
            )}
            {!userExists && (
              <Dropdown.Item
                text={translate('REINVITE_PLAYER')}
                as={Button}
                onClick={handleReinvitePlayerClick}
              />
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </Row>
  );
};

export default PlayerRow;
