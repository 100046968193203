import React, { useEffect } from 'react';
import { Button } from 'semantic-ui-react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import styles from './MembersList.module.scss';
import { MembersListProps } from './MembersListProps';
import Header from '../../../../components/Header';
import PlayersAndStaffList from '../../../PlayersAndStaffList';
import TeamsFooter from '../../../TeamsTeamLead/components/TeamsFooter';
import {
  CLUB_TEAMS_CREATE_MEMBER_ROUTE,
  CLUB_TEAMS_EDIT_ROUTE,
  CLUB_TEAMS_ROUTE, TEAM_SETTINGS_ROUTE,
} from '../../../../constants/routes';
import { OrderItemType } from '../../../OrderPreparation/OrderPreparationProps';
import params from '../../../../utils/params';
import { UrlParams } from '../../../../constants/urlParams';

const MembersList = ({
  fetchTeams,
  clubId,
  fetchTeamId,
  teamsByHash,
  loading,
}: MembersListProps) => {
  const [translate] = useTranslation();
  const teamId = get(useParams(), 'teamId') as string;
  const teamName = get(teamsByHash[teamId], 'name', '');
  const history = useHistory();
  const { getParams } = params(history);
  const {
    [UrlParams.TEAM_ROLE_FILTER]: roleFilter,
  } = getParams();

  useEffect(() => {
    if (!teamId && clubId) {
      fetchTeamId(clubId);
    }
  }, [clubId, fetchTeamId, teamId]);

  useEffect(() => {
    if (clubId) {
      fetchTeams();
    }
  }, [fetchTeams, clubId]);

  const renderNewElementLogic = () => (
    [
      <Button
        primary
        basic
        as={Link}
        to={CLUB_TEAMS_EDIT_ROUTE.replace(':teamId', teamId)}
        content={translate('EDIT_TEAM')}
        key="edit"
      />,
      <Button
        primary
        basic
        as={Link}
        to={TEAM_SETTINGS_ROUTE.replace(':teamId', teamId)}
        content={translate('TEAM_SETTINGS')}
        key="settings"
      />,
      <Button
        as={Link}
        to={CLUB_TEAMS_CREATE_MEMBER_ROUTE.replace(':teamId', String(teamId))}
        primary
        content={translate('ADD_TEAM_MEMBER')}
        key="button"
      />,
    ]
  );

  return (
    <div className={styles.wrapper}>
      <div className={[styles.topGroup, styles.fixHeight].join(' ')}>
        <Header
          backLinkTo={CLUB_TEAMS_ROUTE}
          backLabel="BACK_TO_TEAMS"
          title={teamName}
          loading={loading}
          renderButton={renderNewElementLogic}
        />
        <div className={styles.contentWrapper}>
          <PlayersAndStaffList teamId={+teamId} />
        </div>
      </div>
      <TeamsFooter
        activeTeam={+teamId}
        showAssignModal
        roleFilter={roleFilter as OrderItemType}
      />
    </div>
  );
};

export default MembersList;
