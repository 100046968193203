import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import LogoSection from './LogoSection';
import { State } from '../../../../models/State';
import { uploadClubLogoRequest } from '../../../../actions/clubs';

const mapStatesToProps = ({ clubs: { loadingUpload, logoUrl } }: State) => ({
  loadingUpload,
  logoUrl,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  uploadClubLogo: bindActionCreators(uploadClubLogoRequest, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(LogoSection);
