import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { CATALOG_ROUTE } from '../../../../constants/routes';
import { CatalogHeaderProps } from './CatalogMenuProps';

const CatalogMenu = ({ location: { pathname } }: CatalogHeaderProps) => {
  const [translate] = useTranslation();

  return (
    <Menu className="filter" pointing secondary>
      <Menu.Item
        active={pathname === CATALOG_ROUTE}
        as={Link}
        content={translate('PRODUCTS')}
        to={CATALOG_ROUTE}
      />
    </Menu>
  );
};

export default CatalogMenu;
