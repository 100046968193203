import React, { FC } from 'react';

import NotificationMessage from '../NotificationMessage';
import styles from './Notifications.module.scss';
import { NotificationsProps } from './NotificationsProps';

const ErrroMessages: FC<NotificationsProps> = ({ notifications }) => (
  <div className={styles.wrapper}>
    {notifications.map(notification => (
      <NotificationMessage
        notification={notification}
        key={notification.id}
      />
    ))}
  </div>
);

export default ErrroMessages;
