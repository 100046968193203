import React, { useState } from 'react';
import { Form, FormInputProps } from 'semantic-ui-react';
import styles from './InputPassword.module.scss';

const InputPassword = (props: FormInputProps) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisible = (event: MouseEvent) => {
    event.preventDefault();
    setPasswordVisible(!passwordVisible);
  };

  return (
    <Form.Input
      className={styles.inputPassword}
      type={passwordVisible ? 'text' : 'password'}
      icon={{
        link: true,
        name: passwordVisible ? 'eye slash' : 'eye',
        onClick: togglePasswordVisible,
      }}
      {...props}
    />
  );
};

export default InputPassword;
