import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Checkbox, Image, Menu } from 'semantic-ui-react';

import { MenuItem } from './MenuItem';
import { SidebarProps } from './SidebarProps';
import styles from './Sidebar.module.scss';
import AuthenticatedMenuItems from '../AuthenticatedMenuItem';
import { ClubType } from '../../../../models/ClubType';

const isActive = (pathname: string, route: string) => pathname === route;

const Sidebar: FC<SidebarProps> = ({
  location, menuExpanded, setMenuExpanded,
  menuItems, clubLogoUrl, isAuthenticated, user,
}) => {
  const [translate] = useTranslation();
  const isHobbyClub = useMemo(() => (user ? user.clubType === ClubType.HOBBY : false), [user]);
  const [filteredMenuItems, setFilteredMenuItem] = useState(menuItems);
  useEffect(() => {
    if (isHobbyClub) {
      const items = menuItems.filter(
        (menuItem) => !['CLUB_CATALOG', 'ORDERS', 'REWARDS.SECTION'].includes(menuItem.name),
      );
      setFilteredMenuItem(items);
    }
  }, [isHobbyClub, menuItems]);

  const renderItems = ({
    icon, route, name, disabled, external,
  }: MenuItem) => (
    <Menu.Item
      key={name}
      as={external ? 'a' : Link}
      icon={icon}
      disabled={disabled}
      active={isActive(location.pathname, route.split('?')[0])}
      name={translate(name)}
      to={!disabled ? route : ''}
      href={external ? route : null}
      target={external ? '_blank' : null}
      className="nts"
    />
  );

  return (
    <Menu vertical key="menu1" className={menuExpanded ? '' : `collapsed ${styles.menuCollapsed}`} fixed="left">
      <div className={styles.menuWrapper}>
        <Menu.Item as={Link} to="/" fitted header>
          {clubLogoUrl && (
            <div className={styles.octagonContainer}>
              <Image src={clubLogoUrl} className={styles.logo} />
            </div>
          )}
        </Menu.Item>

        <div className={styles.menuItems}>
          {filteredMenuItems.map(renderItems)}
        </div>

        <div>
          {isAuthenticated && <AuthenticatedMenuItems menuExpanded={menuExpanded} />}
          <Menu.Item className="bordered toggle" onClick={() => setMenuExpanded(!menuExpanded)}>
            <Checkbox toggle fitted checked={menuExpanded} data-lpignore="true" />
            {translate('HIDE_MENU')}
          </Menu.Item>
        </div>
      </div>
    </Menu>
  );
};

export { Sidebar };
