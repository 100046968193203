import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Menu } from 'semantic-ui-react';

import { SETTINGS_EDIT_ROUTE, ADD_NEW_MEMBER_ROUTE } from '../../constants/routes';
import HeaderComponent from '../../components/Header';
import ClubInfo from './components/ClubInfo';
import MembersList from './components/MembersList';
import styles from './Settings.module.scss';
import { PLAYERS_LIST_LIMIT } from '../../constants/customizations';
import InvitationsList from './components/InvitationsList';
import params from '../../utils/params';
import { history } from '../../configureStore';
import useFilters from '../../hooks/useFilters';

enum Tabs {
  CLUB_INFO = 'CLUB_INFO',
  CLUB_MEMBERS = 'CLUB_MEMBERS',
  CLUB_PERSONAL_INVITATIONS = 'CLUB_PERSONAL_INVITATIONS',
  CLUB_TEAM_INVITATIONS = 'CLUB_TEAM_INVITATIONS',
}

const Settings = () => {
  const [translate] = useTranslation();
  const { clearParams } = params(history);
  const { onFilterChange } = useFilters(history);
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.CLUB_INFO);
  const label = activeTab === Tabs.CLUB_INFO ? 'EDIT' : 'ADD_NEW_MEMBER';
  const to = activeTab === Tabs.CLUB_INFO ? SETTINGS_EDIT_ROUTE : ADD_NEW_MEMBER_ROUTE;

  const handleTabChange = (tab: Tabs) => {
    setActiveTab(tab);
    clearParams();
    onFilterChange({ status: ['ACTIVE'] });
  };

  const renderButton = () => {
    if (
      activeTab === Tabs.CLUB_INFO
      || activeTab === Tabs.CLUB_MEMBERS
    ) {
      return <Button primary as={Link} to={to}>{translate(label)}</Button>;
    }
    return null;
  };
  return (
    <>
      <HeaderComponent title={translate('CLUB_MANAGEMENT')} renderButton={renderButton} />
      <div className={styles.wrapper}>
        <Menu className="filter" pointing secondary>
          <Menu.Item
            name={translate('CLUB_INFO')}
            key="club-info"
            active={activeTab === Tabs.CLUB_INFO}
            onClick={() => { handleTabChange(Tabs.CLUB_INFO); }}
          />
          <Menu.Item
            name={translate('CLUB_MEMBERS')}
            key="club-members"
            active={activeTab === Tabs.CLUB_MEMBERS}
            onClick={() => { handleTabChange(Tabs.CLUB_MEMBERS); }}
          />
          <Menu.Item
            name={translate('PERSONAL_INVITATIONS')}
            key="club-personal-invitations"
            active={activeTab === Tabs.CLUB_PERSONAL_INVITATIONS}
            onClick={() => { handleTabChange(Tabs.CLUB_PERSONAL_INVITATIONS); }}
          />
          <Menu.Item
            name={translate('TEAM_INVITATIONS')}
            key="club-team-invitations"
            active={activeTab === Tabs.CLUB_TEAM_INVITATIONS}
            onClick={() => { handleTabChange(Tabs.CLUB_TEAM_INVITATIONS); }}
          />
        </Menu>
        {
          activeTab === Tabs.CLUB_INFO && <ClubInfo />
        }
        {
          activeTab === Tabs.CLUB_MEMBERS && <MembersList limit={PLAYERS_LIST_LIMIT} />
        }
        {
          activeTab === Tabs.CLUB_PERSONAL_INVITATIONS
          && <InvitationsList isPersonalInvitation />
        }
        {
          activeTab === Tabs.CLUB_TEAM_INVITATIONS
          && <InvitationsList isPersonalInvitation={false} />
        }
      </div>
    </>
  );
};

export default Settings;
