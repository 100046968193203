import React from 'react';
import get from 'lodash/get';

import Labels from '../components/Labels';
import { Player } from '../../../models/Player';

export const getSizeStatsSection = ({ sizes, height, shoePreferences }: Player) => [
  { name: 'SIZES.SHIRT', value: get(sizes, 'SHIRT') },
  { name: 'SIZES.SHORT', value: get(sizes, 'SHORT') },
  { name: 'SIZES.SHOE_AND_SOCK_SIZE', value: get(sizes, 'SHOE') },
  { name: 'SIZES.HEIGHT', value: height },
  {
    name: 'SIZES.SHOES_PREFERENCES',
    value: (<Labels values={shoePreferences || []} />),
    wideColumn: true,
  },
];
