import React, { FC } from 'react';
import { Icon, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import ModalActions from '../../../../components/ModalActions';
import styles from './NewEventModal.module.scss';
import { EventType } from '../../../../constants/eventTypes';
import { NewEventModalProps, EventCard } from './NewEventModalProps';

const NewEventModal: FC<NewEventModalProps> = ({
  activeEventType, setActiveEventType, setLandingModal,
}) => {
  const [translate] = useTranslation();
  const options: EventCard[] = [{
    icon: 'futbol outline',
    eventType: EventType.TRAINING,
  }, {
    icon: 'calendar check outline',
    eventType: EventType.MATCH,
  }, {
    icon: 'calendar outline',
    eventType: EventType.ONE_TIME_EVENT,
  }];

  const setActiveEvent = (eventType?: EventType) => () => {
    setActiveEventType(eventType || null);
  };

  const handleSubmitClick = () => {
    setLandingModal(false);
  };

  const onClose = () => {
    setLandingModal(false);
    setActiveEventType(null);
  };

  return (
    <Modal
      open
      onClose={onClose}
      closeIcon
      style={{ width: '730px', padding: '30px' }}
    >
      <h2 className={styles.header}>
        {translate('CHOOSE_EVENT_TYPE')}
      </h2>
      <div className={styles.content}>
        {options.map(({ icon, eventType }) => (
          <div
            key={icon}
            onClick={setActiveEvent(eventType)}
            className={[styles['option-row'], eventType === activeEventType ? styles.active : ''].join(' ')}
          >
            <Icon name={icon} size="huge" className={styles['option-row-image']} />
            <span className={styles.eventType}>{translate(eventType)}</span>
          </div>
        ))}
      </div>
      <ModalActions
        disabled={!activeEventType}
        onCancel={onClose}
        onSave={handleSubmitClick}
        textSave={translate('CREATE_EVENT')}
      />
    </Modal>
  );
};

export default NewEventModal;
