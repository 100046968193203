import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import {
  Button, Form, Header, Message,
} from 'semantic-ui-react';

import AuthenticationFormsWrapper from '../../components/AuthenticationFormsWrapper/AuthenticationFormsWrapper';
import FormFields from '../../components/FormFields';
import styles from './SignIn.module.scss';
import useFocusFirstInput from '../../hooks/useFocusFirstInput';
import useForm from '../../hooks/useForm';
import { REQUIRED_ROLES } from '../../constants/customizations';
import {
  DATA_PRIVACY_ROUTE, IMPRINT_ROUTE, RESET_PASSWORD, TERMS_ROUTE,
} from '../../constants/routes';
import { SignInProps } from './SignInProps';
import { signInSchema, validationSchema } from './schema';
import { Roles } from '../../constants/roles';
import { UrlParams } from '../../constants/urlParams';

const SignIn: FC<SignInProps> = ({
  signIn, loading, clubLogoUrl, error,
  clubId,
  teamId,
  userRole,
  location,
}) => {
  const [submitted, setSubmitted] = useState(false);
  const queryParams = queryString.parse(location.search);
  const [translate] = useTranslation();
  const {
    values,
    errors,
    handleChange,
    validate,
  } = useForm(validationSchema);
  const inviteToken = queryParams[UrlParams.INVITATION_TOKEN] as string;

  useFocusFirstInput();

  const handleSubmit = () => {
    if (validate()) {
      const { email, password } = values;
      signIn(email, password, { requiredRoles: REQUIRED_ROLES });
      setSubmitted(true);
    }
  };

  return (
    <AuthenticationFormsWrapper clubLogoUrl={clubLogoUrl}>
      <div />
      <div>
        <Header as="h2" content={translate('LOGIN')} className={styles.headerLogin} />
        <Form onSubmit={handleSubmit} loading={loading} className={styles.formWrapper}>
          <FormFields
            schema={signInSchema}
            errors={errors}
            values={values}
            handleChange={handleChange}
          />

          {submitted && error
            && <Message size="mini" header={translate('INVALID_CREDENTIALS')} negative />}
          {submitted && !error && !loading && clubId && (!teamId || userRole === Roles.CLUB_ADMIN)
            && <Message size="mini" header={translate('SIGN_IN_ERROR_NO_TEAMS_ASSIGNED')} negative />}
          {submitted && !error && !loading && !clubId
            && <Message size="mini" header={translate('SIGN_IN_ERROR_NOT_IN_A_CLUB')} negative />}
          <Button
            primary
            type="submit"
            content={translate('LOGIN')}
            fluid
            className={styles.submit}
          />
          <Link to={RESET_PASSWORD} className={styles.forgotLink}>{translate('PASSWORD_FORGOTTEN')}</Link>
          {!inviteToken && (
            <div className={styles['access-app-message']}>
              {translate('ACCESS_APP_MESSAGE')}
            </div>
          )}
        </Form>
      </div>
      <div className={styles.links}>
        <Link to={DATA_PRIVACY_ROUTE}>{translate('DATA_PRIVACY')}</Link>
        <span>|</span>
        <Link to={TERMS_ROUTE}>{translate('TERMS_AND_CONDITIONS')}</Link>
        <span>|</span>
        <Link to={IMPRINT_ROUTE}>{translate('IMPRINT.PAGE_NAME')}</Link>
      </div>
    </AuthenticationFormsWrapper>
  );
};

export default SignIn;
