import {
  ActionsObservable, ofType,
} from 'redux-observable';
import {
  catchError, mergeMap, map,
} from 'rxjs/operators';
import { ajax, AjaxError } from 'rxjs/ajax';
import { SIGN_UP_WITH_TOKEN_ERROR, SIGN_UP_WITH_TOKEN_REQUEST } from '../../constants/actionTypes';
import { SIGN_UP_WITH_TOKEN } from '../../constants/paths';
import { signUpError, signUpSuccess } from '../../actions/signUp';
import BASE_URL from '../../config';
import { SignUpWithTokenRequestAction } from '../../models/actions';
import { handleError } from '../../actions/errors';
import { SIGN_UP_ERROR_MESSAGES } from '../../containers/SignUp/SignUp';

export default (
  action$: ActionsObservable<SignUpWithTokenRequestAction>,
) => action$.pipe(
  ofType(SIGN_UP_WITH_TOKEN_REQUEST),
  mergeMap(({ payload: { inviteToken, signUpData, receiverRole } }) => ajax({
    url: BASE_URL.API_URL + SIGN_UP_WITH_TOKEN.replace('{inviteToken}', inviteToken),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(signUpData),
  }).pipe(
    map(() => signUpSuccess(signUpData, receiverRole)),
    catchError((error: AjaxError) => [
      handleError(error, error.response.message === SIGN_UP_ERROR_MESSAGES.ALREADY_IN_USE ? 'SIGN_UP_WITH_TOKEN_IN_USE_ERROR' : SIGN_UP_WITH_TOKEN_ERROR),
      signUpError(error.response),
    ]),
  )),
);
