import { EventListData } from '../../models/Calendar';

const serializeEventList = (events: EventListData[]): EventListData[] => events.map(({
  startDateTime, endDateTime, meetingDate, ...event
}) => ({
  ...event,
  startDateTime: new Date(startDateTime),
  endDateTime: new Date(endDateTime),
  meetingDate: meetingDate ? new Date(meetingDate) : null,
}));

export default serializeEventList;
