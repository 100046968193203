import { MenuItem } from '../../components/Layout/components/Sidebar/MenuItem';
import {
  CALENDAR_ROUTE,
  CATALOG_ROUTE,
  CLUB_ORDERS_ROUTE,
  CLUB_TEAMS_ROUTE, DATA_PRIVACY_ROUTE, HOME_ROUTE, IMPRINT_ROUTE,
  SETTINGS_ROUTE, SIGN_IN_ROUTE,
  TEAM_ORDERS_ROUTE,
  TEAM_VIEW_ROUTE, TERMS_ROUTE,
  FAQ_ROUTE,
} from '../../constants/routes';
import { Roles } from '../../constants/roles';

type MenuItemsType = (params: { teamId?: number | null, clubId?: number | null }) => MenuItem[];

export const adminMenuItems: MenuItemsType = ({ teamId }) => [{
  name: 'TEAMS',
  route: CLUB_TEAMS_ROUTE,
  icon: 'users',
}, {
  name: 'CALENDAR',
  route: CALENDAR_ROUTE.replace(':teamId', String(teamId)),
  icon: 'calendar',
  disabled: !teamId,
}, {
  name: 'CLUB_CATALOG',
  route: `${CATALOG_ROUTE}?activeTeam=${teamId}`,
  icon: 'shield alternate',
}, {
  name: 'ORDERS',
  route: CLUB_ORDERS_ROUTE,
  icon: 'shop',
}, {
  name: 'CLUB_MANAGEMENT',
  route: SETTINGS_ROUTE,
  icon: 'cog',
},
{
  name: 'FAQ',
  route: FAQ_ROUTE,
  icon: 'question',
  external: true,
}];

export const teamLeaderMenuItems: MenuItemsType = ({ teamId }) => [{
  name: 'MY_TEAMS',
  route: TEAM_VIEW_ROUTE.replace(':teamId', String(teamId)),
  icon: 'users',
}, {
  name: 'CALENDAR',
  route: CALENDAR_ROUTE.replace(':teamId', String(teamId)),
  icon: 'calendar',
}, {
  name: 'CLUB_CATALOG',
  route: `${CATALOG_ROUTE}?activeTeam=${teamId}`,
  icon: 'shield alternate',
}, {
  name: 'ORDERS',
  route: TEAM_ORDERS_ROUTE.replace(':teamId', String(teamId)),
  icon: 'shop',
}, {
  name: 'FAQ',
  route: FAQ_ROUTE,
  icon: 'question',
  external: true,
}];

export const staticMenuItems = [{
  name: 'DATA_PRIVACY',
  route: DATA_PRIVACY_ROUTE,
  icon: 'privacy',
}, {
  name: 'IMPRINT.PAGE_NAME',
  route: IMPRINT_ROUTE,
  icon: 'address card outline',
}, {
  name: 'TERMS_AND_CONDITIONS',
  route: TERMS_ROUTE,
  icon: 'law',
}];

export const authenticatedMenuStaticItem = (userRole: Roles | null) => ({
  name: userRole === Roles.CLUB_ADMIN ? 'TEAMS' : 'MY_TEAMS',
  route: HOME_ROUTE,
  icon: 'users',
});

export const unAuthenticatedMenuStaticItem = {
  name: 'LOGIN',
  route: SIGN_IN_ROUTE,
  icon: 'users',
};
