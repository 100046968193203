import pickBy from 'lodash/pickBy';
import queryString from 'query-string';
import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import { FETCH_TEAM_ORDERS_ERROR, FETCH_TEAM_ORDERS_REQUEST } from '../../constants/actionTypes';
import { FetchTeamOrdersRequestAction } from '../../models/actions';
import { ListResponse, Response } from '../../models/Response';
import { State } from '../../models/State';
import { TEAM_ORDERS_LIST_LIMIT } from '../../constants/customizations';
import { ORDERS_LIST } from '../../constants/paths';
import { fetchTeamOrdersSuccess, fetchTeamOrdersError } from '../../actions/orders';
import { OrderList } from '../../models/Order';
import { Roles } from '../../constants/roles';
import { handleError } from '../../actions/errors';

const ROLE_CLUB_ADMIN = 'club-admin';
const ROLE_TEAM_LEAD = 'team-lead';

const fetchOrders = (
  action$: ActionsObservable<FetchTeamOrdersRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_TEAM_ORDERS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { page, field, order } },
    {
      authentication: { authToken },
      application: { clubId, userRole },
    },
  ]) => {
    const role = userRole === Roles.CLUB_ADMIN ? ROLE_CLUB_ADMIN : ROLE_TEAM_LEAD;
    const query = queryString.stringify(pickBy({
      offset: ((page || 1) - 1) * TEAM_ORDERS_LIST_LIMIT,
      limit: TEAM_ORDERS_LIST_LIMIT,
    }));

    return ajax({
      url: `${ORDERS_LIST.replace('$role', role)}?${query}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authToken,
      },
      body: JSON.stringify({
        clubId,
        ...((field || order) && { sort: { field, order } }),
      }),
    }).pipe(
      map(({ response: { data, count } }: Response<ListResponse<OrderList>>) => (
        fetchTeamOrdersSuccess(data, count)
      )),
      catchError(error => [
        handleError(error, FETCH_TEAM_ORDERS_ERROR),
        fetchTeamOrdersError(error),
      ]),
    );
  }),
);

export default fetchOrders;
