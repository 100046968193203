import moment, { MomentInput } from 'moment';
import { MEETING_TIME } from '../components/MatchEventModal/MatchEventModalProps';
import { WeekDays } from '../../../constants/weekDays';
import { EN } from '../../../constants/customizations';

export const getDisabledHours = (startTime: Date | null, name: string) => () => (startTime
  ? [...Array(24).keys()]
    .filter(number => (name === MEETING_TIME
      ? number > startTime.getHours()
      : number < startTime.getHours()))
  : []
);

export const getDisabledMinutes = (
  startTime: Date | null, name: string,
) => (hour: number) => (startTime && hour === startTime.getHours()
  ? [...Array(60).keys()]
    .filter(number => (name === MEETING_TIME
      ? number > startTime.getMinutes()
      : number <= startTime.getMinutes()))
  : []
);

export const createMoment = (date?: MomentInput) => moment(date).locale(EN);
export const getUpperCasedDay = (date: MomentInput): WeekDays => createMoment(date).format('dddd').toUpperCase() as WeekDays;
