import React, { FC } from 'react';
import queryString from 'query-string';
import { Button } from 'semantic-ui-react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import params from '../../../../utils/params';
import styles from './RoleFilterTabs.module.scss';
import { RoleFilterTabsProps } from './RoleFilterTabsProps';
import { UrlParams } from '../../../../constants/urlParams';
import { OrderItemType } from '../../../OrderPreparation/OrderPreparationProps';

const RoleFilterTabs: FC<RoleFilterTabsProps> = ({
  roleFilter, playersActions = [], staffActions = [],
}) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [translate] = useTranslation();
  const { getParams } = params(history);
  const currentParams = getParams();

  const getLink = (role: OrderItemType) => (
    `${pathname}?${queryString.stringify({
      ...currentParams,
      [UrlParams.TEAM_ROLE_FILTER]: role,
    })}`
  );

  const actionArray = roleFilter === OrderItemType.PLAYER ? playersActions : staffActions;

  return (
    <div className={styles.actionWrappers}>
      <Button as={Link} basic className="simple" to={getLink(OrderItemType.PLAYER)} content={translate('PLAYERS')} primary={roleFilter === OrderItemType.PLAYER} />
      <Button as={Link} basic className="simple" to={getLink(OrderItemType.STAFF)} content={translate('STAFF')} primary={roleFilter === OrderItemType.STAFF} />

      <div className={styles.alignRight}>
        {actionArray}
      </div>
    </div>
  );
};

export default RoleFilterTabs;
