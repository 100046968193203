import { FormSchema } from '../../../../models/Form';
import Seasons from '../../../../constants/seasons';
import TransferOptions from '../../../../constants/transferOptions';

export const changeTeamSeasonSchema: FormSchema = ({
  season: {
    type: 'select',
    label: 'SEASON',
    placeholder: 'SEASON',
    required: true,
    options: (translate) => Object.keys(Seasons).map((key: string) => ({
      value: key,
      text: translate(key),
      key,
    })),
  },
  playerLineUpTransferOption: {
    type: 'select',
    label: 'PLAYER_LINEUP_TRANSFER_OPTION',
    placeholder: 'PLAYER_LINEUP_TRANSFER_OPTION',
    required: true,
    options: (translate) => Object.keys(TransferOptions).map((key: string) => ({
      value: key,
      text: translate(key),
      key,
    })),
  },
  treasurerTransferOption: {
    type: 'select',
    label: 'TREASURER_LINEUP_TRANSFER_OPTION',
    placeholder: 'TREASURER_LINEUP_TRANSFER_OPTION',
    required: true,
    options: (translate) => Object.keys(TransferOptions).map((key: string) => ({
      value: key,
      text: translate(key),
      key,
    })),
  },
  teamLeadTransferOption: {
    type: 'select',
    label: 'TEAM_LEAD_LINEUP_TRANSFER_OPTION',
    placeholder: 'TEAM_LEAD_LINEUP_TRANSFER_OPTION',
    required: true,
    options: (translate) => Object.keys(TransferOptions)
      .filter(val => val !== TransferOptions.MOVE)
      .map((key: string) => ({
        value: key,
        text: translate(key),
        key,
      })),
  },
});
