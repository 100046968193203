import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';

import { CREATE_EVENT_REQUEST } from '../../constants/actionTypes';
import { CreateEventRequestAction, NotificationType } from '../../models/actions';
import { State } from '../../models/State';
import { EVENTS } from '../../constants/paths';
import { Response } from '../../models/Response';
import { createEventError, createEventSuccess } from '../../actions/calendar';
import { EventListData } from '../../models/Calendar';
import { handleError } from '../../actions/errors';
import { addNotification } from '../../actions/notifications';
import serializeEventList from '../../utils/serializers/serializeEventList';

const createEvent = (
  action$: ActionsObservable<CreateEventRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(CREATE_EVENT_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { event } },
    {
      authentication: { authToken },
    },
  ]) => ajax({
    url: EVENTS,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify(event),
  }).pipe(
    mergeMap(({ response: events }: Response<EventListData[]>) => [
      addNotification(NotificationType.SUCCESS, `CREATE_${event.type}_SUCCESS`),
      createEventSuccess(serializeEventList(events)),
    ]),
    catchError(error => [
      handleError(error, `CREATE_${event.type}_ERROR`),
      createEventError(error),
    ]),
  )),
);

export default createEvent;
