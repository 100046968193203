import {
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR,
} from '../constants/actionTypes';

export const signIn = (username, password, params = {}) => ({
  type: SIGN_IN_REQUEST,
  payload: {
    username,
    password,
    params,
  },
});

export const signInSuccess = (signInResponse, params = {}) => ({
  type: SIGN_IN_SUCCESS,
  payload: {
    signInResponse,
    params,
  },
});

export const signInError = error => ({
  type: SIGN_IN_ERROR,
  payload: {
    error,
  },
});
