import { AjaxError } from 'rxjs/ajax';
import {
  NumberSettings, Order, OrderList, UsedNumbers,
} from '../models/Order';
import { OrderStatus } from '../constants/orderStatus';
import {
  DELETE_ORDERS_ERROR,
  DELETE_ORDERS_SUCCESS,
  DELETE_ORDERS_REQUEST,
  FETCH_TEAM_ORDERS_REQUEST,
  FETCH_TEAM_ORDERS_SUCCESS,
  FETCH_TEAM_ORDERS_ERROR,
  UPSERT_ORDER_REQUEST,
  UPSERT_ORDER_SUCCESS,
  UPSERT_ORDER_ERROR,
  CHANGE_ORDER_STATUS_REQUEST,
  CHANGE_ORDER_STATUS_SUCCESS,
  CHANGE_ORDER_STATUS_ERROR,
  SET_STATUS,
  FETCH_ORDER_REQUEST,
  FETCH_ORDER_SUCCESS,
  FETCH_ORDER_ERROR,
  LOCK_NUMBERS,
  FETCH_NUMBERS_SETTINGS_REQUEST,
  FETCH_NUMBERS_SETTINGS_SUCCESS,
  FETCH_NUMBERS_SETTINGS_ERROR,
} from '../constants/actionTypes';
import {
  DeleteOrdersRequestAction,
  DeleteOrdersSuccessAction,
  DeleteOrdersErrorAction,
  FetchTeamOrdersRequestAction,
  FetchTeamOrdersSuccessAction,
  FetchTeamOrdersErrorAction,
  UpsertOrderRequestAction,
  UpsertOrderSuccessAction,
  UpsertOrderErrorAction,
  ChangeOrderStatusRequestAction,
  ChangeOrderStatusSuccessAction,
  ChangeOrderStatusErrorAction,
  SetStatusAction,
  FetchOrderRequestAction,
  FetchOrderSuccessAction,
  FetchOrderErrorAction,
  LockNumbersAction,
  FetchNumbersSettingsRequestAction,
  FetchNumbersSettingsSuccessAction,
  FetchNumbersSettingsErrorAction,
} from '../models/actions';

const fetchOrdersDefaultPayload = {
  page: 1,
};

export const fetchTeamOrdersRequest = (
  payload = fetchOrdersDefaultPayload,
): FetchTeamOrdersRequestAction => ({
  type: FETCH_TEAM_ORDERS_REQUEST,
  payload,
});

export const fetchTeamOrdersSuccess = (
  orders: OrderList[], count: number,
): FetchTeamOrdersSuccessAction => ({
  type: FETCH_TEAM_ORDERS_SUCCESS,
  payload: {
    orders,
    count,
  },
});

export const fetchTeamOrdersError = (error: AjaxError): FetchTeamOrdersErrorAction => ({
  type: FETCH_TEAM_ORDERS_ERROR,
  payload: {
    error,
  },
});

export const fetchOrderRequest = (id: number): FetchOrderRequestAction => ({
  type: FETCH_ORDER_REQUEST,
  payload: {
    id,
  },
});

export const fetchOrderSuccess = (order: Order): FetchOrderSuccessAction => ({
  type: FETCH_ORDER_SUCCESS,
  payload: {
    order,
  },
});

export const fetchOrderError = (error: AjaxError): FetchOrderErrorAction => ({
  type: FETCH_ORDER_ERROR,
  payload: {
    error,
  },
});

export const upsertOrderRequest = (
  teamId: number, successRedirectCb?: (orderId?: number) => string,
): UpsertOrderRequestAction => ({
  type: UPSERT_ORDER_REQUEST,
  payload: {
    teamId,
    successRedirectCb,
  },
});

export const upsertOrderSuccess = (order: Order): UpsertOrderSuccessAction => ({
  type: UPSERT_ORDER_SUCCESS,
  payload: {
    order,
  },
});

export const upsertOrderError = (error: AjaxError): UpsertOrderErrorAction => ({
  type: UPSERT_ORDER_ERROR,
  payload: {
    error,
  },
});

export const deleteOrdersRequest = (orderIds: number[]): DeleteOrdersRequestAction => ({
  type: DELETE_ORDERS_REQUEST,
  payload: {
    orderIds,
  },
});

export const deleteOrdersSuccess = (
  orderIds: number[],
): DeleteOrdersSuccessAction => ({
  type: DELETE_ORDERS_SUCCESS,
  payload: {
    orderIds,
  },
});

export const deleteOrdersError = (error: AjaxError): DeleteOrdersErrorAction => ({
  type: DELETE_ORDERS_ERROR,
  payload: {
    error,
  },
});

export const changeOrderStatusRequest = (
  teamId: number, status: OrderStatus,
): ChangeOrderStatusRequestAction => ({
  type: CHANGE_ORDER_STATUS_REQUEST,
  payload: {
    status,
    teamId,
  },
});

export const changeOrderStatusSuccess = (
  status: OrderStatus, orderId: number,
): ChangeOrderStatusSuccessAction => ({
  type: CHANGE_ORDER_STATUS_SUCCESS,
  payload: {
    status,
    orderId,
  },
});

export const changeOrderStatusError = (error: AjaxError): ChangeOrderStatusErrorAction => ({
  type: CHANGE_ORDER_STATUS_ERROR,
  payload: {
    error,
  },
});

export const setStatus = (
  id: number, status: OrderStatus,
): SetStatusAction => ({
  type: SET_STATUS,
  payload: {
    id,
    status,
  },
});

export const lockNumbers = (numbers: number[], usedNumbers?: UsedNumbers): LockNumbersAction => ({
  type: LOCK_NUMBERS,
  payload: {
    numbers,
    usedNumbers,
  },
});

export const fetchNumbersSettingsRequest = (
  orderId: number,
): FetchNumbersSettingsRequestAction => ({
  type: FETCH_NUMBERS_SETTINGS_REQUEST,
  payload: {
    orderId,
  },
});

export const fetchNumbersSettingsSuccess = (
  payload: NumberSettings,
): FetchNumbersSettingsSuccessAction => ({
  type: FETCH_NUMBERS_SETTINGS_SUCCESS,
  payload,
});

export const fetchNumbersSettingsError = (error: AjaxError): FetchNumbersSettingsErrorAction => ({
  type: FETCH_NUMBERS_SETTINGS_ERROR,
  payload: {
    error,
  },
});
