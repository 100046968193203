import React, { FC } from 'react';
import classNames from 'classnames';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import styles from './ModalActions.module.scss';
import { ModalActionsProps } from './ModalActionsProps';

const ModalActions: FC<ModalActionsProps> = ({
  disabled,
  loading,
  onCancel,
  onSave,
  padded,
  textCancel,
  textSave,
}) => {
  const [translate] = useTranslation();

  return (
    <div className={classNames(styles.container, padded && styles.padded)}>
      <Button
        basic
        circular
        content={textCancel || translate('CANCEL')}
        fluid
        onClick={onCancel}
        primary
      />
      <Button
        circular
        content={textSave || translate('SAVE')}
        disabled={disabled}
        fluid
        loading={loading}
        onClick={onSave}
        primary
      />
    </div>
  );
};

export default ModalActions;
