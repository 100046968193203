import { Column } from '../../../../../../components/Table/Column';

const columns: { [key: string]: Column } = {
  PLAYERS: {
    key: 'name',
    align: 'left',
    width: '600px',
    sortable: true,
  },
  PRODUCT: {
    key: 'PRODUCT',
    align: 'left',
    width: '600px',
  },
  VK: {
    key: 'vk',
    header: 'VK',
    align: 'left',
    width: '200px',
  },
  QUANTITY: {
    width: '200px',
    header: 'QUANTITY',
    align: 'left',
    key: 'quantity',
  },
  MAX_QUANTITY: {
    width: '200px',
    header: 'MAX_QUANTITY',
    align: 'left',
    key: 'max_quantity',
  },
  SIZE: {
    width: '200px',
    header: 'SIZE',
    align: 'left',
    key: 'size',
  },
  NUMBER: {
    width: '300px',
    header: 'NUMBER',
    align: 'left',
    key: 'number',
    sortable: true,
  },
  BACK_LABEL: {
    width: '300px',
    header: 'BACK_LABEL',
    align: 'left',
    key: 'back_label',
  },
  INITIALS: {
    width: '200px',
    header: 'INITIALS',
    align: 'left',
    key: 'initials',
  },
};

export default columns;
