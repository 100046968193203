import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import RequestTokenForm from './RequestTokenForm';
import { resetPasswordTokenRequest } from '../../../../packages/authentication';
import { State } from '../../../../models/State';

const mapStateToProps = ({ authentication }: State) => ({
  emailSent: authentication.resetPasswordEmailSent,
  errorMessage: authentication.resetPasswordErrorMessage,
  loading: authentication.resetPasswordLoading,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  resetPasswordTokenRequest,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RequestTokenForm);
