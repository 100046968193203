export const SIGN_IN_ROUTE = '/sign-in';
export const SIGN_UP_ROUTE = '/sign-up';
export const SIGN_UP_SUPERVISED = '/sign-up-supervised';
export const HOME_ROUTE = '/';
export const RESET_PASSWORD = '/password-reset';
export const HOBBY_TEAM_ROUTE = '/hobby-team';
export const NAVIGATION_ROUTE = '/navigation';

export const CLUB_ROUTE = '/club';
export const CLUB_TEAMS_ROUTE = `${CLUB_ROUTE}/teams`;
export const CLUB_TEAMS_MEMBERS_ROUTE = `${CLUB_TEAMS_ROUTE}/:teamId/members`;
export const CLUB_TEAMS_EDIT_ROUTE = `${CLUB_TEAMS_ROUTE}/:teamId/edit`;
export const CLUB_TEAMS_CREATE_MEMBER_ROUTE = `${CLUB_TEAMS_MEMBERS_ROUTE}/new`;
export const NEW_CLUB_TEAM_ROUTE = `${CLUB_TEAMS_ROUTE}/new`;
export const CLUB_ORDERS_ROUTE = `${CLUB_ROUTE}/orders/club`;
export const CLUB_INDIVIDUAL_ORDERS_ROUTE = `${CLUB_ROUTE}/orders/individual`;
export const CLUB_TEAM_BASKET_ORDERS_ROUTE = `${CLUB_ROUTE}/orders/team-basket`;

export const CATALOG_ROUTE = `${CLUB_ROUTE}/catalog`;
export const CATALOG_PRODUCT_ROUTE = `${CATALOG_ROUTE}/product/:id`;

export const TEAM_ROUTE = '/teams';
export const TEAM_VIEW_ROUTE = `${TEAM_ROUTE}/:teamId`;
export const TEAM_EDIT_ROUTE = `${TEAM_ROUTE}/:teamId/edit`;
export const TEAM_SETTINGS_ROUTE = `${TEAM_ROUTE}/:teamId/settings`;
export const TEAM_ORDERS_ROUTE = `${TEAM_VIEW_ROUTE}/orders`;
export const ORDER_ROUTE = `${TEAM_VIEW_ROUTE}/order/:orderId`;
export const ORDER_PREPARATION_ROUTE = `${TEAM_VIEW_ROUTE}/order`;
export const TEAM_PLAYERS_ROUTE = `${TEAM_VIEW_ROUTE}/players`;
export const TEAM_MEMBERS_ROUTE = `${TEAM_VIEW_ROUTE}/members`;
export const CALENDAR_ROUTE = `${TEAM_VIEW_ROUTE}/calendar`;
export const NEW_MEMBER_ROUTE = `${TEAM_MEMBERS_ROUTE}/new`;
export const EDIT_PLAYER_ROUTE = `${TEAM_PLAYERS_ROUTE}/edit/:playerId`;
export const PLAYER_PROFILE_ROUTE = `${TEAM_PLAYERS_ROUTE}/player/:playerId`;

export const PROFILE_ROUTE = '/profile';
export const PROFILE_EDIT_ROUTE = '/profile/edit';
export const SETTINGS_ROUTE = '/settings';
export const SETTINGS_EDIT_ROUTE = '/settings/edit';
export const ADD_NEW_MEMBER_ROUTE = '/settings/members/new';
export const MEMBER_ROUTE = '/settings/members/:memberId';

export const TERMS_ROUTE = '/terms';
export const SIGNUP_WELCOME_PAGE_ROUTE = '/welcome';

export const DELETE_ACCOUNT_INSTRUCTION_ROUTE = '/gdpr/delete-account-instruction';
export const DELETE_DATA_ROUTE = '/gdpr/remove-data';
export const DELETE_DATA_CONFIRMATION_PAGE = '/gdpr/remove-data-confirmation';

export const IMPRINT_ROUTE = '/imprint';
export const DATA_PRIVACY_ROUTE = '/data-privacy';
export const USER_CONSENT_ROUTE = '/user-consent';
export const JOIN_TEAM_AND_CLUB_REJECTED_ROUTE = '/join-team-and-club-rejected';
export const JOIN_OFFICIAL_CLUB_REJECTED_ROUTE = '/join-official-club-rejected';
export const JOIN_TEAM_AND_CLUB_SIGNUP_APPROVED_ROUTE = '/join-team-and-club-approved';
export const JOIN_OFFICIAL_CLUB_SIGNUP_APPROVED_ROUTE = '/join-official-club-approved';
export const SELF_SIGNUP_GENERAL_ROUTE = '/server-error';
export const ADIDAS_RUNNING_OAUTH_SUCCESS_ROUTE = '/adidas-running/oauth/success';

export const FAQ_ROUTE = 'https://www.11teamsports.com/de-de/house-of-clubs/app/faq/';
