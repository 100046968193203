import moment from 'moment';
import {
  Match, OneTimeEvent, Training,
} from '../../../models/Calendar';
import {
  DESCRIPTION,
  END_TIME,
  OneTimeEventFormSchema,
  MEETING_TIME,
  START_TIME,
  NAME,
  ADDRESS,
  InformTeamParams,
} from '../components/OneTimeEventModal/OneTimeEventModalProps';
import { TIME_FORMAT } from '../../../utils/dateUtils';
import { EventType } from '../../../constants/eventTypes';
import useEventDateTime from '../hooks/useEventDateTime';
import { getUpperCasedDay } from './TimePickerUtil';

export default (
  translate: (key: string) => string,
  formState: OneTimeEventFormSchema,
  startDate: Date | undefined,
  endDate: Date | undefined,
  teamId: number,
  event: Match | Training | OneTimeEvent | null,
  informTeamParams?: InformTeamParams,
): OneTimeEvent => {
  const [startDateTime, endDateTime] = useEventDateTime(formState, startDate);

  return {
    name: formState[NAME],
    description: formState[DESCRIPTION],
    type: EventType.ONE_TIME_EVENT,
    ...(!event && ({
      interval: {
        type: 'WEEK',
        length: 1,
        startDate: moment(startDate!).startOf('day').valueOf(),
        startDay: getUpperCasedDay(startDate!),
        endDate: moment(startDate!).endOf('day').valueOf(),
        startTime: moment(formState[START_TIME]!).format(TIME_FORMAT),
        endTime: moment(formState[END_TIME]!).format(TIME_FORMAT),
      },
    })),
    location: {
      displayName: formState[ADDRESS],
    },
    oneTimeEvent: {
      ...(!event && ({ teamId })),
      ...(formState[MEETING_TIME]
        && ({ meetingTime: moment(formState[MEETING_TIME]!).format(TIME_FORMAT) })),
    },
    ...(!event && ({
      interval: {
        type: 'WEEK',
        length: 0,
        startDate: moment(startDate!).startOf('day').valueOf(),
        startDay: getUpperCasedDay(startDate!),
        endDate: moment(endDate!).endOf('day').valueOf(),
        startTime: moment(formState[START_TIME]!).format(TIME_FORMAT),
        endTime: moment(formState[END_TIME]!).format(TIME_FORMAT),
      },
    })),
    ...(event && ({ id: event.id })),
    ...(event && ({ startDateTime })),
    ...(event && ({ endDateTime })),
    ...(informTeamParams && (informTeamParams)),
  };
};
