import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import MobileNavigation from './MobileNavigation';
import State from '../../models';
import { fetchAuthUserRequest, resetUserData } from '../../actions/application';
import { setAuthToken } from '../../packages/authentication';

const mapStateToProps = ({
  application: {
    userDataFetched, userRole,
  },
  authentication: {
    authToken,
  },
}: State) => ({
  userDataFetched,
  userRole: !!userRole,
  isAuthenticated: !!authToken,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAuthUser: bindActionCreators(fetchAuthUserRequest, dispatch),
  setAuthToken: bindActionCreators(setAuthToken, dispatch),
  resetUserData: bindActionCreators(resetUserData, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileNavigation);
