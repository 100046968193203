import debounce from 'lodash/debounce';
import { PercentCrop } from 'react-image-crop';

const cropImage = debounce((
  imageRef: HTMLImageElement,
  crop: PercentCrop,
  callback: (blob: Blob | null) => void,
) => {
  if (imageRef && crop.width && crop.height) {
    const canvas = document.createElement('canvas');
    canvas.width = imageRef.naturalWidth * (crop.width / 100);
    canvas.height = imageRef.naturalHeight * (crop.height / 100);
    const ctx = canvas.getContext('2d');
    const sx = ((crop.x || 0) / 100) * imageRef.naturalWidth;
    const sy = ((crop.y || 0) / 100) * imageRef.naturalHeight;
    const sw = (crop.width / 100) * imageRef.naturalWidth;
    const sh = (crop.height / 100) * imageRef.naturalHeight;
    if (ctx) {
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(imageRef, sx, sy, sw, sh, 0, 0, canvas.width, canvas.height);
    }
    canvas.toBlob(callback, 'image/jpeg', 1);
  }
}, 100);

export default cropImage;
