import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash/get';

import PlayerEdit from './PlayerEdit';
import { PlayerEditProps } from './PlayerEditProps';
import { State } from '../../models/State';
import { fetchPlayerRequest, updatePlayerRequest } from '../../actions/players';
import { fetchTeamRequest } from '../../actions/teams';

const mapStatesToProps = (
  state: State,
  { match: { params: { playerId } } }: PlayerEditProps,
) => {
  const {
    application: { clubId, teamId },
    players: {
      error, loading: loadingPlayer, byHash: playerByHash,
    },
    teams: { byHash: teamsByHash, loading: loadingTeam },
  } = state;

  return ({
    clubId,
    error,
    loading: loadingPlayer || loadingTeam,
    player: playerByHash[playerId],
    lockedNumbers: teamId ? get(teamsByHash[teamId], 'lockedNumbers', []) : [],
    usedNumbers: teamId ? get(teamsByHash[teamId], 'usedNumbers', []) : [],
  });
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updatePlayer: bindActionCreators(updatePlayerRequest, dispatch),
  fetchPlayer: bindActionCreators(fetchPlayerRequest, dispatch),
  fetchTeam: bindActionCreators(fetchTeamRequest, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(PlayerEdit);
