import React from 'react';
import { useTranslation } from 'react-i18next';

import MobileItem from '../MobileItem';
import { EmbellishmentTypes } from '../../../../../../models/Embellishment';
import { MobileItemsListProps } from './MobileItemsListProps';
import { Member } from '../../../../../../models/Member';
import { Product } from '../../../../../../models/Catalog';
import styles from './MobileItemsList.module.scss';
import { OrderItemType } from '../../../../OrderPreparationProps';

const MobileItemsList = ({
  changeProperty,
  itemType,
  itemsByHash,
  itemsById,
  playersByHash,
  productsByHash,
  staffByHash,
  staffById,
}: MobileItemsListProps) => {
  const [translate] = useTranslation();

  const embellishmentsColumns: EmbellishmentTypes[] = itemsById.reduce(
    (embellishments: EmbellishmentTypes[], itemId) => ([
      ...embellishments,
      ...itemsByHash[itemId].embellishments
        .map(({ type }) => type)
        .filter(type => !embellishments.includes(type)),
    ]), [],
  );

  const getProduct = (id: string) => {
    const { productCatalogId } = itemsByHash[id];
    return productCatalogId ? productsByHash[productCatalogId] : {} as Product;
  };

  const getMember = (id: string) => {
    const { playerId, staffId } = itemsByHash[id];
    const player = playerId && playersByHash[playerId];
    const staff = staffById
      .map(itemId => staffByHash[itemId])
      .find(({ userId }) => userId === staffId);
    return player || staff || {};
  };

  const renderItems = () => (
    itemsById.filter(id => itemsByHash[id]).map((id: string) => (
      <MobileItem
        changeProperty={changeProperty}
        embellishmentsColumns={embellishmentsColumns}
        item={itemsByHash[id]}
        itemType={itemType}
        key={id}
        member={getMember(id) as Member}
        product={getProduct(id)}
      />
    ))
  );

  return (
    <div className={styles.wrapper}>
      {itemsById.length ? renderItems() : (
        <div className={styles.empty}>
          {translate(itemType === OrderItemType.PRODUCT ? 'NO_ARTICLES_ASSIGNED_TO_USER' : 'NO_MEMBERS_ASSIGNED_TO_ARTICLE')}
        </div>
      )}
    </div>
  );
};

export default MobileItemsList;
