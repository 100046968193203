import pickBy from 'lodash/pickBy';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';

import { BULK_ASSIGN_MEMBERS_PATH } from '../../constants/paths';
import { BulkAssignMembersRequestAction, NotificationType } from '../../models/actions';
import { State } from '../../models/State';
import { addNotification } from '../../actions/notifications';
import { assignMemberError, bulkAssignMembersSuccess } from '../../actions/members';
import { handleError } from '../../actions/errors';
import {
  BULK_ASSIGN_MEMBERS_ERROR, BULK_ASSIGN_MEMBERS_REQUEST, BULK_ASSIGN_MEMBERS_SUCCESS,
} from '../../constants/actionTypes';
import { MemberAssignType } from '../../models/Member';
import { fetchPlayersRequest } from '../../actions/players';
import { fetchTeamRequest } from '../../actions/teams';
import { fetchStaffRequest } from '../../actions/staff';

export default (
  action$: ActionsObservable<BulkAssignMembersRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(BULK_ASSIGN_MEMBERS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      personIds, sourceTeamId, targetTeamId, actionType, roles,
    },
  }, {
    application: { clubId },
    authentication: { authToken },
  }]) => ajax({
    url: BULK_ASSIGN_MEMBERS_PATH,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify(pickBy({
      personIds,
      clubId,
      sourceTeamId,
      targetTeamId,
      actionType,
      roles,
    })),
  }).pipe(
    mergeMap(() => [
      addNotification(NotificationType.SUCCESS, BULK_ASSIGN_MEMBERS_SUCCESS),
      bulkAssignMembersSuccess(),
      actionType === MemberAssignType.MOVE && fetchTeamRequest(sourceTeamId),
      actionType === MemberAssignType.MOVE && fetchPlayersRequest(sourceTeamId, {}),
      actionType === MemberAssignType.MOVE && fetchStaffRequest(sourceTeamId),
    ].filter(Boolean)),
    catchError((error: AjaxError) => [
      handleError(error, BULK_ASSIGN_MEMBERS_ERROR),
      assignMemberError(error),
    ]),
  )),
);
