import { Item, ItemsByHash } from '../../../../models/Order';

export default (itemsById: string[], itemsByHash: ItemsByHash) => (
  itemsById.reduce((grouped: { [key: number]: Item[] }, id: string) => {
    const item = itemsByHash[id];
    const groupProperty = item.playerId ? item.playerId : (item.staffId || 0);

    return ({
      ...grouped,
      [groupProperty]: [
        ...(grouped[groupProperty] || []),
        item,
      ],
    });
  }, {})
) as { [key: number]: Item[] };
