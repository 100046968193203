import pickBy from 'lodash/pickBy';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { push } from 'connected-react-router';
import { AjaxError } from 'rxjs/ajax';
import {
  catchError, mergeMap, throttleTime, withLatestFrom,
} from 'rxjs/operators';
import queryString from 'query-string';

import {
  CHANGE_ORDER_STATUS_ERROR,
  CHANGE_ORDER_STATUS_REQUEST,
  SET_STATUS,
} from '../../constants/actionTypes';
import { ChangeOrderStatusRequestAction, NotificationType, SetStatusAction } from '../../models/actions';
import { State } from '../../models/State';
import { ORDERS, ORDERS_SET_STATUS } from '../../constants/paths';
import { Response } from '../../models/Response';
import { Order } from '../../models/Order';
import { CLUB_ORDERS_ROUTE, TEAM_ORDERS_ROUTE } from '../../constants/routes';
import { Roles } from '../../constants/roles';
import { addNotification } from '../../actions/notifications';
import { handleError } from '../../actions/errors';
import serializeItemForUpsert from './methods/serializeItemForUpsert';
import {
  changeOrderStatusError, changeOrderStatusSuccess,
  fetchTeamOrdersRequest, setStatus, upsertOrderSuccess,
} from '../../actions/orders';
import {
  TABLE_PAGE_URL_PARAM,
  TABLE_SEARCH_URL_PARAM,
  TABLE_SORT_FIELD_URL_PARAM,
  TABLE_SORT_ORDER_URL_PARAM,
} from '../../constants/customizations';
import {
  ListFilters,
} from '../../models/ListFilters';

const setStatusEpic = (
  action$: ActionsObservable<SetStatusAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(SET_STATUS),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { status, id } },
    {
      authentication: { authToken },
      application: { teamId, userRole },
      router: {
        location: {
          search,
        },
      },
    },
  ]) => ajax({
    url: `${ORDERS_SET_STATUS.replace('$id', String(id))}`,
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify({ requestedStatus: status }),
  }).pipe(
    mergeMap(() => {
      const {
        [TABLE_SEARCH_URL_PARAM]: searchParam,
        [TABLE_PAGE_URL_PARAM]: page,
        [TABLE_SORT_FIELD_URL_PARAM]: field,
        [TABLE_SORT_ORDER_URL_PARAM]: order,
      }: ListFilters = queryString.parse(search);
      return [
        changeOrderStatusSuccess(status, id),
        userRole && push(userRole === Roles.CLUB_ADMIN
          ? CLUB_ORDERS_ROUTE
          : TEAM_ORDERS_ROUTE.replace(':teamId', String(teamId))),
        addNotification(NotificationType.SUCCESS, `SET_STATUS_${status}_SUCCESS`),
        fetchTeamOrdersRequest({
          page: (page && +page) || 1,
          ...(field && { field }),
          ...(order && { order }),
          ...(searchParam && { search: searchParam }),
        }),
      ].filter(Boolean);
    }),
    catchError((error: AjaxError) => [
      handleError(error,
        error.response.code === 400
          && error.response.message === 'order.status.not_all_data_filled'
          ? 'ORDER_NOT_FILLED_ERROR'
          : `SET_STATUS_${status}_ERROR`),
      changeOrderStatusError(error),
    ]),
  )),
);

const changeOrderStatus = (
  action$: ActionsObservable<ChangeOrderStatusRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(CHANGE_ORDER_STATUS_REQUEST),
  throttleTime(500),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { teamId, status } },
    {
      authentication: { authToken },
      application: { clubId },
      orders: {
        itemsById, itemsByHash, id, lockedNumbers,
      },
      catalog: {
        catalog: {
          productsByHash,
        },
      },
    },
  ]) => ajax({
    url: ORDERS,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify(pickBy({
      clubId,
      id,
      teamId,
      lockedNumbers,
      items: itemsById.map(
        (itemId: string) => serializeItemForUpsert(itemId, itemsByHash, productsByHash),
      ),
    })),
  }).pipe(
    mergeMap(({ response } : Response<Order>) => [
      upsertOrderSuccess(response),
      setStatus(response.id, status),
    ]),
    catchError((error) => [
      handleError(error, CHANGE_ORDER_STATUS_ERROR),
      changeOrderStatusError(error),
    ]),
  )),
);

export {
  changeOrderStatus,
  setStatusEpic,
};
