import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'semantic-ui-react';
import { useDebouncedCallback } from 'use-debounce';
import styles from './NameFilter.module.scss';
import { FilterComponentProps } from '../../Table/components/TableFilter/TableFilterProps';

const NameFilter = ({
  onFilterChange = () => {},
  filterValue,
  column,
}: FilterComponentProps) => {
  const [translate] = useTranslation();
  const [name, setName] = useState('');
  const [debounceCallback] = useDebouncedCallback((searchValue: string) => {
    onFilterChange({ [column]: searchValue });
  }, 1000);

  useEffect(() => {
    if (filterValue) {
      setName(filterValue as string);
    }
  }, [filterValue]);

  const handleNameSearch = useCallback((
    { target: { value } }: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setName(value);
    debounceCallback(value);
  }, [debounceCallback]);

  return (
    <div className={styles.wrapper}>
      <Form id="name-filter-form">
        <Form.Field
          key="name"
        >
          <label>{translate('NAME')}</label>
          <Input
            icon="search"
            onChange={handleNameSearch}
            value={name}
            size="big"
          />
        </Form.Field>
      </Form>
    </div>
  );
};

export default NameFilter;
