import React, { FC } from 'react';
import styles from './LabelValueField.module.scss';

interface LabelValueFieldProps {
  label: string;
  value?: string;
  isLink?: boolean;
}

const LabelValueField: FC<LabelValueFieldProps> = ({ label, value, isLink }) => (
  <div className={styles.field}>
    <span className={styles.label}>{label}</span>
    {isLink
      ? (<a target="__blank" href={value || ''} className={styles.link}>{value}</a>)
      : (<span className={styles.value}>{value}</span>)}
  </div>
);

export default LabelValueField;
