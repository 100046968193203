import DayPickerInput from 'react-day-picker/DayPickerInput';
import React, { FC, useState, useEffect } from 'react';
import TimePicker from 'rc-time-picker';
import classNames from 'classnames';
import moment, { MomentInput, Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Form, DropdownItemProps, Checkbox, Label,
} from 'semantic-ui-react';

import { MatchEventFormProps } from './MatchEventFormProps';
import {
  DAY, PITCH, MATCH_PLACE, MEETING_TIME,
  COMPETITION, START_TIME, END_TIME,
  OPPONENT_TEAM, DESCRIPTION, ADDRESS, MEETING_POINT,
} from '../MatchEventModal/MatchEventModalProps';
import styles from './MatchEventForm.module.scss';
import { formatDate, DATE_FORMAT, TIME_FORMAT } from '../../../../utils/dateUtils';
import { Pitch, MatchPlace, MatchType } from '../../../../models/Calendar';
import 'rc-time-picker/assets/index.css';
import { getDisabledHours, getDisabledMinutes, getUpperCasedDay } from '../../methods/TimePickerUtil';
import { isRequired } from '../../../../utils/formValidators';

const MatchEventForm: FC<MatchEventFormProps> = ({
  formState,
  handleChange,
  matchDate,
  isSubmitted,
  handleMatchDateChange,
  handleTimeChange,
  loading,
}) => {
  const [pitchTypes, setPitchTypes] = useState<DropdownItemProps[]>([]);
  const [matchTypes, setMatchTypes] = useState<DropdownItemProps[]>([]);
  const [translate] = useTranslation();

  useEffect(() => {
    setPitchTypes(Object.keys(Pitch)
      .map(type => ({ text: translate(type), value: type })));
    setMatchTypes(Object.keys(MatchType)
      .map(type => ({ text: translate(type), value: type })));
  }, [translate]);

  useEffect(() => {
    const {
      [START_TIME]: startTime,
      [END_TIME]: endTime,
      [MEETING_TIME]: meetingTime,
    } = formState;

    if (startTime && (!endTime || startTime > endTime)) {
      const tempEndTime = new Date(startTime);
      tempEndTime.setHours(tempEndTime.getHours() + 2);
      handleTimeChange(moment(tempEndTime), END_TIME);
    }

    if (startTime && meetingTime && startTime < meetingTime) {
      handleTimeChange(moment(startTime), MEETING_TIME);
    }
  }, [formState, handleTimeChange]);

  const handleStartTimeChange = (date: Moment) => {
    handleTimeChange(date, START_TIME);
  };

  const handleEndTimeChange = (date: Moment) => {
    handleTimeChange(date, END_TIME);
  };

  const handleMeetingTimeChange = (date: Moment) => {
    handleTimeChange(date, MEETING_TIME);
  };

  const errorRequired = (value: any) => (
    isSubmitted && !isRequired.rule(value) && translate(isRequired.errorMessage)
  );

  const defaultTimePickerProps = {
    clearIcon: null,
    defaultOpenValue: moment(),
    format: TIME_FORMAT,
    minuteStep: 15,
    showSecond: false,
  };

  return (
    <Form className={styles.content} loading={loading}>
      <Form.Group widths="equal">
        <Form.Input
          fluid
          label={translate('OPPONENT_TEAM')}
          value={formState[OPPONENT_TEAM]}
          onChange={handleChange}
          name={OPPONENT_TEAM}
          error={errorRequired(formState[OPPONENT_TEAM])}
          className={styles.required}
        />
        <Form.Select
          label={translate('COMPETITION')}
          onChange={handleChange}
          options={matchTypes}
          value={formState[COMPETITION] || ''}
          name={COMPETITION}
          className={[styles.select, styles.dropdown].join(' ')}
        />
      </Form.Group>
      <Form.Group>
        <Form.Field>
          <Checkbox
            radio
            label={translate(MatchPlace.HOME)}
            name={MATCH_PLACE}
            value={MatchPlace.HOME}
            checked={formState[MATCH_PLACE] === MatchPlace.HOME}
            onChange={handleChange}
            className={[styles.checkbox, styles.radio, styles.filled].join(' ')}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
            radio
            label={translate(MatchPlace.AWAY)}
            name={MATCH_PLACE}
            value={MatchPlace.AWAY}
            checked={formState[MATCH_PLACE] === MatchPlace.AWAY}
            onChange={handleChange}
            className={[styles.checkbox, styles.radio, styles.filled].join(' ')}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group className={[styles.flex, styles.align, styles.flexEnd].join(' ')}>
        <Form.Field width={5} className={[styles.flex, styles.direction, styles.column, styles.required].join(' ')} error={isSubmitted && !matchDate}>
          <label>{translate('DATE')}</label>
          <DayPickerInput
            onDayChange={handleMatchDateChange}
            value={matchDate || ''}
            format={DATE_FORMAT}
            formatDate={(date: MomentInput) => formatDate(date)}
            placeholder=""
            dayPickerProps={{
              firstDayOfWeek: 1,
              selectedDays: matchDate,
            }}
          />
          {errorRequired(matchDate) && (
            <Label content={errorRequired(matchDate)} pointing prompt />
          )}
        </Form.Field>
        <Form.Input
          fluid
          width={5}
          label={translate('DAY')}
          error={errorRequired(matchDate)}
          disabled
          value={matchDate ? translate(getUpperCasedDay(matchDate)) : ''}
          name={DAY}
          className={[styles.input, styles.disabled, styles.opacity, styles.required].join(' ')}
        />
        <div
          className={classNames(
            ['field', styles.flex, styles.align, styles.flexEnd, styles.justify, styles.spaceBetween, styles.eventDates, styles.flexItem, styles.flexOne],
            errorRequired(formState[START_TIME]) && 'error',
          )}
        >
          <Form.Field className={styles.required} error={isSubmitted && !formState[START_TIME]}>
            <label>{translate('TIME')}</label>
            <TimePicker
              {...defaultTimePickerProps}
              disabledHours={() => [0, 1, 2, 3, 4]}
              className={[styles.time, styles.picker, styles.noClear].join(' ')}
              onChange={handleStartTimeChange}
              value={(formState[START_TIME])
                ? moment(formState[START_TIME]!)
                : undefined
              }
            />
          </Form.Field>
          <span className={styles.timeToLabel}>{translate('TO')}</span>
          <Form.Field error={isSubmitted && !formState[END_TIME]}>
            <TimePicker
              {...defaultTimePickerProps}
              disabledHours={getDisabledHours(formState[START_TIME], END_TIME)}
              disabledMinutes={getDisabledMinutes(formState[START_TIME], END_TIME)}
              disabled={!formState[START_TIME]}
              className={[styles.time, styles.picker, styles.noClear].join(' ')}
              onChange={handleEndTimeChange}
              value={formState[END_TIME] ? moment(formState[END_TIME]!) : undefined}
            />
          </Form.Field>
          {errorRequired(formState[START_TIME]) && (
            <Label content={errorRequired(formState[START_TIME])} pointing prompt />
          )}
        </div>
      </Form.Group>
      <Form.Group>
        <Form.Input
          width={10}
          label={translate('LOCATION_OF_PLAY')}
          value={formState[ADDRESS]}
          onChange={handleChange}
          name={ADDRESS}
        />
        <Form.Select
          label={translate('PITCH')}
          onChange={handleChange}
          options={pitchTypes}
          value={formState[PITCH] || ''}
          name={PITCH}
          className={[styles.select, styles.dropdown].join(' ')}
          width={6}
        />
      </Form.Group>
      <Form.Group>
        <Form.Input
          width={10}
          label={translate('MEETING_POINT')}
          value={formState[MEETING_POINT]}
          onChange={handleChange}
          name={MEETING_POINT}
        />
        <Form.Field width={6} className={[styles.flex, styles.direction, styles.column].join(' ')}>
          <label>{translate('MEETING_TIME')}</label>
          <TimePicker
            {...defaultTimePickerProps}
            disabledHours={getDisabledHours(formState[START_TIME], MEETING_TIME)}
            disabledMinutes={getDisabledMinutes(formState[START_TIME], MEETING_TIME)}
            disabled={!formState[START_TIME]}
            className={[styles.time, styles.picker].join(' ')}
            onChange={handleMeetingTimeChange}
            value={formState[MEETING_TIME] ? moment(formState[MEETING_TIME]!) : undefined}
          />
        </Form.Field>
      </Form.Group>
      <Form.TextArea rows={4} label={translate('INFORMATION')} onChange={handleChange} value={formState[DESCRIPTION] || ''} name={DESCRIPTION} />
    </Form>
  );
};

export default MatchEventForm;
