import {
  catchError, mergeMap, withLatestFrom,
} from 'rxjs/operators';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';

import { AjaxError } from 'rxjs/ajax';
import { FETCH_MEMBER_REQUEST } from '../../constants/actionTypes';
import { fetchMemberError, fetchMemberSuccess } from '../../actions/members';
import { State } from '../../models/State';
import { MEMBER_PATH } from '../../constants/paths';
import { Response } from '../../models/Response';
import { FetchMemberRequestAction } from '../../models/actions';
import { GetMemberResponse } from '../../models/ClubManagement';
import { Roles } from '../../constants/roles';
import { fetchPlayerSuccess } from '../../actions/players';
import serializePerson from '../../utils/serializers/serializePerson';

export default (
  action$: ActionsObservable<FetchMemberRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_MEMBER_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { id } },
    { authentication: { authToken }, application: { clubId } },
  ]) => ajax({
    url: MEMBER_PATH.replace('$clubId', `${clubId}`).replace('$memberId', `${id}`),
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    mergeMap(({ response: { player, person, roles } }: Response<GetMemberResponse>) => [
      ...[roles.includes(Roles.PLAYER) && fetchPlayerSuccess({ ...player, roles })].filter(Boolean),
      fetchMemberSuccess(serializePerson(person, player, roles)),
    ]),
    catchError((error: AjaxError) => [
      fetchMemberError(error),
    ]),
  )),
);
