import { Club, ClubDataResponse } from '../../models/Club';

const serializeClub = (
  { club: { createdAt, logoUrl, ...rest } }: { club: ClubDataResponse },
): Club => ({
  createdAt: new Date(createdAt),
  logoUrl: logoUrl ? `${logoUrl}?alpha=true` : null,
  ...rest,
});

export default serializeClub;
