import { connect } from 'react-redux';

import ManageEmbellishmentsModal from './ManageEmbellishmentsModal';
import { State } from '../../../../../../models/State';

const mapStateToProps = ({
  catalog: { embellishmentsById, embellishmentsByHash },
  players: { byHash: playersByHash },
}: State) => ({
  embellishmentsByHash,
  embellishmentsById,
  playersByHash,
});

export default connect(mapStateToProps)(ManageEmbellishmentsModal);
