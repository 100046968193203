import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { history } from '../../../../configureStore';
import styles from './StaticPage.module.scss';
import {
  IMPRINT_ROUTE,
  USER_CONSENT_ROUTE,
  DATA_PRIVACY_ROUTE,
  ADIDAS_RUNNING_OAUTH_SUCCESS_ROUTE,
} from '../../../../constants/routes';
import params from '../../../../utils/params';

interface StaticPageProps {
  setUserConsentApproved: (approved: boolean) => void;
}

export default ({
  setUserConsentApproved,
}: StaticPageProps) => {
  const { pathname } = useLocation();
  const { getParams } = params(history);
  const currentParams = getParams();
  const [translate] = useTranslation();

  const handleAcceptConsentClick = useCallback(() => {
    setUserConsentApproved(true);
    history.goBack();
  }, [setUserConsentApproved]);

  const ImprintPage = useCallback(() => (
    <>
      <h1>
        {translate('IMPRINT.PAGE_NAME')}
      </h1>
      <div className={styles.noWrap}>
        {translate('IMPRINT.SECTION_1')}
      </div>
      <div className={styles.noWrap}>
        {translate('IMPRINT.SECTION_2')}
      </div>
      <div className={styles.noWrap}>
        {translate('IMPRINT.SECTION_3')}
      </div>
    </>
  ), [translate]);

  const UserConsentPage = useCallback(() => (
    <>
      <h1>
        {translate('USER_CONSENT.PAGE_NAME')}
      </h1>
      <div className={[styles.noWrap, styles.dotEnded].join(' ')}>
        {translate('USER_CONSENT.SECTION_1')}
        <Link to={DATA_PRIVACY_ROUTE}>{translate('DATA_PRIVACY_POLICY')}</Link>
      </div>
      <Button className={styles.acceptButton} onClick={handleAcceptConsentClick} primary>{translate('USER_CONSENT.ACCEPTANCE_BUTTON')}</Button>
    </>
  ), [handleAcceptConsentClick, translate]);

  const AdidasRunningOAuthSuccessPage = useCallback(() => (
    <>
      <h1>
        {
          translate(currentParams && currentParams.code
            ? 'ADIDAS_RUNNING_ACCOUNT_CONNECTED_TITLE_SUCCESS'
            : 'ADIDAS_RUNNING_ACCOUNT_CONNECTED_TITLE_ERROR')
      }
      </h1>
      {
            currentParams && currentParams.code
              ? (
                <a href={`hoc-app://runtastic/oauth/success?code=${currentParams.code}`}>
                  {
                    translate('ADIDAS_RUNNING_ACCOUNT_CONNECTED_CTA')
                }
                </a>
              )
              : <span>{translate('ADIDAS_RUNNING_ACCOUNT_CONNECTED_ERROR_TEXT')}</span>
        }
    </>
  ), [currentParams, translate]);

  const PageContent = useMemo(() => {
    switch (pathname) {
      case IMPRINT_ROUTE:
        return <ImprintPage />;
      case USER_CONSENT_ROUTE:
        return <UserConsentPage />;
      case ADIDAS_RUNNING_OAUTH_SUCCESS_ROUTE:
        return <AdidasRunningOAuthSuccessPage />;
      default:
        return '';
    }
  }, [pathname, ImprintPage, UserConsentPage, AdidasRunningOAuthSuccessPage]);

  return (
    <div className={styles.wrapper}>
      {PageContent}
    </div>
  );
};
