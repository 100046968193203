import React, { useCallback, useState, useMemo } from 'react';
import difference from 'lodash/difference';
import get from 'lodash/get';
import union from 'lodash/union';
import xorBy from 'lodash/xorBy';
import { Button, Header, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import MemberRow from './components/MemberRow';
import MobileMemberRow from './components/MobileMemberRow';
import TableCheckboxHeader from '../../../../components/TableCheckboxHeader';
import TableContainer from '../../../../components/Layout/components/TableContainer';
import columns from './columns';
import columnsMobile from './columnsMobile';
import styles from './SelectPlayersModal.module.scss';
import { PLAYERS_LIST_LIMIT } from '../../../../constants/customizations';
import { SelectPlayersModalProps } from './SelectPlayersModalProps';
import { useViewport } from '../../../../hooks/useViewport';
import MobileModal from '../MobileModal';
import MobileFooter from '../../../../components/MobileFooter';

enum PersonType {
  PLAYERS = 'PLAYERS',
  STAFF_MEMBERS = 'STAFF_MEMBERS',
}

const SelectPlayersModal = ({
  isOpen,
  onClose,
  onSubmit,
  loading,
  playersById,
  playersByHash,
  playersCount,
  fetchPlayers,
  fetchStaff,
  staffById,
  staffByHash,
}: SelectPlayersModalProps) => {
  const [translate] = useTranslation();
  const { isMobile } = useViewport();
  const [activeTab, setActiveTab] = useState(PersonType.PLAYERS);
  const [selectedPlayers, setSelectedPlayers] = useState<number[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<number[]>([]);
  const history = useHistory();
  const teamId = get(useParams(), 'teamId') as string;
  const isAllSelected = !difference(playersById, selectedPlayers).length
    && !difference(staffById, selectedMembers).length;

  const select = (memberId: number) => () => {
    setSelectedPlayers(xorBy(selectedPlayers, [memberId]));
  };

  const selectMember = (memberId: number) => () => {
    setSelectedMembers(xorBy(selectedMembers, [memberId]));
  };

  const selectAll = useCallback(() => {
    setSelectedPlayers(isAllSelected ? [] : union(selectedPlayers, playersById));
    setSelectedMembers(isAllSelected ? [] : union(selectedMembers, staffById));
  }, [isAllSelected, playersById, selectedMembers, selectedPlayers, staffById]);

  const [firstColumn, ...restColumns] = isMobile ? columnsMobile : columns;

  const enrichedColumns = useMemo(() => [
    {
      ...firstColumn,
      headerComponent: () => (
        <TableCheckboxHeader
          title="TEAM_MEMBER"
          onClick={selectAll}
          checked={isAllSelected}
          checkbox
        />
      ),
    },
    ...restColumns,
  ], [firstColumn, restColumns, isAllSelected, selectAll]);

  const onClick = () => {
    onSubmit(selectedPlayers, selectedMembers);
    setSelectedPlayers([]);
    setSelectedMembers([]);
  };

  const handleListChange = (type: PersonType) => () => {
    setActiveTab(type);
  };

  const fetchMembers = useCallback((payload?: any) => {
    fetchPlayers(+teamId, payload);
    fetchStaff(+teamId);
  }, [fetchPlayers, fetchStaff, teamId]);

  const Row = useMemo(() => (isMobile ? MobileMemberRow : MemberRow), [isMobile]);

  const renderPlayers = () => (playersById.map((id: number) => (
    <Row
      key={id}
      player={playersByHash[id]}
      selected={selectedPlayers.some(memberId => memberId === id)}
      select={select(id)}
    />
  )));

  const renderStaffMembers = () => (staffById.map((id: number) => (
    <Row
      key={id}
      member={staffByHash[id]}
      selected={selectedMembers.some(memberId => memberId === id)}
      select={selectMember(id)}
    />
  )));

  const modalContent = (
    <>
      <div className={styles.secondMenuWrapper}>
        <Button
          basic
          className={['simple', styles.listTypeButton].join(' ')}
          content={translate('PLAYERS')}
          onClick={handleListChange(PersonType.PLAYERS)}
          primary={activeTab === PersonType.PLAYERS}
        />
        <Button
          basic
          className="simple"
          content={translate('STAFF')}
          onClick={handleListChange(PersonType.STAFF_MEMBERS)}
          primary={activeTab === PersonType.STAFF_MEMBERS}
        />
      </div>
      <div className={styles.tableWrapper}>
        <TableContainer
          columns={enrichedColumns}
          count={playersCount}
          disableSearch
          fetch={fetchMembers}
          history={history}
          invertedHeader
          limit={PLAYERS_LIST_LIMIT}
          loading={loading}
          renderItems={activeTab === PersonType.PLAYERS ? renderPlayers() : renderStaffMembers()}
        />
      </div>
    </>
  );

  return (isMobile ? (
    <MobileModal
      button={(
        <MobileFooter
          // @ts-ignore
          disabled={!selectedPlayers.length && !selectedMembers.length}
          buttonText={
            translate('ADD_MEMBERS_WITH_COUNT', { count: selectedPlayers.length + selectedMembers.length })
          }
          onClick={onClick}
        />
      )}
      content={modalContent}
      border
      isOpen={isOpen}
      title={translate('ADD_MEMBERS')}
      onClose={onClose}
    />
  ) : (
    <Modal open={isOpen} onClose={onClose} closeIcon>
      <div className={styles.container}>
        <Header as="h2" content={translate('ADD_MEMBERS')} />
        <div className={styles.buttons}>
          <Button basic primary onClick={onClose} content={translate('CANCEL')} />
          <Button
            primary
            disabled={!selectedPlayers.length && !selectedMembers.length}
            onClick={onClick}
          >
            {translate('ADD_MEMBERS_WITH_COUNT', { count: selectedPlayers.length + selectedMembers.length })}
          </Button>
        </div>
      </div>
      {modalContent}
    </Modal>
  ));
};

export default SelectPlayersModal;
