import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import serializeClub from '../../utils/serializers/serializeClub';
import { CLUBS_PATH } from '../../constants/paths';
import { Club, ClubDataResponse } from '../../models/Club';
import { FETCH_CLUB_ERROR, FETCH_CLUB_REQUEST } from '../../constants/actionTypes';
import { FetchClubRequestAction } from '../../models/actions';
import { Response } from '../../models/Response';
import { State } from '../../models/State';
import { fetchClubSuccess, fetchClubError } from '../../actions/clubs';
import { handleError } from '../../actions/errors';

export default (
  action$: ActionsObservable<FetchClubRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_CLUB_REQUEST),
  withLatestFrom(state$),
  mergeMap(([, { authentication: { authToken }, application: { clubId } }]) => ajax({
    url: `${CLUBS_PATH}/${clubId}`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    map(({ response }: Response<ClubDataResponse>) => serializeClub({ club: response })),
    map((club: Club) => fetchClubSuccess(club)),
    catchError(error => [
      handleError(error, FETCH_CLUB_ERROR),
      fetchClubError(error),
    ]),
  )),
);
