import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';
import { FETCH_STAFF_ERROR, FETCH_STAFF_REQUEST } from '../../constants/actionTypes';
import { FetchStaffRequestAction } from '../../models/actions';
import { Response } from '../../models/Response';
import { StaffListResponse } from '../../models/Staff';
import { State } from '../../models/State';
import { TEAM_STAFF_PATH } from '../../constants/paths';
import { fetchStaffError, fetchStaffSuccess } from '../../actions/staff';
import { handleError } from '../../actions/errors';

export default (
  action$: ActionsObservable<FetchStaffRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_STAFF_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { teamId } },
    {
      authentication: { authToken },
    },
  ]) => ajax({
    url: TEAM_STAFF_PATH.replace('$teamId', `${teamId}`),
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    map(({
      response: { staffMembers, pendingStaffMembers },
    }: Response<StaffListResponse>) => fetchStaffSuccess(
      staffMembers, pendingStaffMembers,
    )),
    catchError(error => [
      handleError(error, FETCH_STAFF_ERROR),
      fetchStaffError(error),
    ]),
  )),
);
