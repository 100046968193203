import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import OrderOutdatedModal from './OrderOutdatedModal';
import { State } from '../../../../models/State';
import { deleteOrdersRequest } from '../../../../actions/orders';

const mapStatesToProps = ({
  orders: { orderOutdated, id },
}: State) => ({
  orderOutdated,
  id,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  deleteOrders: bindActionCreators(deleteOrdersRequest, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(OrderOutdatedModal);
