import { AjaxError } from 'rxjs/ajax';
import { SET_EXTERNAL_REF_ERROR, SET_EXTERNAL_REF_REQUEST, SET_EXTERNAL_REF_SUCCESS } from '../../constants/actionTypes';
import {
  SetExternalReferenceErrorAction,
  SetExternalReferenceRequestAction,
  SetExternalReferenceSuccessAction,
} from '../../models/actions';
import { ExternalSystemReference } from '../../models/externalSystemIntegration';

export const setExternalReferencesRequest = (
  references: ExternalSystemReference[],
  teamId: string,
): SetExternalReferenceRequestAction => ({
  type: SET_EXTERNAL_REF_REQUEST,
  payload: {
    references,
    teamId,
  },
});

export const setExternalReferencesSuccess = (
  externalRefs: { response: ExternalSystemReference[] },
): SetExternalReferenceSuccessAction => ({
  type: SET_EXTERNAL_REF_SUCCESS,
  payload: {
    externalRefs,
  },
});

export const setExternalReferencesError = (error: AjaxError): SetExternalReferenceErrorAction => ({
  type: SET_EXTERNAL_REF_ERROR,
  payload: {
    error,
  },
});
