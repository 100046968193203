import React, { useState } from 'react';
import xorBy from 'lodash/xorBy';
import { useTranslation } from 'react-i18next';
import {
  Button, Dimmer, Header, Icon, Loader, Modal,
} from 'semantic-ui-react';

import ProductCard from '../../../Catalog/components/ProductCard';
import styles from './SelectProductsModal.module.scss';
import { Item } from '../../../../models/Order';
import { SelectProductsModalProps } from './SelectProductsModalProps';
import { useViewport } from '../../../../hooks/useViewport';
import MobileModal from '../MobileModal';
import MobileFooter from '../../../../components/MobileFooter';

const SelectProductsModal = ({
  clubId,
  teamId,
  isOpen,
  onClose,
  onSubmit,
  productsById = [],
  productsByHash,
  loading,
}: SelectProductsModalProps) => {
  const [translate] = useTranslation();
  const { isMobile } = useViewport();
  const [selected, setSelected] = useState<Item[]>([]);
  const isSelected = (id: string) => selected.some(item => item.productCatalogId === id);

  const onSelect = (id: string) => () => {
    setSelected(xorBy(
      selected,
      [{ productCatalogId: id } as Item],
      ({ productCatalogId }) => productCatalogId,
    ));
  };

  const onClick = () => {
    onSubmit(selected);
    setSelected([]);
  };

  const handleOnClose = () => {
    onClose();
    setSelected([]);
  };

  const products = (
    <div className={styles.products}>
      {loading && <Dimmer active inverted><Loader /></Dimmer>}

      {(clubId && teamId && !loading && productsById.length) ? productsById.map((
        id: string,
      ) => (
        <div onClick={onSelect(id)} key={id}>
          {isSelected(id) && (
            <Icon name="check" size="large" color="blue" inverted circular className={styles.selected} />
          )}
          <ProductCard
            product={productsByHash[id]}
            clubId={clubId}
            teamId={teamId}
            simple
          />
        </div>
      )) : <p className={styles.noProductsInfo}>{translate('TEAM_CATALOG_HAS_NO_PRODUCTS')}</p>}
    </div>
  );

  return (isMobile ? (
    <MobileModal
      button={(
        <MobileFooter
          // @ts-ignore
          disabled={!selected.length}
          buttonText={
            translate('ADD_PRODUCTS_WITH_COUNT', { count: selected.length })
          }
          onClick={onClick}
        />
      )}
      content={products}
      border
      isOpen={isOpen}
      title={translate('ADD_PRODUCTS')}
      onClose={handleOnClose}
    />
  ) : (
    <Modal open={isOpen} onClose={handleOnClose} closeIcon>
      <div className={styles.modalWrapper}>
        <div className={styles.container}>
          <Header as="h2" content={translate('ADD_PRODUCTS')} />
          {!isMobile && (
            <div className={styles.buttons}>
              <Button basic primary onClick={handleOnClose} content={translate('CANCEL')} />
              <Button primary disabled={!selected.length} onClick={onClick}>
                {translate('ADD_PRODUCTS_WITH_COUNT', { count: selected.length })}
              </Button>
            </div>
          )}
        </div>
        <div className={styles.productsWrapper}>
          {products}
        </div>
      </div>
    </Modal>
  ));
};

export default SelectProductsModal;
