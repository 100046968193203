import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import {
  catchError, mergeMap, withLatestFrom, map,
} from 'rxjs/operators';
import { HOBBY_TEAM_TOKEN_VALIDATION } from '../../constants/paths';
import { Response } from '../../models/Response';
import { State } from '../../models/State';
import { validateHobbyTeamTokenSuccess, validateHobbyTeamTokenError } from '../../actions/application';
import { HobbyTeamToken } from '../../models/HobbyTeamToken';
import { VALIDATE_HOBBY_TEAM_TOKEN_REQUEST } from '../../constants/actionTypes';
import { ValidateHobbyTeamTokenRequestAction } from '../../models/actions';

export default (
  action$: ActionsObservable<ValidateHobbyTeamTokenRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(
    VALIDATE_HOBBY_TEAM_TOKEN_REQUEST,
  ),
  withLatestFrom(state$),
  mergeMap(([{ payload: { token } }, { authentication: { authToken } }]) => ajax({
    url: HOBBY_TEAM_TOKEN_VALIDATION.replace('$token', token),
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    map(({ response }: Response<HobbyTeamToken>) => validateHobbyTeamTokenSuccess(response)),
    catchError(error => [
      validateHobbyTeamTokenError(error),
    ]),
  )),
);
