import React, { useEffect } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import CatalogMenu from './components/CatalogMenu';
import Header from '../../components/Header';
import ProductCard from './components/ProductCard';
import params from '../../utils/params';
import styles from './Catalog.module.scss';
import { CatalogProps } from './CatalogProps';
import TeamSelectComponent from '../../components/TeamSelectComponent';
import { UrlParams } from '../../constants/urlParams';

const Catalog = ({
  catalog,
  clubId,
  embellishmentsById,
  fetchCatalog,
  fetchEmbellishments,
  setApplicationParameter,
  fetchTeams,
  loading,
  history,
}: CatalogProps) => {
  const { getParams, setParam } = params(history);
  const { [UrlParams.ACTIVE_TEAM]: activeTeam } = getParams();
  const [translate] = useTranslation();
  const {
    productsById = [], productsByHash, clubName = '',
  } = catalog;

  useEffect(() => {
    if (clubId && activeTeam) {
      fetchCatalog(+activeTeam);
    }
  }, [fetchCatalog, clubId, activeTeam]);

  useEffect(() => {
    if (activeTeam) {
      setApplicationParameter({ teamId: +activeTeam });
    }
  }, [activeTeam, setApplicationParameter]);

  useEffect(() => {
    if (clubId) {
      fetchTeams({ pagination: false });
    }
  }, [fetchTeams, clubId]);

  useEffect(() => {
    if (!embellishmentsById.length) {
      fetchEmbellishments();
    }
  }, [fetchEmbellishments, embellishmentsById]);

  return catalog ? (
    <div className={styles.wrapper}>
      <Header
        title={`${translate('CATALOG_FOR')} ${clubName}`}
      />

      {loading && (
        <Dimmer active inverted><Loader /></Dimmer>
      )}

      {activeTeam && (
        <TeamSelectComponent
          teamId={+activeTeam}
          onTeamChange={(teamId: number) => setParam(UrlParams.ACTIVE_TEAM, teamId)}
        />
      )}

      <div className={styles.container}>
        <CatalogMenu />
      </div>

      <div className={styles.products}>
        {clubId && productsById.length ? productsById.map((productId: string) => (
          <ProductCard
            key={productId}
            product={productsByHash[productId]}
            clubId={clubId}
            teamId={(activeTeam && +activeTeam) as number}
          />
        )) : (
          <span>{!loading && translate('NO_PRODUCTS_AVAILABLE')}</span>
        )}
      </div>
    </div>
  ) : (
    <div className={styles.wrapper}>
      {loading
        ? <Dimmer active inverted><Loader /></Dimmer>
        : <Header title={translate('CATALOG.NOT_FOUND')} />
      }
    </div>
  );
};

export default Catalog;
