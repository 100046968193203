import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { State } from '../../../models/State';
import { fetchTeamIdRequest } from '../../../actions/teams';
import TeamFilter from './TeamFilter';

const mapStatesToProps = ({
  teams: {
    activeTeams,
    error,
    loading,
  },
  application: {
    clubId,
  },
}: State) => ({
  teams: activeTeams, error, loading, clubId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchTeams: bindActionCreators(fetchTeamIdRequest, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(TeamFilter);
