import {
  RESET_PASSWORD_BY_ADMIN_REQUEST,
  RESET_PASSWORD_BY_ADMIN_SUCCESS,
} from '../constants';

export const resetPasswordByAdminRequest = (userId, password) => ({
  type: RESET_PASSWORD_BY_ADMIN_REQUEST,
  payload: {
    userId,
    password,
  },
});

export const resetPasswordByAdminSuccess = () => ({
  type: RESET_PASSWORD_BY_ADMIN_SUCCESS,
});
