import React, { useEffect, useState } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';

import get from 'lodash/get';
import Footer from './components/Footer';
import HeaderMenu from './components/HeaderMenu';
import PlayersList from './components/PlayersList';
import ProductsList from './components/ProductsList';
import StaffList from './components/StaffList';
import TeamInfo from './components/TeamInfo';
import params from '../../utils/params';
import styles from './OrderPreparation.module.scss';
import { OrderItemType, OrderPreparationProps } from './OrderPreparationProps';
import { UrlParams } from '../../constants/urlParams';
import OrderOutdatedModal from './components/OrderOutdatedModal';
import Header from './components/Header';

const OrderPreparation = ({
  isTeamAvailable,
  initializeOrder,
  clearOrder,
  history,
  loadingUpsert,
  lockNumbers,
  teamLockedNumbers,
  teamUsedNumbers,
  fetchTeams,
  selectedMembersTeam,
  amountOfArticles,
  setSelectedPlayers,
  setSelectedStaff,
}: OrderPreparationProps) => {
  const teamId = +get(useParams(), 'teamId');
  const orderId = +get(useParams(), 'orderId') || undefined;
  const { getParams } = params(history);
  const { [UrlParams.TEAM_ROLE_FILTER]: roleFilter } = getParams();
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<OrderItemType>(
    roleFilter as OrderItemType || OrderItemType.PLAYER,
  );

  useEffect(() => {
    if (!!selectedMembersTeam && selectedMembersTeam !== teamId) {
      setSelectedPlayers([]);
      setSelectedStaff([]);
    }
  }, [selectedMembersTeam, teamId, setSelectedPlayers, setSelectedStaff]);

  useEffect(() => {
    fetchTeams({ pagination: false });
  }, [fetchTeams]);

  useEffect(() => {
    if (teamId) {
      initializeOrder(teamId, orderId);
    }
  }, [teamId, orderId, initializeOrder]);

  const handleSwitchTeam = () => {
    if (teamId) {
      setSelectedPlayers([]);
      setSelectedStaff([]);
    }
  };

  useEffect(() => {
    if (!orderId) {
      lockNumbers(teamLockedNumbers, teamUsedNumbers);
    }
  }, [orderId, teamLockedNumbers, teamUsedNumbers, lockNumbers]);

  useEffect(() => () => {
    clearOrder();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const setModal = (value: boolean) => () => {
    setModalOpened(value);
  };

  const handleSetActiveTab = (tab: OrderItemType) => {
    setActiveTab(tab);
  };

  let List;
  switch (activeTab) {
    case OrderItemType.PRODUCT:
      List = ProductsList;
      break;
    case OrderItemType.STAFF:
      List = StaffList;
      break;
    default:
    case OrderItemType.PLAYER:
      List = PlayersList;
      break;
  }

  return isTeamAvailable ? (
    <div className={styles.wrapper}>
      <div className={styles.topGroup}>
        {loadingUpsert
          && (<Dimmer active inverted><Loader active /></Dimmer>)}
        <Header teamId={teamId} orderId={orderId} />
        <TeamInfo
          teamId={teamId}
          orderId={orderId}
          onSwitchTeam={handleSwitchTeam}
          disabled={!!amountOfArticles}
        />
        <HeaderMenu
          activeTab={activeTab}
          setActiveTab={handleSetActiveTab}
          amountProducts={amountOfArticles}
        />
        <div className={styles.contentWrapper}>
          <List modalOpened={modalOpened} setModal={setModal} />
        </div>
      </div>
      <Footer openModal={setModal(true)} activeTab={activeTab} />
      <OrderOutdatedModal />
    </div>
  ) : <Dimmer active inverted><Loader /></Dimmer>;
};

export default OrderPreparation;
