import pickBy from 'lodash/pickBy';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { push } from 'connected-react-router';
import {
  catchError, mergeMap, throttleTime, withLatestFrom,
} from 'rxjs/operators';

import { UPSERT_ORDER_ERROR, UPSERT_ORDER_REQUEST, UPSERT_ORDER_SUCCESS } from '../../constants/actionTypes';
import { NotificationType, UpsertOrderRequestAction } from '../../models/actions';
import { State } from '../../models/State';
import { ORDERS } from '../../constants/paths';
import { upsertOrderError, upsertOrderSuccess } from '../../actions/orders';
import { Response } from '../../models/Response';
import { Order } from '../../models/Order';
import serializeItemForUpsert from './methods/serializeItemForUpsert';
import { addNotification } from '../../actions/notifications';
import { handleError } from '../../actions/errors';

const upsertOrder = (
  action$: ActionsObservable<UpsertOrderRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(UPSERT_ORDER_REQUEST),
  throttleTime(1000),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { teamId, successRedirectCb } },
    {
      authentication: { authToken },
      application: { clubId },
      orders: {
        itemsById, itemsByHash, id, lockedNumbers,
      },
      catalog: {
        catalog: {
          productsByHash,
        },
      },
    },
  ]) => ajax({
    url: ORDERS,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify(pickBy({
      clubId,
      id,
      teamId,
      items: itemsById.map(
        (itemId: string) => serializeItemForUpsert(itemId, itemsByHash, productsByHash),
      ),
      lockedNumbers,
    })),
  }).pipe(
    mergeMap(({ response } : Response<Order>) => [
      upsertOrderSuccess(response),
      successRedirectCb && push(successRedirectCb(response.id)),
      addNotification(NotificationType.SUCCESS, UPSERT_ORDER_SUCCESS),
    ].filter(Boolean)),
    catchError(error => [
      handleError(error, UPSERT_ORDER_ERROR),
      upsertOrderError(error),
    ]),
  )),
);

export default upsertOrder;
