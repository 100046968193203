import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import App from './containers/App';
import { store, persistor, history } from './configureStore';
import './i18n';

const appComponent = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback="">
          <App />
        </Suspense>
      </PersistGate>
    </ConnectedRouter>
  </Provider>
);

ReactDOM.render(
  appComponent,
  document.getElementById('root'),
);
