import get from 'lodash/get';

import { EmbellishmentTypes } from '../../../../models/Embellishment';
import {
  Item, ItemsByHash, LockedSettings, LockedTypes,
} from '../../../../models/Order';

type GetItemsLockedSettings = (itemsById: string[], itemsByHash: ItemsByHash) => LockedSettings;

export const getItemsLockedSettings: GetItemsLockedSettings = (itemsById, itemsByHash) => {
  const items: Item[] = itemsById.map(itemId => itemsByHash[itemId]);

  const embellishmentsLocked: LockedSettings = Object.keys(EmbellishmentTypes)
    .reduce((settings, embellishmentType) => {
      const isAnyUnlocked = items.some(({ embellishments }) => {
        const embellishment = embellishments
          .find(({ type }) => type === embellishmentType);

        return !get(embellishment, 'locked', true);
      });

      return {
        ...settings,
        [embellishmentType]: !isAnyUnlocked,
      };
    }, {});

  const fieldsLocked = Object.keys(LockedTypes)
    .reduce((settings, fieldType) => {
      const isAnyUnlocked = items.some(item => !get(item, fieldType));

      return {
        ...settings,
        [fieldType]: !isAnyUnlocked,
      };
    }, {});

  return {
    ...embellishmentsLocked,
    ...fieldsLocked,
  };
};
