import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import StaticPage from './StaticPage';
import { setUserConsentApproved } from '../../../../actions/application';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setUserConsentApproved: bindActionCreators(setUserConsentApproved, dispatch),
});

export default connect(null, mapDispatchToProps)(StaticPage);
