import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import MembersList from './MembersList';
import { State } from '../../../../models/State';
import { fetchMembersRequest, deleteMemberRequest } from '../../../../actions/members';

const mapStatesToProps = ({
  members: {
    byHash, byId, loading, count,
  },
  application: { clubId },
}: State) => ({
  byHash, byId, loading, count, clubId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchMembers: bindActionCreators(fetchMembersRequest, dispatch),
  deleteMemberFromClub: bindActionCreators(deleteMemberRequest, dispatch),
});

export default withRouter(connect(mapStatesToProps, mapDispatchToProps)(MembersList));
