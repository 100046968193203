import React from 'react';
import classNames from 'classnames';
import { Dropdown, Icon } from 'semantic-ui-react';

import styles from './styles.module.scss';
import { DropdownWithLockProps } from './DropdownWithLockProps';

const DropdownWithLock = ({
  options, error, isLocked, toggleLocked, value, hideIcon, ...props
}: DropdownWithLockProps) => {
  const handleToggleLocked = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (toggleLocked) {
      toggleLocked(event);
    }
  };

  const dropdownTrigger = (
    <span className={styles.dropdownLabel}>
      {!hideIcon && (
        <Icon
          color={isLocked ? 'blue' : 'grey'}
          name={isLocked ? 'lock' : 'unlock'}
          onClick={handleToggleLocked}
          link
        />
      )}
      {value}
    </span>
  );

  return (
    <Dropdown
      fluid
      options={!isLocked ? options : []}
      placeholder="Choose an option"
      trigger={dropdownTrigger}
      className={classNames(
        error && styles.dangerInput,
        isLocked && styles.disabledInput,
        'selection',
      )}
      {...props}
    />
  );
};

export default DropdownWithLock;
