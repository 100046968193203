import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import styles from './PlainInfoPage.module.scss';
import {
  JOIN_TEAM_AND_CLUB_REJECTED_ROUTE,
  JOIN_OFFICIAL_CLUB_REJECTED_ROUTE,
  JOIN_TEAM_AND_CLUB_SIGNUP_APPROVED_ROUTE,
  JOIN_OFFICIAL_CLUB_SIGNUP_APPROVED_ROUTE,
  SELF_SIGNUP_GENERAL_ROUTE,
} from '../../../../constants/routes';
import AuthenticationFormsWrapper from '../../../../components/AuthenticationFormsWrapper';
import HeaderTag from '../../../../components/WelcomePage/HeaderTag';

interface PlainInfoPageProps {
  clubLogoUrl: string;
}
interface PlainInfoPageContentProps {
  label: string;
  header: string;
  text: string;
  isError?: boolean;
}

const selfSignupSuccessfullyRejectedContent: PlainInfoPageContentProps = {
  label: 'SELF_SIGNUP_REJECTED_SUCCESSFULLY_LABEL',
  header: 'SELF_SIGNUP_REJECTED_SUCCESSFULLY_HEADER',
  text: 'SELF_SIGNUP_REJECTED_SUCCESSFULLY_TEXT',
};
const joinOfficialClubSignupSuccessfullyApprovedContent: PlainInfoPageContentProps = {
  label: 'TEAMLEAD_ADDED_TO_THE_TEAM_SUCCESSFULLY_LABEL',
  header: 'TEAMLEAD_ADDED_TO_THE_TEAM_SUCCESSFULLY_HEADER',
  text: 'TEAMLEAD_ADDED_TO_THE_TEAM_SUCCESSFULLY_TEXT',
};
const joinClubAndTeamSignupSuccessfullyApprovedContent: PlainInfoPageContentProps = {
  label: 'PLAYER_ADDED_TO_THE_TEAM_SUCCESSFULLY_LABEL',
  header: 'PLAYER_ADDED_TO_THE_TEAM_SUCCESSFULLY_HEADER',
  text: 'PLAYER_ADDED_TO_THE_TEAM_SUCCESSFULLY_TEXT',
};
const selfSignupGeneralErrorContent: PlainInfoPageContentProps = {
  label: 'SELF_SIGNUP_GENERAL_ERROR_LABEL',
  header: 'SELF_SIGNUP_GENERAL_ERROR_HEADER',
  text: 'SELF_SIGNUP_GENERAL_ERROR_TEXT',
  isError: true,
};

export default ({
  clubLogoUrl,
}: PlainInfoPageProps) => {
  const { pathname } = useLocation();
  const [translate] = useTranslation();

  const PlainInfoPagePage: FC<PlainInfoPageContentProps> = useCallback(({
    label, header, text, isError = false,
  }) => (
    <>
      <HeaderTag
        successTitle={!isError ? label : null}
        errorTitle={isError ? label : null}
        serverError={isError}
      />
      <h2 className={styles.header}>
        {translate(header)}
      </h2>
      <p className={[styles.text, styles.shrink, styles.confirmationText].join(' ')}>
        {translate(text)}
      </p>
    </>
  ), [translate]);

  const PageContent = useMemo(() => {
    switch (pathname) {
      case JOIN_OFFICIAL_CLUB_SIGNUP_APPROVED_ROUTE:
        return <PlainInfoPagePage {...joinOfficialClubSignupSuccessfullyApprovedContent} />;
      case JOIN_TEAM_AND_CLUB_SIGNUP_APPROVED_ROUTE:
        return <PlainInfoPagePage {...joinClubAndTeamSignupSuccessfullyApprovedContent} />;
      case JOIN_TEAM_AND_CLUB_REJECTED_ROUTE:
        return <PlainInfoPagePage {...selfSignupSuccessfullyRejectedContent} />;
      case JOIN_OFFICIAL_CLUB_REJECTED_ROUTE:
        return <PlainInfoPagePage {...selfSignupSuccessfullyRejectedContent} />;
      case SELF_SIGNUP_GENERAL_ROUTE:
        return <PlainInfoPagePage {...selfSignupGeneralErrorContent} />;
      default:
        return '';
    }
  }, [pathname, PlainInfoPagePage]);

  return (
    <AuthenticationFormsWrapper clubLogoUrl={clubLogoUrl}>
      <div className={styles.wrapper}>
        {PageContent}
      </div>
    </AuthenticationFormsWrapper>
  );
};
