import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import TeamInvitationPopup from './TeamInvitationPopup';
import { State } from '../../../../models/State';
import { fetchTeamInviteRequest } from '../../../../actions/teams';
import { StateProps, DispatchProps } from './TeamInvitationPopupProps';
import { deactivateInviteTokenRequest } from '../../../../actions/invitations';

const mapStateToProps = ({
  teams: { inviteToken, loadingInviteToken, tokenDeactivated },
}: State): StateProps => ({
  inviteToken,
  loadingInviteToken,
  tokenDeactivated,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  fetchInviteToken: bindActionCreators(fetchTeamInviteRequest, dispatch),
  deactivateToken: bindActionCreators(deactivateInviteTokenRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamInvitationPopup);
