import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import State from '../../models';
import { signOut } from '../../packages/authentication/actions';
import SignupWelcomePage from './SignupWelcomePage';
import { ClubType } from '../../models/ClubType';

const mapStateToProps = ({
  clubs: { logoUrl },
  authentication: { authToken },
  application: {
    error: serverError, clubId, memberships, loading, userRole,
  },
  signUp: { userSigned, receiverRole },
  user: { user },
}: State) => ({
  clubLogoUrl: logoUrl,
  isAuthenticated: !!authToken,
  serverError,
  loading,
  mainUserRole: userRole || receiverRole,
  userRoles: (clubId && memberships[clubId]) || (receiverRole && [receiverRole]) || [],
  userFirstName: userSigned || (user && user.firstName) || '',
  isHobbyTeam: !!user && user.clubType && user.clubType === ClubType.HOBBY,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ signOut }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SignupWelcomePage);
