import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router';
import TeamSettings from './TeamSettings';
import { State } from '../../../../models/State';
import { editTeamSettingsRequest, fetchTeamRequest } from '../../../../actions/teams';

const mapStateToProps = ({
  teams,
}: State, { match: { params: { teamId } } }: RouteComponentProps<{ teamId: string }>) => ({
  teamId: +teamId,
  team: teamId ? teams.byHash[teamId] : null,
  loading: teams.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  editTeamSettings: bindActionCreators(editTeamSettingsRequest, dispatch),
  fetchTeam: bindActionCreators(fetchTeamRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamSettings);
