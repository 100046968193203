import { AjaxError } from 'rxjs/ajax';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import {
  catchError, mergeMap, withLatestFrom, map,
} from 'rxjs/operators';

import { DEACTIVATE_INVITE_TOKEN_ERROR, DEACTIVATE_INVITE_TOKEN_REQUEST } from '../../constants/actionTypes';
import { DEACTIVATE_INVITE_TOKEN } from '../../constants/paths';
import { DeactivateInviteTokenRequestAction } from '../../models/actions';
import { State } from '../../models/State';
import { generateInviteTokenError } from '../../actions/teams';
import { handleError } from '../../actions/errors';
import { deactivateInviteTokenSuccess } from '../../actions/invitations';

export default (
  action$: ActionsObservable<DeactivateInviteTokenRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(DEACTIVATE_INVITE_TOKEN_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { token } },
    { authentication: { authToken } },
  ]) => ajax({
    url: DEACTIVATE_INVITE_TOKEN.replace('$token', token),
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    map(() => deactivateInviteTokenSuccess()),
    catchError((error: AjaxError) => [
      handleError(error, DEACTIVATE_INVITE_TOKEN_ERROR),
      generateInviteTokenError(error),
    ]),
  )),
);
