import { connect } from 'react-redux';

import Notifications from './Notifications';
import { State } from '../../../../models/State';

const mapStateToProps = ({ notifications: { notifications } }: State) => ({
  notifications,
});

export default connect(mapStateToProps)(Notifications);
