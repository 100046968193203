import resetPassword from './resetPassword';
import resetPasswordByAdmin from './resetPasswordByAdmin';
import resetPasswordToken from './resetPasswordToken';
import signIn from './signIn';
import signOut from './signOut';
import unauthorized from './unauthorized';

export default [
  resetPassword,
  resetPasswordByAdmin,
  resetPasswordToken,
  signIn,
  signOut,
  unauthorized,
];
