import { Column } from '../../components/Table/Column';
import seasonFilter from '../../components/SeasonFilter';

const columns: Column[] = [{
  header: 'TEAM',
  align: 'left',
  width: '15%',
  key: 'team-name',
}, {
  header: 'SEASON',
  align: 'left',
  width: '15%',
  key: 'seasons',
  filter: seasonFilter,
}, {
  header: 'TRAINER',
  width: '20%',
  align: 'left',
  key: 'team-leads',
}, {
  width: '25%',
  header: 'PLAYERS',
  align: 'left',
  key: 'players',
}, {
  width: '10%',
  header: 'SEASON_START',
  align: 'left',
  sortable: false,
  key: 'seasonStartDate',
}, {
  width: '10%',
  header: 'SEASON_END',
  align: 'left',
  sortable: false,
  key: 'seasonEndDate',
}, {
  width: '5%',
  header: '',
  align: 'right',
  sortable: false,
  key: 'options',
}];

export default columns;
