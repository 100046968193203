import i18next from 'i18next';
import { FormSchema } from '../../../../models/Form';
import { isRequired, isValidEmail } from '../../../../utils/formValidators';
import { Roles } from '../../../../constants/roles';

export const getOptions = (values: string[], translate: i18next.TFunction) => values.map(value => ({
  key: value as string,
  text: translate(`${value}_ROLE`) as string,
  value: value as string,
}));

export const createSchema: FormSchema = {
  firstName: {
    type: 'text',
    label: 'FIRSTNAME',
    required: true,
  },
  lastName: {
    type: 'text',
    label: 'LASTNAME',
    required: true,
  },
  email: {
    type: 'text',
    label: 'EMAIL',
    required: true,
  },
  teamName: {
    type: 'label',
    label: 'TEAM_NAME',
  },
  role: {
    type: 'select',
    label: 'ROLE',
    required: true,
    options: (translate: i18next.TFunction) => getOptions(
      [Roles.PLAYER, Roles.TEAM_LEAD],
      translate,
    ),
  },
};

export const createValidationSchema = {
  firstName: [isRequired],
  lastName: [isRequired],
  role: [isRequired],
  email: [isRequired, isValidEmail],
};

export const existingSchema: FormSchema = {
  fullName: {
    type: 'label',
    label: 'FULL_NAME',
  },
  teamName: {
    type: 'label',
    label: 'TEAM_NAME',
  },
  role: {
    type: 'select',
    label: 'ROLE',
    required: true,
    options: (translate: i18next.TFunction) => getOptions(
      [Roles.PLAYER, Roles.TEAM_LEAD],
      translate,
    ),
  },
};

export const existingValidationSchema = {
  role: [isRequired],
};
