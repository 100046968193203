import * as types from '../../constants/actionTypes';

export enum NotificationType {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export interface AddNotificationAction {
  type: typeof types.ADD_NOTIFICATION;
  payload: {
    id: string;
    type: NotificationType;
    actionType: string;
    status?: number;
    message?: string;
  };
}

export interface RemoveNotificationAction {
  type: typeof types.REMOVE_NOTIFICATION;
  payload: {
    id: string;
  };
}

export type NotificationsActions =
  | AddNotificationAction
  | RemoveNotificationAction;
