import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import getItemsByIdForMember from '../../../../methods/getItemsByIdForMember';
import DesktopMemberDetails from './DesktopMemberDetails';
import { PlayerDetailsSimpleProps } from './DesktopMemberDetailsProps';
import { State } from '../../../../../../models/State';
import {
  addItemsForPlayers, removeItems, upsertItems, addItemsForSelectedStaffMembers,
} from '../../../../../../actions/orderEditor';

const mapStatesToProps = (
  { orders }: State,
  { member }: PlayerDetailsSimpleProps,
) => ({
  itemsByHash: orders.itemsByHash,
  itemsById: getItemsByIdForMember(member, orders.itemsById, orders.itemsByHash),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  upsertItems: bindActionCreators(upsertItems, dispatch),
  removeItems: bindActionCreators(removeItems, dispatch),
  addItemsForPlayers: bindActionCreators(addItemsForPlayers, dispatch),
  addItemsForSelectedStaffMembers: bindActionCreators(addItemsForSelectedStaffMembers, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(DesktopMemberDetails);
