import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import SignIn from './SignIn';
import State from '../../models';
import { signIn } from '../../packages/authentication';

const mapStateToProps = ({
  authentication: { loading: authenticationLoading, error },
  application: {
    loading: applicationLoading, teamId, userRole, clubId,
  },
  clubs: { logoUrl },
}: State) => ({
  loading: applicationLoading || authenticationLoading,
  clubLogoUrl: logoUrl,
  error,
  teamId,
  userRole,
  clubId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  signIn: bindActionCreators(signIn, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
