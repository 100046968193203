import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import TableContainer from '../../../../components/Layout/components/TableContainer';
import columns from './columnsMobile';
import { OrdersMobileProps } from './OrdersMobileProps';
import OrdersMobileRow from './components/OrdersMobileRow';
import { OrderList } from '../../../../models/Order';
import styles from './OrdersMobile.module.scss';
import { TEAM_ROUTE, CLUB_ROUTE } from '../../../../constants/routes';
import MobileHeader from '../../../../components/MobileHeader';
import { history } from '../../../../configureStore';
import { HeaderTypes } from '../../../../components/MobileHeader/MobileHeaderProps';

const OrdersMobile: FC<OrdersMobileProps> = ({
  count,
  fetchOrders,
  loading,
  orders,
}) => {
  const [translate] = useTranslation();
  const orderContext = history.location.pathname.startsWith(TEAM_ROUTE) ? TEAM_ROUTE : CLUB_ROUTE;
  const renderRows = (order: OrderList) => (
    <OrdersMobileRow
      order={order}
      orderContext={orderContext}
      key={order.id}
    />
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.topGroup}>
        <MobileHeader
          title={translate('ORDERS')}
          border
          titleType={HeaderTypes.H2}
        />
        <div className={styles.listWrapper}>
          <TableContainer
            fetch={fetchOrders}
            loading={loading}
            count={count}
            history={history}
            columns={columns}
            renderItems={orders.map(renderRows)}
            disableSearch
            invertedHeader
            emptyTableText="NO_ORDERS_AVAILABLE"
          />
        </div>
      </div>
    </div>
  );
};

export default OrdersMobile;
