import { AjaxError } from 'rxjs/ajax';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import {
  catchError, mergeMap, withLatestFrom, map, filter,
} from 'rxjs/operators';

import { FETCH_TEAM_INVITE_TOKEN_ERROR, FETCH_TEAM_INVITE_TOKEN_REQUEST } from '../../constants/actionTypes';
import { TEAM_INVITATION_TOKEN } from '../../constants/paths';
import { FetchTeamInviteRequestAction } from '../../models/actions';
import { Response } from '../../models/Response';
import { State } from '../../models/State';
import { fetchTeamInviteSuccess, fetchTeamInviteError, generateInviteTokenRequest } from '../../actions/teams';
import { CreateInvitation, TeamInvitationToken } from '../../models/InvitationData';
import { Roles } from '../../constants/roles';
import { handleError } from '../../actions/errors';

export default (
  action$: ActionsObservable<FetchTeamInviteRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_TEAM_INVITE_TOKEN_REQUEST),
  withLatestFrom(state$),
  filter(([, { application: { clubId } }]) => !!clubId),
  mergeMap(([{
    payload: {
      teamId,
    },
  }, {
    authentication: { authToken },
    application: { clubId },
  }]) => {
    const createInvitation: CreateInvitation = {
      clubId,
      teamId,
      receiverRole: Roles.PLAYER,
    };

    return ajax({
      url: TEAM_INVITATION_TOKEN.replace('$teamId', `${teamId}`),
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authToken,
      },
    }).pipe(
      map(({
        response: { token },
      }: Response<TeamInvitationToken>) => fetchTeamInviteSuccess(token)),
      catchError((error: AjaxError) => (
        (error.status === 404 || error.status === 500)
          ? of(generateInviteTokenRequest(createInvitation))
          : [
            fetchTeamInviteError(error),
            handleError(error, FETCH_TEAM_INVITE_TOKEN_ERROR),
          ]
      )),
    );
  }),
);
