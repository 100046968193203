import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxProps, Form } from 'semantic-ui-react';
import { FilterComponentProps } from '../../Table/components/TableFilter/TableFilterProps';
import styles from './StatusFilter.module.scss';

const StatusFilter = ({
  onFilterChange,
  filterValue,
  column,
}: FilterComponentProps) => {
  const [translate] = useTranslation();
  const [active, setActive] = useState<boolean>(false);
  const [inactive, setInactive] = useState<boolean>(false);

  useEffect(() => {
    if (Array.isArray(filterValue)) {
      setActive(filterValue.some((val) => val === 'ACTIVE'));
      setInactive(filterValue.some((val) => val === 'INACTIVE'));
    } else {
      setActive(false);
      setInactive(false);
    }
  }, [filterValue]);

  const handleStatusFilter = (isActive: boolean) => (e: any, data: CheckboxProps) => {
    let statuses = [];
    if (active) {
      statuses.push('ACTIVE');
    }
    if (inactive) {
      statuses.push('INACTIVE');
    }
    if (data.checked) {
      if (isActive) {
        statuses.push('ACTIVE');
      } else {
        statuses.push('INACTIVE');
      }
    } else {
      statuses = statuses.filter((st) => st === (isActive ? 'INACTIVE' : 'ACTIVE'));
    }
    if (onFilterChange) {
      if (statuses.length) {
        onFilterChange({
          [column]: statuses,
        });
      } else {
        onFilterChange({
          [column]: null,
        });
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <Form id="status-filter-form">
        <Form.Field
          key="status"
          className={styles.field}
        >
          <label>{translate('STATUS')}</label>
          <div className={styles.checkboxContainer}>
            <Form.Checkbox
              checked={active}
              onChange={handleStatusFilter(true)}
            />
            <div className={styles.marginRight}>{translate('ACTIVE')}</div>
            <Form.Checkbox
              checked={inactive}
              onChange={handleStatusFilter(false)}
            />
            <div>{translate('INACTIVE')}</div>
          </div>
        </Form.Field>
      </Form>
    </div>
  );
};

export default StatusFilter;
