import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { push } from 'connected-react-router';

import serializeTeam from '../../utils/serializers/serializeTeam';
import { CREATE_TEAM_ERROR, CREATE_TEAM_REQUEST, CREATE_TEAM_SUCCESS } from '../../constants/actionTypes';
import { CreateTeamRequestAction, NotificationType } from '../../models/actions';
import { State } from '../../models/State';
import { TEAMS_PATH } from '../../constants/paths';
import { CLUB_TEAMS_ROUTE } from '../../constants/routes';
import { addNotification } from '../../actions/notifications';
import { createTeamError, createTeamSuccess } from '../../actions/teams';
import { handleError } from '../../actions/errors';
import { setExternalReferencesRequest } from '../../actions/externalSystems/setExternalReferences';
import { ExternalReferencedEntityType, ExternalSystemName } from '../../models/externalSystemIntegration';

export default (
  action$: ActionsObservable<CreateTeamRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(CREATE_TEAM_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      clubId, name, season, seasonStartDate, seasonEndDate, externalTeam,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: TEAMS_PATH.replace('$clubId', `${clubId}`),
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify({ name, teamSettings: { season, seasonStartDate, seasonEndDate } }),
  }).pipe(
    mergeMap(({ response: createdTeam }) => ([
      addNotification(NotificationType.SUCCESS, CREATE_TEAM_SUCCESS),
      externalTeam
        && setExternalReferencesRequest([{
          entityType: ExternalReferencedEntityType.TEAM,
          entityId: createdTeam.id,
          externalSystemName: ExternalSystemName.DFB_SQUAD_ID,
          externalSystemEntityId: externalTeam.staffelId,
        }, {
          entityType: ExternalReferencedEntityType.TEAM,
          entityId: createdTeam.id,
          externalSystemName: ExternalSystemName.DFB_SQUAD_REF,
          externalSystemEntityId: externalTeam.staffelWettkampfId,
        }, {
          entityType: ExternalReferencedEntityType.TEAM,
          entityId: createdTeam.id,
          externalSystemName: ExternalSystemName.DFB_DIVISION,
          externalSystemEntityId: externalTeam.staffelKennung,
        }, {
          entityType: ExternalReferencedEntityType.TEAM,
          entityId: createdTeam.id,
          externalSystemName: ExternalSystemName.DFB_TEAM_ID,
          externalSystemEntityId: externalTeam.mannschaftsId,
        }, {
          entityType: ExternalReferencedEntityType.TEAM,
          entityId: createdTeam.id,
          externalSystemName: ExternalSystemName.DFB_TEAM_PERMANENT_ID,
          externalSystemEntityId: externalTeam.mannschaftsPermanentId,
        }], createdTeam.id),
      createTeamSuccess(serializeTeam(createdTeam)),
      push(CLUB_TEAMS_ROUTE),
    ].filter(Boolean))),
    catchError((error: AjaxError) => [
      handleError(error, CREATE_TEAM_ERROR),
      createTeamError(error),
    ]),
  )),
);
