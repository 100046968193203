import { AjaxError } from 'rxjs/ajax';
import * as types from '../constants/actionTypes';
import { Member, Person } from '../models/ClubManagement';
import { ListFilters } from '../models/ListFilters';
import {
  AssignMemberErrorAction,
  AssignMemberRequestAction,
  BulkAssignMembersErrorAction,
  BulkAssignMembersRequestAction,
  BulkAssignMembersSuccessAction,
  EditRolesMembersErrorAction,
  EditRolesMembersRequestAction,
  EditRolesMembersSuccessAction,
  FetchMemberErrorAction,
  FetchMemberRequestAction,
  FetchMemberSuccessAction,
  FetchMembersErrorAction,
  FetchMembersRequestAction,
  FetchMembersSuccessAction,
  DeleteMembersRequestAction,
  DeleteMembersSuccessAction,
  DeleteMembersErrorAction,
  DeleteMemberRequestAction,
  DeleteMemberSuccessAction,
  DeleteMemberErrorAction,
} from '../models/actions';
import { MemberAssignType } from '../models/Member';
import { Roles } from '../constants/roles';

export const fetchMembersRequest = (
  filters: ListFilters,
  search?: string,
): FetchMembersRequestAction => ({
  type: types.FETCH_MEMBERS_REQUEST,
  payload: {
    filters,
    search,
  },
});

export const fetchMembersSuccess = (
  members: Member[], count: number,
): FetchMembersSuccessAction => ({
  type: types.FETCH_MEMBERS_SUCCESS,
  payload: {
    members,
    count,
  },
});

export const fetchMembersError = (error: AjaxError): FetchMembersErrorAction => ({
  type: types.FETCH_MEMBERS_ERROR,
  payload: {
    error,
  },
});

export const assignMemberRequest = (
  teamId: number,
  role: string,
  personId: number,
): AssignMemberRequestAction => ({
  type: types.ASSIGN_MEMBER_REQUEST,
  payload: {
    teamId,
    role,
    personId,
  },
});

export const assignMemberError = (error: AjaxError): AssignMemberErrorAction => ({
  type: types.ASSIGN_MEMBER_ERROR,
  payload: {
    error,
  },
});

export const bulkAssignMembersRequest = (
  personIds: number[], sourceTeamId: number, targetTeamId: number,
  actionType: MemberAssignType, roles: Roles[],
): BulkAssignMembersRequestAction => ({
  type: types.BULK_ASSIGN_MEMBERS_REQUEST,
  payload: {
    personIds,
    sourceTeamId,
    targetTeamId,
    actionType,
    roles,
  },
});

export const bulkAssignMembersSuccess = (): BulkAssignMembersSuccessAction => ({
  type: types.BULK_ASSIGN_MEMBERS_SUCCESS,
});

export const bulkAssignMembersError = (error: AjaxError): BulkAssignMembersErrorAction => ({
  type: types.BULK_ASSIGN_MEMBERS_ERROR,
  payload: {
    error,
  },
});

export const fetchMemberRequest = (id: number): FetchMemberRequestAction => ({
  type: types.FETCH_MEMBER_REQUEST,
  payload: {
    id,
  },
});

export const fetchMemberSuccess = (person: Person): FetchMemberSuccessAction => ({
  type: types.FETCH_MEMBER_SUCCESS,
  payload: {
    person,
  },
});

export const fetchMemberError = (error: AjaxError): FetchMemberErrorAction => ({
  type: types.FETCH_MEMBER_ERROR,
  payload: {
    error,
  },
});

export const editRolesMembersRequest = (
  teamId: number, personId: number, roles: Roles[],
): EditRolesMembersRequestAction => ({
  type: types.EDIT_ROLES_MEMBERS_REQUEST,
  payload: {
    teamId,
    personId,
    roles,
  },
});

export const editRolesMembersSuccess = (
  teamId: number, personId: number, roles: Roles[],
): EditRolesMembersSuccessAction => ({
  type: types.EDIT_ROLES_MEMBERS_SUCCESS,
  payload: {
    teamId,
    personId,
    roles,
  },
});

export const editRolesMembersError = (error: AjaxError): EditRolesMembersErrorAction => ({
  type: types.EDIT_ROLES_MEMBERS_ERROR,
  payload: {
    error,
  },
});

export const deleteMembersRequest = (
  personIds: number[], teamId: number,
): DeleteMembersRequestAction => ({
  type: types.DELETE_MEMBERS_REQUEST,
  payload: {
    teamId,
    personIds,
  },
});

export const deleteMembersSuccess = (personIds: number[]): DeleteMembersSuccessAction => ({
  type: types.DELETE_MEMBERS_SUCCESS,
  payload: {
    personIds,
  },
});

export const deleteMembersError = (error: AjaxError): DeleteMembersErrorAction => ({
  type: types.DELETE_MEMBERS_ERROR,
  payload: {
    error,
  },
});

export const deleteMemberRequest = (
  memberId: number, clubId: number,
): DeleteMemberRequestAction => ({
  type: types.DELETE_MEMBER_REQUEST,
  payload: {
    clubId,
    memberId,
  },
});

export const deleteMemberSuccess = (
  memberId: number, clubId: number,
): DeleteMemberSuccessAction => ({
  type: types.DELETE_MEMBER_SUCCESS,
  payload: {
    memberId,
    clubId,
  },
});

export const deleteMemberError = (error: AjaxError): DeleteMemberErrorAction => ({
  type: types.DELETE_MEMBER_ERROR,
  payload: {
    error,
  },
});
