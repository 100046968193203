import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import ResetPassword from './ResetPassword';
import { resetPasswordCleanUp, resetPasswordTokenRequest, resetPasswordRequest } from '../../packages/authentication';
import State from '../../models';

const mapStateToProps = ({
  clubs: { logoUrl },
}: State) => ({
  clubLogoUrl: logoUrl,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  resetPasswordCleanUp,
  resetPasswordTokenRequest,
  resetPasswordRequest,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
