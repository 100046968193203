import React, { FC } from 'react';
import classNames from 'classnames';
import { Dropdown, Header, Icon } from 'semantic-ui-react';

import styles from './MobileHeader.module.scss';
import { MobileHeaderProps, HeaderTypes } from './MobileHeaderProps';

const MobileHeader: FC<MobileHeaderProps> = ({
  title,
  subtitle,
  primaryTitle,
  primarySubtitle,
  titleType = HeaderTypes.H2,
  subtitleType = HeaderTypes.H3,
  titleEllipsis = false,
  border,
  menuItems = [],
}) => (
  <div className={classNames(
    styles.wrapper,
    border && styles.border,
  )}
  >
    <div className={styles.leftSide}>
      <Header
        className={[primaryTitle ? styles.primary : '', titleEllipsis ? styles.titleEllipsis : ''].join(' ')}
        as={titleType}
        content={title}
      />
    </div>
    <div className={styles.rightSide}>
      {subtitle && (
        <Header
          className={[styles.subtitle, primarySubtitle ? styles.primary : ''].join(' ')}
          as={subtitleType}
          content={subtitle}
        />
      )}
      {menuItems && (menuItems as []).length ? (
        <Dropdown icon={<Icon className={styles.menuIcon} name="ellipsis vertical" color="blue" size="large" />}>
          <Dropdown.Menu direction="left">
            {menuItems}
          </Dropdown.Menu>
        </Dropdown>
      ) : null}
    </div>
  </div>
);

export default MobileHeader;
