import { pickBy } from 'lodash';
import { useState } from 'react';

import { TABLE_FILTER_URL_PARAM } from '../../constants/customizations';
import { FilterValue } from '../../models/general/FilterValue';
import params from '../../utils/params';

const useFilters = (history: any) => {
  const [filter, setFilter] = useState<Record<string, FilterValue>>({});
  const { setParams } = params(history);

  const onFilterChange = (value: Record<string, FilterValue>) => {
    const mergedValue: Record<string, FilterValue> = pickBy({
      ...filter,
      ...value,
    });
    setParams({
      [TABLE_FILTER_URL_PARAM]: Object.keys(pickBy(mergedValue)).length ? JSON.stringify(pickBy(mergedValue)) : '',
    });
    setFilter(mergedValue);
  };

  return {
    filter, setFilter, onFilterChange,
  };
};

export default useFilters;
