import React, { FC, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Form, Modal } from 'semantic-ui-react';

import ModalActions from '../../../../components/ModalActions';
import styles from './EditRoleModal.module.scss';
import { EditRoleModalProps } from './EditRoleModalProps';
import { Roles } from '../../../../constants/roles';

const EditRoleModal: FC<EditRoleModalProps> = ({
  editRolesMembers,
  member: {
    personId, roles: memberRoles = [],
    firstName, lastName,
  },
  teamId,
}) => {
  const [translate] = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [roles, setRoles] = useState<Roles[]>(memberRoles);

  const rolesOptions = [Roles.TEAM_LEAD, Roles.PLAYER, Roles.TREASURER].map((role: Roles) => ({
    key: role,
    text: translate(`${role}_ROLE`),
    value: role,
  }));

  const toggleOpen = (event: SyntheticEvent) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleConfirm = (event: SyntheticEvent) => {
    editRolesMembers(teamId, personId, roles);
    setIsOpen(!isOpen);
    event.stopPropagation();
  };

  const handleCancel = (event: SyntheticEvent) => {
    setRoles(memberRoles);
    setIsOpen(false);
    event.stopPropagation();
  };

  const handleChange = (event: SyntheticEvent, { value }: any) => {
    setRoles(value);
  };

  const actionsDisabled = () => (
    !roles.length || (roles.length === 1 && roles[0] === Roles.TREASURER)
  );

  return (
    <Modal
      className={styles.wrapper}
      onClose={toggleOpen}
      open={isOpen}
      trigger={<Dropdown.Item text={translate('EDIT_ROLE')} onClick={toggleOpen} />}
      size="tiny"
    >
      <h2>{translate('EDIT_ROLE')}</h2>

      <div className={styles.formWrapper}>
        <h3>
          {`${firstName} ${lastName}`}
        </h3>

        <Form.Field className={styles.field}>
          <label>{translate('ROLE')}</label>
          <Dropdown
            fluid
            multiple
            onChange={handleChange}
            options={rolesOptions}
            placeholder={translate('ROLE')}
            selection
            value={roles}
            className={styles.dropdown}
          />
        </Form.Field>
      </div>

      <ModalActions
        disabled={actionsDisabled()}
        onCancel={handleCancel}
        onSave={handleConfirm}
      />
    </Modal>
  );
};

export default EditRoleModal;
