import { isRequired } from '../../../../utils/formValidators';
import { FormSchema } from '../../../../models/Form';

export const createTeamSchema: FormSchema = ({
  teamName: {
    type: 'text',
    label: 'TEAM_NAME',
    required: true,
  },
});

export const validationSchema = {
  teamName: [isRequired],
};
