import Favicon from 'react-favicon';
import React, { FC, useMemo } from 'react';

import Sidebar from './components/Sidebar';
import styles from './Layout.module.scss';
import { LayoutProps } from './LayoutProps';
import { useViewport } from '../../hooks/useViewport';

const Layout: FC<LayoutProps> = ({
  children,
  clubLogoUrl,
  menuExpanded,
  menuItems,
  setMenuExpanded,
}) => {
  const { isMobile } = useViewport();
  const paddingCoveringSidebar = useMemo(() => {
    if (!isMobile) {
      return menuExpanded ? styles.paddingBig : styles.paddingSmall;
    }
    return '';
  }, [isMobile, menuExpanded]);

  return (
    <div className={[styles.wrapper, paddingCoveringSidebar].join(' ')}>
      {clubLogoUrl && <Favicon url={clubLogoUrl} />}

      {!isMobile && (
        <Sidebar
          clubLogoUrl={clubLogoUrl}
          menuExpanded={menuExpanded}
          menuItems={menuItems}
          setMenuExpanded={setMenuExpanded}
        />
      )}

      {children}
    </div>
  );
};

export default Layout;
