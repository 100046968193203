import moment from 'moment';
import {
  Match, MatchData, MatchPlace, MatchType,
} from '../../../models/Calendar';
import { TIME_FORMAT } from '../../../utils/dateUtils';
import { EventType } from '../../../constants/eventTypes';
import { getUpperCasedDay } from './TimePickerUtil';

export default (
  matchData: MatchData,
  teamId: number,
): Match => ({
  type: EventType.MATCH,
  name: `${matchData.homeTeamName} vs ${matchData.awayTeamName}`,
  match: {
    teamId,
    matchPlace: matchData.matchPlace || MatchPlace.HOME,
    matchType: matchData.matchType || MatchType.LEAGUE,
    oppositeClubName:
      (matchData.matchPlace === MatchPlace.HOME)
        ? (matchData.awayClubName || matchData.awayTeamName || '')
        : (matchData.homeTeamName || matchData.homeClubName || ''),
    oppositeTeamName:
      (matchData.matchPlace === MatchPlace.HOME)
        ? (matchData.awayTeamName || '') : (matchData.homeTeamName || ''),
    pitch: matchData.pitch,
    matchEndsAt: moment(matchData.endDateTime)
      .format(TIME_FORMAT),
    matchStartsAt: moment(matchData.startDateTime)
      .format(TIME_FORMAT),
  },
  interval: {
    type: 'WEEK',
    length: 1,
    startDate: moment(matchData.startDateTime).startOf('day').valueOf(),
    startDay: getUpperCasedDay(matchData.startDateTime),
    endDate: moment(matchData.startDateTime).endOf('day').valueOf(),
    startTime: moment(matchData.startDateTime)
      .format(TIME_FORMAT),
    endTime: moment(matchData.endDateTime)
      .format(TIME_FORMAT),
  },
});
