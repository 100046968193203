import { RouteComponentProps } from 'react-router';

import { OrderStatus } from '../../constants/orderStatus';
import {
  ChangeOrderStatusRequestAction,
  ClearOrderAction,
  InitializeOrderAction,
  LockNumbersAction,
  FetchTeamsRequestAction,
  SetSelectedStaffAction,
  SetSelectedPlayersAction,
} from '../../models/actions';
import { UsedNumbers } from '../../models/Order';

export enum OrderItemType {
  PRODUCT = 'PRODUCT',
  PLAYER = 'PLAYER',
  STAFF = 'STAFF',
}

export interface OrderPreparationProps extends RouteComponentProps{
  initializeOrder: (teamId: number, orderId?: number) => InitializeOrderAction;
  clearOrder: () => ClearOrderAction;
  changeStatus: (teamId: number, status: OrderStatus) => ChangeOrderStatusRequestAction;
  setSelectedStaff: (ids: number[]) => SetSelectedStaffAction;
  setSelectedPlayers: (ids: number[]) => SetSelectedPlayersAction;
  id?: number;
  loadingUpsert: boolean;
  match: any;
  selectedMembersTeam?: number | null;
  amountOfArticles: number;
  lockNumbers: (numbers: number[], usedNumbers?: UsedNumbers) => LockNumbersAction;
  fetchTeams: (payload: any) => FetchTeamsRequestAction;
  isTeamAvailable: boolean;
  teamLockedNumbers: number[],
  teamUsedNumbers: UsedNumbers,
  selectedPlayersIds: number[];
  selectedProductsIds: string[];
}
