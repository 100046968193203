import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import MembersList from './MembersList';
import { State } from '../../../../models/State';
import {
  fetchTeamIdRequest, fetchTeamsRequest, setLockNumbersRequest,
} from '../../../../actions/teams';
import { fetchPlayersRequest, setSelectedPlayers } from '../../../../actions/players';
import { fetchStaffRequest } from '../../../../actions/staff';

const mapStatesToProps = (
  {
    application: { clubId },
    teams,
    players,
    staff,
  }: State,
) => ({
  clubId,
  teamsById: teams.byId,
  teamsByHash: teams.byHash,
  loading: teams.loading,
  playersByHash: players.byHash,
  playersById: players.byId,
  playersLoading: players.loading,
  playerDeleted: players.playerDeleted,
  playersCount: players.count,
  selectedPlayers: players.selectedId,
  staffByHash: staff.byHash,
  editionSuccessful: teams.editionSuccessful,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchTeams: bindActionCreators(fetchTeamsRequest, dispatch),
  fetchPlayers: bindActionCreators(fetchPlayersRequest, dispatch),
  fetchStaff: bindActionCreators(fetchStaffRequest, dispatch),
  setSelectedPlayers: bindActionCreators(setSelectedPlayers, dispatch),
  fetchTeamId: bindActionCreators(fetchTeamIdRequest, dispatch),
  lockNumbers: bindActionCreators(setLockNumbersRequest, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(MembersList);
