import { Column } from '../../components/Table/Column';

export const playerColumns: Column[] = [{
  width: '30%',
  align: 'left',
  sortable: true,
  key: 'firstName',
}, {
  width: '15%',
  header: 'PREFFERED_TEAM_POSITION',
  align: 'left',
  key: 'preferredPosition',
}, {
  width: '10%',
  header: 'TEAMS_SHIRT_NUMBER',
  align: 'left',
  key: 'teamNumber',
  sortable: true,
}, {
  width: '10%',
  header: 'FAVOURITE_NUMBER',
  align: 'left',
  key: 'number',
  sortable: true,
}, {
  width: '10%',
  header: 'HEIGHT',
  align: 'left',
  key: 'height',
  sortable: true,
}, {
  width: '10%',
  header: 'FOOTEDNESS',
  align: 'left',
  key: 'footedness',
  sortable: true,
}, {
  width: '10%',
  header: 'BIRTH_DATE',
  align: 'left',
  key: 'dayOfBirth',
  sortable: false,
}, {
  width: '5%',
  header: '',
  align: 'right',
  key: 'redirect',
}];

export const staffColumns: Column[] = [{
  width: '90%',
  header: 'TEAM_MEMBER',
  align: 'left',
  key: 'firstName',
}, {
  width: '10%',
  header: '',
  align: 'right',
  key: 'redirect',
}];
