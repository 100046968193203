import { CLUB_ROUTE } from '../../../../constants/routes';
import insertIf from '../../../../utils/insertIf';
import { Column } from '../../../../components/Table/Column';

const columns = (ordersContext: string): Column[] => [{
  size: 1,
  align: 'left',
  key: 'id',
  sortable: true,
}, {
  size: 2,
  header: 'TEAM_NAME',
  align: 'left',
  key: 'teamName',
  sortable: true,
}, {
  size: 2,
  header: 'STATUS',
  align: 'left',
  key: 'status',
  sortable: true,
}, ...(insertIf(ordersContext === CLUB_ROUTE, {
  size: 1,
  header: 'ACCEPTED_BY',
  align: 'left',
  key: 'acceptedBy',
  sortable: true,
})), {
  size: 1,
  header: 'TOTAL',
  align: 'right',
  key: 'price',
  sortable: true,
}, {
  size: 4,
  header: '',
  align: 'left',
  key: 'actionbuttons',
  sortable: false,
}, {
  width: '55px',
  header: '',
  align: 'right',
  key: 'redirect',
  sortable: false,
}];

export default columns;
