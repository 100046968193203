import get from 'lodash/get';

import { Price } from '../../models/Price';
import { TeamDiscountsType } from '../../models/Catalog';
import { calculatePrice } from '../PricingUtil';

const calculateProductPrice = (
  teamId: number | null,
  teamProductDiscounts: TeamDiscountsType[],
  productDiscountPercent: number | null,
  catalogDiscountPercent: number,
  price: Price,
) => {
  const teamDiscount = teamProductDiscounts
    .find(({ teamId: id }) => teamId === id);
  const discount = productDiscountPercent !== null
    ? productDiscountPercent
    : get(teamDiscount, 'discountPercent', catalogDiscountPercent);

  return calculatePrice(price.amount, discount);
};

export default calculateProductPrice;
