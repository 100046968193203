import calculateEmbellishmentsPrice from './calculateEmbellishmentsPrice';
import calculateProductPrice from './calculateProductPrice';
import { Catalog } from '../../models/Catalog';
import { Embellishment, ProductEmbellishment } from '../../models/Embellishment';
import { ClubTeamPrice, Price } from '../../models/Price';
import { Team } from '../../models/Team';
import { sumPrices } from '../PricingUtil';

const calculateProductPricesForTeams = (
  clubId: number,
  teams: Team[],
  embellishments: Embellishment[],
  price: Price,
  productDiscountPercent: number | null,
  catalog?: Catalog,
  productEmbellishments?: ProductEmbellishment[],
) => {
  const {
    productDiscountPercent: catalogDiscountPercent = 0,
    teamProductDiscounts = [],
  } = catalog || {};

  const enrichPrice = (id: number, name?: string) => {
    const discountedPriceAmount = calculateProductPrice(
      id,
      teamProductDiscounts,
      productDiscountPercent,
      catalogDiscountPercent,
      price,
    );

    const embellishmentsPrice = calculateEmbellishmentsPrice(
      clubId,
      id,
      embellishments,
      catalog,
      productEmbellishments,
    );

    return {
      id,
      name,
      price: {
        ...price,
        amount: sumPrices([discountedPriceAmount, embellishmentsPrice]),
      },
    };
  };

  const clubPrice: ClubTeamPrice = enrichPrice(clubId);
  const teamsPrice: ClubTeamPrice[] = teams
    .map(({ id, name }) => enrichPrice(id, name))
    .filter(team => team.price.amount !== clubPrice.price.amount);
  const showClubPrice = teams.length !== teamsPrice.length;

  return {
    clubPrice: showClubPrice ? clubPrice : null,
    teamsPrice,
  };
};

export default calculateProductPricesForTeams;
