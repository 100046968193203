import React, { MouseEvent } from 'react';
import get from 'lodash/get';
import { useParams } from 'react-router-dom';
import {
  Button, Checkbox, Image,
} from 'semantic-ui-react';

import PriceDiscountsPopup from '../../../../../ProductDetails/components/PriceDiscountsPopup';
import Row from '../../../../../../components/Table/components/Row';
import Text from '../../../../../../components/Text';
import styles from './MobileProductsRow.module.scss';
import useImage from '../../../../../../hooks/useImage';
import { Embellishment } from '../../../../../../models/Embellishment';
import { MobileProductsRowProps } from './MobileProductsRowProps';

const MobileProductsRow = ({
  product: {
    id, name, files, originalImages, originalPrice, discountPercent,
    embellishments: productEmbellishments,
  },
  clubId,
  checked,
  embellishmentsByHash,
  toggleSelection,
  fetchFile,
  filesByHash,
  article: {
    embellishments = [],
  },
  showArticleDetails,
}: MobileProductsRowProps) => {
  const [img] = useImage(files, originalImages, filesByHash, fetchFile);
  const teamId = +get(useParams(), 'teamId');
  const itemEmbellishments: Embellishment[] = productEmbellishments
    .filter(({ embellishment }) => embellishments.some(({ type }) => type === embellishment))
    .map(({ embellishment }) => embellishmentsByHash[embellishment]);

  const onClick = (e: MouseEvent) => {
    e.stopPropagation();
    toggleSelection(id);
  };

  return (
    <Row key={id} classNames={styles.wrapper} itemOnClick={showArticleDetails}>
      <div className={styles.article}>
        <Checkbox onClick={onClick} checked={checked} />
        <div className={styles.productDetails}>
          <div className={styles.img}>
            {!!img && (
              <Image src={img} className={styles.image} />
            )}
          </div>
          <div className={styles.nameAndPrice}>
            <span className={styles.articleName}>{name}</span>
            {!clubId ? <Text /> : (
              <PriceDiscountsPopup
                clubId={clubId}
                teamId={teamId}
                productEmbellishments={productEmbellishments}
                embellishments={itemEmbellishments}
                price={originalPrice}
                productDiscountPercent={discountPercent}
                underline={false}
              />
            )}
          </div>
        </div>
      </div>
      <Button basic primary className="simple" icon="angle right" onClick={showArticleDetails} />
    </Row>
  );
};

export default MobileProductsRow;
