import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import PlayerProfile from './PlayerProfile';
import { fetchPlayerRequest } from '../../actions/players';
import { State } from '../../models/State';
import { PlayerProfileProps } from './PlayerProfileProps';
import { sendInvitationRequest } from '../../actions/invitations';
import { deleteMembersRequest } from '../../actions/members';

const mapStateToProps = (
  { application: { clubId, userRole }, players: { byHash, loading } }: State,
  { match: { params: { playerId } } }: PlayerProfileProps,
) => ({
  userRole,
  loading,
  player: byHash[playerId],
  clubId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchPlayer: bindActionCreators(fetchPlayerRequest, dispatch),
  deletePlayer: bindActionCreators(deleteMembersRequest, dispatch),
  sendInvitation: bindActionCreators(sendInvitationRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayerProfile);
