import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import State from '../../models';
import { validateHobbyTeamTokenRequest } from '../../actions/application';
import HobbyTeamWelcome from './HobbyTeamWelcome';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  validateHobbyTeamToken: bindActionCreators(validateHobbyTeamTokenRequest, dispatch),
});

const mapStateToProps = ({
  clubs: { logoUrl },
  application: { error: serverError, hobbyTeamTokenDetails: tokenDetails, loading },
  user: { user },
}: State) => ({
  clubLogoUrl: logoUrl,
  serverError,
  loading,
  userFirstName: (tokenDetails && tokenDetails.firstName) || (user && user.firstName) || '',
});

export default connect(mapStateToProps, mapDispatchToProps)(HobbyTeamWelcome);
