import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { State } from '../../../../models/State';
import StaffList from './StaffList';
import { addItemsForSelectedStaffMembers, selectStaff, addItemsForPlayers } from '../../../../actions/orderEditor';

const mapStatesToProps = ({
  staff: {
    byHash, byId, loading,
  },
  orders: {
    itemsById, itemsByHash, selectedStaffIds, selectedPlayersIds,
  },
}: State) => ({
  loading,
  byHash,
  itemsById,
  itemsByHash,
  byId,
  selectedStaff: selectedStaffIds,
  selectedPlayers: selectedPlayersIds,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addItemsForStaff: bindActionCreators(addItemsForSelectedStaffMembers, dispatch),
  addItemsForPlayers: bindActionCreators(addItemsForPlayers, dispatch),
  selectStaff: bindActionCreators(selectStaff, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(StaffList);
