import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import OrdersMobileRow from './OrdersMobileRow';
import { deleteOrdersRequest, setStatus } from '../../../../../../actions/orders';
import { fetchFileRequest } from '../../../../../../actions/files';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setStatus: bindActionCreators(setStatus, dispatch),
  fetchFile: bindActionCreators(fetchFileRequest, dispatch),
  deleteOrders: bindActionCreators(deleteOrdersRequest, dispatch),
});

export default connect(null, mapDispatchToProps)(OrdersMobileRow);
