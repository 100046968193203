import { ERROR, UNAUTHORIZED } from '../constants/actionTypes';

export const handleError = error => ({
  type: ERROR,
  payload: {
    error,
  },
});

export const unauthorized = () => ({
  type: UNAUTHORIZED,
});
