import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './HeaderTag.module.scss';

interface HeaderTagProps {
  serverError?: boolean;
  successTitle?: string | null;
  errorTitle?: string | null;
}

const HeaderTag: FC<HeaderTagProps> = ({ serverError, successTitle = 'GOAL_EXCLMATION', errorTitle = 'FOUL' }) => {
  const [translate] = useTranslation();

  return (
    <>
      <div className={[styles.headerLabelWrapper, serverError ? styles.errorState : ''].join(' ')}>
        <p className={styles.headerLabel}>
          { /* @ts-ignore */}
          {translate(!serverError ? successTitle : errorTitle)}
        </p>
      </div>
    </>
  );
};

export default HeaderTag;
