import { OrderResponse } from '../../../../models/Order';
import { ItemEmbellishment, ProductEmbellishment } from '../../../../models/Embellishment';
import { ProductsByHash } from '../../../../models/Catalog';
import { EmbellishmentGroup } from '../../../../containers/OrderPreparation/components/MemberDetails/embellishmentTypes';

const prepareEmbellishments = (
  embellishments: ItemEmbellishment[],
  productCatalogEmbellishments: ProductEmbellishment[],
) => embellishments.map(e => {
  const catalogMatch = productCatalogEmbellishments.find(pce => pce.embellishment === e.type);

  let value = null;
  if (catalogMatch) {
    value = e.value;

    // This is important because api always returns null as
    // boolean type embellishment value. Just existance of that
    // embellishment in order item's embellishments list means that
    // it was selected
    if (EmbellishmentGroup.BOOLEAN_TYPES.includes(e.type)) {
      value = true;
    }
  }
  return ({
    ...e,
    type: e.type,
    mandatory: e.mandatory,
    value,
  });
});

export const deserializeOrders = (
  { items, ...order }: OrderResponse,
  productsByHash: ProductsByHash,
): OrderResponse => ({
  ...order,
  items: items.map(
    ({ embellishments: itemEmbellishments, ...item }) => {
      const productCatalogEmbellishments = productsByHash[item.productCatalogId].embellishments;
      return ({
        ...item,
        embellishments: prepareEmbellishments(itemEmbellishments, productCatalogEmbellishments),
      });
    },
  ),
});
