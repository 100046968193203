enum Seasons {
  PERMANENT = 'PERMANENT',
  S_2022 = 'S_2022',
  S_2022_23 = 'S_2022_23',
  S_2023 = 'S_2023',
  S_2023_24 = 'S_2023_24',
  S_2024 = 'S_2024',
  S_2024_25 = 'S_2024_25',
  S_2025 = 'S_2025',
  S_2025_26 = 'S_2025_26',
  S_2026 = 'S_2026',
  S_2026_27 = 'S_2026_27',
  S_2027 = 'S_2026_27',
}

export default Seasons;
