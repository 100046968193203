import { UserReducer } from '../models/State';
import { AuthenticationActions, UserActions } from '../models/actions';
import { SIGN_OUT_REQUEST, UNAUTHORIZED } from '../packages/authentication';
import {
  FETCH_USER_INFO_SUCCESS,
  SET_USER,
  UPDATE_USER_SUCCESS,
  UPLOAD_AVATAR_ERROR,
  UPLOAD_AVATAR_REQUEST,
  UPLOAD_AVATAR_SUCCESS,
} from '../constants/actionTypes';

const initialState: UserReducer = {
  user: null,
  loadingUpload: false,
  infoFetched: false,
};

export default (
  state = initialState,
  action: UserActions | AuthenticationActions,
) => {
  switch (action.type) {
    case UPDATE_USER_SUCCESS:
    case FETCH_USER_INFO_SUCCESS: {
      const { user } = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          ...user,
        },
        infoFetched: true,
      };
    }
    case SET_USER: {
      const { user } = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          ...user,
        },
      };
    }
    case UPLOAD_AVATAR_REQUEST:
      return {
        ...state,
        loadingUpload: true,
      };
    case UPLOAD_AVATAR_SUCCESS:
    case UPLOAD_AVATAR_ERROR:
      return {
        ...state,
        loadingUpload: false,
      };
    case SIGN_OUT_REQUEST:
    case UNAUTHORIZED:
      return initialState;
    default:
      return state;
  }
};
