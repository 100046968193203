import { Actions } from '../models/actions';
import {
  DFB_CLUBS_ERROR,
  DFB_CLUBS_REQUEST,
  DFB_CLUBS_SUCCESS, EXTERNAL_TEAMS_ERROR,
  EXTERNAL_TEAMS_REQUEST, EXTERNAL_TEAMS_SUCCESS,
  FETCH_TOR24_MATCHES_ERROR,
  FETCH_TOR24_MATCHES_REQUEST,
  FETCH_TOR24_MATCHES_SUCCESS,
  TOR24_TEAMS_ERROR,
  TOR24_TEAMS_REQUEST,
  TOR24_TEAMS_SUCCESS,
} from '../constants/actionTypes';
import {
  ExternalClubsByHash,
  ExternalMatchesByHash,
  ExternalSystemsReducer,
  Tor24TeamsByHash,
  OfficialClubDto, ExternalTeamsByHash, ExternalTeam,
} from '../models/externalSystemIntegration/externalSystemReference';
import { Tor24Team } from '../models/externalSystemIntegration/tor24Team';
import { SIGN_OUT_ERROR, SIGN_OUT_SUCCESS } from '../packages/authentication/constants';
import { MatchData } from '../models/Calendar';

export const initialState: ExternalSystemsReducer = {
  loading: false,
  tor24TeamsByHash: {},
  tor24TeamsById: [],
  externalMatchesByHash: {},
  externalMatchesById: [],
  externalClubByHash: {},
  externalClubById: [],
  externalTeamsByHash: {},
  externalTeamsById: [],
  error: false,
};

export default (state = initialState, action : Actions) => {
  switch (action.type) {
    case TOR24_TEAMS_REQUEST:
    case EXTERNAL_TEAMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TOR24_TEAMS_SUCCESS: {
      const teams = action.payload.teams.response;
      return {
        ...state,
        tor24TeamsByHash: teams
          .reduce((byHash: Tor24TeamsByHash, team: Tor24Team) => ({
            ...byHash,
            [team.id]: team,
          }), state.tor24TeamsByHash),
        tor24TeamsById: teams.map(({ id }: Tor24Team) => id),
        loading: false,
      };
    }
    case TOR24_TEAMS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case FETCH_TOR24_MATCHES_SUCCESS: {
      const { payload: { matches } } = action;

      return {
        ...state,
        loading: false,
        externalMatchesByHash: matches
          .reduce((byHash: ExternalMatchesByHash, match: MatchData) => ({
            ...byHash,
            [match.externalId!]: match,
          }), state.externalMatchesByHash),
        externalMatchesById: matches.map(({ externalId }: MatchData) => externalId),
      };
    }
    case FETCH_TOR24_MATCHES_REQUEST:
      return {
        ...state,
        loading: true,
        tor24TeamsById: [],
        tor24TeamsByHash: {},
      };
    case FETCH_TOR24_MATCHES_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case DFB_CLUBS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DFB_CLUBS_SUCCESS: {
      const { clubs } = action.payload;
      return {
        ...state,
        externalClubByHash: clubs
          .reduce((byHash: ExternalClubsByHash, club: OfficialClubDto) => ({
            ...byHash,
            [club.id]: club,
          }), state.externalClubByHash),
        externalClubById: clubs.map(({ id }: OfficialClubDto) => id),
        loading: false,
      };
    }
    case DFB_CLUBS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case EXTERNAL_TEAMS_SUCCESS: {
      const { teams } = action.payload;
      return {
        ...state,
        externalTeamsByHash: teams
          .reduce((byHash: ExternalTeamsByHash, team: ExternalTeam) => ({
            ...byHash,
            [team.id]: team,
          }), state.externalTeamsByHash),
        externalTeamsById: teams.map(({ id }: ExternalTeam) => id),
        loading: false,
      };
    }
    case EXTERNAL_TEAMS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SIGN_OUT_SUCCESS:
    case SIGN_OUT_ERROR:
      return initialState;
    default:
      return state;
  }
};
