import React, {
  FC, useEffect, useState, useMemo,
} from 'react';
import moment from 'moment';
import { Button, Loader } from 'semantic-ui-react';
import { Calendar as BigCalendar, DateRange } from 'react-big-calendar';
import { useLocalStorage } from '@rehooks/local-storage';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import EventCell from './components/EventCell';
import FileUploadModal, { FileUploadTypes } from '../../components/FileUploadModal';
import Header from '../../components/Header';
import MatchEventModal from './components/MatchEventModal';
import NewEventModal from './components/NewEventModal';
import TeamsTabs from '../../components/TeamsTabs';
import TrainingEventModal from './components/TrainingEventModal';
import styles from './Calendar.module.scss';
import { CalendarProps } from './CalendarProps';
import { CellType } from './components/EventCell/EventCellProps';
import { EventType } from '../../constants/eventTypes';
import { TIME_FORMAT } from '../../utils/dateUtils';
import { TEMPLATE_EVENT_XLSX_FILE, UPLOAD_EVENTS_EXTENSIONS } from '../../constants/customizations';
import { localizer } from '../../config';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CalendarOverrides.scss';
import PreviewCSVUpload from './components/PreviewCSVUpload';
import { CALENDAR_ROUTE } from '../../constants/routes';
import filterTeamsBasedOnRole from '../../utils/roleBasedTeamsFilter';
import OneTimeEventModal from './components/OneTimeEventModal';
import ImportMatchModal from './components/ImportMatchModal';
import { ExternalSystemName } from '../../models/externalSystemIntegration';

const Calendar: FC<CalendarProps> = ({
  teamsById,
  teamsByHash,
  fetchTeams,
  clubId,
  teamId,
  fetchEvent,
  fetchEvents,
  eventCreated,
  eventRemoved,
  eventsById,
  resetEvent,
  setApplicationParameter,
  loadingEvents,
  loadingTeams,
  eventsByHash,
  uploadEvents,
  previewCSVEvent,
  previewEvent = null,
  loadingPreview,
  error,
  authUser,
  userRole,
}) => {
  const [activeEventType, setActiveEventType] = useState<EventType | null>(null);
  const [teamConnectedWithTor24, setTeamConnectedWithTor24] = useState(false);
  const [landingModal, setLandingModal] = useState(false);
  const [importGamesModalOpened, setImportGamesModalOpened] = useState(false);
  const [activeSlot, setActiveSlot] = useState<Date | undefined>();
  const [translate] = useTranslation();
  const [lang] = useLocalStorage('i18nextLng');

  useMemo(() => {
    if (teamId) {
      const activeTeam = teamsByHash[teamId];
      if (activeTeam) {
        setTeamConnectedWithTor24(!!(activeTeam.externalSystemReferences || [])
          .find(ref => ref.externalSystemName === ExternalSystemName.TOR_24));
      }
    }
  }, [teamId, teamsByHash]);

  useEffect(() => {
    setApplicationParameter({ teamId });
  }, [teamId, setApplicationParameter]);

  useEffect(() => {
    if (clubId) {
      fetchTeams({ pagination: false });
    }
  }, [fetchTeams, clubId]);

  useEffect(() => {
    if (eventCreated || eventRemoved) {
      setActiveEventType(null);
      setLandingModal(false);
      resetEvent();
    }
  }, [eventCreated, eventRemoved, resetEvent]);

  useEffect(() => {
    if (teamId) {
      fetchEvents(teamId, moment().startOf('month').toDate(), moment().endOf('month').toDate());
    }
  }, [teamId, fetchEvents]);

  const handleSelectSlot = ({ start }: any) => {
    setActiveSlot(start);
    setLandingModal(true);
  };

  const handleOpenModal = () => {
    setActiveSlot(undefined);
    setLandingModal(true);
  };

  const handleEventClick = (id: number, type: EventType) => () => {
    if (type !== EventType.BIRTH_DATE) {
      fetchEvent(id);
      setActiveEventType(type);
    }
  };

  const closeModals = (nextType?: EventType) => () => {
    setActiveEventType(nextType || null);
    resetEvent();
    setLandingModal(false);
  };

  const importSeasonGamesClicked = (opened: boolean) => () => {
    setImportGamesModalOpened(opened);
  };

  const roleBasedTeamIds = useMemo(
    () => filterTeamsBasedOnRole(authUser, userRole, teamsById),
    [authUser, teamsById, userRole],
  );

  const renderNewEventButton = () => [
    teamConnectedWithTor24 ? (
      <Button
        key="import-season-games"
        basic
        content={translate('IMPORT_SEASON_GAMES')}
        onClick={importSeasonGamesClicked(true)}
        primary
      />
    ) : null,
    <FileUploadModal
      extensions={UPLOAD_EVENTS_EXTENSIONS}
      key="button1"
      loading={loadingEvents}
      type={FileUploadTypes.XLSX}
      uploadFile={file => teamId && uploadEvents(teamId, file)}
      requestPreview={file => teamId && previewCSVEvent(teamId, file)}
      previewNode={() => <PreviewCSVUpload event={previewEvent} loading={loadingPreview} />}
      secondaryAction={() => (
        <a href={TEMPLATE_EVENT_XLSX_FILE} download>
          {translate('DOWNLOAD_TEMPLATE_FILE')}
        </a>
      )}
      error={error}
      triggerButton={onClick => (
        <Button
          basic
          content={translate('MATCH_BULK_UPLOAD')}
          disabled={!teamId}
          onClick={onClick}
          primary
        />
      )}
    />,
    <Button
      content={translate('CREATE_NEW_EVENT')}
      disabled={!teamId}
      key="button2"
      onClick={handleOpenModal}
      primary
    />,
  ];

  const renderModals = () => teamId && (
    <>
      { landingModal && (
        <NewEventModal
          activeTeam={teamId}
          setLandingModal={setLandingModal}
          activeEventType={activeEventType}
          setActiveEventType={setActiveEventType}
        />
      )}
      { !landingModal && activeEventType === EventType.TRAINING && (
        <TrainingEventModal
          onClose={closeModals()}
          teamId={teamId}
          activeSlot={activeSlot}
        />
      )}
      { !landingModal && activeEventType === EventType.MATCH && (
        <MatchEventModal
          onClose={closeModals()}
          teamId={teamId}
          activeSlot={activeSlot}
        />
      )}
      { !landingModal && activeEventType === EventType.ONE_TIME_EVENT && (
        <OneTimeEventModal
          onClose={closeModals()}
          teamId={teamId}
          activeSlot={activeSlot}
        />
      )}
      { importGamesModalOpened && (
        <ImportMatchModal
          setModalState={setImportGamesModalOpened}
          teamId={teamId}
        />
      )}
    </>
  );

  return (
    <div className={styles.wrapper}>
      <Header
        title={translate('TEAM_CALENDAR')}
        // @ts-ignore
        renderButton={renderNewEventButton}
      />
      <div className={styles.contentWrapper}>
        <TeamsTabs
          activeTeam={teamId}
          teams={roleBasedTeamIds.map(id => teamsByHash[id])}
          route={CALENDAR_ROUTE}
        />
        <Loader active={(loadingTeams || loadingEvents)} />
        <BigCalendar
          localizer={localizer}
          popup
          selectable
          views={['month', 'day']}
          className={loadingEvents ? styles.dimmed : ''}
          events={
            eventsById
              .map(id => {
                const {
                  name, startDateTime, endDateTime, type, ...rest
                } = eventsByHash[id] || {} as EventType;
                return ({
                  title: name,
                  start: startDateTime,
                  end: endDateTime,
                  type,
                  ...(type === EventType.BIRTH_DATE.toString() && {
                    allDay: true,
                    start: moment(startDateTime.getTime()).startOf('day').toDate(),
                    end: moment(startDateTime.getTime()).endOf('day').toDate(),
                  }),
                  ...rest,
                  id: id || uuidv4(),
                });
              })
          }
          onRangeChange={({ start, end }: any) => {
            if (teamId && start && end) {
              fetchEvents(teamId, start, end);
            }
          }}
          formats={{
            dayHeaderFormat: (date: Date) => {
              if (lang) {
                moment.locale(lang);
              }
              return moment(date).format('ll');
            },
            timeGutterFormat: (date: Date) => moment(date).format(TIME_FORMAT),
            eventTimeRangeFormat: ({ start, end }: DateRange) => `${moment(start).format(TIME_FORMAT)} - ${moment(end).format(TIME_FORMAT)}`,
          }}
          messages={{
            month: translate('MONTH'),
            day: translate('DAY'),
            showMore: (num: number) => `+${num} ${translate('MORE')}`,
          }}
          onSelectSlot={handleSelectSlot}
          components={{
            month: {
              event: ({ event }: any) => (
                <EventCell
                  event={event}
                  team={teamId ? teamsByHash[teamId] : null}
                  onOpen={handleEventClick(event.id, event.type)}
                  cellType={CellType.MONTH}
                />
              ),
              dateHeader: (eventData: any) => (
                <div>{Number(eventData.label)}</div>
              ),
            },
            day: {
              event: ({ event }: any) => (
                <EventCell
                  event={event}
                  team={teamId ? teamsByHash[teamId] : null}
                  onOpen={handleEventClick(event.id, event.type)}
                  cellType={CellType.DAY}
                />
              ),
            },
          }}
        />
      </div>
      {renderModals()}
    </div>
  );
};

export default Calendar;
