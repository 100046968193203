import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { DispatchProps, StateProps } from './CreateMemberProps';
import { State } from '../../../../models/State';
import { fetchTeamsRequest } from '../../../../actions/teams';
import CreateMember from './CreateMember';
import { sendInvitationRequest } from '../../../../actions/invitations';
import { createPlayerRequest } from '../../../../actions/players';
import { checkEmailAvailabilityRequest } from '../../../../actions/application';

const mapStatesToProps = (state: State): StateProps => {
  const {
    players: {
      error,
      loading,
    },
    teams,
    application: {
      clubId, emailAvailable, checkingEmail,
    },
  } = state;

  return ({
    loading,
    byHash: teams.byHash,
    byId: teams.byId,
    error,
    clubId,
    emailAvailable,
    checkingEmail,
  });
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  sendInvitation: bindActionCreators(sendInvitationRequest, dispatch),
  fetchTeams: bindActionCreators(fetchTeamsRequest, dispatch),
  createPlayer: bindActionCreators(createPlayerRequest, dispatch),
  checkEmail: bindActionCreators(checkEmailAvailabilityRequest, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(CreateMember);
