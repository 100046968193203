import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button, Header, Loader, Popup,
} from 'semantic-ui-react';

import ProfileAvatar from '../../../PlayerProfile/components/ProfileAvatar';
import styles from './AvatarWithPopup.module.scss';
import { AvatarWithPopupProps } from './AvatarWithPopupProps';
import { TeamLeadTeamList } from '../../../../models/Team';

const AvatarWithPopup: FC<AvatarWithPopupProps> = ({
  id, user, linkTo, teamId, playersById, playersByHash,
  fetchPlayer, italic, loading, customClickHandler, buttonText,
  playerId,
}) => {
  const [translate] = useTranslation();
  const history = useHistory();
  const player = playerId && playersByHash[playerId];

  const handleMount = () => {
    if (teamId && playerId && playersById.indexOf(playerId) === -1) {
      fetchPlayer(teamId, playerId);
    }
  };

  const defaultHandleClick = (event: React.MouseEvent) => {
    if (linkTo) {
      history.push(linkTo as string);
      event.stopPropagation();
    }
  };

  return (
    <Popup
      flowing
      hoverable
      key={id}
      position="bottom left"
      trigger={<ProfileAvatar user={user} italic={italic} />}
      onMount={handleMount}
      popperDependencies={[user, player]}
    >
      <div className={styles.popupContent}>
        <ProfileAvatar user={user} size="medium" italic={italic} />
        <div>
          <Header as="h3" content={`${user.firstName} ${user.lastName}`} />
          {!player && (
            <>
              <h4 className={styles.sectionHeader}>{translate('CONTACT_INFO.DETAILS')}</h4>
              <span>{(user as TeamLeadTeamList).email}</span>
            </>
          )}
          {player ? (
            <>
              <em key="nickname">{player.nickname}</em>
              {(player.phoneNumber || player.email) && (
                <>
                  <h4 className={styles.sectionHeader}>{translate('CONTACT_INFO.DETAILS')}</h4>
                  <span>{player.phoneNumber}</span>
                  <span>{player.email}</span>
                </>
              )}
              {(player.emergencyContact) && (
                <>
                  <h4 className={styles.sectionHeader}>{translate('EMERGENCY_CONTACT.TITLE')}</h4>
                  <span>{player.emergencyContact.name || ''}</span>
                  <span>{player.emergencyContact.phoneNumber || ''}</span>
                </>
              )}
            </>
          ) : (
            loading && <Loader active inline className={styles.loader} />
          )}

          {(customClickHandler || linkTo) && (
            <Button
              basic
              compact
              content={buttonText || translate('VIEW_PROFILE')}
              floated="right"
              primary
              size="mini"
              onClick={customClickHandler || defaultHandleClick}
            />
          )}
        </div>
      </div>
    </Popup>
  );
};

export default AvatarWithPopup;
