import React, { FC } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Button, Header, Popup, Icon,
} from 'semantic-ui-react';

import styles from './EventCell.module.scss';
import { CellType, EventCellProps } from './EventCellProps';
import { EventType } from '../../../../constants/eventTypes';
import { TIME_FORMAT } from '../../../../utils/dateUtils';
import { EventStatus } from '../../../../models/Calendar';

const EventCell: FC<EventCellProps> = ({
  event: {
    start, end, title, type, match, meetingDate, location, status,
  },
  onOpen,
  team,
  cellType,
}) => {
  const [translate] = useTranslation();
  const getTime = (date: Date) => moment(date).format(TIME_FORMAT);
  const {
    awayTeamName = '',
    homeTeamName = '',
    matchPlace = '',
    matchType = '',
  } = match || {};

  const opponentTeamName = matchPlace === 'HOME' ? awayTeamName : homeTeamName;
  const teamName = team ? team.name : '';

  const renderRow = (key: string, value: string) => (
    <div className={styles.row} key={key}>
      <span>{`${translate(key)}: `}</span>
      <span>{value}</span>
    </div>
  );

  const renderButton = () => (
    <Button
      basic
      className={styles.actionButton}
      compact
      content={translate('VIEW_EVENT')}
      onClick={onOpen}
      primary
      size="small"
    />
  );

  const renderGenericPopup = () => (
    <div className={styles.popupContainer}>
      <small>{moment(start).format('dddd DD MMMM')}</small>
      <Header as="h3" className={styles.header} content={title} />
      <div>{teamName}</div>

      <br />

      {location && location.displayName && renderRow('ADDRESS', location.displayName)}
      {renderRow('TIME', `${getTime(start)} ${translate('TO')} ${getTime(end)}`)}

      <br />

      {!!meetingDate && renderRow('MEETING_TIME', getTime(meetingDate))}

      {renderButton()}
    </div>
  );

  const renderMatchPopup = () => (
    <div className={styles.popupContainer}>
      <small>{moment(start).format('dddd DD MMMM')}</small>
      <Header
        as="h3"
        className={styles.header}
        content={translate(matchType)}
      />

      <div>
        {`${teamName} (${matchPlace[0]}) ${translate('VS')} ${opponentTeamName}`}
      </div>

      <br />

      {match && match.location && match.location.displayName && renderRow('PLAYING_SITE', match.location.displayName)}
      {renderRow('KICK_OFF', getTime(start))}

      <br />

      {!!meetingDate && renderRow('MEETING_TIME', getTime(meetingDate))}
      {location && renderRow('MEETING_POINT', location.displayName)}
      {renderButton()}
    </div>
  );

  const renderEventText = () => {
    switch (type) {
      case EventType.TRAINING:
        return title;
      case EventType.MATCH:
        return opponentTeamName;
      case EventType.BIRTH_DATE:
        return title;
      case EventType.ONE_TIME_EVENT:
        return teamName;
      default:
        return '';
    }
  };

  const renderTitlePartRight = () => {
    switch (type) {
      case EventType.TRAINING:
        return `TR ${teamName}`;
      case EventType.MATCH:
        return `SP ${teamName} (${matchPlace[0]})`;
      case EventType.BIRTH_DATE:
        return teamName;
      case EventType.ONE_TIME_EVENT:
        return title;
      default:
        return '';
    }
  };

  const renderTitlePartLeft = () => {
    if (type === EventType.BIRTH_DATE) {
      return <strong>{`${translate('BIRTH_DATE')} - `}</strong>;
    }
    if (cellType === CellType.MONTH) {
      return <strong>{`${getTime(start)} - `}</strong>;
    }
    return null;
  };

  const renderTrigger = () => (
    <div className={styles[`eventCell_${cellType}`]}>
      {status === EventStatus.CANCELED && (
        <div className={styles.cancelLabel}>
          <Icon name="bell outline" />
          This event has been canceled
        </div>
      )}
      {renderTitlePartLeft()}
      <span className={styles.eventTitle}>
        {renderTitlePartRight()}
      </span>
      <span className={styles.eventText}>
        {renderEventText()}
      </span>
    </div>
  );

  return (
    <Popup
      hoverable
      trigger={renderTrigger()}
      disabled={type === EventType.BIRTH_DATE}
      content={type === EventType.MATCH ? renderMatchPopup() : renderGenericPopup()}
    />
  );
};

export default EventCell;
