import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './MobileApps.module.scss';

export interface MobileAppsProps {
  title?: string;
}

const MobileApps: FC<MobileAppsProps> = ({ title = 'GET_APP_MESSAGE' }) => {
  const [translate] = useTranslation();

  return (
    <div className={styles.wrapper}>
      <h3 className={styles.getAppMessage}>{translate(title)}</h3>
      <div className={styles.linksWrapper}>
        <a className={styles.iosLogo} href={translate('HOC_APPLE_STORE_APP_URL')}>
          <img src="/images/app-store-download.svg" alt="ios download" />
        </a>
        <a className={styles.googlePlayLogo} href={translate('HOC_PLAY_STORE_APP_URL')}>
          <img src="/images/google-play-download.png" alt="play download" />
        </a>
      </div>
    </div>
  );
};

export default MobileApps;
