import * as BigCalendar from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/de';

moment.locale('en-new', {
  parentLocale: 'en',
  week: {
    dow: 1,
    doy: 1,
  },
});
moment.locale(['en-new', 'de']);
// @ts-ignore
moment.locale((navigator.userLanguage || navigator.language).slice(0, 2));
export const localizer = BigCalendar.momentLocalizer(moment);

/* eslint-disable */
// @ts-ignore
const environmentConfig = window.__APP_CONFIG__ || {};
const API_URL = process.env.REACT_APP_API_URL || environmentConfig.apiUrl;
const EXTERNAL_CLUBS_URL = process.env.REACT_APP_EXTERNAL_CLUBS_URL || environmentConfig.externalClubsUrl;

export default {API_URL, EXTERNAL_CLUBS_URL};
