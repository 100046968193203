import { EmbellishmentTypes } from '../../../../models/Embellishment';
import { Item } from '../../../../models/Order';
import { ProductsByHash } from '../../../../models/Catalog';

export default (items: Item[], productsByHash: ProductsByHash = {}): string[] => (
  items.map(item => {
    const missingFields = [];
    const {
      productCatalogId, size, embellishments,
    } = item;
    const { availableSizes = [] } = productsByHash[productCatalogId] || {};

    const getEmbellishmentWithType = (type: EmbellishmentTypes) => embellishments
      .find(embellishment => embellishment.type === type);

    const numberEmbellishment = getEmbellishmentWithType(EmbellishmentTypes.BACK_NUMBER);
    const playerNameEmbellishment = getEmbellishmentWithType(EmbellishmentTypes.PLAYER_NAME);

    if (numberEmbellishment && !numberEmbellishment.value) {
      missingFields.push('number');
    }

    if (playerNameEmbellishment && !playerNameEmbellishment.value) {
      missingFields.push('backLabel');
    }

    if (availableSizes.filter(Boolean).length && !size) {
      missingFields.push('size');
    }

    return missingFields;
  }).flat().filter(Boolean)
);
