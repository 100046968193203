import {
  catchError, map, mergeMap, tap, withLatestFrom,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';

import { signOutError, signOutSuccess } from '../actions/signOut';
import { handleError } from '../actions/error';
import { SIGN_OUT_REQUEST } from '../constants/actionTypes';
import { SIGN_OUT_PATH } from '../constants/paths';

const signOutRequest = baseUrl => (action$, state$, { ajax, localStorage }) => action$.pipe(
  ofType(SIGN_OUT_REQUEST),
  withLatestFrom(state$),
  mergeMap(([, { authentication: { signOutAuthToken } }]) => ajax({
    url: `${baseUrl}${SIGN_OUT_PATH}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authToken: signOutAuthToken,
    },
  }).pipe(
    map(signOutSuccess),
    tap(() => localStorage.clear()),
    catchError((error) => {
      localStorage.clear();
      return of(handleError(error), signOutError(error));
    }),
  )),
);

export default signOutRequest;
