import { Column } from '../../../../components/Table/Column';

export default [{
  width: '30%',
  align: 'left',
  sortable: true,
  key: 'fullName',
  header: 'TEAM_MEMBER',
}, {
  width: '25%',
  header: 'ROLE',
  align: 'left',
  sortable: true,
  key: 'roles',
}, {
  width: '20%',
  header: 'BIRTH_DATE',
  align: 'left',
  sortable: false,
  key: 'dayOfBirth',
}, {
  width: '20%',
  header: 'IN_CLUB_SINCE',
  align: 'left',
  sortable: true,
  key: 'inClubSince',
}, {
  width: '5%',
  header: '',
  align: 'right',
  key: 'button',
}] as Column[];
