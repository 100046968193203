import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { DispatchProps, StateProps } from './TeamBasketOrdersProps';
import State from '../../models';
import { fetchTeamBasketOrdersRequest } from '../../actions/fetchTeamBasketOrders';
import TeamBasketOrders from './TeamBasketOrders';

const mapStateToProps = ({
  teamBasketOrders: {
    loading,
    byId,
    byHash,
    count,
  },
}: State): StateProps => ({
  count,
  loading,
  orders: byId.map((id: number) => byHash[id]),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  fetchOrders: bindActionCreators(fetchTeamBasketOrdersRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TeamBasketOrders));
