import React from 'react';
import classNames from 'classnames';
import { Input } from 'semantic-ui-react';

import styles from './styles.module.scss';
import { InputWithLockProps } from './InputWithLockProps';

const InputWithLock = ({
  error, isLocked, toggleLocked, ...props
}: InputWithLockProps) => (
  <Input
    className={classNames('full', styles.fullWidth, error && styles.dangerInput)}
    disabled={isLocked}
    iconPosition="left"
    icon={{
      color: isLocked ? 'blue' : 'grey',
      name: isLocked ? 'lock' : 'unlock',
      link: true,
      onClick: toggleLocked,
    }}
    {...props}
  />
);

export default InputWithLock;
