import pickBy from 'lodash/pickBy';
import queryString from 'query-string';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import { FETCH_TEAMS_ERROR, FETCH_TEAMS_REQUEST } from '../../constants/actionTypes';
import { FetchTeamsRequestAction } from '../../models/actions';
import { ListResponse, Response } from '../../models/Response';
import { Team } from '../../models/Team';
import { State } from '../../models/State';
import { TEAMS_LIST_LIMIT, MAX_TEAMS_LIST_LIMIT } from '../../constants/customizations';
import { TEAMS_PATH } from '../../constants/paths';
import { fetchTeamsError, fetchTeamsSuccess } from '../../actions/teams';
import { handleError } from '../../actions/errors';
import TeamStatus from '../../constants/teamStatus';

export default (
  action$: ActionsObservable<FetchTeamsRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_TEAMS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    {
      payload: {
        page,
        search,
        pagination = true,
        filter,
      },
    },
    { authentication: { authToken }, application: { clubId, teamId } },
  ]) => {
    const query = queryString.stringify(pickBy({
      offset: ((page || 1) - 1) * (pagination ? TEAMS_LIST_LIMIT : MAX_TEAMS_LIST_LIMIT),
      limit: (pagination ? TEAMS_LIST_LIMIT : MAX_TEAMS_LIST_LIMIT),
    }));
    let seasons;
    if (filter) {
      seasons = JSON.parse(filter);
    }
    return ajax({
      url: `${TEAMS_PATH.replace('$clubId', `${clubId}`)}/filter?${query}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authToken,
      },
      body: JSON.stringify({ search, ...seasons }),
    }).pipe(
      map(({ response: { data, count } }: Response<ListResponse<Team>>) => {
        const hasInactiveTeams = data.find(team => team.status === TeamStatus.INACTIVE);
        const sortedTeam = [
          (data || []).find(team => team.id === teamId),
          ...(data || []).filter(team => team.id !== teamId),
        ].filter(Boolean) as [];

        return fetchTeamsSuccess(
          count,
          teamId ? sortedTeam : data,
          !!hasInactiveTeams,
        );
      }),
      catchError(error => [
        handleError(error, FETCH_TEAMS_ERROR),
        fetchTeamsError(error),
      ]),
    );
  }),
);
