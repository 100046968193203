import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';

import { EVENTS_UPLOAD } from '../../constants/paths';
import { EventListData } from '../../models/Calendar';
import { NotificationType, UploadEventsRequestAction } from '../../models/actions';
import { Response } from '../../models/Response';
import { State } from '../../models/State';
import { UPLOAD_EVENTS_ERROR, UPLOAD_EVENTS_REQUEST, UPLOAD_EVENTS_SUCCESS } from '../../constants/actionTypes';
import { addNotification } from '../../actions/notifications';
import { createEventError, uploadEventsSuccess } from '../../actions/calendar';
import { handleError } from '../../actions/errors';
import serializeEventList from '../../utils/serializers/serializeEventList';

const uploadEvents = (
  action$: ActionsObservable<UploadEventsRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(UPLOAD_EVENTS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{ payload: { teamId, file } }, { authentication: { authToken } }]) => {
    const form = new FormData();
    form.append('file', file);

    return ajax.post(
      EVENTS_UPLOAD.replace('$teamId', String(teamId)),
      form,
      { authToken },
    ).pipe(
      mergeMap(({ response: events }: Response<EventListData[]>) => [
        addNotification(NotificationType.SUCCESS, UPLOAD_EVENTS_SUCCESS),
        uploadEventsSuccess(teamId, serializeEventList(events)),
      ]),
      catchError(error => [
        handleError(error, UPLOAD_EVENTS_ERROR),
        createEventError(error),
      ]),
    );
  }),
);

export default uploadEvents;
