import { v4 as uuidv4 } from 'uuid';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { mergeMap, withLatestFrom } from 'rxjs/operators';

import getEmbellishmentsForItem from '../methods/getEmbellishmentsForItem';
import { AddItemsForPlayersAction } from '../../../models/actions';
import { Item } from '../../../models/Order';
import { ORDER_ADD_ITEMS_FOR_PLAYERS } from '../../../constants/actionTypes';
import { State } from '../../../models/State';
import { orderUpdatePriceRequest, upsertItems } from '../../../actions/orderEditor';

const addItemsForPlayers = (
  action$: ActionsObservable<AddItemsForPlayersAction>,
  state$: StateObservable<State>,
) => action$.pipe(
  ofType(ORDER_ADD_ITEMS_FOR_PLAYERS),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { itemIds, playerIds } },
    {
      players: { byHash },
      catalog: { catalog: { productsByHash } },
      orders: { itemsById, itemsByHash },
    },
  ]) => {
    const enrichedItems: Item[] = itemIds.reduce((previousItems: Item[], itemId: string) => {
      const itemsForAllSelected = playerIds.map((playerId: number) => {
        const itemEmbellishments = getEmbellishmentsForItem({
          itemsByHash,
          itemsById,
          player: byHash[playerId],
          productCatalogId: itemId,
          productsByHash,
        });

        return {
          quantity: 1,
          maxQuantity: 1,
          quantityLocked: false,
          sizeLocked: false,
          backLabelLocked: false,
          numberLabelLocked: false,
          externalId: uuidv4(),
          productCatalogId: itemId,
          playerId,
          embellishments: itemEmbellishments,
        } as Item;
      });

      return [
        ...previousItems,
        ...itemsForAllSelected,
      ];
    }, []);

    return [
      upsertItems(enrichedItems),
      orderUpdatePriceRequest(),
    ];
  }),
);

export default addItemsForPlayers;
