import { Actions } from '../models/actions';
import {
  SEND_INVITATION,
  SEND_INVITATION_ERROR,
  SEND_INVITATION_SUCCESS,
  RESEND_INVITATION_ERROR,
  RESEND_INVITATION_SUCCESS,
  RESEND_INVITATION_REQUEST,
  FETCH_INVITATIONS_REQUEST,
  FETCH_INVITATIONS_SUCCESS,
  FETCH_INVITATIONS_ERROR,
} from '../constants/actionTypes';
import { SIGN_OUT_REQUEST } from '../packages/authentication';
import { InvitationByHash, InvitationResponse, InvitationsReducer } from '../models/Invitations';

export const initialState: InvitationsReducer = {
  byHash: {},
  byId: [],
  count: 0,
  loading: false,
  error: false,
  errorMessage: '',
};

export default (state = initialState, action: Actions) => {
  switch (action.type) {
    case RESEND_INVITATION_REQUEST:
    case SEND_INVITATION:
      return {
        ...state,
        loading: true,
      };
    case RESEND_INVITATION_SUCCESS:
    case SEND_INVITATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
      };
    case RESEND_INVITATION_ERROR:
    case SEND_INVITATION_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.error.message,
      };
    case SIGN_OUT_REQUEST:
      return initialState;
    case FETCH_INVITATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_INVITATIONS_SUCCESS: {
      const { invitations, count } = action.payload;
      return {
        ...state,
        count,
        loading: false,
        byHash: invitations
          .reduce((byHash: InvitationByHash, invitation: InvitationResponse) => ({
            ...byHash,
            [invitation.id]: invitation,
          }), state.byHash),
        byId: invitations.map(({ id }: InvitationResponse) => id),
      };
    }
    case FETCH_INVITATIONS_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
