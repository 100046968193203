import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import NotificationMessage from './NotificationMessage';
import { removeNotification } from '../../../../actions/notifications';

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  removeNotification,
}, dispatch);

export default connect(null, mapDispatchToProps)(NotificationMessage);
