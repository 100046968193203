import React, { useState, useMemo } from 'react';
import difference from 'lodash/difference';
import ProductDetails from './components/ProductDetails';
import ProductsRow from './components/ProductsRow';
import SelectPlayersModal from '../SelectPlayersModal';
import Table from '../../../../components/Table';
import TableCheckboxHeader from '../../../../components/TableCheckboxHeader';
import columns from './columns';
import mobileColumns from './mobileColumns';
import { Product } from '../../../../models/Catalog';
import { ProductsListProps } from './ProductsListProps';
import MobileProductsRow from './components/MobileProductsRow';
import { useViewport } from '../../../../hooks/useViewport';

const ProductsList = ({
  productsByItemId,
  productsByHash,
  groupedArticles,
  addItemsForPlayers,
  loading,
  selected,
  toggleAllSelection,
  modalOpened,
  addItemsForStaff,
  setModal,
}: ProductsListProps) => {
  const [articleId, setArticleId] = useState<string|null>(null);
  const { isMobile } = useViewport();
  const Row = useMemo(() => (isMobile ? MobileProductsRow : ProductsRow), [isMobile]);

  const [firstColumn, ...restColumns] = isMobile ? mobileColumns : columns;
  const enrichedColumns = [
    {
      ...firstColumn,
      headerComponent: () => (
        <TableCheckboxHeader
          title="ARTICLES"
          onClick={toggleAllSelection}
          checked={!difference(productsByItemId, selected).length}
          checkbox
        />
      ),
    },
    ...restColumns,
  ];

  const showArticleDetails = (id: string) => () => {
    setArticleId(id);
  };

  const closeArticleDetails = () => {
    setArticleId(null);
  };

  const renderItems = () => (
    productsByItemId.filter(id => productsByHash[id]).map(id => (
      <Row
        key={id}
        product={productsByHash[id]}
        checked={selected.includes(id)}
        article={groupedArticles[id]}
        showArticleDetails={showArticleDetails(id)}
      />
    ))
  );

  const onSubmit = (playerIds: number[], memberIds: number[]) => {
    addItemsForPlayers(selected, playerIds);
    addItemsForStaff(selected, memberIds);
    setModal(false)();
  };

  return (
    <>
      <Table columns={enrichedColumns} loading={loading} invertedHeader>
        {renderItems()}
      </Table>
      <ProductDetails
        open={!!articleId}
        article={articleId ? groupedArticles[articleId] : null}
        product={articleId ? productsByHash[articleId] : {} as Product}
        onClose={closeArticleDetails}
      />
      <SelectPlayersModal
        isOpen={modalOpened}
        onClose={setModal(false)}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default ProductsList;
