import { ItemsByHash } from '../../../../models/Order';

const changeProperty = (
  itemsByHash: ItemsByHash,
  setItemsByHash: (itemsByHash: ItemsByHash) => void,
) => (itemId: string | string[], property: string | string[], value: any) => {
  const itemIds = Array.isArray(itemId) ? itemId : [itemId];
  const properties = Array.isArray(property) ? property : [property];

  const newProperties = properties.reduce((values: { [key: string]: any }, prop: string) => ({
    ...values,
    [prop]: value,
  }), {});

  const upsertItem = (itemIdArg: string) => ({
    [itemIdArg]: {
      ...itemsByHash[itemIdArg],
      ...newProperties,
    },
  });

  const newItems = itemIds.reduce((items: ItemsByHash, itemIdArg: string) => ({
    ...items,
    ...upsertItem(itemIdArg),
  }), {});

  setItemsByHash({
    ...itemsByHash,
    ...newItems,
  });
};

export default changeProperty;
