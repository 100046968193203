import {
  RESET_PASSWORD_CLEAN_UP,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
} from '../constants/actionTypes';

export const resetPasswordRequest = (username, password, token) => ({
  type: RESET_PASSWORD_REQUEST,
  payload: {
    username,
    password,
    token,
  },
});

export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS,
});

export const resetPasswordError = error => ({
  type: RESET_PASSWORD_ERROR,
  payload: {
    error,
  },
});

export const resetPasswordCleanUp = () => ({
  type: RESET_PASSWORD_CLEAN_UP,
});
