import { bindActionCreators, Dispatch } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { State } from '../../../../models/State';
import PlayersList from './PlayersList';
import { addItemsForPlayers, toggleAllPlayersSelection, addItemsForSelectedStaffMembers } from '../../../../actions/orderEditor';
import { fetchPlayersRequest } from '../../../../actions/players';

const mapStatesToProps = ({
  players: {
    byId: playersById, byHash: playersByHash, count: playersCount, loading,
  },
  orders: {
    itemsById, itemsByHash, selectedPlayersIds, selectedStaffIds,
  },
}: State) => ({
  loading,
  playersByHash,
  itemsById,
  itemsByHash,
  playersById,
  playersCount,
  selectedPlayers: selectedPlayersIds,
  selectedStaff: selectedStaffIds,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleAllSelection: bindActionCreators(toggleAllPlayersSelection, dispatch),
  addItems: bindActionCreators(addItemsForPlayers, dispatch),
  addItemsForStaff: bindActionCreators(addItemsForSelectedStaffMembers, dispatch),
  fetchPlayers: bindActionCreators(fetchPlayersRequest, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(withRouter(PlayersList));
