import React from 'react';
import i18next from 'i18next';
import moment from 'moment';
import range from 'lodash/range';
import { Flag, FlagNameValues } from 'semantic-ui-react';

import { FormSchema } from '../../models/Form';
import { countries } from '../../utils/countries';
import {
  Footedness, Gender, PlayerPosition, Sizes,
} from '../../models/Player';
import { isRequired, isValidEmail } from '../../utils/formValidators';

const getOptions = (values: string[], translate: i18next.TFunction) => values.map(value => ({
  key: value as string,
  text: translate(value) as string,
  value: value as string,
}));

const getCountriesOptions = () => countries.map((
  { countryCode }: { name: string, countryCode: string },
) => ({
  key: countryCode,
  text: countryCode.toUpperCase(),
  value: countryCode,
  content: (
    <span>
      <Flag name={countryCode as FlagNameValues} />
      {countryCode.toUpperCase()}
    </span>
  ),
}));

export const infoSchema: FormSchema = ({
  firstName: {
    type: 'text',
    label: 'FIRSTNAME',
  },
  lastName: {
    type: 'text',
    label: 'LASTNAME',
  },
  nickname: {
    type: 'text',
    label: 'NICKNAME',
  },
});

export const generalSchema: FormSchema = ({
  footedness: {
    type: 'select',
    label: 'GENERAL_INFO.FUSS',
    options: (translate: i18next.TFunction) => getOptions(Object.values(Footedness), translate),
  },
  preferredPosition: {
    type: 'select',
    label: 'PREFFERED_TEAM_POSITION',
    options: (translate: i18next.TFunction) => getOptions(Object.values(PlayerPosition), translate),
  },
  alternativePosition: {
    type: 'select',
    label: 'GENERAL_INFO.ALTERNATIVE_POSITION',
    options: (translate: i18next.TFunction) => getOptions(Object.values(PlayerPosition), translate),
  },
  nationality: {
    type: 'country',
    label: 'GENERAL_INFO.NATIONALITY',
    options: getCountriesOptions,
  },
  gender: {
    type: 'select',
    label: 'GENERAL_INFO.GENDER',
    options: (translate: i18next.TFunction) => getOptions(Object.values(Gender), translate),
  },
  dayOfBirth: {
    type: 'date',
    label: 'GENERAL_INFO.DATE_OF_BIRTH',
  },
  joinedClubDate: {
    type: 'select',
    label: 'GENERAL_INFO.IN_THE_CLUB_SINCE',
    options: () => range(
      moment().year(),
      moment().subtract(80, 'years').year(),
    ).map(value => ({
      key: value,
      text: value,
      value: moment(0).year(value).valueOf(),
    })),
  },
  passNumber: {
    type: 'text',
    label: 'GENERAL_INFO.PASSNUMMER',
  },
});

export const sizesSchema: FormSchema = ({
  'sizes.SHIRT': {
    type: 'select',
    label: 'SIZES.SHIRT',
    options: (translate: i18next.TFunction) => getOptions(Object.values(Sizes), translate),
  },
  'sizes.SHORT': {
    type: 'select',
    label: 'SIZES.SHORT',
    options: (translate: i18next.TFunction) => getOptions(Object.values(Sizes), translate),
  },
  'sizes.SHOE': {
    type: 'number',
    label: 'SIZES.SHOE_AND_SOCK_SIZE',
    max: 50,
    min: 27,
    step: 0.5,
  },
  height: {
    type: 'number',
    label: 'SIZES.HEIGHT',
  },
  shoePreferences: {
    type: 'text',
    label: 'SIZES.SHOES_PREFERENCES',
  },
});

export const contactSchema: FormSchema = ({
  'address.country': {
    type: 'country',
    label: 'CONTACT_INFO.COUNTRY',
    required: true,
    options: getCountriesOptions,
  },
  'address.street': {
    type: 'text',
    label: 'CONTACT_INFO.STREET',
    required: true,
  },
  'address.number': {
    type: 'number',
    label: 'CONTACT_INFO.NUMBER',
    required: true,
  },
  'address.postCode': {
    type: 'text',
    label: 'CONTACT_INFO.ZIP_CODE',
    required: true,
  },
  'address.city': {
    type: 'text',
    label: 'CONTACT_INFO.CITY',
    required: true,
  },
  phoneNumber: {
    type: 'text',
    label: 'CONTACT_INFO.TELEPHONE_NUMBER',
  },
  email: {
    type: 'text',
    label: 'CONTACT_INFO.PRIMARY_EMAIL',
    required: true,
  },
  secondaryEmail: {
    type: 'text',
    label: 'CONTACT_INFO.ALTERNATIVE_EMAIL',
  },
  'socialMedias.facebook': {
    type: 'text',
    label: 'CONTACT_INFO.SOCIAL_MEDIA.FACEBOOK',
  },
  'socialMedias.instagram': {
    type: 'text',
    label: 'CONTACT_INFO.SOCIAL_MEDIA.INSTAGRAM',
  },
  'socialMedias.snapchat': {
    type: 'text',
    label: 'CONTACT_INFO.SOCIAL_MEDIA.SNAPCHAT',
  },
});

export const emergencyContactSchema: FormSchema = ({
  'emergencyContact.name': {
    type: 'text',
    label: 'EMERGENCY_CONTACT.NAME_OF_CONTACT',
  },
  'emergencyContact.relation': {
    type: 'text',
    label: 'EMERGENCY_CONTACT.ROLE',
  },
  'emergencyContact.phoneNumber': {
    type: 'text',
    label: 'EMERGENCY_CONTACT.TELEPHONE_NUMBER',
  },
});

export const validationSchema = {
  'address.country': [isRequired],
  'address.city': [isRequired],
  'address.postCode': [isRequired],
  'address.street': [isRequired],
  'address.number': [isRequired],
  email: [isRequired, isValidEmail],
};
