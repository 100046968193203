import { connect } from 'react-redux';

import { State } from '../../../../models/State';
import SelectProductsModal from './SelectProductsModal';

const mapStatesToProps = ({
  application: { clubId, teamId },
  catalog: {
    catalog: {
      productsById,
      productsByHash,
    },
    loading,
  },
}: State) => ({
  clubId,
  teamId,
  productsById,
  productsByHash,
  loading,
});

export default connect(mapStatesToProps)(SelectProductsModal);
