import {
  CLEAR_ORDER,
  INITIALIZE_ORDER,
  ORDER_ADD_ITEMS_FOR_PLAYERS,
  ORDER_ADD_ITEMS_FOR_SELECTED_STAFF_MEMBERS,
  ORDER_SELECT_PLAYERS,
  ORDER_SELECT_PRODUCTS,
  ORDER_TOGGLE_ALL_PLAYERS_SELECTION,
  ORDER_TOGGLE_ALL_PRODUCTS_SELECTION,
  ORDER_TOGGLE_PLAYER_SELECTION,
  ORDER_TOGGLE_PRODUCT_SELECTION,
  ORDER_UPDATE_ITEMS,
  ORDER_UPDATE_PRICE_REQUEST,
  ORDER_UPDATE_PRICE_SUCCESS,
  ORDER_UPSERT_ITEMS,
  REMOVE_ITEMS,
  ORDER_SELECT_STAFF,
  ORDER_TOGGLE_STAFF_SELECTION,
} from '../constants/actionTypes';

import {
  AddItemsForPlayersAction,
  AddItemsForSelectedStaffMembersAction,
  ClearOrderAction,
  InitializeOrderAction,
  OrderUpdatePriceRequestAction,
  OrderUpdatePriceSuccessAction,
  RemoveItemsAction,
  SelectPlayersAction,
  SelectProductsAction,
  ToggleAllPlayersSelectionAction,
  ToggleAllProductsSelectionAction,
  TogglePlayerSelectionAction,
  ToggleProductSelectionAction,
  UpdateItemsAction,
  UpsertItemsAction,
  SelectStaffAction,
  ToggleStaffSelectionAction,
} from '../models/actions';
import { Item, ItemsByHash } from '../models/Order';
import { Price } from '../models/Price';

export const togglePlayerSelection = (
  id: number, teamId?: number,
): TogglePlayerSelectionAction => ({
  type: ORDER_TOGGLE_PLAYER_SELECTION,
  payload: { id, teamId },
});

export const toggleStaffSelection = (id: number, teamId?: number): ToggleStaffSelectionAction => ({
  type: ORDER_TOGGLE_STAFF_SELECTION,
  payload: { id, teamId },
});

export const toggleAllPlayersSelection = (): ToggleAllPlayersSelectionAction => ({
  type: ORDER_TOGGLE_ALL_PLAYERS_SELECTION,
});

export const toggleProductSelection = (id: string): ToggleProductSelectionAction => ({
  type: ORDER_TOGGLE_PRODUCT_SELECTION,
  payload: { id },
});

export const toggleAllProductsSelection = (): ToggleAllProductsSelectionAction => ({
  type: ORDER_TOGGLE_ALL_PRODUCTS_SELECTION,
});

export const selectPlayers = (ids: number[], teamId?: number): SelectPlayersAction => ({
  type: ORDER_SELECT_PLAYERS,
  payload: {
    ids,
    teamId,
  },
});

export const selectStaff = (ids: number[], teamId?: number): SelectStaffAction => ({
  type: ORDER_SELECT_STAFF,
  payload: {
    ids,
    teamId,
  },
});

export const selectProducts = (ids: string[]): SelectProductsAction => ({
  type: ORDER_SELECT_PRODUCTS,
  payload: { ids },
});

export const addItemsForPlayers = (
  itemIds: string[], playerIds: number[],
): AddItemsForPlayersAction => ({
  type: ORDER_ADD_ITEMS_FOR_PLAYERS,
  payload: { itemIds, playerIds },
});

export const addItemsForSelectedStaffMembers = (
  items: string[], memberIds: number[],
): AddItemsForSelectedStaffMembersAction => ({
  type: ORDER_ADD_ITEMS_FOR_SELECTED_STAFF_MEMBERS,
  payload: { items, memberIds },
});

export const upsertItems = (items: Item[]): UpsertItemsAction => ({
  type: ORDER_UPSERT_ITEMS,
  payload: { items },
});

export const updateItems = (items: ItemsByHash): UpdateItemsAction => ({
  type: ORDER_UPDATE_ITEMS,
  payload: { items },
});

export const removeItems = (itemsIds: string[]): RemoveItemsAction => ({
  type: REMOVE_ITEMS,
  payload: { itemsIds },
});

export const initializeOrder = (teamId: number, orderId?: number): InitializeOrderAction => ({
  type: INITIALIZE_ORDER,
  payload: {
    teamId,
    orderId,
  },
});

export const clearOrder = (): ClearOrderAction => ({
  type: CLEAR_ORDER,
});

export const orderUpdatePriceRequest = (): OrderUpdatePriceRequestAction => ({
  type: ORDER_UPDATE_PRICE_REQUEST,
});

export const orderUpdatePriceSuccess = (price: Price): OrderUpdatePriceSuccessAction => ({
  type: ORDER_UPDATE_PRICE_SUCCESS,
  payload: {
    price,
  },
});
