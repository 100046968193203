import { Actions } from '../models/actions';
import {
  SIGN_UP_ERROR, SIGN_UP_SUCCESS, SIGN_UP_WITH_TOKEN_REQUEST,
  RESET_SIGNUP, ACCEPT_INVITATION_SUCCESS, ACCEPT_INVITATION_ERROR, ACCEPT_INVITATION_REQUEST,
} from '../constants/actionTypes';
import { SignUpReducer } from '../models/State';
import { UNAUTHORIZED } from '../packages/authentication';

const initialState: SignUpReducer = {
  serverError: null,
  error: false,
  loading: false,
  userSigned: '',
  receiverRole: null,
  inviteAccepted: false,
  inviteRejected: false,
};

export default (
  state = initialState,
  action: Actions,
) => {
  switch (action.type) {
    case ACCEPT_INVITATION_REQUEST:
      return {
        ...state,
        inviteAccepted: false,
      };
    case ACCEPT_INVITATION_SUCCESS:
      return {
        ...state,
        inviteAccepted: true,
        inviteRejected: false,
      };
    case ACCEPT_INVITATION_ERROR:
      return {
        ...state,
        inviteAccepted: false,
        inviteRejected: true,
      };
    case SIGN_UP_WITH_TOKEN_REQUEST:
      return {
        ...state,
        error: false,
        serverError: null,
        loading: true,
      };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        userSigned: action.payload.signUpData.firstName,
        receiverRole: action.payload.receiverRole,
        serverError: null,
      };
    case UNAUTHORIZED:
    case RESET_SIGNUP: {
      return {
        ...initialState,
      };
    }
    case SIGN_UP_ERROR: {
      const { serverError } = action.payload;
      return {
        ...state,
        error: true,
        loading: false,
        serverError,
      };
    }
    default:
      return state;
  }
};
