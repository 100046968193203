import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';
import { forkJoin } from 'rxjs';

import { CREATE_EVENTS_REQUEST } from '../../constants/actionTypes';
import { CreateEventsRequestAction, NotificationType } from '../../models/actions';
import { State } from '../../models/State';
import { EVENTS } from '../../constants/paths';
import { Response } from '../../models/Response';
import { createEventError, createEventSuccess } from '../../actions/calendar';
import { EventListData } from '../../models/Calendar';
import { handleError } from '../../actions/errors';
import { addNotification } from '../../actions/notifications';
import serializeEventList from '../../utils/serializers/serializeEventList';

const createEvent = (
  action$: ActionsObservable<CreateEventsRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(CREATE_EVENTS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { events } },
    {
      authentication: { authToken },
    },
  ]) => forkJoin(events.map((event: any) => ajax({
    url: EVENTS,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify(event),
  }).pipe(
    map(({ response: newEvents }: Response<EventListData[]>) => newEvents || []),
  )))),
  mergeMap(merged => {
    const newEvents = merged.reduce(
      // @ts-ignore
      (acc: EventListData[], current: EventListData[]) => [...acc, ...current], [],
    );
    return [
      addNotification(NotificationType.SUCCESS, 'CREATE_EVENTS_SUCCESS'),
      createEventSuccess(serializeEventList(newEvents as EventListData[])),
    ];
  }),
  catchError(error => [
    handleError(error, 'CREATE_EVENTS_ERROR'),
    createEventError(error),
  ]),
);

export default createEvent;
