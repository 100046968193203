import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import InvitationModal from './InvitationModal';
import { acceptInvitationRequest } from '../../actions/application';
import { State } from '../../models/State';

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  acceptInvitationRequest,
}, dispatch);

const mapStateToProps = ({
  invitations: { error, errorMessage, loading }, user: { user },
  application: { clubId },
  clubs: { byHash },
}: State) => ({
  error,
  user,
  loading,
  errorMessage,
  currentClub: clubId ? byHash[clubId] : null,
});
export default connect(mapStateToProps, mapDispatchToProps)(InvitationModal);
