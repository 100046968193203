import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import styles from './ClubInfo.module.scss';
import LabelValueField from '../../../../components/LabelValueField';
import LogoSection from '../LogoSection';
import colors from '../../../../constants/colors';
import { Club } from '../../../../models/Club';

interface ClubInfoProps {
  club: Club;
}

export default ({
  club: {
    name, phoneNumber, primaryColor, email,
    socialMedia, websiteEventsAddress, websiteNewsAddress,
    websiteAddress, faxNumber, address: {
      street, city, postCode, number,
    },
  },
}: ClubInfoProps) => {
  const [translate] = useTranslation();
  const color = colors.find(c => c.name === primaryColor);

  return (
    <>
      <h3 className={styles.header}>{translate('BASIC_DATA')}</h3>
      <div className={styles.layout}>
        <div className={styles.left}>
          <LabelValueField label={translate('CLUB_NAME')} value={name} />
          <LabelValueField label={translate('STREET')} value={street} />
          <LabelValueField label={translate('NUMBER')} value={number} />
          <LabelValueField label={translate('CITY')} value={city} />
          <LabelValueField label={translate('ZIP_CODE')} value={postCode} />
          <LabelValueField label={translate('PHONE_NUMBER')} value={phoneNumber} />
          <LabelValueField label={translate('EMAIL')} value={email} />
        </div>
        <div className={styles.right}>
          <LogoSection />
          <LabelValueField label={translate('FAX_NUMBER')} value={faxNumber} />
          <LabelValueField label={translate('WEBSITE')} value={websiteAddress} isLink />
        </div>
      </div>
      <div className={classNames(styles.field, styles.border)}>
        <span className={styles.label}>
          {translate('PRIMARY_COLOR')}
          <small>{`(${translate('PRIMARY_COLOR_DESCRIPTION')})`}</small>
        </span>
        { primaryColor && (
          <div className={styles.group}>
            <div className={styles.circle} style={{ backgroundColor: color && color.hex, borderColor: color && color.hex === '#FFFFFF' ? '#979797' : 'none' }} />
            <span className={styles.text}>{color && color.name}</span>
          </div>
        )}
      </div>
      <div className={classNames(styles.field, styles.border)}>
        <span className={styles.label}>{translate('SOCIAL_MEDIA')}</span>
        { socialMedia && (
          <div className={styles.group}>
            { socialMedia.facebookUrl && (
              <a href={socialMedia.facebookUrl} target="__blank">
                <Icon name="facebook f" circular className={styles.media} />
              </a>
            )}
            { socialMedia.instagramUrl && (
              <a href={socialMedia.instagramUrl} target="__blank">
                <Icon name="instagram" circular className={styles.media} />
              </a>
            )}
            { socialMedia.snapchatUrl && (
              <a href={socialMedia.snapchatUrl} target="__blank">
                <Icon name="twitter" circular className={styles.media} />
              </a>
            )}
          </div>
        )}
      </div>
      <h3 className={styles.header}>{translate('CURRENT')}</h3>
      <LabelValueField label={translate('WEBSITE_EVENTS_ADDRESS')} value={websiteEventsAddress} isLink />
      <LabelValueField label={translate('WEBSITE_NEWS_ADDRESS')} value={websiteNewsAddress} isLink />
    </>
  );
};
