import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TeamBasketOrdersProps } from './TeamBasketOrdersProps';
import Row from '../../components/Table/components/Row';
import Text from '../../components/Text';
import { formatPrice } from '../../utils/PricingUtil';
import styles from '../Orders/components/OrdersDesktop/OrdersDesktop.module.scss';
import Header from '../../components/Header';
import OrderTabsComponent from '../../components/OrderTabsComponent/OrderTabsComponent';
import TableContainer from '../../components/Layout/components/TableContainer';
import { history } from '../../configureStore';
import { TeamBasketOrder } from '../../models/TeamBasketOrder';
import { formatDate } from '../../utils/dateUtils';
import filteredColumns from './filteredColumns';

const TeamBasketOrders: FC<TeamBasketOrdersProps> = ({
  orders,
  loading,
  count,
  fetchOrders,
}) => {
  const [translate] = useTranslation();
  const renderRows = ({
    id, team, price, orderDate, articleCount,
  }: TeamBasketOrder) => (
    <Row key={id}>
      <div>{`#${id}`}</div>
      <Text>
        {formatDate(orderDate)}
      </Text>
      <Text>
        {team.name}
      </Text>
      <Text>
        {articleCount}
      </Text>
      <Text>
        {price ? formatPrice(price.amount, price.currency) : formatPrice(0, 'EUR')}
      </Text>
    </Row>
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.topGroup}>
        <Header
          title={translate('ORDERS')}
          border
        />
        <div className={styles['content-wrapper']}>
          <OrderTabsComponent />
          <TableContainer
            fetch={fetchOrders}
            loading={loading}
            count={count}
            history={history}
            columns={filteredColumns(translate)}
            renderItems={orders.map(renderRows)}
            disableSearch
            invertedHeader
            emptyTableText="NO_ORDERS_AVAILABLE"
          />
        </div>
      </div>
    </div>
  );
};

export default TeamBasketOrders;
