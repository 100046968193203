import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Calendar from './Calendar';
import State from '../../models';
import { fetchTeamsRequest } from '../../actions/teams';
import {
  fetchEventRequest, fetchEventsRequest, resetEvent, uploadEventsRequest, previewCSVEventRequest,
} from '../../actions/calendar';
import { CalendarProps } from './CalendarProps';
import { setApplicationParameters } from '../../actions/application';

const mapStateToProps = (
  {
    teams,
    calendar,
    application: { clubId, userRole },
    user: { user: authUser },
  }: State,
  { match: { params: { teamId } } }: CalendarProps,
) => ({
  teamsByHash: teams.byHash,
  teamsById: teams.byId,
  eventsById: calendar.byId,
  eventsByHash: calendar.byHash,
  eventCreated: calendar.eventCreated,
  eventRemoved: calendar.eventRemoved,
  loadingEvents: calendar.loading,
  clubId,
  loadingTeams: teams.loadingTeams,
  teamId: +teamId,
  previewEvent: calendar.previewEvent,
  loadingPreview: calendar.loadingPreview,
  error: calendar.error,
  authUser,
  userRole,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchTeams: bindActionCreators(fetchTeamsRequest, dispatch),
  resetEvent: bindActionCreators(resetEvent, dispatch),
  fetchEvent: bindActionCreators(fetchEventRequest, dispatch),
  fetchEvents: bindActionCreators(fetchEventsRequest, dispatch),
  uploadEvents: bindActionCreators(uploadEventsRequest, dispatch),
  previewCSVEvent: bindActionCreators(previewCSVEventRequest, dispatch),
  setApplicationParameter: bindActionCreators(setApplicationParameters, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
