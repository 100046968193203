import { IndividualOrderListItem, IndividualOrdersByHash, IndividualOrdersListReducer } from '../models/IndividualOrder';
import {
  FETCH_INDIVIDUAL_ORDERS_ERROR,
  FETCH_INDIVIDUAL_ORDERS_REQUEST,
  FETCH_INDIVIDUAL_ORDERS_SUCCESS,
} from '../constants/actionTypes';
import { IndividualOrdersActions } from '../models/actions';

export const initialState: IndividualOrdersListReducer = {
  byHash: {},
  byId: [],
  count: 0,
  loading: false,
  loadingConfirmation: false,
  orderConfirmed: false,
  error: false,
  ordersDeleted: false,
};

export default (state = initialState, action: IndividualOrdersActions) => {
  switch (action.type) {
    case FETCH_INDIVIDUAL_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_INDIVIDUAL_ORDERS_SUCCESS: {
      const { orders, count } = action.payload;

      return {
        ...state,
        byHash: orders
          .reduce((byHash: IndividualOrdersByHash, order: IndividualOrderListItem) => ({
            ...byHash,
            [order.orderId]: order,
          }), {}),
        byId: orders.map(({ orderId }: IndividualOrderListItem) => orderId),
        loading: false,
        count,
      };
    }
    case FETCH_INDIVIDUAL_ORDERS_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        loadingConfirmation: false,
        orderConfirmed: false,
      };
    default:
      return state;
  }
};
