import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import SettingsEdit from './SettingsEdit';
import { State } from '../../../../models/State';
import { Club } from '../../../../models/Club';
import { updateClubRequest } from '../../../../actions/clubs';

const mapStatesToProps = ({
  application: { clubId },
  clubs: { byHash },
}: State) => ({
  club: (clubId && byHash[clubId]) || {} as Club,
  clubId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateClub: bindActionCreators(updateClubRequest, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(SettingsEdit);
