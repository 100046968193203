import { useEffect } from 'react';
import { FilesByHash } from '../../models/File';
import { File } from '../../models/Catalog';
import { IMAGES_EXTENSIONS } from '../../constants/customizations';

function useImage(
  files: File[] | null,
  fallback: string[],
  filesByHash: FilesByHash,
  fetchFile: (id: string, extension: string) => void,
): [string] {
  const fallbackImages = fallback && fallback.length ? fallback[0] : '';
  const image = files && files.find(
    ({ extension }) => IMAGES_EXTENSIONS.includes(extension.toLowerCase()),
  );
  const img = image ? filesByHash[image.id] : fallbackImages;

  useEffect(() => {
    if (image && !filesByHash[image.id]) {
      fetchFile(image.id, image.extension);
    }
  }, [fetchFile, image, filesByHash]);

  return [img];
}

export default useImage;
