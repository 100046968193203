import { FormSchema } from '../../../../models/Form';
import { isRequired } from '../../../../utils/formValidators';

export const requestTokenSchema: FormSchema = ({
  email: {
    type: 'text',
    label: 'EMAIL',
  },
});

export const validationSchema = {
  email: [isRequired],
};
