import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import MobileHeader from './MobileOrderHeader';
import { State } from '../../../../models/State';
import {
  changeOrderStatusRequest, lockNumbers, deleteOrdersRequest, upsertOrderRequest,
} from '../../../../actions/orders';
import { clearOrder, initializeOrder } from '../../../../actions/orderEditor';

const mapStatesToProps = ({
  orders: {
    itemsById, itemsByHash, orderStatus, draft, loading, orderPrice,
    lockedNumbers, usedNumbers,
  },
  catalog: {
    catalog: {
      productsByHash,
    },
  },
  application: {
    userRole,
  },
}: State) => ({
  draft,
  itemsByHash,
  itemsById,
  loading,
  orderPrice,
  userRole,
  orderStatus,
  productsByHash,
  usedNumbers: usedNumbers ? Object.keys(usedNumbers).map(Number) : [],
  lockedNumbers,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  changeStatus: bindActionCreators(changeOrderStatusRequest, dispatch),
  clearOrder: bindActionCreators(clearOrder, dispatch),
  deleteOrders: bindActionCreators(deleteOrdersRequest, dispatch),
  initializeOrder: bindActionCreators(initializeOrder, dispatch),
  upsertOrder: bindActionCreators(upsertOrderRequest, dispatch),
  lockNumbers: bindActionCreators(lockNumbers, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(withRouter(MobileHeader));
