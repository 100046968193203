import moment from 'moment';
import {
  Match, MatchType, Training,
} from '../../../models/Calendar';
import {
  ADDRESS,
  COMPETITION,
  DESCRIPTION, END_TIME, InformTeamParams,
  MATCH_PLACE, MatchFormSchema, MEETING_POINT,
  MEETING_TIME,
  OPPONENT_TEAM,
  PITCH, REPORT, START_TIME,
} from '../components/MatchEventModal/MatchEventModalProps';
import { TIME_FORMAT } from '../../../utils/dateUtils';
import { EventType } from '../../../constants/eventTypes';
import useEventDateTime from '../hooks/useEventDateTime';
import { getUpperCasedDay } from './TimePickerUtil';

export default (
  translate: (key: string) => string,
  formState: MatchFormSchema,
  matchDate: Date|undefined,
  teamId: number,
  event: Match | Training | null,
  informTeamParams?: InformTeamParams,
): Match => {
  const [startDateTime, endDateTime] = useEventDateTime(formState, matchDate);

  return {
    name: `${translate(formState[COMPETITION] || '')} ${translate('MATCH').toLocaleLowerCase()} ${formState[OPPONENT_TEAM]}`,
    description: formState[DESCRIPTION],
    match: {
      ...(formState[MEETING_TIME]
        && ({ meetingTime: moment(formState[MEETING_TIME]!).format(TIME_FORMAT) })),
      matchPlace: formState[MATCH_PLACE],
      matchType: formState[COMPETITION] || MatchType.FRIENDLY,
      oppositeClubName: formState[OPPONENT_TEAM] || '',
      oppositeTeamName: formState[OPPONENT_TEAM] || '',
      ...(!!formState[PITCH] && ({ pitch: formState[PITCH] })),
      matchLocation: {
        displayName: formState[ADDRESS],
      },
      ...(!!formState[REPORT] && ({ report: formState[REPORT] })),
      ...(!event && ({ teamId })),
      ...(!event && ({
        matchEndsAt: moment(formState[END_TIME]!).format(TIME_FORMAT),
      })),
      ...(!event && ({
        matchStartsAt: moment(formState[START_TIME]!).format(TIME_FORMAT),
      })),
    },
    ...(formState[MEETING_POINT] && ({
      location: {
        displayName: formState[MEETING_POINT],
      },
    })),
    type: EventType.MATCH,
    ...(!event && ({
      interval: {
        type: 'WEEK',
        length: 1,
        startDate: moment(matchDate!).startOf('day').valueOf(),
        startDay: getUpperCasedDay(matchDate!),
        endDate: moment(matchDate!).endOf('day').valueOf(),
        startTime: moment(formState[START_TIME]!).format(TIME_FORMAT),
        endTime: moment(formState[END_TIME]!).format(TIME_FORMAT),
      },
    })),
    ...(event && ({ id: event.id })),
    ...(event && ({ startDateTime })),
    ...(event && ({ endDateTime })),
    ...(informTeamParams && (informTeamParams)),
  };
};
