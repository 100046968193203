import application from './application';
import calendar from './calendar';
import catalog from './catalog';
import clubs from './clubs';
import files from './files';
import invitations from './invitations';
import members from './members';
import notifications from './notifications';
import orders from './orders';
import ordersList from './ordersList';
import persons from './persons';
import players from './players';
import signUp from './signUp';
import staff from './staff';
import teams from './teams';
import user from './user';
import externalSystems from './externalSystems';
import individualOrders from './individualOrders';
import teamBasketOrders from './teamBasketOrders';

export default {
  application,
  calendar,
  catalog,
  clubs,
  files,
  invitations,
  members,
  notifications,
  orders,
  ordersList,
  persons,
  players,
  signUp,
  staff,
  teams,
  user,
  externalSystems,
  individualOrders,
  teamBasketOrders,
};
