import {
  FETCH_MEMBERS_ERROR, FETCH_MEMBERS_REQUEST, FETCH_MEMBERS_SUCCESS,
  DELETE_MEMBER_SUCCESS, DELETE_MEMBER_ERROR, DELETE_MEMBER_REQUEST,
} from '../constants/actionTypes';
import { Member, MembersByHash, MembersReducer } from '../models/ClubManagement';
import { MembersActions } from '../models/actions';

export const initialState: MembersReducer = {
  byHash: {},
  byId: [],
  count: 0,
  loading: false,
  error: false,
  errorMessage: '',
};

export default (state = initialState, action: MembersActions) => {
  switch (action.type) {
    case FETCH_MEMBERS_REQUEST:
    case DELETE_MEMBER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MEMBERS_SUCCESS: {
      const { members, count } = action.payload;

      return {
        ...state,
        byHash: members
          .reduce((byHash: MembersByHash, member: Member) => ({
            ...byHash,
            [member.personId]: member,
          }), state.byHash),
        byId: members.map(({ personId }: Member) => personId),
        loading: false,
        count,
      };
    }
    case FETCH_MEMBERS_ERROR:
    case DELETE_MEMBER_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case DELETE_MEMBER_SUCCESS: {
      const { memberId } = action.payload;
      const filteredMembers = state.byId
        .filter(id => id !== memberId);
      return {
        ...state,
        loading: false,
        error: false,
        count: state.count - 1,
        byId: filteredMembers,
        byHash: filteredMembers.reduce((byHash: MembersByHash, id: number) => ({
          ...byHash,
          [id]: state.byHash[id],
        }), {}),
      };
    }
    default:
      return state;
  }
};
