import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { Icon } from 'semantic-ui-react';
import styles from './BalloonSelect.module.scss';
import {
  DATA_PRIVACY_ROUTE, IMPRINT_ROUTE, PROFILE_ROUTE, TERMS_ROUTE,
} from '../../../../constants/routes';

interface BalloonSelectProps extends RouteComponentProps {
  signOut: () => void;
}

const BallonSelect: FC<BalloonSelectProps> = ({
  signOut,
  history,
}) => {
  const [translate] = useTranslation();

  const navigateToProfile = (route: string) => () => {
    history.push(route);
  };

  return (
    <div className={styles.wrapper}>
      <div className={[styles.item, styles.dividerBottom].join(' ')} onClick={navigateToProfile(PROFILE_ROUTE)}>
        <Icon name="user circle outline" />
        {translate('PROFILE')}
      </div>
      <div className={styles.item} onClick={navigateToProfile(DATA_PRIVACY_ROUTE)}>
        <Icon name="privacy" />
        {translate('DATA_PRIVACY')}
      </div>
      <div className={styles.item} onClick={navigateToProfile(IMPRINT_ROUTE)}>
        <Icon name="address card outline" />
        {translate('IMPRINT.PAGE_NAME')}
      </div>
      <div className={styles.item} onClick={navigateToProfile(TERMS_ROUTE)}>
        <Icon name="law" />
        {translate('TERMS_AND_CONDITIONS')}
      </div>
      <div className={[styles.item, styles.dividerTop].join(' ')} onClick={signOut}>
        <Icon name="power" />
        {translate('LOGOUT')}
      </div>
    </div>
  );
};

export default BallonSelect;
