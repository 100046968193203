import React, { FC } from 'react';
import classNames from 'classnames';
import max from 'lodash/max';
import min from 'lodash/min';
import { Loader, Popup, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import calculateProductPricesForTeams from '../../../../utils/calculatePrices/calculateProductPricesForTeams';
import styles from './PriceDiscountsPopup.module.scss';
import { ClubTeamPrice, Price } from '../../../../models/Price';
import { PriceDiscountsPopupProps } from './PriceDiscountsPopupProps';
import { formatPrice } from '../../../../utils/PricingUtil';

const PriceDiscountsPopup: FC<PriceDiscountsPopupProps> = ({
  catalog,
  clubId,
  embellishments,
  productEmbellishments,
  popupPosition = 'right center',
  price = { amount: 0, currency: 'EUR' } as Price,
  productDiscountPercent = null,
  teams,
  loading,
  underline = true,
}) => {
  const [translate] = useTranslation();
  const {
    clubPrice,
    teamsPrice,
  } = calculateProductPricesForTeams(
    clubId,
    teams,
    embellishments,
    price,
    productDiscountPercent,
    catalog,
    productEmbellishments,
  );
  const prices = [...teamsPrice, clubPrice]
    .filter((value): value is ClubTeamPrice => !!value)
    .map(({ price: { amount } }) => amount);
  const minPrice = min(prices);
  const maxPrice = max(prices);

  if (loading) {
    return (
      <Loader active inline />
    );
  }

  if (!minPrice || !maxPrice || minPrice === maxPrice) {
    return (
      <span>{formatPrice(minPrice || 0, price.currency)}</span>
    );
  }

  return (
    <Popup
      className={styles.popup}
      hoverable
      popperModifiers={[{ preventOverflow: { boundariesElement: 'viewport' } }]}
      position={popupPosition}
      trigger={(
        <span className={classNames(styles.popupTrigger, underline && styles.underline)}>
          {`${formatPrice(minPrice, price.currency)} – ${formatPrice(maxPrice, price.currency)}`}
        </span>
      )}
    >
      <div className={styles.tableWrapper}>
        <Table>
          <Table.Body>
            {clubPrice && (
              <Table.Row>
                <Table.Cell>
                  <strong>{translate('CLUB')}</strong>
                </Table.Cell>
                <Table.Cell>
                  {formatPrice(clubPrice.price.amount, clubPrice.price.currency)}
                </Table.Cell>
              </Table.Row>
            )}
            {teamsPrice && teamsPrice.map(({ name, price: discountedPrice }) => (
              <Table.Row key={name}>
                <Table.Cell>
                  {name}
                </Table.Cell>
                <Table.Cell>
                  {formatPrice(discountedPrice.amount, discountedPrice.currency)}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </Popup>
  );
};

export default PriceDiscountsPopup;
