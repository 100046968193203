import DayPickerInput from 'react-day-picker/DayPickerInput';
import React, { FC, useEffect } from 'react';
import TimePicker from 'rc-time-picker';
import classNames from 'classnames';
import moment, { MomentInput, Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Form, Label,
} from 'semantic-ui-react';

import { OneTimeEventFormProps } from './OneTimeEventFormProps';
import {
  MEETING_TIME,
  START_TIME, END_TIME,
  DESCRIPTION, ADDRESS,
  NAME,
} from '../OneTimeEventModal/OneTimeEventModalProps';
import styles from './OneTimeEventForm.module.scss';
import { formatDate, DATE_FORMAT, TIME_FORMAT } from '../../../../utils/dateUtils';
import 'rc-time-picker/assets/index.css';
import { getDisabledHours, getDisabledMinutes } from '../../methods/TimePickerUtil';
import { isRequired } from '../../../../utils/formValidators';

const OneTimeEventForm: FC<OneTimeEventFormProps> = ({
  formState,
  handleChange,
  eventDate,
  isSubmitted,
  handleEventDateChange,
  handleTimeChange,
  loading,
}) => {
  const [translate] = useTranslation();

  useEffect(() => {
    const {
      [START_TIME]: startTime,
      [END_TIME]: endTime,
      [MEETING_TIME]: meetingTime,
    } = formState;

    if (startTime && (!endTime || startTime > endTime)) {
      handleTimeChange(moment(startTime), END_TIME);
    }

    if (startTime && meetingTime && startTime < meetingTime) {
      handleTimeChange(moment(startTime), MEETING_TIME);
    }
  }, [formState, handleTimeChange]);

  const handleStartTimeChange = (date: Moment) => {
    handleTimeChange(date, START_TIME);
  };

  const handleEndTimeChange = (date: Moment) => {
    handleTimeChange(date, END_TIME);
  };

  const handleMeetingTimeChange = (date: Moment) => {
    handleTimeChange(date, MEETING_TIME);
  };

  const errorRequired = (value: any) => (
    isSubmitted && !isRequired.rule(value) && translate(isRequired.errorMessage)
  );

  const defaultTimePickerProps = {
    clearIcon: null,
    defaultOpenValue: moment(),
    format: TIME_FORMAT,
    minuteStep: 15,
    showSecond: false,
  };

  return (
    <Form className={styles.content} loading={loading}>
      <Form.Group>
        <Form.Input width={5} className={[styles.required].join(' ')} label={translate('NAME')} value={formState.name} error={isSubmitted && !formState[NAME]} onChange={handleChange} name={NAME} />
        <Form.Input width={11} label={translate('ADDRESS')} value={formState[ADDRESS]} onChange={handleChange} name={ADDRESS} />
      </Form.Group>
      <Form.Group className={[styles.flex, styles.align, styles.flexEnd].join(' ')}>
        <Form.Field width={5} className={[styles.flex, styles.direction, styles.column, styles.required].join(' ')} error={isSubmitted && !eventDate}>
          <label>{translate('DATE')}</label>
          <DayPickerInput
            onDayChange={handleEventDateChange}
            value={eventDate || ''}
            format={DATE_FORMAT}
            formatDate={(date: MomentInput) => formatDate(date)}
            placeholder=""
            dayPickerProps={{
              firstDayOfWeek: 1,
              selectedDays: eventDate,
            }}
          />
          {errorRequired(eventDate) && (
            <Label content={errorRequired(eventDate)} pointing prompt />
          )}
        </Form.Field>
        <div
          className={classNames(
            ['field', styles.flex, styles.align, styles.flexEnd, styles.justify, styles.spaceBetween, styles.eventDates, styles.flexItem, styles.flexOne],
            errorRequired(formState[START_TIME]) && 'error',
          )}
        >
          <Form.Field className={styles.required} error={isSubmitted && !formState[START_TIME]}>
            <label>{translate('TIME')}</label>
            <TimePicker
              {...defaultTimePickerProps}
              disabledHours={() => [0, 1, 2, 3, 4]}
              className={[styles.time, styles.picker, styles.noClear].join(' ')}
              onChange={handleStartTimeChange}
              value={(formState[START_TIME])
                ? moment(formState[START_TIME]!)
                : undefined}
            />
          </Form.Field>
          <span className={styles.timeToLabel}>{translate('TO')}</span>
          <Form.Field error={isSubmitted && !formState[END_TIME]}>
            <TimePicker
              {...defaultTimePickerProps}
              disabledHours={getDisabledHours(formState[START_TIME], END_TIME)}
              disabledMinutes={getDisabledMinutes(formState[START_TIME], END_TIME)}
              disabled={!formState[START_TIME]}
              className={[styles.time, styles.picker, styles.noClear].join(' ')}
              onChange={handleEndTimeChange}
              value={formState[END_TIME] ? moment(formState[END_TIME]!) : undefined}
            />
          </Form.Field>
          {errorRequired(formState[START_TIME]) && (
            <Label content={errorRequired(formState[START_TIME])} pointing prompt />
          )}
        </div>
        <Form.Field width={6} className={[styles.flex, styles.direction, styles.column].join(' ')}>
          <label>{translate('MEETING_TIME')}</label>
          <TimePicker
            {...defaultTimePickerProps}
            disabledHours={getDisabledHours(formState[START_TIME], MEETING_TIME)}
            disabledMinutes={getDisabledMinutes(formState[START_TIME], MEETING_TIME)}
            disabled={!formState[START_TIME]}
            className={[styles.time, styles.picker].join(' ')}
            onChange={handleMeetingTimeChange}
            value={formState[MEETING_TIME] ? moment(formState[MEETING_TIME]!) : undefined}
          />
        </Form.Field>
      </Form.Group>
      <Form.TextArea rows={4} label={translate('INFORMATION')} onChange={handleChange} value={formState[DESCRIPTION] || ''} name={DESCRIPTION} />
    </Form>
  );
};

export default OneTimeEventForm;
