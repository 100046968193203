import React, { FC, useState } from 'react';
import classNames from 'classnames';
import range from 'lodash/range';
import xor from 'lodash/xor';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';

import ModalActions from '../../../../components/ModalActions';
import Text from '../../../../components/Text';
import styles from './NumbersBalloon.module.scss';
import { LockNumbersAction, SetLockNumbersRequestAction } from '../../../../models/actions';
import MobileFooter from '../../../../components/MobileFooter';
import MobileModal from '../MobileModal';
import { useViewport } from '../../../../hooks/useViewport';

interface NumbersBalloonProps {
  lockedNumbers?: number[];
  usedNumbers?: number[];
  selectedNumbers?: number[];
  lockNumbers: (numbers: number[]) => LockNumbersAction|SetLockNumbersRequestAction;
}

const NumberBalloon: FC<NumbersBalloonProps> = ({
  lockedNumbers = [],
  lockNumbers,
  selectedNumbers = [],
  usedNumbers = [],
  ...props
}) => {
  const [translate] = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<number[]>([]);
  const [markStart, setMarkStart] = useState<number | null>(null);
  const [marked, setMarked] = useState<number[]>([]);
  const { isMobile } = useViewport();

  const toggleModal = () => {
    setIsOpen(!isOpen);
    setSelected(lockedNumbers);
  };

  useDeepCompareEffect(() => {
    setSelected(lockedNumbers);
  }, [lockedNumbers]);

  const handleMouseDown = (number: number) => () => {
    setMarkStart(number);
    setMarked([number]);
  };

  const handleMouseOver = (number: number) => () => {
    if (markStart !== null) {
      setMarked([...range(markStart, number), number]);
    }
  };

  const handleMouseUp = () => {
    setSelected(xor(selected, marked));
    setMarkStart(null);
    setMarked([]);
  };

  const renderButtons = () => [...Array(100).keys()].map(number => (
    <button
      type="button"
      key={number}
      onMouseDown={handleMouseDown(number)}
      onMouseOver={handleMouseOver(number)}
      onFocus={handleMouseOver(number)}
      onMouseUp={handleMouseUp}
      className={classNames(
        styles.circle,
        selected.includes(number) && styles.locked,
        marked.includes(number) && styles.mark,
        usedNumbers.includes(number) && styles.used,
        selectedNumbers.includes(number) && styles.selected,
      )}
    >
      {number}
    </button>
  ));

  const onSave = () => {
    lockNumbers(selected);
    toggleModal();
  };

  const MobileNumberBalloon = (
    <MobileModal
      button={(
        <MobileFooter
          // @ts-ignore
          buttonText={translate('SAVE')}
          onClick={onSave}
        />
      )}
      content={(
        <div className={styles.modalNumbers} onMouseUp={handleMouseUp}>
          {renderButtons()}
        </div>
      )}
      border
      isOpen={isOpen}
      title={translate('BLOCK_SHIRT_NUMBERS')}
      onClose={toggleModal}
    />
  );

  const DesktopNumberBalloon = (
    <div className={styles.wrapper}>
      <div className={styles.arrow} />
      <div className={styles.content}>
        <div className={styles.header}>
          <Text bold>{translate('BLOCK_SHIRT_NUMBERS')}</Text>
          <Button icon="close" className="primary simple" basic onClick={toggleModal} />
        </div>
        <div className={styles.numbers} onMouseUp={handleMouseUp}>
          {renderButtons()}
        </div>
        <ModalActions onCancel={toggleModal} onSave={onSave} padded />
      </div>
    </div>
  );

  return (
    <>
      <Button
        className="simple"
        content={translate('BLOCK_SHIRT_NUMBERS')}
        onClick={toggleModal}
        {...props}
      />

      {isOpen && (
        isMobile ? MobileNumberBalloon : DesktopNumberBalloon
      )}
    </>
  );
};

export default NumberBalloon;
