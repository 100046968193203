import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import TeamInfo from './TeamInfo';
import { State } from '../../../../models/State';

const mapStatesToProps = ({
  teams: {
    byHash, byId,
  },
  application: { clubId },
}: State) => ({
  teamsByHash: byHash,
  teamsById: byId,
  clubId,
});

export default withRouter(connect(mapStatesToProps)(TeamInfo));
