import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { push } from 'connected-react-router';

import { Response } from '../../models/Response';
import { State } from '../../models/State';
import { UPDATE_USER_ERROR, UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS } from '../../constants/actionTypes';
import { NotificationType, UpdateUserRequestAction } from '../../models/actions';
import { PROFILE_ROUTE } from '../../constants/routes';
import { updateUserError, updateUserSuccess } from '../../actions/user';
import { User } from '../../models/general/User';
import { USER_INFO_PATH } from '../../constants/paths';
import { addNotification } from '../../actions/notifications';
import { handleError } from '../../actions/errors';

const updateUser = (
  action$: ActionsObservable<UpdateUserRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(UPDATE_USER_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      firstName, lastName, phoneNumber,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: USER_INFO_PATH,
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify({
      firstName, lastName, phoneNumber,
    }),
  }).pipe(
    mergeMap(({ response }: Response<User>) => [
      updateUserSuccess(response),
      addNotification(NotificationType.SUCCESS, UPDATE_USER_SUCCESS),
      push(PROFILE_ROUTE),
    ]),
    catchError((error: AjaxError) => [
      handleError(error, UPDATE_USER_ERROR),
      updateUserError(error),
    ]),
  )),
);

export default updateUser;
