import React, { useEffect, useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { InactiveTeamPopupProps } from './InactiveTeamPopupProps';
import { Roles } from '../../constants/roles';

const InactiveTeamPopup = ({
  hasInactiveTeams, userRole, teamId, clearTeamId,
}: InactiveTeamPopupProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [translate] = useTranslation();

  const onClose = () => {
    setModalOpen(false);

    if (!teamId) {
      clearTeamId();
    }
  };
  useEffect(() => {
    if (userRole === Roles.TEAM_LEAD && (hasInactiveTeams || teamId === null)) {
      setModalOpen(true);
    }
  }, [hasInactiveTeams, userRole, teamId]);

  const headerMessage = teamId ? 'SOME_OF_THE_TEAMS_ARE_DEACTIVATED' : 'NO_ACTIVE_TEAMS_DETECTED';
  const contentMessage = teamId ? 'YOU_CAN_STILL_WORK_WITH_THE_ACTIVE_TEAMS' : 'YOU_DONT_HAVE_ANY_ACTIVE_TEAM';

  return (
    <Modal
      closeIcon
      onClose={onClose}
      open={modalOpen}
      closeOnDimmerClick={false}
      size="tiny"
    >
      <Modal.Header>
        {translate(headerMessage)}

      </Modal.Header>
      <Modal.Content>
        {translate(contentMessage)}
      </Modal.Content>
    </Modal>
  );
};

export default InactiveTeamPopup;
