import React, { useMemo, useState } from 'react';
import difference from 'lodash/difference';

import MemberDetails from '../MemberDetails';
import DesktopMemberRow from '../DesktopMemberRow';
import SelectProductsModal from '../SelectProductsModal';
import Table from '../../../../components/Table';
import TableCheckboxHeader from '../../../../components/TableCheckboxHeader';
import columns from './columns';
import columnsMobile from './columnsMobile';
import groupItemsByMember from '../../methods/groupItemsByMember';
import { Item } from '../../../../models/Order';
import { Member, MemberIdKey } from '../../../../models/Member';
import { OrderItemType } from '../../OrderPreparationProps';
import { StaffListProps } from './StaffListProps';
import { useViewport } from '../../../../hooks/useViewport';
import { history } from '../../../../configureStore';
import MobileMemberRow from '../MobileMemberRow';

const StaffList = ({
  byId,
  byHash,
  addItemsForStaff,
  addItemsForPlayers,
  itemsByHash,
  itemsById,
  loading,
  selectedStaff,
  selectedPlayers,
  selectStaff,
  modalOpened,
  setModal,
}: StaffListProps) => {
  const { isMobile } = useViewport();
  const [staffId, setStaffId] = useState<number | null>(null);
  const [,, teamId] = history.location.pathname.split('/');
  const showDetails = (id: number) => () => setStaffId(id);
  const closeDetails = () => setStaffId(null);
  const isAllSelected = !difference(byId, selectedStaff).length;

  const Row = useMemo(() => (isMobile ? MobileMemberRow : DesktopMemberRow), [isMobile]);

  const handleAllSelection = () => {
    selectStaff(isAllSelected ? [] : byId);
  };

  const [firstColumn, ...restColumns] = isMobile ? columnsMobile : columns;
  const enrichedColumns = [
    {
      ...firstColumn,
      headerComponent: () => (
        <TableCheckboxHeader
          title="STAFF_MEMBER"
          onClick={handleAllSelection}
          checked={isAllSelected}
          checkbox
        />
      ),
    },
    ...restColumns,
  ];

  const onSubmit = (items: Item[]) => {
    const itemIds = items.map(({ productCatalogId }) => productCatalogId);
    addItemsForStaff(itemIds, selectedStaff);
    addItemsForPlayers(itemIds, selectedPlayers);
    setModal(false)();
  };

  return (
    <>
      <Table columns={enrichedColumns} loading={loading} invertedHeader>
        {byId.map((id: number) => (
          <Row
            key={id}
            member={byHash[id] as Member}
            checked={selectedStaff.includes(id)}
            items={groupItemsByMember(itemsById, itemsByHash)[byHash[id].userId!] || []}
            showDetails={showDetails(id)}
            itemType={OrderItemType.STAFF}
            teamId={+teamId}
          />
        ))}
      </Table>
      <MemberDetails
        open={!!staffId}
        member={(staffId ? byHash[staffId] : {}) as Member}
        onClose={closeDetails}
        itemIdKey={MemberIdKey.STAFF_ID}
        itemType={OrderItemType.STAFF}
      />
      <SelectProductsModal
        isOpen={modalOpened}
        onClose={setModal(false)}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default StaffList;
