import React, { FC, useCallback, useState } from 'react';
import classNames from 'classnames';
import { Button, Dropdown, Icon } from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import AvatarWithPopup from '../AvatarWithPopup';
import Row from '../../../../components/Table/components/Row';
import TeamInvitationPopup from '../TeamInvitationPopup';
import styles from './ClubTeamsRow.module.scss';
import {
  CLUB_TEAMS_CREATE_MEMBER_ROUTE,
  PLAYER_PROFILE_ROUTE,
} from '../../../../constants/routes';
import { ClubTeamsRowProps } from './ClubTeamsRowProps';
import { PlayerTeamList } from '../../../../models/Player';
import { TeamLeadTeamList } from '../../../../models/Team';
import Confirm from '../../../../components/Confirm';
import TeamStatus from '../../../../constants/teamStatus';
import ChangeTeamSeasonPopup from '../ChangeTeamSeasonPopup';
import { formatDate } from '../../../../utils/dateUtils';

const ClubTeamsRow: FC<ClubTeamsRowProps> = ({
  team: {
    id,
    name,
    players,
    teamLeads = [],
    pendingTeamLeads = [],
    removable,
    status,
    season,
    seasonStartDate,
    seasonEndDate,
  },
  deleteTeam, changeTeamStatus, resendInvitation, fetchInviteToken,
}) => {
  const [translate] = useTranslation();
  const [showInvitationPopup, setShowInvitationPopup] = useState<boolean>(false);
  const [showSeasonChangePopup, setShowSeasonChangePopup] = useState<boolean>(false);
  const history = useHistory();
  const pendingTeamLeadsFiltered = pendingTeamLeads.filter(({ firstName, lastName }) => `${firstName}${lastName}`);
  const getName = (user: TeamLeadTeamList | PlayerTeamList) => `${user.firstName} ${user.lastName}`;
  const hasOneTeamLead = [...teamLeads, ...pendingTeamLeadsFiltered].length === 1;
  const isTeamActive = status === TeamStatus.ACTIVE;

  const renderAddButton = useCallback((linkTo: string) => (
    <Button
      as={Link}
      circular
      className={styles.buttonAdd}
      icon="add"
      primary
      size="mini"
      to={linkTo}
      onClick={event => { event.stopPropagation(); }}
    />
  ), []);

  const onDeleteTeam = () => {
    deleteTeam(id);
  };

  const redirectViewTeam = () => {
    history.push(`/club/teams/${id}/members`);
  };

  const resendInvitationHandler = (user: TeamLeadTeamList) => () => {
    resendInvitation(user.invitationToken!);
  };

  return (
    <Row
      key={id}
      classNames={styles.wrapper}
      itemOnClick={redirectViewTeam}
    >
      <div className={classNames(styles.teamName, !isTeamActive && styles.disabled)}>
        {name}
      </div>
      <div className={classNames(!isTeamActive && styles.disabled)}>
        {translate(season)}
      </div>
      <div className={classNames(styles.avatarCell, !isTeamActive && styles.disabled)}>
        {(teamLeads || pendingTeamLeadsFiltered) && (
          <div className={classNames(styles.avatarContainer, hasOneTeamLead && styles.noWrap)}>
            {teamLeads.map(user => (
              <AvatarWithPopup
                user={user}
                teamId={id}
                id={user.userId}
                key={user.userId}
              />
            ))}
            {pendingTeamLeadsFiltered.map(user => (
              <AvatarWithPopup
                id={uuidv4()}
                user={user}
                teamId={id}
                key={`${user.firstName}${user.lastName}`}
                italic
                buttonText={translate('REINVITE')}
                customClickHandler={resendInvitationHandler(user)}
              />
            ))}

            {hasOneTeamLead && (teamLeads.length ? (
              <span>{getName(teamLeads[0])}</span>
            ) : pendingTeamLeads && (
              <em>
                {getName(pendingTeamLeads[0])}
                <small>
                  {`(${translate('PENDING')})`}
                </small>
              </em>
            ))}
          </div>
        )}

        {renderAddButton(CLUB_TEAMS_CREATE_MEMBER_ROUTE.replace(':teamId', `${id}`))}
      </div>
      <div className={classNames(styles.avatarCell, !isTeamActive && styles.disabled)}>
        {players && (
          <div className={styles.avatarContainer}>
            {players.map((player: PlayerTeamList) => (
              <AvatarWithPopup
                id={player.id}
                key={player.id}
                playerId={player.id}
                user={player}
                linkTo={
                  PLAYER_PROFILE_ROUTE
                    .replace(':teamId', String(id))
                    .replace(':playerId', String(player.id))
                }
                teamId={id}
              />
            ))}

            {players.length === 1 && (
              <span>{getName(players[0])}</span>
            )}
          </div>
        )}

        {renderAddButton(CLUB_TEAMS_CREATE_MEMBER_ROUTE.replace(':teamId', `${id}`))}
      </div>
      <div className={classNames(!isTeamActive && styles.disabled)}>{`${seasonStartDate ? formatDate(seasonStartDate) : translate('UNKNOWN')}`}</div>
      <div className={classNames(!isTeamActive && styles.disabled)}>{`${seasonEndDate ? formatDate(seasonEndDate) : translate('UNKNOWN')}`}</div>
      <div className={styles['options-wrapper']} onClick={(e: any) => { e.stopPropagation(); }}>
        <Dropdown
          icon={<Icon name="ellipsis vertical" color="blue" size="large" />}
          direction="left"
          basic
        >
          <Dropdown.Menu>
            {isTeamActive && (
              <Dropdown.Item
                text={translate('VIEW_TEAM')}
                onClick={redirectViewTeam}
              />
            )}
            <Dropdown.Item
              text={translate(isTeamActive ? 'DEACTIVATE_TEAM' : 'ACTIVATE_TEAM')}
              onClick={() => {
                changeTeamStatus(id, isTeamActive ? TeamStatus.INACTIVE : TeamStatus.ACTIVE);
              }}
            />
            <Dropdown.Item
              text={translate('TEAM_SEASON_CHANGE')}
              onClick={() => {
                setShowSeasonChangePopup(true);
              }}
            />
            {isTeamActive && (
              <Dropdown.Item
                text={translate('BULK_INVITE_PLAYERS')}
                onClick={() => {
                  setShowInvitationPopup(true);
                  fetchInviteToken(id);
                }}
              />
            )}
            {isTeamActive && (
              <Confirm
                as={Dropdown.Item}
                className={styles.menuOptionDelete}
                confirmText={translate('ARE_YOU_SURE_TO_DELETE_TEAM')}
                onConfirm={onDeleteTeam}
                triggerText={translate('DELETE_TEAM')}
                disabled={!removable}
              />
            )}
          </Dropdown.Menu>
        </Dropdown>
        <ChangeTeamSeasonPopup
          teamId={id}
          teamName={name}
          isOpen={showSeasonChangePopup}
          closePopup={() => setShowSeasonChangePopup(false)}
        />
        <TeamInvitationPopup
          isOpen={showInvitationPopup}
          teamId={id}
          closePopup={() => {
            setShowInvitationPopup(false);
          }}
        />
      </div>
    </Row>
  );
};

export default ClubTeamsRow;
