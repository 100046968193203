import { connect } from 'react-redux';
import ProtectedRoute from './ProtectedRoute';
import State from '../../../../models';

const mapStateToProps = ({
  authentication: { authToken },
  application: {
    clubId, memberships = {}, teamId, userRole,
  },
  user: { user, infoFetched },
}: State) => ({
  isAuthenticated: !!authToken,
  user,
  userRole,
  clubId,
  teamId,
  memberships,
  infoFetched,
});

export default connect(mapStateToProps, null)(ProtectedRoute);
