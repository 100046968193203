import { EventType } from '../constants/eventTypes';
import { WeekDays } from '../constants/weekDays';
import { EventInterval } from '../constants/eventInterval';
import { Address } from './Address';

export interface Interval {
  type: string;
  length?: EventInterval;
  startDate: number;
  startDay?: WeekDays,
  startTime: string;
  endDate: number;
  endTime: string;
}

export enum MatchType {
  FRIENDLY = 'FRIENDLY',
  LEAGUE = 'LEAGUE',
  CUP = 'CUP',
  PRACTICE = 'PRACTICE',
}

export enum MatchPlace {
  HOME = 'HOME', AWAY = 'AWAY',
}

export enum Pitch {
  GRASS = 'GRASS',
  ARTIFICIAL_GRASS = 'ARTIFICIAL_GRASS',
  CINDER = 'CINDER',
  SAND = 'SAND',
  INDOOR_GROUND = 'INDOOR_GROUND',
}

export enum EventStatus {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
}

export interface Event {
  id?: number;
  type?: EventType;
  name: string;
  description?: string;
  interval?: Interval;
  location?: Address;
  startDateTime?: Date;
  endDateTime?: Date;
  meetingDate?: Date;
  resetAttendance?: boolean;
  informTeam?: boolean;
  groupId?: string;
  numberOfEventsInGroup?: number;
}

export interface Training extends Event {
  training: {
    teamId?: number;
    meetingTime?: string;
  };
}

export interface OneTimeEvent extends Event {
  oneTimeEvent: {
    teamId?: number;
    meetingTime?: string;
  };
}

export interface Birthday extends Event {
  type: EventType.BIRTH_DATE;
  firstName: string;
  lastName: string;
  teamName: string;
  playerNumber: number;
  playerId: number;
  personId: number;
  teamId: number;
  birthDate: Date;
  fullName: string;
}

export interface MatchData {
  teamId?: number;
  meetingTime?: string;
  matchType: MatchType;
  matchPlace: MatchPlace;
  oppositeTeamName: string;
  oppositeClubName: string;
  oppositeTeamId?: number;
  oppositeClubLogo?: string;
  matchStartsAt?: string;
  matchEndsAt?: string;
  matchLocation?: Address;
  pitch?: Pitch | null;
  location?: Address;
  homeClubName?: string;
  awayClubName?: string;
  homeTeamName?: string;
  awayTeamName?: string;
  homeClubLogo?: string;
  awayClubLogo?: string;
  homeTeamScore?: number;
  awayTeamScore?: number;
  startDateTime?: number;
  endDateTime?: number;
  report?: string;
  id?: number;
  externalId?: string;
}

export interface Match extends Event {
  match: MatchData;
}

export interface EventsByHash {
  [key: string]: EventListData;
}

export interface CalendarReducer {
  byHash: EventsByHash;
  byId: number[];
  count: number;
  error: boolean;
  loading: boolean;
  eventCreated: boolean;
  eventRemoved: boolean;
  event: Match | Training | null;
  loadingPreview: boolean;
  previewEvent: Match | Training | null;
}

export interface EventListData {
  id: number;
  createdBy: number;
  endDateTime: Date;
  location: Address;
  groupId: string;
  meetingDate: Date | null;
  name: string
  startDateTime: Date;
  teamId: number;
  type: keyof EventType;
  match?: MatchData;
  status?: EventStatus;
  personId?: number;
}
