import React from 'react';
import get from 'lodash/get';
import { useParams } from 'react-router-dom';
import {
  Button, Checkbox, Image, Input,
} from 'semantic-ui-react';

import PriceDiscountsPopup from '../../../../../ProductDetails/components/PriceDiscountsPopup';
import Row from '../../../../../../components/Table/components/Row';
import Text from '../../../../../../components/Text';
import styles from './ProductsRow.module.scss';
import useImage from '../../../../../../hooks/useImage';
import { Embellishment } from '../../../../../../models/Embellishment';
import { ProductsRowProps } from './ProductsRowProps';

const ProductsRow = ({
  product: {
    id, name, files, originalImages, originalPrice, discountPercent,
    embellishments: productEmbellishments,
  },
  clubId,
  checked,
  embellishmentsByHash,
  toggleSelection,
  fetchFile,
  filesByHash,
  article: {
    quantity, playerIds, maxQuantity, embellishments = [],
  },
  showArticleDetails,
}: ProductsRowProps) => {
  const [img] = useImage(files, originalImages, filesByHash, fetchFile);
  const teamId = +get(useParams(), 'teamId');
  const itemEmbellishments: Embellishment[] = productEmbellishments
    .filter(({ embellishment }) => embellishments.some(({ type }) => type === embellishment))
    .map(({ embellishment }) => embellishmentsByHash[embellishment]);

  const onClick = () => {
    toggleSelection(id);
  };

  const hasOptionals = productEmbellishments.some(({ mandatory }) => !mandatory);

  return (
    <Row key={id} classNames={styles.wrapper}>
      <div className={styles.article}>
        <Checkbox onClick={onClick} checked={checked} />
        <div className={styles.img}>
          {!!img && (
            <Image src={img} className={styles.image} />
          )}
        </div>
        <Text bold>{name}</Text>
      </div>
      {!clubId ? <Text /> : (
        <PriceDiscountsPopup
          clubId={clubId}
          teamId={teamId}
          productEmbellishments={productEmbellishments}
          embellishments={itemEmbellishments}
          price={originalPrice}
          productDiscountPercent={discountPercent}
          underline={false}
        />
      )}
      <Text translate>{hasOptionals ? 'YES' : 'NO'}</Text>
      <Input value={quantity} className="full" disabled={!!playerIds.length} />
      <Input value={maxQuantity} className="full" disabled={!!playerIds.length} />
      <Button basic primary className="simple" icon="angle right" onClick={showArticleDetails} />
    </Row>
  );
};

export default ProductsRow;
