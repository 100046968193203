import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownItemProps, DropdownProps, Form } from 'semantic-ui-react';
import { Roles } from '../../../constants/roles';
import { FilterComponentProps } from '../../Table/components/TableFilter/TableFilterProps';
import styles from './RoleFilter.module.scss';

const RoleFilter = ({
  onFilterChange,
  filterValue,
  column,
}: FilterComponentProps) => {
  const [translate] = useTranslation();
  const [roleOptions] = useState<DropdownItemProps[]>(
    Object.keys(Roles).map((key: string) => ({
      value: key,
      text: key,
      key,
    })),
  );
  const [roles, setRoles] = useState<string[]>([]);

  useEffect(() => {
    if (filterValue) {
      setRoles(filterValue as string[]);
    }
  }, [filterValue]);

  const handleRolesChange = (e: any, data: DropdownProps) => {
    setRoles(data.value as string[]);
    if (onFilterChange) {
      onFilterChange({
        [column]: (data.value as string[]).length ? data.value as string[] : null,
      });
    }
  };

  return (
    <div className={styles.wrapper}>
      <Form id="teams-filter-form">
        <Form.Field
          className={styles.formField}
          key="teams-id"
        >
          <label>{translate('TEAMS')}</label>
          <div className={styles.inputWithLabel}>
            <Form.Dropdown
              name="roles-id-select"
              onChange={handleRolesChange}
              placeholder={translate('ROLES')}
              options={roleOptions}
              selection
              clearable
              multiple
              search
              value={roles || []}
            />
          </div>
        </Form.Field>
      </Form>
    </div>
  );
};

export default RoleFilter;
