import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import Catalog from './Catalog';
import { State } from '../../models/State';
import { fetchCatalogRequest } from '../../actions/catalogs';
import { fetchTeamsRequest } from '../../actions/teams';
import { fetchEmbellishmentsRequest } from '../../actions/embellishments';
import { setApplicationParameters } from '../../actions/application';

const mapStateToProps = ({
  application: { clubId, userRole },
  catalog: { catalog, loading, embellishmentsById },
  teams: { byId: teamsById },
}: State) => ({
  userRole,
  catalog,
  clubId,
  embellishmentsById,
  loading,
  teamsById,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  fetchCatalog: fetchCatalogRequest,
  fetchEmbellishments: fetchEmbellishmentsRequest,
  fetchTeams: fetchTeamsRequest,
  setApplicationParameter: setApplicationParameters,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Catalog);
