import Favicon from 'react-favicon';
import React, { FC } from 'react';
import classNames from 'classnames';
import { Image } from 'semantic-ui-react';

import styles from './AuthenticationFormsWrapper.module.scss';
import { AuthenticationFormsWrapperProps } from './AuthenticationFormsWrapperProps';

const AuthenticationFormsWrapper: FC<AuthenticationFormsWrapperProps> = ({
  clubLogoUrl, children, isBlockingMobile,
}) => (
  <div className={[styles.authenticationFormsContainer, isBlockingMobile ? styles.isBlockingMobile : ''].join(' ')}>
    <div className={styles.leftContainer}>
      <div className={styles.topBanner}>
        <div className={styles.hocLogo} />
      </div>
      {children}
    </div>

    {clubLogoUrl ? (
      <div className={styles.rightContainer}>
        <Image src={clubLogoUrl} className={styles.logo} key="image" />
        <Favicon url={clubLogoUrl} key="favicon" />
      </div>
    ) : (
      <div className={classNames(styles.rightContainer, styles.defaultLogoContainer)} />
    )}
  </div>
);

export default AuthenticationFormsWrapper;
