import {
  Catalog, CatalogDataResponse, EmbellishmentFixedPriceDiscountType, Product, TeamDiscountsType,
} from '../../models/Catalog';

const mapTeamDiscounts = (teamDiscount: TeamDiscountsType) => ({
  ...teamDiscount,
  key: String(teamDiscount.teamId),
});

const mapGroupDiscounts = (fixedPrice: EmbellishmentFixedPriceDiscountType) => ({
  ...fixedPrice,
  key: `${fixedPrice.embellishment}${fixedPrice.groupId}`,
});

const serializeCatalog = ({
  club,
  createdAt,
  products,
  productDiscountPercent,
  embellishmentsDiscountPercent,
  teamProductDiscounts = [],
  teamEmbellishmentsDiscounts = [],
  embellishmentFixedPriceDiscounts = [],
  updatedAt,
}: CatalogDataResponse): Catalog => ({
  productDiscountPercent,
  createdAt: new Date(createdAt),
  updatedAt: new Date(updatedAt),
  clubName: club.name,
  productsById: products.map(product => product.id),
  productsByHash: products.reduce(
    (previousProductsByHash: { [key: string]: Product }, product: Product) => ({
      ...previousProductsByHash,
      [product.id]: product,
    }), {},
  ),
  embellishmentsDiscountPercent: embellishmentsDiscountPercent || 0,
  teamProductDiscounts: teamProductDiscounts.map(mapTeamDiscounts),
  teamEmbellishmentsDiscounts: teamEmbellishmentsDiscounts.map(mapTeamDiscounts),
  embellishmentFixedPriceDiscounts: embellishmentFixedPriceDiscounts.map(mapGroupDiscounts),
});

export default serializeCatalog;
