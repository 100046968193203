import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import ProfileEdit from './ProfileEdit';
import { State } from '../../models/State';
import { updateUserRequest } from '../../actions/user';

const mapStateToProps = (
  { user: { user } }: State,
) => ({
  user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateUser: bindActionCreators(updateUserRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEdit);
