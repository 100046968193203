import { connect } from 'react-redux';
import DeleteDataConfirmation from './DeleteDataConfirmation';
import State from '../../models';

const mapStateToProps = ({
  clubs: { logoUrl },
}: State) => ({
  clubLogoUrl: logoUrl,
});

export default connect(mapStateToProps)(DeleteDataConfirmation);
