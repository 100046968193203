import { connect } from 'react-redux';

import ClubInfo from './ClubInfo';
import { State } from '../../../../models/State';
import { Club } from '../../../../models/Club';

const mapStatesToProps = ({
  application: { clubId },
  clubs: { byHash },
}: State) => ({
  club: clubId && byHash[clubId] && byHash[clubId].address
    ? byHash[clubId]
    : {
      address: {},
    } as Club,
});

export default connect(mapStatesToProps)(ClubInfo);
