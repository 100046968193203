import { ApplicationActions } from './Application';
import { AuthenticationActions } from './Authentication';
import { CalendarActions } from './Calendar';
import { CatalogActions } from './Catalog';
import { ClubActions } from './Club';
import { ErrorsActions } from './Errors';
import { FileActions } from './File';
import { InvitationActions } from './InvitationAction';
import { MembersActions } from './Members';
import { NotificationsActions } from './Notifications';
import { OrderActions } from './Order';
import { OrderEditorActions } from './OrderEditor';
import { PlayerActions } from './Player';
import { TeamActions } from './Teams';
import { UserActions } from './User';
import {
  DfbClubActions, ExternalTeamsActions, Tor24Actions, Tor24Matches,
} from './externalSystem';
import { IndividualOrdersActions } from './IndividualOrdersActions';
import { TeamBasketOrderActions } from './TeamBasketOrderActions';
import { InvitationsActions } from './Invitations';

export * from './Application';
export * from './Authentication';
export * from './Calendar';
export * from './Catalog';
export * from './Club';
export * from './Errors';
export * from './File';
export * from './InvitationAction';
export * from './Invitations';
export * from './Members';
export * from './Notifications';
export * from './Order';
export * from './OrderEditor';
export * from './Player';
export * from './Teams';
export * from './User';
export * from './externalSystem';
export * from './IndividualOrdersActions';
export * from './TeamBasketOrderActions';

export type Actions =
  | ApplicationActions
  | AuthenticationActions
  | CalendarActions
  | CatalogActions
  | ClubActions
  | ErrorsActions
  | FileActions
  | InvitationActions
  | InvitationsActions
  | MembersActions
  | NotificationsActions
  | OrderActions
  | OrderEditorActions
  | PlayerActions
  | TeamActions
  | UserActions
  | Tor24Matches
  | Tor24Actions
  | IndividualOrdersActions
  | TeamBasketOrderActions
  | DfbClubActions
  | ExternalTeamsActions;
