import { AjaxError } from 'rxjs/ajax';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';

import {
  FETCH_TOR24_MATCHES_ERROR,
  FETCH_TOR24_MATCHES_REQUEST,
} from '../../constants/actionTypes';
import { TOR24_MATCHES_PATH } from '../../constants/paths';
import { State } from '../../models/State';
import { handleError } from '../../actions/errors';
import { FetchTor24MatchesRequestAction } from '../../models/actions/externalSystem';
import { fetchTor24MatchesError, fetchTor24MatchesSuccess } from '../../actions/externalSystems/tor24Matches';
import { Response } from '../../models/Response';
import { MatchData } from '../../models/Calendar';
import deserializeTor24Match from '../../utils/deserializers/deserializeTor24Match';

export default (
  action$: ActionsObservable<FetchTor24MatchesRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_TOR24_MATCHES_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      teamId,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: TOR24_MATCHES_PATH.replace('$teamId', `${teamId}`),
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    mergeMap(({ response: matches }: Response<MatchData[]>) => [
      fetchTor24MatchesSuccess(matches.map(deserializeTor24Match)),
    ]),
    catchError((error: AjaxError) => [
      handleError(error, FETCH_TOR24_MATCHES_ERROR),
      fetchTor24MatchesError(error),
    ]),
  )),
);
