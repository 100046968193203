import React, { useEffect, FC } from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Icon, Pagination, Dropdown } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { MembersByHash } from '../../../../models/ClubManagement';
import { FetchMembersRequestAction, DeleteMemberRequestAction } from '../../../../models/actions';
import usePagination from '../../../../hooks/usePagination';
import {
  DEFAULT_LIST_LIMIT,
  TABLE_PAGE_URL_PARAM,
  TABLE_SORT_FIELD_URL_PARAM,
  TABLE_SORT_ORDER_URL_PARAM,
} from '../../../../constants/customizations';
import styles from '../../../../components/Layout/components/TableContainer/TableContainer.module.scss';
import Table from '../../../../components/Table';
import useSorting from '../../../../hooks/useSorting';
import params from '../../../../utils/params';
import { SortOrder } from '../../../../models/general/SortOrder';
import {
  ListFilters,
} from '../../../../models/ListFilters';
import columns from './columns';
import Row from '../../../../components/Table/components/Row';
import { formatDate } from '../../../../utils/dateUtils';
import ProfileAvatar from '../../../PlayerProfile/components/ProfileAvatar';
import Text from '../../../../components/Text';
import { UserType } from '../../../PlayerProfile/components/ProfileAvatar/ProfileAvatarProps';
import { MEMBER_ROUTE } from '../../../../constants/routes';
import Confirm from '../../../../components/Confirm';
import { history } from '../../../../configureStore';

export interface MembersListProps extends RouteComponentProps {
  byId: number[];
  byHash: MembersByHash;
  fetchMembers: (filters: ListFilters) => FetchMembersRequestAction;
  count: number;
  loading: boolean;
  limit?: number;
  clubId: number | null;
  deleteMemberFromClub: (memberId: number, clubId: number) => DeleteMemberRequestAction;
}

const MembersList: FC<MembersListProps> = ({
  fetchMembers, byHash, byId, count, loading, limit = DEFAULT_LIST_LIMIT,
  deleteMemberFromClub, clubId,
}) => {
  const [translate] = useTranslation();
  const { getParams } = params(history);
  const {
    onSortChange,
    sortOrder,
    setSortOrder,
    sortField,
    setSortField,
  } = useSorting(history);

  const {
    [TABLE_PAGE_URL_PARAM]: page,
    [TABLE_SORT_FIELD_URL_PARAM]: field,
    [TABLE_SORT_ORDER_URL_PARAM]: order,
  } = getParams();
  const [onPageChange, totalPages, showPagination] = usePagination(history, count, loading, limit);

  useEffect(() => {
    fetchMembers({
      page, field, order,
    });
    setSortField(field as string);
    setSortOrder(order as SortOrder);
  }, [order, fetchMembers, field, page, setSortField, setSortOrder]);

  const handleRemoveMember = (memberId: number) => () => {
    if (clubId) {
      deleteMemberFromClub(memberId, clubId);
    }
  };

  const handleRowClick = (id: number) => () => {
    history.push(MEMBER_ROUTE.replace(':memberId', String(id)));
  };

  const renderItems = () => byId.map((id: number) => (
    <Row key={id} itemOnClick={handleRowClick(id)}>
      <>
        <ProfileAvatar user={byHash[id] as UserType} key="avatar" />
        <Text bold key="text">{`${byHash[id].firstName} ${byHash[id].lastName}`}</Text>
      </>
      <div>
        {byHash[id].roles.reduce((acc, value) => (acc && acc.length ? `${acc}, ${translate(`${value}_ROLE`)}` : translate(`${value}_ROLE`)), '')}
      </div>
      <Text>{formatDate(byHash[id].dayOfBirth)}</Text>
      <Text>{formatDate(byHash[id].inClubSince)}</Text>
      <div className={styles['options-wrapper']} onClick={(e: any) => { e.stopPropagation(); }}>
        <Dropdown direction="left" icon={<Icon name="ellipsis vertical" color="blue" size="large" />}>
          <Dropdown.Menu>
            <Dropdown.Item
              text={translate('VIEW_MEMBER')}
              as={Link}
              to={MEMBER_ROUTE.replace(':memberId', String(id))}
            />
            <Confirm
              as={Dropdown.Item}
              headerText={translate('CONFIRM_REMOVE_MEMBER')}
              confirmText={translate('CONFIRM_REMOVE_MEMBER_TEXT')}
              onConfirm={handleRemoveMember(id)}
              triggerText={translate('REMOVE_MEMBER')}
              key="cancel"
            />
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </Row>
  ));

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Table
          columns={columns}
          loading={loading}
          onSortChange={onSortChange}
          sortOrder={sortOrder}
          sortField={sortField}
          invertedHeader
        >
          {renderItems()}
        </Table>
      </div>
      <div className={styles.pagination}>
        {showPagination && (
          <Pagination
            defaultActivePage={(page && +page) as number || 1}
            totalPages={(totalPages && +totalPages) as number}
            onPageChange={onPageChange as () => void}
          />
        )}
      </div>
    </div>
  );
};

export default MembersList;
