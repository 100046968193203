import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';

import { DELETE_MEMBERS_REQUEST, DELETE_MEMBERS_SUCCESS, DELETE_MEMBERS_ERROR } from '../../constants/actionTypes';
import { DeleteMembersRequestAction, NotificationType } from '../../models/actions';
import { State } from '../../models/State';
import { DELETE_MEMBERS_PATH } from '../../constants/paths';
import { addNotification } from '../../actions/notifications';
import { handleError } from '../../actions/errors';
import { deleteMembersError, deleteMembersSuccess } from '../../actions/members';
import { setSelectedPlayers } from '../../actions/players';
import { setSelectedStaff } from '../../actions/staff';

export default (
  action$: ActionsObservable<DeleteMembersRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(DELETE_MEMBERS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      teamId, personIds,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: DELETE_MEMBERS_PATH.replace('$teamId', `${teamId}`),
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify({ personIds }),
  }).pipe(
    mergeMap(() => [
      addNotification(NotificationType.SUCCESS, DELETE_MEMBERS_SUCCESS),
      setSelectedPlayers([]),
      setSelectedStaff([]),
      deleteMembersSuccess(personIds),
    ]),
    catchError((error: AjaxError) => [
      handleError(error, DELETE_MEMBERS_ERROR),
      deleteMembersError(error),
    ]),
  )),
);
