import { Column } from '../../../../components/Table/Column';

const columns: Column[] = [{
  size: 9,
  header: 'ARTICLES',
  align: 'left',
  key: 'name',
}, {
  size: 1,
  key: 'menu',
  align: 'center',
}];

export default columns;
