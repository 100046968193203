import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import get from 'lodash/get';
import { InitializeOrderAction } from '../../../models/actions';
import { State } from '../../../models/State';
import { INITIALIZE_ORDER } from '../../../constants/actionTypes';
import { fetchCatalogRequest } from '../../../actions/catalogs';
import { fetchEmbellishmentsRequest } from '../../../actions/embellishments';
import { fetchStaffRequest } from '../../../actions/staff';
import { fetchTeamRequest } from '../../../actions/teams';
import {
  fetchNumbersSettingsRequest,
  fetchOrderRequest,
  lockNumbers,
} from '../../../actions/orders';

export const initializeOrder = (
  action$: ActionsObservable<InitializeOrderAction>,
  state$: StateObservable<State>,
) => action$.pipe(
  ofType(INITIALIZE_ORDER),
  withLatestFrom(state$),
  mergeMap(([
    {
      payload: { teamId, orderId },
    }, {
      teams: {
        byHash,
      },
    },
  ]) => [
    fetchCatalogRequest(teamId),
    fetchEmbellishmentsRequest(),
    fetchTeamRequest(teamId),
    fetchStaffRequest(teamId),
    ...(!orderId ? [lockNumbers(get(byHash[teamId], 'lockedNumbers', []))] : []),
    ...(orderId ? [fetchOrderRequest(orderId)] : []),
    ...(orderId ? [fetchNumbersSettingsRequest(orderId)] : []),
  ]),
);
