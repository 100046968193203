import { Player } from '../../../models/Player';

export const getEmergencyContactSection = ({ emergencyContact }: Player) => {
  const { name = '', relation = '', phoneNumber = '' } = emergencyContact || {};

  return [
    { name: 'EMERGENCY_CONTACT.NAME_OF_CONTACT', value: name },
    { name: 'EMERGENCY_CONTACT.ROLE', value: relation },
    { name: 'EMERGENCY_CONTACT.TELEPHONE_NUMBER', value: phoneNumber, wideColumn: true },
  ];
};
