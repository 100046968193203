import { AjaxError } from 'rxjs/ajax';

import { ERROR } from '../constants/actionTypes';
import { HandleErrorAction } from '../models/actions';

export const handleError = (error: AjaxError, actionType: string): HandleErrorAction => ({
  type: ERROR,
  payload: {
    error,
    actionType,
  },
});
