import React, { FC } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import styles from '../../Content.module.scss';
import { formatDate, formatTime } from '../../../../../../utils/dateUtils';
import { Match, MatchPlace } from '../../../../../../models/Calendar';
import { MATCH_PLACE } from '../../../MatchEventModal/MatchEventModalProps';
import { getUpperCasedDay } from '../../../../methods/TimePickerUtil';

export interface MatchContentProps {
  event: Match;
}

const MatchContent: FC<MatchContentProps> = ({
  event: {
    match, interval, location, description,
  },
}) => {
  const [translate] = useTranslation();
  return (
    <>
      <div className={styles.content}>
        <div className={styles.half}>
          <div className={styles.formGroup}>
            <div>{translate('OPPONENT_TEAM')}</div>
            <div>{match.oppositeTeamName}</div>
          </div>
        </div>
        <div className={styles.half}>
          <div className={styles.formGroup}>
            <div>{translate('COMPETITION')}</div>
            <div>{match.matchType}</div>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={[styles.half, styles.content].join(' ')} style={{ marginBottom: 0 }}>
          <div className={styles.half}>
            <div className={styles.formGroup}>
              <div>{translate('DAY')}</div>
              <div>{interval && translate(getUpperCasedDay(interval.startDate))}</div>
            </div>
          </div>
          <div className={styles.half}>
            <div className={styles.formGroup}>
              <div>{translate('DATE')}</div>
              <div>{interval && formatDate(interval.startDate)}</div>
            </div>
          </div>
        </div>
        <div className={styles.half}>
          <div className={styles.formGroup}>
            <div>{translate('TIME')}</div>
            <div className={styles.time}>
              <div>{interval && formatTime(interval.startTime)}</div>
              <div>to</div>
              <div>{interval && formatTime(interval.endTime)}</div>
              <Checkbox
                radio
                label={translate(MatchPlace.HOME)}
                name={MATCH_PLACE}
                disabled={match.matchPlace !== MatchPlace.HOME}
                checked={match.matchPlace === MatchPlace.HOME}
                className={[styles.checkbox, styles.radio, styles.filled].join(' ')}
              />
              <Checkbox
                radio
                label={translate(MatchPlace.AWAY)}
                name={MATCH_PLACE}
                disabled={match.matchPlace !== MatchPlace.AWAY}
                checked={match.matchPlace === MatchPlace.AWAY}
                className={[styles.checkbox, styles.radio, styles.filled].join(' ')}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.dominant}>
          <div className={styles.formGroup}>
            <div>{translate('MEETING_POINT')}</div>
            <div>{location && location.displayName}</div>
          </div>
        </div>
        <div className={styles.half}>
          <div className={styles.formGroup}>
            <div>{translate('MEETING_TIME')}</div>
            <div>{formatTime(match.meetingTime)}</div>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.dominant}>
          <div className={styles.formGroup}>
            <div>{translate('ADDRESS')}</div>
            <div>{match.matchLocation && match.matchLocation.displayName}</div>
          </div>
        </div>
        <div className={styles.half}>
          <div className={styles.formGroup}>
            <div>{translate('PITCH')}</div>
            <div>{match.pitch}</div>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.formGroup}>
          <div>{translate('INFORMATION')}</div>
          <div>{description}</div>
        </div>
      </div>
    </>
  );
};

export default MatchContent;
