import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import Profile from './Profile';
import { State } from '../../models/State';
import { resetPasswordTokenRequest } from '../../packages/authentication';
import { uploadAvatarRequest } from '../../actions/user';

const mapStateToProps = (
  { user: { user, loadingUpload } }: State,
) => ({
  user,
  loadingUpload,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetPasswordToken: bindActionCreators(resetPasswordTokenRequest, dispatch),
  uploadAvatar: bindActionCreators(uploadAvatarRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
