import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import DeleteEventModal from './DeleteEventModal';
import { State } from '../../../../models/State';
import { deleteEventRequest, bulkDeleteEventRequest } from '../../../../actions/calendar';

const mapStateToProps = ({
  clubs: { logoUrl },
  calendar: { event },
}: State) => ({
  clubLogoUrl: logoUrl,
  event,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  deleteEvent: bindActionCreators(deleteEventRequest, dispatch),
  bulkDeleteEvents: bindActionCreators(bulkDeleteEventRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteEventModal);
