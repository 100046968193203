import React, { SyntheticEvent } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { useParams } from 'react-router-dom';
import {
  Checkbox, CheckboxProps, DropdownItemProps, Image, Input, InputOnChangeData, Popup,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import DropdownWithLock from '../../../../../../components/DropdownWithLock';
import InputWithLock from '../../../../../../components/InputWithLock';
import PriceDiscountsPopup from '../../../../../ProductDetails/components/PriceDiscountsPopup';
import ProfileAvatar from '../../../../../PlayerProfile/components/ProfileAvatar';
import Row from '../../../../../../components/Table/components/Row';
import Text from '../../../../../../components/Text';
import getMissingProperties from '../../../../methods/getMissingProperties';
import styles from './DesktopItem.module.scss';
import useImage from '../../../../../../hooks/useImage';
import { DesktopItemProps } from './DesktopItemProps';
import { LockedProperties } from '../../../../../../constants/lockedProperties';
import { OrderItemType } from '../../../../OrderPreparationProps';
import { OrderStatus } from '../../../../../../constants/orderStatus';
import {
  Embellishment, EmbellishmentTypes, EmbellishmentValueType, ItemEmbellishment,
} from '../../../../../../models/Embellishment';
import { EmbellishmentGroup } from '../../embellishmentTypes';

const DesktopItem = ({
  changeProperty,
  checked,
  clubId,
  embellishmentsByHash,
  embellishmentsColumns,
  fetchFile,
  filesByHash,
  item,
  itemType,
  lockedNumbers,
  orderStatus,
  productsByHash,
  toggleSelection,
  usedNumbers,
  member,
  member: { firstName, lastName },
  product: {
    name, files, originalImages, availableSizes,
    embellishments: productEmbellishments, originalPrice, discountPercent,
  },
}: DesktopItemProps) => {
  const [translate] = useTranslation();
  const [img] = useImage(files, originalImages, filesByHash, fetchFile);
  const teamId = +get(useParams(), 'teamId');
  const { embellishments } = item;
  const playerNameTypes = [EmbellishmentTypes.PLAYER_NAME, EmbellishmentTypes.SMALL_PLAYER_NAME];
  const itemEmbellishments: Embellishment[] = productEmbellishments
    .filter(({ embellishment }) => embellishments.some(({ type }) => type === embellishment))
    .map(({ embellishment }) => embellishmentsByHash[embellishment]);

  const changeQuantity = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    changeProperty(item.externalId, 'quantity', +value);
  };

  const changeMaxQuantity = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    changeProperty(item.externalId, 'maxQuantity', +value);
  };

  const changeSize = (event: SyntheticEvent, { text }: DropdownItemProps) => {
    changeProperty(item.externalId, 'size', text as string);
  };

  const toggleItemLock = (property: LockedProperties) => () => {
    changeProperty(item.externalId, property, !item[property]);
  };

  const changeEmbellishment = (
    embellishmentTypes: EmbellishmentTypes[], property: 'value' | 'locked', value: any,
  ) => {
    const indexes = embellishments.reduce((a: number[], e: ItemEmbellishment, i: number) => {
      if (embellishmentTypes.includes(e.type)) {
        a.push(i);
      }
      return a;
    }, []);

    const enrichedEmbellishments: ItemEmbellishment[] = JSON.parse(
      JSON.stringify(embellishments),
    );
    indexes.forEach(index => {
      enrichedEmbellishments[index][property] = value;
    });

    changeProperty(item.externalId, 'embellishments', enrichedEmbellishments);
  };

  const changeEmbellishmentValue = (
    embellishmentType: EmbellishmentTypes, valueType: EmbellishmentValueType,
  ) => (event: SyntheticEvent, data: InputOnChangeData | DropdownItemProps | CheckboxProps) => {
    const value = data.value || data.text || data.checked;
    const embellishmentValue = valueType === EmbellishmentValueType.INTEGER ? +value : value;

    let embellishmentTypes;
    switch (embellishmentType) {
      case EmbellishmentTypes.BACK_NUMBER:
      case EmbellishmentTypes.SMALL_NUMBER:
        embellishmentTypes = EmbellishmentGroup.NUMBER_TYPES;
        break;
      case EmbellishmentTypes.PLAYER_NAME:
      case EmbellishmentTypes.SMALL_PLAYER_NAME:
        embellishmentTypes = playerNameTypes;
        break;
      default:
        embellishmentTypes = [embellishmentType];
    }

    changeEmbellishment(embellishmentTypes, 'value', embellishmentValue);
  };

  const toggleLockedEmbellishment = (embellishmentType: EmbellishmentTypes) => () => {
    const embellishment = embellishments.find(({ type }) => type === embellishmentType);

    changeEmbellishment([embellishmentType], 'locked', !(embellishment && embellishment.locked));
  };

  const showSizeField = availableSizes && availableSizes.some(Boolean);
  const missingProps = getMissingProperties([item], productsByHash);
  const showDangerState = orderStatus === OrderStatus.SENT_TO_TEAM && !!missingProps.length;
  const isNumberLabelInvalid = showDangerState && missingProps.includes('number');
  const isInitialsLabelInvalid = showDangerState && missingProps.includes('initialsLabel');
  const isSizeInvalid = showDangerState && missingProps.includes('size');

  const renderEmpty = (key?: number | string) => (
    <div className={styles.placeholder} key={key} />
  );

  const renderNumberField = ({ type, value, locked }: ItemEmbellishment) => (
    <DropdownWithLock
      error={isNumberLabelInvalid}
      isLocked={!!locked}
      key={type}
      value={value as string | number | undefined || ''}
      toggleLocked={toggleLockedEmbellishment(type)}
      options={[...Array(100).keys()].filter(num => (
        !lockedNumbers.includes(num)
        && (!usedNumbers[num] || usedNumbers[num] === item.playerId)
      )).map(num => ({
        key: num,
        text: num,
        onClick: changeEmbellishmentValue(type, EmbellishmentValueType.INTEGER),
      }))}
    />
  );

  const renderInputField = (
    { type, value, locked }: ItemEmbellishment,
    valueType: EmbellishmentValueType,
  ) => (
    <InputWithLock
      error={isInitialsLabelInvalid}
      isLocked={!!locked}
      key={type}
      value={value || ''}
      onChange={changeEmbellishmentValue(type, valueType)}
      toggleLocked={toggleLockedEmbellishment(type)}
      type={valueType === EmbellishmentValueType.INTEGER ? 'number' : 'text'}
      {...(playerNameTypes.includes(type) && { value: value || '' })}
    />
  );

  const renderCheckboxField = (
    { type, value, mandatory }: any,
    valueType: EmbellishmentValueType,
  ) => {
    const catalogMatch = productEmbellishments.find(pce => pce.embellishment === type);

    return (
      <div className={styles.dynamicCheckbox} key={type}>
        <Popup
          content={translate('EMBELLISHMENT_MANDATORY_BY_SALES_REP')}
          disabled={(catalogMatch && !catalogMatch.mandatory) || false}
          position="bottom center"
          trigger={(
            <Checkbox
              disabled={(catalogMatch && catalogMatch.mandatory) || false}
              key={type}
              checked={mandatory ? true : !!value}
              onChange={changeEmbellishmentValue(type, valueType)}
            />
          )}
        />
      </div>
    );
  };

  const renderEmbellishment = (type: EmbellishmentTypes) => {
    const itemEmbellishment = embellishments.find(embellishment => embellishment.type === type);
    const { valueType } = embellishmentsByHash[type];
    if (!itemEmbellishment) {
      return renderEmpty(type);
    }
    if (EmbellishmentGroup.NUMBER_TYPES.includes(type)) {
      return renderNumberField(itemEmbellishment);
    }
    if (EmbellishmentGroup.BOOLEAN_TYPES.includes(type)) {
      return renderCheckboxField(itemEmbellishment, valueType);
    }
    return renderInputField(itemEmbellishment, valueType);
  };

  return (
    <Row key={item.id} classNames={classNames(styles.wrapper, showDangerState && styles.danger)}>
      <div className={styles.teamMember}>
        <Checkbox onClick={toggleSelection} checked={checked} />
        {itemType === OrderItemType.PRODUCT ? [
          <div className={styles.img} key="img">
            {!!img && <Image src={img} className={styles.image} />}
          </div>,
          <Text bold key="text">{name}</Text>,
        ] : [
          <ProfileAvatar user={member} key="avatar" />,
          <Text bold key="text">{`${firstName} ${lastName}`}</Text>,
        ]}
      </div>
      {itemType === OrderItemType.PRODUCT && clubId ? (
        <PriceDiscountsPopup
          clubId={clubId}
          teamId={teamId}
          productEmbellishments={productEmbellishments}
          embellishments={itemEmbellishments}
          price={originalPrice}
          productDiscountPercent={discountPercent}
          underline={false}
        />
      ) : null}
      <InputWithLock
        isLocked={item.quantityLocked}
        max={item.maxQuantity}
        min={1}
        onChange={changeQuantity}
        toggleLocked={toggleItemLock(LockedProperties.quantityLocked)}
        type="number"
        value={item.quantity}
      />
      <Input
        className="full"
        min={item.quantity}
        onChange={changeMaxQuantity}
        type="number"
        value={item.maxQuantity}
      />
      {!showSizeField ? renderEmpty() : (
        <DropdownWithLock
          error={isSizeInvalid}
          isLocked={item.sizeLocked}
          toggleLocked={toggleItemLock(LockedProperties.sizeLocked)}
          value={item.size}
          options={availableSizes.map(size => ({
            key: size,
            text: size,
            onClick: changeSize,
          }))}
        />
      )}
      {embellishmentsColumns.map(renderEmbellishment) as any}
    </Row>
  );
};

export default DesktopItem;
