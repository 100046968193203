import { StaffMember } from './Staff';
import { Player } from './Player';

export enum MemberIdKey {
  PLAYER_ID = 'playerId',
  STAFF_ID = 'staffId',
}

export interface Member extends StaffMember, Player {}

export enum MemberAssignType {
  COPY = 'COPY',
  MOVE = 'MOVE',
}
