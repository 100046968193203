import React, { FC } from 'react';

import { MemberDetailsProps } from './MemberDetailsProps';
import { useViewport } from '../../../../hooks/useViewport';
import MobileMemberDetails from './components/MobileMemberDetails';
import DesktopMemberDetails from './components/DesktopMemberDetails';

const MemberDetails: FC<MemberDetailsProps> = ({
  open,
  member,
  onClose,
  itemType,
  itemIdKey,
}) => {
  const { isMobile } = useViewport();
  const MemberView = isMobile ? MobileMemberDetails : DesktopMemberDetails;

  return (
    <MemberView
      open={open}
      member={member}
      onClose={onClose}
      itemIdKey={itemIdKey}
      itemType={itemType}
    />
  );
};

export default MemberDetails;
