import { connect } from 'react-redux';
import Footer from './Footer';
import { State } from '../../../../models/State';

const mapStatesToProps = ({
  orders: {
    selectedPlayersIds, selectedProductsIds, selectedStaffIds,
  },
}: State) => ({
  selectedPlayersIds,
  selectedProductsIds,
  selectedStaffIds,
});

export default connect(mapStatesToProps)(Footer);
