import React, { FC, useEffect, useState } from 'react';
import difference from 'lodash/difference';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';

import MobileItemsList from '../MobileItemsList';
import SelectProductsModal from '../../../SelectProductsModal';
import changeProperty from '../../../../methods/changeProperty';
import styles from './MobileMemberDetails.module.scss';
import { Item, ItemsByHash } from '../../../../../../models/Order';
import { MobileMemberDetailsProps } from './MobileMemberDetailsProps';
import { OrderItemType } from '../../../../OrderPreparationProps';
import MobileFooter from '../../../../../../components/MobileFooter';
import MobileModal from '../../../MobileModal';

const MobileMemberDetails: FC<MobileMemberDetailsProps> = ({
  open,
  member: {
    firstName, lastName,
  },
  member,
  onClose,
  itemsByHash,
  itemsById,
  upsertItems,
  removeItems,
  itemType,
  addItemsForPlayers,
  addItemsForSelectedStaffMembers,
}) => {
  const [translate] = useTranslation();
  const [editableItemsById, setEditableItemsById] = useState<string[]>(itemsById);
  const [editableItemsByHash, setEditableItemsByHash] = useState<ItemsByHash>(itemsByHash);
  const [selectedModalOpened, setSelectedModalOpened] = useState<boolean>(false);

  const setCurrentState = () => {
    setEditableItemsById(itemsById);
    setEditableItemsByHash(savedItemsByHash => ({
      ...itemsByHash,
      ...savedItemsByHash,
    }));
  };

  useEffect(setCurrentState, [itemsById]); // eslint-disable-line react-hooks/exhaustive-deps

  const setModal = (value: boolean) => () => {
    setSelectedModalOpened(value);
  };

  const close = () => {
    onClose();
    setEditableItemsByHash({});
  };

  const onSave = () => {
    onClose();
    upsertItems(editableItemsById.map((itemId: string) => editableItemsByHash[itemId]));
    removeItems(difference(itemsById, editableItemsById));
  };

  const onSubmit = (items: Item[]) => {
    const itemIds = items.map(({ productCatalogId }) => productCatalogId);
    if (itemType === OrderItemType.STAFF) {
      addItemsForSelectedStaffMembers(itemIds, [member.id]);
    } else {
      addItemsForPlayers(itemIds, [member.id]);
    }
    setModal(false)();
  };

  return (
    <>
      <MobileModal
        button={(
          <MobileFooter
            buttonText={translate('SAVE')}
            onClick={onSave}
          />
        )}
        content={(
          <div className={styles.contentWrapper}>
            <div className={styles.addArticleWrapper}>
              <Button
                primary
                className={styles.addNewArticle}
                content={translate('ADD_A_NEW_ARTICLE')}
                onClick={setModal(true)}
              />
            </div>
            <div className={styles.listWrapper}>
              <MobileItemsList
                changeProperty={changeProperty(editableItemsByHash, setEditableItemsByHash)}
                itemsById={editableItemsById}
                itemsByHash={editableItemsByHash}
                itemType={OrderItemType.PRODUCT}
              />
            </div>
          </div>
        )}
        border
        isOpen={open}
        title={`${firstName} ${lastName}`}
        onClose={close}
        onMount={setCurrentState}
      />
      <SelectProductsModal
        isOpen={selectedModalOpened}
        onClose={setModal(false)}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default MobileMemberDetails;
