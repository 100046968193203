import React, { FC } from 'react';

import { OrdersProps } from './OrdersProps';
import MobileOrders from './components/OrdersMobile';
import DesktopOrders from './components/OrdersDesktop';
import { useViewport } from '../../hooks/useViewport';

const Orders: FC<OrdersProps> = ({
  count,
  deleteOrders,
  fetchOrders,
  loading,
  orders,
  ordersDeleted,
  teamId,
}) => {
  const { isMobile } = useViewport();

  return isMobile ? (
    <MobileOrders
      count={count}
      fetchOrders={fetchOrders}
      loading={loading}
      orders={orders}
      teamId={teamId}
    />
  ) : (
    <DesktopOrders
      count={count}
      deleteOrders={deleteOrders}
      fetchOrders={fetchOrders}
      loading={loading}
      orders={orders}
      ordersDeleted={ordersDeleted}
      teamId={teamId}
    />
  );
};

export default Orders;
