import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { REHYDRATE } from 'redux-persist';
import { filter, map, withLatestFrom } from 'rxjs/operators';

import { RehydrateAction } from '../../models/actions';
import { State } from '../../models/State';
import { fetchClubLogoRequest } from '../../actions/clubs';

export default (
  action$: ActionsObservable<RehydrateAction>,
  state$: StateObservable<State>,
) => action$.pipe(
  ofType(REHYDRATE),
  withLatestFrom(state$),
  filter(([, { application: { clubId } }]) => !!clubId),
  map(() => fetchClubLogoRequest()),
);
