import React, { useEffect, useState } from 'react';
import { DropdownItemProps, DropdownProps, Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { TeamBase } from '../../../models/Team';
import { FilterComponentProps } from '../../Table/components/TableFilter/TableFilterProps';
import { FetchTeamIdRequestAction } from '../../../models/actions/Teams';
import styles from './TeamFilter.module.scss';

interface TeamFilterProps extends FilterComponentProps {
  loading: boolean;
  error: boolean;
  teams: TeamBase[];
  clubId: number | null;
  fetchTeams: (clubId: number) => FetchTeamIdRequestAction;
}

const TeamFilter = ({
  onFilterChange,
  filterValue,
  column,
  error,
  loading,
  teams,
  clubId,
  fetchTeams,
}: TeamFilterProps) => {
  const [translate] = useTranslation();
  const [teamsOptions, setTeamsOptions] = useState<DropdownItemProps[]>([]);
  const [teamIds, setTeamIds] = useState<number[]>([]);

  if (!teams || !teams.length) {
    fetchTeams(clubId as number);
  }

  useEffect(() => {
    if (filterValue) {
      setTeamIds(filterValue as number[]);
    }
  }, [filterValue]);

  useEffect(() => {
    setTeamsOptions(teams.map((team: TeamBase) => ({
      value: team.id,
      text: team.name,
      key: team.id,
    })));
  }, [teams]);

  const handleTeamsChange = (e: any, data: DropdownProps) => {
    setTeamIds(data.value as number[]);
    if (onFilterChange) {
      onFilterChange({
        [column]: (data.value as number[]).length ? data.value as number[] : null,
      });
    }
  };
  return (
    <div className={styles.wrapper}>
      <Form id="teams-filter-form">
        <Form.Field
          className={styles.formField}
          key="teams-id"
        >
          <label>{translate('TEAMS')}</label>
          <div className={styles.inputWithLabel}>
            <Form.Dropdown
              name="teams-id-select"
              onChange={handleTeamsChange}
              placeholder={translate('TEAMS')}
              options={teamsOptions}
              disabled={loading || error}
              loading={loading}
              selection
              clearable
              multiple
              search
              value={teamIds || []}
            />
          </div>
        </Form.Field>
      </Form>
    </div>
  );
};

export default TeamFilter;
