import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { State } from '../../../../models/State';
import { Sidebar } from './Sidebar';

const mapStateToProps = ({
  authentication: {
    authToken,
  },
  user: { user },
}: State) => ({
  isAuthenticated: !!authToken,
  user,
});

export default withRouter(connect(mapStateToProps)(Sidebar));
