import { filter, map, tap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { ERROR } from '../constants/actionTypes';
import * as HttpStatus from '../constants/httpStatuses';
import { unauthorized } from '../actions/error';

export default () => action$ => action$.pipe(
  ofType(ERROR),
  filter(({ payload: { error: { status } } }) => status === HttpStatus.UNAUTHORIZED),
  tap(() => localStorage.clear()),
  map(unauthorized),
);
