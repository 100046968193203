import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import XlsModal from './XlsModal';
import { State } from '../../../../../../models/State';
import { fetchFileRequest } from '../../../../../../actions/files';

const mapStateToProps = ({ files: { byHash } }: State) => ({
  filesByHash: byHash,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchFile: bindActionCreators(fetchFileRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(XlsModal);
