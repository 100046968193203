import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import OneTimeEventHeader from './OneTimeEventHeader';
import { State } from '../../../../models/State';
import { cancelEventRequest, deleteEventRequest } from '../../../../actions/calendar';

const mapStateToProps = ({
  clubs: { logoUrl },
  calendar: { event, eventRemoved },
}: State) => ({
  clubLogoUrl: logoUrl,
  event,
  eventRemoved,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  deleteEvent: bindActionCreators(deleteEventRequest, dispatch),
  cancelEvent: bindActionCreators(cancelEventRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OneTimeEventHeader);
