import { Actions } from '../models/actions';
import { FETCH_MEMBER_REQUEST, FETCH_MEMBER_SUCCESS, FETCH_MEMBER_ERROR } from '../constants/actionTypes';
import { PersonsReducer } from '../models/ClubManagement';

export const initialState: PersonsReducer = {
  byHash: {},
  loading: false,
  error: false,
};

export default (state = initialState, action: Actions) => {
  switch (action.type) {
    case FETCH_MEMBER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MEMBER_SUCCESS: {
      const { person } = action.payload;

      return {
        ...state,
        byHash: {
          ...state.byHash,
          [person.personId]: person,
        },
        loading: false,
      };
    }
    case FETCH_MEMBER_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
