import {
  catchError, mergeMap, withLatestFrom, map,
} from 'rxjs/operators';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';

import { FETCH_TAGS_ERROR, FETCH_TAGS_REQUEST } from '../../constants/actionTypes';
import { TAGS_PATH } from '../../constants/paths';
import { fetchTagsSuccess, fetchTagsError } from '../../actions/tags';
import { FetchTagsRequestAction } from '../../models/actions';
import { State } from '../../models/State';
import { Response } from '../../models/Response';
import { Tag } from '../../models/Tag';
import { handleError } from '../../actions/errors';

export default (
  action$: ActionsObservable<FetchTagsRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_TAGS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([, { authentication: { authToken } }]) => ajax({
    url: TAGS_PATH,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    map(({ response: tags }: Response<Tag[]>) => fetchTagsSuccess(tags)),
    catchError((error: AjaxError) => [
      handleError(error, FETCH_TAGS_ERROR),
      fetchTagsError(error),
    ]),
  )),
);
