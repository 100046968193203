import { useEffect } from 'react';

const useFocusFirstInput = () => {
  useEffect(() => {
    const input: NodeListOf<HTMLInputElement> = document.querySelectorAll('input:not(.hidden)');
    if (input.length) {
      input[0].focus();
    }
  }, []);
};

export default useFocusFirstInput;
