import React from 'react';

import getInitials from '../../../../../../utils/getInitials';
import styles from './Avatar.module.scss';
import { AvatarProps } from './AvatarProps';

const Avatar = ({ firstName, lastName }: AvatarProps) => {
  const initials = getInitials(firstName, lastName);

  return (
    <div
      className={styles.avatar}
    >
      <span>{initials}</span>
    </div>
  );
};

export default Avatar;
