import React, {
  FC, SyntheticEvent, useEffect, useState,
} from 'react';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import Seasons from '../../constants/seasons';
import { FilterComponentProps } from '../Table/components/TableFilter/TableFilterProps';

const SeasonFilter: FC<FilterComponentProps> = ({ column, onFilterChange, filterValue }) => {
  const [translate] = useTranslation();
  const [selectedSeason, setSelectedSeason] = useState<Seasons[]>([]);

  const onSeasonChange = (event: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    if (onFilterChange) {
      setSelectedSeason(data.value as Seasons[]);
      onFilterChange({
        [column]: data.value
          ? data.value as string : data.value as undefined,
      });
    }
  };

  useEffect(() => {
    if (filterValue && (filterValue as unknown as Seasons[]).length) {
      setSelectedSeason(filterValue as unknown as Seasons[]);
    }
  }, [filterValue]);
  const seasonOptions: DropdownItemProps[] = Object.keys(Seasons).map((season) => ({
    text: translate(season),
    value: season,
  } as DropdownItemProps));
  return (
    <Dropdown
      options={seasonOptions}
      placeholder={translate('SEASON')}
      selection
      multiple
      value={selectedSeason}
      onChange={onSeasonChange}
      clearable
    />
  );
};

export default SeasonFilter;
