import { ReactNode } from 'react';

export enum HeaderTypes {
  H2 = 'h2',
  H3 = 'h3',
}

export interface MobileHeaderProps {
  menuItems?: ReactNode;
  title?: string,
  subtitle?: string | null,
  titleType?: HeaderTypes,
  subtitleType?: HeaderTypes,
  border?: boolean,
  primaryTitle?: boolean,
  primarySubtitle?: boolean,
  titleEllipsis?: boolean,
}
