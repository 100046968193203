import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { DispatchProps, StateProps } from './CreateMemberProps';
import { State } from '../../../../models/State';
import { fetchTeamRequest } from '../../../../actions/teams';
import CreateMember from './CreateMember';
import { sendInvitationRequest } from '../../../../actions/invitations';
import { assignMemberRequest, fetchMembersRequest } from '../../../../actions/members';
import { createPlayerRequest } from '../../../../actions/players';
import { checkEmailAvailabilityRequest } from '../../../../actions/application';

const mapStatesToProps = (state: State): StateProps => {
  const {
    players: {
      error,
      loading,
    },
    teams,
    application: {
      clubId, userRole, emailAvailable, checkingEmail,
    },
    members,
  } = state;

  return ({
    loading,
    emailAvailable,
    byHash: teams.byHash,
    error,
    clubId,
    membersByHash: members.byHash,
    membersById: members.byId,
    userRole,
    checkingEmail,
  });
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  sendInvitation: bindActionCreators(sendInvitationRequest, dispatch),
  fetchTeam: bindActionCreators(fetchTeamRequest, dispatch),
  fetchMembers: bindActionCreators(fetchMembersRequest, dispatch),
  assignMember: bindActionCreators(assignMemberRequest, dispatch),
  createPlayer: bindActionCreators(createPlayerRequest, dispatch),
  checkEmail: bindActionCreators(checkEmailAvailabilityRequest, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(CreateMember);
