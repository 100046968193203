import { Article, ItemsByHash } from '../../../../models/Order';

export default (itemsById: string[], itemsByHash: ItemsByHash) => itemsById.reduce(
  (grouped: { [key: string]: Article }, id: string) => {
    const item = itemsByHash[id];
    return ({
      ...grouped,
      [item.productCatalogId]: {
        ...(grouped[item.productCatalogId] || item),
        quantity: (
          grouped[item.productCatalogId] ? grouped[item.productCatalogId].quantity : 0
        ) + (item.quantity || 0),
        maxQuantity: (
          grouped[item.productCatalogId] ? grouped[item.productCatalogId].maxQuantity : 0
        ) + (item.maxQuantity || 0),
        playerIds: [
          ...(grouped[item.productCatalogId] ? grouped[item.productCatalogId].playerIds : []),
          ...(item.playerId ? [item.playerId] : []),
        ],
        itemIds: [
          ...(grouped[item.productCatalogId] ? grouped[item.productCatalogId].itemIds : []),
          item.externalId,
        ],
        embellishments: item.embellishments,
      },
    });
  }, {},
);
