import React, { FC, useEffect, useState } from 'react';
import difference from 'lodash/difference';
import xor from 'lodash/xor';
import { Button, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import DesktopItemsList from '../DesktopItemsList';
import ProfileAvatar from '../../../../../PlayerProfile/components/ProfileAvatar';
import SelectProductsModal from '../../../SelectProductsModal';
import Text from '../../../../../../components/Text';
import changeEmbellishmentAllLock from '../../../../methods/changeEmbellishmentAllLock';
import changeProperty from '../../../../methods/changeProperty';
import styles from './DesktopMemberDetails.module.scss';
import { Item, ItemsByHash } from '../../../../../../models/Order';
import { DesktopMemberDetailsProps } from './DesktopMemberDetailsProps';
import { OrderItemType } from '../../../../OrderPreparationProps';
import FooterSelectedNumber from '../../../DesktopFooter/FooterSelectedNumber';

const DesktopMemberDetails: FC<DesktopMemberDetailsProps> = ({
  open,
  member: {
    firstName, lastName, preferredPosition,
  },
  member,
  onClose,
  itemsByHash,
  itemsById,
  upsertItems,
  removeItems,
  itemType,
  addItemsForPlayers,
  addItemsForSelectedStaffMembers,
}) => {
  const [translate] = useTranslation();
  const [editableItemsById, setEditableItemsById] = useState<string[]>(itemsById);
  const [editableItemsByHash, setEditableItemsByHash] = useState<ItemsByHash>(itemsByHash);
  const [selectedModalOpened, setSelectedModalOpened] = useState<boolean>(false);
  const [selectedItems, selectItems] = useState<string[]>([]);

  const setCurrentState = () => {
    setEditableItemsById(itemsById);
    setEditableItemsByHash({
      ...itemsByHash,
      ...editableItemsByHash,
    });
    selectItems([]);
  };

  useEffect(setCurrentState, [itemsById]); // eslint-disable-line react-hooks/exhaustive-deps

  const setModal = (value: boolean) => () => {
    setSelectedModalOpened(value);
  };

  const toggleItemValue = (
    setItemsValues: (items: string[]) => void,
    itemsValues: string[],
  ) => (itemId: string) => () => setItemsValues(xor(itemsValues, [itemId]));

  const toggleAllItems = (
    setItemsValues: (items: string[]) => void,
    itemsValues: string[],
  ) => () => setItemsValues(
    itemsById.length !== itemsValues.length ? itemsById : [],
  );

  const close = () => {
    onClose();
    setEditableItemsByHash({});
  };

  const onSave = () => {
    onClose();
    upsertItems(editableItemsById.map((itemId: string) => editableItemsByHash[itemId]));
    removeItems(difference(itemsById, editableItemsById));
  };

  const onSubmit = (items: Item[]) => {
    const itemIds = items.map(({ productCatalogId }) => productCatalogId);
    if (itemType === OrderItemType.STAFF) {
      addItemsForSelectedStaffMembers(itemIds, [member.id]);
    } else {
      addItemsForPlayers(itemIds, [member.id]);
    }
    setModal(false)();
  };

  const remove = () => {
    removeItems(selectedItems);
    selectItems([]);
  };

  return (
    <>
      <Modal
        onClose={onClose}
        open={open}
        onMount={setCurrentState}
        size="large"
      >
        <div className={styles.wrapper}>
          <div className={styles.topGroup}>
            <div className={styles.container}>
              <div className={styles.article}>
                <ProfileAvatar user={member} size="medium" />
                <div className={styles.text}>
                  <Text bold>
                    {`${firstName} ${lastName}`}
                  </Text>
                  <Text subtitle>
                    {preferredPosition ? translate(preferredPosition) : ''}
                  </Text>
                </div>
              </div>
              <div className={styles.buttons}>
                <Button primary onClick={close} content={translate('CANCEL')} basic />
                <Button primary onClick={onSave} content={translate('SAVE')} />
              </div>
            </div>
            <div className={styles.newArticle}>
              <Button
                basic
                primary
                className="simple"
                content={translate('ADD_A_NEW_ARTICLE')}
                onClick={setModal(true)}
              />
            </div>
            <div className={styles.contentWrapper}>
              <DesktopItemsList
                selected={selectedItems}
                changeProperty={changeProperty(editableItemsByHash, setEditableItemsByHash)}
                changeEmbellishmentAllLock={changeEmbellishmentAllLock(
                  editableItemsByHash, setEditableItemsByHash,
                )}
                itemsById={editableItemsById}
                itemsByHash={editableItemsByHash}
                toggleAllSelection={toggleAllItems(selectItems, selectedItems)}
                toggleSelection={toggleItemValue(selectItems, selectedItems)}
                itemType={OrderItemType.PRODUCT}
              />
            </div>
          </div>
          <div className={styles.footer}>
            <FooterSelectedNumber text={translate('ARTICLE_SELECTED_WITH_COUNT', { count: selectedItems.length })} />
            <Button
              circular
              disabled={!selectedItems.length}
              onClick={remove}
              primary
              content={translate('DELETE_ARTICLE_WITH_COUNT', { count: selectedItems.length })}
            />
          </div>
        </div>
      </Modal>
      <SelectProductsModal
        isOpen={selectedModalOpened}
        onClose={setModal(false)}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default DesktopMemberDetails;
