import { AjaxError } from 'rxjs/ajax';
import {
  FetchIndividualOrdersError,
  FetchIndividualOrdersRequest,
  FetchIndividualOrdersSuccess,
} from '../models/actions';
import {
  FETCH_INDIVIDUAL_ORDERS_ERROR,
  FETCH_INDIVIDUAL_ORDERS_REQUEST,
  FETCH_INDIVIDUAL_ORDERS_SUCCESS,
} from '../constants/actionTypes';
import { IndividualOrderListItem } from '../models/IndividualOrder';

const fetchIndividualOrdersDefaultPayload = {
  page: 1,
  search: '',
};

export const fetchIndividualOrdersRequest = (payload = {}): FetchIndividualOrdersRequest => ({
  type: FETCH_INDIVIDUAL_ORDERS_REQUEST,
  payload: {
    ...fetchIndividualOrdersDefaultPayload,
    ...payload,
  },
});

export const fetchIndividualOrdersSuccess = (
  payload: { orders: IndividualOrderListItem[], count: number },
): FetchIndividualOrdersSuccess => ({
  type: FETCH_INDIVIDUAL_ORDERS_SUCCESS,
  payload,
});

export const fetchIndividualOrdersError = (error: AjaxError): FetchIndividualOrdersError => ({
  type: FETCH_INDIVIDUAL_ORDERS_ERROR,
  payload: {
    error,
  },
});
