import {
  EditTeamRequestAction, FetchClubRequestAction,
  FetchTeamIdRequestAction, FetchTeamRequestAction,
} from '../../models/actions';
import { Team, TeamsByHash } from '../../models/Team';

export type StateProps = {
  clubId: number | null;
  error: boolean;
  loading: boolean;
  errorMessage?: string;
  teamsByHash: TeamsByHash;
  fetchTeamId: (clubId: number) => FetchTeamIdRequestAction;
  fetchTeam: (id: number) => FetchTeamRequestAction;
  editTeam: (
    team: Team,
  ) => EditTeamRequestAction;
  fetchClubById: () => FetchClubRequestAction
};

export const ERROR_MESSAGE_TRANSLATIONS: { [key: string] : string } = {
  'team.paypal_url.invalid': 'UPDATE_TEAM.PAYPAL_URL_INVALID_ERROR',
};

export interface EditTeamTeamLeadProps extends StateProps {
}
