import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import {
  catchError, mergeMap, withLatestFrom,
} from 'rxjs/operators';
import { AjaxError } from 'rxjs/ajax';
import { DfbClubRequestAction, NotificationType } from '../../models/actions';
import { State } from '../../models/State';
import { DFB_CLUBS_ERROR, DFB_CLUBS_REQUEST, DFB_CLUBS_SUCCESS } from '../../constants/actionTypes';
import { SEARCH_EXTERNAL_CLUBS_PATH } from '../../constants/paths';
import { Response } from '../../models/Response';
import { handleError } from '../../actions/errors';
import { OfficialClubDto } from '../../models/externalSystemIntegration';
import { addNotification } from '../../actions/notifications';
import { dfbClubsSuccess } from '../../actions/externalSystems/dfbClubs';

export default (
  action$: ActionsObservable<DfbClubRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(DFB_CLUBS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      searchTerm,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: SEARCH_EXTERNAL_CLUBS_PATH.replace('$searchTerm', `${searchTerm}`),
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    mergeMap(({ response: clubs }: Response<OfficialClubDto[]>) => [
      dfbClubsSuccess(clubs),
      addNotification(NotificationType.SUCCESS, DFB_CLUBS_SUCCESS),
    ]),
    catchError((error: AjaxError) => [
      handleError(error, DFB_CLUBS_ERROR),
    ]),
  )),
);
