import queryString, { ParsedQuery } from 'query-string';
import pickBy from 'lodash/pickBy';

export default (history: any) => {
  const getParams = (): ParsedQuery => queryString.parse(history.location.search);

  const setParam = (name: string, value: any) => {
    const queryParams: string = queryString.stringify(pickBy({
      ...getParams(),
      [name]: value,
    }));

    const url = queryParams
      ? `${history.location.pathname}?${queryParams}`
      : history.location.pathname;

    history.push(url);
  };

  const setParams = (newParams: { [key: string]: string | null }) => {
    const queryParams: string = queryString.stringify(pickBy({
      ...getParams(),
      ...newParams,
    }));

    const url = queryParams
      ? `${history.location.pathname}?${queryParams}`
      : history.location.pathname;
    history.push(url);
  };

  const clearParams = () => {
    history.push(history.location.pathname);
  };

  return {
    setParam,
    setParams,
    getParams,
    clearParams,
  };
};
