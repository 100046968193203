import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Dimmer, Form, Loader, Message,
} from 'semantic-ui-react';
import get from 'lodash/get';
import { useParams } from 'react-router';

import FormFields from '../../components/FormFields';
import Header from '../../components/Header';
import styles from './EditTeamTeamLead.module.scss';
import useFocusFirstInput from '../../hooks/useFocusFirstInput';
import useForm from '../../hooks/useForm';
import { TEAM_VIEW_ROUTE } from '../../constants/routes';
import { EditTeamTeamLeadProps, ERROR_MESSAGE_TRANSLATIONS } from './EditTeamTeamLeadProps';
import { createTeamSchema, validationSchema } from './schema';

const EditTeamTeamLead = ({
  clubId,
  error,
  errorMessage,
  teamsByHash,
  loading,
  editTeam,
  fetchTeam,
}: EditTeamTeamLeadProps) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [translate] = useTranslation();
  const teamId = get(useParams(), 'teamId') as string;
  const team = teamsByHash[teamId];

  const {
    values,
    errors,
    handleChange,
    validate,
    setValues,
  } = useForm(validationSchema);

  useFocusFirstInput();

  useEffect(() => {
    if (teamId && clubId) {
      fetchTeam(+teamId);
    }
  }, [clubId, fetchTeam, teamId]);

  const handleSubmit = () => {
    setIsSubmitted(true);
    if (validate() && clubId) {
      editTeam(
        {
          ...team,
          name: values.teamName,
          paypalUrl: values.paypalUrl,
        },
      );
    }
  };

  const renderButton = () => (
    <Button
      primary
      disabled={loading}
      type="submit"
      content={translate('SAVE')}
      form="create-form"
    />
  );

  const renderErrorMessage = () => (
    <Message
      error={error}
      header={translate('ERROR')}
      content={
        (errorMessage && !!Object.keys(ERROR_MESSAGE_TRANSLATIONS).includes(errorMessage))
          ? translate(
            ERROR_MESSAGE_TRANSLATIONS[errorMessage as keyof typeof ERROR_MESSAGE_TRANSLATIONS],
          ) : translate('GENERAL_ERROR')}
    />
  );

  useEffect(() => {
    if (team) {
      setValues((currentValues: any) => ({
        ...currentValues,
        teamName: team.name || null,
        paypalUrl: team.paypalUrl || null,
      }));
    }
  }, [team, setValues]);

  return (
    <div className={styles.wrapper}>
      <Header
        backLabel="TO_MY_TEAMS"
        backLinkTo={TEAM_VIEW_ROUTE.replace(':teamId', teamId)}
        renderButton={renderButton}
        title={translate('EDIT_TEAM')}
        border
      />
      <Form error={error} onSubmit={handleSubmit} className={styles.form} id="create-form">
        {isSubmitted && errors && renderErrorMessage()}
        {loading ? (
          <Dimmer active inverted className={styles.dimmer}>
            <Loader />
          </Dimmer>
        ) : (
          <FormFields
            errors={errors}
            handleChange={handleChange}
            schema={createTeamSchema}
            values={values}
          />
        )}
      </Form>
    </div>
  );
};

export default EditTeamTeamLead;
