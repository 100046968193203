import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './DeleteAccountInstruction.module.scss';
import HeaderTag from '../../components/WelcomePage/HeaderTag';

const DeleteData: FC<any> = () => {
  const [translate] = useTranslation();

  return (
    <div className={styles.wrapper}>
      <HeaderTag successTitle="DELETE_ACCOUNT_INSTRUCTION.TITLE" />
      <h2 className={styles.header}>
        {translate('DELETE_ACCOUNT_INSTRUCTION.P1.HEADER')}
      </h2>
      <ol>
        <li className={styles.text}>
          <div className={styles.stepText}>{translate('DELETE_ACCOUNT_INSTRUCTION.P_1.STEP_1')}</div>
        </li>
        <li className={styles.text}>
          <div className={styles.stepText}>{translate('DELETE_ACCOUNT_INSTRUCTION.P_1.STEP_2')}</div>
          <div className={styles.screenshot}>
            <div className={styles.image}>
              <img src="/images/delete-account-instruction-step-2.webp" alt={translate('DELETE_ACCOUNT_INSTRUCTION.P_1.STEP_2')} />
            </div>
          </div>
        </li>
        <li className={styles.text}>
          <div className={styles.stepText}>{translate('DELETE_ACCOUNT_INSTRUCTION.P_1.STEP_3')}</div>
          <div className={styles.screenshot}>
            <div className={styles.image}>
              <img src="/images/delete-account-instruction-step-3.webp" alt={translate('DELETE_ACCOUNT_INSTRUCTION.P_1.STEP_3')} />
            </div>
          </div>
        </li>
        <li className={styles.text}>
          <div className={styles.stepText}>{translate('DELETE_ACCOUNT_INSTRUCTION.P_1.STEP_4')}</div>
          <div className={styles.screenshot}>
            <div className={styles.image}>
              <img src="/images/delete-account-instruction-step-4.webp" alt={translate('DELETE_ACCOUNT_INSTRUCTION.P_1.STEP_4')} />
            </div>
          </div>
        </li>
        <li className={styles.text}>
          <div className={styles.stepText}>{translate('DELETE_ACCOUNT_INSTRUCTION.P_1.STEP_5')}</div>
          <div className={styles.screenshot}>
            <div className={styles.image}>
              <img src="/images/delete-account-instruction-step-5.webp" alt={translate('DELETE_ACCOUNT_INSTRUCTION.P_1.STEP_5')} />
            </div>
          </div>
        </li>
        <li className={styles.text}>
          <div className={styles.stepText}>{translate('DELETE_ACCOUNT_INSTRUCTION.P_1.STEP_6')}</div>
        </li>
      </ol>
      <p className={[styles.text, styles.paragraphText].join(' ')}>{translate('DELETE_ACCOUNT_INSTRUCTION.P_1.CONCLUSION')}</p>
      <p className={[styles.separated, styles.text, styles.paragraphText].join(' ')}>{translate('DELETE_ACCOUNT_INSTRUCTION.P_1.SUPPORT')}</p>
      <h2 className={styles.header}>
        {translate('DELETE_ACCOUNT_INSTRUCTION.P2.HEADER')}
      </h2>
      <p className={[styles.text, styles.paragraphText].join(' ')}>{translate('DELETE_ACCOUNT_INSTRUCTION.P2.CONTENT')}</p>
    </div>
  );
};

export default DeleteData;
