import { AjaxError } from 'rxjs/ajax';
import {
  FETCH_CATALOG_REQUEST,
  FETCH_CATALOG_SUCCESS,
  FETCH_CATALOG_ERROR,
  INVALID_CATALOG,
} from '../constants/actionTypes';
import {
  FetchCatalogErrorAction,
  FetchCatalogRequestAction,
  FetchCatalogSuccessAction,
  InvalidCatalogAction,
} from '../models/actions';
import { Catalog } from '../models/Catalog';

export const fetchCatalogRequest = (
  activeTeam?: number,
): FetchCatalogRequestAction => ({
  type: FETCH_CATALOG_REQUEST,
  payload: {
    activeTeam,
  },
});

export const fetchCatalogSuccess = (catalog: Catalog): FetchCatalogSuccessAction => ({
  type: FETCH_CATALOG_SUCCESS,
  payload: {
    catalog,
  },
});

export const fetchCatalogError = (error: AjaxError): FetchCatalogErrorAction => ({
  type: FETCH_CATALOG_ERROR,
  payload: {
    error,
  },
});

export const invalidCatalog = (): InvalidCatalogAction => ({
  type: INVALID_CATALOG,
  payload: {},
});
