import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import DesktopItem from './DesktopItem';
import { State } from '../../../../../../models/State';
import { fetchFileRequest } from '../../../../../../actions/files';

const mapStatesToProps = ({
  application: {
    clubId,
  },
  files: {
    byHash,
  },
  orders: {
    orderStatus, usedNumbers, lockedNumbers,
  },
  catalog: {
    catalog: {
      productsByHash,
    },
    embellishmentsByHash,
  },
}: State) => ({
  clubId,
  embellishmentsByHash,
  productsByHash,
  filesByHash: byHash,
  lockedNumbers,
  orderStatus,
  usedNumbers,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchFile: bindActionCreators(fetchFileRequest, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(DesktopItem);
