import React from 'react';
import { useTranslation } from 'react-i18next';

import DesktopItem from '../DesktopItem';
import Table from '../../../../../../components/Table';
import TableCheckboxHeader from '../../../../../../components/TableCheckboxHeader';
import columns from './columns';
import { Column } from '../../../../../../components/Table/Column';
import { EmbellishmentTypes } from '../../../../../../models/Embellishment';
import { DesktopItemsListProps } from './DesktopItemsListProps';
import { LockedProperties } from '../../../../../../constants/lockedProperties';
import { Member } from '../../../../../../models/Member';
import { OrderItemType } from '../../../../OrderPreparationProps';
import { Product } from '../../../../../../models/Catalog';
import { SortFields } from '../../../../../../models/Order';
import { getItemsLockedSettings } from '../../../../methods/getItemsLockedSettings';
import { EmbellishmentGroup } from '../../embellishmentTypes';

const DesktopItemsList = ({
  changeProperty,
  changeEmbellishmentAllLock,
  itemType,
  itemsByHash,
  itemsById,
  playersByHash,
  productsByHash,
  selected,
  staffByHash,
  staffById,
  toggleAllSelection,
  toggleSelection,
  onSortClick = () => {},
  sortField,
  sortOrder,
}: DesktopItemsListProps) => {
  const [translate] = useTranslation();
  const allItemsLockedSettings = getItemsLockedSettings(itemsById, itemsByHash);

  const toggleAllItemsLockedSettings = (property: LockedProperties) => () => {
    changeProperty(itemsById, property, !allItemsLockedSettings[property]);
  };

  const toggleAllItemsLockedEmbellishment = (type: EmbellishmentTypes) => () => {
    changeEmbellishmentAllLock(itemsById, type, !allItemsLockedSettings[type]);
  };

  const embellishmentsColumns: EmbellishmentTypes[] = itemsById.reduce(
    (embellishments: EmbellishmentTypes[], itemId) => ([
      ...embellishments,
      ...itemsByHash[itemId].embellishments
        .map(({ type }) => type)
        .filter(type => !embellishments.includes(type)),
    ]), [],
  );

  const enrichedColumns = [{
    ...(itemType === OrderItemType.PRODUCT ? columns.PRODUCT : columns.PLAYERS),
    headerComponent: () => (
      <TableCheckboxHeader
        title={itemType === OrderItemType.PRODUCT ? 'PRODUCT.PRODUCT' : 'MEMBER'}
        onClick={toggleAllSelection}
        checked={selected.length === itemsById.length}
        checkbox
        sortable={itemType === OrderItemType.PLAYER}
        sortProps={sortField === SortFields.player ? sortOrder : null}
      />
    ),
  },
  ...(itemType === OrderItemType.PRODUCT ? [columns.VK] : []),
  {
    ...columns.QUANTITY,
    headerComponent: () => (
      <TableCheckboxHeader
        title="QUANTITY"
        onClick={toggleAllItemsLockedSettings(LockedProperties.quantityLocked)}
        checked={allItemsLockedSettings.quantityLocked}
      />
    ),
  },
  columns.MAX_QUANTITY,
  {
    ...columns.SIZE,
    headerComponent: () => (
      <TableCheckboxHeader
        title="SIZE"
        onClick={toggleAllItemsLockedSettings(LockedProperties.sizeLocked)}
        checked={allItemsLockedSettings.sizeLocked}
      />
    ),
  },
  ...embellishmentsColumns.map(type => ({
    width: '200px',
    header: translate(type),
    align: 'left',
    key: type,
    headerComponent: () => (
      <TableCheckboxHeader
        title={translate(type)}
        onClick={toggleAllItemsLockedEmbellishment(type)}
        checked={EmbellishmentGroup.BOOLEAN_TYPES.includes(type)
          ? true : allItemsLockedSettings[type]}
        disabled={EmbellishmentGroup.BOOLEAN_TYPES.includes(type)}
      />
    ),
  }) as Column),
  ];

  const getProduct = (id: string) => {
    const { productCatalogId } = itemsByHash[id];
    return productCatalogId ? productsByHash[productCatalogId] : {} as Product;
  };

  const getMember = (id: string) => {
    const { playerId, staffId } = itemsByHash[id];
    const player = playerId && playersByHash[playerId];
    const staff = staffById
      .map(itemId => staffByHash[itemId])
      .find(({ userId }) => userId === staffId);
    return player || staff || {};
  };

  const renderItems = () => (
    itemsById.filter(id => itemsByHash[id]).map((id: string) => (
      <DesktopItem
        changeProperty={changeProperty}
        checked={selected.includes(id)}
        embellishmentsColumns={embellishmentsColumns}
        item={itemsByHash[id]}
        itemType={itemType}
        key={id}
        member={getMember(id) as Member}
        product={getProduct(id)}
        toggleSelection={toggleSelection(id)}
      />
    ))
  );

  return (
    <Table
      columns={enrichedColumns}
      scrollable
      invertedHeader
      onSortChange={field => { onSortClick(field as SortFields); }}
    >
      {renderItems()}
    </Table>
  );
};

export default DesktopItemsList;
