import uniq from 'lodash/uniq';
import values from 'lodash/values';
import { OrderStatus } from '../constants/orderStatus';
import {
  CatalogActions, OrderActions, OrderEditorActions,
  SetSelectedPlayersAction, SetSelectedStaffAction,
} from '../models/actions';
import {
  Item, ItemsByHash, OrdersReducer,
} from '../models/Order';

import getUsedNumbers from '../utils/getUsedNumbers';
import {
  CLEAR_ORDER,
  DELETE_ORDERS_SUCCESS,
  FETCH_NUMBERS_SETTINGS_SUCCESS,
  FETCH_ORDER_ERROR,
  FETCH_ORDER_REQUEST,
  FETCH_ORDER_SUCCESS,
  INVALID_CATALOG,
  LOCK_NUMBERS,
  ORDER_SELECT_PLAYERS,
  ORDER_SELECT_PRODUCTS,
  ORDER_UPDATE_ITEMS,
  ORDER_UPDATE_PRICE_SUCCESS,
  ORDER_UPSERT_ITEMS,
  REMOVE_ITEMS,
  SET_SELECTED_PLAYERS,
  UPSERT_ORDER_SUCCESS,
  ORDER_SELECT_STAFF,
  SET_SELECTED_STAFF,
  UPSERT_ORDER_REQUEST,
  UPSERT_ORDER_ERROR,
} from '../constants/actionTypes';

export const initialState: OrdersReducer = {
  loading: false,
  loadingUpsert: false,
  error: false,
  orderStatus: OrderStatus.IN_PROGRESS,
  orderPrice: null,
  itemsById: [],
  itemsByHash: {},
  productsByItemId: [],
  selectedPlayersIds: [],
  selectedMembersTeam: null,
  selectedStaffIds: [],
  selectedProductsIds: [],
  lockedNumbers: [],
  usedNumbers: {},
  usedPlayerNumbers: {},
  draft: false,
  orderOutdated: false,
};

export default (
  state = initialState,
  action: OrderActions | OrderEditorActions | SetSelectedPlayersAction
  | SetSelectedStaffAction | CatalogActions,
) => {
  switch (action.type) {
    case INVALID_CATALOG:
      return {
        ...state,
        orderOutdated: true,
      };
    case ORDER_SELECT_STAFF:
    case SET_SELECTED_STAFF:
      return {
        ...state,
        selectedStaffIds: action.payload.ids,
        selectedMembersTeam: action.payload.teamId,
      };
    case ORDER_SELECT_PLAYERS:
    case SET_SELECTED_PLAYERS:
      return {
        ...state,
        selectedPlayersIds: action.payload.ids,
        selectedMembersTeam: action.payload.teamId,
      };
    case ORDER_SELECT_PRODUCTS:
      return {
        ...state,
        selectedProductsIds: action.payload.ids,
      };
    case ORDER_UPSERT_ITEMS: {
      const { items } = action.payload;
      const usedNumbers = getUsedNumbers(items, state.usedNumbers);

      const byId: string[] = uniq([
        ...state.itemsById,
        ...items.map((item: Item) => item.externalId),
      ]);

      const byHash: ItemsByHash = items.reduce((prev: ItemsByHash, current: Item) => ({
        ...prev,
        [current.externalId]: current,
      }), state.itemsByHash);

      return {
        ...state,
        itemsById: byId,
        itemsByHash: byHash,
        productsByItemId: [
          ...new Set(byId.map((id: string) => byHash[id].productCatalogId)),
        ],
        usedNumbers,
        draft: true,
      };
    }
    case ORDER_UPDATE_ITEMS: {
      const { items } = action.payload;
      const usedNumbers = getUsedNumbers(values(items), state.usedNumbers);

      const byHash = {
        ...state.itemsByHash,
        ...items,
      };

      return {
        ...state,
        itemsByHash: byHash,
        usedNumbers,
        draft: true,
      };
    }
    case ORDER_UPDATE_PRICE_SUCCESS: {
      const { price } = action.payload;
      return {
        ...state,
        orderPrice: price,
      };
    }
    case UPSERT_ORDER_REQUEST: {
      return {
        ...state,
        loadingUpsert: true,
      };
    }
    case REMOVE_ITEMS: {
      const { itemsIds } = action.payload;
      const byId = state.itemsById.filter(
        (itemId: string) => !itemsIds.includes(itemId),
      );
      return {
        ...state,
        itemsById: byId,
        productsByItemId: [
          ...new Set(byId.map((id: string) => state.itemsByHash[id].productCatalogId)),
        ],
        draft: true,
      };
    }
    case FETCH_ORDER_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_ORDER_SUCCESS: {
      const {
        order: {
          items, id, status, price,
        },
      } = action.payload;

      const byId: string[] = items.map((item: Item) => item.externalId);

      const byHash: ItemsByHash = {
        ...items.reduce((prev: ItemsByHash, current: Item) => ({
          ...prev,
          [current.externalId]: current,
        }), {}),
      };

      return {
        ...state,
        itemsById: byId,
        itemsByHash: byHash,
        productsByItemId: [
          ...new Set(byId.map((itemId: string) => byHash[itemId].productCatalogId)),
        ],
        id,
        orderPrice: price,
        orderStatus: status,
        draft: false,
        loading: false,
      };
    }
    case FETCH_NUMBERS_SETTINGS_SUCCESS: {
      const { usedNumbers, lockedNumbers } = action.payload;

      return {
        ...state,
        usedNumbers,
        lockedNumbers,
      };
    }
    case UPSERT_ORDER_SUCCESS:
      return {
        ...state,
        id: action.payload.order.id,
        draft: false,
        loadingUpsert: false,
      };
    case LOCK_NUMBERS:
      return {
        ...state,
        lockedNumbers: action.payload.numbers,
        usedNumbers: action.payload.usedNumbers || state.usedNumbers,
      };
    case FETCH_ORDER_ERROR:
    case UPSERT_ORDER_ERROR:
      return {
        ...initialState,
        error: true,
      };
    case CLEAR_ORDER:
    case DELETE_ORDERS_SUCCESS: {
      return {
        ...initialState,
        selectedPlayersIds: state.selectedPlayersIds,
        selectedStaffIds: state.selectedStaffIds,
        selectedMembersTeam: state.selectedMembersTeam,
      };
    }
    default:
      return state;
  }
};
