import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import {
  catchError, mergeMap, withLatestFrom, map,
} from 'rxjs/operators';

import { CHECK_EMAIL_AVAILABILITY } from '../../constants/paths';
import { Response } from '../../models/Response';
import { State } from '../../models/State';
import { checkEmailAvailabilityError, checkEmailAvailabilitySuccess } from '../../actions/application';
import { CHECK_EMAIL_AVAILABILITY_REQUEST, CHECK_EMAIL_AVAILABILITY_ERROR } from '../../constants/actionTypes';
import { CheckEmailAvailabilityRequestAction } from '../../models/actions';
import { handleError } from '../../actions/errors';
import EmailAvailabilityResponse from '../../models/general/EmailAvailabilityResponse';

export default (
  action$: ActionsObservable<CheckEmailAvailabilityRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(CHECK_EMAIL_AVAILABILITY_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{ payload: { email } }, {
    application: { clubId }, authentication: { authToken },
  }]) => ajax({
    url: CHECK_EMAIL_AVAILABILITY.replace('$clubId', `${clubId}`),
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify({
      email,
    }),
  }).pipe(
    map(({ response }: Response<EmailAvailabilityResponse>) => (
      checkEmailAvailabilitySuccess(response)
    )),
    catchError(error => [
      handleError(error, CHECK_EMAIL_AVAILABILITY_ERROR),
      checkEmailAvailabilityError(error),
    ]),
  )),
);
