import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import DesktopProductDetails from './DesktopProductDetails';
import { State } from '../../../../../../models/State';
import { fetchFileRequest } from '../../../../../../actions/files';
import { removeItems, updateItems } from '../../../../../../actions/orderEditor';
import { lockNumbers } from '../../../../../../actions/orders';

const mapStatesToProps = ({
  files,
  orders,
  players,
}: State) => ({
  filesByHash: files.byHash,
  playersByHash: players.byHash,
  itemsByHash: orders.itemsByHash,
  itemsById: orders.itemsById,
  lockedNumbers: orders.lockedNumbers,
  usedNumbers: Object.keys(orders.usedNumbers).map(Number),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchFile: bindActionCreators(fetchFileRequest, dispatch),
  updateItems: bindActionCreators(updateItems, dispatch),
  removeItems: bindActionCreators(removeItems, dispatch),
  lockNumbers: bindActionCreators(lockNumbers, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(DesktopProductDetails);
