import React from 'react';
import moment from 'moment';
import { Flag, FlagNameValues } from 'semantic-ui-react';
import i18next from 'i18next';

import Labels from '../components/Labels';
import { Player } from '../../../models/Player';
import { formatDate } from '../../../utils/dateUtils';
import { ProfileSectionData } from '../components/ProfileSection/ProfileSectionProps';

export const getGeneralStatsSection = ({
  alternativePosition,
  dayOfBirth,
  footedness,
  gender,
  joinedClubDate,
  nationality,
  number,
  passNumber,
  preferredPosition,
  previousClubs,
  teamNumber,
}: Player, translate: i18next.TFunction): ProfileSectionData[] => [
  {
    name: 'GENERAL_INFO.TEAMS_SHIRT_NUMBER',
    value: (teamNumber && `#${teamNumber}`) || (number && `#${number} (${translate('PLAYERS_FAVOURITE')})`) || '',
    italic: !teamNumber,
  },
  { name: 'GENERAL_INFO.FUSS', value: footedness },
  { name: 'PREFFERED_TEAM_POSITION', value: preferredPosition },
  { name: 'GENERAL_INFO.ALTERNATIVE_POSITION', value: alternativePosition },
  {
    name: 'GENERAL_INFO.NATIONALITY',
    value: nationality && (
      <span>
        <Flag name={nationality as FlagNameValues} />
        {nationality.toUpperCase()}
      </span>
    ),
  },
  { name: 'GENERAL_INFO.GENDER', value: gender },
  { name: 'GENERAL_INFO.DATE_OF_BIRTH', value: formatDate(dayOfBirth) },
  { name: 'GENERAL_INFO.IN_THE_CLUB_SINCE', value: joinedClubDate ? moment(joinedClubDate).year() : '' },
  { name: 'GENERAL_INFO.PASSNUMMER', value: passNumber, wideColumn: true },
  {
    name: 'GENERAL_INFO.PREVIOUS_CLUBS',
    wideColumn: true,
    value: (<Labels values={previousClubs || []} />),
  },
];
