import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import {
  map, catchError, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import { FETCH_ORDER_ERROR, FETCH_ORDER_REQUEST } from '../../constants/actionTypes';
import { FetchOrderRequestAction } from '../../models/actions';
import { Response } from '../../models/Response';
import { State } from '../../models/State';
import { ORDERS } from '../../constants/paths';
import { OrderResponse } from '../../models/Order';
import { fetchOrderSuccess, fetchOrderError } from '../../actions/orders';
import { handleError } from '../../actions/errors';
import { deserializeOrders } from './methods/deserializeOrder';

const fetchOrder = (
  action$: ActionsObservable<FetchOrderRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_ORDER_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { id } },
    {
      authentication: { authToken },
      catalog: {
        catalog: {
          productsByHash,
        },
      },
    },
  ]) => ajax({
    url: `${ORDERS}/${id}`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    map(({ response: order }: Response<OrderResponse>) => (
      fetchOrderSuccess(deserializeOrders(order, productsByHash))
    )),
    catchError(error => [
      handleError(error, FETCH_ORDER_ERROR),
      fetchOrderError(error),
    ]),
  )),
);

export default fetchOrder;
