import React, { FC } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import colors from '../../../../constants/colors';
import styles from './ColorSelector.module.scss';

interface ColorSelectorProps {
  value?: string;
  placeholder?: string;
  onChange: (value: string) => void;
}

const ColorSelector: FC<ColorSelectorProps> = ({ value, onChange, placeholder }) => {
  const [translate] = useTranslation();

  const options = colors.map(({ name, hex }) => ({
    key: name,
    text: translate(name),
    value: name,
    label: (
      <span
        className={styles.circle}
        style={{
          background: hex,
          borderColor: hex === '#FFFFFF' ? '#979797' : hex,
        }}
      />
    ),
  }));

  return (
    <Dropdown
      fluid
      onChange={(event, target) => onChange(target.value as string)}
      options={options}
      placeholder={placeholder}
      selection
      value={value}
      className={styles.wrapper}
    />
  );
};

export default ColorSelector;
