import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { DATA_PRIVACY_ROUTE, TERMS_ROUTE } from '../../../../constants/routes';
import styles from './AsyncStaticPage.module.scss';
import './iubenda-overrides.scss';
import { IUBENDA_API, DATA_PRIVACY_PATH, TERMS_AND_CONDITIONS_PATH } from '../../../../constants/customizations';

const getApiUrl = (pathname: string) => {
  switch (pathname) {
    case TERMS_ROUTE:
      return `${IUBENDA_API}${TERMS_AND_CONDITIONS_PATH}`;
    case DATA_PRIVACY_ROUTE:
      return `${IUBENDA_API}${DATA_PRIVACY_PATH}`;
    default:
      return '';
  }
};

const AsyncStaticPage: FC = () => {
  const { pathname } = useLocation();
  const [content, setContent] = useState<string>('');

  useEffect(() => {
    fetch(getApiUrl(pathname)).then(response => {
      if (response.ok) {
        response.json().then((parsed => {
          setContent(parsed.content);
        }));
      }
    });
  }, [pathname]);

  return (
    // eslint-disable-next-line react/no-danger
    <div className={styles.wrapper} dangerouslySetInnerHTML={{ __html: content }} />
  );
};

export default AsyncStaticPage;
