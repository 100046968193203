import { EmbellishmentTypes, ItemEmbellishment } from '../../../../models/Embellishment';
import { ItemsByHash } from '../../../../models/Order';

const changeEmbellishmentAllLock = (
  itemsByHash: ItemsByHash,
  setItemsByHash: (itemsByHash: ItemsByHash) => void,
) => (
  itemIds: string[],
  type: EmbellishmentTypes,
  value: boolean,
) => {
  const newEmbellishments = (itemId: string) => {
    const { embellishments } = itemsByHash[itemId];
    const index = embellishments.findIndex(embellishment => embellishment.type === type);
    const enrichedEmbellishments: ItemEmbellishment[] = [...embellishments];
    if (index !== -1) {
      enrichedEmbellishments[index].locked = value;
    }

    return enrichedEmbellishments;
  };

  const newItems = itemIds.reduce((items: ItemsByHash, itemId) => ({
    ...items,
    [itemId]: {
      ...itemsByHash[itemId],
      embellishments: newEmbellishments(itemId),
    },
  }), {});

  setItemsByHash({
    ...itemsByHash,
    ...newItems,
  });
};

export default changeEmbellishmentAllLock;
