import React, { FC } from 'react';
import {
  Checkbox, Dropdown, Button, Icon,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import ProfileAvatar from '../../../PlayerProfile/components/ProfileAvatar';
import Row from '../../../../components/Table/components/Row';
import Text from '../../../../components/Text';
import styles from './StaffRow.module.scss';
import { StaffRowProps } from './StaffRowProps';
import EditRoleModal from '../EditRoleModal';
import { Member } from '../../../../models/Member';

const StaffRow: FC<StaffRowProps> = ({
  member: {
    firstName, lastName, invitationToken, personId,
  },
  member,
  isSelected,
  pendingOnSelect,
  onSelect,
  resendInvitation,
  activeTeam,
}) => {
  const [translate] = useTranslation();
  const handleCheckboxChange = () => {
    if (!!personId && onSelect) {
      onSelect(personId!);
    } else if (!!invitationToken && pendingOnSelect) {
      pendingOnSelect(invitationToken!);
    }
  };

  const handleReinviteMemberClick = () => {
    resendInvitation(invitationToken!);
  };

  const renderEditRoleOption = activeTeam && personId;

  return (
    <Row key={personId || invitationToken} classNames={styles.wrapper}>
      <div className={[styles.teamMember, invitationToken ? styles.pendingMember : ''].join(' ')}>
        <Checkbox onChange={handleCheckboxChange} checked={isSelected} />
        <ProfileAvatar
          user={member}
          size="small"
        />
        <div>
          <Text bold>
            {`${firstName} ${lastName}`}
          </Text>
          { invitationToken && (
            <em className={styles.pendingLabel}>
              {` (${translate('INVITE_PENDING')})`}
            </em>
          )}
        </div>
      </div>
      <div className={styles['options-wrapper']}>
        <Dropdown icon={<Icon name="ellipsis vertical" color="blue" size="large" />}>
          <Dropdown.Menu direction="left">
            {invitationToken && (
              <Dropdown.Item
                text={translate('REINVITE_MEMBER')}
                as={Button}
                onClick={handleReinviteMemberClick}
              />
            )}
            {renderEditRoleOption && (
              <EditRoleModal
                member={member as Member}
                teamId={activeTeam as number}
              />
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </Row>
  );
};

export default StaffRow;
