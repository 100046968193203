import React, { FC, useEffect, useState } from 'react';
import { Icon, Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import styles from './NotificationMessage.module.scss';
import { NotificationMessageProps } from './NotificationMessageProps';
import { NotificationType } from '../../../../models/actions';

const NotificationMessage: FC<NotificationMessageProps> = ({
  notification, removeNotification,
}) => {
  const [translate] = useTranslation();
  const [progressActive, setProgressActive] = useState(false);
  const {
    id, type, actionType,
  } = notification;

  useEffect(() => {
    setTimeout(() => setProgressActive(true), 0);
  }, []);

  return (
    <Message
      icon
      onDismiss={() => removeNotification(id)}
      negative={type === NotificationType.ERROR}
      positive={type === NotificationType.SUCCESS}
    >
      {type === NotificationType.ERROR ? <Icon name="x" /> : <Icon name="check" />}
      <Message.Content>
        <Message.Header content={translate(`MESSAGES.${actionType}_HEADER`)} />
        <p className={styles.message}>{translate(`MESSAGES.${actionType}_TEXT`)}</p>
      </Message.Content>

      <div className={[styles.progress, progressActive ? styles.progressActive : ''].join(' ')} />
    </Message>
  );
};

export default NotificationMessage;
