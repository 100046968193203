import React, { FC, useEffect, useState } from 'react';
import {
  CheckboxProps,
  DropdownProps,
  InputOnChangeData,
  Modal,
  TextAreaProps,
} from 'semantic-ui-react';
import 'react-day-picker/lib/style.css';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import {
  ADDRESS,
  DESCRIPTION,
  END_TIME,
  ONE_TIME_EVENT_FORM_DEFAULT_STATE,
  OneTimeEventModalProps,
  OneTimeEventFormSchema,
  MEETING_TIME,
  NAME,
  START_TIME,
  InformTeamParams,
  INFORM_TEAM_PARAMS_DEFAULT_PROPS,
  RESET_ATTENDANCE,
} from './OneTimeEventModalProps';
import { EventType } from '../../../../constants/eventTypes';
import InformTeamModal from '../InformTeamModal';
import generateOneTimeEvent from '../../methods/generateOneTimeEvent';
import OneTimeEventHeader from '../OneTimeEventHeader';
import OneTimeEventForm from '../OneTimeEventForm';

const OneTimeEventModal: FC<OneTimeEventModalProps> = ({
  onClose, createEvent, teamId, event, firstName,
  updateEvent, teamsByHash, activeSlot,
}) => {
  const [translate] = useTranslation();
  const [eventStartDate, setEventStartDate] = useState<Date | undefined>(activeSlot);
  const [eventEndDate, setEventEndDate] = useState<Date | undefined>(activeSlot);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [
    formState, setFormState,
  ] = useState<OneTimeEventFormSchema>(ONE_TIME_EVENT_FORM_DEFAULT_STATE);
  const [eventName, setEventName] = useState('');
  const [showInformTeamModal, setShowInformTeamModal] = useState<boolean>(false);
  const [
    informTeamParams, setInformTeamParams,
  ] = useState<InformTeamParams>(INFORM_TEAM_PARAMS_DEFAULT_PROPS);

  useEffect(() => {
    if (event && event.startDateTime && event.endDateTime) {
      setEventName(event.name);
      setFormState(({
        [NAME]: event.name,
        [DESCRIPTION]: event.description || '',
        [MEETING_TIME]: event.meetingDate || null,
        [START_TIME]: event.startDateTime,
        [END_TIME]: event.endDateTime,
        [ADDRESS]: (event.location && event.location.displayName) || '',
      }));
      setEventStartDate(event.startDateTime);
      setEventEndDate(event.startDateTime);
    }
  }, [event]);

  const handleEventDateChange = (value: Date) => {
    setEventStartDate(value);
    setEventEndDate(value);
  };

  useEffect(() => {
    const validateForm = () => {
      const {
        [DESCRIPTION]: desc,
        [MEETING_TIME]: meetingTime,
        [ADDRESS]: address,
        ...requiredFormFields
      } = formState;
      setIsFormValid(
        !Object.values(requiredFormFields).filter(item => !item).length
          && !!eventStartDate,
      );
      return formState;
    };

    validateForm();
  }, [formState, eventStartDate]);

  const handleChange = (e: any, {
    name, value,
  }: InputOnChangeData | DropdownProps | TextAreaProps | CheckboxProps) => {
    setFormState(state => ({ ...state, [name]: value }));
  };

  const handleDateTimeChange = (dateTime: Moment, name: string) => {
    setFormState(state => ({ ...state, [name]: dateTime ? dateTime.toDate() : null }));
  };

  const onMainFormSubmit = () => {
    setIsSubmitted(true);

    if (isFormValid) {
      if (event && event.id) {
        setShowInformTeamModal(true);
      } else {
        createEvent(generateOneTimeEvent(
          translate, formState, eventStartDate, eventEndDate, teamId, event,
        ));
      }
    }
  };

  const onEditFormSubmit = () => {
    setShowInformTeamModal(false);
    updateEvent(generateOneTimeEvent(
      translate, formState, eventStartDate, eventEndDate, teamId, event, informTeamParams,
    ));
  };

  const toggleResetAttendance = () => {
    setInformTeamParams({
      ...informTeamParams,
      [RESET_ATTENDANCE]: !informTeamParams[RESET_ATTENDANCE],
    });
  };

  return (
    <>
      <Modal
        open
        key="main-modal"
        size="small"
        onClose={onClose}
        closeIcon
        closeOnDimmerClick={false}
        style={{ width: '930px', padding: '30px' }}
      >
        <OneTimeEventHeader
          header={`${eventName || translate(EventType.ONE_TIME_EVENT)} ${teamId && teamsByHash[teamId] ? `(${teamsByHash[teamId].name})` : ''}`}
          onClose={onClose}
          onSubmit={onMainFormSubmit}
          isFormValid={isFormValid}
          isSubmitted={isSubmitted}
        />
        <OneTimeEventForm
          formState={formState}
          handleChange={handleChange}
          eventDate={eventStartDate}
          handleEventDateChange={handleEventDateChange}
          handleTimeChange={handleDateTimeChange}
          isSubmitted={isSubmitted}
        />
      </Modal>
      <InformTeamModal
        firstName={firstName}
        showInformTeamModal={showInformTeamModal}
        setShowInformTeamModal={setShowInformTeamModal}
        onEditFormSubmit={onEditFormSubmit}
        toggleResetAttendance={toggleResetAttendance}
        informTeamParams={informTeamParams}
      />
    </>
  );
};

export default OneTimeEventModal;
