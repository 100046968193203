import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, DropdownItemProps, DropdownProps, Form, Message,
} from 'semantic-ui-react';

import FormFields from '../../../../components/FormFields';
import Header from '../../../../components/Header';
import styles from './CreateTeam.module.scss';
import useFocusFirstInput from '../../../../hooks/useFocusFirstInput';
import useForm from '../../../../hooks/useForm';
import { CLUB_TEAMS_ROUTE } from '../../../../constants/routes';
import { CreateTeamProps } from './CreateTeamProps';
import { createTeamSchema, validationSchema } from './schema';
import { ExternalSystemName, ExternalTeam } from '../../../../models/externalSystemIntegration';

const CreateTeam = ({
  createTeam,
  error,
  clubId,
  loading,
  externalTeams,
  club,
  fetchExternalTeams,
}: CreateTeamProps) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [translate] = useTranslation();
  const [externalTeamOptions, setExternalTeamOptions] = useState<DropdownItemProps[]>([]);
  const [externalClubRef, setExternalClubRef] = useState<string>();
  const [externalTeam, setExternalTeam] = useState<ExternalTeam>();
  const [dfbReferenceId, setDfbReferenceId] = useState<string | undefined>();

  useEffect(() => {
    if (club.externalSystemReferences) {
      const dfbClubRef = club.externalSystemReferences
        .find(
          (externalSystemReference) => (
            externalSystemReference.externalSystemName === ExternalSystemName.DFB_CLUB_ID
          ),
        );
      if (dfbClubRef) {
        setExternalClubRef(dfbClubRef.externalSystemEntityId);
        fetchExternalTeams(dfbClubRef.externalSystemEntityId);
      }
    }
  }, [club, setExternalClubRef, fetchExternalTeams]);

  useEffect(() => {
    if (externalTeams && externalTeams.length) {
      setExternalTeamOptions(
        externalTeams.map(
          ({
            id, mannschaftsName, mannschaftsart, spielklasse,
          }) => ({ value: id, text: `${mannschaftsName} (${mannschaftsart} - ${spielklasse})` }),
        ),
      );
    }
  }, [externalTeams, setExternalTeamOptions]);

  useEffect(() => {
    if (dfbReferenceId && externalTeams) {
      const found = externalTeams.find(extTeam => extTeam.staffelKennung === dfbReferenceId);
      if (found) {
        setExternalTeam(found);
      }
    }
  }, [externalTeams, dfbReferenceId]);

  const handleExternalTeamChange = (e: any, { value }: DropdownProps) => {
    const found = externalTeams.find(t => t.id === value);
    setExternalTeam(found);
    if (found) {
      setDfbReferenceId(found.staffelKennung);
    }
  };

  const {
    values,
    errors,
    handleChange,
    validate,
  } = useForm(validationSchema);

  useFocusFirstInput();

  const handleSubmit = () => {
    setIsSubmitted(true);

    if (validate() && clubId) {
      const {
        teamName, season, seasonStartDate, seasonEndDate,
      } = values;

      createTeam(
        clubId,
        teamName,
        season,
        new Date(seasonStartDate).valueOf(),
        new Date(seasonEndDate).valueOf(),
        externalTeam,
      );
    }
  };

  const renderButton = () => (
    <Button
      primary
      disabled={!values.teamName}
      type="submit"
      content={translate('SAVE')}
      form="create-form"
    />
  );

  return (
    <div className={styles.wrapper}>
      <Header
        backLabel="TO_MY_TEAMS"
        backLinkTo={CLUB_TEAMS_ROUTE}
        renderButton={renderButton}
        title={translate('ADD_NEW_TEAM')}
        border
      />

      <Form error={error} onSubmit={handleSubmit} loading={loading} className={styles.form} id="create-form">
        {isSubmitted && error && (
        <Message error={error} header={translate('ERROR')} content={translate('GENERAL_ERROR')} />)}
        <FormFields
          errors={errors}
          handleChange={handleChange}
          schema={createTeamSchema}
          values={values}
        />
        <div hidden={!externalClubRef}>
          <Form.Field
            className={styles.formField}
            key="external-club-id"
            required
          >
            <label>{translate('CONNECT_DFB_TEAM')}</label>
            <div className={styles.inputWithLabel}>
              <Form.Dropdown
                name="club-name"
                placeholder={translate('SET_DFB_DIVISION')}
                onChange={handleExternalTeamChange}
                className={styles.field}
                options={[...externalTeamOptions]}
                selection
                value={externalTeam ? externalTeam.id : undefined}
                defaultValue={externalTeam ? externalTeam.id : undefined}
              />
            </div>
          </Form.Field>
        </div>
      </Form>
    </div>
  );
};

export default CreateTeam;
