import { AjaxError } from 'rxjs/ajax';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { goBack } from 'connected-react-router';

import { SEND_INVITATION } from '../../constants/actionTypes';
import { INVITATIONS_PATH } from '../../constants/paths';
import { sendInvitationError, sendInvitationSuccess } from '../../actions/invitations';
import { NotificationType, SendInvitationAction } from '../../models/actions';
import { State } from '../../models/State';
import { addNotification } from '../../actions/notifications';
import { handleError } from '../../actions/errors';

export default (
  action$: ActionsObservable<SendInvitationAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(SEND_INVITATION),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      createInvitation,
      invitationPersonalData,
      shouldRedirect,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: INVITATIONS_PATH,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify({
      createInvitation,
      invitationPersonalData,
    }),
  }).pipe(
    mergeMap(() => [
      addNotification(NotificationType.SUCCESS, `${SEND_INVITATION}_${createInvitation.receiverRole}_SUCCESS`),
      sendInvitationSuccess(),
      shouldRedirect && goBack(),
    ].filter(Boolean)),
    catchError((error: AjaxError) => [
      handleError(error, `${SEND_INVITATION}_${createInvitation.receiverRole}_ERROR`),
      sendInvitationError(error),
    ]),
  )),
);
