import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import { mergeMap, tap, withLatestFrom } from 'rxjs/operators';

import { MembershipResponse } from '../../models/general/Membership';
import { NtsUserMembership } from '../../models/Response';
import { Roles } from '../../constants/roles';
import { FetchAuthUserRequestAction } from '../../models/actions';
import { State } from '../../models/State';
import { fetchClubLogoRequest, fetchClubRequest } from '../../actions/clubs';
import { fetchUserInfoRequest, setUser } from '../../actions/user';
import { fetchAuthUserSuccess, setApplicationParameters } from '../../actions/application';
import { fetchTeamIdRequest } from '../../actions/teams';
import { FETCH_AUTH_USER_REQUEST } from '../../constants/actionTypes';
import { USER_PATH } from '../../constants/paths';
import AUTH_TOKEN from '../../packages/authentication/constants/localStorageKeys';
import { setAuthToken } from '../../packages/authentication';

const parseMemberships = (memberships: MembershipResponse[]): NtsUserMembership => (
  memberships.reduce((fin, { organizationId, roles }) => ({
    ...fin,
    [organizationId]: roles,
  }), {})
);

const fetchClubOrLogo = (clubId: number, memberships: NtsUserMembership) => (
  memberships[clubId].includes(Roles.CLUB_ADMIN)
    ? fetchClubRequest()
    : fetchClubLogoRequest()
);

export default (
  action$: ActionsObservable<FetchAuthUserRequestAction>,
  state$: StateObservable<State>,
  { ajax, localStorage }: any,
) => action$.pipe(
  ofType(FETCH_AUTH_USER_REQUEST),
  withLatestFrom(state$),
  tap(([{ payload: { authToken } }]) => {
    const storage = localStorage || window.localStorage;
    storage.setItem(AUTH_TOKEN, authToken);
  }),
  mergeMap(([{ payload: { authToken } }]) => ajax({
    url: USER_PATH,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    mergeMap(({
      response: {
        email, firstName, lastName, username, id,
        memberships, currentlyUsedOrganizationId, clubType,
      },
    }) => {
      const parsedMemberships = parseMemberships(memberships);
      return [
        setAuthToken(authToken),
        setApplicationParameters({
          memberships: parsedMemberships,
          clubId: currentlyUsedOrganizationId,
        }),
        currentlyUsedOrganizationId && fetchTeamIdRequest(currentlyUsedOrganizationId),
        currentlyUsedOrganizationId
          && memberships.length
          && fetchClubOrLogo(currentlyUsedOrganizationId, parsedMemberships),
        setUser({
          id, email, firstName, lastName, username, clubType,
        }),
        fetchUserInfoRequest(),
        fetchAuthUserSuccess(),
      ].filter(Boolean);
    }),
  )),
);
