import React, { FC, useEffect, useState } from 'react';
import {
  CheckboxProps,
  DropdownProps,
  InputOnChangeData,
  Modal,
  TextAreaProps,
} from 'semantic-ui-react';
import 'react-day-picker/lib/style.css';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import {
  ADDRESS,
  AWAY_TEAM_RESULT,
  COMPETITION,
  DESCRIPTION,
  END_TIME,
  HOME_TEAM_RESULT,
  INFORM_TEAM,
  INFORM_TEAM_PARAMS_DEFAULT_PROPS,
  InformTeamParams,
  MATCH_FORM_DEFAULT_STATE,
  MATCH_PLACE,
  MatchEditTabs,
  MatchEventModalProps,
  MatchFormSchema,
  MEETING_POINT,
  MEETING_TIME,
  OPPONENT_TEAM,
  PITCH,
  REPORT,
  RESET_ATTENDANCE,
  START_TIME,
} from './MatchEventModalProps';
import { EventType } from '../../../../constants/eventTypes';
import MatchEventForm from '../MatchEventForm';
import { Match, MatchPlace } from '../../../../models/Calendar';
import MatchEventHeader from '../MatchEventHeader';
import MatchStatsForm from '../MatchStatsForm';
import generateMatch from '../../methods/generateMatch';
import InformTeamModal from '../InformTeamModal';

const MatchEventModal: FC<MatchEventModalProps> = ({
  onClose, createEvent, teamId, event, firstName,
  updateEvent, teamsByHash, activeSlot, updateResult,
}) => {
  const [translate] = useTranslation();
  const [matchDate, setMatchDate] = useState<Date | undefined>(activeSlot);
  const [activeTab, setActiveTab] = useState<MatchEditTabs>(MatchEditTabs.MATCH_DETAILS);
  const [
    matchResult, setMatchResult,
  ] = useState<{ [key: string]: number | null }>({});
  const [matchResultChanged, setMatchResultChanged] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formState, setFormState] = useState<MatchFormSchema>(MATCH_FORM_DEFAULT_STATE);
  const team = teamsByHash[String(teamId)];
  const [showInformTeamModal, setShowInformTeamModal] = useState<boolean>(false);
  const [
    informTeamParams, setInformTeamParams,
  ] = useState<InformTeamParams>(INFORM_TEAM_PARAMS_DEFAULT_PROPS);

  useEffect(() => {
    if (event && event.startDateTime && event.endDateTime) {
      setFormState(({
        [OPPONENT_TEAM]: ((event as Match).match.matchPlace === MatchPlace.HOME)
          ? (event as Match).match.awayTeamName
          : (event as Match).match.homeTeamName,
        [COMPETITION]: (event as Match).match.matchType,
        [PITCH]: (event as Match).match.pitch || null,
        [MATCH_PLACE]: (event as Match).match.matchPlace,
        [DESCRIPTION]: event.description || '',
        [MEETING_TIME]: event.meetingDate || null,
        [START_TIME]: event.startDateTime,
        [END_TIME]: event.endDateTime,
        [ADDRESS]: ((event as Match).match.matchLocation && (event as Match).match.matchLocation!.displayName) || '',
        [MEETING_POINT]: (event.location && event.location.displayName) || '',
        [REPORT]: (event as Match).match.report || '',
      }));
      setMatchDate(event.startDateTime);
      const { homeTeamScore, awayTeamScore } = (event as Match).match;
      setMatchResult({
        [HOME_TEAM_RESULT]: homeTeamScore !== undefined ? homeTeamScore : null,
        [AWAY_TEAM_RESULT]: awayTeamScore !== undefined ? awayTeamScore : null,
      });
    }
  }, [event]);

  const handleMatchDateChange = (value: Date) => {
    setMatchDate(value);
  };

  useEffect(() => {
    const validateForm = () => {
      const {
        [DESCRIPTION]: desc,
        [MEETING_TIME]: meetingTime,
        [REPORT]: matchReport,
        [COMPETITION]: competition,
        [PITCH]: pitch,
        [MEETING_POINT]: meetingPoint,
        [ADDRESS]: address,
        ...requiredFormFields
      } = formState;
      setIsFormValid(
        !Object.values(requiredFormFields).filter(item => !item).length
          && !!matchDate,
      );
      return formState;
    };

    validateForm();
  }, [formState, matchDate]);

  const handleChange = (e: any, {
    name, value,
  }: InputOnChangeData | DropdownProps | TextAreaProps | CheckboxProps) => {
    setFormState(state => ({ ...state, [name]: value }));
  };

  const handleResultChange = (e: any, { name, value }: InputOnChangeData) => {
    setMatchResult(state => ({
      ...state,
      [name]: value !== '' ? +value : null,
    }));
    setMatchResultChanged(true);
  };

  const handleDateTimeChange = (dateTime: Moment, name: string) => {
    setFormState(state => ({ ...state, [name]: dateTime ? dateTime.toDate() : null }));
  };

  const updateMatchEvent = () => {
    const homeTeamResult = matchResult[HOME_TEAM_RESULT];
    const awayTeamResult = matchResult[AWAY_TEAM_RESULT];

    if (matchResultChanged && homeTeamResult !== null && awayTeamResult !== null) {
      updateResult(
        (event as Match).match.id!,
        homeTeamResult,
        awayTeamResult,
      );
    }
  };

  const onMainFormSubmit = () => {
    setIsSubmitted(true);

    if (isFormValid) {
      if (event && event.id) {
        setShowInformTeamModal(true);
      } else {
        createEvent(generateMatch(translate, formState, matchDate, teamId, event));
      }
    }
  };

  const onEditFormSubmit = () => {
    setShowInformTeamModal(false);
    updateEvent(generateMatch(translate, formState, matchDate, teamId, event, informTeamParams));
    updateMatchEvent();
  };

  const toggleInformTeam = () => {
    setInformTeamParams({
      ...informTeamParams,
      [INFORM_TEAM]: !informTeamParams[INFORM_TEAM],
    });
  };

  const toggleResetAttendance = () => {
    setInformTeamParams({
      ...informTeamParams,
      [RESET_ATTENDANCE]: !informTeamParams[RESET_ATTENDANCE],
    });
  };

  return (
    <>
      <Modal
        open
        key="main-modal"
        size="small"
        onClose={onClose}
        closeIcon
        closeOnDimmerClick={false}
        style={{ width: '930px', padding: '30px' }}
      >
        <MatchEventHeader
          header={`${translate(EventType.MATCH)} ${team ? `(${team.name})` : ''}`}
          onClose={onClose}
          onSubmit={onMainFormSubmit}
          isFormValid={isFormValid}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isSubmitted={isSubmitted}
        />
        { activeTab === MatchEditTabs.MATCH_DETAILS ? (
          <MatchEventForm
            formState={formState}
            handleChange={handleChange}
            matchDate={matchDate}
            handleMatchDateChange={handleMatchDateChange}
            handleTimeChange={handleDateTimeChange}
            isSubmitted={isSubmitted}
          />
        ) : (
          <MatchStatsForm
            formState={formState}
            handleChange={handleChange}
            matchResult={matchResult}
            handleResultChange={handleResultChange}
          />
        )}
        {/* Uncomment when backend content stream support is ready
        { !event && <NewEventFooter /> }
        */}
      </Modal>
      <InformTeamModal
        firstName={firstName}
        showInformTeamModal={showInformTeamModal}
        setShowInformTeamModal={setShowInformTeamModal}
        onEditFormSubmit={onEditFormSubmit}
        toggleInformTeam={toggleInformTeam}
        toggleResetAttendance={toggleResetAttendance}
        informTeamParams={informTeamParams}
      />
    </>
  );
};

export default MatchEventModal;
