import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import ReactCrop, { PercentCrop, PixelCrop } from 'react-image-crop';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import {
  Button, Header, Input, Modal,
} from 'semantic-ui-react';

import ModalActions from '../ModalActions';
import SelectFiles from './components/SelectFiles';
import convertFromBlobToUrl from './methods/convertFromBlobToUrl';
import cropImage from './methods/cropImage';
import styles from './FileUploadModal.module.scss';
import { IMAGES_EXTENSIONS } from '../../constants/customizations';
import { FileUploadModalProps, FileUploadTypes } from './FileUploadModalProps';
import { UploadFile } from '../../models/File';
import 'react-image-crop/dist/ReactCrop.css';
import { useDebounceEffect } from '../../hooks/useDebounceEffect/useDebounceEffect';
import { canvasPreview } from './methods/canvasPreview';

const cropInitial = {
  height: 50,
  keepSelection: true,
  width: 50,
  x: 25,
  y: 25,
  unit: '%',
} as PercentCrop;

const FileUploadModal = ({
  uploadFile, triggerButton, loading, type, filesToSelect, error, secondaryAction,
  selectFile, aspect, extensions = IMAGES_EXTENSIONS, requestPreview = () => {}, previewNode,
}: FileUploadModalProps) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [translate] = useTranslation();
  const [crop, setCrop] = useState<PercentCrop>({ ...cropInitial });
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [fileBlob, setFileBlob] = useState<File | null>(null);
  const [fileSrc, setFileSrc] = useState<string | null>(null);
  const [imageBlobCropped, setImageBlobCropped] = useState<Blob | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<UploadFile | null>(null);

  const { getRootProps, isDragActive } = useDropzone({
    onDrop: files => setFileBlob(files[0]),
  });

  const onUploadFile = useCallback(
    (event: any) => setFileBlob(event.target.files[0]), [],
  );

  useEffect(() => {
    if (fileBlob) {
      convertFromBlobToUrl([fileBlob], setFileSrc);
    }
  }, [fileBlob]);

  useEffect(() => {
    if (error) {
      setFileSrc(null);
    }
  }, [error]);

  useEffect(() => {
    if (type === FileUploadTypes.XLSX && fileBlob) {
      requestPreview(new File([fileBlob], fileBlob.name));
    }
    // eslint-disable-next-line
  }, [type, fileBlob]);

  const setInitValues = useCallback(() => {
    setCrop({ ...cropInitial });
    setFileSrc(null);
    setImageBlobCropped(null);
    setSelectedFile(null);
  }, [setCrop, setFileSrc, setImageBlobCropped]);

  const onClose = useCallback(() => {
    setInitValues();
    setModalOpen(false);
  }, [setModalOpen, setInitValues]);

  useEffect(() => {
    if (!loading) {
      setInitValues();
      onClose();
    }
  }, [loading, setInitValues, onClose]);

  const onSubmit = useCallback(() => {
    const file = type === FileUploadTypes.IMAGE ? imageBlobCropped : fileBlob;
    if (file && fileBlob) {
      uploadFile(new File([file], fileBlob.name));
    }
    if (selectFile && selectedFile) {
      selectFile(selectedFile.id, selectedFile.originalFilename, selectedFile.extension);
      onClose();
    }
  }, [uploadFile, type, imageBlobCropped, fileBlob, selectFile, selectedFile, onClose]);

  const onClickButton = useCallback(() => setModalOpen(true), [setModalOpen]);
  const onToggleSelect = useCallback((file: UploadFile) => {
    setSelectedFile(selectedFile && selectedFile.id === file.id ? null : file);
  }, [setSelectedFile, selectedFile]);

  const fileNotUploaded = !fileSrc;
  const acceptExtensions = extensions.map(ext => `.${ext}`).join(',');

  const specificTypes = [
    FileUploadTypes.XLSX,
    FileUploadTypes.IMAGE,
  ];

  function onImageLoad() {
    setCrop(cropInitial);
  }

  useDebounceEffect(
    async () => {
      if (completedCrop
            && completedCrop.width
            && completedCrop.height
            && imgRef.current
            && previewCanvasRef.current
      ) {
        await canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
        );
      }
    },
    100,
    [completedCrop],
  );

  return (
    <Modal
      className={styles.wrapper}
      closeIcon
      onClose={onClose}
      open={modalOpen}
      trigger={triggerButton
        ? triggerButton(onClickButton)
        : (
          <Button
            content={translate('UPLOAD_IMAGE')}
            onClick={onClickButton}
            primary
            size="mini"
          />
        )
      }
    >
      <h2>{translate(`SELECT_${type}`)}</h2>

      <Modal.Content>
        {type === FileUploadTypes.IMAGE && fileSrc && (
          <div className={styles.containerResults}>
            <div className={styles.cropWrapper}>
              <ReactCrop
                crop={crop}
                className={styles.reactCrop}
                onChange={(pixelCrop, percentCrop) => {
                  setCrop(percentCrop);
                  setCompletedCrop(pixelCrop);
                }}
                onComplete={(pixelCrop, percentCrop) => {
                  if (imgRef && imgRef.current) {
                    cropImage(imgRef.current, percentCrop, (image: Blob | null) => {
                      if (image) {
                        setImageBlobCropped(image);
                      }
                    });
                  }
                }}
                aspect={aspect}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={fileSrc}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            </div>
            <div>
              {completedCrop && (
              <canvas
                ref={previewCanvasRef}
                className={styles.previewCanvas}
                style={{
                  width: completedCrop.width,
                  height: completedCrop.height,
                }}
              />
              )}
            </div>
          </div>
        )}
        {type === FileUploadTypes.XLSX && fileSrc && (
          previewNode && !error && previewNode()
        )}

        {!specificTypes.includes(type) && fileSrc && (
          translate('UPLOADED')
        )}

        {!fileSrc && (
          <div className={styles.container}>
            <div {...getRootProps()}>
              <div className={classNames(styles.dropzone, isDragActive && styles.dropzoneActive)}>
                <Header content={translate(isDragActive ? `DROP_A_${type}_HERE` : `DRAG_DROP_ANY_${type}`)} />
              </div>
              <div className={styles.containerOr}>
                {translate('OR')}
              </div>
            </div>
            <div style={{ marginBottom: '1rem' }}>
              <label className="ui primary button" htmlFor="uploadFile">
                {translate(`UPLOAD_${type}`)}
              </label>
              <Input
                type="file"
                onChange={onUploadFile}
                id="uploadFile"
                className={styles.inputUploadFile}
                accept={acceptExtensions}
              />
            </div>
            {secondaryAction && secondaryAction()}
          </div>
        )}
      </Modal.Content>

      {type === FileUploadTypes.FILE && filesToSelect && selectFile && fileNotUploaded && (
        <SelectFiles
          filesToSelect={filesToSelect}
          toggleFile={onToggleSelect}
          selectedFile={selectedFile}
        />
      )}

      <ModalActions
        disabled={fileNotUploaded && !selectedFile}
        loading={loading}
        onCancel={onClose}
        onSave={onSubmit}
      />
    </Modal>
  );
};

export default FileUploadModal;
