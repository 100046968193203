import React, { FC } from 'react';
import XLSX from 'xlsx';

export interface OutTableProps {
  tableClassName?: string;
  withZeroColumn?: boolean;
  columns: Column[];
  data: string[];
  withoutRowNum?: boolean;
  tableHeaderRowClass?: string;
  renderRowNum?: any;
}

export interface Column {
  name: string;
  key: number;
}

export const OutTable: FC<OutTableProps> = ({
  tableClassName,
  withZeroColumn,
  withoutRowNum,
  tableHeaderRowClass,
  columns,
  data,
  renderRowNum,
}) => (
  <div>
    <table className={tableClassName}>
      <tbody>
        <tr>
          {withZeroColumn && !withoutRowNum && <th className={tableHeaderRowClass || ''} />}
          {
            columns.map((column: Column) => <th key={column.key} className={column.key === -1 ? tableHeaderRowClass : ''}>{column.key === -1 ? '' : column.name}</th>)
          }
        </tr>
        {data.map((row: any, i: any) => (
          // eslint-disable-next-line
          <tr key={i}>
            {!withoutRowNum && (
              // eslint-disable-next-line
              <td key={i} className={tableHeaderRowClass}>
                {renderRowNum ? renderRowNum(row, i) : i}
              </td>
            )}
            {columns.map((column: Column) => <td key={column.key}>{row[column.key]}</td>)}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const createColumns = (refstr: any) => {
  const columns = [];
  const range = XLSX.utils.decode_range(refstr).e.c + 1;
  for (let i = 0; i < range; i += 1) {
    columns[i] = { name: XLSX.utils.encode_col(i), key: i };
  }
  return columns;
};

export const ExcelRenderer = (file: Blob | string, callback: any) => {
  const readerPromise = new Promise(resolve => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    if (file && rABS) reader.readAsBinaryString(file as Blob);
    else reader.readAsArrayBuffer(file as Blob);

    reader.onload = (e: ProgressEvent) => {
      /* Parse data */
      const bstr = e.target ? reader.result : null;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', cellDates: true });

      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      /* Convert array of arrays */
      const json = XLSX.utils.sheet_to_json(ws, { header: 1, raw: false, dateNF: 'dd-MM-yyyy' });
      const cols = createColumns(ws['!ref']);

      const data = { rows: json, cols };

      resolve(data);
      return callback(null, data);
    };
  });
  return readerPromise;
};
