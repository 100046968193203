import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './DeleteDataConfirmation.module.scss';
import AuthenticationFormsWrapper from '../../components/AuthenticationFormsWrapper';
import HeaderTag from '../../components/WelcomePage/HeaderTag';
import Signature from '../../components/WelcomePage/Signature';
import {
  DATA_PRIVACY_ROUTE, TERMS_ROUTE, IMPRINT_ROUTE, SIGN_IN_ROUTE,
} from '../../constants/routes';

interface DeleteDataConfirmationProps extends RouteComponentProps {
  clubLogoUrl: string;
}

const DeleteDataConfirmation: FC<DeleteDataConfirmationProps> = ({
  clubLogoUrl,
}) => {
  const [translate] = useTranslation();

  return (
    <AuthenticationFormsWrapper clubLogoUrl={clubLogoUrl}>
      <div className={styles.wrapper}>
        <HeaderTag successTitle="DATA_DELETION" />
        <h2 className={styles.header}>
          {translate('DATA_DELETION_CONFIRMATION_HEADER')}
        </h2>
        <span className={styles.text}>
          {`${translate('HI')}!`}
        </span>
        <p className={[styles.text, styles.paragraphText].join(' ')}>
          {translate('DATA_DELETION_CONFIRMATION_TEXT')}
        </p>
        <Signature />
        <Link to={SIGN_IN_ROUTE} className={styles.backLink}>
          <div className={styles.arrow} />
          {translate('GO_TO_LOGIN_PAGE')}
        </Link>
        <div className={styles.links}>
          <Link to={DATA_PRIVACY_ROUTE}>{translate('DATA_PRIVACY')}</Link>
          <span>|</span>
          <Link to={TERMS_ROUTE}>{translate('TERMS_AND_CONDITIONS')}</Link>
          <span>|</span>
          <Link to={IMPRINT_ROUTE}>{translate('IMPRINT.PAGE_NAME')}</Link>
        </div>
      </div>
    </AuthenticationFormsWrapper>
  );
};

export default DeleteDataConfirmation;
