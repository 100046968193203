import { ItemEmbellishment } from '../../../../models/Embellishment';
import { ItemsByHash } from '../../../../models/Order';

const changeEmbellishments = (
  itemsByHash: ItemsByHash,
  setItemsByHash: (itemsByHash: ItemsByHash) => void,
) => (
  itemIds: string[],
  values: { [key: string]: ItemEmbellishment[] },
) => {
  setItemsByHash({
    ...itemsByHash,
    ...itemIds.reduce((items: ItemsByHash, itemId) => ({
      ...items,
      [itemId]: {
        ...itemsByHash[itemId],
        embellishments: values[itemId],
      },
    }), {}),
  });
};

export default changeEmbellishments;
