import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';

import { State } from '../../models/State';
import { EVENTS } from '../../constants/paths';
import { UPDATE_EVENT_REQUEST } from '../../constants/actionTypes';
import { NotificationType, UpdateEventRequestAction } from '../../models/actions';
import { updateEventSuccess, updateEventError } from '../../actions/calendar';
import { EventListData } from '../../models/Calendar';
import { addNotification } from '../../actions/notifications';
import { handleError } from '../../actions/errors';

const updateEvent = (
  action$: ActionsObservable<UpdateEventRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(UPDATE_EVENT_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      event: {
        id,
        type,
        ...rest
      },
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: `${EVENTS}/${id}`,
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify({
      ...rest,
      ...(rest.startDateTime && ({ startDateTime: rest.startDateTime.getTime() })),
      ...(rest.endDateTime && ({ endDateTime: rest.endDateTime.getTime() })),
    }),
  }).pipe(
    mergeMap(({ response: updatedEvent }): any => {
      const serializedEvent: EventListData = ({
        ...updatedEvent,
        startDateTime: new Date(updatedEvent.startDateTime),
        endDateTime: new Date(updatedEvent.endDateTime),
        ...(updatedEvent.meetingDate && ({ meetingDate: new Date(updatedEvent.meetingDate) })),
      });
      return [
        addNotification(NotificationType.SUCCESS, `UPDATE_${type}_SUCCESS`),
        updateEventSuccess(serializedEvent),
      ].filter(Boolean);
    }),
    catchError((error: AjaxError) => [
      handleError(error, `UPDATE_${type}_ERROR`),
      updateEventError(error),
    ]),
  )),
);

export default updateEvent;
