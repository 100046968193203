import { withRouter } from 'react-router-dom';
import React from 'react';
import {
  Button, Message, Modal,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import styles from './InvitationModal.module.scss';
import { User } from '../../models/general/User';
import { history } from '../../configureStore';
import { Club } from '../../models/Club';
import { AcceptInvitationRequestAction } from '../../models/actions';
import { CUSTOMER_SERVICE_EMAIL_11TEAMSPORTS } from '../../constants/customizations';

interface InvitationModalProps extends RouteComponentProps {
  acceptInvitationRequest: (token: string) => AcceptInvitationRequestAction;
  loading: boolean;
  error: boolean;
  errorMessage: string;
  inviteToken: string;
  user: User | null;
  invitingPerson: string;
  invitingClub: string;
  currentClub?: Club | null;
}

const InvitationModal: React.FC<InvitationModalProps> = ({
  inviteToken = '',
  errorMessage,
  user,
  error,
  loading,
  invitingPerson,
  invitingClub,
  acceptInvitationRequest,
  currentClub,
  location,
}) => {
  const [translate] = useTranslation();

  const handleNewClubClick = () => {
    acceptInvitationRequest(inviteToken);
  };

  const handleCurrentClubClick = () => {
    history.push(location.pathname);
  };

  return (
    <Modal open={!!inviteToken && !(currentClub && currentClub.name === invitingClub) && !!location.search} size="tiny" className={styles.modal}>
      <Modal.Header as="h2" content={translate('NEW_CLUB_INVITATION.YOU_HAVE_BEEN_INVITED_TO_A_NEW_CLUB')} className={styles.header} />
      <Modal.Content>
        <p className={styles.content}>
          {translate('NEW_CLUB_INVITATION.WELCOME', { user: user && user.firstName })}
        </p>
        <p className={styles.content}>
          {translate('NEW_CLUB_INVITATION.CURRENTLY_MEMBER_OF_PARAGRAPH', { club: (currentClub && currentClub.name) || '-' })}
        </p>
        <p className={styles.content}>
          {translate('NEW_CLUB_INVITATION.INVITING_PERSON_PARAGRAPH', { invitingPerson, invitingClub })}
        </p>
        <p className={styles.content}>
          {translate('NEW_CLUB_INVITATION.ACCEPT_INVITATION_PARAGRAPH')}
        </p>
        <strong className={styles.preferredClubQuestion}>
          {translate('NEW_CLUB_INVITATION.WHICH_CLUB_PREFERRED_QUESTION')}
        </strong>
        <p className={[styles.content, styles.sidenote].join(' ')}>
          {translate('PROBLEMS_SIGNING_UP_CUSTOMER_SUPPORT_TEXT')}
          &nbsp;
          <a className={styles.csLink} href={`mailto:${CUSTOMER_SERVICE_EMAIL_11TEAMSPORTS}`}>{`${translate('PROBLEMS_SIGNING_UP_CUSTOMER_SUPPORT_LINK')}.`}</a>
        </p>
        { error && errorMessage && errorMessage.length && (<Message size="mini" header={translate(errorMessage.toUpperCase())} negative />)}
        { error && !(errorMessage && errorMessage.length) && (<Message size="mini" header={translate('GENERAL_ERROR')} negative />)}
      </Modal.Content>
      <Modal.Actions>
        <div className={styles.actions}>
          <Button
            className={styles.currentClubButton}
            fluid
            onClick={handleCurrentClubClick}
            circular
          >
            {translate('NEW_CLUB_INVITATION.CURRENT_CLUB')}
          </Button>
          <Button
            className={styles.backButton}
            disabled={loading}
            onClick={handleNewClubClick}
            circular
            fluid
            primary
          >
            {translate('NEW_CLUB_INVITATION.NEW_CLUB')}
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};

export default withRouter(InvitationModal);
