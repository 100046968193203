import get from 'lodash/get';
import { uniqBy } from 'lodash';
import { Actions } from '../models/actions';
import { Team, TeamReducer, TeamsByHash } from '../models/Team';
import {
  ACTIVE_TEAMS_SUCCESS,
  BULK_ASSIGN_MEMBERS_ERROR,
  BULK_ASSIGN_MEMBERS_REQUEST,
  BULK_ASSIGN_MEMBERS_SUCCESS,
  CHANGE_TEAM_STATUS_SUCCESS,
  CREATE_TEAM_ERROR,
  CREATE_TEAM_REQUEST,
  CREATE_TEAM_SUCCESS,
  DEACTIVATE_INVITE_TOKEN_ERROR,
  DEACTIVATE_INVITE_TOKEN_REQUEST,
  DEACTIVATE_INVITE_TOKEN_SUCCESS,
  DELETE_TEAM_ERROR,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
  EDIT_TEAM_ERROR,
  EDIT_TEAM_REQUEST, EDIT_TEAM_SETTINGS_ERROR,
  EDIT_TEAM_SETTINGS_REQUEST, EDIT_TEAM_SETTINGS_SUCCESS,
  EDIT_TEAM_SUCCESS,
  FETCH_PLAYERS_SUCCESS,
  FETCH_TEAM_ERROR,
  FETCH_TEAM_INVITE_TOKEN_REQUEST,
  FETCH_TEAM_INVITE_TOKEN_SUCCESS,
  FETCH_TEAM_REQUEST,
  FETCH_TEAM_SUCCESS,
  FETCH_TEAMS_ERROR,
  FETCH_TEAMS_REQUEST,
  FETCH_TEAMS_SUCCESS,
  GENERATE_INVITE_TOKEN_ERROR,
  GENERATE_INVITE_TOKEN_REQUEST,
  GENERATE_INVITE_TOKEN_SUCCESS,
  SET_LOCK_NUMBERS_SUCCESS,
  TEAM_SEASON_TRANSFER_ERROR,
  TEAM_SEASON_TRANSFER_REQUEST,
  TEAM_SEASON_TRANSFER_SUCCESS,
} from '../constants/actionTypes';
import { SIGN_OUT_REQUEST } from '../packages/authentication';

export const initialState: TeamReducer = {
  byHash: {},
  byId: [],
  activeTeams: [],
  count: 0,
  loading: false,
  loadingTeams: false,
  bulkAssignLoading: false,
  error: false,
  errorMessage: '',
  inviteToken: null,
  inviteTokenError: false,
  loadingInviteToken: false,
  tokenDeactivated: false,
  hasInactiveTeams: false,
  editionSuccessful: false,
  switchingSeason: false,
  switchSeasonSuccess: false,
};

export default (state = initialState, action: Actions) => {
  switch (action.type) {
    case BULK_ASSIGN_MEMBERS_REQUEST:
      return {
        ...state,
        bulkAssignLoading: true,
        loading: true,
      };
    case CREATE_TEAM_REQUEST:
    case DELETE_TEAM_REQUEST:
    case FETCH_TEAMS_REQUEST:
      return {
        ...state,
        loadingTeams: true,
      };
    case EDIT_TEAM_REQUEST:
      return {
        ...state,
        editionSuccessful: false,
        loading: true,
      };
    case EDIT_TEAM_SUCCESS:
      return {
        ...state,
        error: false,
        errorMessage: null,
        editionSuccessful: true,
        loading: false,
        byHash: state.byId.reduce((byHash: TeamsByHash, teamId: number) => ({
          ...byHash,
          ...(teamId === action.payload.teamId)
            && ({ [teamId]: { ...state.byHash[teamId], name: action.payload.teamName } }),
        }), state.byHash),
      };
    case EDIT_TEAM_ERROR: {
      const { payload: { error } } = action;
      return {
        ...state,
        error: true,
        loading: false,
        errorMessage: error.response && error.response.message ? error.response.message : '',
      };
    }
    case BULK_ASSIGN_MEMBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        bulkAssignLoading: false,
      };
    case FETCH_TEAMS_SUCCESS: {
      const { count, teams, hasInactiveTeams } = action.payload;

      return {
        ...state,
        byHash: teams
          .reduce((byHash: TeamsByHash, team: Team) => ({
            ...byHash,
            [team.id]: team,
          }), state.byHash),
        byId: teams.map(({ id }: Team) => id),
        hasInactiveTeams,
        loadingTeams: false,
        count,
      };
    }
    case DELETE_TEAM_SUCCESS: {
      const { id: teamId } = action.payload;
      const { [teamId]: removedTeam, ...updatedByHash } = state.byHash;
      return {
        ...state,
        loading: false,
        byHash: updatedByHash,
        byId: state.byId.filter(id => id !== teamId),
      };
    }
    case ACTIVE_TEAMS_SUCCESS:
      return {
        ...state,
        activeTeams: uniqBy(action.payload.activeTeams, 'id'),
      };
    case FETCH_TEAM_REQUEST:
    case FETCH_TEAM_INVITE_TOKEN_REQUEST:
    case GENERATE_INVITE_TOKEN_REQUEST:
    case DEACTIVATE_INVITE_TOKEN_REQUEST:
      return {
        ...state,
        loading: true,
        loadingInviteToken: true,
        tokenDeactivated: false,
      };
    case FETCH_TEAM_INVITE_TOKEN_SUCCESS:
    case GENERATE_INVITE_TOKEN_SUCCESS:
      return {
        ...state,
        inviteToken: action.payload.token,
        loading: false,
        loadingInviteToken: false,
        inviteTokenError: false,
        tokenDeactivated: false,
      };
    case GENERATE_INVITE_TOKEN_ERROR:
    case DEACTIVATE_INVITE_TOKEN_ERROR:
      return {
        ...state,
        loadingInviteToken: false,
        inviteTokenError: true,
        tokenDeactivated: false,
      };
    case DEACTIVATE_INVITE_TOKEN_SUCCESS:
      return {
        ...state,
        inviteToken: null,
        loadingInviteToken: false,
        inviteTokenError: false,
        tokenDeactivated: true,
      };
    case FETCH_TEAM_SUCCESS: {
      const { team } = action.payload;

      const statePlayers = get(state.byHash[team.id], 'players', []);
      const playersIds = team.players.map(({ id }) => id);

      return {
        ...state,
        loading: false,
        byHash: {
          ...state.byHash,
          [team.id]: {
            ...team,
            players: statePlayers.filter(({ id }) => playersIds.includes(id)),
          },
        },
      };
    }
    case FETCH_PLAYERS_SUCCESS: {
      const { players, teamId } = action.payload;

      return {
        ...state,
        loading: false,
        byHash: {
          ...state.byHash,
          [teamId]: {
            ...state.byHash[teamId],
            players,
          },
        },
      };
    }
    case CREATE_TEAM_SUCCESS: {
      const { team } = action.payload;
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        byId: [
          ...state.byId.filter(id => id !== team.id),
          team.id,
        ],
        byHash: { ...state.byHash, [team.id]: team },
      };
    }
    case SET_LOCK_NUMBERS_SUCCESS: {
      const { teamId, lockedNumbers } = action.payload;
      return {
        ...state,
        byHash: {
          ...state.byHash,
          [teamId as number]: {
            ...state.byHash[teamId as number],
            lockedNumbers,
          },
        },
      };
    }
    case CHANGE_TEAM_STATUS_SUCCESS: {
      const { teamId, status } = action.payload;
      return {
        ...state,
        byHash: {
          ...state.byHash,
          [teamId as number]: {
            ...state.byHash[teamId as number],
            status,
          },
        },
      };
    }
    case SIGN_OUT_REQUEST:
      return initialState;
    case CREATE_TEAM_ERROR:
    case FETCH_TEAM_ERROR:
    case FETCH_TEAMS_ERROR:
    case DELETE_TEAM_ERROR:
    case BULK_ASSIGN_MEMBERS_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        loadingTeams: false,
      };
    case TEAM_SEASON_TRANSFER_REQUEST:
      return {
        ...state,
        switchingSeason: true,
        switchSeasonSuccess: false,
      };
    case TEAM_SEASON_TRANSFER_SUCCESS:
      return {
        ...state,
        switchingSeason: false,
        switchSeasonSuccess: true,
      };
    case TEAM_SEASON_TRANSFER_ERROR:
      return {
        ...state,
        switchingSeason: false,
        switchSeasonSuccess: false,
      };
    case EDIT_TEAM_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EDIT_TEAM_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case EDIT_TEAM_SETTINGS_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
