import { Price } from './Price';
import { TagsByHash } from './Tag';
import { EmbellishmentsByHash, EmbellishmentTypes, ProductEmbellishment } from './Embellishment';

export interface File {
  id: string;
  extension: string;
  originalFilename: string;
}

export interface Product {
  id: string;
  name: string;
  externalId: string;
  originalPrice: Price;
  priceWithEmbellishmentsMandatory: Price;
  priceWithEmbellishmentsOptional: Price;
  discountPercent: number | null;
  createdAt: string;
  files: File[];
  originalImages: string[];
  tagIds: number[];
  embellishments: ProductEmbellishment[];
  description: string;
  availableSizes: string[];
  exclusiveToTeams?: number[];
}

export interface ProductsByHash {
  [key: string]: Product,
}

export enum GroupType {
  CLUB = 'CLUB',
  TEAM = 'TEAM',
}

export interface EmbellishmentFixedPriceDiscountType {
  key: string;
  embellishment?: EmbellishmentTypes;
  groupType?: GroupType;
  groupId?: number;
  price: Price;
}

export interface TeamDiscountsType {
  key: string;
  teamId?: number;
  discountPercent: number;
}

export interface Catalog {
  productDiscountPercent: number;
  embellishmentsDiscountPercent?: number;
  teamProductDiscounts?: TeamDiscountsType[],
  teamEmbellishmentsDiscounts?: TeamDiscountsType[],
  embellishmentFixedPriceDiscounts?: EmbellishmentFixedPriceDiscountType[],
  createdAt: Date;
  updatedAt: Date;
  productsById: string[];
  productsByHash: ProductsByHash;
  clubName: string;
}

export interface CatalogDataResponse {
  productDiscountPercent: number;
  createdAt: string;
  products: Product[];
  club: {
    id: number;
    name: string;
  }
  embellishmentsDiscountPercent?: number;
  teamProductDiscounts?: TeamDiscountsType[],
  teamEmbellishmentsDiscounts?: TeamDiscountsType[],
  embellishmentFixedPriceDiscounts?: EmbellishmentFixedPriceDiscountType[],
  updatedAt: string;
}

export interface CatalogReducer {
  loading: boolean;
  loadingTags: boolean;
  catalog: Catalog;
  tagsByHash: TagsByHash;
  tagsById: [];
  embellishmentsByHash: EmbellishmentsByHash;
  embellishmentsById: EmbellishmentTypes[];
}
