import React, { FC, useEffect, useState } from 'react';
import { Checkbox, CheckboxProps } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { xor } from 'lodash';

import Confirm from '../../../../components/Confirm';
import TableContainer from '../../../../components/Layout/components/TableContainer';
import filteredColumns from './filteredColumns';
import { OrdersDesktopProps } from './OrdersDesktopProps';
import OrdersRow from './components/OrdersRow';
import { OrderList } from '../../../../models/Order';
import Header from '../../../../components/Header';
import Text from '../../../../components/Text';
import { history } from '../../../../configureStore';
import styles from './OrdersDesktop.module.scss';
import { Column } from '../../../../components/Table/Column';
import SortArrows from '../../../../components/Table/components/SortArrows';
import { CLUB_ROUTE, TEAM_ROUTE } from '../../../../constants/routes';
import FooterSelectedNumber from '../../../OrderPreparation/components/DesktopFooter/FooterSelectedNumber';
import OrderTabsComponent from '../../../../components/OrderTabsComponent/OrderTabsComponent';

const OrdersDesktop: FC<OrdersDesktopProps> = ({
  count,
  deleteOrders,
  fetchOrders,
  loading,
  orders,
  ordersDeleted,
}) => {
  const [translate] = useTranslation();
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const orderContext = history.location.pathname.startsWith(TEAM_ROUTE) ? TEAM_ROUTE : CLUB_ROUTE;
  const ordersIds = orders.map(({ id }) => id);

  const handleRowSelection = (id: number) => {
    setSelectedRows(xor(selectedRows, [id]));
  };

  useEffect(() => {
    if (ordersDeleted) {
      setSelectedRows([]);
    }
  }, [ordersDeleted]);

  const handleDeleteClick = () => {
    deleteOrders(selectedRows);
  };

  const renderRows = (order: OrderList) => (
    <OrdersRow
      order={order}
      orderContext={orderContext}
      key={order.id}
      onSelect={handleRowSelection}
      isSelected={selectedRows.includes(order.id)}
    />
  );

  const handleSelectAll = (e: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    e.stopPropagation();
    setSelectedRows(data.checked ? ordersIds : []);
  };

  const [firstColumn, ...restColumns] = filteredColumns(orderContext);
  const enrichedColumns: Column[] = [{
    ...firstColumn,
    headerComponent: sortOrder => (
      <div className={styles.headerComponent}>
        { orderContext === TEAM_ROUTE && (
          <Checkbox checked={orders.length === selectedRows.length} onChange={handleSelectAll} />
        )}
        <Text bold ellipsis>{`# ${translate('ORDER')}`}</Text>
        <SortArrows sortOrder={sortOrder} />
      </div>
    ),
  },
  ...restColumns,
  ];

  return (
    <div className={styles.wrapper}>
      <div className={[styles.topGroup, orderContext === TEAM_ROUTE && styles.fixHeight].join(' ')}>
        <Header
          title={translate('ORDERS')}
          border
        />
        <div className={styles['content-wrapper']}>
          {orderContext === CLUB_ROUTE && <OrderTabsComponent />}
          <TableContainer
            fetch={fetchOrders}
            loading={loading}
            count={count}
            history={history}
            columns={enrichedColumns}
            renderItems={orders.map(renderRows)}
            disableSearch
            invertedHeader
            emptyTableText="NO_ORDERS_AVAILABLE"
          />
        </div>
      </div>
      { orderContext === TEAM_ROUTE && (
        <div className={styles.footer}>
          <FooterSelectedNumber text={`${translate('ITEMS_SELECTED')} (${selectedRows.length})`} />
          <Confirm
            basic
            className={[styles.action, styles.button].join(' ')}
            confirmText={translate('ARE_YOU_SURE_TO_DELETE_ORDERS')}
            disabled={!selectedRows.length}
            onConfirm={handleDeleteClick}
            primary
            triggerText={`${translate('DELETE_ORDER')} (${selectedRows.length})`}
          />
        </div>
      )}
    </div>
  );
};

export default OrdersDesktop;
