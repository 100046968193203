import { connect } from 'react-redux';
import UnprotectedRoute from './UnprotectedRoute';
import State from '../../../../models';

const mapStateToProps = ({
  authentication: { authToken },
  application: {
    clubId, teamId, userRole, memberships = {},
  },
  user: { user, infoFetched },
}: State) => ({
  isAuthenticated: !!authToken,
  clubId,
  teamId,
  userRole,
  user,
  memberships,
  infoFetched,
});

export default connect(mapStateToProps, null)(UnprotectedRoute);
