import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import EditTeamTeamLead from './EditTeamTeamLead';
import { State } from '../../models/State';
import {
  editTeamRequest,
  fetchTeamIdRequest, fetchTeamRequest,
} from '../../actions/teams';
import { fetchClubRequest } from '../../actions/clubs';

const mapStatesToProps = ({
  application: { clubId },
  teams,
}: State) => ({
  clubId,
  teamsByHash: teams.byHash,
  loading: teams.loading,
  error: teams.error,
  errorMessage: teams.errorMessage,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchTeam: bindActionCreators(fetchTeamRequest, dispatch),
  fetchTeamId: bindActionCreators(fetchTeamIdRequest, dispatch),
  editTeam: bindActionCreators(editTeamRequest, dispatch),
  fetchClubById: bindActionCreators(fetchClubRequest, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(EditTeamTeamLead);
