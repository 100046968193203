import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import DeleteData from './DeleteData';
import State from '../../models';
import { deleteDataRequest } from '../../actions/user';

const mapStateToProps = ({
  clubs: { logoUrl },
}: State) => ({
  clubLogoUrl: logoUrl,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  deleteData: bindActionCreators(deleteDataRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteData);
