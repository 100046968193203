import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Message } from 'semantic-ui-react';
import { Subject } from 'rxjs';
import { tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import styles from './CreateMember.module.scss';
import Header from '../../../../components/Header';
import { SETTINGS_ROUTE } from '../../../../constants/routes';
import FormFields from '../../../../components/FormFields';
import { createSchema, validationSchema } from './schema';
import useForm from '../../../../hooks/useForm';
import { CreateMemberProps } from './CreateMemberProps';
import { Roles } from '../../../../constants/roles';
import { PlayerFormData } from '../../../../models/Player';
import TeamStatus from '../../../../constants/teamStatus';
import { isValidEmail } from '../../../../utils/formValidators';

const CreateMember = ({
  clubId,
  sendInvitation,
  createPlayer,
  error,
  fetchTeams,
  emailAvailable,
  checkingEmail,
  checkEmail,
  loading,
  byHash,
  byId,
}: CreateMemberProps) => {
  const [translate] = useTranslation();
  const [emailChecked, setEmailChecked] = useState(false);
  const [email$] = useState(() => new Subject<string>());

  const {
    values,
    errors,
    handleChange,
    validate,
  } = useForm(validationSchema);

  useEffect(() => {
    email$.next(values.email);
  }, [values.email, email$]);

  useEffect(() => {
    const emailChangeListener = email$
      .pipe(
        tap(() => { setEmailChecked(false); }),
        debounceTime(500),
        distinctUntilChanged(),
      ).subscribe((email: string) => {
        if (isValidEmail.rule(email)) {
          setEmailChecked(true);
          checkEmail(email);
        }
      });

    return () => {
      emailChangeListener.unsubscribe();
    };
  }, [email$, checkEmail]);

  const submitInviteMember = () => {
    if (validate()) {
      const {
        firstName, lastName, role, email, teamId,
      } = values;
      const invitationPersonalData = { firstName, lastName };
      const createInvitation = {
        receiverEmail: email,
        receiverRole: role,
        teamId,
        clubId,
      };

      if (role === Roles.PLAYER) {
        const player : PlayerFormData = {
          firstName,
          lastName,
          email,
        };

        createPlayer(player, byHash[teamId], true, createInvitation, invitationPersonalData);
      } else {
        sendInvitation(
          createInvitation,
          invitationPersonalData,
          true,
        );
      }
    }
  };

  useEffect(() => {
    fetchTeams();
  }, [fetchTeams]);

  const renderSaveButton = () => (
    <div>
      <Button
        primary
        content={translate('SAVE')}
        disabled={checkingEmail || !emailChecked || !emailAvailable}
        key="button"
        onClick={submitInviteMember}
      />
    </div>
  );

  createSchema.teamId.disabled = values.role === Roles.CLUB_ADMIN || !values.role;
  createSchema.teamId.options = () => byId
    .filter(id => byHash[id].status === TeamStatus.ACTIVE)
    .map(id => ({
      value: id,
      key: id,
      text: byHash[id].name,
    }));

  return (
    <div className={styles.wrapper}>
      <div className={[styles.topGroup, styles.fixHeight].join(' ')}>
        <Header
          backLinkTo={SETTINGS_ROUTE}
          backLabel="CANCEL"
          title={translate('ADD_TEAM_MEMBER')}
          renderButton={renderSaveButton}
        />
        <div className={styles.contentWrapper}>
          <Form error={error} onSubmit={submitInviteMember} loading={loading} className={styles.form} id="create-form">
            {error && (<Message error={error} header={translate('ERROR')} content={translate('GENERAL_ERROR')} />)}

            <FormFields
              schema={createSchema}
              errors={errors}
              handleChange={handleChange}
              values={values}
              inline
            />
            {!emailAvailable && !checkingEmail && emailChecked && (
              <Message
                negative
                header={translate('EMAIL_NOT_AVAILABLE_ERROR_HEADER')}
                content={translate('EMAIL_NOT_AVAILABLE_SETTINGS_ERROR_TEXT')}
              />
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CreateMember;
