import React, {
  useEffect, useState, useMemo,
} from 'react';
import { Button, Loader } from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from '../../components/Header';
import TeamInvitationPopup from '../ClubTeams/components/TeamInvitationPopup';
import TeamsFooter from './components/TeamsFooter';
import TeamsTabs from '../../components/TeamsTabs';
import params from '../../utils/params';
import styles from './TeamsTeamLead.module.scss';
import {
  NEW_MEMBER_ROUTE,
  TEAM_EDIT_ROUTE,
  TEAM_VIEW_ROUTE,
} from '../../constants/routes';
import { OrderItemType } from '../OrderPreparation/OrderPreparationProps';
import { TeamsTeamLeadProps } from './TeamsTeamLeadProps';
import { UrlParams } from '../../constants/urlParams';
import PlayersAndStaffList from '../PlayersAndStaffList';
import NumbersBalloon from '../OrderPreparation/components/NumbersBalloon';
import filterTeamsBasedOnRole from '../../utils/roleBasedTeamsFilter';

const TeamsTeamLead = ({
  teamsById,
  teamsByHash,
  setSelectedPlayers,
  lockedNumbers,
  usedNumbers,
  fetchTeams,
  clubId,
  teamId,
  setApplicationParameter,
  fetchTeamId,
  lockNumbers,
  fetchInviteToken,
  authUser,
  userRole,
  loadingTeams,
}: TeamsTeamLeadProps) => {
  const [translate] = useTranslation();
  const history = useHistory();
  const { getParams } = params(history);
  const [showInvitationPopup, setShowInvitationPopup] = useState<boolean>(false);
  const {
    [UrlParams.TEAM_ROLE_FILTER]: roleFilter,
  } = getParams();

  useEffect(() => {
    setApplicationParameter({ teamId });
  }, [teamId, setApplicationParameter]);

  useEffect(() => {
    if (!teamId && clubId) {
      fetchTeamId(clubId);
    }
  }, [clubId, fetchTeamId, teamId]);

  useEffect(() => {
    if (clubId) {
      fetchTeams();
    }
  }, [fetchTeams, clubId]);

  const onTeamChange = () => {
    setSelectedPlayers([]);
  };

  const renderNewElementLogic = () => (
    <div key="new-buttons">
      {!!teamId && (
        <>
          <Button
            content={translate('SHARE_TEAM_INVITATION')}
            icon="share alternate"
            onClick={() => {
              setShowInvitationPopup(true);
              fetchInviteToken(teamId);
            }}
          />
          <TeamInvitationPopup
            teamId={teamId}
            isOpen={showInvitationPopup}
            closePopup={() => {
              setShowInvitationPopup(false);
            }}
          />
        </>
      )}
      <Button
        as={Link}
        primary
        basic
        content={translate('EDIT_TEAM')}
        key="button-edit"
        to={TEAM_EDIT_ROUTE.replace(':teamId', `${teamId}`)}
      />
    </div>
  );

  const linkAddMember = NEW_MEMBER_ROUTE.replace(':teamId', `${teamId}`);

  const playersActions = [
    <NumbersBalloon
      // @ts-ignore
      basic
      primary
      lockedNumbers={lockedNumbers}
      lockNumbers={lockNumbers}
      usedNumbers={usedNumbers}
      key="numbers-balloon"
    />,
    <Button as={Link} basic to={linkAddMember} content={translate('ADD_PLAYER')} className="simple" primary key="button" />,
  ];

  const roleBasedTeamIds = useMemo(
    () => filterTeamsBasedOnRole(authUser, userRole, teamsById),
    [authUser, teamsById, userRole],
  );

  const staffActions = [
    <Button as={Link} basic to={linkAddMember} content={translate('ADD_TEAM_LEAD')} className="simple" primary key="button" />,
  ];

  return (
    <div className={styles.wrapper}>
      <div className={[styles.topGroup, styles.fixHeight].join(' ')}>
        <Header
          title={translate('TEAMS')}
          renderButton={renderNewElementLogic}
        />
        {loadingTeams ? <Loader active /> : (
          <div className={styles.contentWrapper}>
            <TeamsTabs
              activeTeam={teamId}
              teams={roleBasedTeamIds.map(id => teamsByHash[id])}
              onTabChange={onTeamChange}
              route={TEAM_VIEW_ROUTE}
            />
            <PlayersAndStaffList
              teamId={teamId}
              playersActions={playersActions}
              staffActions={staffActions}
            />
          </div>
        )}
      </div>
      <TeamsFooter
        activeTeam={teamId}
        roleFilter={roleFilter as OrderItemType}
      />
    </div>
  );
};

export default TeamsTeamLead;
