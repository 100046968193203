import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { StateProps, DispatchProps } from './IndividualOrdersProps';
import State from '../../models';
import IndividualOrders from './IndividualOrders';
import { fetchIndividualOrdersRequest } from '../../actions/fetchIndividualOrders';

const mapStateToProps = ({
  individualOrders: {
    loading,
    byId,
    byHash,
    count,
  },
}: State): StateProps => ({
  count,
  loading,
  orders: byId.map((id: number) => byHash[id]),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  fetchOrders: bindActionCreators(fetchIndividualOrdersRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IndividualOrders));
