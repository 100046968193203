import params from '../../utils/params';
import { DEFAULT_LIST_LIMIT } from '../../constants/customizations';

export default (history: any, count: number, loadingList: boolean, limit = DEFAULT_LIST_LIMIT) => {
  const { setParam } = params(history);

  const onPageChange = (event: any, { activePage }: any) => {
    setParam('page', activePage);
  };

  const totalPages = Math.ceil(count / limit);
  const showPagination = limit < count;

  return [onPageChange as () => {}, totalPages as number, showPagination as boolean];
};
