import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AuthenticationFormsWrapper from '../../components/AuthenticationFormsWrapper';
import styles from './MobileBlockerPage.module.scss';
import Signature from '../../components/WelcomePage/Signature';
import HeaderTag from '../../components/WelcomePage/HeaderTag';

interface MobileBlockerProps {
  loading?: boolean;
}

const MobileBlockerPage: FC<MobileBlockerProps> = ({
  loading,
}) => {
  const [translate] = useTranslation();

  const LABEL_TEXT = 'MOBILE_WARNING.LABEL_TEXT';
  const HEADER_TEXT = 'MOBILE_WARNING.HEADER_TEXT';
  const MAIN_CONTENT = 'MOBILE_WARNING.MAIN_CONTENT';

  return (
    <AuthenticationFormsWrapper clubLogoUrl="test" isBlockingMobile>
      { !loading && (
      <div className={styles.wrapper}>
        <HeaderTag
          serverError
          errorTitle={LABEL_TEXT}
        />
        <h2 className={styles.header}>
          {translate(HEADER_TEXT)}
        </h2>
        <span className={[styles.text].join(' ')}>
          {translate('HI')}
          ,
        </span>
        <p className={[styles.text, styles.successfulText, styles.shrink].join(' ')}>
          {translate(MAIN_CONTENT)}
        </p>
        <Signature showHaveFun />
      </div>
      )}
    </AuthenticationFormsWrapper>
  );
};

export default MobileBlockerPage;
