import { Column } from '../../../../components/Table/Column';

const columns: Column[] = [{
  width: '70%',
  key: 'name',
  align: 'left',
}, {
  width: '20%',
  header: 'ORDER_ITEMS',
  key: 'orderItems',
  align: 'right',
}, {
  width: '10%',
  key: 'menu',
  align: 'right',
}];

export default columns;
