import { Item, UsedNumbers } from '../models/Order';
import { EmbellishmentTypes } from '../models/Embellishment';

const filterPrevValues = (usedNumbers: UsedNumbers, playerId: number) => Object.keys(usedNumbers)
  .reduce((prev: UsedNumbers, currentNumber: string) => ({
    ...prev,
    ...(usedNumbers[+currentNumber] !== playerId
      && ({ [+currentNumber]: usedNumbers[+currentNumber] })),
  }), {});

const getUsedNumbers = (items: Item[], usedNumbers: UsedNumbers) => items
  .reduce((prev: UsedNumbers, { embellishments = [], playerId }: Item) => {
    const numberEmbellishment = embellishments
      .find(({ type }) => type === EmbellishmentTypes.BACK_NUMBER);

    let filteredUsedNumbers;
    if (numberEmbellishment && numberEmbellishment.value && playerId) {
      filteredUsedNumbers = filterPrevValues(usedNumbers, playerId);
    }

    return (numberEmbellishment && numberEmbellishment.value) ? ({
      ...filteredUsedNumbers,
      [numberEmbellishment.value as string | number | symbol | any]: playerId || 0,
    }) : prev;
  }, usedNumbers);

export default getUsedNumbers;
