import React, { FC, useEffect } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { TeamSettingsProps } from './TeamSettingsProps';
import useForm from '../../../../hooks/useForm';
import styles from '../EditTeam/EditTeam.module.scss';
import Header from '../../../../components/Header';
import { CLUB_TEAMS_MEMBERS_ROUTE, CLUB_TEAMS_ROUTE } from '../../../../constants/routes';
import FormFields from '../../../../components/FormFields';
import { teamSettingsSchema } from './schema';

const TeamSettings: FC<TeamSettingsProps> = ({
  teamId,
  team,
  editTeamSettings,
  fetchTeam,
}) => {
  const {
    values,
    handleChange,
    setValues,
    errors,
  } = useForm({});
  const [translate] = useTranslation();
  const handleSubmit = () => {
    if (team && team.id) {
      editTeamSettings(
        team.id,
        values.season,
        new Date(values.seasonStartDate).valueOf(),
        new Date(values.seasonEndDate).valueOf(),
      );
    }
  };

  useEffect(() => {
    if (!team && teamId) {
      fetchTeam(teamId);
    } else if (team) {
      let startDate = null;
      if (team.seasonStartDate) {
        startDate = team.seasonStartDate;
      }
      let endDate = null;
      if (team.seasonEndDate) {
        endDate = team.seasonEndDate;
      }
      setValues({
        seasonStartDate: startDate,
        seasonEndDate: endDate,
        season: team && team.season ? team.season : null,
      });
    }
  }, [team, teamId, fetchTeam, setValues]);

  const renderButton = () => <Button primary basic onClick={handleSubmit}>{translate('SAVE')}</Button>;

  return (
    <div className={styles.wrapper}>
      <Header
        backLabel="TO_TEAM_DETAILS"
        backLinkTo={team && team.id ? CLUB_TEAMS_MEMBERS_ROUTE.replace(':teamId', `${team.id}`) : CLUB_TEAMS_ROUTE}
        renderButton={renderButton}
        title={translate('TEAM_SETTINGS')}
        border
      />

      <Form onSubmit={handleSubmit} className={styles.form} id="create-form">
        <FormFields
          handleChange={handleChange}
          schema={teamSettingsSchema}
          values={values}
          errors={errors}
        />
      </Form>
    </div>
  );
};

export default TeamSettings;
