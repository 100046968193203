import React, { FC, useState } from 'react';
import get from 'lodash/get';
import partition from 'lodash/partition';
import useDeepCompareEffect from 'use-deep-compare-effect';
import xor from 'lodash/xor';
import { useTranslation } from 'react-i18next';
import {
  Button, Checkbox, Modal,
} from 'semantic-ui-react';

import ModalActions from '../../../../../../components/ModalActions';
import styles from './ManageEmbellishmentsModal.module.scss';
import { Embellishment, EmbellishmentTypes, ItemEmbellishment } from '../../../../../../models/Embellishment';
import { ManageEmbellishmentsModalProps } from './ManageEmbellishmentsModalProps';
import { enrichEmbellishment } from '../../../../../../epics/orders/methods/getEmbellishmentsForItem';
import { formatPrice } from '../../../../../../utils/PricingUtil';
import MobileFooter from '../../../../../../components/MobileFooter';
import MobileModal from '../../../MobileModal';
import { useViewport } from '../../../../../../hooks/useViewport';
import Text from '../../../../../../components/Text';
import columns from './columns';
import mobileColumns from './mobileColumns';
import TableCheckboxHeader from '../../../../../../components/TableCheckboxHeader';
import Table from '../../../../../../components/Table';
import Row from '../../../../../../components/Table/components/Row';
import { EmbellishmentGroup } from '../../../MemberDetails/embellishmentTypes';

const ManageEmbellishmentsModal: FC<ManageEmbellishmentsModalProps> = ({
  changeEmbellishments,
  embellishments,
  embellishmentsByHash,
  itemsByHash,
  itemsById,
  playersByHash,
  as,
}) => {
  const [translate] = useTranslation();
  const { isMobile } = useViewport();
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<EmbellishmentTypes[]>([]);
  const itemEmbellishments: ItemEmbellishment[] = get(itemsByHash[itemsById[0]], 'embellishments', []);
  const [embellishmentsMandatory, embellishmentsOptional] = partition(
    embellishments, ({ mandatory }) => mandatory,
  );
  const mandatoryTypes = embellishmentsMandatory.map(({ embellishment }) => embellishment);
  const optionalTypes = embellishmentsOptional.map(({ embellishment }) => embellishment);
  const isAllSelected = selected.length === optionalTypes.length;

  useDeepCompareEffect(() => {
    setSelected(
      itemEmbellishments
        .filter(({ type }) => optionalTypes.includes(type))
        .map(({ type }) => type),
    );
  }, [itemEmbellishments, optionalTypes]);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleSelected = (type: EmbellishmentTypes) => () => {
    setSelected(xor(selected, [type]));
  };

  const toggleAllSelected = () => {
    setSelected(isAllSelected ? [] : optionalTypes);
  };

  const handleSave = () => {
    const enrichedEmbellishments = itemsById.reduce((items, itemId) => {
      const item = itemsByHash[itemId];
      const player = item.playerId ? playersByHash[item.playerId] : null;
      return {
        ...items,
        [itemId]: [
          ...item.embellishments.filter(({ type }) => mandatoryTypes.includes(type)),
          ...selected.map(type => ({
            ...enrichEmbellishment({ type }, player),
            ...item.embellishments.find(embellishment => embellishment.type === type),
            ...((EmbellishmentGroup.BOOLEAN_TYPES.includes(type)
              && !item.embellishments.find(embellishment => embellishment.type === type))
              && ({ value: true })),
          })),
        ],
      };
    }, {});

    changeEmbellishments(itemsById, enrichedEmbellishments);
    toggleModal();
  };

  const renderRow = ({ type, price }: Embellishment) => (
    <Row key={type} itemOnClick={toggleSelected(type)}>
      <div className={styles.article}>
        <Checkbox onClick={toggleSelected(type)} checked={selected.includes(type)} />
        <Text>{translate(type)}</Text>
      </div>
      <Text>{formatPrice(price.amount, price.currency)}</Text>
    </Row>
  );

  const [firstColumn, ...restColumns] = isMobile ? mobileColumns : columns;
  const enrichedColumns = [
    {
      ...firstColumn,
      headerComponent: () => (
        <TableCheckboxHeader
          title="EMBELLISHMENT"
          onClick={toggleAllSelected}
          checked={isAllSelected}
          checkbox
        />
      ),
    },
    ...restColumns,
  ];

  const MobileEmbellishmentModal = (
    <MobileModal
      button={(
        <MobileFooter
          buttonText={translate('SAVE')}
          onClick={handleSave}
        />
      )}
      content={(
        <div className={styles.mobileTableWrapper}>
          <Table columns={enrichedColumns} invertedHeader>
            {optionalTypes.map(type => renderRow(embellishmentsByHash[type]))}
          </Table>
        </div>
      )}
      border
      isOpen={isOpen}
      title={translate('MANAGE_OPTIONAL_EMBELLISHMENTS')}
      onClose={toggleModal}
    />
  );

  const DesktopEmbellishmentModal = (
    <Modal
      onClose={toggleModal}
      onOpen={toggleModal}
      open={isOpen}
      className={styles.wrapper}
    >
      <h2>{translate('MANAGE_OPTIONAL_EMBELLISHMENTS')}</h2>
      <div className={styles.desktopTableWrapper}>
        <Table columns={enrichedColumns} invertedHeader>
          {optionalTypes.map(type => renderRow(embellishmentsByHash[type]))}
        </Table>
      </div>
      <ModalActions
        onCancel={toggleModal}
        onSave={handleSave}
      />
    </Modal>
  );

  return (
    <>
      <Button
        primary={!isMobile}
        basic={!isMobile}
        className="simple"
        onClick={toggleModal}
        content={translate('MANAGE_OPTIONAL_EMBELLISHMENTS')}
        as={as}
      />
      {isMobile ? MobileEmbellishmentModal : DesktopEmbellishmentModal}
    </>
  );
};

export default ManageEmbellishmentsModal;
