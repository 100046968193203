import {
  AuthenticationActions, MembersActions, PlayerActions,
  TeamActions, OrderEditorActions,
} from '../models/actions';
import { SIGN_OUT_REQUEST } from '../packages/authentication';
import {
  PlayerListTL,
  PlayerTeamList,
  PlayersByHash,
  PlayersReducer,
} from '../models/Player';
import {
  CREATE_PLAYER_ERROR,
  CREATE_PLAYER_REQUEST,
  CREATE_PLAYER_SUCCESS,
  FETCH_PLAYER_ERROR,
  FETCH_PLAYER_REQUEST,
  FETCH_PLAYER_SUCCESS,
  FETCH_PLAYERS_SUCCESS,
  FETCH_PLAYERS_REQUEST,
  FETCH_TEAM_SUCCESS,
  FETCH_PLAYERS_ERROR,
  DELETE_MEMBERS_ERROR,
  DELETE_MEMBERS_SUCCESS,
  DELETE_MEMBERS_REQUEST,
  SET_SELECTED_PLAYERS,
  EDIT_ROLES_MEMBERS_SUCCESS,
  EDIT_ROLES_MEMBERS_REQUEST,
  EDIT_ROLES_MEMBERS_ERROR,
  ORDER_SELECT_PLAYERS,
} from '../constants/actionTypes';
import { Roles } from '../constants/roles';

export const initialState: PlayersReducer = {
  byHash: {},
  byId: [],
  selectedId: [],
  count: 0,
  loading: false,
  selectedMembersTeam: null,
  error: false,
  errorMessage: '',
  playerDeleted: false,
};

export default (
  state = initialState,
  action: PlayerActions | AuthenticationActions | TeamActions
  | MembersActions | OrderEditorActions,
) => {
  switch (action.type) {
    case CREATE_PLAYER_REQUEST:
    case FETCH_PLAYER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_PLAYER_SUCCESS: {
      const { player } = action.payload;
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        byId: [
          ...state.byId.filter(id => id !== player.id),
          player.id,
        ],
        byHash: { ...state.byHash, [player.id]: player },
      };
    }
    case FETCH_TEAM_SUCCESS: {
      const { team: { players } } = action.payload;

      const playersByHash = players.reduce((byHash: PlayersByHash, player: PlayerTeamList) => ({
        ...byHash,
        [player.id]: {
          ...player,
          ...byHash[player.id],
        },
      }), state.byHash);

      return {
        ...state,
        byHash: playersByHash,
        byId: players.map(player => player.id),
      };
    }
    case FETCH_PLAYERS_SUCCESS: {
      const { players, count } = action.payload;

      const playersByHash = players.reduce((byHash: PlayersByHash, player: PlayerTeamList) => ({
        ...byHash,
        [player.id]: player,
      }), state.byHash);

      return {
        ...state,
        loading: false,
        error: false,
        byHash: playersByHash,
        byId: players.map((player: PlayerListTL) => player.id),
        count,
      };
    }
    case FETCH_PLAYERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_MEMBERS_REQUEST:
      return {
        ...state,
        loading: true,
        playerDeleted: false,
      };
    case FETCH_PLAYER_SUCCESS: {
      const { player } = action.payload;

      return {
        ...state,
        loading: false,
        byHash: {
          ...state.byHash,
          [player.id]: player,
        },
        byId: state.byId.includes(player.id)
          ? state.byId
          : [...state.byId, player.id],
      };
    }
    case DELETE_MEMBERS_SUCCESS: {
      const { personIds } = action.payload;
      const filteredPlayers = state.byId
        .filter(id => !personIds.includes(state.byHash[id].personId));
      return {
        ...state,
        loading: false,
        error: false,
        playerDeleted: true,
        byId: filteredPlayers,
        byHash: filteredPlayers.reduce((byHash: PlayersByHash, playerId: number) => ({
          ...byHash,
          [playerId]: state.byHash[playerId],
        }), {}),
      };
    }
    case ORDER_SELECT_PLAYERS:
    case SET_SELECTED_PLAYERS: {
      const { ids, teamId } = action.payload;
      return {
        ...state,
        selectedId: ids,
        selectedMembersTeam: teamId,
      };
    }
    case DELETE_MEMBERS_ERROR:
    case FETCH_PLAYERS_ERROR:
    case FETCH_PLAYER_ERROR:
    case CREATE_PLAYER_ERROR:
    case EDIT_ROLES_MEMBERS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.error.message,
      };
    case EDIT_ROLES_MEMBERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EDIT_ROLES_MEMBERS_SUCCESS: {
      const { personId, roles } = action.payload;
      const playerWithPersonId = state.byId
        .map(id => state.byHash[id])
        .find(player => player.personId === personId);
      const updatedByHash: PlayersByHash = playerWithPersonId ? {
        ...state.byHash, [playerWithPersonId.id]: { ...state.byHash[playerWithPersonId.id], roles },
      } : state.byHash;
      const filteredByHash = state.byId
        .reduce((byHash: PlayersByHash, playerId: number) => ({
          ...byHash,
          ...(
            updatedByHash[playerId]
            && updatedByHash[playerId].roles
            && updatedByHash[playerId].roles!.some(
              role => [Roles.PLAYER].includes(role),
            )
            && ({ [playerId]: { ...updatedByHash[playerId] } })),
        }), {});
      return {
        ...state,
        loading: false,
        error: false,
        byHash: filteredByHash,
        byId: Object.keys(filteredByHash).map(Number),
      };
    }
    case SIGN_OUT_REQUEST:
      return initialState;
    default:
      return state;
  }
};
