import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { changeTeamStatusRequest, fetchTeamInviteRequest } from '../../../../actions/teams';
import ClubTeamsRow from './ClubTeamsRow';
import { DispatchProps } from './ClubTeamsRowProps';
import { resendInvitationRequest } from '../../../../actions/invitations';

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  changeTeamStatus: bindActionCreators(changeTeamStatusRequest, dispatch),
  resendInvitation: bindActionCreators(resendInvitationRequest, dispatch),
  fetchInviteToken: bindActionCreators(fetchTeamInviteRequest, dispatch),
});

export default connect(null, mapDispatchToProps)(ClubTeamsRow);
