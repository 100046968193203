import get from 'lodash/get';
import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import {
  catchError, mergeMap, withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';

import { Response } from '../../models/Response';
import { State } from '../../models/State';
import { ACTIVE_TEAMS_PATH } from '../../constants/paths';
import { TeamBase } from '../../models/Team';
import { FETCH_TEAM_ID_REQUEST } from '../../constants/actionTypes';
import { setApplicationParameters } from '../../actions/application';
import { FetchTeamIdRequestAction } from '../../models/actions';
import { fetchTeamIdError, activeTeamsSuccess } from '../../actions/teams';

export default (
  action$: ActionsObservable<FetchTeamIdRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_TEAM_ID_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{ payload: { clubId } }, { authentication: { authToken } }]) => ajax({
    url: ACTIVE_TEAMS_PATH.replace('$clubId', `${clubId}`),
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify({}),
  }).pipe(
    mergeMap(({ response: activeTeams }: Response<TeamBase[]>) => {
      const teamId = get(activeTeams, [0, 'id'], null);
      return [setApplicationParameters({ teamId }), activeTeamsSuccess(activeTeams)];
    }),
    catchError(error => of(fetchTeamIdError(error))),
  )),
);
