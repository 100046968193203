import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { fetchTor24MatchesRequest } from '../../../../actions/externalSystems/tor24Matches';
import State from '../../../../models';
import ImportMatchModal from './ImportMatchModal';
import { createEventsRequest, resetEventCreated } from '../../../../actions/calendar';

const mapStateToProps = (
  {
    externalSystems: {
      externalMatchesByHash,
      externalMatchesById,
      loading: externalSystemsLoading,
    },
    calendar: {
      eventCreated,
      loading: calendarLoading,
    },
  }: State,
) => ({
  externalMatchesByHash,
  externalMatchesById,
  matches: externalMatchesById.map(id => externalMatchesByHash[id]),
  loading: externalSystemsLoading || calendarLoading,
  eventsCreated: eventCreated,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchMatches: bindActionCreators(fetchTor24MatchesRequest, dispatch),
  createEvents: bindActionCreators(createEventsRequest, dispatch),
  resetEventCreated: bindActionCreators(resetEventCreated, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportMatchModal);
