import React, { FC } from 'react';
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { MatchStatsFormProps } from './MatchStatsFormProps';
import {
  HOME_TEAM_RESULT, AWAY_TEAM_RESULT, REPORT,
} from '../MatchEventModal/MatchEventModalProps';
import styles from './MatchStatsForm.module.scss';

const MatchStatsForm: FC<MatchStatsFormProps> = ({
  formState,
  handleChange,
  matchResult,
  handleResultChange,
  loading,
}) => {
  const [translate] = useTranslation();

  return (
    <Form className={styles.wrapper} loading={loading}>
      <div className={styles.flex}>
        <div className={styles.result}>{translate('RESULT')}</div>
        <Form.Group widths="equal" className={styles.inputWrapper}>
          <Form.Input
            fluid
            label={translate('HOME_TEAM')}
            value={matchResult[HOME_TEAM_RESULT]}
            onChange={handleResultChange}
            name={HOME_TEAM_RESULT}
            type="number"
            placeholder="-"
            className={styles.resultInput}
            min={0}
          />
          <div className={styles.colon}>:</div>
          <Form.Input
            fluid
            label={translate('AWAY_TEAM')}
            value={matchResult[AWAY_TEAM_RESULT]}
            onChange={handleResultChange}
            name={AWAY_TEAM_RESULT}
            placeholder="-"
            type="number"
            className={styles.resultInput}
            min={0}
          />
        </Form.Group>
        <div className={styles.placeholder} />
      </div>
      <Form.TextArea rows={8} label={translate('MATCH_REPORT')} onChange={handleChange} value={formState[REPORT]} name={REPORT} />
    </Form>
  );
};

export default MatchStatsForm;
