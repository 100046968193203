import { AuthenticationActions, CatalogActions } from '../models/actions';
import {
  FETCH_CATALOG_REQUEST,
  FETCH_CATALOG_SUCCESS,
  FETCH_CATALOG_ERROR,
  FETCH_TAGS_REQUEST,
  FETCH_TAGS_SUCCESS,
  FETCH_EMBELLISHMENTS_SUCCESS,
} from '../constants/actionTypes';
import { Catalog, CatalogReducer } from '../models/Catalog';
import { SIGN_OUT_ERROR, SIGN_OUT_SUCCESS } from '../packages/authentication/constants';
import { Embellishment, EmbellishmentsByHash } from '../models/Embellishment';
import { Tag, TagsByHash } from '../models/Tag';

export const initialState: CatalogReducer = {
  loading: false,
  loadingTags: false,
  catalog: {} as Catalog,
  tagsByHash: {},
  tagsById: [],
  embellishmentsByHash: {},
  embellishmentsById: [],
};

export default (state = initialState, action: CatalogActions | AuthenticationActions) => {
  switch (action.type) {
    case FETCH_CATALOG_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CATALOG_SUCCESS: {
      const { catalog } = action.payload;
      return {
        ...state,
        catalog,
        loading: false,
      };
    }
    case FETCH_CATALOG_ERROR:
      return {
        ...state,
        loading: false,
      };
    case FETCH_TAGS_REQUEST:
      return {
        ...state,
        loadingTags: true,
      };
    case FETCH_TAGS_SUCCESS: {
      const { tags } = action.payload;
      return {
        ...state,
        loadingTags: false,
        tagsByHash: tags
          .reduce((tagsbyHash: TagsByHash, tag: Tag) => ({
            ...tagsbyHash,
            [tag.id]: tag,
          }), state.tagsByHash),
        tagsById: tags.map(({ id }: Tag) => id),
      };
    }
    case FETCH_EMBELLISHMENTS_SUCCESS: {
      const { embellishments } = action.payload;
      return {
        ...state,
        embellishmentsByHash: embellishments
          .reduce((embellishmentsByHash: EmbellishmentsByHash, embellishment: Embellishment) => ({
            ...embellishmentsByHash,
            [embellishment.type]: embellishment,
          }), state.embellishmentsByHash),
        embellishmentsById: embellishments.map(({ type }: Embellishment) => type),
      };
    }
    case SIGN_OUT_SUCCESS:
    case SIGN_OUT_ERROR:
      return initialState;
    default:
      return state;
  }
};
