import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import { FETCH_USER_INFO_ERROR, FETCH_USER_INFO_REQUEST } from '../../constants/actionTypes';
import { FetchUserInfoRequestAction } from '../../models/actions';
import { Response } from '../../models/Response';
import { State } from '../../models/State';
import { USER_INFO_PATH } from '../../constants/paths';
import { User } from '../../models/general/User';
import { fetchUserInfoSuccess } from '../../actions/user';
import { handleError } from '../../actions/errors';

export default (
  action$: ActionsObservable<FetchUserInfoRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_USER_INFO_REQUEST),
  withLatestFrom(state$),
  mergeMap(([, {
    authentication: { authToken },
  }]) => ajax({
    url: USER_INFO_PATH,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    map(({ response: user }: Response<User>) => fetchUserInfoSuccess(user)),
    catchError((error: AjaxError) => [
      handleError(error, FETCH_USER_INFO_ERROR),
    ]),
  )),
);
