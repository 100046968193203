import React, { FC, useEffect } from 'react';
import { RouteComponentProps, Redirect } from 'react-router';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import AuthenticationFormsWrapper from '../../components/AuthenticationFormsWrapper';
import styles from './HobbyTeamWelcome.module.scss';
import { UrlParams } from '../../constants/urlParams';
import MobileApps from '../../components/WelcomePage/MobileApps';
import Signature from '../../components/WelcomePage/Signature';
import HeaderTag from '../../components/WelcomePage/HeaderTag';

interface HobbyTeamWelcomeProps extends RouteComponentProps {
  clubLogoUrl: string;
  validateHobbyTeamToken: (token: string) => void;
  serverError?: boolean;
  loading?: boolean;
  userFirstName: string;
}

const HobbyTeamWelcome: FC<HobbyTeamWelcomeProps> = ({
  clubLogoUrl,
  validateHobbyTeamToken,
  location,
  serverError,
  loading,
  userFirstName,
}) => {
  const [translate] = useTranslation();
  const queryParams = queryString.parse(location.search);
  const token = queryParams[UrlParams.TOKEN] as string;

  useEffect(() => {
    validateHobbyTeamToken(token);
  }, [validateHobbyTeamToken, token]);

  if (!token) {
    return <Redirect to="/sign-in" />;
  }

  return (
    <AuthenticationFormsWrapper clubLogoUrl={clubLogoUrl}>
      { !loading && (
        <div className={styles.wrapper}>
          <HeaderTag successTitle="GOAL_EXCLMATION" errorTitle="FOUL" serverError={serverError} />
          <h2 className={styles.header}>
            {translate(!serverError ? 'EMAIL_SUCCESSFULLY_CONFIRMED' : 'EMAIL_NOT_CONFIRMED')}
          </h2>
          <span className={[styles.text].join(' ')}>
            {translate('HI')}
            <span className={styles.capitalizedName}>{(userFirstName && ` ${userFirstName}!`) || '!'}</span>
          </span>
          <p className={[styles.text, styles.shrink, styles.confirmationText].join(' ')}>
            {translate(!serverError ? 'EMAIL_SUCCESSFULLY_CONFIRMED_TEXT' : 'EMAIL_CONFIRMATION_FAILURE_TEXT')}
          </p>
          {!serverError && <MobileApps title="GET_APP_MESSAGE" />}
          <Signature showHaveFun serverError={serverError} />
        </div>
      )}
    </AuthenticationFormsWrapper>
  );
};

export default HobbyTeamWelcome;
