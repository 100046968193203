import { AjaxError } from 'rxjs/ajax';
import {
  FETCH_TAGS_ERROR,
  FETCH_TAGS_REQUEST,
  FETCH_TAGS_SUCCESS,
} from '../constants/actionTypes';
import { Tag } from '../models/Tag';
import {
  FetchTagsErrorAction,
  FetchTagsRequestAction,
  FetchTagsSuccessAction,
} from '../models/actions';

export const fetchTagsRequest = (): FetchTagsRequestAction => ({
  type: FETCH_TAGS_REQUEST,
  payload: {},
});

export const fetchTagsSuccess = (tags: Tag[]): FetchTagsSuccessAction => ({
  type: FETCH_TAGS_SUCCESS,
  payload: { tags },
});

export const fetchTagsError = (error: AjaxError): FetchTagsErrorAction => ({
  type: FETCH_TAGS_ERROR,
  payload: {
    error,
  },
});
