import { Menu, Popup } from 'semantic-ui-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BalloonSelect from '../BalloonSelect';
import styles from './AuthenticatedMenuItem.module.scss';
import ProfileAvatar from '../../../../containers/PlayerProfile/components/ProfileAvatar';
import { Roles } from '../../../../constants/roles';
import { User } from '../../../../models/general/User';

interface AuthenticatedMenuItemProps {
  menuExpanded: boolean;
  signOut: () => void;
  user: User;
  userRole: Roles;
}

export default ({
  menuExpanded, signOut, user, userRole,
}: AuthenticatedMenuItemProps) => {
  const [translate] = useTranslation();

  const renderProfileItem = () => (
    <div className={styles.loggedInUser}>
      <ProfileAvatar user={user} />
      {menuExpanded && (
        <div className={styles.textWrapper}>
          <div className={styles.header}>{`${user.firstName} ${user.lastName}`}</div>
          <span className={styles.subheader}>{translate(Roles[userRole])}</span>
        </div>
      )}
    </div>
  );

  return (
    <Menu.Item>
      <Popup
        content={<BalloonSelect signOut={signOut} />}
        hoverable
        style={{ padding: 0 }}
        wide="very"
        trigger={renderProfileItem()}
      />
    </Menu.Item>
  );
};
