import { MatchData } from '../Calendar';
import { Tor24Team } from './tor24Team';

export interface Tor24TeamsByHash {
  [key: string]: Tor24Team;
}

export interface ExternalMatchesByHash {
  [key: string]: MatchData;
}

export interface ExternalClubsByHash {
  [key: string]: OfficialClubDto;
}
export interface ExternalTeamsByHash {
  [key: string]: ExternalTeam;
}

export interface ExternalSystemsReducer {
  tor24TeamsByHash: Tor24TeamsByHash;
  tor24TeamsById: number[];
  externalMatchesByHash: ExternalMatchesByHash;
  externalMatchesById: string[];
  externalClubByHash: ExternalClubsByHash,
  externalClubById: number[],
  externalTeamsByHash: ExternalTeamsByHash;
  externalTeamsById: number[];
  error: boolean;
  loading: boolean;
}

export enum ExternalReferencedEntityType {
  CLUB = 'CLUB',
  TEAM = 'TEAM',
}

export enum ExternalSystemName {
  YOU_SPORT = 'YOU_SPORT',
  TOR_24 = 'TOR_24',
  DFB_DIVISION = 'DFB_DIVISION',
  DFB_CLUB_ID = 'DFB_CLUB_ID',
  DFB_SQUAD_REF = 'DFB_SQUAD_REF',
  DFB_SQUAD_ID = 'DFB_SQUAD_ID',
  DFB_TEAM_ID = 'DFB_TEAM_ID',
  DFB_TEAM_PERMANENT_ID = 'DFB_TEAM_PERMANENT_ID',
}

export interface ExternalSystemReference {
  entityType: ExternalReferencedEntityType;
  entityId: number;
  externalSystemName: ExternalSystemName;
  externalSystemEntityId: string;
}

export interface UpdateExternalSystemReferenceRequest {
  references: ExternalSystemReference[];
}

export interface OfficialClubDto {
  id: string;
  name: string;
  spielklasseId: number;
  restrictRegistration: boolean;
}

export interface ExternalTeam {
  id: string;
  verband: string;
  mannschaftsart: string;
  mannschaftsartId: number;
  spielklasse: string;
  spielklasseId: number;
  gebiet: string;
  staffelWettkampfId: string;
  staffelId: string
  staffelKennung: string;
  mannschaftsId: string;
  mannschaftsPermanentId: string;
  mannschaftsName: string;
  clubId: string;
  clubName: string;
}
