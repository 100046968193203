import NameFilter from '../../../../components/InvitationFilters/NameFilter';
import RoleFilter from '../../../../components/InvitationFilters/RoleFilter';
import { Column } from '../../../../components/Table/Column';
import TeamFilter from '../../../../components/InvitationFilters/TeamFilter';
import StatusFilter from '../../../../components/InvitationFilters/StatusFilter';

export const personalInvitationCols = [
  {
    width: '7.5%',
    align: 'left',
    key: 'status',
    header: 'STATUS',
    filter: StatusFilter,
  },
  {
    width: '10%',
    align: 'left',
    key: 'receiver',
    header: 'NAME',
    filter: NameFilter,
  },
  {
    width: '22.5%',
    align: 'left',
    key: 'receiverEmail',
    header: 'EMAIL',
  },
  {
    width: '10%',
    align: 'left',
    key: 'teamIds',
    header: 'TEAM',
    filter: TeamFilter,
  },
  {
    width: '10%',
    align: 'left',
    key: 'roles',
    header: 'ROLE',
    filter: RoleFilter,
  },
  {
    width: '22.5%',
    align: 'left',
    key: 'ownerEmail',
    header: 'INVITED_BY',
  },
  {
    width: '10%',
    align: 'left',
    key: 'expiry',
    header: 'EXPIRY',
  },
  {
    width: '5%',
    align: 'left',
    key: 'used',
    header: 'USED',
  },
  {
    width: '2.5%',
    header: '',
    align: 'center',
    key: 'button',
  },
] as Column[];

export const teamInvitationCols = [
  {
    width: '12.5%',
    align: 'left',
    key: 'status',
    header: 'STATUS',
    filter: StatusFilter,
  },
  {
    width: '20%',
    align: 'left',
    key: 'teamIds',
    header: 'TEAM',
    filter: TeamFilter,
  },
  {
    width: '15%',
    align: 'left',
    key: 'roles',
    header: 'ROLE',
    filter: RoleFilter,
  },
  {
    width: '25%',
    align: 'left',
    key: 'ownerEmail',
    header: 'INVITED_BY',
  },
  {
    width: '15%',
    align: 'left',
    key: 'expiry',
    header: 'EXPIRY',
  },
  {
    width: '10%',
    align: 'left',
    key: 'used',
    header: 'USED',
  },
  {
    width: '2.5%',
    header: '',
    align: 'center',
    key: 'button',
  },
] as Column[];
