import { Price } from './Price';
import { OrderStatus } from '../constants/orderStatus';
import { User } from './general/User';
import { ItemEmbellishment } from './Embellishment';
import { Player } from './Player';
import { StaffMember } from './Staff';

export interface Item {
  id?: number;
  externalId: string;
  productCatalogId: string;
  quantity: number;
  maxQuantity: number;
  quantityLocked: boolean;
  sizeLocked: boolean;
  playerId?: number;
  staffId?: number;
  color?: string;
  size?: string;
  embellishments: ItemEmbellishment[];
}

export interface ItemResponse {
  id: number;
  externalId: string;
  embellishments: ItemEmbellishment[];
  maxQuantity: number;
  playerId: number;
  productCatalogId: string;
  quantity: number;
  quantityLocked: boolean;
  size?: string;
  sizeLocked: boolean;
  staffId: number;
  teamId: number;
}

export interface Article {
  externalId: string;
  quantity: number;
  maxQuantity: number;
  itemIds: string[];
  playerIds: number[];
  size?: string[];
  embellishments?: ItemEmbellishment[];
}

export interface Order extends OrderList{
  clubId: number;
  items: Item[];
  createdAt: string;
}

export interface OrderResponse extends OrderList {
  clubId: number;
  lockedNumbers: number[];
  items: ItemResponse[];
  createdAt: string;
}

export interface ItemsByHash {
  [key: string]: Item;
}

export interface OrderList {
  id: number;
  articleCount: number;
  status: OrderStatus;
  team: {
    id: number,
    name: string;
  };
  price: Price;
  acceptedBy: User;
  idlePlayersCount: number;
  idlePlayers: Player[];
  idleStaff: StaffMember[];
  idleStaffCount: number;
  outdated: boolean;
  fileReference: string | null;
}

export interface OrdersByHash {
  [key: string]: OrderList;
}

export interface OrdersListReducer {
  byHash: OrdersByHash;
  byId: number[];
  count: number;
  error: boolean;
  loading: boolean;
  ordersDeleted: boolean;
}

export enum SortFields {
  player = 'name',
  number = 'number',
}

export enum LockedTypes {
  sizeLocked = 'sizeLocked',
  quantityLocked = 'quantityLocked',
}

export interface OrdersReducer extends NumberSettings{
  error: boolean;
  id?: number;
  selectedMembersTeam?: number | null;
  orderStatus: OrderStatus;
  orderPrice: Price | null;
  loading: boolean;
  loadingUpsert: boolean;
  itemsById: string[];
  itemsByHash: ItemsByHash;
  productsByItemId: string[];
  selectedPlayersIds: number[];
  selectedStaffIds: number[];
  selectedProductsIds: string[];
  draft: boolean;
  orderOutdated: boolean;
}

export interface UsedNumbers {
  [key: number]: number;
}

export interface NumberSettings {
  lockedNumbers: number[];
  usedNumbers: UsedNumbers;
  usedPlayerNumbers: UsedNumbers;
}

export interface LockedSettings {
  [key: string]: boolean;
}
