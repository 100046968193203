import React, { FC, useCallback } from 'react';

enum ResponsiveBreakpoints {
  MOBILE = 769, TABLET = 1024,
}

export interface ContextType {
  width: number | null;
  height: number | null;
  isMobile: boolean | null;
  isTablet: boolean | null;
}

const viewportContext = React.createContext<ContextType>({
  width: null, height: null, isMobile: false, isTablet: false,
});

export const ViewportProvider: FC = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const [isMobile, setMobile] = React.useState(false);
  const [isTablet, setTablet] = React.useState(false);

  const handleWindowResize = useCallback(() => {
    if (window.innerWidth < ResponsiveBreakpoints.MOBILE) {
      setMobile(true);
      setTablet(false);
    } else if (window.innerWidth < ResponsiveBreakpoints.TABLET) {
      setMobile(false);
      setTablet(true);
    } else {
      setMobile(false);
      setTablet(false);
    }
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  }, []);

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [handleWindowResize]);

  React.useEffect(() => {
    handleWindowResize();
  }, [handleWindowResize]);

  return (
    <viewportContext.Provider
      value={{
        width, height, isMobile, isTablet,
      }}
    >
      {children}
    </viewportContext.Provider>
  );
};

export const useViewport = () => {
  const {
    width, height, isMobile, isTablet,
  } = React.useContext<ContextType>(
    viewportContext,
  );
  return {
    width, height, isMobile, isTablet,
  };
};
