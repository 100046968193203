import { connect } from 'react-redux';

import PriceDiscountsPopup from './PriceDiscountsPopup';
import { PriceDiscountsPopupParams } from './PriceDiscountsPopupProps';
import { State } from '../../../../models/State';

const mapStateToProps = ({
  catalog: { catalog },
  clubs: { loading },
  teams: { byId: teamsById, byHash: teamsByHash },
}: State, {
  teamId,
}: PriceDiscountsPopupParams) => ({
  catalog,
  teams: (teamId ? [teamId] : teamsById).map(id => teamsByHash[id]).filter(Boolean),
  loading,
});

export default connect(mapStateToProps)(PriceDiscountsPopup);
