import { Column } from '../../../../components/Table/Column';

const columnsMobile: Column[] = [{
  width: '60px',
  header: 'ID',
  align: 'left',
  key: 'id',
  sortable: true,
}, {
  size: 2,
  header: 'STATUS',
  align: 'left',
  key: 'status',
  sortable: true,
}, {
  size: 1,
  header: 'TOTAL',
  align: 'right',
  key: 'price',
  sortable: true,
}, {
  width: '40px',
  noPadding: true,
  header: '',
  align: 'center',
  key: 'redirect',
  sortable: false,
}];

export default columnsMobile;
