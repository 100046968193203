import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';

import { CANCEL_EVENT_REQUEST } from '../../constants/actionTypes';
import { CancelEventRequestAction, NotificationType } from '../../models/actions';
import { State } from '../../models/State';
import { EVENTS_CANCEL } from '../../constants/paths';
import { cancelEventSuccess, cancelEventError } from '../../actions/calendar';
import { addNotification } from '../../actions/notifications';
import { handleError } from '../../actions/errors';

export default (
  action$: ActionsObservable<CancelEventRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(CANCEL_EVENT_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      id,
    },
  }, {
    authentication: { authToken }, calendar: { byHash },
  }]) => ajax({
    url: EVENTS_CANCEL.replace('$eventId', `${id}`),
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    mergeMap(() => [
      addNotification(NotificationType.SUCCESS, `CANCEL_${byHash[id].type}_SUCCESS`),
      cancelEventSuccess(id),
    ]),
    catchError((error: AjaxError) => [
      handleError(error, `CANCEL_${byHash[id].type}_ERROR`),
      cancelEventError(error),
    ]),
  )),
);
