import React, { FormEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Checkbox, CheckboxProps } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import TableContainer from '../../components/Layout/components/TableContainer';
import columns from './columns';
import { ClubTeamsProps } from './ClubTeamsProps';
import ClubTeamsRow from './components/ClubTeamsRow';
import { Team } from '../../models/Team';
import { NEW_CLUB_TEAM_ROUTE } from '../../constants/routes';
import Header from '../../components/Header';
import styles from './ClubTeams.module.scss';
import { TEAMS_LIST_LIMIT } from '../../constants/customizations';
import TeamStatus from '../../constants/teamStatus';

const ClubTeams = ({
  count,
  fetch,
  history,
  loading,
  teams,
  deleteTeam,
}: ClubTeamsProps) => {
  const [translate] = useTranslation();
  const [showInactiveTeams, setShowInactiveTeams] = useState<boolean>(false);
  const [shownTeams, setShownTeams] = useState<Team[]>(
    teams.filter(team => team.status === TeamStatus.ACTIVE),
  );
  useEffect(() => {
    if (showInactiveTeams) {
      setShownTeams(teams);
    } else {
      setShownTeams(teams.filter(team => team.status === TeamStatus.ACTIVE));
    }
  }, [teams, showInactiveTeams]);
  const toggleInactiveTeams = (event: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    setShowInactiveTeams(data.checked || false);
  };
  const renderClubRows = (team: Team) => (
    <ClubTeamsRow team={team} key={team.id} deleteTeam={deleteTeam} />
  );

  const renderNewElementLogic = () => (
    <Button
      as={Link}
      primary
      content={translate('ADD_NEW_TEAM')}
      key="button"
      to={NEW_CLUB_TEAM_ROUTE}
    />
  );

  return (
    <div className={styles.wrapper}>
      <Header
        title={translate('TEAMS')}
        renderButton={renderNewElementLogic}
        border
      />

      <div className={styles.contentWrapper}>
        <TableContainer
          fetch={fetch}
          loading={loading}
          count={count}
          history={history}
          columns={columns}
          limit={TEAMS_LIST_LIMIT}
          renderItems={shownTeams.map(renderClubRows)}
          disableSearch
          invertedHeader
          showHeaderOnEmpty
          extraFilters={(
            <Checkbox label={translate('SHOW_INACTIVE_TEAMS')} onChange={toggleInactiveTeams} />
          )}
        />
      </div>
    </div>
  );
};

export default ClubTeams;
