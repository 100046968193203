import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';

import { EVENT_CSV_PREVIEW } from '../../constants/paths';
import { PreviewCSVEventRequestAction } from '../../models/actions';
import { Response } from '../../models/Response';
import { State } from '../../models/State';
import { PREVIEW_CSV_EVENT_REQUEST, PREVIEW_CSV_EVENT_ERROR } from '../../constants/actionTypes';
import { handleError } from '../../actions/errors';
import { previewCSVEventError, previewCSVEventSuccess } from '../../actions/calendar';
import { Match, Training } from '../../models/Calendar';

export default (
  action$: ActionsObservable<PreviewCSVEventRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(PREVIEW_CSV_EVENT_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{ payload: { teamId, file } }, { authentication: { authToken } }]) => {
    const form = new FormData();
    form.append('file', file);

    return ajax.post(
      `${EVENT_CSV_PREVIEW.replace('$teamId', String(teamId))}?limit=1`,
      form,
      { authToken },
    ).pipe(
      mergeMap(({ response: events }: Response<Training[] | Match[]>) => [
        previewCSVEventSuccess(events[0]),
      ]),
      catchError(error => [
        handleError(error, PREVIEW_CSV_EVENT_ERROR),
        previewCSVEventError(error),
      ]),
    );
  }),
);
