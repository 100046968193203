import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Dropdown, Icon, Pagination } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Table from '../../../../components/Table';
import { teamInvitationCols, personalInvitationCols } from './columns';
import Row from '../../../../components/Table/components/Row';
import { FetchInvitationsRequestAction } from '../../../../models/actions';
import Text from '../../../../components/Text';
import { InvitationByHash, InvitationResponse } from '../../../../models/Invitations';
import styles from '../../../../components/Layout/components/TableContainer/TableContainer.module.scss';
import usePagination from '../../../../hooks/usePagination';
import { history } from '../../../../configureStore';
import { INVITATIONS_LIST_LIMIT, TABLE_FILTER_URL_PARAM, TABLE_PAGE_URL_PARAM } from '../../../../constants/customizations';
import params from '../../../../utils/params';
import { ListFilters } from '../../../../models/ListFilters';
import { formatDate } from '../../../../utils/dateUtils';
import useFilters from '../../../../hooks/useFilters';
import Confirm from '../../../../components/Confirm';
import InvitationUrlPopup from './InvitationUrlPopup';

export interface InvitationsListProps extends RouteComponentProps {
  fetchInvitations: (
    isPersonalInvitation: boolean,
    filters: ListFilters
  ) => FetchInvitationsRequestAction;
  resendInvitation: (token: string) => void;
  invalidateInvitation: (token: string) => void;
  isPersonalInvitation: boolean;
  loading: boolean;
  count: number;
  limit?: number;
  byId: number[];
  byHash: InvitationByHash;
}

const InvitationsList: FC<InvitationsListProps> = ({
  fetchInvitations,
  resendInvitation,
  invalidateInvitation,
  isPersonalInvitation,
  loading,
  count,
  limit = INVITATIONS_LIST_LIMIT,
  byId,
  byHash,
}) => {
  const [invitationPopupToken, setInvitationPopupToken] = useState<string>('');
  const { getParams } = params(history);
  const [translate] = useTranslation();
  const {
    [TABLE_PAGE_URL_PARAM]: page,
    [TABLE_FILTER_URL_PARAM]: filter,
  } = getParams();
  const [onPageChange, totalPages, showPagination] = usePagination(history, count, loading, limit);
  const { onFilterChange, filter: savedFilters, setFilter } = useFilters(history);
  useEffect(() => {
    fetchInvitations(isPersonalInvitation, { page, filter });
  }, [fetchInvitations, isPersonalInvitation, page, filter]);

  useEffect(() => {
    if (filter) {
      setFilter(JSON.parse(filter as string));
    }
  }, [filter, setFilter]);
  const getReceiverName = (firstName: string, name: string): string => {
    if (!firstName && !name) {
      return '';
    }
    if (!firstName) {
      return name;
    }
    if (!name) {
      return firstName;
    }
    return `${firstName} ${name}`;
  };
  const isInactive = ({ allowedUsages, invitationAcceptances, expiryDate }: InvitationResponse)
  : boolean => allowedUsages <= invitationAcceptances
              || new Date(expiryDate).getTime() <= Date.now();

  const translatedRole = (role: string) => translate(`${role}_ROLE`);
  const handleResendInvitation = (token: string) => () => {
    resendInvitation(token);
  };
  const handleInvalidateInvitation = (token: string) => () => {
    invalidateInvitation(token);
  };
  const renderItems = () => byId.map((id: number) => (
    <Row key={id} inactive={isInactive(byHash[id])}>
      <Text>{ `${isInactive(byHash[id]) ? translate('INACTIVE') : translate('ACTIVE')}` }</Text>
      { isPersonalInvitation
        ? (
          <Text>{ `${getReceiverName(byHash[id].receiverFirstName, byHash[id].receiverName)}` }</Text>
        )
        : null
      }
      { isPersonalInvitation
        ? (
          <Text>{ `${byHash[id].receiverEmail || ''}` }</Text>
        )
        : null
      }
      <Text>{ `${byHash[id].team ? byHash[id].team.name : ''}` }</Text>
      <Text>{ `${byHash[id].receiverRole ? translatedRole(byHash[id].receiverRole) : ''}` }</Text>
      <Text>{ `${(byHash[id].owner && byHash[id].owner.email) ? byHash[id].owner.email : ''}` }</Text>
      <Text>{ `${byHash[id].expiryDate ? formatDate(byHash[id].expiryDate) : ''}` }</Text>
      <Text>{ `${byHash[id].invitationAcceptances || 0}/${byHash[id].allowedUsages || 0}` }</Text>
      <div className={styles['options-wrapper']} onClick={(e: any) => { e.stopPropagation(); }}>
        <Dropdown
          direction="left"
          icon={<Icon name="ellipsis vertical" color="blue" size="large" />}
        >
          <Dropdown.Menu>
            { byHash[id].token && byHash[id].receiverEmail && !byHash[id].invitationAcceptances && (
              <Confirm
                as={Dropdown.Item}
                headerText={translate('RESEND_INVITATION')}
                confirmText={translate('CONFIRM_RESEND_INVITATION')}
                onConfirm={handleResendInvitation(byHash[id].token)}
                triggerText={translate('RESEND_INVITATION')}
                key="resend"
              />
            )}
            <Confirm
              as={Dropdown.Item}
              headerText={translate('INVALIDATE_INVITATION')}
              confirmText={translate('CONFIRM_INVALIDATE_INVITATION')}
              onConfirm={handleInvalidateInvitation(byHash[id].token)}
              triggerText={translate('INVALIDATE_INVITATION')}
              key="invalidate"
            />
            {
              !isInactive(byHash[id]) && (
                <Dropdown.Item
                  text={translate('INVITATION_LINK')}
                  onClick={() => {
                    setInvitationPopupToken(byHash[id].jwtToken);
                  }}
                />
              )
            }
          </Dropdown.Menu>
        </Dropdown>
        <InvitationUrlPopup
          isOpen={!!invitationPopupToken}
          inviteToken={invitationPopupToken}
          closePopup={() => {
            setInvitationPopupToken('');
          }}
        />
      </div>
    </Row>
  ));
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Table
          columns={isPersonalInvitation ? personalInvitationCols : teamInvitationCols}
          showHeaderOnEmpty
          loading={loading}
          onFilterChange={onFilterChange}
          filters={savedFilters}
          invertedHeader
        >
          {renderItems()}
        </Table>
      </div>
      <div className={styles.pagination}>
        {showPagination && (
          <Pagination
            defaultActivePage={(page && +page) as number || 1}
            totalPages={(totalPages && +totalPages) as number}
            onPageChange={onPageChange as () => void}
          />
        )}
      </div>
    </div>
  );
};

export default InvitationsList;
