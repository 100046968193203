import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { push } from 'connected-react-router';
import { handleError } from '../../actions/errors';
import { addNotification } from '../../actions/notifications';
import { DELETE_DATA_REQUEST, DELETE_DATA_SUCCESS, DELETE_DATA_ERROR } from '../../constants/actionTypes';
import { DELETE_DATA } from '../../constants/paths';
import { NotificationType, DeleteDataRequestAction } from '../../models/actions';
import { State } from '../../models/State';
import { deleteDataSuccess, deleteDataError } from '../../actions/user';
import { DELETE_DATA_CONFIRMATION_PAGE } from '../../constants/routes';

export default (
  action$: ActionsObservable<DeleteDataRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(DELETE_DATA_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      token,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: DELETE_DATA,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify({
      token,
    }),
  }).pipe(
    mergeMap(() => [
      addNotification(NotificationType.SUCCESS, DELETE_DATA_SUCCESS),
      deleteDataSuccess(),
      push(DELETE_DATA_CONFIRMATION_PAGE),
    ]),
    catchError((error: AjaxError) => [
      handleError(error, DELETE_DATA_ERROR),
      deleteDataError(error),
    ]),
  )),
);
