import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { push } from 'connected-react-router';
import { handleError } from '../../actions/errors';
import { addNotification } from '../../actions/notifications';
import { acceptInvitationSuccess, acceptInvitationError } from '../../actions/application';
import { ACCEPT_INVITATION_ERROR, ACCEPT_INVITATION_REQUEST, ACCEPT_INVITATION_SUCCESS } from '../../constants/actionTypes';
import { ACCEPT_INVITATION } from '../../constants/paths';
import { AcceptInvitationRequestAction, NotificationType } from '../../models/actions';
import { State } from '../../models/State';
import { SIGNUP_WELCOME_PAGE_ROUTE } from '../../constants/routes';

const acceptInvitation = (
  action$: ActionsObservable<AcceptInvitationRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(ACCEPT_INVITATION_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { token } },
    {
      authentication: { authToken },
    },
  ]) => ajax({
    url: ACCEPT_INVITATION.replace('$inviteToken', `${token}`),
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    mergeMap(() => [
      addNotification(NotificationType.SUCCESS, ACCEPT_INVITATION_SUCCESS),
      acceptInvitationSuccess(token),
      push(SIGNUP_WELCOME_PAGE_ROUTE),
    ]),
    catchError(error => [
      handleError(error, ACCEPT_INVITATION_ERROR),
      acceptInvitationError(error),
    ]),
  )),
);

export default acceptInvitation;
