import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Image } from 'semantic-ui-react';

import Confirm from '../../../../components/Confirm';
import styles from './OneTimeEventHeader.module.scss';
import { OneTimeEventHeaderProps } from './OneTimeEventHeaderProps';
import { DEFAULT_LOGO_URL } from '../../../../constants/customizations';
import DeleteEventModal from '../DeleteEventModal';

const OneTimeEventHeader: FC<OneTimeEventHeaderProps> = ({
  onClose, onSubmit, isFormValid, clubLogoUrl, header,
  event, cancelEvent, isSubmitted,
}) => {
  const [translate] = useTranslation();
  const [deletionModalOpened, setDeletionModalOpened] = useState(false);

  const handleCancel = (id: number) => () => {
    cancelEvent(id);
    onClose();
  };

  const closeDeleteModal = () => {
    setDeletionModalOpened(false);
  };

  const openDeleteModal = () => {
    setDeletionModalOpened(true);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.leftWrapper}>
        <Image
          src={clubLogoUrl || DEFAULT_LOGO_URL}
          className={styles.image}
        />
        <h2 className={styles.header}>
          {header}
        </h2>
      </div>
      <div className={styles.rightWrapper}>
        {event && event.id ? [
          <Button key="delete" basic primary onClick={openDeleteModal} content={translate('DELETE')} />,
          <Confirm
            basic
            headerText={translate('CONFIRM_CANCEL_ONE_TIME_EVENT_TEXT')}
            confirmText={translate('CONFIRM_CANCEL_ONE_TIME_EVENT')}
            onConfirm={handleCancel(event.id)}
            primary
            triggerText={translate('CANCEL_EVENT')}
            key="cancel"
          />,
        ] : (
          <Button basic primary onClick={onClose} content={translate('CANCEL')} />
        )}

        <Button primary disabled={!event && isSubmitted && !isFormValid} onClick={onSubmit}>
          {translate(event ? 'SAVE_EVENT' : 'CREATE_EVENT')}
        </Button>
      </div>
      {event && deletionModalOpened && <DeleteEventModal onClose={closeDeleteModal} />}
    </div>
  );
};

export default OneTimeEventHeader;
