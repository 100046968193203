import React from 'react';
import { Route, Redirect, RouteProps as RoutePropsLib } from 'react-router-dom';
import queryString from 'query-string';
import { SIGN_IN_ROUTE, SIGN_UP_ROUTE } from '../../../../constants/routes';
import { UrlParams } from '../../../../constants/urlParams';
import { Roles } from '../../../../constants/roles';
import { NtsUserMembership } from '../../../../models/Response';
import { REQUIRED_ROLES } from '../../../../constants/customizations';
import { User } from '../../../../models/general/User';

export interface ProtectedRouteProps extends RoutePropsLib {
  isAuthenticated?: Boolean;
  user?: User | null;
  memberships?: NtsUserMembership;
  clubId?: number | null;
  teamId?: number | null;
  userRole?: Roles | null;
  infoFetched?: boolean;
}

const hasRequiredRole = (
  requiredRoles: Roles[], memberships: NtsUserMembership = [], clubId?: number | null,
) => clubId && memberships[clubId]
  && memberships[clubId].some((role: Roles) => requiredRoles.includes(role));

const ProtectedRoute = ({
  isAuthenticated, user, clubId, infoFetched, userRole, teamId, memberships, location, ...props
}: ProtectedRouteProps) => {
  const queryParams = location ? queryString.parse(location.search) : {};
  const assureTeamLeadHasTeams = userRole
    && (!infoFetched || (userRole === Roles.CLUB_ADMIN || (user!.teams && user!.teams.length)));
  const inviteToken = queryParams[UrlParams.INVITATION_TOKEN] as string;
  const redirectTo = inviteToken ? `${SIGN_UP_ROUTE}${location ? location.search : ''}` : SIGN_IN_ROUTE;

  return isAuthenticated
    && assureTeamLeadHasTeams
    && (
      Object.keys(memberships!)
      && clubId
      && hasRequiredRole(REQUIRED_ROLES, memberships, clubId)
    )
    ? <Route {...props} />
    : <Redirect to={redirectTo} />;
};

export default ProtectedRoute;
