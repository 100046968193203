import { AjaxError } from 'rxjs/ajax';
import {
  FETCH_FILE_ERROR,
  FETCH_FILE_REQUEST,
  FETCH_FILE_SUCCESS,
  REMOVE_FILE,
} from '../constants/actionTypes';
import {
  FetchFileErrorAction,
  FetchFileRequestAction,
  FetchFileSuccessAction,
  RemoveFileAction,
} from '../models/actions';

export const fetchFileRequest = (
  id: string, extension?: string, download: boolean = false,
): FetchFileRequestAction => ({
  type: FETCH_FILE_REQUEST,
  payload: {
    id,
    extension,
    download,
  },
});

export const fetchFileSuccess = (id: string, file: string | Blob): FetchFileSuccessAction => ({
  type: FETCH_FILE_SUCCESS,
  payload: {
    id,
    file,
  },
});

export const fetchFileError = (error: AjaxError): FetchFileErrorAction => ({
  type: FETCH_FILE_ERROR,
  payload: {
    error,
  },
});

export const removeFile = (fileId: string): RemoveFileAction => ({
  type: REMOVE_FILE,
  payload: {
    fileId,
  },
});
