import React, { FC, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button, Checkbox, Icon, Label, Popup,
} from 'semantic-ui-react';

import Row from '../../../../../../components/Table/components/Row';
import Confirm from '../../../../../../components/Confirm';
import Text from '../../../../../../components/Text';
import XlsModal from '../XlsModal';
import styles from './OrdersRow.module.scss';
import { formatPrice } from '../../../../../../utils/PricingUtil';
import { ORDER_ROUTE, TEAM_ROUTE, CLUB_ROUTE } from '../../../../../../constants/routes';
import { OrderStatus } from '../../../../../../constants/orderStatus';
import { OrdersRowProps } from './OrdersRowProps';
import { Player } from '../../../../../../models/Player';
import { getFullNameWithNullGuards } from '../../../../../../utils/getFullName';
import { StaffMember } from '../../../../../../models/Staff';
import { history } from '../../../../../../configureStore';

const OrdersRow: FC<OrdersRowProps> = ({
  order: {
    id,
    team,
    status,
    price,
    idlePlayersCount,
    idleStaffCount,
    idleStaff,
    fileReference,
    acceptedBy,
    outdated,
    idlePlayers,
  },
  isSelected,
  onSelect,
  setStatus,
  deleteOrders,
  orderContext,
  fetchFile,
}) => {
  const [translate] = useTranslation();
  const orderDetailsAllowed = useMemo(() => (
    [OrderStatus.IN_PROGRESS, OrderStatus.SENT_TO_TEAM].includes(status)), [status]);
  const [openedPreview, setOpenedPreview] = useState(false);
  const handleCheckboxChange = (event: React.FormEvent<HTMLInputElement>) => {
    event.stopPropagation();
    onSelect(id);
  };

  const link = ORDER_ROUTE.replace(':teamId', String(team.id)).replace(':orderId', String(id));

  const handleStatusChange = (
    newStatus: OrderStatus,
  ) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setStatus(id, newStatus);
  };

  const handleDownload = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    if (fileReference) {
      fetchFile(fileReference, 'xls', true);
    }
  };

  const handleDeleteOrder = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.stopPropagation();
    deleteOrders([id]);
  };

  const defaultButtonProps = ({
    circular: true,
    primary: true,
  });

  const renderTeamActionButtons = () => status === OrderStatus.SENT_TO_TEAM
    && (idlePlayersCount + idleStaffCount === 0)
    && [
      <Confirm
        {...defaultButtonProps}
        className={styles.sendOrder}
        icon="check circle"
        triggerText={translate('SEND_TO_CLUB_ADMIN')}
        primary
        key="club-admin"
        confirmText={translate('SEND_TO_CLUB_ADMIN_CONFIRMATION')}
        // @ts-ignore
        onConfirm={handleStatusChange(OrderStatus.SENT_TO_CLUB_ADMIN)}
      />,
      <Confirm
        {...defaultButtonProps}
        className={styles.sendOrder}
        triggerText={translate('SEND_TO_SALES_REP')}
        icon="check circle"
        primary
        confirmText={translate('SEND_TO_SALES_REP_CONFIRMATION')}
        // @ts-ignore
        onConfirm={handleStatusChange(OrderStatus.SENT_TO_SALES_REP)}
        key="sales-rep"
      />,
    ];

  const renderAwaitingUsers = () => [
    idlePlayers
      .map((player: Player) => (
        <li key={`player-${player.id}`}>
          { getFullNameWithNullGuards(player.firstName, player.lastName) }
        </li>
      )),
    idleStaff
      .map((staff: StaffMember) => (
        <li key={`staff-${staff.id}`}>
          { getFullNameWithNullGuards(staff.firstName, staff.lastName) }
        </li>
      )),
  ];

  const renderClubActionButtons = () => status === OrderStatus.SENT_TO_CLUB_ADMIN && [
    <Button
      {...defaultButtonProps}
      className={styles.reject}
      content={translate('REJECT')}
      icon="exclamation circle"
      key="reject"
      onClick={handleStatusChange(OrderStatus.IN_PROGRESS)}
    />,
    <Button
      {...defaultButtonProps}
      className={styles.approve}
      content={translate('APPROVE')}
      icon="check circle"
      key="approve"
      onClick={handleStatusChange(OrderStatus.SENT_TO_SALES_REP)}
    />,
  ];

  const handleOpenPreview = (state: boolean) => (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    event.preventDefault();
    setOpenedPreview(state);
  };

  const handleRowClick = () => {
    if (outdated) {
      setOpenedPreview(true);
    } else if (orderDetailsAllowed) {
      history.push(link);
    }
  };

  const deleteButton = (
    <Popup
      style={{ zIndex: 100 }}
      on="hover"
      hoverable
      content={translate('DELETE_OUTDATED_ORDER')}
      trigger={(
        <div className={styles.deleteButtonWrapper}>
          <Confirm
            {...defaultButtonProps}
            className={styles.deleteButton}
            confirmText={translate('DELETE_ORDER_CONFIRMATION_TEXT')}
            as={Button}
            onConfirm={handleDeleteOrder}
            triggerText={translate('DELETE_ORDER')}
            key="delete-order-confirmation"
          />
        </div>
      )}
    />
  );

  const renderActionButtons = orderContext === TEAM_ROUTE
    ? renderTeamActionButtons
    : renderClubActionButtons;

  const renderMissingDataChip = () => status === OrderStatus.SENT_TO_TEAM
    && (idlePlayersCount + idleStaffCount > 0)
    && (
      <Popup
        className={styles.playerPopup}
        on="hover"
        hoverable
        key="missing-data-chip"
        trigger={(
          <Label circular color="blue" className={styles['label-count']}>{idlePlayersCount + idleStaffCount}</Label>
        )}
      >
        <div>
          <Text bold>
            {translate('ORDER_PLAYER_STAFF_MISSING')}
          </Text>
          <ul className={styles.playerList}>
            {renderAwaitingUsers()}
          </ul>
        </div>
      </Popup>
    );

  return (
    <Row key={id} classNames={styles.wrapper} itemOnClick={handleRowClick}>
      <div className={styles['order-id']}>
        { orderContext === TEAM_ROUTE && (
          <Checkbox checked={isSelected} onChange={handleCheckboxChange} />
        )}
        <Text bold>{`#${id}`}</Text>
      </div>
      <Text>{team.name}</Text>
      <div className={styles['order-status']}>
        {outdated ? (
          <Text>{translate('ORDER_INVALID')}</Text>
        ) : [
          <Text key="order-status">{translate(status)}</Text>,
          renderMissingDataChip(),
        ]}
      </div>
      { orderContext === CLUB_ROUTE ? (
        <Text>{acceptedBy ? `${acceptedBy.firstName} ${acceptedBy.lastName}` : ''}</Text>
      ) : null }
      <Text>{formatPrice(price.amount, price.currency)}</Text>
      <div style={{ width: '100%' }}>
        <div className={styles.actionsWrapper}>
          {outdated ? deleteButton : renderActionButtons()}
          {fileReference
            && [OrderStatus.SENT_TO_CLUB_ADMIN, OrderStatus.SENT_TO_SALES_REP].includes(status)
            && [
              <Button
                icon="eye"
                key="revisit_order"
                inverted
                circular
                onClick={handleOpenPreview(true)}
                primary
                content={translate(outdated ? 'REVISIT_ORDER' : 'PREVIEW')}
              />,
              openedPreview && (
                // @ts-ignore
                <XlsModal
                  openPreview={openedPreview}
                  file={{ id: fileReference, originalFilename: `order_#${id}.xls`, extension: 'xls' }}
                  key="xlsModal"
                  onClose={handleOpenPreview(false)}
                />
              ),
              !outdated && (
                <Button
                  {...defaultButtonProps}
                  content={translate('DOWNLOAD')}
                  icon="download"
                  key="download"
                  onClick={handleDownload}
                />
              ),
            ].filter(Boolean)}
        </div>
      </div>
      {!orderDetailsAllowed || outdated
        ? <div /> : (
          <Button
            as={Link}
            basic
            className="simple"
            color="blue"
            icon={() => <Icon name="angle right" size="large" />}
            primary
            to={link}
          />
        )}
    </Row>
  );
};

export default OrdersRow;
