import i18next from 'i18next';
import { FormSchema } from '../../../../models/Form';
import { isRequired, isValidEmail } from '../../../../utils/formValidators';
import { Roles } from '../../../../constants/roles';

const getOptions = (values: string[], translate: i18next.TFunction) => values.map(value => ({
  key: value as string,
  text: translate(`${value}_ROLE`) as string,
  value: value as string,
}));

export const createSchema: FormSchema = ({
  firstName: {
    type: 'text',
    label: 'FIRSTNAME',
    required: true,
  },
  lastName: {
    type: 'text',
    label: 'LASTNAME',
    required: true,
  },
  email: {
    type: 'text',
    label: 'EMAIL',
    required: true,
  },
  role: {
    type: 'select',
    label: 'ROLE',
    required: true,
    options: (translate: i18next.TFunction) => getOptions(
      [Roles.CLUB_ADMIN, Roles.PLAYER, Roles.TEAM_LEAD],
      translate,
    ),
  },
  teamId: {
    type: 'select',
    label: 'TEAM',
    disabled: true,
  },
});

export const validationSchema = {
  firstName: [isRequired],
  lastName: [isRequired],
  role: [isRequired],
  email: [isRequired, isValidEmail],
};
