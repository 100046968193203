import { AjaxError } from 'rxjs/ajax';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import {
  catchError, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import {
  EXTERNAL_TEAMS_ERROR,
  EXTERNAL_TEAMS_REQUEST,
} from '../../constants/actionTypes';
import { State } from '../../models/State';
import { externalTeamsError, externalTeamsSuccess } from '../../actions/externalSystems/externalTeams';
import { ExternalTeamsRequestAction } from '../../models/actions';
import { ExternalTeam } from '../../models/externalSystemIntegration';
import { handleError } from '../../actions/errors';
import { SEARCH_EXTERNAL_TEAMS_PATH } from '../../constants/paths';

export default (
  action$: ActionsObservable<ExternalTeamsRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(EXTERNAL_TEAMS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      externalClubId,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: SEARCH_EXTERNAL_TEAMS_PATH.replace('$clubId', externalClubId),
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    mergeMap((res: { response: { teams: ExternalTeam[] } }) => [
      externalTeamsSuccess(res.response.teams),
    ]),
    catchError((error: AjaxError) => [
      handleError(error, EXTERNAL_TEAMS_ERROR),
      externalTeamsError(error),
    ]),
  )),
);
