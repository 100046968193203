import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import { saveAs } from 'file-saver';
import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import { FETCH_FILE_PATH } from '../../constants/paths';
import { FETCH_FILE_ERROR, FETCH_FILE_REQUEST } from '../../constants/actionTypes';
import { FetchFileRequestAction } from '../../models/actions';
import { Response } from '../../models/Response';
import { State } from '../../models/State';
import { fetchFileError, fetchFileSuccess } from '../../actions/files';
import { convertFromBlobToUrl$ } from '../../utils/convertFromBlobToUrl';
import { EXCEL_EXTENSIONS, IMAGES_EXTENSIONS } from '../../constants/customizations';
import { handleError } from '../../actions/errors';

const fetchFile = (
  action$: ActionsObservable<FetchFileRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_FILE_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { id, extension, download } },
    {
      authentication: { authToken },
    },
  ]) => ajax({
    url: FETCH_FILE_PATH
      .replace('$contentId', String(id)),
    method: 'GET',
    headers: {
      authToken,
    },
    responseType: 'blob',
  }).pipe(
    map(({ response: file }: Response<Blob>) => file),
    mergeMap((file: Blob) => convertFromBlobToUrl$([file]).pipe(
      mergeMap(fileUrl => {
        if (download && extension && IMAGES_EXTENSIONS.indexOf(extension.toLowerCase()) === -1) {
          saveAs(fileUrl, `${id}.${extension}`);
          return [];
        }

        if (extension && EXCEL_EXTENSIONS.indexOf(extension) !== -1) {
          return [fetchFileSuccess(id, file)];
        }

        return [fetchFileSuccess(id, fileUrl)];
      }),
    )),
    catchError(error => [
      handleError(error, FETCH_FILE_ERROR),
      fetchFileError(error),
    ]),
  )),
);

export default fetchFile;
