import { bindActionCreators, Dispatch } from 'redux';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import App from './App';
import State from '../../models';
import { setMenuExpanded } from '../../actions/application';
import { fetchTeamIdRequest } from '../../actions/teams';

const mapStateToProps = ({
  application: {
    clubId, teamId, userRole, menuExpanded,
  },
  authentication: {
    authToken,
  },
  clubs: { logoUrl },
  user: { user },
}: State) => ({
  clubLogoUrl: logoUrl,
  userRole,
  menuExpanded,
  isAuthenticated: !!authToken,
  teamId,
  clubId,
  userEmail: user && user.email ? user.email : '',
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  setMenuExpanded,
  fetchTeamId: fetchTeamIdRequest,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
