import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';

import { DELETE_MEMBER_REQUEST, DELETE_MEMBER_SUCCESS, DELETE_MEMBER_ERROR } from '../../constants/actionTypes';
import { NotificationType, DeleteMemberRequestAction } from '../../models/actions';
import { State } from '../../models/State';
import { DELETE_MEMBER_PATH } from '../../constants/paths';
import { addNotification } from '../../actions/notifications';
import { handleError } from '../../actions/errors';
import { deleteMemberSuccess, deleteMemberError } from '../../actions/members';

export default (
  action$: ActionsObservable<DeleteMemberRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(DELETE_MEMBER_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      clubId, memberId,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: DELETE_MEMBER_PATH.replace('$clubId', `${clubId}`).replace('$memberId', `${memberId}`),
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    mergeMap(() => [
      addNotification(NotificationType.SUCCESS, DELETE_MEMBER_SUCCESS),
      deleteMemberSuccess(memberId, clubId),
    ]),
    catchError((error: AjaxError) => [
      handleError(error, DELETE_MEMBER_ERROR),
      deleteMemberError(error),
    ]),
  )),
);
