import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button, Dimmer, Image, Label,
} from 'semantic-ui-react';
import queryString from 'query-string';

import PriceDiscountsPopup from '../../../ProductDetails/components/PriceDiscountsPopup';
import styles from './ProductCard.module.scss';
import useImage from '../../../../hooks/useImage';
import { CATALOG_PRODUCT_ROUTE } from '../../../../constants/routes';
import { IMAGES_EXTENSIONS } from '../../../../constants/customizations';
import { ProductCardProps } from './ProductCardProps';
import { UrlParams } from '../../../../constants/urlParams';
import { Embellishment, ProductEmbellishment } from '../../../../models/Embellishment';

const ProductCard: FC<ProductCardProps> = ({
  clubId,
  teamId,
  fetchFile,
  filesByHash,
  product: {
    id, externalId, name, originalPrice, originalImages, files,
    discountPercent: productDiscountPercent, embellishments: productEmbellishments,
  },
  simple,
  embellishmentsByHash,
}) => {
  const [translate] = useTranslation();
  const status = true;
  const hasPdfFiles = files.some(
    ({ extension }) => (IMAGES_EXTENSIONS.indexOf(extension.toLowerCase()) === -1),
  );
  const [img] = useImage(files, originalImages, filesByHash, fetchFile);
  const activeTeamQueryParam = queryString.stringify({ [UrlParams.ACTIVE_TEAM]: teamId });
  const mapEmbellishment = ({ embellishment }: ProductEmbellishment) => (
    embellishmentsByHash[embellishment]
  );
  const mandatoryEmbellishments: Embellishment[] = productEmbellishments
    .filter(({ mandatory }) => mandatory).map(mapEmbellishment).filter(Boolean);

  return (
    <div className={styles.wrapper}>
      <div className={styles.imageContainer}>
        {!!img && (
          <Image src={img} className={styles.image} />
        )}
      </div>

      <div className={styles.descriptionContainer}>
        <p className={styles.id}>
          {`${translate('ID')}: ${externalId}`}
        </p>
        {!simple && (
          <p className={status ? styles.complete : styles.incomplete}>
            {translate(status ? 'COMPLETE' : 'INCOMPLETE')}
          </p>
        )}
      </div>

      <p className={styles.text}>
        {name}
      </p>

      <div className={styles.bottomContainer}>
        <div>
          {!simple && hasPdfFiles && (<Label color="violet" content="PDF" />)}
        </div>

        <div className={styles.price}>
          <PriceDiscountsPopup
            clubId={clubId}
            teamId={teamId}
            productEmbellishments={productEmbellishments.filter(({ mandatory }) => mandatory)}
            embellishments={mandatoryEmbellishments}
            price={originalPrice}
            productDiscountPercent={productDiscountPercent}
            popupPosition="left center"
            underline={false}
          />

          {!simple && productDiscountPercent !== null && [
            <span key="star">*</span>,
            <span key="footnote" className={styles.footnote}>
              {`*${translate('SPECIAL_PRODUCT_DISCOUNT')}`}
            </span>,
          ]}
        </div>
      </div>

      {!simple && (
        <Dimmer className={styles.dimmer}>
          <Button
            as={Link}
            content={translate('VIEW')}
            to={`${CATALOG_PRODUCT_ROUTE.replace(':clubId', String(clubId)).replace(':id', id)}${teamId ? `?${activeTeamQueryParam}` : ''}`}
          />
        </Dimmer>
      )}
    </div>
  );
};

export default ProductCard;
