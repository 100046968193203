import React, { FC } from 'react';
import queryString from 'query-string';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Header, Menu } from 'semantic-ui-react';

import styles from './Profile.module.scss';
import { ProfileProps } from './ProfileProps';
import { getContactInfoSection } from '../../methods/getContactInfoSection';
import { getEmergencyContactSection } from '../../methods/getEmergencyContactSection';
import { getGeneralStatsSection } from '../../methods/getGeneralStatsSection';
import { getSizeStatsSection } from '../../methods/getSizeStatsSection';
import ProfileAvatar from '../ProfileAvatar';
import ProfileSection from '../ProfileSection';

const menuItems = ['player_profile_in_team'];

const Profile: FC<ProfileProps> = ({
  player,
}) => {
  const [translate] = useTranslation();
  const { search, pathname } = useLocation();
  const { tab } = queryString.parse(search);
  const activeTab = tab || menuItems[0];
  const {
    firstName, lastName, nickname,
  } = player;

  return (
    <div className={styles.wrapper}>
      <div className={styles.profileHeader}>
        <ProfileAvatar user={player} size="large" />
        <Header as="h2">
          {[firstName, lastName].filter(Boolean).join(' ')}
          <Header.Subheader>{nickname}</Header.Subheader>
        </Header>
      </div>

      <div className={styles.contentWrapper}>
        <Menu className="filter" pointing secondary>
          {menuItems.map((title) => (
            <Menu.Item
              active={title === activeTab}
              as={Link}
              key={title}
              content={translate(title.toUpperCase())}
              to={`${pathname}?tab=${title}`}
            />
          ))}
        </Menu>
      </div>

      <ProfileSection
        title={translate('GENERAL_INFO.TITLE')}
        data={getGeneralStatsSection(player, translate)}
      />

      <ProfileSection
        title={translate('SIZES.TITLE')}
        data={getSizeStatsSection(player)}
      />

      <ProfileSection
        title={translate('CONTACT_INFO.TITLE')}
        data={getContactInfoSection(player)}
      />

      <ProfileSection
        title={translate('EMERGENCY_CONTACT.TITLE')}
        data={getEmergencyContactSection(player)}
      />
    </div>
  );
};

export default Profile;
