import { RouteComponentProps } from 'react-router-dom';
import {
  Match, Pitch, MatchType, MatchPlace, Training,
} from '../../../../models/Calendar';
import { CreateEventRequestAction, UpdateEventRequestAction, UpdateResultRequestAction } from '../../../../models/actions';
import { TeamsByHash } from '../../../../models/Team';

export const DESCRIPTION = 'description';
export const MEETING_POINT = 'meetingPoint';
export const ADDRESS = 'address';
export const MEETING_TIME = 'meetingTime';
export const START_TIME = 'startTime';
export const END_TIME = 'endTime';
export const DAY = 'day';
export const DATE = 'date';
export const OPPONENT_TEAM = 'opponentTeam';
export const COMPETITION = 'competition';
export const PITCH = 'pitch';
export const MATCH_PLACE = 'matchPlace';
export const HOME_TEAM_RESULT = 'homeTeamResult';
export const AWAY_TEAM_RESULT = 'awayTeamResult';
export const REPORT = 'report';
export const RESET_ATTENDANCE = 'resetAttendance';
export const INFORM_TEAM = 'informTeam';

export interface MatchEventModalProps extends RouteComponentProps {
  teamId: number;
  activeSlot?: Date;
  event: Match | Training | null;
  teamsByHash: TeamsByHash;
  firstName: string | null;
  onClose: () => void;
  createEvent: (match: Match) => CreateEventRequestAction;
  updateEvent: (match: Match) => UpdateEventRequestAction;
  updateResult: (
    matchId: number, homeResult: number, awayResult: number,
  ) => UpdateResultRequestAction;
}

export interface MatchFormSchema {
  [OPPONENT_TEAM]?: string;
  [COMPETITION]: MatchType | null;
  [PITCH]: Pitch | null;
  [MATCH_PLACE]: MatchPlace;
  [DESCRIPTION]: string;
  [MEETING_TIME]: Date | null;
  [START_TIME]: Date | null;
  [END_TIME]: Date | null;
  [ADDRESS]: string;
  [MEETING_POINT]: string;
  [REPORT]?: string;
}

export const MATCH_FORM_DEFAULT_STATE: MatchFormSchema = {
  [PITCH]: null,
  [COMPETITION]: null,
  [MATCH_PLACE]: MatchPlace.HOME,
  [MEETING_TIME]: null,
  [START_TIME]: null,
  [END_TIME]: null,
  [OPPONENT_TEAM]: '',
  [DESCRIPTION]: '',
  [ADDRESS]: '',
  [MEETING_POINT]: '',
  [REPORT]: '',
};

export enum MatchEditTabs {
  MATCH_DETAILS = 'MATCH_DETAILS',
  MATCH_STATISTICS = 'MATCH_STATISTICS',
}

export interface InformTeamParams {
  [RESET_ATTENDANCE]: boolean;
  [INFORM_TEAM]: boolean;
}

export const INFORM_TEAM_PARAMS_DEFAULT_PROPS = {
  [RESET_ATTENDANCE]: false,
  [INFORM_TEAM]: false,
};
