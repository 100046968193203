import {
  SIGN_OUT_REQUEST,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_ERROR,
} from '../constants/actionTypes';

export const signOut = () => ({
  type: SIGN_OUT_REQUEST,
});

export const signOutSuccess = () => ({
  type: SIGN_OUT_SUCCESS,
});

export const signOutError = error => ({
  type: SIGN_OUT_ERROR,
  payload: {
    error,
  },
});
