import { AjaxError } from 'rxjs/ajax';
import {
  CANCEL_EVENT_REQUEST,
  CANCEL_EVENT_SUCCESS,
  CANCEL_EVENT_ERROR,
  CREATE_EVENT_REQUEST,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_ERROR,
  FETCH_EVENTS_REQUEST,
  FETCH_EVENTS_SUCCESS,
  FETCH_EVENTS_ERROR,
  FETCH_EVENT_SUCCESS,
  FETCH_EVENT_REQUEST,
  FETCH_EVENT_ERROR,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_ERROR,
  UPDATE_EVENT_REQUEST,
  RESET_EVENT,
  UPDATE_RESULT_REQUEST,
  UPDATE_RESULT_SUCCESS,
  UPDATE_RESULT_ERROR,
  DELETE_EVENT_REQUEST,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_ERROR,
  UPLOAD_EVENTS_REQUEST,
  UPLOAD_EVENTS_SUCCESS,
  UPLOAD_EVENTS_ERROR,
  PREVIEW_CSV_EVENT_REQUEST,
  PREVIEW_CSV_EVENT_SUCCESS,
  PREVIEW_CSV_EVENT_ERROR,
  BULK_DELETE_EVENT_REQUEST,
  BULK_DELETE_EVENT_SUCCESS,
  BULK_DELETE_EVENT_ERROR,
  CREATE_EVENTS_ERROR,
  CREATE_EVENTS_SUCCESS,
  CREATE_EVENTS_REQUEST,
  RESET_EVENT_CREATED,
} from '../constants/actionTypes';
import {
  CancelEventRequestAction,
  CancelEventSuccessAction,
  CancelEventErrorAction,
  CreateEventRequestAction,
  CreateEventSuccessAction,
  CreateEventErrorAction,
  FetchEventsRequestAction,
  FetchEventsSuccessAction,
  FetchEventsErrorAction,
  FetchEventErrorAction,
  FetchEventSuccessAction,
  FetchEventRequestAction,
  UpdateEventRequestAction,
  UpdateEventSuccessAction,
  UpdateEventErrorAction,
  ResetEventAction,
  UpdateResultRequestAction,
  UpdateResultSuccessAction,
  UpdateResultErrorAction,
  DeleteEventRequestAction,
  DeleteEventSuccessAction,
  DeleteEventErrorAction,
  UploadEventsRequestAction,
  UploadEventsSuccessAction,
  UploadEventsErrorAction,
  PreviewCSVEventRequestAction,
  PreviewCSVEventSuccessAction,
  PreviewCSVEventErrorAction,
  BulkDeleteEventRequestAction,
  BulkDeleteEventSuccessAction,
  BulkDeleteEventErrorAction,
  CreateEventsErrorAction,
  CreateEventsSuccessAction,
  CreateEventsRequestAction,
  ResetEventCreatedAction,
} from '../models/actions';
import {
  Training, Match, EventListData, OneTimeEvent,
} from '../models/Calendar';
import { EventType } from '../constants/eventTypes';

export const createEventRequest = (
  event: Training | Match | OneTimeEvent,
): CreateEventRequestAction => ({
  type: CREATE_EVENT_REQUEST,
  payload: { event },
});

export const createEventSuccess = (events: EventListData[]): CreateEventSuccessAction => ({
  type: CREATE_EVENT_SUCCESS,
  payload: {
    events,
  },
});

export const createEventError = (error: AjaxError): CreateEventErrorAction => ({
  type: CREATE_EVENT_ERROR,
  payload: { error },
});

export const resetEventCreated = (): ResetEventCreatedAction => ({
  type: RESET_EVENT_CREATED,
});

export const createEventsRequest = (
  events: Training[] | Match[] | OneTimeEvent[],
): CreateEventsRequestAction => ({
  type: CREATE_EVENTS_REQUEST,
  payload: { events },
});

export const createEventsSuccess = (events: EventListData[]): CreateEventsSuccessAction => ({
  type: CREATE_EVENTS_SUCCESS,
  payload: {
    events,
  },
});

export const createEventsError = (error: AjaxError): CreateEventsErrorAction => ({
  type: CREATE_EVENTS_ERROR,
  payload: { error },
});

export const deleteEventRequest = (id: number): DeleteEventRequestAction => ({
  type: DELETE_EVENT_REQUEST,
  payload: { id },
});

export const deleteEventSuccess = (id: number): DeleteEventSuccessAction => ({
  type: DELETE_EVENT_SUCCESS,
  payload: {
    id,
  },
});

export const deleteEventError = (error: AjaxError): DeleteEventErrorAction => ({
  type: DELETE_EVENT_ERROR,
  payload: { error },
});

export const bulkDeleteEventRequest = (
  groupId: string, eventType: EventType,
): BulkDeleteEventRequestAction => ({
  type: BULK_DELETE_EVENT_REQUEST,
  payload: { groupId, eventType },
});

export const bulkDeleteEventSuccess = (groupId: string): BulkDeleteEventSuccessAction => ({
  type: BULK_DELETE_EVENT_SUCCESS,
  payload: {
    groupId,
  },
});

export const bulkDeleteEventError = (error: AjaxError): BulkDeleteEventErrorAction => ({
  type: BULK_DELETE_EVENT_ERROR,
  payload: { error },
});

export const cancelEventRequest = (id: number): CancelEventRequestAction => ({
  type: CANCEL_EVENT_REQUEST,
  payload: { id },
});

export const cancelEventSuccess = (id: number): CancelEventSuccessAction => ({
  type: CANCEL_EVENT_SUCCESS,
  payload: { id },
});

export const cancelEventError = (error: AjaxError): CancelEventErrorAction => ({
  type: CANCEL_EVENT_ERROR,
  payload: { error },
});

export const fetchEventsRequest = (
  teamId: number, start: Date, end: Date,
): FetchEventsRequestAction => ({
  type: FETCH_EVENTS_REQUEST,
  payload: {
    teamId,
    start,
    end,
  },
});

export const fetchEventsSuccess = (
  events: EventListData[], count: number,
): FetchEventsSuccessAction => ({
  type: FETCH_EVENTS_SUCCESS,
  payload: {
    events,
    count,
  },
});

export const fetchEventsError = (error: AjaxError): FetchEventsErrorAction => ({
  type: FETCH_EVENTS_ERROR,
  payload: { error },
});

export const fetchEventRequest = (id: number): FetchEventRequestAction => ({
  type: FETCH_EVENT_REQUEST,
  payload: {
    id,
  },
});

export const fetchEventSuccess = (
  event: Training | Match | OneTimeEvent,
): FetchEventSuccessAction => ({
  type: FETCH_EVENT_SUCCESS,
  payload: {
    event,
  },
});

export const fetchEventError = (error: AjaxError): FetchEventErrorAction => ({
  type: FETCH_EVENT_ERROR,
  payload: { error },
});

export const updateEventRequest = (
  event: Training | Match | OneTimeEvent,
): UpdateEventRequestAction => ({
  type: UPDATE_EVENT_REQUEST,
  payload: {
    event,
  },
});

export const updateEventSuccess = (event: EventListData): UpdateEventSuccessAction => ({
  type: UPDATE_EVENT_SUCCESS,
  payload: {
    event,
  },
});

export const updateEventError = (error: AjaxError): UpdateEventErrorAction => ({
  type: UPDATE_EVENT_ERROR,
  payload: { error },
});

export const updateResultRequest = (
  id: number, homeTeamScore: number, awayTeamScore: number,
): UpdateResultRequestAction => ({
  type: UPDATE_RESULT_REQUEST,
  payload: {
    id,
    homeTeamScore,
    awayTeamScore,
  },
});

export const updateResultSuccess = (): UpdateResultSuccessAction => ({
  type: UPDATE_RESULT_SUCCESS,
  payload: {},
});

export const updateResultError = (error: AjaxError): UpdateResultErrorAction => ({
  type: UPDATE_RESULT_ERROR,
  payload: { error },
});

export const uploadEventsRequest = (teamId: number, file: File): UploadEventsRequestAction => ({
  type: UPLOAD_EVENTS_REQUEST,
  payload: {
    teamId,
    file,
  },
});

export const uploadEventsSuccess = (
  teamId: number, events: EventListData[] = [],
): UploadEventsSuccessAction => ({
  type: UPLOAD_EVENTS_SUCCESS,
  payload: {
    teamId,
    events,
  },
});

export const uploadEventsError = (error: AjaxError): UploadEventsErrorAction => ({
  type: UPLOAD_EVENTS_ERROR,
  payload: {
    error,
  },
});

export const resetEvent = (): ResetEventAction => ({
  type: RESET_EVENT,
  payload: {},
});

export const previewCSVEventRequest = (
  teamId: number, file: File,
): PreviewCSVEventRequestAction => ({
  type: PREVIEW_CSV_EVENT_REQUEST,
  payload: {
    teamId,
    file,
  },
});

export const previewCSVEventError = (error: AjaxError): PreviewCSVEventErrorAction => ({
  type: PREVIEW_CSV_EVENT_ERROR,
  payload: {
    error,
  },
});

export const previewCSVEventSuccess = (event: Training | Match): PreviewCSVEventSuccessAction => ({
  type: PREVIEW_CSV_EVENT_SUCCESS,
  payload: {
    event,
  },
});
