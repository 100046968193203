import React from 'react';
import { Flag, FlagNameValues } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { Player } from '../../../models/Player';

export const getContactInfoSection = ({
  address, phoneNumber, socialMedias, email = '', secondaryEmail = '',
}: Player) => {
  const {
    country = '', city = '', postCode = '', number = '', street = '',
  } = address || {};

  return [
    {
      name: 'CONTACT_INFO.COUNTRY',
      value: (
        <span>
          <Flag name={country as FlagNameValues} />
          {(country || '').toUpperCase()}
        </span>
      ),
    },
    { name: 'CONTACT_INFO.STREET', value: street || '' },
    { name: 'CONTACT_INFO.NUMBER', value: number || '' },
    { name: 'CONTACT_INFO.ZIP_CODE', value: postCode || '' },
    { name: 'CONTACT_INFO.CITY', value: city || '' },
    { name: 'CONTACT_INFO.TELEPHONE_NUMBER', value: phoneNumber, wideColumn: true },
    { name: 'CONTACT_INFO.PRIMARY_EMAIL', value: email },
    { name: 'CONTACT_INFO.ALTERNATIVE_EMAIL', value: secondaryEmail },
    {
      name: 'CONTACT_INFO.SOCIAL_MEDIA.TITLE',
      wideColumn: true,
      value: (
        <div>
          {socialMedias ? socialMedias.map(({ profileUrl, socialMedia }) => (
            <Link to={profileUrl} key={socialMedia}>{socialMedia}</Link>
          )) : ''}
        </div>
      ),
    },
  ];
};
