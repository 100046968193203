import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { push } from 'connected-react-router';
import { handleError } from '../../actions/errors';
import { addNotification } from '../../actions/notifications';
import { editTeamError, editTeamSuccess } from '../../actions/teams';
import { ONE_TEAM_PATH } from '../../constants/paths';
import { EditTeamRequestAction, NotificationType } from '../../models/actions';
import { State } from '../../models/State';
import { CLUB_TEAMS_MEMBERS_ROUTE } from '../../constants/routes';
import { setExternalReferencesRequest } from '../../actions/externalSystems/setExternalReferences';
import { EDIT_TEAM_ERROR, EDIT_TEAM_REQUEST, EDIT_TEAM_SUCCESS } from '../../constants/actionTypes';
import { deleteExternalReferencesRequest } from '../../actions/externalSystems/deleteExternalSystemReference';
import { ExternalSystemName } from '../../models/externalSystemIntegration';

export default (
  action$: ActionsObservable<EditTeamRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(EDIT_TEAM_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      team, deactivateTor24Integration,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: ONE_TEAM_PATH.replace('$teamId', `${team.id}`),
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify({
      name: team.name,
      ...(team.paypalUrl && { paypalUrl: team.paypalUrl }),
    }),
  }).pipe(
    mergeMap(() => [
      addNotification(NotificationType.SUCCESS, EDIT_TEAM_SUCCESS),
      editTeamSuccess(team.id, team.name),
      !!team.externalSystemReferences && !!team.externalSystemReferences.length
        && setExternalReferencesRequest(team.externalSystemReferences, team.id.toString()),
      deactivateTor24Integration
        && deleteExternalReferencesRequest(ExternalSystemName.TOR_24, team.id.toString()),
      push(CLUB_TEAMS_MEMBERS_ROUTE.replace(':teamId', team.id.toString())),
    ].filter(Boolean)),
    catchError((error: AjaxError) => [
      handleError(error, EDIT_TEAM_ERROR),
      editTeamError(error),
    ]),
  )),
);
