import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import { map, withLatestFrom } from 'rxjs/operators';
import xor from 'lodash/xor';

import {
  ORDER_TOGGLE_PLAYER_SELECTION,
  ORDER_TOGGLE_ALL_PLAYERS_SELECTION,
  ORDER_TOGGLE_PRODUCT_SELECTION,
  ORDER_TOGGLE_ALL_PRODUCTS_SELECTION,
  ORDER_TOGGLE_STAFF_SELECTION,
} from '../../../constants/actionTypes';
import {
  ToggleAllPlayersSelectionAction,
  ToggleAllProductsSelectionAction,
  TogglePlayerSelectionAction,
  ToggleProductSelectionAction,
  ToggleStaffSelectionAction,
} from '../../../models/actions';
import { State } from '../../../models/State';

import { selectPlayers, selectProducts, selectStaff } from '../../../actions/orderEditor';

export const togglePlayerSelection = (
  action$: ActionsObservable<TogglePlayerSelectionAction>,
  state$: StateObservable<State>,
) => action$.pipe(
  ofType(ORDER_TOGGLE_PLAYER_SELECTION),
  withLatestFrom(state$),
  map(([
    {
      payload: { id, teamId },
    },
    {
      orders: { selectedPlayersIds },
    },
  ]) => selectPlayers(xor(selectedPlayersIds, [id]), teamId)),
);

export const toggleStaffSelection = (
  action$: ActionsObservable<ToggleStaffSelectionAction>,
  state$: StateObservable<State>,
) => action$.pipe(
  ofType(ORDER_TOGGLE_STAFF_SELECTION),
  withLatestFrom(state$),
  map(([
    {
      payload: { id, teamId },
    },
    {
      orders: { selectedStaffIds },
    },
  ]) => selectStaff(xor(selectedStaffIds, [id]), teamId)),
);

export const toggleProductSelection = (
  action$: ActionsObservable<ToggleProductSelectionAction>,
  state$: StateObservable<State>,
) => action$.pipe(
  ofType(ORDER_TOGGLE_PRODUCT_SELECTION),
  withLatestFrom(state$),
  map(([
    {
      payload: { id },
    },
    {
      orders: { selectedProductsIds },
    },
  ]) => selectProducts(xor(selectedProductsIds, [id]))),
);

export const toggleAllPlayersSelection = (
  action$: ActionsObservable<ToggleAllPlayersSelectionAction>,
  state$: StateObservable<State>,
) => action$.pipe(
  ofType(ORDER_TOGGLE_ALL_PLAYERS_SELECTION),
  withLatestFrom(state$),
  map(([,
    {
      players: { byId },
      orders: { selectedPlayersIds },
    },
  ]) => selectPlayers(byId.length !== selectedPlayersIds.length ? byId : [])),
);

export const toggleAllProductsSelection = (
  action$: ActionsObservable<ToggleAllProductsSelectionAction>,
  state$: StateObservable<State>,
) => action$.pipe(
  ofType(ORDER_TOGGLE_ALL_PRODUCTS_SELECTION),
  withLatestFrom(state$),
  map(([,
    {
      orders: { productsByItemId, selectedProductsIds },
    },
  ]) => selectProducts(
    productsByItemId.length !== selectedProductsIds.length ? productsByItemId : [],
  )),
);
