import partition from 'lodash/partition';
import { ItemsByHash } from '../../../../../../../../models/Order';
import { SortOrder } from '../../../../../../../../models/general/SortOrder';
import SortingOrder from '../../../../../../../../constants/sortingOrder';
import { PlayersByHash } from '../../../../../../../../models/Player';

const getValue = (itemsByHash: any, playersByHash: PlayersByHash, a: string) => (
  itemsByHash[a].numberLabel
  || playersByHash[itemsByHash[a].playerId].teamNumber
  || playersByHash[itemsByHash[a].playerId].number
);

const sortNumberAsc = (itemsByHash: any, playersByHash: any) => (a: string, b: string) => (
  getValue(itemsByHash, playersByHash, a) - getValue(itemsByHash, playersByHash, b)
);

const sortNumberDesc = (itemsByHash: any, playersByHash: any) => (a: string, b: string) => (
  getValue(itemsByHash, playersByHash, b) - getValue(itemsByHash, playersByHash, a)
);

export default (
  itemsByHash: ItemsByHash, itemsById: string[], order: SortOrder, playersByHash: PlayersByHash,
): string[] => {
  const checkProperties = (id: string) => !!(
    playersByHash[(itemsByHash[id] as any).playerId].number
    || playersByHash[(itemsByHash[id] as any).playerId].teamNumber
  );

  const [
    withNumber, withoutNumber,
  ] = partition(itemsById, itemId => (
    itemsByHash[itemId]
    && playersByHash[(itemsByHash[itemId] as any).playerId]
    && checkProperties(itemId)
  ));

  const sortAlg = order === SortingOrder.ASC
    ? sortNumberAsc(itemsByHash, playersByHash)
    : sortNumberDesc(itemsByHash, playersByHash);

  withNumber.sort(sortAlg);

  return [
    ...withNumber,
    ...withoutNumber,
  ];
};
