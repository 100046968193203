import { AjaxError } from 'rxjs/ajax';
import {
  EXTERNAL_TEAMS_ERROR,
  EXTERNAL_TEAMS_REQUEST, EXTERNAL_TEAMS_SUCCESS,
} from '../../constants/actionTypes';

import { ExternalTeam } from '../../models/externalSystemIntegration';
import { ExternalTeamsErrorAction, ExternalTeamsRequestAction, ExternalTeamsSuccessAction } from '../../models/actions';

export const externalTeamsRequest = (
  externalClubId: string,
): ExternalTeamsRequestAction => ({
  type: EXTERNAL_TEAMS_REQUEST,
  payload: {
    externalClubId,
  },
});

export const externalTeamsSuccess = (
  teams: ExternalTeam[],
): ExternalTeamsSuccessAction => ({
  type: EXTERNAL_TEAMS_SUCCESS,
  payload: {
    teams,
  },
});

export const externalTeamsError = (error: AjaxError): ExternalTeamsErrorAction => ({
  type: EXTERNAL_TEAMS_ERROR,
  payload: {
    error,
  },
});
