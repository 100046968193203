import { AjaxResponse } from 'rxjs/ajax';
import * as types from '../constants/actionTypes';
import { SignUpData } from '../models/signUp/SignUpData';
import { SignUpWithTokenRequestAction, SignUpWithTokenSuccessAction } from '../models/actions';
import { Token } from '../models/Token';

export const signUpWithToken = (
  signUpData: SignUpData, inviteToken: string, tokenDetails: Token,
): SignUpWithTokenRequestAction => ({
  type: types.SIGN_UP_WITH_TOKEN_REQUEST,
  payload: {
    signUpData,
    inviteToken,
    receiverRole: tokenDetails.reciverRole,
  },
});

export const signUpSuccess = (
  signUpData: SignUpData, receiverRole: string,
): SignUpWithTokenSuccessAction => ({
  type: types.SIGN_UP_SUCCESS,
  payload: {
    signUpData,
    receiverRole,
  },
});

export const signUpError = (serverError: AjaxResponse) => ({
  type: types.SIGN_UP_ERROR,
  payload: {
    serverError,
  },
});

export const resetSignup = () => ({
  type: types.RESET_SIGNUP,
});
