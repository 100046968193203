import { AjaxError } from 'rxjs/ajax';
import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import {
  catchError, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import {
  DELETE_EXTERNAL_REF_REQUEST,
  SET_EXTERNAL_REF_ERROR,
} from '../../constants/actionTypes';
import { EXTERNAL_REF_DELETION_PATH } from '../../constants/paths';
import { State } from '../../models/State';
import { handleError } from '../../actions/errors';

import {
  ExternalSystemReference,
} from '../../models/externalSystemIntegration/externalSystemReference';
import {
  setExternalReferencesError,
  setExternalReferencesSuccess,
} from '../../actions/externalSystems/setExternalReferences';
import { DeleteExternalReferenceRequestAction } from '../../models/actions/externalSystem/deleteExternalSystemReference';

export default (
  action$: ActionsObservable<DeleteExternalReferenceRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(DELETE_EXTERNAL_REF_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      referenceName,
      teamId,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: EXTERNAL_REF_DELETION_PATH.replace('$teamId', teamId).replace('$externalRefName', referenceName),
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    mergeMap((externalRefs: { response: ExternalSystemReference[] }) => [
      setExternalReferencesSuccess(externalRefs),
    ]),
    catchError((error: AjaxError) => [
      handleError(error, SET_EXTERNAL_REF_ERROR),
      setExternalReferencesError(error),
    ]),
  )),
);
