import React, { FC, useEffect, useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { ExcelRenderer, OutTable } from '../../../../../../utils/XlsRenderer';

import { TableData, XlsModalProps } from './XlsModalProps';
import styles from './XlsModal.module.scss';
import './Excel.scss';

const XlsModal: FC<XlsModalProps> = ({
  file, triggerButton, filesByHash, fetchFile, openPreview, onClose, onOpen,
}) => {
  const [tableData, setTableData] = useState<TableData | null>(null);

  useEffect(() => {
    if (file.id && !filesByHash[file.id]) {
      fetchFile(file.id, file.extension);
    }
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let mounted = true;
    const fileBlob = filesByHash[file.id];
    if (fileBlob) {
      ExcelRenderer(fileBlob, (error: boolean, table: TableData) => {
        if (!error && mounted) {
          setTableData(table);
        }
      });
    }
    return () => { mounted = false; };
  }, [filesByHash, file]);

  return (
    <Modal
      className={styles.modalContainer}
      closeIcon
      trigger={triggerButton}
      size="fullscreen"
      {...(!triggerButton && {
        onOpen,
        onClose,
        open: openPreview,
      })}
    >
      <Modal.Header content={file.originalFilename} />
      <Modal.Content className={styles.content}>
        {tableData && (
          <OutTable
            data={tableData.rows}
            columns={tableData.cols}
            tableClassName="ExcelTable"
            tableHeaderRowClass="heading"
          />
        )}
      </Modal.Content>
    </Modal>
  );
};

export default XlsModal;
