import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';

import { RESET_PASSWORD_TOKEN_PATH } from '../constants/paths';
import { RESET_PASSWORD_TOKEN_REQUEST } from '../constants/actionTypes';
import { resetPasswordError } from '../actions/resetPassword';
import { resetPasswordTokenSuccess } from '../actions/resetPasswordToken';

export default (baseUrl, app) => (action$, state$, { ajax }) => action$.pipe(
  ofType(RESET_PASSWORD_TOKEN_REQUEST),
  mergeMap(({ payload: { username } }) => ajax({
    url: `${baseUrl}${RESET_PASSWORD_TOKEN_PATH.replace('$username', username).replace('$app', app)}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  }).pipe(
    map(() => resetPasswordTokenSuccess()),
    catchError(error => of(resetPasswordError(error))),
  )),
);
