import DayPickerInput from 'react-day-picker/DayPickerInput';
import React, { FC, useEffect, useState } from 'react';
import TimePicker from 'rc-time-picker';
import classNames from 'classnames';
import { Moment, MomentInput } from 'moment';
import { DropdownItemProps, Form, Label } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { TrainingEventFormProps } from './TrainingEventFormProps';
import { EventInterval } from '../../../../constants/eventInterval';
import { WeekDays } from '../../../../constants/weekDays';
import styles from './TrainingEventForm.module.scss';
import { DATE_FORMAT, formatDate, TIME_FORMAT } from '../../../../utils/dateUtils';
import 'rc-time-picker/assets/index.css';
import { createMoment, getDisabledHours, getDisabledMinutes } from '../../methods/TimePickerUtil';
import { isRequired } from '../../../../utils/formValidators';
import {
  ADDRESS,
  DAY,
  DESCRIPTION,
  END_TIME,
  INTERVAL,
  MEETING_TIME,
  NAME,
  START_TIME,
} from '../TrainingEventModal/TrainingEventModalProps';

const TrainingEventForm: FC<TrainingEventFormProps> = ({
  formState,
  handleChange,
  startDate,
  handleStartDateChange,
  handleEndDateChange,
  endDate,
  handleTimeChange,
  loading,
  isSubmitted,
  event,
}) => {
  const [weekDays, setWeekDays] = useState<DropdownItemProps[]>([]);
  const [eventIntervals, setEventIntervals] = useState<DropdownItemProps[]>([]);
  const [translate] = useTranslation();

  useEffect(() => {
    setWeekDays(Object.keys(WeekDays).map(day => ({ text: translate(day), value: day })));
    setEventIntervals(Object.keys(EventInterval)
      .filter(interval => Number.isNaN(Number(interval)))
      .map(interval => ({
        text: translate(interval),
        value: EventInterval[interval as keyof typeof EventInterval],
      })));
  }, [translate]);

  const handleStartTimeChange = (date: Moment) => {
    handleTimeChange(date, START_TIME);
    if (!formState[END_TIME] || (date && date.toDate() > formState[END_TIME]!)) {
      handleTimeChange(date, END_TIME);
    }
    if (formState[MEETING_TIME] && date && date.toDate() < formState[MEETING_TIME]!) {
      handleTimeChange(date, MEETING_TIME);
    }
  };

  const handleEndTimeChange = (date: Moment) => {
    handleTimeChange(date, END_TIME);
  };

  const handleMeetingTimeChange = (date: Moment) => {
    handleTimeChange(date, MEETING_TIME);
  };

  const errorRequired = (value: any) => (
    isSubmitted && !isRequired.rule(value) && translate(isRequired.errorMessage)
  );

  const defaultTimePickerProps = {
    clearIcon: null,
    defaultOpenValue: createMoment(),
    format: TIME_FORMAT,
    minuteStep: 15,
    showSecond: false,
  };

  return (
    <Form className={styles.content} loading={loading}>
      { event ? (
        <>
          <Form.Group>
            <Form.Input className={[styles.width, styles.oneThird, styles.required].join(' ')} label={translate('NAME')} value={formState.name} error={isSubmitted && !formState[NAME]} onChange={handleChange} name={NAME} />
            <Form.Input className={[styles.width, styles.twoThirds].join(' ')} label={translate('ADDRESS')} value={formState[ADDRESS]} onChange={handleChange} name={ADDRESS} />
          </Form.Group>
          <Form.Group widths="equal" className={[styles.flex, styles.align, styles.flexEnd].join(' ')}>
            <Form.Field className={[styles.flex, styles.direction, styles.column, styles.required].join(' ')} error={isSubmitted && !startDate}>
              <label>{translate('DATE')}</label>
              <DayPickerInput
                onDayChange={handleStartDateChange}
                value={startDate || ''}
                format={DATE_FORMAT}
                formatDate={(date: MomentInput) => formatDate(date)}
                placeholder=""
                dayPickerProps={{
                  firstDayOfWeek: 1,
                  selectedDays: startDate,
                }}
              />
            </Form.Field>
            <div className={['field', styles.flex, styles.align, styles.flexEnd, styles.justify, styles.spaceBetween].join(' ')}>
              <Form.Field className={styles.required} error={isSubmitted && !formState[START_TIME]}>
                <label>{translate('TIME')}</label>
                <TimePicker
                  {...defaultTimePickerProps}
                  disabledHours={() => [0, 1, 2, 3, 4]}
                  className={[styles.time, styles.picker, styles.noClear].join(' ')}
                  onChange={handleStartTimeChange}
                  value={formState[START_TIME] ? createMoment(formState[START_TIME]!) : undefined}
                />
              </Form.Field>
              <span className={styles.timeToLabel}>{translate('TO')}</span>
              <Form.Field error={isSubmitted && !formState[END_TIME]}>
                <TimePicker
                  {...defaultTimePickerProps}
                  disabled={!formState[START_TIME]}
                  disabledHours={getDisabledHours(formState[START_TIME], END_TIME)}
                  disabledMinutes={getDisabledMinutes(formState[START_TIME], END_TIME)}
                  className={[styles.time, styles.picker, styles.noClear].join(' ')}
                  onChange={handleEndTimeChange}
                  value={formState[END_TIME] ? createMoment(formState[END_TIME]!) : undefined}
                />
              </Form.Field>
            </div>
            <Form.Field className={[styles.flex, styles.direction, styles.column].join(' ')}>
              <label>{translate('MEETING_TIME')}</label>
              <TimePicker
                {...defaultTimePickerProps}
                disabledHours={getDisabledHours(formState[START_TIME], MEETING_TIME)}
                disabledMinutes={getDisabledMinutes(formState[START_TIME], MEETING_TIME)}
                disabled={!formState[START_TIME]}
                className={[styles.time, styles.picker].join(' ')}
                onChange={handleMeetingTimeChange}
                value={formState[MEETING_TIME] ? createMoment(formState[MEETING_TIME]!) : undefined}
              />
            </Form.Field>
          </Form.Group>
        </>
      ) : (
        <>
          <Form.Group widths="equal">
            <Form.Input
              className={styles.required}
              label={translate('NAME')}
              value={formState[NAME]}
              error={errorRequired(formState[NAME])}
              onChange={handleChange}
              name={NAME}
            />
            <Form.Select
              label={translate('DAY')}
              onChange={handleChange}
              options={weekDays}
              value={formState[DAY] || undefined}
              name={DAY}
              error={errorRequired(formState[DAY])}
              className={[styles.select, styles.selectWeekDay, styles.required].join(' ')}
              disabled={formState[INTERVAL] === EventInterval.ONCE}
            />
            <Form.Select
              label={translate('INTERVAL')}
              onChange={handleChange}
              options={eventIntervals}
              value={formState[INTERVAL] || undefined}
              name={INTERVAL}
              error={formState[INTERVAL] !== 0 && errorRequired(formState[INTERVAL])}
              className={[styles.select, styles.selectInterval, styles.required].join(' ')}
            />
          </Form.Group>
          <Form.Group widths="equal" className={[styles.flex, styles.align, styles.flexEnd].join(' ')}>
            <Form.Field className={[styles.flex, styles.direction, styles.column, styles.required].join(' ')} error={isSubmitted && !startDate}>
              <label>{translate('START_DATE')}</label>
              <DayPickerInput
                onDayChange={handleStartDateChange}
                value={startDate || ''}
                format={DATE_FORMAT}
                formatDate={(date: MomentInput) => formatDate(date)}
                placeholder=""
                dayPickerProps={{
                  firstDayOfWeek: 1,
                  selectedDays: startDate,
                }}
              />
              {errorRequired(startDate) && (
                <Label content={errorRequired(startDate)} pointing prompt />
              )}
            </Form.Field>
            <Form.Field className={[styles.flex, styles.direction, styles.column, styles.required].join(' ')} error={isSubmitted && !endDate}>
              <label>{translate('END_DATE')}</label>
              <DayPickerInput
                onDayChange={handleEndDateChange}
                value={endDate || ''}
                format={DATE_FORMAT}
                formatDate={(date: MomentInput) => formatDate(date)}
                placeholder=""
                dayPickerProps={{
                  firstDayOfWeek: 1,
                  selectedDays: endDate,
                  ...(startDate && ({ disabledDays: { before: startDate } })),
                }}
                inputProps={{
                  disabled: formState[INTERVAL] === EventInterval.ONCE,
                }}
              />
              {errorRequired(endDate) && (
                <Label content={errorRequired(endDate)} pointing prompt />
              )}
            </Form.Field>
            <div
              className={classNames(
                ['field', styles.flex, styles.align, styles.flexEnd, styles.justify, styles.spaceBetween, styles.eventDates],
                errorRequired(formState[START_TIME]) && 'error',
              )}
            >
              <Form.Field className={styles.required} error={isSubmitted && !formState[START_TIME]}>
                <label>{translate('TIME')}</label>
                <TimePicker
                  {...defaultTimePickerProps}
                  disabledHours={() => [0, 1, 2, 3, 4]}
                  className={[styles.time, styles.picker, styles.noClear].join(' ')}
                  onChange={handleStartTimeChange}
                  value={formState[START_TIME] ? createMoment(formState[START_TIME]!) : undefined}
                />
              </Form.Field>
              <span className={styles.timeToLabel}>{translate('TO')}</span>
              <Form.Field error={isSubmitted && !formState[END_TIME]}>
                <TimePicker
                  {...defaultTimePickerProps}
                  disabledHours={getDisabledHours(formState[START_TIME], END_TIME)}
                  disabledMinutes={getDisabledMinutes(formState[START_TIME], END_TIME)}
                  value={formState[END_TIME] ? createMoment(formState[END_TIME]!) : undefined}
                  disabled={!formState[START_TIME]}
                  className={[styles.time, styles.picker, styles.noClear].join(' ')}
                  onChange={handleEndTimeChange}
                />
              </Form.Field>
              {errorRequired(formState[START_TIME]) && (
                <Label content={errorRequired(formState[START_TIME])} pointing prompt />
              )}
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Field className={[styles.width, styles.oneThird, styles.flex, styles.direction, styles.column].join(' ')}>
              <label>{translate('MEETING_TIME')}</label>
              <TimePicker
                {...defaultTimePickerProps}
                disabled={!formState[START_TIME]}
                disabledHours={getDisabledHours(formState[START_TIME], MEETING_TIME)}
                disabledMinutes={getDisabledMinutes(formState[START_TIME], MEETING_TIME)}
                className={[styles.time, styles.picker].join(' ')}
                onChange={handleMeetingTimeChange}
                value={formState[MEETING_TIME] ? createMoment(formState[MEETING_TIME]!) : undefined}
              />
            </Form.Field>
            <Form.Input
              className={[styles.width, styles.twoThirds].join(' ')}
              label={translate('ADDRESS')}
              value={formState[ADDRESS]}
              onChange={handleChange}
              name={ADDRESS}
            />
          </Form.Group>
        </>
      )}
      <Form.TextArea rows={4} label={translate('INFORMATION')} onChange={handleChange} value={formState[DESCRIPTION] || ''} name={DESCRIPTION} />
    </Form>
  );
};

export default TrainingEventForm;
