import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MatchEventModal from './MatchEventModal';
import { createEventRequest, updateEventRequest, updateResultRequest } from '../../../../actions/calendar';
import State from '../../../../models';

const mapStateToProps = (
  {
    teams,
    calendar: { loading, event },
    user: {
      user,
    },
  }: State,
) => ({
  loading,
  event,
  firstName: user ? user.firstName : null,
  teamsByHash: teams.byHash,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createEvent: bindActionCreators(createEventRequest, dispatch),
  updateEvent: bindActionCreators(updateEventRequest, dispatch),
  updateResult: bindActionCreators(updateResultRequest, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MatchEventModal));
