import React, { useMemo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { history } from '../../../../configureStore';
import styles from './TeamInfo.module.scss';
import { TeamInfoProps } from './TeamInfoProps';
import { ORDER_PREPARATION_ROUTE } from '../../../../constants/routes';
import { useViewport } from '../../../../hooks/useViewport';

const TeamInfo: FC<TeamInfoProps> = ({
  teamsByHash, teamId, teamsById, orderId, onSwitchTeam, disabled = false,
}) => {
  const { isMobile } = useViewport();
  const [translate] = useTranslation();
  const { name, players } = teamsByHash[teamId];

  const handleTeamChange = (
    event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps,
  ) => {
    history.push(ORDER_PREPARATION_ROUTE.replace(':teamId', `${data.value}`));
    if (onSwitchTeam) {
      onSwitchTeam();
    }
  };

  const teamsOptions = useMemo(() => teamsById.map(id => teamsByHash[id]).map(t => ({
    key: t.id,
    text: t.name,
    value: t.id,
  })), [teamsById, teamsByHash]);

  return (
    <div className={styles.teamInfo}>
      <span>{translate('TEAM')}</span>
      {orderId ? (
        <strong>{name}</strong>
      ) : (
        <Dropdown
          className={styles.teamsPicker}
          placeholder={translate('TEAM')}
          defaultValue={teamId}
          disabled={disabled}
          selection
          options={teamsOptions}
          onChange={handleTeamChange}
        />
      )}
      {!isMobile && (
        <>
          <span>{translate('PLAYERS')}</span>
          <strong>{players.length}</strong>
        </>
      )}
    </div>
  );
};

export default TeamInfo;
