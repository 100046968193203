import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Label, Loader } from 'semantic-ui-react';

import Header, { HeaderTypes } from '../Header';
import styles from './ChipComponent.module.scss';
import { ChipComponentProps } from './ChipComponentProps';

const ChipComponent: FC<ChipComponentProps> = ({
  header,
  entities,
  selectedEntities,
  clickable,
  loading = false,
  displayOnlyActive = false,
  noEntriesText,
  onClick,
}) => {
  const [translate] = useTranslation();

  const entries = useMemo(() => (displayOnlyActive && entities && entities.length
    ? selectedEntities.map(
      id => entities.find(entity => entity.id === id)!,
    ).filter(Boolean) : entities), [entities, selectedEntities, displayOnlyActive]);

  const toggleChip = useCallback(
    (selectedEntityId: number) => () => {
      onClick(entities.find(entity => entity.id === selectedEntityId)!.id);
    }, [onClick, entities],
  );

  const isChipActive = useCallback(
    (entityId: number) => selectedEntities.find((id: number) => id === entityId),
    [selectedEntities],
  );

  return (
    <div className={styles.wrapper}>
      <Header title={translate(header)} type={HeaderTypes.H3} />

      {entries.length ? (
        <div className={styles['tags-container']}>
          {loading ? <Loader active /> : entries.map(entity => (
            <Label
              onClick={clickable ? toggleChip(entity.id) : () => { }}
              key={entity.id}
              className={
                [
                  styles.tag,
                  styles.label,
                  clickable ? styles.pointer : '',
                  isChipActive(entity.id) ? styles.active : styles.inactive,
                ].join(' ')
              }
            >
              {entity.name}
              {isChipActive(entity.id) && clickable && <Icon name="delete" className={[styles.delete, styles.icon].join(' ')} />}
            </Label>
          ))}
        </div>
      ) : (
        <p className={styles.noEntriesText}>
          {translate(noEntriesText)}
        </p>
      )}
    </div>
  );
};

export default ChipComponent;
