import { of } from 'rxjs';
import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import {
  catchError, map, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import { FETCH_EVENT_REQUEST } from '../../constants/actionTypes';
import { FetchEventRequestAction } from '../../models/actions';
import { Response } from '../../models/Response';
import { State } from '../../models/State';
import { EVENTS } from '../../constants/paths';
import { fetchEventSuccess, fetchEventError } from '../../actions/calendar';
import { Training, Match, OneTimeEvent } from '../../models/Calendar';
import serializeEvent from '../../utils/serializers/serializeEvent';

const fetchEvent = (
  action$: ActionsObservable<FetchEventRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_EVENT_REQUEST),
  withLatestFrom(state$),
  mergeMap(([
    { payload: { id } },
    {
      authentication: { authToken },
    },
  ]) => ajax({
    url: `${EVENTS}/${id}`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    map(({ response: event }: Response<Training | Match | OneTimeEvent>) => (
      fetchEventSuccess(serializeEvent(event))
    )),
    catchError(error => of(fetchEventError(error))),
  )),
);

export default fetchEvent;
