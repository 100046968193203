import { AjaxError } from 'rxjs/ajax';
import {
  FETCH_TOR24_MATCHES_ERROR,
  FETCH_TOR24_MATCHES_REQUEST,
  FETCH_TOR24_MATCHES_SUCCESS,
} from '../../constants/actionTypes';

import {
  FetchTor24MatchesError,
  FetchTor24MatchesRequestAction,
  FetchTor24MatchesSuccessAction,
} from '../../models/actions/externalSystem';
import { MatchData } from '../../models/Calendar';

export const fetchTor24MatchesRequest = (
  teamId: number,
): FetchTor24MatchesRequestAction => ({
  type: FETCH_TOR24_MATCHES_REQUEST,
  payload: {
    teamId,
  },
});

export const fetchTor24MatchesSuccess = (
  matches: MatchData[],
): FetchTor24MatchesSuccessAction => ({
  type: FETCH_TOR24_MATCHES_SUCCESS,
  payload: {
    matches,
  },
});

export const fetchTor24MatchesError = (error: AjaxError): FetchTor24MatchesError => ({
  type: FETCH_TOR24_MATCHES_ERROR,
  payload: {
    error,
  },
});
