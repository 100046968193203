import get from 'lodash/get';
import isObject from 'lodash/isObject';
import set from 'lodash/set';
import { useTranslation } from 'react-i18next';
import {
  useState, ChangeEvent, SyntheticEvent, useEffect, useCallback,
} from 'react';

const useForm = (validationSchema: any, data?: any) => {
  const [values, setValues] = useState<any>(data || {});
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [validationEnabled, setValidationEnabled] = useState<Boolean>(false);
  const [translate] = useTranslation();

  const scrollToError = (fieldsErrors: any) => {
    if (fieldsErrors) {
      const fieldName = Object.keys(fieldsErrors)[0];
      const field = document.getElementsByName(fieldName);
      if (field && field[0]) {
        field[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  const validate = useCallback(() => {
    const fieldsErrors = Object.keys(validationSchema).reduce((acc, fieldName) => {
      const error = (validationSchema[fieldName] || [])
        .filter(isObject)
        .map((validator: any) => !validator.rule(get(values, fieldName), values) && validator)
        .find(Boolean);

      return {
        ...acc,
        ...(error && { [fieldName]: translate(error.errorMessage, error.errorMessageParams) }),
      };
    }, {});

    setErrors(fieldsErrors);
    setValidationEnabled(true);
    scrollToError(fieldsErrors);

    return !Object.keys(fieldsErrors).length;
  }, [translate, values, validationSchema]);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement> | SyntheticEvent<HTMLElement> | null,
    { name, value }: any,
  ) => {
    if (event && event.persist) {
      event.persist();
    }
    setValues((currentValues: any) => ({
      ...set(currentValues, name, value || null),
    }));
  };

  useEffect(() => {
    if (validationEnabled) {
      validate();
    }
  }, [values, validationEnabled, validate]);

  return {
    handleChange,
    validate,
    setValues,
    values,
    errors,
  };
};

export default useForm;
