import React, { FC, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import {
  DropdownProps, InputOnChangeData, Modal, TextAreaProps,
} from 'semantic-ui-react';
import 'react-day-picker/lib/style.css';
import {
  ADDRESS,
  DAY,
  DESCRIPTION,
  END_TIME,
  INTERVAL,
  MEETING_TIME,
  NAME,
  START_TIME,
  TRAINING_FORM_DEFAULT_STATE,
  TrainingEventModalProps,
  TrainingFormSchema,
} from './TrainingEventModalProps';
import { EventType } from '../../../../constants/eventTypes';
import TrainingEventHeader from '../TrainingEventHeader';
import TrainingEventForm from '../TrainingEventForm';
import { Training } from '../../../../models/Calendar';
import generateTraining from '../../methods/generateTraining';
import InformTeamModal from '../InformTeamModal';
import {
  INFORM_TEAM,
  INFORM_TEAM_PARAMS_DEFAULT_PROPS,
  InformTeamParams,
  RESET_ATTENDANCE,
} from '../MatchEventModal/MatchEventModalProps';
import { EventInterval } from '../../../../constants/eventInterval';
import { getUpperCasedDay } from '../../methods/TimePickerUtil';

const TrainingEventModal: FC<TrainingEventModalProps> = ({
  onClose, createEvent, teamId, event, updateEvent, teamsByHash, activeSlot, firstName, fetchTeam,
}) => {
  const [startDate, setStartDate] = useState<Date | undefined>(activeSlot);
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formState, setFormState] = useState<TrainingFormSchema>(TRAINING_FORM_DEFAULT_STATE);
  const [showInformTeamModal, setShowInformTeamModal] = useState<boolean>(false);
  const [
    informTeamParams, setInformTeamParams,
  ] = useState<InformTeamParams>(INFORM_TEAM_PARAMS_DEFAULT_PROPS);
  const [translate] = useTranslation();

  useEffect(() => {
    if (event && event.startDateTime && event.endDateTime) {
      setFormState({
        [NAME]: event.name,
        [DESCRIPTION]: event.description || '',
        [MEETING_TIME]: event.meetingDate || null,
        [START_TIME]: event.startDateTime,
        [END_TIME]: event.endDateTime,
        [ADDRESS]: (event.location && event.location.displayName) || '',
      });
      setStartDate(event.startDateTime);
      setEndDate(event.endDateTime);
    }
  }, [event]);

  useEffect(() => {
    if (teamId && !teamsByHash[teamId]) {
      fetchTeam(teamId);
    }
  }, [teamsByHash, teamId, fetchTeam]);

  useEffect(() => {
    const validateForm = () => {
      const {
        [DESCRIPTION]: desc,
        [MEETING_TIME]: meetingTime,
        [INTERVAL]: interval,
        [ADDRESS]: address,
        ...requiredFormFields
      } = formState;
      setIsFormValid(
        !Object.values(requiredFormFields).filter(item => !item).length
          && !!startDate
          && !!endDate
          && (!!event || (!!interval || interval === 0)),
      );
    };

    validateForm();
  }, [formState, startDate, endDate, event]);

  const handleStartDateChange = (value: Date) => {
    setEndDate(formState[INTERVAL] ? moment(value).add(formState[INTERVAL]!, 'week').toDate() : value);
    setStartDate(value);
    if (formState[INTERVAL] === EventInterval.ONCE) {
      setFormState({
        ...formState,
        [DAY]: getUpperCasedDay(value),
      });
    }
  };

  const handleEndDateChange = (value: Date) => {
    setEndDate(value);
  };

  const handleChange = (e: any, {
    name, value,
  }: InputOnChangeData | DropdownProps | TextAreaProps) => {
    const newValues: TrainingFormSchema = {
      ...formState,
      [name]: value,
    };

    if (name === INTERVAL) {
      setEndDate(startDate ? moment(startDate)
        .add(value as number, 'week').toDate() : undefined);

      if (value === EventInterval.ONCE && startDate) {
        newValues[DAY] = getUpperCasedDay(startDate);
      }
    }
    if (name === DAY && startDate && endDate) {
      setStartDate(moment(startDate).isoWeekday(value as string).toDate());
      setEndDate(moment(startDate).isoWeekday(value as string).add(formState[INTERVAL] ? formState[INTERVAL]! : 0, 'week').toDate());
    }

    setFormState(newValues);
  };

  const handleDateTimeChange = (dateTime: Moment, name: string) => {
    setFormState(state => ({ ...state, [name]: dateTime ? dateTime.toDate() : null }));
  };

  const onMainFormSubmit = () => {
    setIsSubmitted(true);

    if (isFormValid) {
      const training: Training = generateTraining(formState, startDate, endDate, teamId, event);

      if (event && event.id) {
        setShowInformTeamModal(true);
      } else {
        createEvent(training);
      }
    }
  };

  const onEditFormSubmit = () => {
    setShowInformTeamModal(false);
    updateEvent(generateTraining(formState, startDate, endDate, teamId, event, informTeamParams));
  };

  const toggleInformTeam = () => {
    setInformTeamParams({
      ...informTeamParams,
      [INFORM_TEAM]: !informTeamParams[INFORM_TEAM],
    });
  };

  const toggleResetAttendance = () => {
    setInformTeamParams({
      ...informTeamParams,
      [RESET_ATTENDANCE]: !informTeamParams[RESET_ATTENDANCE],
    });
  };

  return (
    <>
      <Modal
        open
        size="small"
        onClose={onClose}
        closeIcon
        closeOnDimmerClick={false}
        style={{ width: '930px', padding: '30px' }}
      >
        <TrainingEventHeader
          header={`${translate(EventType.TRAINING)} ${teamId && teamsByHash[teamId] ? `(${teamsByHash[teamId].name})` : ''}`}
          onClose={onClose}
          onSubmit={onMainFormSubmit}
          isFormValid={isFormValid}
          isSubmitted={isSubmitted}
        />
        <TrainingEventForm
          formState={formState}
          handleChange={handleChange}
          startDate={startDate}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
          endDate={endDate}
          handleTimeChange={handleDateTimeChange}
          isSubmitted={isSubmitted}
        />
        {/* Uncomment when backend content stream functionality is ready
          { !event && <NewEventFooter /> }
        */}
      </Modal>
      <InformTeamModal
        firstName={firstName}
        showInformTeamModal={showInformTeamModal}
        setShowInformTeamModal={setShowInformTeamModal}
        onEditFormSubmit={onEditFormSubmit}
        toggleInformTeam={toggleInformTeam}
        toggleResetAttendance={toggleResetAttendance}
        informTeamParams={informTeamParams}
      />
    </>
  );
};

export default TrainingEventModal;
