import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'semantic-ui-react';
import { Link, useParams } from 'react-router-dom';
import { OrderOutdatedModalProps } from './OrderOutdatedModalProps';
import { TEAM_ORDERS_ROUTE } from '../../../../constants/routes';

const OrderOutdatedModal = ({ orderOutdated, id, deleteOrders }: OrderOutdatedModalProps) => {
  const [translate] = useTranslation();
  const { teamId } = useParams<{ teamId: string }>();

  const confirmDelete = () => {
    if (id) {
      deleteOrders([id]);
    }
  };

  return (
    <Modal open={orderOutdated} size="tiny">
      <Modal.Content>
        {translate('IN_THE_MEANTIME_ESSENTIAL_PRODUCT_DATA_HAS_CHANGED_ON_THE_PART_OF_11TEAMSPORT')}
      </Modal.Content>
      <Modal.Actions>
        <Button as={Link} content={translate('DELETE_ORDER')} className="primary" onClick={confirmDelete} to={TEAM_ORDERS_ROUTE.replace(':teamId', teamId as string)} />
      </Modal.Actions>
    </Modal>
  );
};

export default OrderOutdatedModal;
