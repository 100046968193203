import { Column } from '../../../../components/Table/Column';

export default [{
  width: '60%',
  header: 'MATCH',
  align: 'left',
  sortable: false,
  key: 'match',
}, {
  width: '40%',
  header: 'DATE',
  align: 'left',
  sortable: false,
  key: 'date',
}] as Column[];
