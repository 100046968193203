import React, { FC } from 'react';
import { Button, Header } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import AuthenticationFormsWrapper from '../../components/AuthenticationFormsWrapper';
import { Page404Props } from './Page404Props';

const Page404: FC<Page404Props> = ({ signOut, history, clubLogoUrl }) => {
  const [translate] = useTranslation();

  const onClick = () => {
    signOut();
    history.push('/');
  };

  return (
    <AuthenticationFormsWrapper clubLogoUrl={clubLogoUrl}>
      <Header
        content={translate('PAGE_NOT_FOUND')}
        textAlign="center"
      />

      <br />

      <Button
        basic
        className="simple"
        content={translate('CANCEL')}
        icon="caret left"
        primary
        onClick={onClick}
      />
    </AuthenticationFormsWrapper>
  );
};

export default Page404;
