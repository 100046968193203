import moment, { MomentInput } from 'moment';
import { createMoment } from '../containers/Calendar/methods/TimePickerUtil';
import { EN } from '../constants/customizations';

export const DATE_FORMAT = 'DD.MM.YYYY';
export const TIME_FORMAT = 'HH:mm';

export const formatDate = (date: MomentInput) => (
  date ? createMoment(date).format(DATE_FORMAT) : ''
);

export const formatTime = (date: MomentInput) => (
  date ? moment(date, TIME_FORMAT).locale(EN).format(TIME_FORMAT) : ''
);
