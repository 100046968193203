import React, { useState, useEffect, useMemo } from 'react';
import get from 'lodash/get';
import set from 'lodash/set';
import {
  Button, Input, Menu,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ColorSelector from '../ColorSelector';
import HeaderComponent from '../../../../components/Header';
import LabelInputField from '../../../../components/LabelInputField';
import LogoSection from '../LogoSection';
import serializeClubEdit from '../../../../utils/serializers/serializeClubEdit';
import styles from './SettingsEdit.module.scss';
import { Club, ClubEditRequest } from '../../../../models/Club';
import { SETTINGS_ROUTE } from '../../../../constants/routes';
import { UpdateClubRequestAction } from '../../../../models/actions';

interface SettingsEditProps {
  club: Club;
  updateClub: (club: ClubEditRequest, clubId: number) => UpdateClubRequestAction;
}

const SettingsEdit = ({ club, updateClub }: SettingsEditProps) => {
  const [translate] = useTranslation();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [editedClub, setEditedClub] = useState<Club>(club);
  const requiredFields = useMemo(() => ['address.street', 'address.city', 'address.number', 'address.postCode', 'name', 'email'], []);

  useEffect(() => {
    setIsFormValid(!requiredFields.filter(item => !get(editedClub, item)).length);
  }, [editedClub, requiredFields]);

  const onSubmit = () => {
    setIsSubmitted(true);
    if (isFormValid) {
      updateClub(serializeClubEdit(editedClub), club.id);
    }
  };

  const renderButton = () => [
    <Button primary as={Link} to={SETTINGS_ROUTE.replace(':clubId', String(club.id))} content={translate('CANCEL')} key="cancel" />,
    <Button primary onClick={onSubmit} content={translate('SAVE')} key="save" />,
  ];

  const setProperty = (property: string) => (
    { target: { value } }: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setEditedClub({
      ...set(editedClub, property, value),
    });
  };

  const setColor = (primaryColor: string) => {
    setEditedClub({
      ...editedClub,
      primaryColor,
    });
  };

  const renderInput = (property: string, icon?: string, required?: boolean) => () => (
    <div className={styles.fieldWrapper}>
      <Input
        className={styles.input}
        onChange={setProperty(property)}
        value={get(editedClub, property) || ''}
        icon={icon || undefined}
        error={isSubmitted && required && !get(editedClub, property)}
      />
      {isSubmitted && required && !get(editedClub, property) && (
        <span className={styles.validation}>
          {translate('REQUIRED')}
        </span>
      )}
    </div>
  );

  return (
    <>
      <HeaderComponent title={translate('SETTINGS')} renderButton={renderButton} />
      <div className={styles.wrapper}>
        <Menu className="filter" pointing secondary>
          <Menu.Item
            name={translate('CLUB_INFO')}
            key="club-info"
            active
          />
        </Menu>
        <h3 className={styles.header}>{translate('BASIC_DATA')}</h3>
        <div className={styles.layout}>
          <div className={styles.left}>
            <LabelInputField label={translate('CLUB_NAME')} input={renderInput('name', '', true)} required />
            <LabelInputField label={translate('STREET')} input={renderInput('address.street', '', true)} required />
            <LabelInputField label={translate('NUMBER')} input={renderInput('address.number', '', true)} required />
            <LabelInputField label={translate('CITY')} input={renderInput('address.city', '', true)} required />
            <LabelInputField label={translate('ZIP_CODE')} input={renderInput('address.postCode', '', true)} required />
            <LabelInputField label={translate('PHONE_NUMBER')} input={renderInput('phoneNumber')} />
            <LabelInputField label={translate('EMAIL')} input={renderInput('email', '', true)} required />
          </div>
          <div className={styles.right}>
            <LogoSection />
            <LabelInputField label={translate('FAX_NUMBER')} input={renderInput('faxNumber')} />
            <LabelInputField label={translate('WEBSITE')} input={renderInput('websiteAddress')} />
          </div>
        </div>
        <div className={styles.field}>
          <span className={styles.label}>
            {translate('PRIMARY_COLOR')}
          </span>
          <ColorSelector
            value={editedClub.primaryColor}
            onChange={setColor}
            placeholder=""
          />
        </div>
        <div className={styles.field}>
          <span className={styles.label}>{translate('SOCIAL_MEDIA')}</span>
          <div className={styles.media}>
            <Input
              icon="facebook f"
              className={styles.input}
              onChange={setProperty('socialMedia.facebookUrl')}
              value={(editedClub && editedClub.socialMedia && editedClub.socialMedia.facebookUrl) || ''}
            />
            <Input
              icon="instagram"
              className={styles.input}
              onChange={setProperty('socialMedia.instagramUrl')}
              value={(editedClub && editedClub.socialMedia && editedClub.socialMedia.instagramUrl) || ''}
            />
            <Input
              icon="twitter"
              className={styles.input}
              onChange={setProperty('socialMedia.snapchatUrl')}
              value={(editedClub && editedClub.socialMedia && editedClub.socialMedia.snapchatUrl) || ''}
            />
          </div>
        </div>
        <h3 className={styles.header}>{translate('CURRENT')}</h3>
        <div className={styles.layout}>
          <div className={styles.left}>
            <LabelInputField label={translate('WEBSITE_EVENTS_ADDRESS')} input={renderInput('websiteEventsAddress')} />
            <LabelInputField label={translate('WEBSITE_NEWS_ADDRESS')} input={renderInput('websiteNewsAddress')} />
          </div>
          <div className={styles.right} />
        </div>
      </div>
    </>
  );
};

export default SettingsEdit;
