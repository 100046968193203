import React, { useState, useEffect } from 'react';
import flatten from 'lodash/flatten';
import { Button, Icon } from 'semantic-ui-react';
import styles from './Row.module.scss';
import ExpandableWrapper from '../ExpandableWrapper';
import { Consumer } from '../../Table';
import { RowProps } from './RowProps';
import { Column } from '../../Column';

const Row = ({
  children,
  itemOnClick,
  onExpand,
  expandable,
  classNames,
  expanded,
  inactive,
}: RowProps) => {
  const [isExpanded, setIsExpanded] = useState(expanded);

  useEffect(() => {
    if (isExpanded) {
      // @ts-ignore
      onExpand();
    }
  }, [isExpanded, onExpand]);

  const expandOnClick = (event: MouseEvent) => {
    event.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const expandSuffix = (child: JSX.Element|JSX.Element[], index: number, columns: Column[]) => (
    <div
      className={[
        styles.column,
        styles['action-justify'],
        styles[`flex-${columns[index].size}`],
      ].join(' ')}
    >
      {child}
      {/*
      // @ts-ignore */}
      <Button basic className="simple" onClick={expandOnClick} icon>
        {/*
        // @ts-ignore */}
        <Icon name={`chevron ${isExpanded ? 'up' : 'down'}` as string} />
      </Button>
    </div>
  );

  const expandedContent = () => {
    const lastChild = (children as JSX.Element[])[(children as JSX.Element[]).length - 1];
    const shouldRenderExpandableWrapper = isExpanded && lastChild.type === ExpandableWrapper;

    return shouldRenderExpandableWrapper && (
      <div
        key="extendedContent"
        className={styles.extendedContent}
        role="button"
        tabIndex={0}
        onClick={e => e.stopPropagation()}
        onKeyPress={e => e.stopPropagation()}
      >
        {lastChild}
      </div>
    );
  };

  const commonColumn = (child: JSX.Element | JSX.Element[], index: number, columns: Column[]) => (
    columns[index] && (
      <div
        key={`${columns[index].header}${index}`}
        className={[
          styles.column,
          styles[`align-content-${columns[index].align}`],
          columns[index].size && styles[`flex-${columns[index].size}`],
          columns[index].noPadding && styles['no-padding'],
        ].join(' ')}
        style={{
          ...(columns[index].width ? ({ width: `${columns[index].width}` }) : ''),
        }}
      >
        {child}
      </div>
    ));

  const detectAdditionalRowOptions = (index: number) => (
    index === (children as JSX.Element[]).length - 2 && expandable ? expandSuffix : commonColumn
  );

  return (
    <Consumer>
      {columns => (
        <div
          // @ts-ignore
          onClick={itemOnClick}
          // @ts-ignore
          onKeyPress={itemOnClick}
          role="button"
          tabIndex={0}
          className={[
            styles.row,
            itemOnClick ? styles.clickable : null,
            inactive ? styles.inactive : null,
            classNames,
          ].join(' ')}
        >
          {Array.isArray(children) ? flatten(children).filter(Boolean).map((child, index) => (
            // @ts-ignore
            detectAdditionalRowOptions(index)(child, index, columns)
          // @ts-ignore
          )) : commonColumn(children, 0, columns)}
          {expandedContent()}
        </div>
      )}
    </Consumer>
  );
};

Row.defaultProps = {
  itemOnClick: null,
  expandable: false,
  onExpand: () => {},
  classNames: '',
  expanded: false,
  inactive: false,
};

export default Row;
