import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Form, Message, Header,
} from 'semantic-ui-react';

import HeaderComponent from '../../components/Header';
import FormFields from '../../components/FormFields';
import styles from './PlayerEdit.module.scss';
import useForm from '../../hooks/useForm';
import { PLAYER_PROFILE_ROUTE } from '../../constants/routes';
import { PlayerEditProps } from './PlayerEditProps';
import {
  contactSchema, emergencyContactSchema, generalSchema, infoSchema, sizesSchema, validationSchema,
} from './schema';

const PlayerEdit = ({
  error, fetchPlayer, fetchTeam, loading, match, player,
  updatePlayer, lockedNumbers, usedNumbers,
}: PlayerEditProps) => {
  const [translate] = useTranslation();
  const { params: { teamId, playerId } } = match;
  const {
    firstName = '',
    lastName = '',
    nickname = '',
    teamNumber = '',
    number = '',
  } = player || {};

  const {
    errors,
    setValues,
    values,
    handleChange,
    validate,
  } = useForm(validationSchema, player);

  const handleSubmit = () => {
    if (validate()) {
      updatePlayer(+teamId, +playerId, values);
    }
  };

  useEffect(() => {
    if (!Object.keys(values).length && player) {
      setValues(player as any);
    }
  }, [setValues, values, player]);

  useEffect(() => {
    fetchPlayer(Number(teamId), Number(playerId));
    fetchTeam(Number(teamId));
  }, [fetchPlayer, fetchTeam, teamId, playerId]);

  const renderButton = () => (
    <Button
      content={translate('SAVE')}
      disabled={loading}
      form="create-form"
      primary
      type="submit"
    />
  );

  const availableNumbers = useMemo(() => [...Array(100).keys()]
    .filter(num => !lockedNumbers.includes(num)
        && (!usedNumbers[num] || usedNumbers[num] === +playerId))
    .map(num => ({
      key: num,
      value: num,
      text: num,
    })), [lockedNumbers, usedNumbers, playerId]);

  return (
    <div className={styles.wrapper}>
      <HeaderComponent
        backLinkTo={PLAYER_PROFILE_ROUTE
          .replace(':teamId', String(teamId))
          .replace(':playerId', String(playerId))
        }
        renderButton={renderButton}
        title={`${translate('EDIT_PLAYER')} - ${firstName} ${lastName} ${nickname ? `(${nickname})` : ''}`}
      />

      <div className={styles.container}>
        <Form onSubmit={handleSubmit} loading={loading} className={styles.form} id="create-form">
          {error && (
            <Message error={error} header={translate('ERROR')} content={translate('GENERAL_ERROR')} />
          )}

          <FormFields
            schema={infoSchema}
            errors={errors}
            handleChange={handleChange}
            values={values}
            inline
          />

          <Header content={translate('GENERAL_INFO.TITLE')} as="h3" />

          <Form.Field
            className={[styles.formField, styles.formFieldInline].join(' ')}
            key="teamNumber"
          >
            <label>
              {translate('GENERAL_INFO.TEAMS_SHIRT_NUMBER')}
            </label>
            <div className={styles.inputWithLabel}>
              <Form.Dropdown
                clearable
                name="teamNumber"
                text={values.teamNumber || teamNumber ? `${values.teamNumber || teamNumber}` : ((number && `${number} (${translate('PLAYERS_FAVOURITE')})`) || '')}
                onChange={handleChange}
                onClick={handleChange}
                className={[(!values.teamNumber && !teamNumber) ? styles.italicText : ''].join(' ')}
                options={availableNumbers}
                selection
                placeholder=""
                value={values.teamNumber || teamNumber || number || ''}
              />
            </div>
          </Form.Field>

          <FormFields
            schema={generalSchema}
            errors={errors}
            handleChange={handleChange}
            values={values}
            inline
          />

          <Header content={translate('SIZES.TITLE')} as="h3" />

          <FormFields
            schema={sizesSchema}
            errors={errors}
            handleChange={handleChange}
            values={values}
            inline
          />

          <Header content={translate('CONTACT_INFO.TITLE')} as="h3" />

          <FormFields
            schema={contactSchema}
            errors={errors}
            handleChange={handleChange}
            values={values}
            inline
          />

          <Header content={translate('EMERGENCY_CONTACT.TITLE')} as="h3" />

          <FormFields
            schema={emergencyContactSchema}
            errors={errors}
            handleChange={handleChange}
            values={values}
            inline
          />
        </Form>
      </div>
    </div>
  );
};

export default PlayerEdit;
