import { AjaxError } from 'rxjs/ajax';
import {
  SET_TOR24_TEAMS_ERROR,
  SET_TOR24_TEAMS_REQUEST,
  SET_TOR24_TEAMS_SUCCESS,
} from '../../constants/actionTypes';
import {
  SetTor24TeamsErrorAction,
  SetTor24TeamsRequestAction,
  SetTor24TeamsSuccessAction,
} from '../../models/actions';
import { ExternalSystemReference } from '../../models/externalSystemIntegration';

export const setTor24TeamsRequest = (
  tor24TeamId: string,
  teamId: string,
): SetTor24TeamsRequestAction => ({
  type: SET_TOR24_TEAMS_REQUEST,
  payload: {
    tor24TeamId,
    teamId,
  },
});

export const setTor24TeamsSuccess = (
  externalRefs: { response: ExternalSystemReference[] },
): SetTor24TeamsSuccessAction => ({
  type: SET_TOR24_TEAMS_SUCCESS,
  payload: {
    externalRefs,
  },
});

export const setTor24TeamsError = (error: AjaxError): SetTor24TeamsErrorAction => ({
  type: SET_TOR24_TEAMS_ERROR,
  payload: {
    error,
  },
});
