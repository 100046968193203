import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import SelectPlayersModal from './SelectPlayersModal';
import { State } from '../../../../models/State';
import { fetchPlayersRequest } from '../../../../actions/players';
import { fetchStaffRequest } from '../../../../actions/staff';

const mapStatesToProps = ({
  players: {
    byHash, byId, count, loading,
  },
  staff: {
    byId: staffById, byHash: staffByHash,
  },
}: State) => ({
  loading,
  playersByHash: byHash,
  playersById: byId,
  playersCount: count,
  staffById,
  staffByHash,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchPlayers: bindActionCreators(fetchPlayersRequest, dispatch),
  fetchStaff: bindActionCreators(fetchStaffRequest, dispatch),
});

export default connect(mapStatesToProps, mapDispatchToProps)(SelectPlayersModal);
