import React, { useState, FC, useEffect } from 'react';
import includes from 'lodash/includes';
import partition from 'lodash/partition';
import xor from 'lodash/xor';
import { Dimmer, Loader, Divider } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';

import { history } from '../../configureStore';
import Header from '../../components/Header';
import ProductDescription from './components/ProductDescription';
import ProductDetailsPrice from './components/ProductDetailsPrice';
import ProductEmbellishments from './components/ProductEmbellishments';
import ProductsImages from './components/ProductImages';
import styles from './ProductDetails.module.scss';
import { CATALOG_ROUTE } from '../../constants/routes';
import params from '../../utils/params';
import { IMAGES_EXTENSIONS } from '../../constants/customizations';
import { ProductDetailsProps } from './ProductDetailsProps';
import ChipComponent from '../../components/ChipComponent';
import { UrlParams } from '../../constants/urlParams';

const ProductDetails: FC<ProductDetailsProps> = ({
  clubId,
  embellishmentsById,
  allTags,
  loadingTags,
  teams,
  fetchCatalog,
  fetchEmbellishments,
  fetchTeams,
  fetchTags,
  loading,
  product,
}) => {
  const { getParams } = params(history);
  const { [UrlParams.ACTIVE_TEAM]: activeTeam } = getParams();
  const [translate] = useTranslation();
  const [tagIds, setTagIds] = useState((product && product.tagIds) || []);
  const [teamIds, setTeamIds] = useState((product && product.exclusiveToTeams) || []);
  const activeTeamQueryParam = queryString.stringify({ [UrlParams.ACTIVE_TEAM]: activeTeam });
  const handleTagClick = (tagId: number) => setTagIds(xor(tagIds, [tagId]));
  const handleTeamClick = (teamId: number) => setTeamIds(xor(teamIds, [teamId]));
  const backLink = `${CATALOG_ROUTE.replace(':clubId', String(clubId))}${activeTeam ? `?${activeTeamQueryParam}` : ''}`;

  const {
    files: productFiles = [],
    name = '',
    originalImages = [],
    description = '',
    discountPercent = 0,
    embellishments = [],
    originalPrice = null,
  } = product || {};

  const [images] = partition(
    productFiles, ({ extension }) => includes(IMAGES_EXTENSIONS, extension.toLowerCase()),
  );

  useEffect(() => {
    if (product && product.tagIds) {
      setTagIds(product.tagIds);
    }
    if (product && product.exclusiveToTeams) {
      setTeamIds(product.exclusiveToTeams);
    }
  }, [product, setTagIds, setTeamIds]);

  useEffect(() => { fetchTags(); }, [fetchTags]);

  useEffect(() => {
    if (!product && activeTeam) {
      fetchCatalog(+activeTeam);
    }
  }, [fetchCatalog, product, activeTeam]);

  useEffect(() => {
    if (!embellishmentsById.length) {
      fetchEmbellishments();
    }
  }, [fetchEmbellishments, embellishmentsById]);

  useEffect(() => {
    if (clubId) {
      fetchTeams({ pagination: false });
    }
  }, [fetchTeams, clubId]);

  return product ? (
    <div className={styles.wrapper}>
      <Header
        title={name}
        backLinkTo={backLink}
        backLabel="TO_THE_CLUB_CATALOG"
      />
      <div className={styles.contentWrapper}>
        <div>
          <ProductsImages
            clubId={clubId}
            images={images}
            originalImages={originalImages}
          />

          <ProductDescription
            content={description}
          />

          <ChipComponent
            header="PROPERTIES"
            entities={allTags}
            selectedEntities={tagIds}
            clickable={false}
            onClick={handleTagClick}
            loading={loadingTags}
            noEntriesText="NO_TAGS"
            displayOnlyActive
          />

          <ChipComponent
            header="TEAMS"
            entities={teams || []}
            selectedEntities={teamIds}
            clickable={false}
            onClick={handleTeamClick}
            noEntriesText="NO_TEAMS_ASSIGNED"
            displayOnlyActive
          />

          <Divider />

          {!!embellishmentsById.length && clubId && (
            <ProductEmbellishments
              embellishments={product && product.embellishments}
              clubId={clubId}
            />
          )}
        </div>
        <div>
          <ProductDetailsPrice
            clubId={clubId}
            discountPercent={discountPercent}
            originalPrice={originalPrice}
            embellishments={embellishments}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.wrapper}>
      {loading
        ? <Dimmer active inverted><Loader /></Dimmer>
        : <Header title={translate('PRODUCT.NOT_FOUND')} backLinkTo={backLink} />
      }
    </div>
  );
};

export default ProductDetails;
