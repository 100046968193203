import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { State } from '../../models/State';

import TeamSelectComponent from './TeamSelectComponent';

const mapStatesToProps = ({
  teams: {
    activeTeams,
  },
}: State) => ({
  activeTeams,
});

export default connect(mapStatesToProps, null)(withRouter(TeamSelectComponent));
