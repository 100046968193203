import React, { FC } from 'react';
import {
  Button, Header,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import Confirm from '../../../../components/Confirm';
import getMissingProperties from '../../methods/getMissingProperties';
import styles from './DesktopOrderHeader.module.scss';
import { DesktopHeaderProps } from './DesktopOrderHeaderProps';
import { OrderStatus } from '../../../../constants/orderStatus';
import { CLUB_ORDERS_ROUTE, ORDER_ROUTE, TEAM_ORDERS_ROUTE } from '../../../../constants/routes';
import { formatPrice } from '../../../../utils/PricingUtil';
import { Roles } from '../../../../constants/roles';

const DesktopOrderHeader: FC<DesktopHeaderProps> = ({
  changeStatus,
  deleteOrders,
  draft,
  history,
  initializeOrder,
  itemsByHash,
  itemsById,
  loading,
  orderId,
  orderPrice,
  orderStatus,
  productsByHash = {},
  teamId,
  upsertOrder,
  userRole,
}) => {
  const [translate] = useTranslation();
  const save = () => upsertOrder(
    teamId,
    (upsertedOrderId?: number) => ORDER_ROUTE.replace(':teamId', String(teamId)).replace(':orderId', String(upsertedOrderId)),
  );

  const handleDelete = () => {
    if (orderId) {
      deleteOrders([orderId]);
    }
    history.push(TEAM_ORDERS_ROUTE);
  };

  const handleClearOrder = () => initializeOrder(teamId, orderId);

  const changeOrderStatus = () => changeStatus(
    teamId,
    orderStatus === OrderStatus.SENT_TO_TEAM
      ? OrderStatus.SENT_TO_CLUB_ADMIN
      : OrderStatus.SENT_TO_TEAM,
  );

  const items = itemsById.map(id => itemsByHash[id]);
  const orderInvalid = !!getMissingProperties(items, productsByHash).length;
  const orderIsEmpty = !itemsById.length;
  const title = `${translate('ORDER')} ${orderId ? `#${orderId}` : ''}`;
  const backLinkClick = () => {
    if (orderId) {
      history.push(userRole === Roles.CLUB_ADMIN ? CLUB_ORDERS_ROUTE : TEAM_ORDERS_ROUTE.replace(':teamId', `${teamId}`));
    } else {
      history.goBack();
    }
  };

  const DesktopActionButtons = () => (
    <div className={styles.buttonsGroup}>
      <Button
        basic
        className="simple"
        content={translate(orderId ? 'BACK_TO_ORDERS' : 'BACK')}
        icon="caret left"
        primary
        onClick={backLinkClick}
      />
      <div className={styles.buttons}>
        {draft && orderId ? (
          <Button
            basic
            content={translate('CLEAR_ORDER')}
            loading={loading}
            onClick={handleClearOrder}
            primary
          />
        ) : (
          <Confirm
            basic
            confirmText={translate('ARE_YOU_SURE_TO_DELETE_ORDER')}
            onConfirm={handleDelete}
            primary
            triggerText={translate('DELETE_ORDER')}
          />
        )}
        <Confirm
          confirmText={translate(orderStatus === OrderStatus.SENT_TO_TEAM ? 'SEND_TO_CLUB_ADMIN_CONFIRMATION' : 'SEND_TO_THE_TEAM_CONFIRMATION')}
          onConfirm={changeOrderStatus}
          primary
          disabled={(orderStatus === OrderStatus.SENT_TO_TEAM && orderInvalid) || orderIsEmpty}
          triggerText={translate(orderStatus === OrderStatus.SENT_TO_TEAM ? 'SEND_TO_CLUB_ADMIN' : 'SEND_TO_THE_TEAM')}
        />
        <Button onClick={save} circular icon="save" primary size="large" disabled={orderIsEmpty} />
      </div>
    </div>
  );

  return (
    <div className={styles.wrapper}>
      <DesktopActionButtons />
      <div className={styles.textGroup}>
        <div className={styles.header}>
          <Header type="h2" content={title} />
          <p className={styles.subtitle}>{orderId ? translate(OrderStatus[orderStatus]) : ''}</p>
        </div>
        <div className={styles.priceWrapper}>
          {!!(orderPrice && orderPrice.amount) && (
            <div className={styles.total}>
              <p className={styles.subtitle}>{translate('TOTAL')}</p>
              <Header type="h2" content={formatPrice(orderPrice.amount, orderPrice.currency)} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DesktopOrderHeader;
