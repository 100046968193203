import { UploadFile } from '../../models/File';

export enum FileUploadTypes {
  IMAGE = 'IMAGE',
  FILE = 'FILE',
  XLSX = 'XLSX',
}

export interface FileUploadModalProps {
  loading: boolean;
  uploadFile: (image: File) => void;
  triggerButton?: (onClick: () => void) => JSX.Element;
  secondaryAction?: () => JSX.Element;
  type: FileUploadTypes;
  filesToSelect?: UploadFile[];
  selectFile?: (id: string, originalFilename: string, extension: string) => void;
  aspect?: number;
  extensions?: string[];
  requestPreview?: (file: File) => void;
  previewNode?: () => JSX.Element;
  error?: boolean;
}
