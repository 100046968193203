import React, { FC } from 'react';
import { Image } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import FileUploadModal, { FileUploadTypes } from '../../../../components/FileUploadModal';
import styles from './LogoSection.module.scss';
import { UploadClubLogoRequestAction } from '../../../../models/actions';

interface LogoSectionProps {
  loadingUpload: boolean;
  logoUrl: string | null;
  uploadClubLogo: (image: File) => UploadClubLogoRequestAction;
}

const LogoSection: FC<LogoSectionProps> = ({ logoUrl, uploadClubLogo, loadingUpload }) => {
  const [translate] = useTranslation();

  return (
    <div className={styles.logoSection}>
      <span className={styles.label}>{translate('LOGO')}</span>
      <div className={styles.valueSection}>
        {logoUrl && (
          <Image src={logoUrl} size="small" alt="" className={styles.image} />
        )}
        <FileUploadModal
          loading={loadingUpload}
          uploadFile={uploadClubLogo}
          type={FileUploadTypes.IMAGE}
        />
      </div>
    </div>
  );
};

export default LogoSection;
