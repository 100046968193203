import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import UpdatePasswordForm from './UpdatePasswordForm';
import { resetPasswordRequest } from '../../../../packages/authentication';
import { State } from '../../../../models/State';

const mapStateToProps = ({ authentication }: State) => ({
  errorMessage: authentication.resetPasswordErrorMessage,
  loading: authentication.resetPasswordLoading,
  success: authentication.resetPasswordSuccess,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  resetPasswordRequest,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePasswordForm);
