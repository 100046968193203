import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Form, Modal,
} from 'semantic-ui-react';
import { ChangeTeamSeasonPopupProps } from './ChangeTeamSeasonPopupProps';
import styles from './ChangeTeamSeasonPopup.module.scss';
import useForm from '../../../../hooks/useForm';
import { changeTeamSeasonSchema } from './schema';
import FormFields from '../../../../components/FormFields';

const ChangeTeamSeasonPopup: FC<ChangeTeamSeasonPopupProps> = ({
  teamId, teamName, closePopup, isOpen, teamSeasonTransfer, switchingSeason, switchSeasonSuccess,
}) => {
  const [translate] = useTranslation();
  const [valid, setIsValid] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const {
    values,
    errors,
    handleChange,
  } = useForm(changeTeamSeasonSchema);

  useEffect(() => {
    if (!switchingSeason && switchSeasonSuccess && submitted) {
      window.location.reload();
    }
  }, [switchingSeason, switchSeasonSuccess, closePopup, submitted]);

  useEffect(() => {
    setIsValid(
      !!values.season
          && !!values.playerLineUpTransferOption
          && !!values.treasurerTransferOption
          && !!values.teamLeadTransferOption,
    );
  }, [values]);

  const save = useCallback(() => {
    setSubmitted(true);
    teamSeasonTransfer(
      teamId,
      values.season,
      values.playerLineUpTransferOption,
      values.treasurerTransferOption,
      values.teamLeadTransferOption,
    );
  }, [
    values,
    teamId,
    teamSeasonTransfer,
  ]);

  return (
    <Modal
      open={isOpen}
      onClose={closePopup}
      closeIcon
      closeOnDimmerClick={false}
    >
      <Modal.Header content={translate('CHANGE_SEASON_FOR', { teamName })} />
      <Modal.Content>
        <Form className={styles.modalContentWrapper} onSubmit={save}>
          <FormFields
            schema={changeTeamSeasonSchema}
            errors={errors}
            values={values}
            handleChange={handleChange}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button basic onClick={closePopup}>{translate('CANCEL')}</Button>
        <Button
          primary
          disabled={!valid}
          onClick={() => save()}
        >
          {translate('SAVE')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ChangeTeamSeasonPopup;
