import get from 'lodash/get';
import { ActionsObservable, StateObservable, ofType } from 'redux-observable';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';

import { TOKEN_DETAILS } from '../../constants/paths';
import { Response } from '../../models/Response';
import { State } from '../../models/State';
import { fetchTokenDetailsSuccess, fetchTokenDetailsError } from '../../actions/application';
import { Token } from '../../models/Token';
import { FETCH_TOKEN_DETAILS_ERROR, FETCH_TOKEN_DETAILS_REQUEST } from '../../constants/actionTypes';
import { FetchTokenDetailsRequestAction } from '../../models/actions';
import { handleError } from '../../actions/errors';
import { fetchClubLogoRequest } from '../../actions/clubs';

export default (
  action$: ActionsObservable<FetchTokenDetailsRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(FETCH_TOKEN_DETAILS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{ payload: { token } }, { authentication: { authToken } }]) => ajax({
    url: TOKEN_DETAILS.replace('$token', token),
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
  }).pipe(
    mergeMap(({ response }: Response<Token>) => [
      fetchClubLogoRequest(get(response, 'club.id')),
      fetchTokenDetailsSuccess(response),
    ]),
    catchError(error => [
      handleError(error, FETCH_TOKEN_DETAILS_ERROR),
      fetchTokenDetailsError(error),
    ]),
  )),
);
