import { ActionsObservable } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

import { FETCH_CATALOG_SUCCESS, FETCH_ORDER_SUCCESS } from '../../constants/actionTypes';
import { OrderStatus } from '../../constants/orderStatus';
import { Catalog } from '../../models/Catalog';
import { Order } from '../../models/Order';
import { invalidCatalog } from '../../actions/catalogs';

const catalogValidation = (catalog: Catalog, order: Order) => (
  order.status !== OrderStatus.SENT_TO_SALES_REP && order.createdAt && catalog && catalog.updatedAt
    && new Date(order.createdAt).getTime() < catalog.updatedAt.getTime()
);

export default (
  action$: ActionsObservable<any>,
) => combineLatest(
  action$.ofType(FETCH_ORDER_SUCCESS),
  action$.ofType(FETCH_CATALOG_SUCCESS),
).pipe(
  mergeMap(([{ payload: { order } }, { payload: { catalog } }]) => [
    ...[catalogValidation(catalog, order) && invalidCatalog()].filter(Boolean),
  ]),
);
