import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Confirm } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import FileUploadModal, { FileUploadTypes } from '../../components/FileUploadModal';
import HeaderComponent from '../../components/Header';
import LabelValueField from '../../components/LabelValueField';
import styles from './Profile.module.scss';
import { PROFILE_EDIT_ROUTE } from '../../constants/routes';
import { UploadAvatarRequestAction } from '../../models/actions';
import { User } from '../../models/general/User';
import ProfileAvatar from '../PlayerProfile/components/ProfileAvatar';

interface ProfileProps {
  loadingUpload: boolean;
  resetPasswordToken: (username: string) => {};
  uploadAvatar: (image: File) => UploadAvatarRequestAction;
  user: User | null;
}

const Profile: FC<ProfileProps> = ({
  uploadAvatar, user, resetPasswordToken, loadingUpload,
}) => {
  const [translate] = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const setIsOpen = (value: boolean) => () => {
    setOpen(value);
  };

  const renderButton = () => (
    <Button primary as={Link} to={PROFILE_EDIT_ROUTE}>{translate('EDIT')}</Button>
  );

  const onConfirm = () => {
    resetPasswordToken(user ? user.username : '');
    setOpen(false);
  };

  return user && (
    <>
      <HeaderComponent title={translate('USER_SETTINGS')} renderButton={renderButton} border />
      <div className={styles.container}>
        <div className={styles.content}>
          <LabelValueField label={translate('FIRST_NAME')} value={user.firstName} />
          <LabelValueField label={translate('LAST_NAME')} value={user.lastName} />
          <LabelValueField label={translate('EMAIL')} value={user.email} />
          <div className={styles.field}>
            <span className={styles.label}>{translate('PASSWORD.PASSWORD')}</span>
            <Button className="simple" basic primary onClick={setIsOpen(true)}>{translate('RESET_PASSWORD')}</Button>
          </div>
        </div>
        <div className={styles.uploadAvatar}>
          <ProfileAvatar user={user} size="large" />
          <FileUploadModal
            loading={loadingUpload}
            type={FileUploadTypes.IMAGE}
            uploadFile={uploadAvatar}
            aspect={1}
          />
        </div>
      </div>
      <Confirm
        open={open}
        onCancel={setIsOpen(false)}
        onConfirm={onConfirm}
        cancelButton={translate('CANCEL')}
        confirmButton={translate('CONFIRM')}
        content={translate('WE_WILL_SEND_YOU_THE_RESET_PASSWORD_EMAIL')}
      />
    </>
  );
};

export default Profile;
