import React, { MouseEvent } from 'react';
import { Button, Checkbox, Label } from 'semantic-ui-react';

import Row from '../../../../components/Table/components/Row';
import Text from '../../../../components/Text';
import getMissingProperties from '../../methods/getMissingProperties';
import styles from './MobileMemberRow.module.scss';
import { MobileMemberRowProps } from './MobileMemberRowProps';
import { OrderStatus } from '../../../../constants/orderStatus';
import { OrderItemType } from '../../OrderPreparationProps';

const MobileMemberRow = ({
  member: {
    id, firstName, lastName,
  },
  checked,
  togglePlayerSelection,
  toggleStaffSelection,
  items,
  teamId,
  showDetails,
  productsByHash,
  orderStatus,
  itemType,
}: MobileMemberRowProps) => {
  const isTypePlayer = itemType === OrderItemType.PLAYER;

  const onClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (isTypePlayer) {
      togglePlayerSelection(id, teamId);
    } else {
      toggleStaffSelection(id, teamId);
    }
  };

  const missingProperties = orderStatus === OrderStatus.SENT_TO_TEAM
    && !!getMissingProperties(items, productsByHash).length;

  return (
    <Row key={id} classNames={[styles.wrapper, missingProperties ? styles.danger : ''].join(' ')} itemOnClick={showDetails}>
      <div className={styles.teamMember}>
        <Checkbox onClick={onClick} checked={checked} />
        <Text>
          {`${firstName} ${lastName}`}
        </Text>
      </div>
      <div className={styles.rowActionsWrapper}>
        <div className={[styles.icons, styles.badgeMobile].join(' ')}>
          {!!items.length && <Label circular className="badge">{items.length}</Label>}
        </div>
        <Button basic primary className="simple" icon="angle right" onClick={showDetails} />
      </div>
    </Row>
  );
};

export default MobileMemberRow;
