import { Column } from '../../../../components/Table/Column';

const columnsMobile: Column[] = [{
  size: 9,
  key: 'name',
  align: 'left',
}, {
  size: 2,
  key: 'menu',
  align: 'right',
}];

export default columnsMobile;
