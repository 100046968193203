import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { push } from 'connected-react-router';
import { AjaxError } from 'rxjs/ajax';
import { EditTeamSettingsRequestAction, NotificationType } from '../../models/actions';
import { State } from '../../models/State';
import {
  EDIT_TEAM_SETTINGS_ERROR,
  EDIT_TEAM_SETTINGS_REQUEST,
  EDIT_TEAM_SETTINGS_SUCCESS,
} from '../../constants/actionTypes';
import { EDIT_TEAM_SETTINGS } from '../../constants/paths';
import { addNotification } from '../../actions/notifications';
import {
  editTeamSettingsError, editTeamSettingsSuccess,
} from '../../actions/teams';
import { CLUB_TEAMS_MEMBERS_ROUTE } from '../../constants/routes';
import { handleError } from '../../actions/errors';

export default (
  action$: ActionsObservable<EditTeamSettingsRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(EDIT_TEAM_SETTINGS_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      teamId,
      season,
      seasonStartDate,
      seasonEndDate,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: EDIT_TEAM_SETTINGS.replace('$teamId', `${teamId}`),
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify({
      season,
      seasonStartDate,
      seasonEndDate,
    }),
  }).pipe(
    mergeMap(() => [
      addNotification(NotificationType.SUCCESS, EDIT_TEAM_SETTINGS_SUCCESS),
      editTeamSettingsSuccess(),
      push(CLUB_TEAMS_MEMBERS_ROUTE.replace(':teamId', `${teamId}`)),
    ].filter(Boolean)),
    catchError((error: AjaxError) => [
      handleError(error, EDIT_TEAM_SETTINGS_ERROR),
      editTeamSettingsError(error),
    ]),
  )),
);
