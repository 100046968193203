import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Icon, Menu } from 'semantic-ui-react';

import { Team } from '../../models/Team';
import { TeamsTabsProps } from './TeamsTabsProps';
import TeamStatus from '../../constants/teamStatus';
import styles from './TeamsTabs.module.scss';

const TeamsTabs: FC<TeamsTabsProps> = ({
  teams, activeTeam, onTabChange, route,
}) => {
  const sortedTeams = teams.sort((x, y) => (
    (x.status === TeamStatus.ACTIVE) === (y.status === TeamStatus.ACTIVE) ? 0 : 1
  ));

  const getlinkProps = (status: string, id: number) => (status === TeamStatus.ACTIVE ? {
    as: Link,
    to: route.replace(':teamId', String(id)),
  } : {});

  const tabs = sortedTeams.slice(0, 5);
  const dropdownOptions = teams.filter(team => !tabs.includes(team));

  return (
    <Menu className="filter" pointing secondary>
      {tabs.map(({ id, name, status }: Team) => (
        <Menu.Item
          content={name}
          active={id === activeTeam}
          key={id}
          onClick={onTabChange}
          disabled={status === TeamStatus.INACTIVE}
          {...getlinkProps(status, id)}
        />
      ))}
      {!!dropdownOptions.length && (
      <Dropdown
        item
        icon={<Icon name="ellipsis horizontal" size="large" className={styles.icon} />}
      >
        <Dropdown.Menu className={styles.menu}>
          {dropdownOptions.map(({ name, id, status }) => (
            <Dropdown.Item
              className={[styles.item, id === activeTeam && styles.selected].join(' ')}
              content={name}
              key={id}
              onClick={onTabChange}
              {...getlinkProps(status, id)}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
      )}
    </Menu>
  );
};

export default TeamsTabs;
