import { RouteComponentProps } from 'react-router';
import { Training, Match } from '../../../../models/Calendar';
import { CreateEventRequestAction, FetchTeamRequestAction, UpdateEventRequestAction } from '../../../../models/actions';
import { WeekDays } from '../../../../constants/weekDays';
import { EventInterval } from '../../../../constants/eventInterval';
import { TeamsByHash } from '../../../../models/Team';

export const NAME = 'name';
export const DAY = 'day';
export const INTERVAL = 'interval';
export const DESCRIPTION = 'description';
export const MEETING_TIME = 'meetingTime';
export const START_TIME = 'startTime';
export const END_TIME = 'endTime';
export const ADDRESS = 'address';

export interface TrainingEventModalProps extends RouteComponentProps {
  teamId: number;
  activeSlot?: Date;
  event: Training | Match | null;
  firstName: string | null;
  loading: boolean;
  teamsByHash: TeamsByHash;
  onClose: () => void;
  fetchTeam: (id: number) => FetchTeamRequestAction;
  createEvent: (training: Training) => CreateEventRequestAction;
  updateEvent: (training: Training) => UpdateEventRequestAction;
}

export interface TrainingFormSchema {
  [NAME]: string;
  [DAY]?: WeekDays | null;
  [INTERVAL]?: EventInterval | null;
  [DESCRIPTION]: string;
  [MEETING_TIME]: Date | null;
  [START_TIME]: Date | null;
  [END_TIME]: Date | null;
  [ADDRESS]: string;
}

export const TRAINING_FORM_DEFAULT_STATE = {
  [NAME]: '',
  [DAY]: null,
  [INTERVAL]: null,
  [DESCRIPTION]: '',
  [MEETING_TIME]: null,
  [START_TIME]: null,
  [END_TIME]: null,
  [ADDRESS]: '',
};
