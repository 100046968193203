import calculateEmbellishmentPrice from './calculateEmbellishmentPrice';
import { Catalog } from '../../models/Catalog';
import { Embellishment, ProductEmbellishment } from '../../models/Embellishment';
import { sumPrices } from '../PricingUtil';

const calculateEmbellishmentsPrice = (
  clubId: number,
  teamId: number | null,
  embellishments: Embellishment[],
  catalog?: Catalog,
  productEmbellishments?: ProductEmbellishment[],
) => embellishments.reduce((acc, embellishment) => {
  const embellishmentPrice = calculateEmbellishmentPrice(
    clubId, teamId, embellishment, catalog,
    productEmbellishments
      ? productEmbellishments.find(pe => pe.embellishment === embellishment.type)
      : null,
  );
  return sumPrices([acc, embellishmentPrice]);
}, 0);

export default calculateEmbellishmentsPrice;
