import { connect } from 'react-redux';
import MobileItemsList from './MobileItemsList';
import { State } from '../../../../../../models/State';

const mapStatesToProps = ({
  catalog: {
    catalog: {
      productsByHash,
    },
    embellishmentsByHash,
  },
  players,
  staff,
}: State) => ({
  embellishmentsByHash,
  productsByHash,
  playersByHash: players.byHash,
  staffByHash: staff.byHash,
  staffById: staff.byId,
});

export default connect(mapStatesToProps)(MobileItemsList);
