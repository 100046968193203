import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import styles from './DeleteData.module.scss';
import params from '../../utils/params';
import AuthenticationFormsWrapper from '../../components/AuthenticationFormsWrapper';
import HeaderTag from '../../components/WelcomePage/HeaderTag';
import Signature from '../../components/WelcomePage/Signature';
import {
  DATA_PRIVACY_ROUTE, TERMS_ROUTE, IMPRINT_ROUTE, SIGN_IN_ROUTE,
} from '../../constants/routes';
import { DeleteDataRequestAction } from '../../models/actions';

interface DeleteDataProps extends RouteComponentProps<{ token: string, email: string }> {
  clubLogoUrl: string;
  deleteData: (token: string) => DeleteDataRequestAction;
}

const DeleteData: FC<DeleteDataProps> = ({
  clubLogoUrl,
  history,
  deleteData,
}) => {
  const [translate] = useTranslation();
  const { getParams } = params(history);
  const { token, email } = getParams();

  const handleDeleteClick = () => {
    if (token) {
      deleteData(token as string);
    }
  };

  return (
    <AuthenticationFormsWrapper clubLogoUrl={clubLogoUrl}>
      <div className={styles.wrapper}>
        <HeaderTag successTitle="DATA_DELETION" />
        <h2 className={styles.header}>
          {translate('DATA_DELETION_QUESTION_HEADER')}
        </h2>
        <span className={styles.text}>
          {`${translate('HI')}!`}
        </span>
        <p className={[styles.text, styles.paragraphText].join(' ')}>
          {translate('DATA_DELETION_FIRST_PARAGRAPH_TEXT')}
        </p>
        {email && (
          <p className={[styles.text, styles.shrink].join(' ')}>
            {`${translate('EMAIL')}: `}
            <span>{email}</span>
          </p>
        )}
        <p className={[styles.text, styles.paragraphText].join(' ')}>
          {translate('DATA_DELETION_SECOND_PARAGRAPH_TEXT')}
        </p>
        <Signature />
        <div>
          <Button
            basic
            as={Link}
            to={SIGN_IN_ROUTE}
            className={styles.button}
            content={translate('CANCEL')}
            primary
          />
          <Button
            className={styles.button}
            content={translate('DELETE')}
            primary
            onClick={handleDeleteClick}
          />
        </div>
        <div className={styles.links}>
          <Link to={DATA_PRIVACY_ROUTE}>{translate('DATA_PRIVACY')}</Link>
          <span>|</span>
          <Link to={TERMS_ROUTE}>{translate('TERMS_AND_CONDITIONS')}</Link>
          <span>|</span>
          <Link to={IMPRINT_ROUTE}>{translate('IMPRINT.PAGE_NAME')}</Link>
        </div>
      </div>
    </AuthenticationFormsWrapper>
  );
};

export default DeleteData;
