import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';

import MemberDetails from '../MemberDetails';
import DesktopMemberRow from '../DesktopMemberRow';
import SelectProductsModal from '../SelectProductsModal';
import TableCheckboxHeader from '../../../../components/TableCheckboxHeader';
import TableContainer from '../../../../components/Layout/components/TableContainer';
import columns from './columns';
import columnsMobile from './columnsMobile';
import groupItemsByMember from '../../methods/groupItemsByMember';
import params from '../../../../utils/params';
import useSorting from '../../../../hooks/useSorting';
import { Item } from '../../../../models/Order';
import { PlayersListProps } from './PlayersListProps';
import { Member, MemberIdKey } from '../../../../models/Member';
import { OrderItemType } from '../../OrderPreparationProps';
import {
  PLAYERS_LIST_LIMIT,
  TABLE_SORT_FIELD_URL_PARAM,
  TABLE_SORT_ORDER_URL_PARAM,
} from '../../../../constants/customizations';
import { SortOrder } from '../../../../models/general/SortOrder';
import { useViewport } from '../../../../hooks/useViewport';
import MobileMemberRow from '../MobileMemberRow';
import { ListFilters } from '../../../../models/ListFilters';

const PlayersList = ({
  playersById,
  playersByHash,
  playersCount,
  addItems,
  itemsByHash,
  itemsById,
  loading,
  selectedPlayers,
  selectedStaff,
  addItemsForStaff,
  toggleAllSelection,
  fetchPlayers,
  modalOpened,
  setModal,
  history,
}: PlayersListProps) => {
  const { isMobile } = useViewport();
  const [playerId, setPlayerId] = useState<number | null>(null);
  const showDetails = (id: number) => () => setPlayerId(id);
  const closeDetails = () => setPlayerId(null);
  const { getParams } = params(history);
  const [,, teamId] = history.location.pathname.split('/');
  const { setSortOrder, setSortField } = useSorting(history);
  const {
    [TABLE_SORT_FIELD_URL_PARAM]: field,
    [TABLE_SORT_ORDER_URL_PARAM]: order,
  }: ListFilters = getParams();
  const [firstColumn, ...restColumns] = isMobile ? columnsMobile : columns;

  const Row = useMemo(() => (isMobile ? MobileMemberRow : DesktopMemberRow), [isMobile]);

  const enrichedColumns = useMemo(() => [
    {
      ...firstColumn,
      headerComponent: () => (
        <TableCheckboxHeader
          title="TEAM_MEMBER"
          onClick={toggleAllSelection}
          checked={selectedPlayers.length === playersById.length}
          checkbox
          sortable
          sortProps={firstColumn.key === field ? order as SortOrder : null}
        />
      ),
    },
    ...restColumns,
  ], [order, field, firstColumn,
    playersById.length, restColumns, selectedPlayers.length, toggleAllSelection]);

  const onSubmit = (items: Item[]) => {
    const itemIds = items.map(({ productCatalogId }) => productCatalogId);
    addItems(itemIds, selectedPlayers);
    addItemsForStaff(itemIds, selectedStaff);
    setModal(false)();
  };

  const fetchPlayersList = useCallback((payload?: any) => {
    fetchPlayers(+teamId, payload);
  }, [fetchPlayers, teamId]);

  useEffect(() => {
    setSortField(field as string);
    setSortOrder(order as SortOrder);
  }, [setSortOrder, setSortField, field, order]);

  return (
    <>
      <TableContainer
        columns={enrichedColumns}
        loading={loading}
        count={playersCount}
        disableSearch
        fetch={fetchPlayersList}
        history={history}
        invertedHeader
        limit={PLAYERS_LIST_LIMIT}
        renderItems={playersById.map((id: number) => (
          <Row
            key={id}
            member={playersByHash[id] as Member}
            checked={selectedPlayers.includes(id)}
            items={groupItemsByMember(itemsById, itemsByHash)[id] || []}
            showDetails={showDetails(id)}
            itemType={OrderItemType.PLAYER}
            teamId={+teamId}
          />
        ))}
      />
      <MemberDetails
        open={!!playerId}
        member={(playerId ? playersByHash[playerId] : {}) as Member}
        onClose={closeDetails}
        itemIdKey={MemberIdKey.PLAYER_ID}
        itemType={OrderItemType.PLAYER}
      />
      <SelectProductsModal
        isOpen={modalOpened}
        onClose={setModal(false)}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default PlayersList;
