import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { mergeMap, withLatestFrom } from 'rxjs/operators';

import calculateEmbellishmentsPrice from '../../../utils/calculatePrices/calculateEmbellishmentsPrice';
import calculateProductPrice from '../../../utils/calculatePrices/calculateProductPrice';
import { Embellishment } from '../../../models/Embellishment';
import { ORDER_UPDATE_PRICE_REQUEST } from '../../../constants/actionTypes';
import { OrderUpdatePriceRequestAction } from '../../../models/actions';
import { Price } from '../../../models/Price';
import { State } from '../../../models/State';
import { orderUpdatePriceSuccess } from '../../../actions/orderEditor';

const orderUpdatePrice = (
  action$: ActionsObservable<OrderUpdatePriceRequestAction>,
  state$: StateObservable<State>,
) => action$.pipe(
  ofType(ORDER_UPDATE_PRICE_REQUEST),
  withLatestFrom(state$),
  mergeMap(([, {
    application: { clubId, teamId },
    catalog: { catalog, embellishmentsByHash },
    orders: { itemsById, itemsByHash },
  }]) => {
    if (!clubId || !teamId) {
      return [];
    }

    const {
      productDiscountPercent: catalogDiscountPercent,
      teamProductDiscounts = [],
      productsByHash,
    } = catalog;

    const price: Price = itemsById.reduce(({ amount, currency }: Price, itemId) => {
      const {
        quantity, embellishments: itemEmbellishments, productCatalogId,
      } = itemsByHash[itemId];
      const { originalPrice, discountPercent } = productsByHash[productCatalogId];

      const embellishments: Embellishment[] = itemEmbellishments.map(
        ({ type }) => embellishmentsByHash[type],
      );

      const productPrice = calculateProductPrice(
        teamId,
        teamProductDiscounts,
        discountPercent,
        catalogDiscountPercent,
        originalPrice,
      );

      const embellishmentsPrice = calculateEmbellishmentsPrice(
        clubId,
        teamId,
        embellishments,
        catalog,
      );

      return {
        amount: amount + (productPrice + embellishmentsPrice) * quantity,
        currency: originalPrice.currency || currency,
      };
    }, { amount: 0, currency: 'EUR' });

    return [
      orderUpdatePriceSuccess(price),
    ];
  }),
);

export default orderUpdatePrice;
