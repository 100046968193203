import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import { MemberViewProps } from './MemberViewProps';
import { Person } from '../../../../models/ClubManagement';
import Profile from '../../../PlayerProfile/components/Profile/Profile';
import styles from '../../../PlayerProfile/PlayerProfile.module.scss';
import HeaderComponent from '../../../../components/Header';
import { SETTINGS_ROUTE } from '../../../../constants/routes';
import TeamLeadClubAdminDetails from './components/TeamLeadClubAdminDetails';

const MemberView = ({
  fetchMember, playersByHash, membersByHash = {}, personsByHash, loading,
}: MemberViewProps) => {
  const [translate] = useTranslation();
  const memberId = get(useParams(), 'memberId') as number;
  const [person, setPerson] = useState<Person>();

  useEffect(() => {
    if (memberId) {
      fetchMember(+memberId);
    }
  }, [fetchMember, memberId]);

  useEffect(() => {
    if (!person && personsByHash[memberId]) {
      setPerson(personsByHash[memberId]);
    }
  }, [personsByHash, person, memberId]);

  const renderContent = () => {
    if (person && person.playerId) {
      return <Profile player={playersByHash[person.playerId]} />;
    }

    const firstName = get(person, 'firstName', get(membersByHash[memberId] || {}, 'firstName', ''));
    const lastName = get(person, 'lastName', get(membersByHash[memberId] || {}, 'lastName', ''));
    const email = get(person, 'email', undefined);

    return <TeamLeadClubAdminDetails firstName={firstName} lastName={lastName} email={email} />;
  };

  return loading && !person ? <Dimmer active inverted><Loader /></Dimmer> : (
    <div className={styles.wrapper}>
      <HeaderComponent
        backLinkTo={SETTINGS_ROUTE}
        backLabel={translate('BACK_TO_CLUB_MANAGEMENT')}
      />
      {renderContent()}
    </div>
  );
};

export default MemberView;
