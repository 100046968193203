import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { push } from 'connected-react-router';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';

import { Response } from '../../models/Response';
import { State } from '../../models/State';
import { NotificationType, UpdateClubRequestAction } from '../../models/actions';
import { handleError } from '../../actions/errors';
import { addNotification } from '../../actions/notifications';
import { UPDATE_CLUB_ERROR, UPDATE_CLUB_REQUEST, UPDATE_CLUB_SUCCESS } from '../../constants/actionTypes';
import { CLUBS_PATH } from '../../constants/paths';
import { Club } from '../../models/Club';
import { updateClubError, updateClubSuccess } from '../../actions/clubs';
import { SETTINGS_ROUTE } from '../../constants/routes';

const shouldAttachSocialMedia = (obj?: Object) => {
  const pickedBy = pickBy(obj);

  return !isEmpty(pickedBy);
};

const updatePlayer = (
  action$: ActionsObservable<UpdateClubRequestAction>,
  state$: StateObservable<State>,
  { ajax }: any,
) => action$.pipe(
  ofType(UPDATE_CLUB_REQUEST),
  withLatestFrom(state$),
  mergeMap(([{
    payload: {
      club, clubId,
    },
  }, {
    authentication: { authToken },
  }]) => ajax({
    url: `${CLUBS_PATH}/${clubId}`,
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authToken,
    },
    body: JSON.stringify(pickBy({
      ...club,
      socialMedia: shouldAttachSocialMedia(club.socialMedia) && pickBy(club.socialMedia),
    })),
  }).pipe(
    mergeMap(({ response }: Response<Club>) => [
      addNotification(NotificationType.SUCCESS, UPDATE_CLUB_SUCCESS),
      updateClubSuccess(response),
      push(SETTINGS_ROUTE),
    ]),
    catchError((error: AjaxError) => [
      handleError(error, UPDATE_CLUB_ERROR),
      updateClubError(error),
    ]),
  )),
);

export default updatePlayer;
