import { bindCallback, fromEvent } from 'rxjs';

const convertFromBlobToUrl = (files: FileList | Blob[] | null, callback: (url: string) => void) => {
  const reader = new FileReader();
  fromEvent(reader, 'load').subscribe(() => {
    if (reader.result) {
      callback(reader.result.toString());
    }
  });

  if (files && files[0]) {
    reader.readAsDataURL(files[0]);
  }
};

export const convertFromBlobToUrl$ = bindCallback(convertFromBlobToUrl);

export default convertFromBlobToUrl;
