import React, { FC, useEffect } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { Dimmer, Loader } from 'semantic-ui-react';

import params from '../../utils/params';
import { FetchAuthUserRequestAction, ResetUserDataAction } from '../../models/actions';

interface MobileNavigationProps extends RouteComponentProps<{
  authToken: string, redirectTo: string,
}> {
  userDataFetched: boolean;
  isAuthenticated: boolean;
  userRole: boolean;
  setAuthToken: (token: string) => void;
  resetUserData: () => ResetUserDataAction;
  fetchAuthUser: (authToken: string) => FetchAuthUserRequestAction;
}

const MobileNavigation: FC<MobileNavigationProps> = ({
  history,
  userDataFetched,
  userRole,
  isAuthenticated,
  fetchAuthUser,
  resetUserData,
  setAuthToken,
}) => {
  const { getParams } = params(history);
  const { redirectTo, authToken } = getParams();

  useEffect(() => {
    if (authToken) {
      setAuthToken(authToken as string);
      fetchAuthUser(authToken as string);
    }
    return () => {
      resetUserData();
    };
  }, [authToken, setAuthToken, fetchAuthUser, resetUserData]);

  return (!userRole || !isAuthenticated || !userDataFetched) ? (
    <Dimmer active inverted>
      <Loader />
    </Dimmer>
  ) : (
    <Redirect to={redirectTo as string} />
  );
};

export default MobileNavigation;
