import React, {
  FC, SyntheticEvent, useState, useEffect,
} from 'react';
import get from 'lodash/get';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button, Dropdown, Modal, DropdownItemProps, Dimmer, Loader,
} from 'semantic-ui-react';

import ModalActions from '../../../../components/ModalActions';
import styles from './AssignToTeamModal.module.scss';
import { AssignToTeamModalProps } from './AssignToTeamModalProps';
import { MemberAssignType } from '../../../../models/Member';
import params from '../../../../utils/params';
import { UrlParams } from '../../../../constants/urlParams';
import { OrderItemType } from '../../../OrderPreparation/OrderPreparationProps';
import { Roles } from '../../../../constants/roles';

const AssignToTeamModal: FC<AssignToTeamModalProps> = ({
  bulkAssignMembers,
  disabled,
  loading,
  fetchTeams,
  memberIds,
  teams,
  history,
  triggerText,
  ...props
}) => {
  const { staticContext, ...restProps } = props;
  const [translate] = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [assignType, setAssignType] = useState<MemberAssignType>(MemberAssignType.COPY);
  const [teamId, setTeamId] = useState<number | null>(null);
  const sourceTeamId = +get(useParams(), 'teamId') as number;
  const { getParams } = params(history);
  const { [UrlParams.TEAM_ROLE_FILTER]: roleFilter } = getParams();
  const roles = [roleFilter === OrderItemType.STAFF ? Roles.TEAM_LEAD : Roles.PLAYER];

  useEffect(() => {
    if (isOpen) {
      fetchTeams({ pagination: false });
    }
  }, [fetchTeams, isOpen]);

  const teamOptions: DropdownItemProps[] = teams
    .filter(({ id }) => id !== sourceTeamId)
    .map(({ id, name }) => ({
      key: id, text: name, value: id,
    }));

  const handleAssignChange = (type: MemberAssignType) => () => {
    setAssignType(type);
  };

  const handleChangeTeam = (event: SyntheticEvent, { value }: any) => {
    setTeamId(value);
  };

  const handleConfirm = () => {
    if (teamId) {
      bulkAssignMembers(
        memberIds, sourceTeamId, teamId, assignType, roles,
      );
      setIsOpen(false);
    }
  };

  const handleOnModalClose = () => {
    setIsOpen(false);
  };

  const handleTriggerClick = () => {
    setIsOpen(true);
  };

  const handleCancelClick = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button
        content={triggerText}
        disabled={disabled}
        onClick={handleTriggerClick}
        {...restProps}
      />
      <Modal
        open={isOpen}
        onClose={handleOnModalClose}
        closeIcon
        size="tiny"
        className={styles.wrapper}
      >
        <h2>{translate('ASSIGN_TO_NEW_TEAM')}</h2>

        <div className={styles.formWrapper}>
          {loading && <Dimmer active inverted><Loader /></Dimmer>}

          <Button.Group className={styles.buttonGroup}>
            <Button
              basic={assignType === MemberAssignType.MOVE}
              circular
              content={translate(roleFilter === OrderItemType.STAFF ? 'COPY_MEMBERS' : 'COPY_PLAYERS')}
              onClick={handleAssignChange(MemberAssignType.COPY)}
              primary
            />
            <Button
              basic={assignType === MemberAssignType.COPY}
              circular
              content={translate(roleFilter === OrderItemType.STAFF ? 'MOVE_MEMBERS' : 'MOVE_PLAYERS')}
              onClick={handleAssignChange(MemberAssignType.MOVE)}
              primary
            />
          </Button.Group>

          <Dropdown
            placeholder={translate('SELECT_TEAM')}
            selection
            options={teamOptions}
            onChange={handleChangeTeam}
            fluid
          />
        </div>

        <ModalActions
          disabled={!teamId}
          onCancel={handleCancelClick}
          onSave={handleConfirm}
          textSave={translate(`${assignType}_TO_NEW_TEAM`)}
        />
      </Modal>
    </>
  );
};

export default AssignToTeamModal;
