import { bindActionCreators, Dispatch } from 'redux';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import AsyncStaticPage from './AsyncStaticPage';
import { State } from '../../../../models/State';
import { setMenuExpanded } from '../../../../actions/application';

const mapStateToProps = ({
  application: { menuExpanded },
  clubs: { logoUrl },
}: State) => ({
  clubLogoUrl: logoUrl,
  menuExpanded,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  setMenuExpanded,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AsyncStaticPage));
